<script setup lang="ts">
import type { VoixWysiwygFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    rulesCopy: VoixWysiwygFieldInterface
  }
}>()

const emit = defineEmits(['open-prizes'])

const showOfficialRules = ref(false)

function openPage(url: string) {
  window.open(url, '_blank')
}
</script>

<template>
  <!-- Navigation -->
  <div class="flex flex-col lg:flex-row justify-center items-center lg:space-x-24 space-y-6 lg:space-y-0 mt-6 lg:mt-20 mb-6">
    <!-- Official Rules -->
    <section v-if="showOfficialRules" class="flex justify-center">
      <Teleport to="#portal-modal">
        <!-- Official Rules -->
        <div class="fixed z-40 inset-0">
          <div class="absolute z-30 inset-0 bg-black/75" @click="showOfficialRules = false" />
          <div class="absolute z-40 inset-0 flex justify-center items-center pointer-events-none">
            <div class="relative bg-white w-[80vw] lg:w-[65vw] mx-8 p-8 pointer-events-auto border-t-8 border-red-500">
              <button class="absolute top-[-8px] right-0 z-10 bg-[#eb897c] rounded-b-lg rounded-br-none" @click="showOfficialRules = false">
                <Icon class="w-8 h-8 text-white" name="heroicons:x-mark-20-solid" />
              </button>
              <VoixWysiwyg class="w-full h-[75vh] overflow-auto font-medium text-black" :field="fields.rulesCopy" />
            </div>
          </div>
        </div>
      </Teleport>
    </section>

    <!-- Official Rules: Desktop -->
    <button
      class="hidden lg:flex w-fit text-white font-barbie-headline text-[40px] uppercase leading-none"
      @click="showOfficialRules = true"
    >
      <svg class="w-10 lg:w-14 relative bottom-4 right-1" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
        <path
          style="fill: #FEFF01;" d="M498.6,145.9c-12.9,0-31.1-0.1-49.4,0.1c-1.6,0-3.2,1.3-4.8,2.1c1,1.6,1.6,3.8,3.1,4.6
            c30.9,17.7,62.7,34.7,83.5,65.2c14.3,20.8,23.1,44.5,30.7,68.5c0.6,1.8-0.9,4.2-1.5,6.3c-1.5-1.1-3.6-1.9-4.5-3.3
            c-30.3-46.4-71.7-75.5-127.3-83c-3.4-0.5-6.4-4.2-9.8-4.7c-33.2-4.2-18.9,20.8-17,32.4c27.1,168.5,78.3,330.1,142.9,487.7
            c4.6,11.1,10,21.9,16.1,32.3c5.8,9.8-1.5,12.5-7.5,14.8c-16.4,6.1-33.2,11.3-49.8,17c-7.5,2.6-11.9-0.7-14.2-7.5
            c-17.4-52.8-35.9-105.3-51.7-158.6c-14.2-47.9-25.6-96.7-37.9-145.2c-6.8-26.7-13.4-53.6-19-80.6c-5.7-27.4-9.2-55.3-15.2-82.6
            c-6.3-28.8-12.6-57.4-12.6-87c0-10.4-6.5-8.8-12.3-6.3c-4,1.7-7,5.3-10.6,8c-17.7,12.9-31.5,29.6-44.5,46.8
            c-6.5,8.6-11.1,18.8-15.7,28.6c-11.4,24-17.1,49.1-13.2,75.8c0.5,3.1,1.6,6.1,2.6,9.1c1.8,5.6,5.6,13.2,0.4,16.1
            c-3.6,2-7.6-6.2-10.1-11c-16.3-31.7-33-64.1-27.5-101c5.5-37.3,18.9-72.2,44.9-101.1c1.8-2,3.7-6.5,2.8-7.7
            c-3.4-4.3-8.2-1.4-11.6,0.4c-20.2,10.6-40.9,20.5-60,32.8c-15.3,9.8-26.3,24.4-35.8,40.5c-7.2,12-11.2,24.1-13,37.5
            c-0.1,0.8-1.6,2.2-1.9,2c-1.1-0.6-2.2-1.4-2.8-2.4c-10.8-18.6-6.6-36.9-2.9-57.2c7.4-40.6,32.3-66.4,63.8-87.9
            c21.1-14.4,44.4-24.4,69.2-30.8c1.2-0.3,2.1-1.7,3.2-2.5c-1.7-1.6-3.2-3.9-5.2-4.6c-23.9-8.7-45,3.4-66.8,9.7
            c-0.7,0.2-1.3,0.6-2,0.9c-8.4,3.9-20.3,11.3-25.1,2.1c-4.8-9,9.9-12.2,16.3-17c39.4-29.4,84.4-50.9,132.2-35.5
            c38.6,12.4,49.1-20.6,72.7-31c57.7-25.3,112.7-36,165.6,9.1c5.3,4.6,10.8,9.2,15,14.8c2.3,3-0.9,4-3.8,2.7c-0.9-0.4-2-0.6-2.9-1
            c-25.5-13.2-52-16.6-79.1-6.5c-17.7,6.6-34.6,15.2-51.8,23.1c-1.3,0.6-2,2.5-3,3.9c3.1,1.5,6.3,4.5,9.4,4.4
            c24.8-0.2,49.6-3.3,74.2,2.8c37.6,9.3,65.8,31.7,87,63.6c4.7,7.1,9.3,14.2,13.2,21.7c1.3,2.6,1.5,8.6,0.2,9.3
            c-5.9,3-8.2-2.5-11.8-6C581.7,150.7,544.8,141.4,498.6,145.9z"
        />
      </svg>
      Official Rules
    </button>

    <!-- Prizes -->
    <button
      class="flex w-fit relative bottom-0 lg:bottom-6 text-white font-barbie-headline text-[48px] lg:text-[56px] uppercase leading-none"
      @click="emit('open-prizes')"
    >
      <svg class="w-14 lg:w-16 relative bottom-0 lg:bottom-1 right-1" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
        <path
          style="fill: #FEFF01;" d="M498.6,145.9c-12.9,0-31.1-0.1-49.4,0.1c-1.6,0-3.2,1.3-4.8,2.1c1,1.6,1.6,3.8,3.1,4.6
            c30.9,17.7,62.7,34.7,83.5,65.2c14.3,20.8,23.1,44.5,30.7,68.5c0.6,1.8-0.9,4.2-1.5,6.3c-1.5-1.1-3.6-1.9-4.5-3.3
            c-30.3-46.4-71.7-75.5-127.3-83c-3.4-0.5-6.4-4.2-9.8-4.7c-33.2-4.2-18.9,20.8-17,32.4c27.1,168.5,78.3,330.1,142.9,487.7
            c4.6,11.1,10,21.9,16.1,32.3c5.8,9.8-1.5,12.5-7.5,14.8c-16.4,6.1-33.2,11.3-49.8,17c-7.5,2.6-11.9-0.7-14.2-7.5
            c-17.4-52.8-35.9-105.3-51.7-158.6c-14.2-47.9-25.6-96.7-37.9-145.2c-6.8-26.7-13.4-53.6-19-80.6c-5.7-27.4-9.2-55.3-15.2-82.6
            c-6.3-28.8-12.6-57.4-12.6-87c0-10.4-6.5-8.8-12.3-6.3c-4,1.7-7,5.3-10.6,8c-17.7,12.9-31.5,29.6-44.5,46.8
            c-6.5,8.6-11.1,18.8-15.7,28.6c-11.4,24-17.1,49.1-13.2,75.8c0.5,3.1,1.6,6.1,2.6,9.1c1.8,5.6,5.6,13.2,0.4,16.1
            c-3.6,2-7.6-6.2-10.1-11c-16.3-31.7-33-64.1-27.5-101c5.5-37.3,18.9-72.2,44.9-101.1c1.8-2,3.7-6.5,2.8-7.7
            c-3.4-4.3-8.2-1.4-11.6,0.4c-20.2,10.6-40.9,20.5-60,32.8c-15.3,9.8-26.3,24.4-35.8,40.5c-7.2,12-11.2,24.1-13,37.5
            c-0.1,0.8-1.6,2.2-1.9,2c-1.1-0.6-2.2-1.4-2.8-2.4c-10.8-18.6-6.6-36.9-2.9-57.2c7.4-40.6,32.3-66.4,63.8-87.9
            c21.1-14.4,44.4-24.4,69.2-30.8c1.2-0.3,2.1-1.7,3.2-2.5c-1.7-1.6-3.2-3.9-5.2-4.6c-23.9-8.7-45,3.4-66.8,9.7
            c-0.7,0.2-1.3,0.6-2,0.9c-8.4,3.9-20.3,11.3-25.1,2.1c-4.8-9,9.9-12.2,16.3-17c39.4-29.4,84.4-50.9,132.2-35.5
            c38.6,12.4,49.1-20.6,72.7-31c57.7-25.3,112.7-36,165.6,9.1c5.3,4.6,10.8,9.2,15,14.8c2.3,3-0.9,4-3.8,2.7c-0.9-0.4-2-0.6-2.9-1
            c-25.5-13.2-52-16.6-79.1-6.5c-17.7,6.6-34.6,15.2-51.8,23.1c-1.3,0.6-2,2.5-3,3.9c3.1,1.5,6.3,4.5,9.4,4.4
            c24.8-0.2,49.6-3.3,74.2,2.8c37.6,9.3,65.8,31.7,87,63.6c4.7,7.1,9.3,14.2,13.2,21.7c1.3,2.6,1.5,8.6,0.2,9.3
            c-5.9,3-8.2-2.5-11.8-6C581.7,150.7,544.8,141.4,498.6,145.9z"
        />
      </svg>

      <span class="border-[#fff200] border-b-8">
        Official Prizes!
      </span>
    </button>

    <!-- Official Rules: Mobile -->
    <button
      class="flex lg:hidden w-fit text-white font-barbie-headline text-[40px] uppercase leading-none"
      @click="showOfficialRules = true"
    >
      <svg class="w-10 lg:w-14 relative bottom-1 right-1" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
        <path
          style="fill: #FEFF01;" d="M498.6,145.9c-12.9,0-31.1-0.1-49.4,0.1c-1.6,0-3.2,1.3-4.8,2.1c1,1.6,1.6,3.8,3.1,4.6
            c30.9,17.7,62.7,34.7,83.5,65.2c14.3,20.8,23.1,44.5,30.7,68.5c0.6,1.8-0.9,4.2-1.5,6.3c-1.5-1.1-3.6-1.9-4.5-3.3
            c-30.3-46.4-71.7-75.5-127.3-83c-3.4-0.5-6.4-4.2-9.8-4.7c-33.2-4.2-18.9,20.8-17,32.4c27.1,168.5,78.3,330.1,142.9,487.7
            c4.6,11.1,10,21.9,16.1,32.3c5.8,9.8-1.5,12.5-7.5,14.8c-16.4,6.1-33.2,11.3-49.8,17c-7.5,2.6-11.9-0.7-14.2-7.5
            c-17.4-52.8-35.9-105.3-51.7-158.6c-14.2-47.9-25.6-96.7-37.9-145.2c-6.8-26.7-13.4-53.6-19-80.6c-5.7-27.4-9.2-55.3-15.2-82.6
            c-6.3-28.8-12.6-57.4-12.6-87c0-10.4-6.5-8.8-12.3-6.3c-4,1.7-7,5.3-10.6,8c-17.7,12.9-31.5,29.6-44.5,46.8
            c-6.5,8.6-11.1,18.8-15.7,28.6c-11.4,24-17.1,49.1-13.2,75.8c0.5,3.1,1.6,6.1,2.6,9.1c1.8,5.6,5.6,13.2,0.4,16.1
            c-3.6,2-7.6-6.2-10.1-11c-16.3-31.7-33-64.1-27.5-101c5.5-37.3,18.9-72.2,44.9-101.1c1.8-2,3.7-6.5,2.8-7.7
            c-3.4-4.3-8.2-1.4-11.6,0.4c-20.2,10.6-40.9,20.5-60,32.8c-15.3,9.8-26.3,24.4-35.8,40.5c-7.2,12-11.2,24.1-13,37.5
            c-0.1,0.8-1.6,2.2-1.9,2c-1.1-0.6-2.2-1.4-2.8-2.4c-10.8-18.6-6.6-36.9-2.9-57.2c7.4-40.6,32.3-66.4,63.8-87.9
            c21.1-14.4,44.4-24.4,69.2-30.8c1.2-0.3,2.1-1.7,3.2-2.5c-1.7-1.6-3.2-3.9-5.2-4.6c-23.9-8.7-45,3.4-66.8,9.7
            c-0.7,0.2-1.3,0.6-2,0.9c-8.4,3.9-20.3,11.3-25.1,2.1c-4.8-9,9.9-12.2,16.3-17c39.4-29.4,84.4-50.9,132.2-35.5
            c38.6,12.4,49.1-20.6,72.7-31c57.7-25.3,112.7-36,165.6,9.1c5.3,4.6,10.8,9.2,15,14.8c2.3,3-0.9,4-3.8,2.7c-0.9-0.4-2-0.6-2.9-1
            c-25.5-13.2-52-16.6-79.1-6.5c-17.7,6.6-34.6,15.2-51.8,23.1c-1.3,0.6-2,2.5-3,3.9c3.1,1.5,6.3,4.5,9.4,4.4
            c24.8-0.2,49.6-3.3,74.2,2.8c37.6,9.3,65.8,31.7,87,63.6c4.7,7.1,9.3,14.2,13.2,21.7c1.3,2.6,1.5,8.6,0.2,9.3
            c-5.9,3-8.2-2.5-11.8-6C581.7,150.7,544.8,141.4,498.6,145.9z"
        />
      </svg>
      Official Rules
    </button>

    <!-- Privacy Policy -->
    <button
      class="flex w-fit text-white font-barbie-headline text-[40px] uppercase leading-none"
      @click="openPage('/privacy')"
    >
      <svg class="w-10 lg:w-14 relative bottom-1 lg:bottom-4 right-1" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
        <path
          style="fill: #FEFF01;" d="M498.6,145.9c-12.9,0-31.1-0.1-49.4,0.1c-1.6,0-3.2,1.3-4.8,2.1c1,1.6,1.6,3.8,3.1,4.6
            c30.9,17.7,62.7,34.7,83.5,65.2c14.3,20.8,23.1,44.5,30.7,68.5c0.6,1.8-0.9,4.2-1.5,6.3c-1.5-1.1-3.6-1.9-4.5-3.3
            c-30.3-46.4-71.7-75.5-127.3-83c-3.4-0.5-6.4-4.2-9.8-4.7c-33.2-4.2-18.9,20.8-17,32.4c27.1,168.5,78.3,330.1,142.9,487.7
            c4.6,11.1,10,21.9,16.1,32.3c5.8,9.8-1.5,12.5-7.5,14.8c-16.4,6.1-33.2,11.3-49.8,17c-7.5,2.6-11.9-0.7-14.2-7.5
            c-17.4-52.8-35.9-105.3-51.7-158.6c-14.2-47.9-25.6-96.7-37.9-145.2c-6.8-26.7-13.4-53.6-19-80.6c-5.7-27.4-9.2-55.3-15.2-82.6
            c-6.3-28.8-12.6-57.4-12.6-87c0-10.4-6.5-8.8-12.3-6.3c-4,1.7-7,5.3-10.6,8c-17.7,12.9-31.5,29.6-44.5,46.8
            c-6.5,8.6-11.1,18.8-15.7,28.6c-11.4,24-17.1,49.1-13.2,75.8c0.5,3.1,1.6,6.1,2.6,9.1c1.8,5.6,5.6,13.2,0.4,16.1
            c-3.6,2-7.6-6.2-10.1-11c-16.3-31.7-33-64.1-27.5-101c5.5-37.3,18.9-72.2,44.9-101.1c1.8-2,3.7-6.5,2.8-7.7
            c-3.4-4.3-8.2-1.4-11.6,0.4c-20.2,10.6-40.9,20.5-60,32.8c-15.3,9.8-26.3,24.4-35.8,40.5c-7.2,12-11.2,24.1-13,37.5
            c-0.1,0.8-1.6,2.2-1.9,2c-1.1-0.6-2.2-1.4-2.8-2.4c-10.8-18.6-6.6-36.9-2.9-57.2c7.4-40.6,32.3-66.4,63.8-87.9
            c21.1-14.4,44.4-24.4,69.2-30.8c1.2-0.3,2.1-1.7,3.2-2.5c-1.7-1.6-3.2-3.9-5.2-4.6c-23.9-8.7-45,3.4-66.8,9.7
            c-0.7,0.2-1.3,0.6-2,0.9c-8.4,3.9-20.3,11.3-25.1,2.1c-4.8-9,9.9-12.2,16.3-17c39.4-29.4,84.4-50.9,132.2-35.5
            c38.6,12.4,49.1-20.6,72.7-31c57.7-25.3,112.7-36,165.6,9.1c5.3,4.6,10.8,9.2,15,14.8c2.3,3-0.9,4-3.8,2.7c-0.9-0.4-2-0.6-2.9-1
            c-25.5-13.2-52-16.6-79.1-6.5c-17.7,6.6-34.6,15.2-51.8,23.1c-1.3,0.6-2,2.5-3,3.9c3.1,1.5,6.3,4.5,9.4,4.4
            c24.8-0.2,49.6-3.3,74.2,2.8c37.6,9.3,65.8,31.7,87,63.6c4.7,7.1,9.3,14.2,13.2,21.7c1.3,2.6,1.5,8.6,0.2,9.3
            c-5.9,3-8.2-2.5-11.8-6C581.7,150.7,544.8,141.4,498.6,145.9z"
        />
      </svg>
      Privacy Policy
    </button>
  </div>
</template>
