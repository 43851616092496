<script>
import { inView, timeline } from 'motion'

export default {

  mounted() {
    const trunk = this.$el.querySelectorAll('.trunk')
    const leftBranches = this.$el.querySelectorAll('.branch-left')
    const rightBranches = this.$el.querySelectorAll('.branch-right')
    const leaves = this.$el.querySelectorAll('.leaf')

    const sequence = [
      [trunk, { scaleY: [0, 1] }, { duration: 0.5 }],
      [leftBranches, { scaleY: [0, 1], scaleX: [0, 1], translateX: [100, 0] }, { duration: 0.5 }],
      [rightBranches, { scaleY: [0, 1], scaleX: [0, 1], translateX: [100, 0] }, { duration: 0.5, at: 0.5 }],
      [leaves, { scaleY: [0, 1] }, { duration: 0.5 }],
    ]
    inView('.trunk', (info) => {
      const theTimeline = timeline(sequence)

      return () => theTimeline.stop()
    })
  },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.81 297.64">
    <polygon
      fill="currentColor" class="trunk"
      points="94.42 297.64 91.63 297.64 91.63 147.75 91.63 9.29 94.42 9.29 94.42 297.64"
    />
    <path
      fill="currentColor" class="branch-left origin-top-left"
      d="m37.42,73.13l-3.28-.29c2.67-30.41,28.04-57.34,60.32-64.04l.67,3.23c-30.89,6.41-55.16,32.1-57.71,61.1Z"
    />
    <path
      fill="currentColor" class="branch-left  origin-top-left"
      d="m95.42,8.89l-1.26,3.05C72.64,3.09,53.06,1.03,36.65,5.8c-1.11.32-2.21.67-3.29,1.06-1.53.54-3.04,1.15-4.51,1.82-1.12.52-2.22,1.05-3.29,1.64-.87.47-1.73.96-2.58,1.48-.66.4-1.3.81-1.93,1.23-1.16.76-2.26,1.55-3.29,2.36C7.43,23.35,3.03,32.37,2.97,32.48l-2.97-1.43c.23-.48,5.39-11.04,17.74-19.78,1.05-.73,2.14-1.46,3.29-2.16.07-.05.15-.09.22-.14,1.33-.81,2.76-1.61,4.29-2.36,1.05-.52,2.15-1.03,3.29-1.51,1.43-.61,2.93-1.19,4.51-1.71,1.06-.35,2.16-.69,3.29-1,14.02-3.84,33.37-3.94,58.77,6.51Z"
    />
    <rect fill="currentColor" class="leaf" x="9.93" y="19.52" width="3.3" height="13.27" />
    <path fill="currentColor" class="leaf" d="m21.03,9.11v23.29h-3.29V11.27c1.05-.73,2.14-1.46,3.29-2.16Z" />
    <path fill="currentColor" class="leaf" d="m28.84,5.09v26.92h-3.29V6.61c1.05-.52,2.15-1.03,3.29-1.51Z" />
    <path fill="currentColor" class="leaf" d="m36.65,2.38v29.24h-3.29V3.38c1.06-.35,2.16-.69,3.29-1Z" />
    <rect fill="currentColor" class="leaf" x="41.15" y="2.35" width="3.3" height="28.88" />
    <rect fill="currentColor" class="leaf" x="48.96" y="1.96" width="3.3" height="29.4" />
    <rect fill="currentColor" class="leaf" x="48.96" y="37.87" width="3.3" height="35.12" />
    <rect fill="currentColor" class="leaf" x="56.77" y="3.13" width="3.3" height="69.47" />
    <rect fill="currentColor" class="leaf" x="64.57" y="2.74" width="3.3" height="69.86" />
    <rect fill="currentColor" class="leaf" x="72.37" y="4.69" width="3.3" height="67.91" />
    <rect fill="currentColor" class="leaf" x="80.18" y="5.87" width="3.3" height="66.73" />
    <rect fill="currentColor" class="leaf" x="41.94" y="47.49" width="3.3" height="25.5" />
    <path
      fill="currentColor" class="branch-right origin-top-left"
      d="m149.39,73.91c-2.55-29-26.81-54.69-57.71-61.1l.67-3.23c32.28,6.69,57.65,33.63,60.32,64.04l-3.28.29Z"
    />
    <path
      fill="currentColor" class="branch-right origin-top-left"
      d="m91.4,9.67l1.26,3.05c21.52-8.86,41.1-10.91,57.52-6.15,1.11.32,2.21.67,3.29,1.06,1.53.54,3.04,1.15,4.51,1.82,1.12.52,2.22,1.05,3.29,1.64.87.47,1.73.96,2.58,1.48.66.4,1.3.81,1.93,1.23,1.16.76,2.26,1.55,3.29,2.36,10.31,7.96,14.71,16.98,14.77,17.09l2.97-1.43c-.23-.48-5.39-11.04-17.74-19.78-1.05-.73-2.14-1.46-3.29-2.16-.07-.05-.15-.09-.22-.14-1.33-.81-2.76-1.61-4.29-2.36-1.05-.52-2.15-1.03-3.29-1.51-1.43-.61-2.93-1.19-4.51-1.71-1.06-.35-2.16-.69-3.29-1-14.02-3.84-33.37-3.94-58.77,6.51Z"
    />
    <rect fill="currentColor" class="leaf" x="173.58" y="20.3" width="3.3" height="13.27" />
    <path fill="currentColor" class="leaf" d="m165.78,9.89v23.29h3.29V12.05c-1.05-.73-2.14-1.46-3.29-2.16Z" />
    <path fill="currentColor" class="leaf" d="m157.97,5.87v26.92h3.29V7.39c-1.05-.52-2.15-1.03-3.29-1.51Z" />
    <path fill="currentColor" class="leaf" d="m150.17,3.16v29.24h3.29V4.16c-1.06-.35-2.16-.69-3.29-1Z" />
    <rect fill="currentColor" class="leaf" x="142.36" y="3.13" width="3.3" height="28.88" />
    <rect fill="currentColor" class="leaf" x="134.56" y="2.74" width="3.3" height="29.4" />
    <rect fill="currentColor" class="leaf" x="134.56" y="38.65" width="3.3" height="35.12" />
    <rect fill="currentColor" class="leaf" x="126.75" y="3.91" width="3.3" height="69.47" />
    <rect fill="currentColor" class="leaf" x="118.95" y="3.52" width="3.3" height="69.86" />
    <rect fill="currentColor" class="leaf" x="111.14" y="5.48" width="3.3" height="67.91" />
    <rect fill="currentColor" class="leaf" x="103.34" y="6.65" width="3.3" height="66.73" />
    <rect fill="currentColor" class="leaf" x="141.58" y="48.27" width="3.3" height="25.5" />
  </svg>
</template>
