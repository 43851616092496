<script setup lang="ts">
const props = defineProps<{
  fields: {
    type: object
    default: () => ({})
  }
  slice: {
    type: object
    default: () => ({})
  }
}>()

defineSlice({
  name: { label: 'Sub Navigation', group: 'Navigation', layouts: ['Atlantisbahamas'] },
  preview: 'SlicesGlueNavigationSubNavigation.jpg',
  description: 'Careers Sub Navigation',
  tags: ['Navigation'],
  fields: {
    title: { type: 'text', label: 'Menu Title', enabled: false },
  },
  templates: [{ label: 'Sub Navigation', fields: { menu: { value: '44' }, title: { value: 'Careers Menu' } } }],
})

const menu = ref({})

$voixFetch(`/api/devise/load-menu/44`).then((response) => {
  menu.value = response
})

const route = useRoute()

function isCurrentRoute(url: string) {
  return url === route.href

}
</script>

<template>
  <div
    class="flex justify-center bg-white font-sans2 uppercase font-bold text-sm py-3"
  >
    <ul
      v-if="menu?.data && menu.data[0] && menu.data[0].menu_items"
      class="container -mt-1 flex flex-col md:flex-row px-4 md:space-x-8"
    >
      <li v-if="fields.title.enabled">
        {{ fields.title.text }}:
      </li>
      <li v-for="menuItem in menu.data[0].menu_items" :key="menuItem.id">
        <a
          :href="menuItem.permalink"
          class=" transition duration-200"
          :class="{
            'text-glueblue-600 hover:text-glueblue-400': !isCurrentRoute(menuItem.permalink),
            'text-glueblue-400 hover:text-glueblue-200 border-b-2 border-glueblue-400': isCurrentRoute(menuItem.permalink),
          }"
        >{{ menuItem.name }}</a>
      </li>
    </ul>
  </div>
</template>
