<script>
export default {
  props: {
    devise: { type: Object },
    modalClasses: {
      type: String,
    },
  },
  computed: {
    modalStyles() {
      const windowWidth = window.innerWidth

      if (windowWidth < 768)
        return { width: '95vw' }

      return { width: '90%' }
    },
  },
  mounted() {
    const body = document.body
    body.classList.add('overflow-hidden')
  },
  beforeUnmount() {
    const body = document.body
    body.classList.remove('overflow-hidden')
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<template>
  <div class="fixed inset-0 z-50">
    <div class="fixed inset-0 flex justify-center items-center z-50">
      <div class="bg-white opacity-75 absolute inset-0" @click="close" />
      <div
        class="relative bg-white shadow-lg h-[80vh] md:min-h-0 m-0 md:m-8"
        :class="modalClasses"
        style="max-width: 95vw; min-width: 350px"
        :style="modalStyles"
      >
        <div
          class="booking-widget-close bg-black absolute top-0 right-0 -mt-12 z-50"
          @click="close"
        />

        <div class="overflow-y-auto bg-white max-h-screen h-[80vh]">
          <div class="relative h-full pb-32 p-8 lg:p-16 flex">
            <div
              class="md:hidden w-full py-4 mb-8 bg-zinc-700 text-white font-sans text-center cursor-pointer"
              @click="close"
            >
              Close
            </div>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
