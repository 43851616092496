<script>
import dayjs from 'dayjs'

export default {
  name: 'DateFormatter',

  props: {
    devise: { type: Object },
    date: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: 'DDD MMMM YYYY',
    },
  },

  computed: {
    formattedDate() {
      return dayjs(this.date).format(this.format)
    },
  },
}
</script>

<template>
  <span>{{ formattedDate }}</span>
</template>
