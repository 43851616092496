<script lang="ts" setup>
const showForm = ref(false)
const newListing = ref({
  unit: null,
  type: null,
  first_name: null,
  last_name: null,
  email: null,
  phone: null,
  agree_to_terms: false,
})
const reefListings = ref([])
const formValid = computed(() => {
  if (
    newListing.value.unit === null
    || newListing.value.type === null
    || newListing.value.first_name === null
    || newListing.value.last_name === null
    || newListing.value.email === null
    || newListing.value.phone === null
    || newListing.value.agree_to_terms === false
  ) {
    return false
  }

  return true
})

defineSlice({
  name: { label: 'Reef Property Listings', group: 'Community', layouts: ['Reef'] },
  preview: 'SlicesReefProperties.jpg',
  description: '',
  tags: ['Property Listings'],

  templates: [{
    label: 'Reef Property Listings',
    fields: {},
  }],
})

async function getListings() {
  const { data } = await $voixFetch('/api/devise/reef-listings')

  if (data)
    reefListings.value = data
}
getListings()

async function createListing() {
  const { data, error } = await $voixFetch(
    '/api/devise/reef-listings',
    { method: 'POST', body: newListing.value },
  )

  if (error)
    console.log(error.data.message)

  if (data) {
    showForm.value = false
    newListing.value.unit = null
    newListing.value.first_name = null
    newListing.value.last_name = null
    newListing.value.email = null
    newListing.value.phone = null
    newListing.value.agree_to_terms = false

    getListings()
  }
}

async function deleteListing(listing) {
  const { error } = await $voixFetch(
    `/api/devise/reef-listings/${listing.id}`,
    { method: 'DELETE' },
  )

  if (error)
    console.log(error.data.message)

  getListings()
}

await getListings()
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <div v-cloak class="container py-24">
    <div class="text-center flex flex-col items-center w-full">
      <div class="prose-p:pb-4">
        <h1 class="uppercase font-sans2 font-light mb-4">
          The Reef Property Listings
        </h1>
        <h4 class="uppercase mb-2">
          Disclaimer:
        </h4>
        <p>
          Please be advised that Owner Services administration reserves the right to remove
          any listing deemed offensive, inaccurate or otherwise inappropriate.
        </p>
        <p>
          Your listing will only be available for 60 days. After 60 days the unit will need
          to be re-listed.
        </p>
      </div>

      <div
        class="bg-glueblue-600 uppercase text-white text-xs p-4 px-6 font-sans mb-12"
        @click="showForm = true"
      >
        Add Your Property
      </div>
      <div v-if="showForm" class="bg-white shadow-lg p-8 w-full md:w-2/3 mb-12">
        <h3 class="uppercase mb-8">
          Add your Property to the Listings
        </h3>
        <div class="text-left">
          <fieldset class="mb-6">
            <label class="mb-2">Unit Number(s)</label>
            <input v-model="newListing.unit" type="text">
          </fieldset>
          <fieldset class="mb-6">
            <label class="mb-2">Unit Type</label>
            <select v-model="newListing.type">
              <option :value="null" disabled>
                Please Select a Type
              </option>
              <option value="1 Bedroom Suite">
                1 Bedroom Suite
              </option>
              <option value="2 Bedroom Suite">
                2 Bedroom Suite
              </option>
              <option value="King Studio">
                King Studio
              </option>
              <option value="Studio Double">
                Studio Double
              </option>
              <option value="1 Bedroom Topaz Suite">
                1 Bedroom Topaz Suite
              </option>
              <option value="2 Bedroom Topaz Suite">
                2 Bedroom Topaz Suite
              </option>
            </select>
          </fieldset>
          <fieldset class="mb-6">
            <label class="mb-2">First Name</label>
            <input v-model="newListing.first_name" type="text">
          </fieldset>
          <fieldset class="mb-6">
            <label class="mb-2">Last Name</label>
            <input v-model="newListing.last_name" type="text">
          </fieldset>
          <fieldset class="mb-6">
            <label class="mb-2">Email Address</label>
            <input v-model="newListing.email" type="text">
          </fieldset>
          <fieldset class="mb-6">
            <label class="mb-2">Phone Number</label>
            <input v-model="newListing.phone" type="text">
          </fieldset>
          <fieldset class="mb-4">
            <div class="flex items-center">
              <label class="mb-0">
                <input
                  v-model="newListing.agree_to_terms"
                  type="checkbox"
                  class="inline-block mr-2"
                  style="width: 20px"
                >
                Agree to displaying contact information?</label>
            </div>
          </fieldset>

          <fieldset>
            <div class="flex justify-center">
              <button
                class="btn btn-xs bg-glueblue-600 text-white"
                :disabled="!formValid"
                @click="createListing"
              >
                Post This Listing
              </button>
            </div>
          </fieldset>
        </div>
      </div>
    </div>

    <template v-if="reefListings">
      <ReefPropertiesByRoomType
        :all-listings="reefListings"
        title="One Bedroom Suites"
        listing-type-key="1 Bedroom Suite"
        @delete-listing="deleteListing"
      />
      <ReefPropertiesByRoomType
        :all-listings="reefListings"
        title="Two Bedroom Suites"
        listing-type-key="2 Bedroom Suite"
        @delete-listing="deleteListing"
      />
      <ReefPropertiesByRoomType
        :all-listings="reefListings"
        title="King Studio"
        listing-type-key="King Studio"
        @delete-listing="deleteListing"
      />
      <ReefPropertiesByRoomType
        :all-listings="reefListings"
        title="Studio Double"
        listing-type-key="Studio Double"
        @delete-listing="deleteListing"
      />
      <ReefPropertiesByRoomType
        :all-listings="reefListings"
        title="One Bedroom Topaz Suite"
        listing-type-key="1 Bedroom Topaz Suite"
        @delete-listing="deleteListing"
      />
      <ReefPropertiesByRoomType
        :all-listings="reefListings"
        title="Two Bedroom Topaz Suite"
        listing-type-key="2 Bedroom Topaz Suite"
        @delete-listing="deleteListing"
      />
    </template>
  </div>
</template>
