<script>
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  name: 'ClippingSlider',
  components: {
    Swiper,
    SwiperSlide,

  },
  props: { devise: { type: Object, default: () => ({}) } },

  data() {
    return {
      swiper: null,
      windowWidth: 1200,
    }
  },

  computed: {
    containerWidth() {
      let width = '1200px'
      if (this.windowWidth > 1680) {
        width = '1450px'
      }
      else if (this.windowWidth > 1120) {
        const threefourths = this.windowWidth * 0.75
        const bleedWidth = (this.windowWidth * 0.25) / 2
        width = `${bleedWidth + threefourths}px`
      }
      else {
        width = '100%'
      }

      return width
    },

    activeIndex() {
      if (this.swiper)
        return Number.parseInt(this.swiper.activeIndex) - 1

      return null
    },
    autoplayOptions() {
      if (this.devise.fields.autoPlay.value) {
        return {
          delay: 5000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }
      }

      return false
    },
  },

  mounted() {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

    this.updateOnInit()
  },

  methods: {
    updateOnInit() {
      setTimeout(() => {
        if (this.$refs.mySwiper?.$el?.swiper) {
          this.$refs.mySwiper.$el.swiper.update()
          this.swiper = this.$refs.mySwiper.$el.swiper
        }
        else {
          this.updateOnInit()
        }
      }, 1000)
    },

    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    goToSlide(index) {
      this.swiper.slideTo(index)
    },
  },
}
</script>

<template>
  <div>
    <div>
      <div
        class="flex" :class="{
          'justify-end': devise.fields.isRight.value && windowWidth <= 1680,
          'justify-start': !devise.fields.isRight.value && windowWidth <= 1680,
          'justify-center': windowWidth > 1680,
        }"
      >
        <div :style="{ width: containerWidth }" class="pb-20">
          <div
            class="px-8 md:px-0 flex flex-col" :class="{
              'items-start': devise.fields.isRight.value && windowWidth <= 1680,
              'items-end text-right': !devise.fields.isRight.value && windowWidth <= 1680,
              'text-center items-center': windowWidth > 1680,
            }"
          >
            <div
              class="flex-1" :class="{
                'lg:pl-48': !devise.fields.isRight.value && windowWidth <= 1680,
                'lg:pr-48': devise.fields.isRight.value && windowWidth <= 1680,
                'flex flex-col items-center': windowWidth > 1680,
              }"
            >
              <div v-if="devise.fields.title.value" class="headline mb-3 max-w-86">
                {{ devise.fields.title.value }}
              </div>
              <div v-if="devise.fields.description.value" class="max-w-5xl body-lg mb-6">
                {{ devise.fields.description.value }}
              </div>
            </div>
          </div>

          <div class="relative md:ml-0 md:mr-0 overflow-hidden flex">
            <Swiper
              ref="mySwiper" slides-per-view="auto" :dir="devise.fields.isRight.value ? 'ltr' : 'rtl'"
              :space-between="10" :loop="devise.slice.elements.length > 1" :autoplay="autoplayOptions" :centered-slides="false"
            >
              <SwiperSlide
                v-for="(slice, key) in devise.slice.elements" :key="key"
                class="lg:mx-0 flex justify-center lg:justify-start w-full max-w-[300px] lg:max-w-full"
                style="direction: ltr"
              >
                <VoixSlice
                  :slice="slice" :slice-index="key" class="w-full max-w-[300px] lg:max-w-full"
                  style="direction: ltr"
                />
              </SwiperSlide>
            </Swiper>

            <div
              v-if="devise.slice.elements.length > 1"
              class="absolute z-50 inset-0 pointer-events-none flex justify-between items-center px-8"
            >
              <button class="slider-button border-gray-500" @click="prev">
                <svg class="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>

              <button class="slider-button border-gray-500" @click="next">
                <svg
                  class="w-7 h-7 transform rotate-180" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div class="flex justify-center lg:justify-end px-4">
            <div class="flex space-x-3">
              <button
                v-for="(slide, n) in devise.slice.elements" :key="n" class="h-1.5 w-1.5 rounded-full transistion-colors duration-500"
                :class="{
                  'bg-gray-500': n !== activeIndex % devise.slice.elements.length,
                  'bg-white': n === activeIndex % devise.slice.elements.length,
                }" @click="goToSlide(n)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.swiper-slide {
  width: 100%;
}

@screen md {
  .swiper-slide { width: auto !important; }

}
</style>
