<script setup lang="ts">
import { useNuxtApp } from '#app'

import { useThrottleFn } from '@vueuse/core'

defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

const { $voix } = useNuxtApp()

defineSlice({

  name: { label: 'Interactive Map', group: 'Hard Coded Information', layouts: ['Atlantisbahamas', 'AtlantisMobileApp'] },
  preview: 'SlicesGlueMapInteractiveMap.jpg',
  description: 'Interactive Map',
  tags: ['Map', 'Global Information'],
  fields: {
    title: {
      label: 'Title',
      type: 'text',
      default: 'Map of Atlantis',
    },
    copy: {
      label: 'Copy',
      type: 'wysiwyg',
      default: `<div class="body-lg mb-6 text-center max-w-120 mx-auto">
          What better way to navigate your stay than with an eagle-eye view of
          the property?
        </div>
        <div class="mt-4 body-lg mb-6 text-center max-w-120 mx-auto">
          For the best and most detailed map experience download the
          <a
            href="/atlantis-mobile-app"
            target="_blank"
            class="text-glueblue-400 border-b border-glueblue-400 hover-draw-border"
          >Atlantis Mobile App</a>.
        </div>`,
    },
  },
  templates: [{ label: 'Interactive Map', fields: [] }],
})

const loaded = ref(false)
const transitioning = ref(false)
const mapContainer: Ref<null | HTMLElement> = ref(null)
const mapContainerBounding: Ref<{ width: null | number, height: null | number }> = ref({
  width: null,
  height: null,
})
const scale = ref(1)
const imageSize = {
  w: 1537,
  h: 1288,
}

const mapPosition = ref({
  x: 0,
  y: 0,
})

const mapMove = ref(false)
const moveStartingCoordinates = ref({
  x: 0,
  y: 0,
})
const zoomedIn = ref(false)

const mapStyles = computed(() => {
  const styles = {
    transition: 'all 500ms',
    backgroundImage: `url(/imgs/maps/property-map.jpg)`,
    backgroundPosition: `${mapPosition.value.x}px ${mapPosition.value.y}px`,
    backgroundSize: `${mapContainerBounding.value.width}px`,
  }

  if (mapContainerBounding.value && mapContainerBounding.value.width) {
    if (!zoomedIn.value) {
      styles.backgroundSize = `${mapContainerBounding.value.width}px`
    }
    else {
      styles.backgroundSize = `${
        (imageSize.w / mapContainerBounding.value.width)
        * (scale.value * 100)
      }%`
    }
  }

  return styles
})

function init() {
  setTimeout(() => {
    if (mapContainer.value) {
      mapContainerBounding.value
          = mapContainer.value.getBoundingClientRect()
      loaded.value = true

      if ($voix.breakpoint.value === 'default' || $voix.breakpoint.value === 'sm')
        scale.value = 0.5
    }
  }, 1000)
}

onMounted(() => {
  window.addEventListener('resize', () => {
    init()
  })
})

function zoomIn() {
  const x = -10
  const y = -10

  if (scale.value === 1)
    scale.value = 1.5
  else if (scale.value < 2.5)
    scale.value = scale.value + 0.5

  zoomedIn.value = true
  moveMap(x, y)
}

function zoomOut() {
  scale.value = 1
  zoomedIn.value = false
  moveMap(1, 1)
}

function moveMap(newX: number, newY: number) {
  transitioning.value = true

  const windowWidth = mapContainerBounding.value.width
  const windowHeight = mapContainerBounding.value.height

  const x = newX * scale.value
  const y = newY * scale.value

  const imageScaleW = imageSize.w * scale.value
  const imageScaleH = imageSize.h * scale.value

  // Width
  const visibleWidth = imageScaleW + x
  const widthOverhang = windowWidth ? visibleWidth - windowWidth : visibleWidth
  const widthOverhangOffset = x - widthOverhang

  // Height
  const visibleHeight = imageScaleH + y
  const heightOverhang = windowHeight ? visibleHeight - windowHeight : visibleHeight
  const heightOverhangOffset = y - heightOverhang

  mapPosition.value.x = widthOverhang > 0 ? x : widthOverhangOffset
  mapPosition.value.y = heightOverhang > 0 ? y : heightOverhangOffset

  setTimeout(() => {
    transitioning.value = false
  }, 100)
}

function startDrag(event: MouseEvent) {
  mapMove.value = true
  moveStartingCoordinates.value = {
    x: event.offsetX,
    y: event.offsetY,
  }
}

const throttledMouseMoving = useThrottleFn(mouseMoving, 50)

function mouseMoving(event: MouseEvent) {
  if (mapMove.value) {
    const moveX = event.offsetX - moveStartingCoordinates.value.x
    const moveY = event.offsetY - moveStartingCoordinates.value.y

    const moveToX = mapPosition.value.x + moveX
    const moveToY = mapPosition.value.y + moveY
    mapPosition.value.x = moveToX > 0 ? 0 : moveToX
    mapPosition.value.y = moveToY > 0 ? 0 : moveToY

    moveStartingCoordinates.value = {
      x: event.offsetX,
      y: event.offsetY,
    }
  }
}

init()
</script>

<template>
  <div class="flex justify-center">
    <div class="container">
      <div ref="mapContainer" class="tracking-atlantis-map">
        <div
          class="max-w-[60rem] mx-auto flex flex-col items-center px-12 lg:px-0 mb-8 "
        >
          <div class="headline">
            {{ fields.title.value }}
          </div>
          <VoixWysiwyg class="container mt-6" :field="fields.copy" />
        </div>
        <!-- Map Box -->
        <div
          v-if="loaded"
          class="overflow-hidden w-full relative h-[500px] lg:h-[1000px] bg-black"
          @mousemove="throttledMouseMoving"
        >
          <div
            class="h-full w-full bg-no-repeat cursor-move"
            :style="mapStyles"
            @mousedown="startDrag"
            @mouseup="mapMove = false"
          />
          <div
            class="absolute top-0 z-10 mt-6 mx-8 flex"
          >
            <button
              class="glue-btn text-xs tracking-wide flex-grow p-3 pt-5 px-16 bg-orange-50"
              @click="zoomIn"
            >
              Zoom In
            </button>
            <button
              class="glue-btn text-xs tracking-wide flex-grow p-3 pt-5 px-16 bg-orange-50"
              @click="zoomOut"
            >
              Zoom Out
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
