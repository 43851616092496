<script setup lang="ts">
import { useUploader } from '~/composables/useUploader'
import { ref, computed } from 'vue'
import type { Ref } from 'vue'

export interface Props {
  autoUpload?: boolean
  multiple?: boolean
  uploadUrl?: string
  field?: string
  hierarchyId?: string
}

class UploadableFile {
  file: File
  id: string
  url: string
  status: string | null

  constructor(file: File) {
    this.file = file
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
    this.url = URL.createObjectURL(file)
    this.status = null
  }
}

// State
const props = withDefaults(defineProps<Props>(), {
  autoUpload: false,
  multiple: false,
  uploadUrl: `/api/barbie/video`,
  hierarchyId: '',
})
const emit = defineEmits(['file-upload-complete', 'file-upload-progress'])
const fileBrowser = ref(null)
const newFiles: Ref<Array<UploadableFile>> = ref([])
const dropActive = ref(false)
const currentlyUploading: Ref<Array<string | null>> = ref([])
const uploadProgress = ref(0)
const uploaderContainer = ref<HTMLElement | null>(null)

// Methods
function openFileBrowser() {
  if (fileBrowser.value === null)
    return

  const element = fileBrowser.value as HTMLInputElement
  element.click()
}
function fileExists(otherId: string) {
  return newFiles.value.some(({ id }) => id === otherId)
}
function appendNewFilesFromInput(event: Event) {

  // If we already have files and multiple is requested then return
  if (!props.multiple && newFiles.value.length > 0)
    return

  // If the event target is null then return
  if (event.target === null)
    return

  let filesToAdd = (<HTMLInputElement>event.target).files as FileList;

  let newUploadableFiles = [...filesToAdd]
    .map((file) => new UploadableFile(file))
    .filter((file) => !fileExists(file.id))

  newFiles.value = newFiles.value.concat(newUploadableFiles)
}
function onDrop(event: any) {
  dropActive.value = false

  if (!props.multiple && newFiles.value.length > 0)
    return

  const filesToAdd = [...event.dataTransfer.files] as unknown as FileList
  let newUploadableFiles = [...filesToAdd]
    .map((file) => new UploadableFile(file))
    .filter((file) => !fileExists(file.id))
  newFiles.value = newFiles.value.concat(newUploadableFiles)
}
function removeNewFile(file: UploadableFile) {
  if (fileBrowser.value) {
    const element = fileBrowser.value as HTMLInputElement

    if (!props.multiple)
      element.value = ''

    const index = newFiles.value.indexOf(file)
    if (index > -1) {
      newFiles.value.splice(index, 1)
      uploadProgress.value = 0
    }
  } else {
    newFiles.value = []
    uploadProgress.value = 0
  }
}
async function uploadFile(file: UploadableFile, url: string) {
  // add the file to the currently uploading list
  currentlyUploading.value.push(file.id)

  // set up the request data
  const formData = new FormData()
  formData.append('file', file.file)
  formData.append('hierarchy_id', props.hierarchyId)

  // track status and upload file
  file.status = 'loading'
  emit('file-upload-progress')

	// upload the file
  const videoUploader = useUploader(url, formData)

	// track the progress of the upload
	const videoProgress = setInterval(() => {
		uploadProgress.value = videoUploader.progress.value
		if (videoUploader.progress.value === 100) {
			clearInterval(videoProgress)
		}
	}, 50)

	// execute the upload
	await videoUploader.execute()

  // Remove the file from the currently uploading list
  const index = currentlyUploading.value.indexOf(file.id)
  if (index > -1) {
    currentlyUploading.value.splice(index, 1)
  }

  // emit the file upload event
  emit('file-upload-complete', { 
		data: videoUploader.data.value,
		status: videoUploader.status.value, 
		error: videoUploader.error.value
	})

  removeNewFile(file)
  console.log('newFiles: ', newFiles.value.length)
  return videoUploader.data
}
function uploadFiles() {
  return Promise.all(newFiles.value.map((file) => uploadFile(file, props.uploadUrl)))
}

// Watchers
watch(newFiles, (newValue, oldValue) => {
	if (newValue.length > 0) uploadFiles()
})

// Lifecycle
onMounted(() => {
  uploaderContainer.value = document.getElementById('uploader')
  uploaderContainer.value?.addEventListener('drop', (event) => {
    event.preventDefault()
    onDrop(event)
  })
  uploaderContainer.value?.addEventListener('click', openFileBrowser)
  })
</script>

<template>
  <div class="rounded-md flex justify-center items-center text-center" :class="{
    ' bg-white': dropActive,
    'voix-admin-bg-light voix-admin-bg-lightest': !dropActive
  }" :data-active="dropActive" @dragenter.prevent="dropActive = true" @dragover.prevent="dropActive = true"
    @dragleave.prevent="dropActive = false" @drop.prevent="onDrop">

    <div v-if="newFiles.length === 0" class="flex flex-col items-center">
      <input ref="fileBrowser" type="file" class="opacity-0 h-0 w-0" :multiple="multiple"
        @change="appendNewFilesFromInput">

      <button class="flex items-center space-x-2 text-white rounded font-medium p-5 py-1.5 mx-auto">
        <Icon
          name="i-mdi:upload-circle" class="w-32 h-32 lg:w-48 lg:h-48 text-white"
        />
      </button>
    </div>

    <div v-if="newFiles.length > 0" class="relative my-8 h-8 overflow-hidden rounded-full bg-gray-400 w-full flex">
      <div class="h-8 rounded-full transition-all duration-1000 bg-gradient-to-r from-[#ff3eb5] to-[#feff00]" :style="`width: ${Math.round(uploadProgress)}%`">
        <span class="absolute top-1 left-[50%] flex items-center justify-center text-base font-semibold text-white">
          {{ Math.round(uploadProgress) }}%
        </span>
      </div>
    </div>
  </div>
</template>
