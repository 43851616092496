<script>
export default {
  name: 'CasinoIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 38 45"
  >
    <defs>
      <path
        id="casino-icon-prefix-a"
        d="M0 0.24L37.292 0.24 37.292 44.043 0 44.043z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(0 .718)">
        <mask id="casino-icon-prefix-b" fill="currentcolor">
          <use xlink:href="#casino-icon-prefix-a" />
        </mask>
        <path
          fill="currentcolor"
          stroke="#currentColor"
          stroke-width=".7"
          d="M5.211 23.909v11.704l-2.5-1.04H2.71c-.425-.172-.752-.5-.899-.907-.158-.397-.158-.871 0-1.268l3.401-8.49zM30.49 6.235v31.81c0 .916-.738 1.66-1.647 1.66H8.494c-.908 0-1.646-.744-1.646-1.66V6.235c0-.915.738-1.66 1.646-1.66h20.348c.909 0 1.647.745 1.647 1.66h0zM13.606 2.926l.006-.014c.368-.857 1.312-1.257 2.157-.907l2.242.921h-4.405zM23.73 41.353l-.006.018c-.17.428-.495.756-.89.9-.495.182-.904.186-1.266.004l-2.242-.922h4.404zm11.798-29.517l-3.401 8.53V8.661l2.5 1c.427.172.753.5.898.899.158.44.16.882.003 1.276h0zm1.508-1.916c-.33-.81-.956-1.425-1.812-1.78l-3.097-1.258v-.647c0-1.825-1.473-3.309-3.284-3.309H22.38L16.368.485c-.806-.335-1.693-.327-2.502.024-.804.346-1.43.986-1.762 1.8l-.252.617H8.494c-1.81 0-3.283 1.484-3.283 3.31v13.256L.257 31.796c-.343.836-.342 1.732-.001 2.518.33.81.957 1.425 1.813 1.78l3.097 1.258V38c0 1.825 1.473 3.31 3.283 3.31h6.462l6.065 2.49c.417.157.85.244 1.22.244.404 0 .84-.084 1.272-.247.803-.333 1.413-.965 1.765-1.825l.252-.618h3.357c1.811 0 3.284-1.484 3.284-3.308V24.787l4.91-12.303c.342-.838.342-1.726 0-2.564h0z"
          mask="url(#casino-icon-prefix-b)"
        />
      </g>
      <path
        fill="currentcolor"
        stroke="#currentColor"
        stroke-width=".5"
        d="M21.904 20.102c.026-.161.047-.332.047-.526 0-1.824-1.472-3.308-3.283-3.308-1.81 0-3.282 1.484-3.282 3.308 0 .169.022.354.047.52-1.513.313-2.624 1.67-2.624 3.263 0 1.825 1.472 3.309 3.282 3.309.375 0 .747-.066 1.124-.202l-.951 2.131c-.08.198-.057.42.059.593.11.163.29.257.492.257h3.708c.202 0 .38-.093.49-.257.117-.174.14-.395.057-.603l-.944-2.113c.363.126.749.194 1.12.194 1.81 0 3.283-1.484 3.283-3.301.045-1.553-1.067-2.915-2.625-3.265M11.549 7.669l.002-.034c0-.73-.57-1.303-1.293-1.303-.726 0-1.293.573-1.293 1.303v.034c-.5.172-.86.672-.86 1.244 0 .73.568 1.303 1.293 1.303l.071-.002-.161.362c-.063.126-.059.269.01.382.065.105.18.168.306.168h1.267c.126 0 .242-.063.306-.17.07-.112.073-.255.015-.372l-.167-.372c.741.067 1.365-.544 1.365-1.3 0-.572-.361-1.072-.861-1.243M27.938 35.546c-.02 0-.043 0-.07.002l.162-.363c.062-.126.058-.269-.012-.38-.064-.107-.178-.17-.306-.17h-1.266c-.126 0-.242.063-.307.17-.068.112-.072.255-.014.372l.167.372c-.024-.002-.047-.003-.072-.003-.725 0-1.292.573-1.292 1.303 0 .56.343 1.035.861 1.23-.002.015-.003.03-.003.046 0 .731.569 1.304 1.294 1.304.724 0 1.293-.573 1.293-1.304v-.033c.498-.172.858-.671.858-1.243 0-.73-.568-1.303-1.293-1.303"
      />
    </g>
  </svg>
</template>
