<script setup lang="ts">
import dayjs from 'dayjs'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({

  name: { label: 'Deal Page', group: 'Specials', layouts: ['Atlantisbahamas'] },
  preview: 'SlicesGlueSpecialsSpecialPage.jpg',
  description: 'Details Page of a Special',
  tags: ['Specials', 'Page'],
  fields: {
    special: {
      type: 'number',
      label: 'Special ID',
    },
    dailyFee: {
      type: 'text',
      label: 'Daily Fee Text',
      default: {
        text: 'The daily resort fee of $52.95 + 10% VAT includes:',
      },
    },
  },
  templates: [{
    label: 'Specials',
  }],
})

const special: Ref<null | AtlantisSpecial> = ref(null)
const error = ref(false)

const specialData = computed(() => {
  if (special.value) {
    return {
      fields: {
        showBookingWidget: {
          value: true,
        },
        image: {
          url: special.value.main_image,
        },
        desktopHeight: {
          value: 950,
        },
        subtitle: {
          value: `${dayjs(special.value.travel_from_date).format('MMM D')} - ${dayjs(special.value.travel_until_date).format('MMM D, YYYY')}`,
        },
        showDateRange: {
          value: special.value.show_date_range,
        },
        showBoxOnHero: {
          value: true,
        },
        showBookNow: {
          value: special.value.show_book_now_btn,
        },
        title: {
          value: special.value.title,
        },
        description: {
          value: special.value.details_page_description,
        },
        included_items: {
          value: special.value.included_items,
        },
        short_description: {
          value: special.value.short_description,
        },
        link: {
          enabled: false,
        },
        includes1Big: {
          enabled: false,
        },
        includes2Big: {
          enabled: false,
        },
        includes3Big: {
          enabled: false,
        },
        copyAppears: {
          value: 'notAtAll',
        },
      },
    }
  }

  return null
})

const { data } = await useVoixAsyncData(`/api/devise/page-specials/${props.fields.special.value}`)
special.value = data.value.data

// @todo how do we handle these?
// this.setCobrandOverride(special.value.cobrand.nav)
// window.deviseSettings.$bus.$emit('special-override', special.value.id)
</script>

<template>
  <div v-if="special?.id">
    <div class="relative z-10" :class="`dvs-sp${special.id}b101z`">
      <div style="height: 0" />

      <div class="relative w-full h-full overflow-hidden max-h-[65vh] pointer-events-none" style="min-height: 200px;">
        <img class="w-full h-full object-center h-screen-85" :src="special.main_image">

        <div class="hidden lg:block relative z-20 lg:absolute lg:h-full lg:w-full lg:top-0 h">
          <GlueSlidersSliderSpecialOffer
            :devise="specialData"
            :special="special"
          />
        </div>
      </div>

      <div v-if="special.show_book_now_btn" class="p-6 pb-0 md:p-0 relative z-50 hidden lg:block">
        <BookingForm
          class="bg-white translate-y-[50%] p-6 md:p-6 w-full md:w-3/4 md:mx-auto md:bottom-0 md:left-0 md:right-0 relative md:absolute z-40 hidden md:mt-0 xl:block"
          :inline="true"
          :special="fields.special.value"
        />
      </div>

      <div
        class="pt-12 lg:pt-32 mt-0 bg-orange-200 text-gray-800 relative z-0 pb-24"
      >
        <div class="container">
          <div
            class="md:mx-auto text-center"
            style="max-width: 940px"
          >
            <div
              class="text-3xl md:text-4xl tracking-widest uppercase font-sans font-light px-8 md:px-0"
            >
              {{ special.details_page_title }}
            </div>
            <div
              class="font-sans2 w-full text-gray-600 font-light text-lg mb-12 px-8 lg:px-32 dvs-wysiwyg"
              v-html="special.details_page_description"
            />
          </div>

          <div class="mb-8 md:mb-24 relative z-20">
            <GlueSpecialsSpecialOfferDetails :special="special" />

            <BookingBookNow v-if="special.show_book_now_btn" :special="special.id" location="navigation" disableable>
              <button class="mx-auto block px-6 py-3 uppercase font-base bg-glueblue-700 text-white mt-12">
                Book Now
              </button>
            </BookingBookNow>
          </div>

          <div class="w-full">
            <slot />
          </div>
        </div>
      </div>
      <div class="bg-orange-50 text-center text-gray-800 pt-24">
        <div class="container">
          <SlicesGlueHotelFeaturesDailyResortFee />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="error"
    class="flex justify-center items-center bg-orange-200 pt-32 md:pt-64"
  >
    <div class="bg-white flex flex-col md:flex-row mx-8 mb-16">
      <div class="p-12 md:w-1/3">
        <div class="text-4xl leading-tight font-sans uppercase font-light text-gray-600">
          You just missed this special
        </div>
        <div class="font-sans2 mt-4">
          It's not a problem. We always have fantastic offerings at Atlantis. Be sure to
          checkout the current specials that are running by clicking the button below.
        </div>
        <a
          href="/specials"
          class="glue-btn btn-sm mt-12 border border-gray-100 text-glueblue-700 border-glueblue-700 btn-active"
        >See Specials</a>
      </div>
      <div
        class="bg-cover w-2/3"
        style="background-image: url('/imgs/hotels/the-royal.jpeg')"
      />
    </div>
  </div>
</template>
