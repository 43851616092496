<script>
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import Animations from '@/mixins/Animations'

dayjs.extend(advancedFormat)

export default {
  name: 'GlueBigSpecial',
  mixins: [Animations],
  props: {
    devise: { type: Object },
    special: {
      type: Object,
      required: true,
    },
    track: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      mousingOver: false,
    }
  },
  computed: {
    learnMoreLink() {
      if (this.special.page?.permalink)
        return this.special.page.permalink

      return null
    },
  },
  methods: {
    formatDate(date) {
      return `${dayjs(date).format('MMM. Do')}`
    },

    goToSpecial() {
      location.href = this.special.page?.permalink
    },

    pushToDataLayer() {
      if (window.dataLayer)
        window.dataLayer.push({ offerName: this.special.title })
    },
  },
}
</script>

<template>
  <div
    v-view="animateSlideUp"
    class="flex flex-col-reverse md:flex-row mb-8 bg-white"
    @mouseenter="mousingOver = true"
    @mouseleave="mousingOver = false"
  >
    <div class="p-10 md:w-110 mr-12">
      <div class="mb-16">
        <div class="w-48">
          <!-- <div
            class="font-light text-red-500 italic mb-4 tracking-wide text-lg text-18"
            v-html="special.travel_date_range"
          ></div> -->

          <div
            class="uppercase font-sans font-light leading-tight text-gray-800 text-3xl tracking-wider mb-4"
          >
            {{ special.title }}
          </div>
        </div>
        <div
          class="text-gray-500 font-sans2 text mb-8 text-15"
          v-html="special.short_description"
        />
        <div class="flex justify-start items-center">
          <BookingBookNow
            v-if="special.show_book_now_btn"
            :dropdown="false"
            :location="`Special - ${special.title}`"
            :special="special.id"
            :track="track"
            disableable
            @click.native="pushToDataLayer"
          >
            <template #default="slotProps">
              <button
                class="px-6 py-4 leading-snug uppercase focus:outline-none font-sans text-xs"
                :class="{
                  'bg-glueblue-700 text-white': slotProps.active,
                  ' btn-ghost border-gray-500 btn-active':
                    !slotProps.active,
                }"
              >
                Book Now
              </button>
            </template>
          </BookingBookNow>
          <a
            v-for="(link, index) in special.links"
            :key="index"
            :href="link.href"
            class="mt-2 ml-6 special-learnmore uppercase text-xs font-sans text-glueblue-400 border-b border-glueblue-400 hover-draw-border pb-1"
          >
            {{ link.text }}
          </a>
        </div>
      </div>
      <div v-if="special.included_items">
        <div class="uppercase text-xs font-sans text-gray-600 mb-4">
          This offer includes
        </div>
        <div class="flex justify-start w-64">
          <div
            v-for="item in special.included_items"
            :key="item.id"
            class="leading-tight pr-16 last:pr-0"
          >
            <div
              class="uppercase text-2xl font-sans tracking-widest font-light text-gray-800"
            >
              {{ item.amount }}
            </div>
            <div class="uppercase text-gray-500 font-sans text-sm">
              {{ item.unit }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow overflow-hidden relative flex">
      <div
        class="bg-cover bg-center min-h-96 transform duration-300 w-full h-full"
        :class="{ 'scale-105': mousingOver }"
      />
    </div>
  </div>
</template>
