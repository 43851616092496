<script setup lang="ts">
const props = defineProps({
  slice: {
    type: Object,
    required: true,
  },
})

const images = computed(() => {
  const applicableSlices = props.slice.elements.filter((element: any) => element.fields[0]?.media[0]?.url)
  return applicableSlices.map((element: any) => ({
    url: element.fields[0]?.media[0]?.url,
    provider: element.fields[0]?.media[0]?.provider,
  }))
})
const imageThumbnails = computed(() => {
  const applicableSlices = props.slice.elements.filter((element: any) => element.fields[0]?.media[0])
  return applicableSlices.map((element: any) => element.fields[0]?.media[0])
})

const state = reactive({
  mousingOver: 0,
  showGallery: false,
  activeImage: 0,

  pattern: [
    'col-start-1 col-span-3 lg:col-span-5 row-span-2 h-full',
    'col-span-2 h-full',
    'col-span-2 lg:col-span-5 lg:row-span-2 h-full',
    'col-span-2 lg:col-start-6 h-full',
    'col-span-3 lg:col-span-3 row-span-2 lg:row-span-1 h-full',
    'col-span-2 lg:col-span-3 h-full',
    'col-span-3 row-span-2 lg:col-span-6 h-full',
    'col-span-2 lg:col-span-3 h-full',
    'col-span-2 lg:col-span-3 h-full',
    'col-span-2 lg:col-span-5 lg:row-span-2 h-full',
    'col-span-3 lg:col-span-5 row-span-2  h-full',
    'col-span-2 lg:col-span-2 h-full',
    'hidden lg:block col-span-2 lg:col-span-2 h-full',
  ],
})

const generateClasses = function (key: number) {
  return state.pattern[key % state.pattern.length]
}

const openGallery = function (key: number) {
  state.showGallery = true
  state.activeImage = key
}
defineSlice({
  name: { label: 'Categorized Gallery', group: 'galleries', layouts: ['Paranza'] },
  slots: [{ name: 'default - Categorized Image', allowedElements: [] }],
  tags: ['Gallery'],
  preview: 'SlicesGalleriesCategorizedGallery.jpg',
})
</script>

<template>
  <div class="container max-w-5xl">
    <div class="mt-6 grid grid-cols-5 lg:grid-cols-12 gap-2">
      <div
        v-for="(image, key) in imageThumbnails"
        :key="key"
        class="relative bg-glueblue-600 overflow-hidden cursor-pointer"
        :class="generateClasses(key)"
        @mouseenter="state.mousingOver = key"
        @mouseleave="state.mousingOver = 0"
        @click="openGallery(key)"
      >
        <VoixMedia
          v-if="image"
          :field="image.url"
          :provider="image.provider"
          class="w-full h-full object-cover transition duration-500 ease-out"
          :class="{ 'scale-105': state.mousingOver === key }"
          width="422"
          height="633"
        />
      </div>
    </div>

    <RestaurantMicroChromeModalGallery
      :show="state.showGallery"
      :gallery="images"
      :initial-image="state.activeImage"
      @close="state.showGallery = false"
    />
  </div>
</template>
