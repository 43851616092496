<script>
export default {
  name: 'FloorPlanIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="currentcolor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".8"
    >
      <g>
        <path
          d="M0 7.483L0 0.065 10.339 0.065 10.339 10.404 0 10.404"
          transform="translate(1) translate(0 .455)"
        />
        <path
          d="M0 5.235L3.576 5.235 3.576 3.882M3.576.093L3.576 1.542M5.657 10.382L5.657 8.626M5.657 5.235L10.339 5.235"
          transform="translate(1) translate(0 .455)"
        />
      </g>
      <path
        d="M5.657 4.337L5.657 6.914M5.657.52L5.657 1.997"
        transform="translate(1)"
      />
    </g>
  </svg>
</template>
