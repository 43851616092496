import type { AsyncDataRequestStatus } from '#app/composables/asyncData'
import type { MaybeRef } from 'vue'

export function useUploader(url: string, form: MaybeRef<FormData>) {
  const progress = ref(0)
  const data = ref(null)
  const error = ref(null)
  const { csrf } = useCsrf()
  const status = ref<AsyncDataRequestStatus>('idle')

  const execute = async () => new Promise((resolve) => {
    const xhr = new XMLHttpRequest()

    xhr.open('POST', url, true)
    xhr.setRequestHeader('csrf-token', csrf)

    xhr.upload.onprogress = function (event) {
      if (!event.lengthComputable) {
        console.log('onProgress not supported')
        return
      }

      progress.value = +((event.loaded / event.total) * 100).toFixed(2)
    }

    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        try {
          error.value = null
          status.value = 'success'
          data.value = JSON.parse(xhr.responseText)
        }
        catch (err) {
          data.value = xhr.responseText
        }
      }
      else {
        try {
          data.value = null
          status.value = 'error'
          error.value = JSON.parse(xhr.responseText)
        }
        catch (err) {
          error.value = xhr.responseText
        }
      }
      resolve(null)
    }

    xhr.onerror = function () {
      error.value = 'Upload failed due to a network error'
      console.error(new Error(error.value))
      resolve(null)
    }

    status.value = 'pending'
    xhr.send(unref(form))
  })

  return {
    progress,
    data,
    error,
    status,
    execute,
  }
}
