<script setup lang="ts">
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

defineProps({
  fields: {
    type: Object,
    required: true,
  },
})

defineSlice({
  name: { label: 'Movies', group: 'Movies', layouts: ['AtlantisMobileApp', 'Atlantisbahamas'] },
  preview: 'SlicesGlueMoviesMovies.jpg',
  description: 'Shows movie times',
  tags: ['Movies', 'Showtimes', 'Index'],
  templates: [{ label: 'Movies', fields: [] }],
})

dayjs.extend(isBetween)

const moviesData = ref([])
const firstDayOfWeek = computed(() => {
  return dayjs().startOf('day')
})
const lastDayOfWeek = computed(() => {
  return dayjs().startOf('day').add(6, 'day')
})

function formatDate(date) {
  return dayjs(date).format('MMMM D')
}

function formatTime(time) {
  return dayjs(time).format('h:mm a')
}

function getDate(dayOfWeek) {
  return firstDayOfWeek.value.add(dayOfWeek, 'day').format('dddd MMMM D')
}

function getMoviesForDay(dayOfWeek) {
  const day = firstDayOfWeek.value.add(dayOfWeek, 'day')
  const movies = []
  for (let i = 0; i < moviesData.value.length; i++) {
    const movie = moviesData.value[i]
    for (let j = 0; j < movie.times.length; j++) {
      const time = movie.times[j]
      if (dayjs(time).isBetween(day, day.add(1, 'day')))
        movies.push({ movie, time })
    }
  }

  return movies.sort((a, b) => {
    return dayjs(a.time).isBefore(dayjs(b.time)) ? -1 : 1
  })
}

onMounted(() => {
  $voixFetch(`/api/devise/movies`).then((response) => {
    moviesData.value = response.data
  })
})
</script>

<template>
  <div class="container my-16 px-8">
    <div class="mx-auto" style="max-width: 900px">
      <div class="text-2xl font-sans uppercase">
        Movie Times
      </div>
      <div class="body-lg">
        Below are times for {{ formatDate(firstDayOfWeek) }} -
        {{ formatDate(lastDayOfWeek) }}
      </div>
    </div>

    <template v-for="(day, key) in 7" :key="key">
      <div

        class="mt-4 bg-white mx-auto text-left font-sans2 divide-y divide-zinc-400 px-8 md:px-16 w-full"
        style="max-width: 900px"
      >
        <div class="pt-10 pb-3">
          <div
            class="relative text-lg px-4 pr-8 flex flex-col md:flex-row justify-between"
          >
            <div class="md:w-1/2">
              <span class="text-glueblue-700 text-xl font-bold">{{
                getDate(day - 1)
              }}</span>
            </div>
          </div>

          <div
            class="mt-6 pt-6 border-t border-zinc-300 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          >
            <div
              v-for="movieTime in getMoviesForDay(day - 1)"
              :key="movieTime.movie.id"
              class="rounded-lg border-4 border-zinc-100 p-6 text-left flex flex-col justify-between"
            >
              <div>
                <div
                  class="-mt-1 text-glueblue-400 text-lg font-bold flex justify-between"
                >
                  <div>{{ movieTime.movie.name }}</div>
                  <div class="flex-none text-zinc-400 min-w-[70px] text-right">
                    {{ movieTime.movie.rating }}
                  </div>
                </div>
                <div class="mt-1 text-zinc-500 font-light text-sm">
                  {{ movieTime.movie.description }}
                </div>
              </div>
              <div>
                <div class="border-t border-zinc-300 mt-4 pt-4 font-bold text-zinc-600">
                  {{ formatTime(movieTime.time) }}
                </div>
                <div class="-mt-1 text-zinc-400">
                  {{ movieTime.movie.location }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="getMoviesForDay(day - 1).length < 1"
            class="font-bold text-xl italic text-zinc-500 pb-8"
          >
            No showtimes for this date at this time.
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
