<script>
import dayjs from 'dayjs'

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
    showDateSquare: {
      type: Boolean,
      default: true,
    },

    siblings: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      showModal: false,
      currentIndex: null,
      currentLinkIndex: null,
    }
  },

  computed: {
    currentIframeData() {
      return this.siblings[this.currentIndex]
    },

    currentIframeUrl() {
      if (this.currentIframeData) {
        const linkHref = this.currentIframeData?.links[this.currentLinkIndex]?.href
        if (window && window.location && this.currentLinkIndex && linkHref) {
          const url = linkHref.includes('http')
            ? new URL(linkHref)
            : new URL(
              `${window.location.protocol}//${window.location.hostname}${linkHref}`,
            )
          url.searchParams.append('nochrome', 'true')
          return url.href
        }
        return this.currentIframeData.links[this.currentLinkIndex]?.href
      }
      return null
    },

    iframeNext() {
      if (this.currentIndex === this.siblings.length - 1)
        return this.siblings[0]

      return this.siblings[this.currentIndex + 1]
    },

    iframePrevious() {
      if (this.currentIndex === 0)
        return this.siblings[this.siblings.length - 1]

      return this.siblings[this.currentIndex - 1]
    },

    startDate() {
      return dayjs(this.event.start_date).format('dddd, MMMM D')
    },

    startTime() {
      return dayjs(this.event.start_date).format('h:mm a')
    },

    endTime() {
      return dayjs(`${this.event.start_date} ${this.event.end_time}`).format('h:mm a')
    },
  },

  mounted() {
    this.currentIndex = this.siblings.indexOf(this.event)
    window.addEventListener('goPrevIframe', this.goToPrevIframe)
    window.addEventListener('goNextIframe', this.goToNextIframe)
  },

  beforeUnmount() {
    window.removeEventListener('goPrevIframe', this.goToPrevIframe)
    window.removeEventListener('goNextIframe', this.goToNextIframe)
  },

  methods: {
    showOriginalImage(event) {
      if (
        event.size === 3
        && (this.breakpoint === 'largedesktop'
          || this.breakpoint === 'ultraWideDesktop')
        && Object.prototype.hasOwnProperty.call(event.image, 'media')
        && Object.prototype.hasOwnProperty.call(event.image.media, 'orig_optimized')
      ) {
        return true
      }

      return false
    },

    getEventMonthAbbr(event) {
      const date = dayjs(event.start_date)
      const month = date.format('MMM')
      return month
    },

    getEventDayNumber(event) {
      const date = dayjs(event.start_date)
      const day = date.format('D')
      return day
    },

    selectEvent(linkIndex) {
      this.currentLinkIndex = linkIndex
      const linkHref = this.currentIframeData?.links[this.currentLinkIndex]?.href

      if (linkHref) {
        navigateTo(linkHref, {
          open: {
            target: '_blank',
          },
        })
      }
    },

    goToPrevIframe() {
      if (this.currentIndex === 0)
        this.currentIndex = this.siblings.length - 1
      else
        this.currentIndex = this.currentIndex - 1
    },

    goToNextIframe() {
      if (this.currentIndex === this.siblings.length - 1)
        this.currentIndex = 0
      else
        this.currentIndex = this.currentIndex + 1
    },

    getVenueName(venue) {
      if (venue) {
        if (venue === 'JokersWild')
          return 'Joker\'s Wild'

        if (venue === 'MarinaVillage')
          return 'Marina Village'

        if (venue === 'ConferencesAndEvents')
          return 'Conference & Event Center'

        return venue
      }
    },

    getVenueUrl(venue) {
      if (venue === 'Aura')
        return '/things-to-do/entertainment/aura-nightclub'

      if (venue === 'JokersWild')
        return '/things-to-do/entertainment/jokers-wild-comedy-club'

      if (venue === 'Crush')
        return '/kids/teens/crush'

      if (venue === 'Theater')
        return '/things-to-do/entertainment/atlantis-theatre'
    },
    formatDate(date) {
      return dayjs(date).format('ddd, MMMM D')
    },
  },
}
</script>

<template>
  <div
    class="relative w-full flex justify-stretch items-center text-center animation-ease-up flex-col"
    :class="{
      'lg:col-span-2': event.featured,
    }"
  >
    <div
      class="relative w-full h-[245px] flex justify-center bg-gradient-to-b from-orange-300 to-white"
    >
      <img
        :src="event.image"
        class="object-cover"
      >

      <div v-if="event.brand" class="absolute bottom-0 right-0 z-10 w-24 h-24 bg-white">
        <img
          v-if="event.brand"
          :src="`/imgs/logos/event-brands/${event.brand}.jpg`"
        >
      </div>
    </div>
    <div
      v-if="showDateSquare"
      class="absolute top-0 left-0 -ml-2 flex flex-col bg-white shadow-lg font-sans uppercase text-4xl font-medum text-center w-16 py-1 pb-5 -mt-4 leading-[1.5rem]"
    >
      <span class="text-xs font-light">{{ getEventMonthAbbr(event) }}</span>
      <span>{{ getEventDayNumber(event) }}</span>
      <span v-if="event.event_occurance_type !== 'single'" class="text-2xs pt-2 -mb-3">&amp; more</span>
    </div>

    <div
      class="w-full bg-white flex-grow p-10 py-10 flex flex-col justify-start text-left"
    >
      <div class="h-24 overflow-hidden">
        <h3 class="mb-2 headline-3 uppercase text-gray-800 line-clamp-3">
          {{ event.title }}
        </h3>
      </div>
      <div>
        <h5 class="text-glueblue-600 uppercase" v-html="event.date_range ?? ''" />
        <div class="mb-6 flex flex-col space-y-0.5">
          <div class="flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
            </svg>

            <div class="font-sans2 text-sm text-zinc-600 space-x-1">
              <span v-if="event.event_occurance_type === 'single'">{{ startDate }}</span>
              <div v-if="event.event_occurance_type === 'consecutive'">
                <span>
                  {{ formatDate(event.dates_of_occurence[0]) }} - {{ formatDate(event.dates_of_occurence[event.dates_of_occurence.length - 1]) }}
                </span>
              </div>
              <div v-if="event.event_occurance_type === 'nonconsecutive'">
                <span v-for="(eventOccuranceDate, key) in event.dates_of_occurence" :key="eventOccuranceDate + key" class="block">
                  {{ formatDate(eventOccuranceDate) }}
                </span>
              </div>
            </div>
          </div>
          <div v-if="!event.hide_time" class="flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="12" r="9" /><path d="M12 7v3.764a2 2 0 0 0 1.106 1.789L16 14" /></g></svg>
            <div class="font-sans2 text-sm text-zinc-600 space-x-1">
              <template>
                <span>|</span>
                <span>Begins: {{ startTime }}</span>
                <template v-if="!event.hide_end_time">
                  <span>|</span>
                  <span>Ends: {{ endTime }}</span>
                </template>
              </template>
            </div>
          </div>
          <div class="flex items-center space-x-2 text-sm h-10">
            <svg v-if="(getVenueUrl(event.venue) && event.venue) || event.venue" xmlns="http://www.w3.org/2000/svg" class="-ml-[3px] w-7 h-7" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M5 9.923c0 4.852 4.244 8.864 6.123 10.402c.27.22.405.332.606.388c.156.044.386.044.542 0c.201-.056.336-.167.606-.388C14.756 18.787 19 14.775 19 9.923a6.885 6.885 0 0 0-2.05-4.895A7.04 7.04 0 0 0 12 3a7.04 7.04 0 0 0-4.95 2.028A6.884 6.884 0 0 0 5 9.923Z" /><path d="M10 9a2 2 0 1 0 4 0a2 2 0 0 0-4 0Z" /></g></svg>
            <div>
              <a
                v-if="getVenueUrl(event.venue) && event.venue"
                :href="getVenueUrl(event.venue)"
                class="font-sans2 text-glueblue-500 font-light"
              >
                {{ getVenueName(event.venue) }}
              </a>
              <div v-else-if="event.venue" class="font-sans2 font-light">
                {{ getVenueName(event.venue) }}
              </div>
            </div>
          </div>
          <div class="mt-3 font-light line-clamp-4 font-sans2 text-sm h-[85px]" v-html="event.content" />
        </div>
        <div class="flex space-x-1">
          <template
            v-for="(link, linkKey) in event.links"
            :key=" `event-${event.id}-${event.start_date}-link-${linkKey}`"
          >
            <button
              v-if="link.type === 'href'"
              class="block glue-btn uppercase body-11 tracking-widest py-1 px-4"
              :class="{
                'btn-ghost': link.type !== 'booking',
                'bg-glueblue-600 text-white border-zinc-600': link.type === 'booking',
              }"
              @click="selectEvent(linkKey)"
            >
              {{ link.text }}
            </button>
            <a
              v-if="link.type === 'booking'"
              class="block glue-btn uppercase body-11 tracking-widest py-1 px-4"
              :class="{
                'btn-ghost': link.type !== 'booking',
                'bg-glueblue-600 text-white border-zinc-600': link.type === 'booking',
              }"
              :href="link.href"
            >
              {{ link.text }}
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~/assets/sass/atlantisbahamas.scss';
</style>
