<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixMediaFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

const props = defineProps<{
  inView: boolean
  fields: {
    textAppearsOn: VoixSelectFieldInterface
    imageSize: VoixSelectFieldInterface
    showVerticalRule: VoixCheckboxFieldInterface
    title: VoixTextFieldInterface
    subtitle: VoixTextFieldInterface
    titleSize: VoixSelectFieldInterface
    copy: VoixWysiwygFieldInterface
    media: VoixMediaFieldInterface
  }
}>()

const imageSize = computed(() => {
  if (props.fields.textAppearsOn.value === 'top' || props.fields.textAppearsOn.value === 'bottom') {
    if (props.fields.imageSize.value === 'lg')
      return 1200
    else
      return 600
  }

  // Left or Right
  if (props.fields.imageSize.value === 'lg')
    return 682
  else
    return 350
})
defineSlice({
  name: { label: 'Paranza Text Flanking Image', group: 'Copy', layouts: ['Paranza'] },
  preview: 'SlicesParanzaTextFlankingImage.jpg',
  tags: ['Copy', 'Media'],
  fields: {
    textAppearsOn: { type: 'select', label: 'Text appears on', default: 'left', options: { left: 'Left', right: 'Right', top: 'Top', bottom: 'Bottom' }, name: 'textAppearsOn' },
    imageSize: { type: 'select', label: 'Image Size', default: 'lg', options: { sm: 'Small', lg: 'Large' }, name: 'imageSize' },
    title: { type: 'text', label: 'Title', name: 'title' },
    subtitle: { type: 'text', label: 'Subtitle', name: 'subtitle' },
    titleSize: { type: 'select', label: 'Title Size', default: 'md', options: { sm: 'Small', md: 'Medium', lg: 'Large' }, name: 'titleSize' },
    showVerticalRule: { type: 'checkbox', label: 'Show Vertical Rule', default: true, name: 'showVerticalRule' },
    copy: { type: 'wysiwyg', label: 'Copy', name: 'copy' },
    media: { type: 'media', label: 'Media', default: '\/imgs\/fpo.jpg', fit: 'cover', position: 'center', breakpoints: { default: { width: 682, height: 454 } }, name: 'media' },
  },
  slots: [],
})
</script>

<template>
  <div
    class="py-12 px-8 md:px-0"
  >
    <div
      class="grid container max-w-5xl"
      :class="{
        'grid-cols-1': fields.textAppearsOn.value === 'bottom' || fields.textAppearsOn.value === 'top',
        'md:grid-cols-12': fields.textAppearsOn.value === 'left' || fields.textAppearsOn.value === 'right',
      }"
    >
      <div
        class="flex items-center" :class="{
          'md:col-span-4': fields.imageSize.value === 'lg',
          'md:col-span-8': fields.imageSize.value === 'sm',
          'md:col-start-5': fields.textAppearsOn.value === 'right' && fields.imageSize.value === 'sm',
          'md:col-start-9': fields.textAppearsOn.value === 'right' && fields.imageSize.value === 'lg',
          'md:row-start-2': fields.textAppearsOn.value === 'bottom',
        }"
      >
        <div
          class="flex" :class="{
            'md:pl-4': fields.textAppearsOn.value === 'left',
            'md:pr-4': fields.textAppearsOn.value === 'right',
            'md:pt-4': fields.textAppearsOn.value === 'bottom',
            'md:pb-4': fields.textAppearsOn.value === 'top',
          }"
        >
          <div
            class="duration-[2s] ease-out"
            :class="{
              'md:px-8': fields.textAppearsOn.value === 'left' || fields.textAppearsOn.value === 'right',
              'translate-y-32 opacity-0': !inView,
            }"
          >
            <RestaurantMicroChromeTitle
              :title="fields.title.value" :subtitle="fields.subtitle.value"
              :titlesize="fields.titleSize.value"
            />

            <RestaurantMicroChromeVerticalRule v-if="fields.showVerticalRule.value" />

            <VoixWysiwyg
              v-if="fields.copy.value" :field="fields.copy"
              class="mt-3 prose prose-sm prose-zinc leading-snug font-light"
              :width="imageSize"
              :height="imageSize"
            />
          </div>
        </div>
      </div>
      <div
        :class="{
          'col-span-4': fields.imageSize.value === 'sm',
          'col-span-8': fields.imageSize.value === 'lg',
          'row-start-1 col-start-1': fields.textAppearsOn.value === 'right',
        }"
      >
        <VoixMedia
          v-if="fields.media" :field="fields.media" class="h-full w-full duration-[2s] ease-out"
          :width="imageSize"
          :height="imageSize"
          :class="{
            'opacity-0 translate-y-32': !inView,
            'opacity-100': inView,
          }"
        />
      </div>
    </div>
  </div>
</template>
