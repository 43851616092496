<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { Swiper as SwiperType } from 'swiper'

import type { SliceInterface, VoixTextFieldInterface } from '@voix/types'

// Import Swiper styles
import 'swiper/css'

defineProps<{
  slice: SliceInterface
  fields: {
    title: VoixTextFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Barbie Circle Slider', group: 'Barbie', layouts: ['Barbie'] },
  preview: 'SlicesBarbieCircleSlider.jpg',
  slots: [{ name: 'default', allowedElements: ['SlicesBarbieCircleSlide'] }],
  description: 'Title with a slider of images in a circle',
  fields: {
    title: {
      label: 'Title',
      type: 'text',
      default: 'Make it a beach day!',
    },
  },
  templates: [{
    label: 'Slider Container',
    fields: {
      title: {
        value: 'Make it a beach day!',
      },
    },
  }],
})

const swiper = ref<SwiperType | null>(null)

function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}

function next() {
  if (swiper.value)
    swiper.value.slideNext()
}

function prev() {
  if (swiper.value)
    swiper.value.slidePrev()
}
</script>

<template>
  <div class="py-6">
    <div class="container mx-auto">
      <div class="text-center">
        <div class="text-5xl font-barbie-headline uppercase text-barbie-100">
          {{ fields.title.value }}
        </div>
      </div>
      <div class="flex justify-center">
        <div v-if="slice.elements">
          <Swiper
            :slides-per-view="6"
            :space-between="10"
            :breakpoints="{
              640: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 6,
              },
            }"
            class="w-[318px] md:w-[424px] lg:w-[636px]  mt-6"
            @swiper="onSwiper"
          >
            <SwiperSlide v-for="(s, key) in slice.elements" :key="key" class="w-auto">
              <VoixSlice :slice="s as SliceInterface" :slice-index="key" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div
        class="mt-3 pointer-events-none flex items-center space-x-2 mx-auto w-[318px] md:w-[424px] lg:w-[636px]"
      >
        <button
          class="pointer-events-auto flex items-center"
          @click="prev"
        >
          <div class="w-6 h-6 rounded-full flex justify-center items-center text-gray-700 bg-white hover:bg-barbie-500 hover:text-white duration-100 border border-gray-300">
            <Icon class="w-5 pointer-events-none" name="heroicons:chevron-left-20-solid" />
          </div>
        </button>
        <button
          class="pointer-events-auto flex items-center"
          @click="next"
        >
          <div class="w-6 h-6 rounded-full flex justify-center items-center text-gray-700 bg-white hover:bg-barbie-500 hover:text-white duration-100 border border-gray-300">
            <Icon class="w-5 pointer-events-none" name="heroicons:chevron-right-20-solid" />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.swiper-slide{
 width: fit-content;
}
</style>
