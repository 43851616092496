<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixMediaFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

const props = defineProps<{
  fields: {
    image: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    item1: VoixWysiwygFieldInterface
    item2: VoixWysiwygFieldInterface
    item3: VoixWysiwygFieldInterface
    item4: VoixWysiwygFieldInterface
    bottomCopy: VoixWysiwygFieldInterface
    showBookNowButton: VoixCheckboxFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Image over features', group: 'Summer 2024', layouts: ['Summer2024'] },
  description: 'Big image that has two blocks of content below it',
  preview: 'SlicesSummerTwentyTwentyFourImageOverFeatures.jpg',
  tags: ['Media', 'Copy'],

  fields: {
    image: {
      label: 'Image',
      type: 'media',
      breakpoints: {
        default: {
          width: 1600,
          height: 800,
        },
      },
    },
    title: {
      label: 'TopTitle',
      type: 'text',
      default: 'Discover your ultimate escape',
    },
    copy: {
      label: 'Copy above items',
      type: 'wysiwyg',
      default: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      enabled: true,
    },

    item1: {
      label: 'Item 1',
      type: 'wysiwyg',
      enabled: true,
    },

    item2: {
      label: 'Item 2',
      type: 'wysiwyg',
      enabled: true,
    },

    item3: {
      label: 'Item 3',
      type: 'wysiwyg',
      enabled: true,
    },

    item4: {
      label: 'Item 4',
      type: 'wysiwyg',
      enabled: true,
    },

    bottomCopy: {
      label: 'Copy below items',
      type: 'wysiwyg',
      default: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      enabled: false,
    },

    showBookNowButton: {
      label: 'Show Book Now Button',
      type: 'checkbox',
      default: true,
    },

  },
  templates: [{
    label: 'Main Example',
    fields: {
      image: {
        value: 'https://images.unsplash.com/photo-1627310000000-0e7b3b7b3b3b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwyMDI0NjN8MHwxfGFsbHwxfHx8fHx8fHx8fHwxNjI3MzEwNjIw&ixlib=rb-1.2.1&q=80&w=1600',
      },
    },
  }],
})

const gridCols = computed(() => {
  // Calculate the number of columns based on if each item is enabled
  const enabledItems = [props.fields.item1, props.fields.item2, props.fields.item3, props.fields.item4].filter(item => item.enabled)

  return [`grid-cols-${enabledItems.length / 2}`, `md:grid-cols-${enabledItems.length}`]
})
</script>

<template>
  <div class="bg-summer2024-300 text-slate-800 px-8 pt-24">
    <div class="container mx-auto">
      <div class="text-4xl uppercase font-sans font-light text-center pb-12 ">
        {{ fields.title.value }}
      </div>
      <div class="overflow-hidden rounded-md border border-summer2024-100 w-full aspect-video">
        <VoixMedia :field="fields.image" class="w-full h-full object-cover" />
      </div>
      <div class="max-w-4xl py-8 font-light font-sans2 container mx-auto text-center text-lg">
        <VoixWysiwyg :field="fields.copy" />

        <div class="mt-8 grid gap-8 text-left py-4" :class="gridCols">
          <div>
            <VoixWysiwyg :field="fields.item1" />
          </div>
          <div>
            <VoixWysiwyg :field="fields.item2" />
          </div>
          <div>
            <VoixWysiwyg :field="fields.item3" />
          </div>
          <div>
            <VoixWysiwyg :field="fields.item4" />
          </div>
        </div>

        <VoixWysiwyg :field="fields.bottomCopy" class="mt-8" />

        <BookingBookNow v-show="fields.showBookNowButton.value">
          <button class="mt-8 text-summer2024-600 italic font-serif w-full p-2 text-4xl underline underline-offset-4 tracking-wide">
            Book Now
          </button>
        </BookingBookNow>
      </div>
      <div class="tailwind-hydrator" />
    </div>
  </div>
</template>

<style lang="scss">
.tailwind-hydrator {
  @apply md:grid-cols-1 ;
}
.tailwind-hydrator {
  @apply md:grid-cols-2 ;
}
.tailwind-hydrator {
  @apply md:grid-cols-3 ;
}
.tailwind-hydrator {
  @apply md:grid-cols-4;
}
</style>
