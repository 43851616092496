<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixLinkFieldInterface, VoixMediaFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

const props = defineProps<{
  fields: {
    image: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    description: VoixWysiwygFieldInterface
    details: VoixWysiwygFieldInterface
    detailsTitle: VoixTextFieldInterface
    detailsShowclixId: VoixTextFieldInterface
    detailsButton: VoixLinkFieldInterface
    detailsAdvancedEventListener: VoixCheckboxFieldInterface
    details2: VoixWysiwygFieldInterface
    detailsTitle2: VoixTextFieldInterface
    detailsLink2: VoixLinkFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Event Details', group: 'Events', layouts: ['Npiwff'] },
  description: 'Event Details Slice',
  tags: ['Event'],
  fields: {
    image: {
      label: 'Image',
      type: 'media',
      breakpoints: {
        default: {
          width: 408,
          height: 513,
        },
      },
    },
    title: {
      type: 'text',
      label: 'Title',
    },
    description: {
      type: 'wysiwyg',
      label: 'Description',
    },
    detailsTitle: {
      type: 'text',
      label: 'Title',
      group: 'Details',
    },
    details: {
      type: 'wysiwyg',
      label: 'Details',
      group: 'Details',
    },
    detailsButton: {
      type: 'link',
      label: 'Link',
      group: 'Details',
      enabled: false,
    },
    detailsShowclixId: {
      type: 'text',
      label: 'Showclix ID',
      group: 'Details',
      default: 'f47c6735-d980-47f7-a682-a6fc5dd40e89',
      description: 'You will have to reload the page after changing this value based on how showclix works.',
    },
    detailsAdvancedEventListener: {
      type: 'checkbox',
      label: 'Advanced Event Listener',
      group: 'Details',
    },
    detailsTitle2: {
      type: 'text',
      label: 'Title',
      group: 'Details 2',
    },
    details2: {
      type: 'wysiwyg',
      label: 'Details',
      group: 'Details 2',
    },
    detailsLink2: {
      type: 'link',
      label: 'Link',
      group: 'Details 2',
      enabled: true,
    },
  },
  templates: [{
    label: 'Event Details',
    fields: {
      title: { value: 'Nobu Dinner With Nobu' },
      description: { value: `Description: Savor an extraordinary evening at Nobu with a meticulously curated dinner by the culinary visionary himself, Nobu Matsuhisa. At this exclusive dining experience, chef Nobu showcases his signature Japanese-Peruvian fusion cuisine in a way that is nothing short of exceptional. Don't miss your chance to dine with a legend!` },
      image: { value: 'https://atlantisbahamas.imgix.net/aws/media/npiwffmedia/chef.png?w=408&h=513&fit=crop' },
      detailsTitle: { value: 'WHEN' },
      detailsTitle2: { value: 'WHERE' },
      details: { value: '<p class="text-lg"><strong>DATE:</strong> MARCH 12 <br> <strong>TIME:</strong> 8PM - 10PM</p>' },
      details2: { value: '<p class="text-lg"><strong>VENUE:</strong> NOBU <br> LOCATED AT THE ROYAL</p>' },
      detailsLink2: { value: { href: 'https://www.atlantisbahamas.com/wine-and-food-festival', target: '_blank', text: 'View Menu' } },
    },
  }],
})

onMounted(() => {
  // Showclix Widget
  const widget = document.getElementById(`showclix-widget-${props.fields.detailsShowclixId.value}`)
  const script = document.createElement('script')
  script.src = `https://www.showclix.com/embed/${props.fields.detailsShowclixId.value}`
  script.async = true

  // Add Widget to DOM
  if (widget)
    widget.appendChild(script)

  // Advanced Event Listener
  if (widget && props.fields.detailsAdvancedEventListener.value) {
    const advancedEventListenerScript = document.createElement('script')
    advancedEventListenerScript.innerHTML = `
        var marketingPort = null;
        window.addEventListener("message", function(e) {
          console.log('showclix event', e)
          if (e.data == "embedPort" && e.ports) {
            marketingPort = e.ports[0];
            marketingPort.onmessage = function(embedMessage) {
              console.log("Handle Embed Marketing Data", embedMessage.data);
            }
          }
        });
      `
    widget.appendChild(advancedEventListenerScript)
  }
})
</script>

<template>
  <div class="p-12 pb-20 relative overflow-hidden">
    <!-- Image, Title & Description -->
    <div class="w-full lg:grid lg:gap-0 lg:grid-cols-2 relative z-10">
      <!-- Image -->
      <div class="flex justify-center mr-0 lg:mr-28 2xl:mr-0">
        <VoixMedia :field="fields.image" class="max-h-[513px]" />
      </div>

      <!-- Title & Description -->
      <div class="mt-8 lg:mt-0 lg:h-[513px] lg:grid lg:gap-0 lg:grid-rows-2 relative lg:right-20 lg:items-end">
        <!-- Title -->
        <div class="spacer w-full h-full hidden lg:block" />
        <div class="w-full h-fit font-wff-headline-2 text-wff-primary text-center lg:text-left text-5xl xl:text-6xl font-bold uppercase tracking-widest leading-tight">
          {{ fields.title.value }}
        </div>

        <!-- Description -->
        <div class="spacer w-full h-6 lg:h-12" />
        <VoixWysiwyg
          class="w-full h-fit lg:max-w-xl text-wff-primary text-center lg:text-left text-lg"
          :field="fields.description"
        />
      </div>
    </div>

    <!-- Spacer -->
    <div class="spacer w-full hidden md:block md:h-12 relative z-10" />

    <!-- Details -->
    <div class="w-full md:flex-none md:grid md:gap-0 md:grid-cols-1 lg:grid-cols-2 relative md:right-0 lg:right-20 z-10">
      <!-- Left -->
      <div class="spacer hidden lg:block w-full h-52" />

      <!-- Right -->
      <div class="w-full flex flex-col justify-self-center lg:justify-self-start md:flex-none md:grid md:gap-0 md:grid-cols-2 md:grid-rows-1 md:w-fit">
        <!-- When -->
        <div class="w-fit mt-10 mr-0 md:mt-0 md:mr-3 xl:mr-16">
          <!-- Title -->
          <div class="w-full font-wff-headline text-wff-secondary text-2xl md:text-3xl font-bold tracking-widest">
            {{ fields.detailsTitle.value }}
          </div>

          <!-- Details -->
          <VoixWysiwyg
            class="w-full font-wff-headline text-wff-primary tracking-widest"
            :field="fields.details"
          />

          <!-- ShowClix Link -->
          <div v-if="!fields.detailsButton.enabled" :id="`showclix-widget-${fields.detailsShowclixId.value}`" class="inline-block mt-11 max-w-[14.4rem]" />

          <!-- Link -->
          <a
            v-if="fields.detailsButton.enabled"
            class="font-showclix inline-block text-center rounded-full border-2 py-5 mt-11 border-wff-primary bg-wff-primary text-white duration-200 uppercase tracking-widest min-w-52"
            :href="fields.detailsButton.value?.href"
            :target="fields.detailsButton.value?.target"
          >
            {{ fields.detailsButton.value?.text }}
          </a>
        </div>

        <!-- Where -->
        <div class="w-fit mt-10 mr-0 md:mt-0 md:mr-3 xl:mr-16">
          <!-- Title -->
          <div class="w-full font-wff-headline text-wff-secondary text-2xl md:text-3xl font-bold tracking-widest">
            {{ fields.detailsTitle2.value }}
          </div>

          <!-- Details -->
          <VoixWysiwyg
            class="w-full font-wff-headline text-wff-primary"
            :field="fields.details2"
          />

          <!-- Link -->
          <a
            v-if="fields.detailsLink2.enabled"
            class="inline-block text-white text-center text-lg rounded-full border-2 px-[40px] py-[13px] mt-11 border-wff-secondary bg-wff-secondary duration-200 min-w-52"
            :href="fields.detailsLink2.value?.href"
            :target="fields.detailsLink2.value?.target"
          >
            {{ fields.detailsLink2.value?.text }}
          </a>
        </div>
      </div>
    </div>

    <!-- Background -->
    <div class="grid gap-0 grid-cols-3 w-full h-full absolute top-0 left-0 z-0">
      <!-- Left -->
      <div class="col-span-1 hidden lg:block">
        <svg viewBox="0 0 149 372" class="h-[159%] absolute bottom-[-528px] left-[-22px]">
          <path style="fill:#F48C94;" d="m23.2 361.4c-6.1 0-12-1-17.5-2.9v11.6h121.7c10.5-17.6 16.5-38 16.5-59.9 0-65.5-54.1-118.8-120.7-118.8q-8.9 0-17.5 1.2v69.4c5.5-1.9 11.4-2.9 17.5-2.9 29.1 0 52.7 22.9 52.7 51.1 0 28.3-23.6 51.2-52.7 51.2z" />
          <path style="fill:#5dc2c9;" d="m33.6 3.5c4.7 0 8.6 3.8 8.6 8.6 0 4.7-3.9 8.5-8.6 8.5-4.8 0-8.6-3.8-8.6-8.5 0-4.8 3.8-8.6 8.6-8.6z" />
          <path style="fill:#2E3192;" d="m5.7 163.8v-102.3h0.6c28.3 0 51.2 22.9 51.2 51.1 0 28.3-22.9 51.2-51.2 51.2q-0.3 0-0.6 0z" />
          <path style="fill:#2E3192;" d="m78.7 191.7c-11.2 3-14.1 13.3-14.1 32.5l4.1 50.7h4.1v-51.2c0-1.2 1-2.2 2.3-2.2 1.2 0 2.2 1 2.2 2.2v51.2h4v-51.2c0-1.2 1-2.2 2.2-2.2 1.2 0 2.3 1 2.3 2.2v51.2h3.9v-51.2c0-1.2 1-2.2 2.2-2.2 1.3 0 2.3 1 2.3 2.2v51.2h4.1l4.2-50.7c0-19.2-3-29.5-14.1-32.5v-76.7c1.4-14.5 8.9-41.9 8.9-53.5 0-9.5-2.9-23.5-13.8-23.5-10.1 0-13.7 14-13.7 23.5 0 11.3 6.3 37.1 8.9 53.5z" />
        </svg>
      </div>

      <!-- Right -->
      <div class="col-span-1 lg:col-span-2 w-screen bg-[#ECF7FA]">
        <svg viewBox="0 0 273 352" class="h-full absolute bottom-[-500px] right-[-800px] opacity-[2%] md:bottom-[-120px] md:right-[-383px] lg:opacity-5">
          <path d="m134.7 430.5h4v-213.7-197.4h-4z" />
          <path d="m72.2 154.1v277.7h-4v-277.7z" />
          <path d="m216 110.5l4.7-0.5c-3.8-43.3-40-81.7-86-91.3l-1 4.6c44.1 9.2 78.7 45.8 82.3 87.2z" />
          <path d="m133.3 18.9l1.8 4.3c30.7-12.6 58.6-15.5 82-8.8q2.4 0.7 4.7 1.6 3.3 1.1 6.4 2.6 2.4 1 4.7 2.3 1.9 1 3.7 2.1 1.4 0.8 2.8 1.8 2.4 1.6 4.7 3.3c14.7 11.4 20.9 24.2 21 24.4l4.3-2c-0.4-0.7-7.7-15.8-25.3-28.2q-2.3-1.6-4.7-3.1-0.2-0.1-0.3-0.2-2.9-1.8-6.2-3.4-2.2-1.1-4.7-2.2-3-1.3-6.4-2.4-2.3-0.7-4.7-1.4c-20-5.5-47.6-5.6-83.8 9.3z" />
          <path d="m255.2 34v18.9h-4.7v-18.9z" />
          <path d="m239.4 19.2v33.2h4.7v-30.1q-2.3-1.6-4.7-3.1z" />
          <path d="m228.2 13.4v38.4h4.7v-36.2q-2.2-1.1-4.7-2.2z" />
          <path d="m217.1 9.6v41.7h4.7v-40.3q-2.3-0.7-4.7-1.4z" />
          <path d="m210.7 9.5v41.2h-4.7v-41.2z" />
          <path d="m199.6 9v41.9h-4.7v-41.9z" />
          <path d="m199.6 60.2v50h-4.7v-50z" />
          <path d="m188.4 10.7v99h-4.7v-99z" />
          <path d="m177.3 10.1v99.6h-4.7v-99.6z" />
          <path d="m166.2 12.9v96.8h-4.7v-96.8z" />
          <path d="m155 14.6v95.1h-4.7v-95.1z" />
          <path d="m209.6 73.9v36.3h-4.7v-36.3z" />
          <path d="m56.4 111.6c3.6-41.4 38.2-78 82.2-87.1l-0.9-4.6c-46 9.5-82.2 47.9-86 91.3z" />
          <path d="m139.1 20l-1.8 4.3c-30.7-12.6-58.6-15.5-82-8.7q-2.4 0.6-4.7 1.5-3.3 1.1-6.5 2.6-2.4 1.1-4.7 2.3-1.8 1-3.6 2.1-1.5 0.9-2.8 1.8-2.5 1.6-4.7 3.3c-14.7 11.4-21 24.3-21 24.4l-4.3-2c0.4-0.7 7.7-15.8 25.3-28.2q2.2-1.6 4.7-3.1 0.2-0.1 0.3-0.2 2.9-1.8 6.1-3.4 2.3-1.1 4.7-2.1 3.1-1.3 6.5-2.5 2.2-0.7 4.7-1.4c19.9-5.5 47.5-5.6 83.8 9.3z" />
          <path d="m21.9 35.1v19h-4.7v-19z" />
          <path d="m33 20.3v33.2h-4.7v-30.1q2.2-1.6 4.7-3.1z" />
          <path d="m44.1 14.6v38.3h-4.7v-36.2q2.3-1.1 4.7-2.1z" />
          <path d="m55.3 10.7v41.7h-4.7v-40.3q2.2-0.7 4.7-1.4z" />
          <path d="m66.4 10.7v41.1h-4.7v-41.1z" />
          <path d="m77.5 10.1v41.9h-4.7v-41.9z" />
          <path d="m77.5 61.3v50.1h-4.7v-50.1z" />
          <path d="m88.6 11.8v99h-4.7v-99z" />
          <path d="m99.8 11.2v99.6h-4.7v-99.6z" />
          <path d="m110.9 14v96.8h-4.7v-96.8z" />
          <path d="m122 15.7v95.1h-4.7v-95.1z" />
          <path d="m67.5 75v36.4h-4.7v-36.4z" />
          <path d="m109.8 197.6l2.3-0.3c-1.9-21.5-19.9-40.7-42.8-45.4l-0.4 2.3c21.9 4.5 39.1 22.8 40.9 43.4z" />
          <path d="m68.6 152l0.9 2.1c15.3-6.3 29.2-7.7 40.9-4.3q1.1 0.3 2.3 0.7 1.6 0.6 3.2 1.3 1.2 0.5 2.3 1.2 1 0.5 1.9 1 0.7 0.4 1.3 0.9 1.3 0.8 2.4 1.7c7.3 5.6 10.4 12 10.5 12.1l2.1-1c-0.2-0.4-3.8-7.9-12.6-14.1q-1.1-0.7-2.4-1.5 0 0-0.1-0.1-1.4-0.9-3.1-1.7-1.1-0.5-2.3-1-1.5-0.7-3.2-1.3-1.1-0.3-2.3-0.7c-10-2.7-23.7-2.8-41.8 4.7z" />
          <path d="m129.3 159.5v9.4h-2.3v-9.4z" />
          <path d="m121.4 152.1v16.5h2.4v-15q-1.1-0.7-2.4-1.5z" />
          <path d="m115.9 149.3v19.1h2.3v-18.1q-1.1-0.5-2.3-1z" />
          <path d="m110.4 147.3v20.8h2.3v-20.1q-1.1-0.3-2.3-0.7z" />
          <path d="m107.2 147.3v20.5h-2.4v-20.5z" />
          <path d="m101.6 147v20.9h-2.3v-20.9z" />
          <path d="m101.6 172.5v25h-2.3v-25z" />
          <path d="m96.1 147.9v49.3h-2.4v-49.3z" />
          <path d="m90.5 147.6v49.6h-2.3v-49.6z" />
          <path d="m85 149v48.2h-2.3v-48.2z" />
          <path d="m79.5 149.8v47.4h-2.4v-47.4z" />
          <path d="m106.6 179.4v18.1h-2.3v-18.1z" />
          <path d="m30.3 198.1c1.8-20.6 19.1-38.8 41-43.4l-0.5-2.3c-22.9 4.8-40.9 23.9-42.8 45.5z" />
          <path d="m71.5 152.5l-0.9 2.2c-15.3-6.3-29.2-7.8-40.8-4.4q-1.2 0.3-2.4 0.8-1.6 0.5-3.2 1.3-1.1 0.5-2.3 1.1-0.9 0.5-1.8 1.1-0.7 0.4-1.4 0.8-1.2 0.9-2.3 1.7c-7.4 5.7-10.5 12.1-10.5 12.2l-2.1-1.1c0.1-0.3 3.8-7.8 12.6-14q1.1-0.8 2.3-1.5 0.1-0.1 0.2-0.1 1.4-0.9 3-1.7 1.1-0.6 2.3-1.1 1.6-0.6 3.2-1.2 1.2-0.4 2.4-0.7c9.9-2.7 23.7-2.8 41.7 4.6z" />
          <path d="m13.2 160.1v9.4h-2.4v-9.4z" />
          <path d="m18.7 152.7v16.5h-2.3v-15q1.1-0.8 2.3-1.5z" />
          <path d="m24.2 149.8v19.1h-2.3v-18q1.1-0.6 2.3-1.1z" />
          <path d="m29.8 147.9v20.7h-2.4v-20q1.2-0.4 2.4-0.7z" />
          <path d="m35.3 147.9v20.5h-2.3v-20.5z" />
          <path d="m40.9 147.6v20.9h-2.4v-20.9z" />
          <path d="m40.9 173.1v24.9h-2.4v-24.9z" />
          <path d="m46.4 148.4v49.3h-2.3v-49.3z" />
          <path d="m51.9 148.1v49.6h-2.3v-49.6z" />
          <path d="m57.5 149.5v48.2h-2.4v-48.2z" />
          <path d="m63 150.4v47.3h-2.3v-47.3z" />
          <path d="m35.9 179.9v18.1h-2.4v-18.1z" />
        </svg>
      </div>
    </div>
  </div>
</template>

<style scoped>
.font-showclix {
  font-family: "Inter", Helvetica, Arial, sans-serif;
  font-size: 18px;
  letter-spacing: Normal;
}
</style>
