<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 15">
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="currentcolor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".8"
    >
      <path
        d="M0 11.667L16.557 11.667 16.557 8.728 0 8.728zM1.221 11.9L1.221 13.3M15.221 11.9L15.221 13.3M6.76 5.742H4.047c-.194 0-.35-.156-.35-.35v-1.39c0-.194.156-.35.35-.35H6.76c.193 0 .35.156.35.35v1.39c0 .194-.157.35-.35.35zM12.36 5.742H9.647c-.194 0-.35-.156-.35-.35v-1.39c0-.194.156-.35.35-.35h2.713c.193 0 .35.156.35.35v1.39c0 .194-.157.35-.35.35z"
        transform="translate(1 1)"
      />
      <path
        d="M3.671 4.9L1.896 4.9 0 8.75 16.557 8.75 14.661 4.9 12.771 4.9M9.271 4.9L7.171 4.9M12.77 4.9h1.75V.612c0-.386-.172-.612-.559-.612H2.596c-.387 0-.675.226-.675.613V4.9h1.75M7.171 4.9L9.271 4.9"
        transform="translate(1 1)"
      />
    </g>
  </svg>
</template>
