<script setup lang="ts">
import type { SliceInterface, VoixNumberFieldInterface, VoixTextFieldInterface } from '@voix/types'
import { resolveFieldsArray } from '@voix/composables/useField'

const props = defineProps<{
  slice: SliceInterface
  fields: {
    title: VoixTextFieldInterface
    columns: VoixNumberFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Logo Group', group: 'Branding', layouts: ['Npiwff'] },
  description: 'Logo Group',
  tags: ['Branding', 'Group'],
  slots: [{ name: 'default', allowedElements: ['SlicesWffLogo'] }],
  fields: {
    title: { type: 'text', label: 'Title' },
    columns: { type: 'number', label: 'Columns', default: 3 },
  },
  templates: [{ label: 'Logo Group', fields: { mode: { value: 'title' }, title: { value: 'Presenting Sponsor' } } }],
})

function randomSliceElements() {
  const elements = props.slice.elements
  if (!elements)
    return []

  return elements.sort(() => Math.random() - 0.5)
}
const randomSlices = computed(() => randomSliceElements())

function getLogoField(element: any) {
  const resolvedField = resolveFieldsArray(element)
  if (!resolvedField || !resolvedField.logo)
    return ''

  return resolvedField.logo
}
</script>

<template>
  <div class="flex flex-col items-center bg-blue-100 py-16">
    <div class="text-2xl uppercase text-center font-wff-headline font-bold">
      {{ fields.title.value }}
    </div>
    <div class="mt-12 container bg-white rounded-xl p-8">
      <div
        class="grid bg-white gap-8 xl:gap-16" :class="{
          'md:grid-cols-2 xl:grid-cols-3': parseInt(fields.columns.value, 10) === 3,
          'md:grid-cols-2': parseInt(fields.columns.value, 10) === 2,
        }"
      >
        <div v-for="element in randomSlices" :key="element.id" class="flex justify-center">
          <div class="overflow-hidden w-[323px] h-[150px] rounded-lg">
            <VoixMedia :field="getLogoField(element)" class="object-cover w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
