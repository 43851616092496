<script>
export default {
  name: 'DiningIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 50 34"
  >
    <defs>
      <path
        id="dining-icon-prefix-a"
        d="M0 0.622L6.504 0.622 6.504 28.541 0 28.541z"
      />
      <path
        id="dining-icon-prefix-c"
        d="M0.556 0.622L8.687 0.622 8.687 28.541 0.556 28.541z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path
        fill="currentcolor"
        stroke="#currentColor"
        stroke-width=".5"
        d="M24.392 30.311c-8.083 0-14.636-6.428-14.636-14.357 0-7.93 6.553-14.359 14.636-14.359 8.083 0 14.634 6.429 14.634 14.359S32.475 30.31 24.392 30.31m0-30.31C15.41 0 8.13 7.142 8.13 15.953c0 8.81 7.28 15.953 16.26 15.953 8.982 0 16.262-7.142 16.262-15.953S33.373 0 24.392 0"
        transform="translate(0 1)"
      />
      <path
        fill="currentcolor"
        stroke="#currentColor"
        stroke-width=".5"
        d="M24.392 25.526c-5.388 0-9.756-4.286-9.756-9.572 0-5.287 4.368-9.573 9.756-9.573s9.756 4.286 9.756 9.573c0 5.286-4.368 9.572-9.756 9.572m0-20.74c-6.287 0-11.383 5-11.383 11.168 0 6.167 5.096 11.167 11.383 11.167 6.286 0 11.383-5 11.383-11.167 0-6.168-5.097-11.168-11.383-11.168"
        transform="translate(0 1)"
      />
      <g transform="translate(0 1) translate(0 4.164)">
        <mask id="dining-icon-prefix-b" fill="currentcolor">
          <use xlink:href="#dining-icon-prefix-a" />
        </mask>
        <path
          fill="currentcolor"
          stroke="#currentColor"
          stroke-width=".5"
          d="M5.692.622c-.45 0-.814.357-.814.798v5.583h-.813V1.42c0-.441-.363-.798-.813-.798-.449 0-.813.357-.813.798v5.583h-.813V1.42c0-.441-.364-.798-.813-.798C.364.622 0 .979 0 1.42v7.178h2.439v19.145c0 .44.364.798.813.798.45 0 .813-.358.813-.798V8.598h2.44V1.42c0-.441-.364-.798-.813-.798"
          mask="url(#dining-icon-prefix-b)"
        />
      </g>
      <g transform="translate(0 1) translate(40.91 4.164)">
        <mask id="dining-icon-prefix-d" fill="currentcolor">
          <use xlink:href="#dining-icon-prefix-c" />
        </mask>
        <path
          fill="currentcolor"
          stroke="#currentColor"
          stroke-width=".5"
          d="M4.622 9.396c-1.325 0-2.44-1.643-2.44-3.589 0-1.947 1.115-3.59 2.44-3.59s2.44 1.643 2.44 3.59c0 1.946-1.115 3.59-2.44 3.59m4.066-3.59c0-2.856-1.822-5.185-4.066-5.185C2.378.622.556 2.95.556 5.807c0 2.512 1.408 4.61 3.253 5.08-.003.035-.003.07 0 .105v16.75c0 .441.365.799.813.799.449 0 .813-.358.813-.799v-16.75c.002-.035.002-.07 0-.104 1.846-.47 3.253-2.57 3.253-5.08"
          mask="url(#dining-icon-prefix-d)"
        />
      </g>
    </g>
  </svg>
</template>
