<script>
export default {
  name: 'QuickTips',

  props: {
    tips: {
      type: Array,
      required: true,
    },
  },
}
</script>

<template>
  <div class="py-8 bg-white">
    <div
      class="px-12 flex flex-col xl:flex-row space-y-8 md:space-y-4 lg:space-y-0 justify-between items-center"
    >
      <div class="headline-2">
        Some quick tips
      </div>
      <div
        class="flex flex-col lg:flex-row justify-end items-center space-y-8 lg:space-y-0 space-x-8"
      >
        <div
          v-for="(tip, key) in tips"
          :key="key"
          class="flex items-center space-x-3 justify-center"
        >
          <div class="p-4 rounded-full border-2 border-zinc-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 20.963 16"
              class="h-5 w-5"
            >
              <defs>
                <clipPath id="clip-path">
                  <path
                    id="Clip_2"
                    data-name="Clip 2"
                    d="M0,0H20.963V16H0Z"
                    transform="translate(16.97 19)"
                    fill="none"
                  />
                </clipPath>
              </defs>
              <g
                id="Check_Mark"
                data-name="Check Mark"
                transform="translate(-16.97 -19)"
                clip-path="url(#clip-path)"
              >
                <path
                  id="Fill_1"
                  data-name="Fill 1"
                  d="M20.278,0h0A.682.682,0,0,0,19.8.2L5.928,14.287l-4.7-6.313a.683.683,0,0,0-.953-.14.672.672,0,0,0-.14.943l5.177,6.95a.682.682,0,0,0,1.032.07L20.77,1.144A.672.672,0,0,0,20.76.192.682.682,0,0,0,20.279,0Z"
                  transform="translate(16.97 19)"
                  fill="#4d4d4f"
                />
              </g>
            </svg>
          </div>
          <div class="font-sans2 text-base font-light w-48">
            {{ tip }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
