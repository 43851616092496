<script setup lang="ts">
defineProps({
  fields: {
    type: Object as PropType<Record<string, any>>,
    default: () => {},
  },
  slice: {
    type: Object as PropType<Record<string, any>>,
    default: () => {},
  },
})

defineSlice({
  name: { label: 'Clipped Card', group: 'Cards', layouts: ['Atlantisbahamas', 'AtlantisMobileApp'] },
  preview: 'SlicesGlueGenericCardsClippedCard.jpg',
  description: 'Copy on top with image that is clipped on the left or right that also has copy',
  tags: ['Card'],
  fields: {
    copyTitle: { type: 'text', label: 'Copy Title' },
    copyDescription: { type: 'textarea', label: 'Copy Description' },
    image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 1300, height: 800 }, md: { width: 800, height: 492 }, default: { width: 600, height: 600 } } },
    imageTitle: { type: 'text', label: 'Image Title (Italics)' },
    imageSubTitle: { type: 'text', label: 'Image Sub-title' },
    imageDescription: { type: 'textarea', label: 'Image Description' },
    imageLink: { type: 'link', label: 'Image Button Link', enabled: false },
  },
  templates: [{ label: 'Clipped Card', fields: { copyDescription: { value: 'We\'ve got you covered. Park your ride at our Marina and take a load off at one of our restaurants, shops, or bars, all conveniently located just steps from the dock.' }, copyTitle: { value: 'Need a place to drop anchor?' }, image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/glue\/image.jpg' }, imageDescription: { value: 'Atlantis Marina is the premier facility in The Bahamas. From far and wide, captains sail to our docks for safe keeping along with endless amenities to enjoy.' }, imageSubTitle: { value: 'A great place to dock' }, imageTitle: { value: 'Atlantis Marina' } } }],
})

const windowWidth = ref(1200)

if (process.client) {
  windowWidth.value = window.innerWidth
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth
  })
}
</script>

<template>
  <div class="container mx-auto">
    <div class="relative">
      <div>
        <div class="flex">
          <div
            class="flex mb-4"
          >
            <div class="px-12 md:px-0">
              <div>
                <div class="w-full md:max-w-[50%] lg:max-w-[75%] headline mb-3">
                  {{ fields.copyTitle.value }}
                </div>
                <div class="w-full md:max-w-[50%] lg:max-w-[90%] body-lg mb-10">
                  {{ fields.copyDescription.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            class="relative"
          >
            <VoixMedia :field="fields.image" class="w-full h-full object-cover" />
            <div
              class="lg:absolute lg:bottom-0 lg:left-0 grid sm:grid-cols-6 gap-16 lg:grid-cols-12"
            >
              <div class="bg-white sm:col-span-6 col-span-4 xl:col-span-3 p-12 lg:translate-x-4 xl:translate-x-16 lg:-translate-y-4 xl:-translate-y-16">
                <div
                  class="font-serif opacity-75 italic text-3xl tracking-wider mb-1  text-red-500"
                >
                  {{ fields.imageTitle.value }}
                </div>
                <div class="headline-2 mb-2">
                  {{ fields.imageSubTitle.value }}
                </div>
                <div class="body">
                  {{ fields.imageDescription.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
