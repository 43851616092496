<script>
export default {
  name: 'MarineIcon',

  props: {
    devise: { type: Object },
    animal: {
      type: String,
      default: 'multiple',
    },
  },
}
</script>

<template>
  <div>
    <!-- Dolphin -->
    <svg
      v-if="animal === 'dolphins'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 55 40"
      class="w-9 h-8"
    >
      <path
        d="M7.9,18.076s1.523-1.084,4.822-.5,4.061,2.834,11.251,3.252c4.085.237.254-1.584.254-1.584a23.942,23.942,0,0,1-4.484-3.335,22.965,22.965,0,0,1,9.813,1.5c4.991,2,9.6,4.369,11.336,10.338.508,1.751-.253,3.5-.677,5.169a45.064,45.064,0,0,0-.592,6s3.126-5.316,5.5-5.253,6.937,2.751,6.937,2.751-1.09-3.313-5.583-7.171c-2.623-2.251-1.676-3.92-1.861-6.17a21.264,21.264,0,0,0-5.5-13.256c-4.737-5.587-6.768-5.086-6.768-5.086s3.3-3,3.3-3.585S32.081-.844,27.31.7A11.543,11.543,0,0,0,23.3,2.735,18.768,18.768,0,0,0,9.339,6.987c-6.853,5.5-5.16,7.671-6.26,9.088S-.3,18.742.034,19.493.034,22.828,7.9,18.076Z"
        transform="translate(0.677 0.708)"
        fill="none"
        stroke="#ea887b"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.4"
      />
    </svg>

    <!-- Stingray -->
    <svg
      v-if="animal === 'stingrays'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 55 55"
      class="w-8 h-8"
    >
      <path
        d="M20.632,53.2s-3.293-2.913-1.52-6.9,6.777-6.081,6.08-11.654-6.523-9.817-13.744-13.174C6.221,19.036,2.288,19.76.516,20.344a.392.392,0,0,1-.453-.584,12.914,12.914,0,0,1,1.885-2.41,34.921,34.921,0,0,1,9.627-6.524c5.764-2.85,6.144-6.4,6.46-8.233S19.3-.574,20.062.249s.76,4.814,3.547,5.13c2.2.251,3.166-3.8,3.61-4.623s1.267-1.013,1.583,0,.7,5.573,3.864,8.233,8.3,3.23,12.033,7.6,2.914,3.8,2.407,3.863-5.764-2.027-13.3,2.09-10,7.877-10,7.877"
        transform="translate(0.72 0.702)"
        fill="none"
        stroke="#ea887b"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.4"
      />
    </svg>

    <!-- Sea Lion -->
    <svg
      v-if="animal === 'seaLions'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 40"
      class="w-10 h-8"
    >
      <g transform="translate(0.732 0.623)">
        <path
          d="M37.295,32.055l3.035-2.472a17.332,17.332,0,0,0,3.918-17.251c-.717-2.088-.828-3.461,1.1-4.286s3.621-1.938,4.059-4.22c.626-3.258-2.12-1.428-4.777-2.153C42.155.995,41.764-.8,38.508.408S34.315,4.639,32.163,10.9c-3.012,8.767-6.752,9.7-12.028,10.219C11.252,22,8.217,23.044,2.478,28.264-2.835,33.1,1.382,36.949,5.9,38.318a14.17,14.17,0,0,0,5.2.648.527.527,0,0,0,.344-.888L8.584,35.051a.475.475,0,0,1,.346-.8l23-.053"
          transform="translate(0 0)"
          fill="none"
          stroke="#ea887b"
          stroke-miterlimit="10"
          stroke-width="1.4"
        />
        <path
          d="M8.782,0s.181,5.914,4.066,8.052c3.469,1.91,2.006,2.581.7,2.426s-4.662.258-7.481-2.323A36.414,36.414,0,0,0,0,3.768"
          transform="translate(27 27)"
          fill="none"
          stroke="#ea887b"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          d="M1.5.75A.75.75,0,1,1,.75,0,.75.75,0,0,1,1.5.75Z"
          transform="translate(40.5 3)"
          fill="none"
          stroke="#ea887b"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          d="M4.713,0a12.119,12.119,0,0,0,5.722,6.113c4.087,1.843,2.906,2.425,2.906,2.425S8.347,10.867,0,5.064"
          transform="translate(37.5 27)"
          fill="none"
          stroke="#ea887b"
          stroke-miterlimit="10"
          stroke-width="1"
        />
      </g>
    </svg>

    <IconsTurtleIcon v-if="animal === 'seaTurtles'" class="w-8 h-8 text-red-500" />

    <!-- Multiple Species -->
    <svg
      v-if="animal === 'multiple' || animal === 'sharks'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 45 45"
      class="w-8 h-8"
    >
      <defs>
        <clipPath id="multiple-species-clip-path">
          <path d="M0,0H45V3.348H0Z" fill="none" />
        </clipPath>
      </defs>
      <path
        d="M.563,32.544a.563.563,0,0,1,0-1.125h1.26V23.28a2.79,2.79,0,0,1,5.581,0v8.139h1.87V27.151a2.361,2.361,0,0,1,4.721,0v4.268h2.417V20.468a2.79,2.79,0,0,1,5.581,0V31.419h1.872V28.276a2.361,2.361,0,1,1,4.721,0v3.143h3.77V26.881l-6.232-6.233a2.673,2.673,0,1,1,3.78-3.78l2.452,2.452V15.4L26.124,9.17A2.673,2.673,0,1,1,29.9,5.389l2.452,2.452V2.813a2.813,2.813,0,0,1,5.627,0V7.842l2.452-2.452A2.674,2.674,0,0,1,44.217,9.17L37.984,15.4V19.32l2.452-2.452a2.673,2.673,0,1,1,3.78,3.78l-6.233,6.233v4.537h1.6V28.794a2.361,2.361,0,1,1,4.721,0v2.625h.131a.563.563,0,1,1,0,1.125Z"
        transform="translate(0 12.456)"
        fill="#eb8a7e"
      />
      <g>
        <g data-name="Fill-3 Clipped">
          <path d="M0,0H45V3.348H0Z" fill="none" />
          <g clip-path="url(#multiple-species-clip-path)">
            <path
              d="M.563,3.348A6,6,0,0,0,4.95,1.431,6.007,6.007,0,0,0,9.338,3.348a6.005,6.005,0,0,0,4.387-1.917,6.005,6.005,0,0,0,4.387,1.917A6,6,0,0,0,22.5,1.431a6.007,6.007,0,0,0,4.388,1.917,6,6,0,0,0,4.387-1.916,6.008,6.008,0,0,0,4.387,1.916A6.005,6.005,0,0,0,40.05,1.431a6,6,0,0,0,4.387,1.917.563.563,0,1,0,0-1.125,4.934,4.934,0,0,1-3.94-2,.562.562,0,0,0-.895,0,4.936,4.936,0,0,1-3.94,2,4.932,4.932,0,0,1-3.939-2,.582.582,0,0,0-.9,0,4.934,4.934,0,0,1-3.94,2,4.935,4.935,0,0,1-3.94-2,.582.582,0,0,0-.9,0,4.934,4.934,0,0,1-3.94,2,4.934,4.934,0,0,1-3.94-2,.563.563,0,0,0-.9,0,4.934,4.934,0,0,1-3.94,2,4.935,4.935,0,0,1-3.94-2,.582.582,0,0,0-.9,0,4.933,4.933,0,0,1-3.939,2,.562.562,0,1,0,0,1.125Z"
              transform="translate(0)"
              fill="#eb8a7e"
            />
          </g>
        </g>
      </g>
      <path
        d="M0,1.686a.563.563,0,1,0,1.125,0,.561.561,0,0,1,1.123,0,.563.563,0,1,0,1.125,0A1.687,1.687,0,0,0,0,1.686"
        transform="translate(16.845 14.753)"
        fill="#eb8a7e"
      />
      <path
        d="M5.1,23.165A1.149,1.149,0,0,1,4.883,21.5a5.631,5.631,0,0,0,1.15-4.552c-.482-.5-.884-.923-1.2-1.264A3.691,3.691,0,0,1,3.41,17.878a2.954,2.954,0,0,0-1.2,1.629.564.564,0,0,1-1.066.143c-.11-.239-2.457-5.417-.111-7.6-2.326-2.136,0-7.38.109-7.622a.562.562,0,0,1,1.068.146A2.962,2.962,0,0,0,3.41,6.2,3.687,3.687,0,0,1,4.835,8.389Q5.4,7.771,6.35,6.8a6.057,6.057,0,0,0-1.468-4.3A1.134,1.134,0,0,1,5.043.851C6.482-.52,12.106-1.1,19.446,6.083l.006.006,3.107,3.036a1.641,1.641,0,0,1,.268,2h.653a.562.562,0,0,1,.558.495,3.158,3.158,0,0,1-.905,2.633l-1.768,1.795c-.629.68-1.244,1.3-1.848,1.876l-.061.061c-.011.011-.023.021-.035.031-4.853,4.6-8.863,6-11.48,6A4.264,4.264,0,0,1,5.1,23.165Z"
        transform="translate(0.002 5.836)"
        fill="#eb8a7e"
      />
      <path
        d="M0,2.95A2.95,2.95,0,1,1,2.95,5.9,2.954,2.954,0,0,1,0,2.95Z"
        transform="translate(39.1 7.451)"
        fill="#eb8a7e"
      />
      <path
        d="M0,2.95A2.95,2.95,0,1,1,2.95,5.9,2.954,2.954,0,0,1,0,2.95Z"
        transform="translate(31.212 4.282)"
        fill="#eb8a7e"
      />
      <path
        d="M0,3.508A3.509,3.509,0,1,1,3.508,7.017,3.513,3.513,0,0,1,0,3.508Z"
        transform="translate(21.34 5.823)"
        fill="#eb8a7e"
      />
    </svg>
  </div>
</template>
