<script lang="ts" setup>
import dayjs from 'dayjs'
import { useUserStore } from '@voix/store/userStore'
import { getCurrentUser } from '@voix/composables/queries/useQueryCurrentUser'

defineProps({
  listing: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['deleteListing'])

const userStore = useUserStore()
await getCurrentUser()
const currentUser = userStore.currentUser

function formatDate(date: string) {
  return dayjs(date).format('MMMM D YYYY')
}
</script>

<template>
  <div class="bg-white shadow-lg p-12 rounded m-4 relative w-full">
    <h4 class="uppercase mb-1 text-glueblue-600">
      Unit {{ listing.unit }}
    </h4>
    <h3 class="uppercase text-sm mb-4 text-zinc-700">
      {{ listing.first_name }} {{ listing.last_name }}
    </h3>

    <h4>{{ listing.type }}</h4>

    <hr class="mb-6">
    <p class="font-bold mb-1 uppercase text-xs">
      Contact information:
    </p>
    <p class="mb-6">
      {{ listing.phone }}<br>
      {{ listing.email }}<br>
    </p>
    <hr class="mb-6">
    <p class="font-bold mb-0 uppercase font-sans2 text-xs">
      <span class="text-zinc-700">Posted:</span>
      {{ formatDate(listing.created_at) }}
    </p>
    <div
      v-if="listing.user_id === currentUser?.id"
      class="absolute bottom-0 right-0 flex items-center uppercase text-xs space-x-2 mb-4 mr-4 cursor-pointer"
      @click="emit('deleteListing', listing)"
    >
      <span>Delete Listing</span>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
      </svg>
    </div>
  </div>
</template>
