<script>
export default {
  name: 'PressReleaseIndex',

  components: {
    GluePressReleasesPressReleasePosts: () => import('@/components/glue/press-releases/GluePressReleasesPressReleasePosts.vue'),
  },
  props: { devise: { type: Object, default: () => ({}) } },

  data() {
    return {
      posts: [],
      mode: 'news',
    }
  },

  computed: {
    filteredPosts() {
      if (this.mode === 'news')
        return this.posts.filter(post => post.category === 'news')
      else if (this.mode === 'newsroom')
        return this.posts.filter(post => post.category === 'newsroom')
      else if (this.mode === 'awards')
        return this.posts.filter(post => post.category === 'awards')
      else if (this.mode === 'all-news') // Currently disabled but will likely return
        return this.posts
      return []
    },
  },

  mounted() {
    this.fetchPosts()
  },

  methods: {
    async fetchPosts() {
      const config = useRuntimeConfig()
      const { data } = await $fetch(`${config.public.voixApiBaseUrl}/api/devise/press-releases/data`)
      this.posts = data
    },

    setMode(newMode) {
      this.mode = newMode
    },
  },
}
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <div class="container py-12">
      <div class="flex flex-col md:flex-row justify-between">
        <div class="md:w-2/3 px-4 lg:px-0 text-center lg:text-left">
          <div class="prose font-sans2 font-light" v-html="devise.fields.introduction.value" />
        </div>
        <div v-if="devise.fields.contactForm.enabled || devise.fields.imagesRequestForm.enabled" class="mx-auto p-12 bg-white flex-1">
          <template v-if="devise.fields.contactForm.enabled">
            <div class="text-xl uppercase font-sans2 font-light">
              Media Inquiries
            </div>
            <a class="font-sans2 text-glueblue-500 text-lg" :href="`${devise.fields.contactForm.value?.href}`">{{
              devise.fields.contactForm.value.text }}</a>
          </template>
          <template v-if="devise.fields.imagesRequestForm.enabled">
            <div class="mt-6 text-xl uppercase font-sans2 font-light">
              Images Request Form
            </div>
            <a class="font-sans2 text-glueblue-500 text-lg" :href="`${devise.fields.imagesRequestForm.value?.href}`">{{
              devise.fields.imagesRequestForm.value.text }}</a>
          </template>
        </div>
      </div>
      <div class="mt-10">
        <slot />
      </div>
    </div>

    <div class="flex flex-col lg:flex-row lg:divide-x divide-y lg:divide-y-0 divide-orange-300 w-full bg-orange-100 border-y border-zinc-300">
      <button
        class="relative flex justify-center items-center space-x-3 px-4 lg:w-1/3 py-6 hover:bg-white transition-colors duration-300"
        :class="{
          'bg-white border-orange-300 ': mode === 'news',
        }" @click="setMode('news')"
      >
        <span
          class="headline-x text-18" :class="{
            'text-red-500': mode === 'news',
          }"
        >In the News</span>
        <div v-show="mode === 'all-events'" class="absolute z-30 right-0 top-0 bottom-0 flex items-center py-3">
          <div class="h-full border-r-2 border-orange-200" />
        </div>
      </button>
      <button
        class="flex justify-center items-center space-x-3 px-4 lg:w-1/3 py-6 hover:bg-white transition-colors duration-300"
        :class="{
          'bg-white  border-orange-300': mode === 'newsroom',
        }" @click="setMode('newsroom')"
      >
        <span
          class="headline-x text-18" :class="{
            'text-red-500': mode === 'newsroom',
          }"
        >Newsroom</span>
      </button>
      <button
        class="flex justify-center items-center space-x-3 px-4 lg:w-1/3 py-6 hover:bg-white transition-colors duration-300"
        :class="{
          'bg-white  border-orange-300': mode === 'awards',
        }" @click="setMode('awards')"
      >
        <span
          class="headline-x text-18" :class="{
            'text-red-500': mode === 'awards',
          }"
        >Awards</span>
      </button>
    </div>
    <ClientOnly>
      <GluePressReleasesPressReleasePosts v-if="mode !== 'awards'" :posts="filteredPosts" :mode="mode" />
      <GluePressReleasesAwards v-else :posts="filteredPosts" :mode="mode" />
    </ClientOnly>
  </div>
</template>
