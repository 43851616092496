<script setup lang="ts">
import type { FieldInterface, SliceInterface } from '@voix/types'
import { resolveFieldsArray } from '@voix/composables/useField'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

export interface ActivityCardInterface {
  fields: { [key: string]: FieldInterface }
  slice: SliceInterface
}

const mode: Ref<string> = ref('all-adventures') // dolphin-cay, marine-adventures, all-adventures
const activityCards: Ref<ActivityCardInterface[]> = ref([])

const filters = reactive({
  animalTypes: {
    dolphins: false,
    seaLions: false,
    stingrays: false,
    seaTurtles: false,
  },
  age: '',
  activityLevel: '',
})

const tips = {
  'dolphin-cay': [
    'Wet suits and lockers are provided',
    'All day access to private beach',
    'Secure storage for all your valuables',
  ],
  'marine-adventures': [
    'Wet suits and lockers are provided',
    'Secure storage for all your valuables',
  ],
  'all-adventures': [],
}

provide('registerActivityCard', (cardInfo: ActivityCardInterface) => {
  activityCards.value.push(cardInfo)
})

const currentSlices = computed(() => {
  return props.slice.elements.filter((slice: SliceInterface) => {
    const fields = resolveFieldsArray(slice)
    if (fields.type.value) {
      if (mode.value === 'all-adventures')
        return true

      return fields.type.value === mode.value
    }
    return false
  })
})

const hasAnimalFilters = computed(() => {
  return (
    Object.entries(filters.animalTypes).filter(([, value]) => value).length > 0
  )
})

const filteredSlices = computed(() => {
  if (currentSlices.value) {
    return currentSlices.value.filter((slice: SliceInterface) => {
      const sliceFields = resolveFieldsArray(slice)

      // Animal Type Filter
      if (hasAnimalFilters.value) {
        for (const animalType of Object.keys(filters.animalTypes)) {
          const animalTypeField: FieldInterface | undefined = sliceFields[animalType]
          if (filters.animalTypes[animalType as keyof typeof filters.animalTypes] && animalTypeField) {
            if (animalTypeField.value)
              return true
          }
        }

        return false
      }

      // Age Filter
      const sliceAgeField: FieldInterface | undefined = sliceFields.ages
      if (sliceAgeField && filters.age) {
        if (sliceAgeField.value === '3-6' && filters.age !== '3-6')
          return false

        if (sliceAgeField.value !== 'All Ages' && filters.age === 'All Ages')
          return false

        // Get the first part of the string up until the space
        const filtersAgeNumber = Number.parseInt(filters.age.substring(0, 2))
        if (
          !Number.isNaN(Number.parseInt(sliceAgeField.value))
          && Number.parseInt(sliceAgeField.value) >= filtersAgeNumber
        ) {
          return false
        }
      }

      const activityLevelField: FieldInterface | undefined = sliceFields.activityLevel

      // Activity Level Filter
      if (activityLevelField && filters.activityLevel) {
        if (activityLevelField.value !== filters.activityLevel)
          return false
      }

      return true
    })
  }
  return false
})

const filteredSiblingsWithIframes = computed(() => {
  return filteredSlices.value.filter((slice: SliceInterface) => {
    return slice.fields?.find(field => field.name === 'iframe')
  })
})

const currentTips = computed(() => {
  return tips[mode.value as keyof typeof tips]
})

defineSlice({
  name: { label: 'Marine Adventures Search', group: 'Activities', layouts: ['Atlantisbahamas'] },
  preview: 'SlicesGlueExperiencesMarineAdventuresSearch.jpg',

  slots: [{ name: 'default' }],
  description: 'Give the user the ability to search through experience',
  tags: ['Activities', 'Search'],
  fields: {
    sectionOneTitle: { label: 'Section One Title', type: 'text' },
    sectionOneIcon: { label: 'Section One Icon', type: 'select', options: { dolphin: 'Dolphin', fish: 'Fish', flower: 'Flower' } },
    sectionOneDescription: { label: 'Section One Description', type: 'wysiwyg' },
    sectionOneImage1: { label: 'Section One Image 1', type: 'media', breakpoints: { lg: { width: 710, height: 360 }, sm: { width: 360, height: 360 } } },
    sectionOneImage2: { label: 'Section One Image 2', type: 'media', breakpoints: { lg: { width: 360, height: 360 }, sm: { width: 360, height: 360 } } },
    sectionTwoTitle: { label: 'Section Two Title', type: 'text' },
    sectionTwoIcon: { label: 'Section Two Icon', type: 'select', options: { dolphin: 'Dolphin', fish: 'Fish', flower: 'Flower' } },
    sectionTwoDescription: { label: 'Section Two Description', type: 'wysiwyg' },
    sectionTwoImage1: { label: 'Section Two Image 1', type: 'media', breakpoints: { lg: { width: 710, height: 360 }, sm: { width: 360, height: 360 } } },
    sectionTwoImage2: { label: 'Section Two Image 2', type: 'media', breakpoints: { lg: { width: 360, height: 360 }, sm: { width: 360, height: 360 } } },
    sectionThreeTitle: { label: 'Section Three Title', type: 'text' },
    sectionThreeIcon: { label: 'Section Three Icon', type: 'select', options: { dolphin: 'Dolphin', fish: 'Fish', flower: 'Flower' } },
    sectionThreeDescription: { label: 'Section Three Description', type: 'wysiwyg' },
    sectionThreeImage1: { label: 'Section Three Image 1', type: 'media', breakpoints: { lg: { width: 710, height: 360 }, sm: { width: 360, height: 360 } } },
    sectionThreeImage2: { label: 'Section Three Image 2', type: 'media', breakpoints: { lg: { width: 360, height: 360 }, sm: { width: 360, height: 360 } } },
    bottomLink: { label: 'Bottom Link', type: 'link', enabled: false },
  },
  templates: [{
    label: 'Marine Adventures Search',
    fields: {
      sectionOneDescription: { value: '<p>Dolphin Cay (pronounced \u201CKey\u201D) is one of the largest and most sophisticated marine-mammal habitats in the world. Our first residents were 17 stranded dolphins and 10 sea lions whose home in Gulfport, Mississippi, was destroyed by Hurricane Katrina. Dolphin Cay is also the Caribbean\u2019s leading marine animal rehabilitation facility.<\/p>' },
      sectionOneIcon: { value: 'dolphin' },
      sectionOneImage1: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Things_To_Do\/Dolphin_Cay\/Hero\/dolphin-cay-special-offer-hero-with-dolphin-cay-logo.copy.jpg' },
      sectionOneImage2: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Things_To_Do\/atlantis-marine-programs-dolphin-cay-playtime-with-sea-lions-guest.jpg' },
      sectionOneTitle: { value: 'DOLPHIN CAY' },
      sectionThreeDescription: { value: '<p>Explore the world\'s largest open-air marine habitat and discover Dolphin Cay\u2019s extraordinary environment and marine mammal interaction programs. Dolphin Cay is one of the most sophisticated marine habitats in the world with the Caribbean\u2019s premier marine life rescue and rehabilitation facility. Every Marine Adventure and Dolphin Cay program supports the <a href="\/ocean\/blue-project-foundation" rel="noopener noreferrer nofollow">Atlantis Blue Project Foundation<\/a>, a non-profit working to protect coral reefs and sea life.<\/p>' },
      sectionThreeIcon: { value: 'flower' },
      sectionThreeImage1: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/N_Homepage\/Heros_8_28_17\/Hero_Image_DolphinCay.jpg' },
      sectionThreeImage2: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/1664x585px-d81-2420.jpg' },
      sectionThreeTitle: { value: 'ALL Adventures' },
      sectionTwoDescription: { value: '<p>Atlantis Marine Adventures offer up-close and personal experiences with our animal care experts and marine life. Learn how conservationists and the Atlantis Blue Project Foundation (ABPF) are working to protect the world\u2019s coral reefs and sea life.<\/p>' },
      sectionTwoIcon: { value: 'fish' },
      sectionTwoImage1: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/1664x585px-d81-2420.jpg' },
      sectionTwoImage2: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Offers\/2020\/cyberweek\/experience-credits\/stingray-experience.jpg' },
      sectionTwoTitle: { value: 'Marine Adventures' },
    },
  }],
})

function setMode(newMode: string) {
  mode.value = newMode
}

function loadImage(key: string) {
  if (key === 'dolphin')
    return '/imgs/logos/dolphin-cay-md.png'
  if (key === 'fish')
    return '/imgs/logos/marine-adventures-sm.png'
  if (key === 'flower')
    return '/imgs/logos/all-adventures-sm.png'
  return ''
}
</script>

<template>
  <div class="overflow-x-hidden">
    <div class="relative py-12 lg:py-20">
      <div style="background-image: url(/imgs/backgrounds/pattern.jpg)" class="absolute z-0 inset-0 opacity-50" />
      <div class="relative z-10">
        <div v-if="mode === 'dolphin-cay'" class="container mx-auto flex flex-col lg:flex-row items-center">
          <div class="w-72 text-center flex flex-col items-center space-y-4 text-red-500">
            <div class="relative rounded-full w-12 h-12 bg-red-500 border-2 border-red-500">
              <div class="absolute inset-0 flex justify-center items-center">
                <div
                  class="rounded-full w-10 h-10 bg-white" style="
                    mask-image: url(/imgs/logos/dolphin-cay-sm.png);
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;
                  "
                />
              </div>
            </div>
            <span class="headline-2">{{ fields.sectionOneTitle.value }}</span>
            <div
              class="font-sans2 font-light leading-tight text-zinc-500"
              v-html="fields.sectionOneDescription.value"
            />
          </div>
          <div
            class="mt-8 lg:mt-0 flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-4 flex-1 mx-12"
          >
            <div class="h-[300px] aspect-video w-full">
              <VoixMedia :field="fields.sectionOneImage1" class="object-cover w-full h-full" />
            </div>

            <div class="h-[300px] aspect-video">
              <VoixMedia :field="fields.sectionOneImage2" class="object-cover w-full h-full" />
            </div>
          </div>
        </div>
        <div v-else-if="mode === 'marine-adventures'" class="container mx-auto flex flex-col lg:flex-row items-center">
          <div class="w-72 text-center flex flex-col items-center space-y-4 text-red-500">
            <div class="relative rounded-full w-12 h-12 bg-red-500 border-2 border-red-500">
              <div class="absolute inset-0 flex justify-center items-center">
                <div
                  class="rounded-full w-10 h-10 bg-white" style="
                    mask-image: url(/imgs/logos/marine-adventures-md.png);
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;
                  "
                />
              </div>
            </div>
            <span class="headline-2">Marine Adventures</span>
            <div
              class="font-sans2 font-light leading-tight text-zinc-500"
              v-html="fields.sectionTwoDescription.value"
            />
          </div>
          <div
            class="mt-8 lg:mt-0 flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-4 flex-1 mx-12"
          >
            <div class="h-[300px] aspect-video w-full">
              <VoixMedia :field="fields.sectionTwoImage1" class="object-cover w-full h-full" />
            </div>

            <div class="h-[300px] aspect-video">
              <VoixMedia :field="fields.sectionTwoImage2" class="object-cover w-full h-full" />
            </div>
          </div>
        </div>
        <div v-else-if="mode === 'all-adventures'" class="container mx-auto flex flex-col lg:flex-row items-center">
          <div class="w-72 text-center flex flex-col items-center space-y-4 text-red-500">
            <div class="relative rounded-full w-12 h-12 bg-red-500 border-2 border-red-500">
              <div class="absolute inset-0 flex justify-center items-center">
                <div
                  class="rounded-full w-10 h-10 bg-white" style="
                    mask-image: url(/imgs/logos/all-adventures-md.png);
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;
                  "
                />
              </div>
            </div>
            <span class="headline-2">All Adventures</span>
            <div
              class="font-sans2 font-light leading-tight text-zinc-500"
              v-html="fields.sectionThreeDescription.value"
            />
          </div>
          <div
            class="mt-8 lg:mt-0 flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-4 flex-1 mx-12"
          >
            <div class="h-[300px] aspect-video w-full">
              <VoixMedia :field="fields.sectionThreeImage1" class="object-cover w-full h-full" />
            </div>

            <div class="h-[300px] aspect-video">
              <VoixMedia :field="fields.sectionThreeImage2" class="object-cover w-full h-full" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden lg:flex bg-orange-100 border-y border-orange-200">
      <button
        class="relative flex justify-center items-center space-x-3 w-1/3 py-6 hover:bg-white transition-colors duration-300"
        :class="{
          'bg-white border-r border-zinc-400 ': mode === 'dolphin-cay',
        }" @click="setMode('dolphin-cay')"
      >
        <div
          class="relative rounded-full w-8 h-8" :class="{
            'bg-red-500 border-2 border-red-500 ': mode === 'dolphin-cay',
            'bg-orange-50 border-2 border-zinc-700 ': mode !== 'dolphin-cay',
          }"
        >
          <div class="absolute inset-0 flex justify-center items-center">
            <div
              class="rounded-full w-6 h-6" :class="{
                'bg-zinc-700': mode !== 'dolphin-cay',
                'bg-white': mode === 'dolphin-cay',
              }" style="mask-size: contain; mask-repeat: no-repeat; mask-position: center" :style="{
                'mask-image': `url(${loadImage(fields.sectionOneIcon.value)})`,
              }"
            />
          </div>
        </div>
        <span
          class="headline-x text-18" :class="{
            'text-red-500': mode === 'dolphin-cay',
          }"
        >{{ fields.sectionOneTitle.value }}</span>

        <div v-show="mode === 'all-adventures'" class="absolute z-30 right-0 top-0 bottom-0 flex items-center py-3">
          <div class="h-full border-r-2 border-zinc-200" />
        </div>
      </button>
      <button
        class="flex justify-center items-center space-x-3 w-1/3 py-6 hover:bg-white transition-colors duration-300"
        :class="{
          'bg-white border-r border-l border-zinc-400 ': mode === 'marine-adventures',
        }" @click="setMode('marine-adventures')"
      >
        <div
          class="relative rounded-full w-8 h-8" :class="{
            'bg-red-500 border-2 border-red-500 ': mode === 'marine-adventures',
            'bg-orange-50 border-2 border-zinc-700 ': mode !== 'marine-adventures',
          }"
        >
          <div class="absolute inset-0 flex justify-center items-center">
            <div
              class="rounded-full w-6 h-6" :class="{
                'bg-zinc-700': mode !== 'marine-adventures',
                'bg-white': mode === 'marine-adventures',
              }" style="mask-size: contain; mask-repeat: no-repeat; mask-position: center" :style="{
                'mask-image': `url(${loadImage(fields.sectionTwoIcon.value)})`,
              }"
            />
          </div>
        </div>
        <span
          class="headline-x text-18" :class="{
            'text-red-500': mode === 'marine-adventures',
          }"
        >{{ fields.sectionTwoTitle.value }}</span>
      </button>
      <button
        class="relative flex justify-center items-center space-x-3 w-1/3 py-6 hover:bg-white transition-colors duration-300"
        :class="{
          'bg-white  border-l border-zinc-400': mode === 'all-adventures',
        }" @click="setMode('all-adventures')"
      >
        <div
          class="relative rounded-full w-8 h-8" :class="{
            'bg-red-500 border-2 border-red-500 ': mode === 'all-adventures',
            'bg-orange-50 border-2 border-zinc-700 ': mode !== 'all-adventures',
          }"
        >
          <div class="absolute inset-0 flex justify-center items-center">
            <div
              class="rounded-full w-6 h-6" :class="{
                'bg-zinc-700': mode !== 'all-adventures',
                'bg-white': mode === 'all-adventures',
              }" style="mask-size: contain; mask-repeat: no-repeat; mask-position: center" :style="{
                'mask-image': `url(${loadImage(fields.sectionThreeIcon.value)})`,
              }"
            />
          </div>
        </div>
        <span
          class="headline-x text-18" :class="{
            'text-red-500': mode === 'all-adventures',
          }"
        >{{ fields.sectionThreeTitle.value }}</span>

        <div v-show="mode === 'dolphin-cay'" class="absolute z-30 left-0 top-0 bottom-0 flex items-center py-3">
          <div class="h-full border-r-2 border-zinc-200" />
        </div>
      </button>
    </div>

    <!-- Mobile Mode Selector -->
    <div class="overflow-hidden w-full grid grid-cols-1 lg:hidden font-sans2 bg-orange-50 py-8">
      <div
        class="flex transition-all duration-300 ease-out" :class="{
          'translate-x-[105px]': mode === 'dolphin-cay',
          '-translate-x-[115px]': mode === 'marine-adventures',
          '-translate-x-[330px]': mode === 'all-adventures',
        }"
      >
        <button
          class="font-bold uppercase text-sm min-w-[200px]" :class="{ 'text-red-500': mode === 'dolphin-cay' }"
          @click="mode = 'dolphin-cay'"
        >
          {{ fields.sectionOneTitle.value }}
        </button>
        <button
          class="font-bold uppercase text-sm min-w-[200px]"
          :class="{ 'text-red-500': mode === 'marine-adventures' }" @click="mode = 'marine-adventures'"
        >
          {{ fields.sectionTwoTitle.value }}
        </button>
        <button
          class="font-bold uppercase text-sm min-w-[200px]" :class="{ 'text-red-500': mode === 'all-adventures' }"
          @click="mode = 'all-adventures'"
        >
          {{ fields.sectionThreeTitle.value }}
        </button>
      </div>

      <div
        class="mt-4 h-3 bg-zinc-200 flex items-center justify-center rounded-l-sm transition-all duration-100"
        :class="{
          ' ml-8': mode === 'dolphin-cay',
          'mr-8': mode === 'all-adventures',
        }"
      >
        <div
          class="h-2 bg-red-500 rounded-sm transition-all duration-300 ease-out delay-300" :class="{
            'w-[100px]': mode === 'dolphin-cay',
            'w-[150px]': mode === 'marine-adventures',
            'w-[130px]': mode === 'all-adventures',
          }"
        />
      </div>
    </div>

    <GlueExperienceSearchFilters
      v-if="mode === 'all-adventures'" v-model="filters"
      :number-of-current-activities="filteredSlices.length"
    />
    <GlueExperienceQuickTips v-else :tips="currentTips" />
    <div class="flex justify-center">
      <div class="container mx-auto grid lg:grid-cols-2 gap-12 lg:m-12 px-8 lg:px-24">
        <VoixSlice
          v-for="(slice, key) in filteredSlices" :key="key" :slice="slice" :slice-index="key" class="flex"
          :siblings="filteredSiblingsWithIframes"
        />
      </div>
    </div>

    <div v-if="fields.bottomLink.enabled" class="mt-8 flex justify-center">
      <a
        :href="fields.bottomLink.value.url" :target="fields.bottomLink.value.target"
        class="glue-btn btn-lg bg-glueblue-600 text-white"
      >
        {{ fields.bottomLink?.value?.text }}
      </a>
    </div>
  </div>
</template>
