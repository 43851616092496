<script setup lang="ts">
import type { SliceInterface, VoixDateTimeFieldInterface, VoixTextFieldInterface } from '@voix/types'
import { usePageField } from '@voix/composables/usePageField'
import dayjs from 'dayjs'

const props = defineProps<{
  slice: SliceInterface
  fields: {
    btnText: VoixTextFieldInterface
    cobrand: VoixTextFieldInterface
    startDate: VoixDateTimeFieldInterface
    endDate: VoixDateTimeFieldInterface
    style: VoixDateTimeFieldInterface
  }
}>()

defineSlice({
  name: {
    label: 'Book Now Button Direct Link',
    group: 'Booking',
    layouts: ['Atlantisbahamas'],
  },
  description: 'Book Now Button Direct Link',
  preview: 'SlicesBookingBookNowDirectLink.jpeg',
  fields: {
    btnText: {
      type: 'text',
      label: 'Button Text',
      default: 'Book Now',
      editorLabel: true,
    },
    cobrand: {
      type: 'text',
      label: 'Cobrand Nav Value',
      description: 'Use this when you want a unique cobrand for this button. Leave blank when you want the page field used.',
    },
    startDate: {
      type: 'datetime',
      label: 'Start Date',
    },
    endDate: {
      type: 'datetime',
      label: 'End Date',
    },
    style: {
      type: 'select',
      label: 'Button Style',
      options: {
        ghost: 'Ghost',
        atlantisBlue: 'Atlantis Blue',
      },
      default: 'ghost',
    },
  },
  templates: [{
    fields: {
      btnText: { value: 'Book Now' },
    },
  }],
})
const pageField = usePageField()
const pageCobrandId = pageField.get('cobrand', 45)

const pageCobrandNav = ref('apiMain')
if (pageCobrandId.value !== 45) {
  const { data } = await useVoixAsyncData(`/api/atlantis/cobrands/${pageCobrandId.value}`)
  if (data?.value)
    pageCobrandNav.value = data.value?.nav
}

const href = computed(() => {
  const nav = props.fields.cobrand?.value || pageCobrandNav.value
  let startDate = dayjs(props.fields.startDate?.value)

  if (startDate.isBefore(dayjs(), 'day'))
    startDate = dayjs()

  let endDate = dayjs(props.fields.endDate?.value)

  if (endDate.isBefore(dayjs(), 'day'))
    endDate = dayjs()

  const start = startDate.format('MM/DD/YYYY')
  const end = endDate.format('MM/DD/YYYY')

  return `https://secure.atlantisbahamas.com/travel/arc_waiting.cfm?nav=${nav}&origin=http%253A%252F%252FAtlantisBahamas.com%252F&activity1=true&area2=%7Cairport%253ANAS&displayarea2=NAS&date1=${start}&date2=${end}}&submit=Search`
})
</script>

<template>
  <a
    class="inline-block text-center border px-8 py-5 leading-snug uppercase focus:outline-none font-sans text-xs min-w-[180px]"
    :class="{
      'border-zinc-500 text-zinc-700 font-bold': fields.style?.value === 'ghost',
      'bg-glueblue-700 text-white font-bold': fields.style?.value === 'atlantisBlue',
    }"
    :href="href"
    target="_top"
  >{{ fields.btnText.value }}</a>
</template>
