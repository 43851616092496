<script>
export default {
  name: 'FlagIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    viewBox="0 0 30 40"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="currentcolor" fill-rule="evenodd">
      <path
        id="Shape"
        d="M29.85,15.29 L26.85,1.38 C26.7399015,0.870398772 26.2567585,0.530892857 25.74,0.6 C23.0354677,0.980355705 20.3618722,1.55517872 17.74,2.32 C16.03,2.82 14.74,3.32 13.66,3.69 C12.2380431,4.23839732 10.7815685,4.6927105 9.3,5.05 C6.75239789,5.63219659 4.14264593,5.89753817 1.53,5.84 C1.22559292,5.8334739 0.934795353,5.96598925 0.74,6.2 C0.543091228,6.43600408 0.465651638,6.74945004 0.53,7.05 L3.53,20.96 L6.94,36.6 C7.04064169,37.0691379 7.46030719,37.4006736 7.94,37.3902505 L8.15,37.3902505 C8.41021652,37.3348514 8.63773885,37.1783024 8.78224166,36.9549799 C8.92674446,36.7316574 8.97631902,36.4599657 8.92,36.2 L5.78,21.74 C8.13625524,21.7050742 10.4823784,21.4235395 12.78,20.9 C14.343375,20.5259672 15.880108,20.0482437 17.38,19.47 C18.51,19.06 19.69,18.63 21.33,18.14 C23.843226,17.4045515 26.4066715,16.8530932 29,16.49 C29.2751927,16.4506054 29.5215665,16.2984333 29.68,16.07 C29.8424899,15.8448732 29.9040708,15.5623259 29.85,15.29 L29.85,15.29 Z M20.77,16.23 C19.06,16.73 17.77,17.23 16.69,17.59 C15.2695996,18.1657536 13.8090485,18.6370069 12.32,19 C10.0239389,19.5276787 7.67591285,19.7960245 5.32,19.8 L2.78,7.84 C5.13625524,7.80507424 7.4823784,7.52353946 9.78,7 C11.342098,6.62133261 12.8785323,6.14370195 14.38,5.57 C15.51,5.15 16.69,4.72 18.38,4.24 C20.5851543,3.59001048 22.8297729,3.08229913 25.1,2.72 L27.67,14.72 C25.3394171,15.0717025 23.0344546,15.5761218 20.77,16.23 L20.77,16.23 Z"
      />
    </g>
  </svg>
</template>
