<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({

      name: { label: 'One Third Image Card Grid', group: 'Containers', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesGlueContainersOneThirdImageCardGrid.jpg',
      slots: [{ name: 'default' }],
      description: 'Grid for 1\/3 cards with title at the top',
      tags: ['Grid', 'Container'],
      fields: {
        title: { type: 'text', label: 'Title' },
        sliderOnMobile: { type: 'checkbox', label: 'Slider on Mobile?', default: true },
        divideX: { type: 'checkbox', label: 'Border Right Left On?', default: false },
        divideY: { type: 'checkbox', label: 'Border Top Bottom On?', default: false },
        columns: {
          type: 'select',
          label: 'Columns',
          options: {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
          },
        },
        gap: {
          type: 'select',
          label: 'Gap',
          options: {
            0: '0',
            2: '2',
            4: '4',
            6: '6',
            8: '8',
            10: '10',
            12: '12',
          },
        },
      },
      templates: [{ label: 'One Third Image Card Grid', fields: { columns: { value: 3 }, gap: { value: 2 }, title: { value: 'You may also like to try...' } } }],
    })
  },
}
</script>

<template>
  <div>
    <div class="small-container">
      <h3 class="px-8 font-sans text-3xl md:text-4xl font-light uppercase leading-tight mb-2 tracking-wider">
        {{ fields.title.value }}
      </h3>
      <div class="px-0 md:px-8">
        <GlueContainersGridContainer :slice="{ elements: slice.elements, fields }">
          <slot />
        </GlueContainersGridContainer>
      </div>
    </div>
  </div>
</template>
