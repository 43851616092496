<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { Swiper as SwiperType } from 'swiper'

import type { SliceInterface, VoixTextFieldInterface } from '@voix/types'

// Import Swiper styles
import 'swiper/css'

defineProps<{
  slice: SliceInterface
  fields: {
    title: VoixTextFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Barbie Square Slider', group: 'Barbie', layouts: ['Barbie'] },
  preview: 'SlicesBarbieSquareSlider.jpg',
  slots: [{ name: 'default', allowedElements: ['SlicesBarbieSquareSlide'] }],
  description: 'Title with a slider of images in a square',
  templates: [{
    label: 'Slider Container',
    fields: {
      title: {
        value: 'Make it a beach day!',
      },
    },
  }],
})

const swiper = ref<SwiperType | null>(null)

function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}

function next() {
  if (swiper.value)
    swiper.value.slideNext()
}

function prev() {
  if (swiper.value)
    swiper.value.slidePrev()
}
</script>

<template>
  <div class="py-6">
    <div class="container mx-auto  flex justify-center">
      <div v-if="slice.elements" class="relative max-w-[400px] md:max-w-[80%]">
        <Swiper
          :slides-per-view="1"
          :space-between="20"
          :breakpoints="{
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 2,
            },
            1280: {
              slidesPerView: 3,
            },
          }"
          class="center-slider"
          @swiper="onSwiper"
        >
          <SwiperSlide v-for="(s, key) in slice.elements" :key="key" class="w-[300px]">
            <VoixSlice :slice="s as SliceInterface" :slice-index="key" />
          </SwiperSlide>
        </Swiper>
        <div
          class="md:absolute z-10 md:inset-0 pt-8 md:pt-0 flex md:justify-between items-center pointer-events-none md:-translate-x-3"
        >
          <button
            class="pointer-events-auto flex items-center translate-x-3 md:-translate-x-16"
            @click="prev"
          >
            <div class="w-16 h-16 rounded-full flex justify-center items-center text-white bg-barbie-500 hover:bg-white hover:text-barbie-500 duration-100 border-2 border-gray-600">
              <Icon class="w-10 h-10 pointer-events-none" name="heroicons:chevron-left-20-solid" />
            </div>
          </button>
          <button
            class="pointer-events-auto flex items-center translate-x-6 md:translate-x-16"
            @click="next"
          >
            <div class="w-16 h-16 rounded-full flex justify-center items-center text-white bg-barbie-500 hover:bg-white hover:text-barbie-500 duration-100 border-2 border-gray-600">
              <Icon class="w-10 h-10 pointer-events-none" name="heroicons:chevron-right-20-solid" />
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.center-slider {
  .swiper-slide{
   @apply text-center flex justify-center;

   > div {
    @apply flex-none;
   }
  }
}
</style>
