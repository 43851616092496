<script>
export default {
  name: 'SinglePlatformMenu',

  props: {
    devise: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      timeout: 20,
      errorLoadingMenu: true,
    }
  },

  mounted() {
    // Docs: http://docs.singleplatform.com/spv2/javascript-api/
    const singlePlatformScript = document.createElement('script')
    singlePlatformScript.setAttribute(
      'src',
      'https://menus.singleplatform.co/jsload?load=menus.2&apiKey=ke09z8icq4xu8uiiccighy1bw',
    )
    document.body.appendChild(singlePlatformScript)

    this.pollForLoading()
  },

  methods: {
    pollForLoading() {
      setTimeout(() => {
        this.timeout--
        if (typeof menuApi !== 'undefined') {
          this.initMenu()
        }
        else if (this.timeout > 0) {
          this.pollForLoading()
        }
        else {
          this.errorLoadingMenu = true
        }
      }, 100)
    },

    initMenu() {
      const menuApi = new MenusApi('ke09z8icq4xu8uiiccighy1bw')
      menuApi.loadMenusForLocation(
        this.devise.fields.locationId.value,
        'single_platform_menu',
      )

      menuApi.setPrimaryBackgroundColor('rgb(249, 248, 242)')
      menuApi.setSecondaryBackgroundColor('rgb(249, 248, 242)')
      menuApi.setSectionTitleBackgroundColor('rgb(249, 248, 242)')
      menuApi.setPrimaryFontFamily('Lato')
      menuApi.setSectionDescFontFamily('Lato')
    },
  },
}
</script>

<template>
  <div class="relative z-0">
    <div id="single_platform_menu" />
    <div id="sp_main" class="hidden sp_tabs" />
  </div>
</template>
