<script>
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const weekdays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

export default {
  name: 'RestaurantHours',

  props: {
    devise: { type: Object },
    restaurant: {
      type: Object,
      required: true,
    },
    align: {
      type: String,
      default: 'right',
    },
  },

  data() {
    return {
      diningTimesDay: dayjs().format('dddd').toLowerCase(),
    }
  },

  computed: {
    diningTimes() {
      if (
        !this.restaurant.hours_details
        || !this.restaurant.hours_details[this.diningTimesDay]
        || !this.restaurant.hours_details[this.diningTimesDay].length
      ) {
        return `Closed on ${this.diningTimesDayCapitalized}`
      }

      // Sort the hours because they were not added to the array in order.
      const sortedHours = JSON.parse(
        JSON.stringify(this.restaurant.hours_details[this.diningTimesDay]),
      )

      if (sortedHours) {
        // Loop through the merged ranges to create the string
        if (sortedHours.length > 1) {
          let rangesOutput = null
          for (let i = 0; i < sortedHours.length; i++) {
            // Current range of hours we are looking at
            const open = this.formatTime(sortedHours[i].open)
            const close = this.formatTime(sortedHours[i].close)

            if (typeof open === 'string') {
              rangesOutput
                = rangesOutput === null
                  ? `${open} - ${close}`
                  : `${rangesOutput}<br> & ${open} - ${close}`
            }
          }
          return `Open ${rangesOutput}`
        }
        else {
          return `${this.formatTime(sortedHours[0].open)} - ${this.formatTime(sortedHours[0].close)}`
        }
      }
      return `Closed ${this.diningTimesDayCapitalized}`
    },

    previousDay() {
      return dayjs()
        .day(weekdays.indexOf(this.diningTimesDay))
        .subtract(1, 'day')
        .format('dddd')
        .toLowerCase()
    },

    nextDay() {
      return dayjs()
        .day(weekdays.indexOf(this.diningTimesDay))
        .add(1, 'day')
        .format('dddd')
        .toLowerCase()
    },

    diningTimesDayCapitalized() {
      return (
        this.diningTimesDay.charAt(0).toUpperCase()
        + this.diningTimesDay.slice(1)
      )
    },
  },

  methods: {
    formatTime(time) {
      const hour = Math.floor(time)
      const minute = Math.round((time - hour) * 60)
      return dayjs().hour(hour).minute(minute).format('h:mm A')
    },

    getMidnightRange(day) {
      if (this.restaurant.hours_details[day]) {
        const hours = this.getSortedHours(
          JSON.parse(JSON.stringify(this.restaurant.hours_details[day])),
        )

        if (hours[0] && hours[0].open)
          return hours[0].open === 0 ? hours[0] : false
      }
      return false
    },

    getSortedHours(hours) {
      const sortedHours = Array.isArray(hours) ? hours : [hours]
      return sortedHours.sort((a, b) => (a.open > b.open ? 1 : -1))
    },
  },
}
</script>

<template>
  <div class="font-sans2 text-xs">
    <div v-if="!restaurant.closed">
      <div class="flex mb-2 text-2xs translate-x-2" :class="{ 'justify-end ': align === 'right' }">
        <div
          class="cursor-pointer h-6 w-6 rounded-full flex justify-center items-center" :class="{
            'bg-green-500 text-white': diningTimesDay === 'sunday',
          }" @click="diningTimesDay = 'sunday'"
        >
          S
        </div>
        <div
          class="cursor-pointer h-6 w-6 rounded-full flex justify-center items-center" :class="{
            'bg-green-500 text-white': diningTimesDay === 'monday',
          }" @click="diningTimesDay = 'monday'"
        >
          M
        </div>
        <div
          class="cursor-pointer h-6 w-6 rounded-full flex justify-center items-center" :class="{
            'bg-green-500 text-white': diningTimesDay === 'tuesday',
          }" @click="diningTimesDay = 'tuesday'"
        >
          T
        </div>
        <div
          class="cursor-pointer h-6 w-6 rounded-full flex justify-center items-center" :class="{
            'bg-green-500 text-white': diningTimesDay === 'wednesday',
          }" @click="diningTimesDay = 'wednesday'"
        >
          W
        </div>
        <div
          class="cursor-pointer h-6 w-6 rounded-full flex justify-center items-center" :class="{
            'bg-green-500 text-white': diningTimesDay === 'thursday',
          }" @click="diningTimesDay = 'thursday'"
        >
          T
        </div>
        <div
          class="cursor-pointer h-6 w-6 rounded-full flex justify-center items-center" :class="{
            'bg-green-500 text-white': diningTimesDay === 'friday',
          }" @click="diningTimesDay = 'friday'"
        >
          F
        </div>
        <div
          class="cursor-pointer h-6 w-6 rounded-full flex justify-center items-center" :class="{
            'bg-green-500 text-white': diningTimesDay === 'saturday',
          }" @click="diningTimesDay = 'saturday'"
        >
          S
        </div>
      </div>
      <div class="text-gray-600" :class="{ 'text-right ': align === 'right' }" v-html="diningTimes" />
    </div>
    <div v-else>
      <div class="text-sm text-gray-500" :class="{ 'text-right ': align === 'right' }">
        {{ restaurant.closed_reason }}
      </div>
    </div>
  </div>
</template>
