<script>
export default {
  name: 'CocktailIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 27 42"
  >
    <defs>
      <path
        id="cocktail-icon-prefix-a"
        d="M0 0L26.977 0 26.977 41.847 0 41.847z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <mask id="cocktail-icon-prefix-b" fill="currentcolor">
        <use xlink:href="#cocktail-icon-prefix-a" />
      </mask>
      <path
        fill="currentcolor"
        stroke="#currentColor"
        d="M6.488 8.265c-.49 0-.912.34-1.002.81-.256 1.273-.574 2.53-.973 4.03l-.09.339c-.58-.316-1.084-.747-1.466-1.256-.606-.806-.926-1.763-.926-2.769 0-1.227.48-2.381 1.355-3.25.874-.867 2.037-1.344 3.272-1.344 1.225 0 2.372.462 3.228 1.301.61.59 1.04 1.326 1.252 2.139h-4.65zm18.445 11.03c0 2.595-1.022 5.038-2.877 6.88-1.843 1.841-4.303 2.855-6.927 2.855-2.614 0-5.074-1.014-6.93-2.856-1.854-1.83-2.875-4.274-2.875-6.879 0-1.438.575-3.6 1.13-5.69.274-1.019.586-2.204.84-3.324h15.67c.252 1.12.566 2.305.838 3.324.562 2.1 1.142 4.272 1.13 5.69h0zm-1.428 8.317c2.24-2.21 3.472-5.16 3.472-8.306 0-1.606-.57-3.766-1.17-6.052l-.04-.15c-.343-1.286-.7-2.655-.973-4.003-.095-.493-.512-.836-1.014-.836h-5.365l2.352-6.247h3.06c.56 0 1.015-.452 1.015-1.009 0-.556-.456-1.008-1.015-1.008h-3.744c-.433-.022-.823.257-.965.655l-2.854 7.609h-3.066c-.244-1.363-.899-2.595-1.9-3.568-1.255-1.21-2.903-1.877-4.64-1.877-1.78 0-3.452.687-4.71 1.934C.693 6.003 0 7.664 0 9.431c0 1.454.465 2.828 1.346 3.975.68.875 1.566 1.572 2.567 2.022-.29 1.195-.61 2.685-.61 3.878 0 3.134 1.234 6.085 3.471 8.306 1.954 1.951 4.556 3.153 7.339 3.39v8.829H8.895c-.56 0-1.015.452-1.015 1.008s.455 1.009 1.015 1.009h12.478c.56 0 1.015-.453 1.015-1.01 0-.565-.445-1.007-1.015-1.007h-5.229v-8.827c2.781-.235 5.391-1.436 7.361-3.392h0z"
        mask="url(#cocktail-icon-prefix-b)"
      />
    </g>
  </svg>
</template>
