<script>
export default {
  name: 'GolfIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 29 41"
  >
    <defs>
      <path
        id="golf-icon-prefix-a"
        d="M0 0.291L4.131 0.291 4.131 4.423 0 4.423z"
      />
      <path
        id="golf-icon-prefix-c"
        d="M0.854 0.598L24.761 0.598 24.761 41 0.854 41z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(0 -1) translate(0 23.402)">
        <mask id="golf-icon-prefix-b" fill="currentcolor">
          <use xlink:href="#golf-icon-prefix-a" />
        </mask>
        <path
          fill="currentcolor"
          d="M2.066.291C.926.291 0 1.219 0 2.359c0 1.139.927 2.065 2.066 2.065 1.139 0 2.065-.926 2.065-2.066 0-1.139-.926-2.066-2.065-2.066"
          mask="url(#golf-icon-prefix-b)"
        />
      </g>
      <g transform="translate(0 -1) translate(4 .402)">
        <mask id="golf-icon-prefix-d" fill="currentcolor">
          <use xlink:href="#golf-icon-prefix-c" />
        </mask>
        <path
          fill="currentcolor"
          stroke="#currentColor"
          d="M2.988 37.382c0-.303 1.445-1.241 4.208-1.443v.899c0 .588.478 1.066 1.066 1.066.589 0 1.067-.478 1.067-1.066v-.9c2.763.202 4.208 1.14 4.208 1.444 0 .37-1.87 1.485-5.275 1.485-3.403 0-5.274-1.115-5.274-1.485M18.654 9.056c-3.882.964-7.613 1.08-9.325 1.073V4.362c1.923.203 2.865.972 4.042 1.934 1.247 1.018 2.64 2.156 5.283 2.76m6.079-.887v-.001c-.12-.494-.57-.846-1.062-.82-5.481.12-7.244-1.313-8.95-2.706-1.316-1.074-2.675-2.184-5.392-2.42v-.557c0-.588-.478-1.067-1.067-1.067-.588 0-1.066.479-1.066 1.067v32.134C4 34.007.854 35.179.854 37.382.854 39.868 4.694 41 8.263 41c3.568 0 7.409-1.132 7.409-3.618 0-2.202-3.147-3.375-6.342-3.583V12.262h.049c2.636 0 9.353-.281 14.778-2.885.449-.216.69-.724.576-1.208"
          mask="url(#golf-icon-prefix-d)"
        />
      </g>
    </g>
  </svg>
</template>
./IconsGolfIcon.vue
