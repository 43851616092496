<script>
export default {}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 50">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      class="s0"
      d="m81.5 2.6v36.4c-3.1-1-6.1-1.7-9.2-2.1q-1.8-9.5-3.5-18.9-0.1 0-0.2 0-1.6 9.2-3.1 18.4c-3.2 0-6.3 0.2-9.4 0.5v-28.4q2.9-0.3 5.8-0.4-0.1 10.5-0.1 20.9 0.1 0 0.3 0 1.4-10.5 2.8-20.9 3.7 0 7.4 0.4 1.6 10.7 3.2 21.5 0.2 0 0.4 0.1-0.1-10.5-0.2-21v-2.2c-25.1-4.9-50.1 9.8-75.2 1.6v-5.9c25.1 8.2 50.1-6.5 75.2-1.6q1.6 0.4 3.3 0.8 1.3 0.4 2.5 0.8zm-75.1 25.7v14.8c25 4.9 50.1-9.8 75.1-1.6v5.9c-25-8.2-50.1 6.5-75.1 1.6q-1.7-0.4-3.4-0.8-1.2-0.4-2.5-0.8v-36.4c3 1 5.9 1.6 8.9 2q1.9 10.5 3.8 20.7 0.2 0.1 0.3 0.1 1.8-10.1 3.5-20.2c3 0 6-0.2 8.9-0.5v28.4q-2.8 0.3-5.7 0.4v-12.6c0-1.2 0-3.7 0.1-6.5-0.3 2.4-0.6 4.5-0.8 5.6q-1.2 6.8-2.4 13.6-3.7-0.1-7.4-0.5-1.3-7-2.6-14c-0.2-1.2-0.5-3.5-0.9-6.1 0.1 3 0.2 5.7 0.2 6.9zm41.4 10.2q0 0 0 0zm0 0v-12.5c0-1.2 0-3.7 0.1-6.5-0.3 2.4-0.6 4.6-0.9 5.7q-1.1 6.9-2.3 13.8-3.7 0.6-7.4 1.2-1.3-6.5-2.7-13.1c-0.2-1.1-0.5-3.3-0.9-5.7 0.1 2.9 0.2 5.5 0.2 6.8v12.5q-2.9 0.5-5.9 0.8v-28.2c3-0.4 6-0.8 9-1.3q1.9 9.8 3.8 19.6 0.1 0 0.3-0.1 1.7-10.4 3.5-20.7c3-0.5 6-1 8.9-1.3v28.2q-2.8 0.4-5.7 0.8z"
    />
  </svg>
</template>
