<script>
import { getCurrentBreakpoint } from '@voix/composables/useBreakpoints'
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    menu: {
      type: Object,
      required: true,
    },
    taTrackNum: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      currentPage: null,
      mounted: false,
      scrollTop: 0,
      hideBookNow: false,
      specialOverride: null,
      dropdownOpen: false,
      currentTopMenu: {},
      hasEyebrow: false,
    }
  },

  computed: {
    showMobile() {
      return this.breakpoint === 'mobile' || this.breakpoint === 'tablet'
    },
    mainMenu() {
      return this.menu.data
    },
    logoWidth() {
      if (typeof window !== 'undefined') {
        const currentBreakpoint = getCurrentBreakpoint(window.innerWidth)
        if (currentBreakpoint === 'md')
          return { width: '100px' }

        if (currentBreakpoint === 'lg' || currentBreakpoint === 'xl' || currentBreakpoint === '2xl')
          return { width: '250px' }
      }
      return '140px'
    },

    dropdownStyles() {
      const styles = {}
      if (this.mounted) {
        const mainMenu = this.$refs.mainMenu
        const mainMenuBounding = mainMenu.getBoundingClientRect()

        const bottom = this.hasEyebrow && (this.scrollDirection === 'up' || this.scrollTop === 0) ? mainMenuBounding.bottom - 40 : mainMenuBounding.bottom

        if (this.dropdownOpen)
          styles.transform = `translateY(${bottom}px)`
        else
          styles.transform = 'translateY(38px)'
      }

      return styles
    },

    currentMenuColumns() {
      if (this.currentTopMenu && this.currentTopMenu.children) {
        return this.currentTopMenu.children.filter(
          child => child.type === 'column',
        )
      }
      return []
    },
    currentMenuCA() {
      if (this.currentTopMenu && this.currentTopMenu.children) {
        return this.currentTopMenu.children.find(
          child => child.type === 'ca',
        )
      }
      return []
    },
  },

  watch: {
    scrollTop(newValue, oldValue) {
      if (typeof window === 'undefined')
        return

      if (oldValue < newValue)
        this.scrollDirection = 'down'
        // TODO: Need to redefine the bus
        // window.deviseSettings.$bus.$emit('nav-scroll-down')

      else
        this.scrollDirection = 'up'
        // TODO: Need to redefine the bus
        // window.deviseSettings.$bus.$emit('nav-scroll-up')
    },
  },

  mounted() {
    const pageStore = usePageStore()
    this.currentPage = pageStore.currentPage

    this.mounted = true
    this.scrollTop = document.documentElement.scrollTop
    window.addEventListener('scroll', () => {
      this.scrollTop = document.documentElement.scrollTop
    })
  },

  methods: {
    openNavigationItem(item) {
      if (!item.open)
        this.$set(item, 'open', true)
      else

        item.open = false
    },

    openSubMenu(topMenuItem) {
      this.currentTopMenu = topMenuItem
      this.dropdownOpen = true
    },

    logout() {
      const accessToken = useCookie('auth-access-token')
      const refreshToken = useCookie('auth-refresh-token')
      accessToken.value = null
      refreshToken.value = null

      window.reload()
    },

    stringToSlug(str) {
      if (!str)
        return ''

      str = str.replace(/^\s+|\s+$/g, '') // trim
      str = str.toLowerCase()

      // remove accents, swap ñ for n, etc
      const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
      const to = 'aaaaeeeeiiiioooouuuunc------'
      for (let i = 0, l = from.length; i < l; i++)
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))

      str = str
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes

      return str
    },
  },
}
</script>

<template>
  <div class="w-full relative z-[70]" @mouseleave="dropdownOpen = false">
    <div
      id="navigation"
      class="fixed top-0 left-0 right-0 z-[70] transition-all duration-300 bg-white border-b border-gray-200"
      :class="{
        '-mt-[2.5rem]': scrollTop > 0 && scrollDirection === 'down',
        'top-8': hasEyebrow,
      }"
    >
      <div class="bg-white border-b border-gray-200">
        <div class="big-container mx-auto relative z-30 tracking-utility-nav bg-white">
          <ul
            class="pt-2 pb-1 flex items-center justify-end text-center font-sans text-2xs text-gray-600 uppercase px-16"
          >
            <li class="px-2">
              <a class="flex items-center text-gray-800 cursor-pointer" href="/my-account">
                <Icon name="heroicons:user-circle" class="-mt-1 w-4 h-4 inline-block mr-1" />
                <span>My Account</span>
              </a>
            </li>
            <li class="px-2">
              <a class="text-gray-800 cursor-pointer" @click="logout">Logout</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tracking-main-nav absolute left-0 right-0 z-20 bg-gradient-to-b from-black/70 to-black/0">
        <div
          class="transition-colors ease-in duration-200"
          :class="{
            'bg-white border-gray-100 border-b ': scrollTop > 0 || dropdownOpen,
          }"
        >
          <div
            class="big-container mx-auto relative z-20 "
          >
            <div
              ref="mainMenu"
              class="relative z-30 pt-6 flex flex-col lg:flex-row justify-between items-center w-full px-16 transition-colors ease-in duration-200 pb-4 border-b border-transparent"
            >
              <a
                href="/"
                class="my-2 xl:mb-0 transition-colors ease-in duration-200"
                :class="{
                  'text-glueblue-700': scrollTop > 0 || dropdownOpen,
                  'text-white': scrollTop < 1 && !dropdownOpen,
                }"
              >

                <LogosReefLogo class="w-24 h-12" />
              </a>
              <ul
                v-if="menu?.data"
                class="flex justify-center items-center uppercase font-sans text-xs min-h-10"
              >
                <li
                  v-for="topMenuItem in menu?.data?.menu_items"
                  :id="`nav-${stringToSlug(topMenuItem.name)}`"
                  :key="topMenuItem.id"
                  class="glue-navigation-item px-3"
                >
                  <a
                    :href="topMenuItem.permalink"
                    :rel="topMenuItem.rel"
                    :onclick="
                      `dataLayer.push({event: 'e_nav', menu: 'main menu', 'menuSelected': '${
                        topMenuItem.name
                      }'})`
                    "
                    class="tracking-wider hover-draw-border pb-1"
                    :class="{
                      'text-gray-500': scrollTop > 0 || dropdownOpen,
                      'text-white': scrollTop < 1 && !dropdownOpen,
                    }"
                    @mouseenter="openSubMenu(topMenuItem)"
                    v-html="topMenuItem.name"
                  />
                </li>
                <li class="pl-6">
                  <BookingBookNow
                    v-if="!hideBookNow"
                    :track="taTrackNum"
                    :special="specialOverride"
                    :dropdown="true"
                    location="navigation"
                    disableable
                  >
                    <template #default="slotProps">
                      <button
                        class="glue-btn btn-sm"
                        :class="{
                          'text-gray-800 border-gray-500 btn-ghost':
                            scrollTop > 0 && !slotProps.active,
                          'text-white': scrollTop < 1 && !slotProps.active,
                          'bg-glueblue-700 text-white': slotProps.active,
                          ' btn-ghost': !slotProps.active,
                          'text-gray-500': scrollTop > 0 || dropdownOpen,
                          'text-white': scrollTop < 1 && !dropdownOpen,
                        }"
                      >
                        Book Now
                      </button>
                    </template>
                  </BookingBookNow>
                </li>
              </ul>
            </div>
          </div>
          <div id="portal-subnavigation" />
        </div>
      </div>

      <div
        v-if="currentMenuColumns.length > 0"
        class="absolute z-0 top-0 left-0 right-0 min-h-[300px] bg-white transform duration-1000 flex justify-center font-sans2 border-t border-gray-200"
        :class="{
          'pointer-events-none': !dropdownOpen,
          'opacity-0': !dropdownOpen,
          'opacity-100': dropdownOpen,
        }"
        :style="dropdownStyles"
      >
        <ul
          v-for="column in currentMenuColumns"
          :key="column.id"
          class="m-8 uppercase"
        >
          <li class="text-xs mb-6 tracking-widest">
            {{ column.name }} &nbsp;
          </li>
          <li
            v-for="childMenuItem in column.children"
            :key="childMenuItem.id"
            class="text-xs mb-2 tracking-wider"
          >
            <a
              class="text-gray-600 hover:text-gray-800 uppercase text-xs border-b border-gray-900 hover-draw-border"
              :href="childMenuItem.permalink"
            >{{ childMenuItem.name }}</a>
          </li>
        </ul>

        <div
          v-if="currentMenuCA"
          class="text-2xs grid grid-cols-2 gap-12 p-12 ml-24"
          style="max-width: 500px"
        >
          <div
            class="bg-cover bg-center"
            :style="{
              maxWidth: '277px',
              backgroundImage: `url('${currentMenuCA.data.image.url}')`,
            }"
          />
          <div>
            <div
              class="font-light font-sans text-xl tracking-widest uppercase leading-6 text-gray-600 mb-3"
            >
              {{ currentMenuCA.name }}
            </div>
            <div class="text-xs text-gray-700 mb-4">
              {{ currentMenuCA.data.description }}
            </div>
            <a
              class="btn-ghost uppercase text-2xs text-gray-700 px-6 font-sans border-gray-500 inline-block text-center py-3 leading-none btn-active tracking-wider"
              :href="currentMenuCA.data.url"
            >{{ currentMenuCA.data.linktext }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
