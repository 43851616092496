<script>
export default {
  name: 'GlueHotelFeature',

  props: {
    devise: { type: Object, default: () => ({}) },
  },
}
</script>

<template>
  <div class="uppercase font-sans flex flex-col items-center">
    <div class="bg-white p-8 rounded-full mb-4">
      <slot />
    </div>
    <div
      class="text-gray-700 text-sm mb-1"
    >
      <slot name="title" />
    </div>
    <div class="text-gray-500 text-2xs">
      <slot name="subtitle" />
    </div>
  </div>
</template>
