<script setup lang="ts">
import type { VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    prizesCopy: VoixWysiwygFieldInterface
    rulesCopy: VoixWysiwygFieldInterface
  }
}>()

defineSlice({
  name: {
    label: 'Barbie Contest Phase 2',
    group: 'Barbie Contest',
    layouts: ['Barbie'],
  },
  tags: ['Barbie', 'Contest'],
  slots: [{ name: 'default', allowedElements: [] }],
  description: '',
  preview: 'SlicesBarbieContentsPhase2.vue',
  fields: {
    prizesCopy: {
      label: 'Copy',
      type: 'wysiwyg',
      group: 'Prizes Page',
    },
    rulesCopy: {
      label: 'Rules Copy',
      type: 'wysiwyg',
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const videos = [
  {
    name: 'Tenzel Family',
    video: '/barbie-contest/tenzel-family.mov',
    poster: '/barbie-contest/tenzel.png',
  },
  {
    name: 'Ippolito Family',
    video: '/barbie-contest/ippolito-family.mp4',
    poster: '/barbie-contest/ippolito.png',
  },
  {
    name: 'Bramlett Family',
    video: '/barbie-contest/bramlett-family.mov',
    poster: '/barbie-contest/bramlett.png',
  },
  {
    name: 'Stepien Family',
    video: '/barbie-contest/stepien-family.mp4',
    poster: '/barbie-contest/stepien.png',
  },
  {
    name: 'Nasir Family',
    video: '/barbie-contest/nasir-family.mp4',
    poster: '/barbie-contest/nasir.png',
  },
]

const currentPage: Ref<null | string> = ref(null)
const contestContainer: Ref<null | HTMLElement> = ref(null)
const contestForm: Ref<null | HTMLElement> = ref(null)
const step = ref(1)
const vote = ref(null)
function advanceStep() {
  step.value += 1

  const contestContainerPosition = contestContainer.value?.getBoundingClientRect().top
  window.scrollTo({ top: contestContainerPosition })
}

const errorMessage = ref(null)
function setError(error) {
  if (error.token) {
    errorMessage.value = `
    <div>
    Thank you for your interest!
    </div>
    <div>
    Our records indicate that you have already voted.
    </div>
    <div class="italic">
    Limit <span class="underline underline-offset-4">one vote</span> per person.
    </div>
    `
  }
  if (error.email) {
    errorMessage.value = `
    <div>
    Thank you for your interest!
    </div>
    <div>
    Our records indicate that you have already voted.
    </div>
    <div class="italic">
    Limit <span class="underline underline-offset-4">one vote</span> per person.
    </div>
    `
  }
}

const token = ref(null)

// Get the current route parameters
const route = useRoute()
if (route.query.token) {
  $voixFetch(`/api/atlantis/votes/tokens/${route.query.token}`, { method: 'get' })
    .then(() => {
      token.value = route.query.token
      step.value = 3
    })
    .catch((e) => {
      errorMessage.value = `
      <div>Our records indicate that you need to register to vote.</div>
        <div class="mt-8">
          <a href="/barbiecontest" class=" cursor-pointer w-full md:w-[300px] border border-white text-white font-barbie-headline text-[32px] md:text-[40px] uppercase py-2 md:py-4 px-12 mt-8 mb-10">
          Register
          </a>
        </div>
      `
    })

  step.value = 3
}
</script>

<template>
  <div ref="contestContainer" class="flex flex-col justify-center items-center bg-barbie-500 font-barbie px-8 text-center">
    <!-- Title -->
    <div class="container mx-auto pt-12 text-center px-8">
      <div class="text-[39px] sm:text-[48px] md:text-[60px] lg:text-[64px] xl:text-[72px] font-barbie-headline text-white leading-tight uppercase">
        Congratulations to the Tenzel Family<br>our Grand Prize Winner!
      </div>
      <div class="text-center font-semibold text-lg text-white py-4 mx-auto">
        <p class="mb-8">
          The Tenzel's have won the Barbie Bahamas Beach Vacation - Family Spring Break Getaway Contest.
          Out of over 600 inspiring video entries, Tenzel's story rose to the top. After weeks of voting and interviews, their submission stood out as a true reflection of our Barbie Bahamas Beach Vacation Family Ambassadors — showcasing empowerment, imagination, and togetherness.
        </p>

        <p class="mb-8">
          Thank you to everyone who participated and shared their creativity with us.
        </p>

        <p>Watch the videos of the 5 finalists below.</p>
      </div>
    </div>

    <div v-if="currentPage === 'prizes'" class="text-white">
      <button class=" cursor-pointer w-full md:w-[300px] border border-white text-white font-barbie-headline text-[32px] md:text-[40px] uppercase py-2 md:py-4 px-12 mt-8 mb-10" @click="currentPage = 'main'">
        Back
      </button>
      <VoixWysiwyg :field="fields.prizesCopy" />
    </div>

    <!-- Intro -->
    <!-- <div v-else class="hidden text-center text-white mt-6">
      <div v-if="step === 1" class="max-w-4xl mx-auto mb-12">
        <div>
          We&#39;re searching for the most extraordinary family to become our official Barbie
          Bahamas Brand Ambassador. Five incredible families have made it to the final
          round, and now it&#39;s <span class="font-bold">YOUR</span> turn to help choose the Grand Prize winner!
        </div>
      </div>
      <BarbieContestPhase2Message v-if="errorMessage" :message="errorMessage" />
      <template v-else-if="step === 1">
        <BarbieContestPhase2Picker :videos="videos" />
        <BarbieContestPhase2Form id="contest-form" ref="contestForm" :videos="videos" :vote="vote" :fields="fields" class="max-w-4xl mx-auto" @next-step="advanceStep" @error="setError" />
      </template>
      <BarbieContestPhase2Message v-else-if="step === 2" message="Check your email and click the confirmation link to finalize your vote.<br />Thank You!" />
      <BarbieContestPhase2Message v-else-if="step === 3" message="Your vote has been confirmed. Thank you for voting!" />
    </div> -->

    <!-- Showcase -->
    <div class="text-center text-white">
      <BarbieContestPhase2Showcase :videos="videos" />
    </div>

    <BarbieContestNavigation :fields="fields" @open-prizes="currentPage = 'prizes'" />
  </div>
</template>
