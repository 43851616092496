<script>
export default {
  name: 'GlueSliderSpecialOffer',
  props: {
    devise: {
      type: Object,
      required: true,
    },
    special: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <div
    class="relative mt-6 mb-48 lg:absolute -bottom-16 max-w-[50vh] lg:left-0 p-6 lg:p-0 lg:ml-32 bg-orange-200 lg:bg-transparent"
  >
    <div
      v-if="devise.fields.showBoxOnHero.value"
      class="bg-white p-6 -mt-24 lg:mt-0 lg:p-10 lg:w-110"
    >
      <div class="mb-16">
        <div>
          <div
            v-if="devise.fields.showDateRange.value"
            class="font-light text-red-500 text-xl tracking-wide italic mb-4"
            v-html="devise.fields.subtitle.value"
          />

          <div
            class="uppercase font-sans font-light leading-tight text-gray-600 text-3xl tracking-widest"
          >
            {{ devise.fields.title.value }}
          </div>
        </div>
      </div>
      <div v-if="devise.fields.link.enabled" class="mb-12">
        <a
          :href="devise.fields.link.value.href"
          class="uppercase text-xs font-sans text-glueblue-400 mb-4 border-b border-glueblue-400 pb-1"
        >
          {{ devise.fields.link.value.value }}
        </a>
      </div>
      <div v-if="special.included_items?.length > 0">
        <div class="uppercase text-xs font-sans text-gray-500 mb-6">
          This offer includes
        </div>
        <div class="grid grid-cols-3 justify-between w-full gap-8">
          <div
            v-for="(item, n) in special.included_items"
            :key="n"
            class="leading-tight col-span-1"
          >
            <div
              class="uppercase text-xl font-sans tracking-widest font-light text-gray-600"
            >
              {{ item.amount }}
            </div>
            <div class="uppercase text-gray-500 font-sans text-xs">
              {{ item.unit }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
