<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { Swiper as SwiperType } from 'swiper'

// Import Swiper styles
import 'swiper/css'

defineProps({
  slice: {
    type: Object,
    default: () => ({}),
  },
})

const swiper = ref<SwiperType | null>(null)

function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}

function next() {
  if (swiper.value)
    swiper.value.slideNext()
}

function prev() {
  if (swiper.value)
    swiper.value.slidePrev()
}
</script>

<template>
  <div class="relative small-container">
    <div
      class="grid-cols-1"
      :class="[
        {
          'hidden lg:grid': slice.fields.sliderOnMobile && slice.fields.sliderOnMobile.value,
          'divide-x divide-zinc-200': slice.fields.divideX && slice.fields.divideX.value,
          'divide-y divide-zinc-200': slice.fields.divideY && slice.fields.divideY.value,
          'grid': !slice.fields.sliderOnMobile || !slice.fields.sliderOnMobile.value,
        },
        `gap-${slice.fields.gap?.value || 4}`,
        `lg:grid-cols-${slice.fields.columns?.value || 3}`,
      ]"
    >
      <VoixSlice v-for="element in slice.elements" :key="element.id" :slice="element" />
    </div>
    <div v-if="slice.fields.sliderOnMobile?.value" class="block lg:hidden ml-8">
      <Swiper
        slides-per-view="auto"
        :space-between="10"
        :loop="slice.elements.length > 1"
        @swiper="onSwiper"
      >
        <SwiperSlide v-for="(s, key) in slice.elements" :key="key">
          <VoixSlice :slice="s" :slice-index="key" />
        </SwiperSlide>
      </Swiper>

      <div
        class="relative z-30 mt-12 mb-4 lg:inset-0 pointer-events-none flex lg:justify-between items-center"
      >
        <button
          class="pointer-events-auto flex lg:absolute lg:z-50 top-0 bottom-0 left-0 ml-10 -mt-8 justify-center items-center"
          @click="prev"
        >
          <div class="slider-button text-blue-300 bg-orange-50">
            <IconsArrow class="w-5 pointer-events-none" />
          </div>
        </button>
        <button
          class="pointer-events-auto flex lg:absolute lg:z-50 top-0 bottom-0 right-0 ml-4 -mt-8 justify-center items-center"
          @click="next"
        >
          <div class="slider-button text-blue-300 bg-orange-50">
            <IconsArrow class="w-5 pointer-events-none transform rotate-180" />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto;
  height: auto;
  display: flex;
}
</style>

<style>
.swiper-wrapper {
  @apply items-stretch;
}
</style>
