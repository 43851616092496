<script>
import { animate, inView, stagger } from 'motion'

export default {
  data() {
    return {
      expanded: null,
    }
  },

  mounted() {
    const root = this.$el
    inView(root, () => {
      const elements = this.$el.querySelectorAll('.animate')
      animate(elements, { y: [-25, 0], x: [25, 0], opacity: [0, 1] }, { duration: 1, delay: stagger(0.3) })

      return () => {
        const elements = this.$el.querySelectorAll('.animate')
        animate(elements, { y: [0, 25], x: [0, -25], opacity: [1, 0] }, { duration: 1, delay: stagger(0.3) })
      }
    })
  },
}
</script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.58 89.6">
    <polygon class="animate" fill="currentColor" points="40.46 89.6 0 89.6 0 49.17 40.46 89.6" />
    <polygon class="animate" fill="currentColor" points="40.46 40.43 0 40.43 0 0 40.46 40.43" />
    <polygon class="animate" fill="currentColor" points="88.58 40.43 48.12 40.43 48.12 0 88.58 40.43" />
    <polygon class="animate" fill="currentColor" points="88.58 89.6 48.12 89.6 48.12 49.17 88.58 89.6" />
  </svg>
</template>
