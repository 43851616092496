
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93n8b2LL3PkHMeta } from "/home/bun/app/pages/[...slug].vue?macro=true";
import { default as createQS8b2iWAZiMeta } from "/home/bun/app/pages/voix/blogs/create.vue?macro=true";
import { default as _91id_93uejYshbqX0Meta } from "/home/bun/app/pages/voix/blogs/edit/[id].vue?macro=true";
import { default as indexQwR3yziTnxMeta } from "/home/bun/app/pages/voix/blogs/index.vue?macro=true";
import { default as index4iZrambjxBMeta } from "/home/bun/app/pages/voix/caches/index.vue?macro=true";
import { default as index5supmA2vUIMeta } from "/home/bun/app/pages/voix/calendar-alerts/index.vue?macro=true";
import { default as createuDFMizMN3NMeta } from "/home/bun/app/pages/voix/cobrands/create.vue?macro=true";
import { default as _91id_93nzZ8BKY5pBMeta } from "/home/bun/app/pages/voix/cobrands/edit/[id].vue?macro=true";
import { default as index7SxxlXaBs9Meta } from "/home/bun/app/pages/voix/cobrands/index.vue?macro=true";
import { default as index1miU6EpwlpMeta } from "/home/bun/app/pages/voix/error-logs/index.vue?macro=true";
import { default as create8vY8wwWCjwMeta } from "/home/bun/app/pages/voix/events/create.vue?macro=true";
import { default as _91id_93o31c9C6KwnMeta } from "/home/bun/app/pages/voix/events/edit/[id].vue?macro=true";
import { default as indexhFWsXz7SUtMeta } from "/home/bun/app/pages/voix/events/index.vue?macro=true";
import { default as _91id_93cqApQOKUw7Meta } from "/home/bun/app/pages/voix/hotels/edit/[id].vue?macro=true";
import { default as index51db0x7kdWMeta } from "/home/bun/app/pages/voix/hotels/index.vue?macro=true";
import { default as createjxKj3bFUSMMeta } from "/home/bun/app/pages/voix/menus/create.vue?macro=true";
import { default as _91id_93ooeLDoUXyLMeta } from "/home/bun/app/pages/voix/menus/edit/[id].vue?macro=true";
import { default as indexahz7Cmz9JGMeta } from "/home/bun/app/pages/voix/menus/index.vue?macro=true";
import { default as indexggxhOXGx4EMeta } from "/home/bun/app/pages/voix/mona/index.vue?macro=true";
import { default as index3jbOPAmpn9Meta } from "/home/bun/app/pages/voix/mona/logs/index.vue?macro=true";
import { default as indexmMmd4M2IB5Meta } from "/home/bun/app/pages/voix/mona/pages/index.vue?macro=true";
import { default as indexyRw7126PfGMeta } from "/home/bun/app/pages/voix/movie-times/index.vue?macro=true";
import { default as create0uaZ5NDRU2Meta } from "/home/bun/app/pages/voix/press-releases/create.vue?macro=true";
import { default as _91id_93neUOTnGv1sMeta } from "/home/bun/app/pages/voix/press-releases/edit/[id].vue?macro=true";
import { default as indexqC0gkVf15nMeta } from "/home/bun/app/pages/voix/press-releases/index.vue?macro=true";
import { default as createxAVeKiYTivMeta } from "/home/bun/app/pages/voix/restaurants/create.vue?macro=true";
import { default as _91id_93VW26z9DPXrMeta } from "/home/bun/app/pages/voix/restaurants/edit/[id].vue?macro=true";
import { default as indexpqt26Pm69rMeta } from "/home/bun/app/pages/voix/restaurants/index.vue?macro=true";
import { default as createkPS8SW32LnMeta } from "/home/bun/app/pages/voix/specials-categories/create.vue?macro=true";
import { default as _91id_93NliShWP20TMeta } from "/home/bun/app/pages/voix/specials-categories/edit/[id].vue?macro=true";
import { default as indexUoDH41dEl5Meta } from "/home/bun/app/pages/voix/specials-categories/index.vue?macro=true";
import { default as createzeFEtflXUYMeta } from "/home/bun/app/pages/voix/specials/create.vue?macro=true";
import { default as _91id_93GrMstTzXozMeta } from "/home/bun/app/pages/voix/specials/edit/[id].vue?macro=true";
import { default as indexCJvuSvtMjJMeta } from "/home/bun/app/pages/voix/specials/index.vue?macro=true";
import { default as createUV8fgh2rXUMeta } from "/home/bun/app/pages/voix/travel-agents/create.vue?macro=true";
import { default as _91id_93CftSkZ1wpwMeta } from "/home/bun/app/pages/voix/travel-agents/edit/[id].vue?macro=true";
import { default as indexFDI6RdiTHuMeta } from "/home/bun/app/pages/voix/travel-agents/index.vue?macro=true";
import { default as indexgTCl6BvGJhMeta } from "/home/bun/app/pages/voix/wait-list/index.vue?macro=true";
import { default as voix_45debugc3Dy79hZXTMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix-debug.vue?macro=true";
import { default as PreviewHVxcz5WebcMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Preview.vue?macro=true";
import { default as IndexW7y91cjPBYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/settings-menu/Index.vue?macro=true";
import { default as loginKM8BHtQEEjMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/login.vue?macro=true";
import { default as callbackcZerxeEKxDMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/callback.vue?macro=true";
import { default as studiowqtmfyvkr3Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/studio.vue?macro=true";
import { default as IndexckmLMvqBpkMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Index.vue?macro=true";
import { default as EditG1HO8Etx8EMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Edit.vue?macro=true";
import { default as CreateLeIXmd2fkwMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Create.vue?macro=true";
import { default as IndexIDbZAZtpzDMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Index.vue?macro=true";
import { default as Createw5wtdqOqOrMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Create.vue?macro=true";
import { default as TranslateIiKIxweMwYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Translate.vue?macro=true";
import { default as CopylpBRpaYKgXMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Copy.vue?macro=true";
import { default as Index4YWKCcvoL4Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Index.vue?macro=true";
import { default as Indexf1ItR7f7qdMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Index.vue?macro=true";
import { default as Editae48Oss9zWMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Edit.vue?macro=true";
import { default as CreatebN69AIcYbHMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Create.vue?macro=true";
import { default as IndexQVnjPyG5BtMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Index.vue?macro=true";
import { default as CreateTOBLRbQQziMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Create.vue?macro=true";
import { default as EditOTvrOnndecMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Edit.vue?macro=true";
import { default as ProfileQhCGEDbhPwMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Profile.vue?macro=true";
import { default as indexsnvtM1ev5PMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/index.vue?macro=true";
import { default as SlicesView931jLWZylPMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicesView.vue?macro=true";
import { default as TemplateViewmnhMeb15cLMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/TemplateView.vue?macro=true";
import { default as DesignGuideViewJFW0wMBTgJMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/DesignGuideView.vue?macro=true";
import { default as slicer3IwWXw1DzYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/slicer.vue?macro=true";
import { default as voixsxpOelNnQBMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix.vue?macro=true";
import { default as SlicePreviewBrpztzHcL8Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicePreview.vue?macro=true";
import { default as previewaEou2GK5toMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/preview.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/bun/app/pages/[...slug].vue")
  },
  {
    name: "voix-blogs-create",
    path: "/voix/blogs/create",
    component: () => import("/home/bun/app/pages/voix/blogs/create.vue")
  },
  {
    name: "voix-blogs-edit-id",
    path: "/voix/blogs/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/blogs/edit/[id].vue")
  },
  {
    name: "voix-blogs",
    path: "/voix/blogs",
    component: () => import("/home/bun/app/pages/voix/blogs/index.vue")
  },
  {
    name: "voix-caches",
    path: "/voix/caches",
    component: () => import("/home/bun/app/pages/voix/caches/index.vue")
  },
  {
    name: "voix-calendar-alerts",
    path: "/voix/calendar-alerts",
    component: () => import("/home/bun/app/pages/voix/calendar-alerts/index.vue")
  },
  {
    name: "voix-cobrands-create",
    path: "/voix/cobrands/create",
    component: () => import("/home/bun/app/pages/voix/cobrands/create.vue")
  },
  {
    name: "voix-cobrands-edit-id",
    path: "/voix/cobrands/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/cobrands/edit/[id].vue")
  },
  {
    name: "voix-cobrands",
    path: "/voix/cobrands",
    component: () => import("/home/bun/app/pages/voix/cobrands/index.vue")
  },
  {
    name: "voix-error-logs",
    path: "/voix/error-logs",
    component: () => import("/home/bun/app/pages/voix/error-logs/index.vue")
  },
  {
    name: "voix-events-create",
    path: "/voix/events/create",
    component: () => import("/home/bun/app/pages/voix/events/create.vue")
  },
  {
    name: "voix-events-edit-id",
    path: "/voix/events/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/events/edit/[id].vue")
  },
  {
    name: "voix-events",
    path: "/voix/events",
    component: () => import("/home/bun/app/pages/voix/events/index.vue")
  },
  {
    name: "voix-hotels-edit-id",
    path: "/voix/hotels/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/hotels/edit/[id].vue")
  },
  {
    name: "voix-hotels",
    path: "/voix/hotels",
    component: () => import("/home/bun/app/pages/voix/hotels/index.vue")
  },
  {
    name: "voix-menus-create",
    path: "/voix/menus/create",
    component: () => import("/home/bun/app/pages/voix/menus/create.vue")
  },
  {
    name: "voix-menus-edit-id",
    path: "/voix/menus/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/menus/edit/[id].vue")
  },
  {
    name: "voix-menus",
    path: "/voix/menus",
    component: () => import("/home/bun/app/pages/voix/menus/index.vue")
  },
  {
    name: "voix-mona",
    path: "/voix/mona",
    component: () => import("/home/bun/app/pages/voix/mona/index.vue")
  },
  {
    name: "voix-mona-logs",
    path: "/voix/mona/logs",
    component: () => import("/home/bun/app/pages/voix/mona/logs/index.vue")
  },
  {
    name: "voix-mona-pages",
    path: "/voix/mona/pages",
    component: () => import("/home/bun/app/pages/voix/mona/pages/index.vue")
  },
  {
    name: "voix-movie-times",
    path: "/voix/movie-times",
    component: () => import("/home/bun/app/pages/voix/movie-times/index.vue")
  },
  {
    name: "voix-press-releases-create",
    path: "/voix/press-releases/create",
    component: () => import("/home/bun/app/pages/voix/press-releases/create.vue")
  },
  {
    name: "voix-press-releases-edit-id",
    path: "/voix/press-releases/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/press-releases/edit/[id].vue")
  },
  {
    name: "voix-press-releases",
    path: "/voix/press-releases",
    component: () => import("/home/bun/app/pages/voix/press-releases/index.vue")
  },
  {
    name: "voix-restaurants-create",
    path: "/voix/restaurants/create",
    component: () => import("/home/bun/app/pages/voix/restaurants/create.vue")
  },
  {
    name: "voix-restaurants-edit-id",
    path: "/voix/restaurants/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/restaurants/edit/[id].vue")
  },
  {
    name: "voix-restaurants",
    path: "/voix/restaurants",
    component: () => import("/home/bun/app/pages/voix/restaurants/index.vue")
  },
  {
    name: "voix-specials-categories-create",
    path: "/voix/specials-categories/create",
    component: () => import("/home/bun/app/pages/voix/specials-categories/create.vue")
  },
  {
    name: "voix-specials-categories-edit-id",
    path: "/voix/specials-categories/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/specials-categories/edit/[id].vue")
  },
  {
    name: "voix-specials-categories",
    path: "/voix/specials-categories",
    component: () => import("/home/bun/app/pages/voix/specials-categories/index.vue")
  },
  {
    name: "voix-specials-create",
    path: "/voix/specials/create",
    component: () => import("/home/bun/app/pages/voix/specials/create.vue")
  },
  {
    name: "voix-specials-edit-id",
    path: "/voix/specials/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/specials/edit/[id].vue")
  },
  {
    name: "voix-specials",
    path: "/voix/specials",
    component: () => import("/home/bun/app/pages/voix/specials/index.vue")
  },
  {
    name: "voix-travel-agents-create",
    path: "/voix/travel-agents/create",
    component: () => import("/home/bun/app/pages/voix/travel-agents/create.vue")
  },
  {
    name: "voix-travel-agents-edit-id",
    path: "/voix/travel-agents/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/travel-agents/edit/[id].vue")
  },
  {
    name: "voix-travel-agents",
    path: "/voix/travel-agents",
    component: () => import("/home/bun/app/pages/voix/travel-agents/index.vue")
  },
  {
    name: "voix-wait-list",
    path: "/voix/wait-list",
    component: () => import("/home/bun/app/pages/voix/wait-list/index.vue")
  },
  {
    name: "voix-debug",
    path: "/voix-debug",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix-debug.vue")
  },
  {
    name: "voix-preview-release-entry-en",
    path: "/preview-release/:releaseId/:previewSecret",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Preview.vue")
  },
  {
    name: "voix",
    path: "/voix",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix.vue"),
    children: [
  {
    name: "voix-settings-en",
    path: "",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/settings-menu/Index.vue")
  },
  {
    name: "voix-login-en",
    path: "login",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/login.vue")
  },
  {
    name: "voix-auth-callback-en",
    path: "auth/callback",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/callback.vue")
  },
  {
    name: "voix-studio-en",
    path: "studio",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/studio.vue")
  },
  {
    name: "voix-settings-head-tags-en",
    path: "head-tags",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Index.vue")
  },
  {
    name: "voix-settings-head-tags-edit-en",
    path: "head-tags/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Edit.vue")
  },
  {
    name: "voix-settings-head-tags-create-en",
    path: "head-tags/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Create.vue")
  },
  {
    name: "voix-settings-pages-en",
    path: "pages",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Index.vue")
  },
  {
    name: "voix-settings-pages-create-en",
    path: "pages/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Create.vue")
  },
  {
    name: "voix-settings-pages-translate-en",
    path: "pages/translate",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Translate.vue")
  },
  {
    name: "voix-settings-pages-copy-en",
    path: "pages/copy",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Copy.vue")
  },
  {
    name: "voix-settings-releases-en",
    path: "releases",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Index.vue")
  },
  {
    name: "voix-settings-redirects-en",
    path: "redirects",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Index.vue")
  },
  {
    name: "voix-settings-redirects-edit-en",
    path: "redirects/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Edit.vue")
  },
  {
    name: "voix-settings-redirects-create-en",
    path: "redirects/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Create.vue")
  },
  {
    name: "voix-settings-users-en",
    path: "users",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Index.vue")
  },
  {
    name: "voix-settings-users-create-en",
    path: "users/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Create.vue")
  },
  {
    name: "voix-settings-users-edit-en",
    path: "users/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Edit.vue")
  },
  {
    name: "voix-settings-users-profile-en",
    path: "profile",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Profile.vue")
  },
  {
    name: "voix-cookbook-en",
    path: "cookbook",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/index.vue")
  },
  {
    name: "voix-slicer-en",
    path: "slicer",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/slicer.vue"),
    children: [
  {
    name: "slicer-slices-en",
    path: "",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicesView.vue")
  },
  {
    name: "slicer-template-en",
    path: "templates/:templateName",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/TemplateView.vue")
  },
  {
    name: "slicer-design-guide-en",
    path: "design-guide",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/DesignGuideView.vue")
  }
]
  }
]
  },
  {
    name: "slicer-slice-en",
    path: "/voix/slice-preview/:sliceName",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicePreview.vue")
  },
  {
    name: "cookbook-preview-en",
    path: "/voix/cookbook-preview/:recipe",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/preview.vue")
  }
]