<script setup lang="ts">
import type { VoixTextFieldInterface } from '@voix/types'
import { reactive } from 'vue'

const props = defineProps<{
  fields: {
    locationId: VoixTextFieldInterface
  }
}>()

const state = reactive({
  timeout: 20,
  errorLoadingMenu: false,
  menuApi: null,
})

function pollForLoading() {
  setTimeout(() => {
    state.timeout--
    if (typeof MenusApi !== 'undefined')
      initMenu()

    else if (state.timeout > 0)
      pollForLoading()

    else
      state.errorLoadingMenu = true
  }, 100)
}

function initMenu() {
  state.menuApi = new MenusApi('ke09z8icq4xu8uiiccighy1bw')

  if (state.menuApi) {
    state.menuApi.loadMenusForLocation(
      props.fields.locationId.value,
      'single_platform_menu',
    )

    state.menuApi.setPrimaryBackgroundColor('rgb(249, 248, 242)')
    state.menuApi.setSecondaryBackgroundColor('rgb(249, 248, 242)')
    state.menuApi.setSectionTitleBackgroundColor('rgb(249, 248, 242)')
    state.menuApi.setPrimaryFontFamily('Lato')
    state.menuApi.setSectionDescFontFamily('Lato')
  }
}

if (typeof window !== 'undefined') {
  // Docs: http://docs.singleplatform.com/spv2/javascript-api/
  const singlePlatformScript = document.createElement('script')
  singlePlatformScript.setAttribute(
    'src',
    'https://menus.singleplatform.co/jsload?load=menus.2&apiKey=ke09z8icq4xu8uiiccighy1bw',
  )
  document.body.appendChild(singlePlatformScript)

  pollForLoading()
}
defineSlice({
  name: { label: 'Single Platform Menu', group: 'Booking', layouts: ['Paranza'] },
  preview: 'SinglePlatform.jpg',
  tags: ['Booking', 'Vendor'],
  fields: {
    locationId: {
      type: 'text',
      label: 'Single Platform Location ID',
      name: 'locationId',
    },
  },
  slots: [],
})
</script>

<template>
  <div class="container py-12">
    <div class="relative z-0">
      <div id="single_platform_menu" />
      <div id="sp_main" class="hidden sp_tabs" />
    </div>

    <div v-show="state.errorLoadingMenu" class="text-lg text-center text-paranza-400">
      Our apologies. There was an error loading the menu. Please try again later.
    </div>
  </div>
</template>
