<script>
export default {
  name: 'EyeIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 12">
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="currentcolor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".8"
    >
      <path
        d="M15.645 4.983c.12.161.12.382 0 .543-.803 1.076-3.98 4.983-7.777 4.983C4.07 10.51.893 6.602.09 5.526c-.12-.161-.12-.382 0-.543C.893 3.908 4.07 0 7.868 0c3.797 0 6.974 3.908 7.777 4.983z"
        transform="translate(1 1)"
      />
      <path
        d="M10.515 5.255c0 1.462-1.185 2.647-2.647 2.647-1.463 0-2.648-1.185-2.648-2.647 0-1.463 1.185-2.648 2.648-2.648 1.462 0 2.647 1.185 2.647 2.648z"
        transform="translate(1 1)"
      />
    </g>
  </svg>
</template>
