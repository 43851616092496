<script>
export default {
  name: 'GlueFitnessIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 26">
    <path
      fill="currentcolor"
      fill-rule="evenodd"
      stroke-width=".3"
      d="M14.514 16.183h20.164v-4.548H14.514v4.548zm-1.482 7.056a.74.74 0 01-.74.74H8.379a.742.742 0 01-.741-.74V3.222c0-.408.333-.74.741-.74h3.913a.74.74 0 01.74.74v20.017zm-6.876-5.786H3.221a.742.742 0 01-.741-.742V9.75c0-.409.333-.741.741-.741h2.935v8.444zM36.159 3.222a.74.74 0 01.74-.74h3.914a.74.74 0 01.74.74v20.017a.74.74 0 01-.74.74h-3.914a.74.74 0 01-.74-.74V3.222zm6.874 5.787h2.936a.74.74 0 01.741.74v6.962c0 .41-.332.742-.741.742h-2.936V9.009zM36.899 25.46h3.914a2.224 2.224 0 002.221-2.221v-4.306h2.936a2.225 2.225 0 002.221-2.222V9.749a2.225 2.225 0 00-2.222-2.221h-2.936V3.222A2.224 2.224 0 0040.812 1h-3.914a2.225 2.225 0 00-2.221 2.222v6.932H14.514V3.222A2.225 2.225 0 0012.292 1H8.379a2.225 2.225 0 00-2.222 2.222v4.306H3.222A2.224 2.224 0 001 9.749v6.962c0 1.225.997 2.222 2.222 2.222h2.935v4.306c0 1.224.997 2.221 2.222 2.221h3.913a2.225 2.225 0 002.222-2.221v-5.575h20.164v5.575c0 1.224.997 2.221 2.221 2.221h0z"
    />
  </svg>
</template>
