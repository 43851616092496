<script setup lang="ts">
import type { VoixLinkFieldInterface, VoixMediaFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'
import { useWindowSize } from '@vueuse/core'

defineProps<{
  fields: {
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    link: VoixLinkFieldInterface
    color: VoixSelectFieldInterface
    image: VoixMediaFieldInterface
    cardPosition: VoixSelectFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Barbie Color Card', group: 'Barbie', layouts: ['Barbie'] },
  description: '',
  tags: ['Barbie', 'Card'],
  preview: 'SlicesBarbieColorCard.jpg',
  fields: {
    title: {
      label: 'Title',
      type: 'text',
    },
    copy: {
      label: 'Copy',
      type: 'wysiwyg',
    },
    link: {
      label: 'Link',
      type: 'link',
    },
    color: {
      label: 'Color',
      type: 'select',
      options: { lightPink: 'Light Pink', pink: 'Pink', blue: 'Blue', yellow: 'Yellow' },
      default: 'lightPink',
    },
    image: {
      label: 'Image',
      type: 'media',
      breakpoints: {
        default: {
          width: 540,
          height: 300,
        },
        lg: {
          width: 800,
          height: 500,
        },
      },
      default: 'https://images.unsplash.com/photo-1507525428034-b723cf961d3e?q=80&w=800&h=800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    cardPosition: {
      label: 'Card Position',
      type: 'select',
      options: { left: 'Left', right: 'Right' },
      default: 'left',
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      title: {
        value: 'Make it a beach day!',
      },
      copy: {
        value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, vestibulum ligula sit amet, elementum ex. Nullam sit amet nisi nec nunc ultricies ultricies. Nullam nec nunc nec nunc ultricies ultricies.',
      },
      link: {
        value: {
          text: 'Learn More',
          target: '_self',
          relationship: null,
          href: '/new-page',
        },
      },
      color: {
        value: 'lightPink',
      },
      image: {
        value: 'https://images.unsplash.com/photo-1507525428034-b723cf961d3e?q=80&w=800&h=800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
    },
  }],
})

const { width } = useWindowSize()
</script>

<template>
  <div
    class="flex my-12" :class="{
      'justify-start': fields.cardPosition.value === 'left',
      'justify-end': fields.cardPosition.value === 'right',
      'justify-center': width > 1680,
    }"
  >
    <div
      class="flex flex-col p-8 md:w-[80%] max-w-7xl" :class="{
        'lg:flex-row': fields.cardPosition.value === 'left',
        'lg:flex-row-reverse': fields.cardPosition.value === 'right',
        'bg-barbie-100': fields.color.value === 'lightPink',
        'bg-barbie-300': fields.color.value === 'yellow',
        'bg-barbie-400': fields.color.value === 'blue',
        'bg-barbie-500': fields.color.value === 'pink',
      }"
    >
      <div class="lg:w-1/4 bg-white py-8 px-6">
        <div
          class="font-barbie-headline text-3xl uppercase" :class="{
            'text-barbie-100': fields.color.value === 'lightPink',
            'text-barbie-400': fields.color.value === 'blue',
            'text-barbie-500': fields.color.value === 'pink' || fields.color.value === 'yellow',
          }"
        >
          {{ fields.title.value }}
        </div>

        <VoixWysiwyg :field="fields.copy" class="pt-4 font-barbie text-zinc-600 font-medium" />

        <VoixLink :field="fields.link" class="inline-block mt-4 px-4  py-1 font-barbie-headline bg-barbie-500 text-white text-center text-xl" />
      </div>

      <div
        class="w-full lg:w-3/4 flex items-center" :class="{
          'lg:pl-12': fields.cardPosition.value === 'left',
          'lg:pr-12': fields.cardPosition.value === 'right',
        }"
      >
        <div class="border-8 border-white w-full aspect-video">
          <VoixMedia :field="fields.image" class="object-cover w-full h-full" />
        </div>
      </div>
    </div>
  </div>
</template>
