<script lang="ts" setup>
import type { VoixCheckboxFieldInterface, VoixColorFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface } from '@voix/types'
import { Countdown } from 'vue3-flip-countdown'

const props = defineProps<{
  fields: {
    deadline: VoixTextFieldInterface
    mainFlipColor: VoixColorFieldInterface
    secondFlipColor: VoixColorFieldInterface
    mainFlipBackgroundColor: VoixColorFieldInterface
    secondFlipBackgroundColor: VoixColorFieldInterface
    labelColor: VoixColorFieldInterface
    labelSize: VoixTextFieldInterface
    labelPosition: VoixSelectFieldInterface
    showLabels: VoixCheckboxFieldInterface
    showSeconds: VoixCheckboxFieldInterface
    showFlipAnimation: VoixCheckboxFieldInterface
    countdownSize: VoixTextFieldInterface
    countdownWeight: VoixTextFieldInterface
    separatorPadding: VoixTextFieldInterface
    separatorPosition: VoixTextFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Special Offers Countdown', group: 'Specials', layouts: ['Atlantisbahamas'] },
  description: 'Special Offers Countdown Slice',
  tags: ['Countdown', 'Special'],

  fields: {
    deadline: {
      type: 'text',
      label: 'Deadline',
      default: '2024-12-12',
      description: 'Deadline Format: YYYY-MM-DD',
    },
    mainFlipColor: {
      type: 'color',
      label: 'Main Flip Color',
      default: '#ffffff',
    },
    secondFlipColor: {
      type: 'color',
      label: 'Second Flip Color',
      default: '#ffffff',
    },
    mainFlipBackgroundColor: {
      type: 'color',
      label: 'Main Flip Background Color',
      default: '#222222',
    },
    secondFlipBackgroundColor: {
      type: 'color',
      label: 'Second Flip Background Color',
      default: '#393939',
    },
    labelColor: {
      type: 'color',
      label: 'Label Color',
      default: '#000000',
    },
    labelSize: {
      type: 'text',
      label: 'Label Size',
      default: '1.2rem',
      description: 'Size Format: 1.2rem or 19.2px',
    },
    labelPosition: {
      type: 'select',
      label: 'Label Position',
      options: { top: 'Top', bottom: 'Bottom' },
      default: 'top',
      description: 'Requires page refresh to take effect',
    },
    showLabels: {
      type: 'checkbox',
      label: 'Show Labels',
      default: true,
    },
    showSeconds: {
      type: 'checkbox',
      label: 'Show Seconds',
      default: true,
      description: 'Requires page refresh to take effect',
    },
    showFlipAnimation: {
      type: 'checkbox',
      label: 'Show Flip Animation',
      default: false,
      description: 'Requires page refresh to take effect',
    },
    countdownSize: {
      type: 'text',
      label: 'Countdown Size',
      default: '3rem',
      description: 'Size Format: 3rem or 48px',
    },
    countdownWeight: {
      type: 'text',
      label: 'Countdown Weight',
      default: 'normal',
      description: 'Weight Format: lighter, light, normal, bold, bolder',
    },
    separatorPadding: {
      type: 'text',
      label: 'Separator Padding',
      default: '1rem',
      description: 'Padding Format: 1rem or 8px',
    },
    separatorPosition: {
      type: 'text',
      label: 'Separator Position',
      default: '0.3rem',
      description: 'Position Format: 0.3rem or 4px',
    },
  },
  preview: 'SlicesSpecialOffersCountdown.jpg',
  templates: [{
    label: 'Special Offers Countdown',
    fields: {},
  }],
})

const flipClockFontSize = computed(() => {
  return props.fields.countdownSize.value
})
const flipClockFontWeight = computed(() => {
  return props.fields.countdownWeight.value
})
const flipClockSeparatorPadding = computed(() => {
  return props.fields.separatorPadding.value
})
const flipClockSeparatorPosition = computed(() => {
  return props.fields.separatorPosition.value
})

onMounted(() => {
  nextTick(() => {
    if (props.fields.labelPosition.value === 'top') {
      const flipClockSlots = document.querySelectorAll('.flip-clock__slot')
      flipClockSlots.forEach((slot) => {
        slot.parentElement?.insertBefore(slot, slot.parentElement.firstChild)
      })
    }

    if (!props.fields.showFlipAnimation.value) {
      const flipClockPieces = document.querySelectorAll('.flip-clock__piece')

      flipClockPieces.forEach((piece, index) => {
        const isLastPiece = index === flipClockPieces.length - 1
        const isThirdPiece = index === 2
        const fourthPieceIsHidden = flipClockPieces[3] && window.getComputedStyle(flipClockPieces[3]).display === 'none'

        if (!isLastPiece && !(isThirdPiece && fourthPieceIsHidden) && window.getComputedStyle(piece).display !== 'none') {
          const span = document.createElement('span')
          span.textContent = ':'
          span.className = 'flip-clock__spacer' // Add the class to the span
          piece.parentElement?.insertBefore(span, piece.nextSibling)
        }
      })
    }

    const flipClockSlot = document.querySelectorAll('.flip-clock__slot')
    flipClockSlot.forEach((slot) => {
      slot.classList.add('atlantis-theme')
      slot.classList.add('headline')
    })
    const noAnimationCards = document.querySelectorAll('.no-animation__card')
    noAnimationCards.forEach((card) => {
      card.classList.add('atlantis-theme')
      card.classList.add('headline')
    })
  })
})
</script>

<template>
  <div class="w-full">
    <ClientOnly>
      <Countdown
        :deadline="fields.deadline.value"
        :main-color="fields.mainFlipColor.value"
        :second-flip-color="fields.secondFlipColor.value"
        :main-flip-background-color="fields.mainFlipBackgroundColor.value"
        :second-flip-background-color="fields.secondFlipBackgroundColor.value"
        :label-color="fields.labelColor.value"
        :label-size="fields.labelSize.value"
        :show-labels="fields.showLabels.value"
        :show-seconds="fields.showSeconds.value"
        :countdown-size="fields.countdownSize.value"
        :flip-animation="fields.showFlipAnimation.value"
      />
    </ClientOnly>
  </div>
</template>

<style>
.flip-clock__spacer {
  position: relative;
  bottom: v-bind(flipClockSeparatorPosition);
  font-size: v-bind(flipClockFontSize);
  padding-left: v-bind(flipClockSeparatorPadding);
  padding-right: v-bind(flipClockSeparatorPadding);
}
.no-animation__card {
  font-size: v-bind(flipClockFontSize) !important;
  font-weight: v-bind(flipClockFontWeight) !important;
  line-height: 0.85 !important;
}
</style>
