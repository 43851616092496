<script setup lang="ts">
import type { Swiper as SwiperType } from 'swiper'
import { resolveFieldsArray } from '@voix/composables/useField'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

const props = defineProps({
  slice: {
    type: Object,
    default: () => ({}),
  },
  fields: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  name: { label: 'Vertical Scroll Container', group: 'Vertical Scroll Navigation', layouts: ['Atlantisbahamas'] },
  preview: 'SlicesGlueVerticalScrollerVerticalScrollContainer.jpg',
  description: 'Vertical Scroll Container',
  tags: ['Navigation'],

  slots: [{ name: 'default' }],
  fields: { link: { type: 'link', label: 'Link', enabled: false } },
  templates: [{ label: 'Vertical Scroll Container', fields: [] }],
})

const windowWidth = ref<number>(0)
const theSwiper = ref<SwiperType | null>(null)
const childSliceFields: any = []

props.slice.elements.forEach((childSlice: any) => {
  const fields = resolveFieldsArray(childSlice)
  childSliceFields.push(fields)
})

const currentSlide = computed(() => {
  if (theSwiper.value)
    return theSwiper.value.activeIndex
  return 0
})

const isMobile = computed(() => {
  return windowWidth.value < 1024
})

function onSwiper(swiperInstance: SwiperType) {
  theSwiper.value = swiperInstance
}

function goToSlide(index: number) {
  theSwiper.value?.slideTo(index)
}

onMounted(() => {
  windowWidth.value = window.innerWidth
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth
  })
})
onUnmounted(() => {
  window.removeEventListener('resize', () => {
    windowWidth.value = window.innerWidth
  })
})
</script>

<template>
  <div>
    <!-- Mobile Slider -->
    <div v-if="isMobile" class="w-full overflow-hidden px-8">
      <div
        class="font-sans2 uppercase font-bold font-stone-700 rounded-sm transition-all duration-300 w-[10000px]"
        :style="{
          transform: `translateX(-${currentSlide * 150}px)`,
        }"
      >
        <ul class="flex mb-6">
          <li
            v-for="(childSlice, key) in childSliceFields" :key="childSlice.id" class="cursor-pointer w-[150px] mr-[20px]"
            :class="{ 'text-red-500': currentSlide === key }" @click="goToSlide(key)"
          >
            <span v-if="childSlice.title">
              {{ childSlice.title.value }}
            </span>
          </li>
        </ul>

        <div class="relative h-2 bg-stone-300">
          <div
            class="absolute top-0 bottom-0 bg-red-500 rounded-sm transition-all duration-300 w-[150px]" :style="{
              transform: `translateX(${currentSlide * 170}px)`,
            }"
          />
        </div>
      </div>

      <div class="mt-4">
        <Swiper
          :slides-per-view="1" :centered-slides="true"
          class="w-full h-[250px]"
          @swiper="onSwiper"
        >
          <SwiperSlide v-for="childSlice in childSliceFields" :key="childSlice.id" class="w-full h-[600px]">
            <VoixMedia :field="childSlice.image" class="w-full h-full object-cover" />
          </SwiperSlide>
        </Swiper>

        <div class="p-8 bg-white">
          <template v-for="(childSlice, key) in childSliceFields" :key="childSlice.id">
            <div v-show="currentSlide === key">
              <div
                v-if="childSlice.title"
                class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
              >
                {{ childSlice.title.value }}
              </div>
              <div
                v-if="childSlice.description" class="text-sm font-sans2 font-light antialiased mt-1"
                v-html="childSlice.description.value ?? ''"
              />
              <a
                v-if="childSlice.link && childSlice.link.enabled" :href="childSlice.link.value.href" :target="childSlice.link.value.target"
                class="mt-4"
              >{{
                childSlice.link.value.text }}</a>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Desktop Slider -->
    <div v-else class="pt-12 pb-40">
      <div class="grid lg:grid-cols-4">
        <div class="flex justify-end py-24 px-12">
          <div class="w-56 flex flex-col justify-between">
            <ul class="my-8 uppercase font-sans font-light text-sm pr-8 space-y-8 text-right">
              <li
                v-for="(childSlice, key) in childSliceFields" :key="childSlice.id"
                class="cursor-pointer transition-all duration-200" :class="{
                  'text-lg text-red-500 font-medium': currentSlide === key,
                }" @click="goToSlide(key)"
              >
                <span v-if="childSlice.title">{{ childSlice.title.value }}</span>
              </li>
            </ul>

            <div class="text-right">
              <a
                v-if="fields.link.enabled" :href="`${fields.link.value.text}`"
                class="mt-4 btn-ghost !text-white"
              >

                {{ fields.link.value.text }}
              </a>
            </div>
          </div>
          <div class="relative w-1 bg-stone-300 rounded-full">
            <!-- Red Track -->
            <div
              class="absolute top-0 left-0 right-0 w-1 bg-red-500 transition-all duration-300 rounded-full"
              :style="{ height: `${currentSlide * 53 + 25}px` }"
            />

            <!-- Handle -->
            <div
              class="absolute top-0 left-0 right-0 -ml-4 w-8 h-8 rounded-full bg-red-500 p-[2px] transition-all duration-300"
              :style="{ marginTop: `${currentSlide * 53 + 25}px` }"
            >
              <div class="w-full h-full rounded-full border-[0.5px] border-orange-100 p-[2px]">
                <div class="w-full h-full rounded-full border-[0.5px] border-orange-100 p-[2px]" />
              </div>
            </div>
          </div>
        </div>

        <div class="relative col-span-3">
          <Swiper
            :slides-per-view="1" :centered-slides="true"
            class="w-full h-[600px]"
            @swiper="onSwiper"
          >
            <SwiperSlide v-for="childSlice in childSliceFields" :key="childSlice.id" class="w-full h-[600px]">
              <VoixMedia :field="childSlice.image" class="w-full h-full object-cover" />
            </SwiperSlide>
          </Swiper>

          <div class="absolute z-10 bottom-0 right-0 p-8 bg-white w-1/3 -mb-24">
            <div v-for="(childSlice, key) in childSliceFields" :key="childSlice.id">
              <div v-show="currentSlide === key">
                <div
                  v-if="childSlice.title.enabled"
                  class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
                >
                  {{ childSlice.title.value }}
                </div>
                <div
                  v-if="childSlice.description" class="text-sm font-sans2 font-light antialiased mt-1"
                  v-html="childSlice.description.value ?? ''"
                />
                <a
                  v-if="childSlice.link && childSlice.link.enabled" :href="childSlice.link.href" :target="childSlice.link.target"
                  class="mt-4 btn-ghost  !text-white !border-white"
                >{{
                  childSlice.link.text }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
