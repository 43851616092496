<script>
export default {
  name: 'FeaturedEventCard',

  props: {
    fields: { type: Object },
    cardNumber: {
      type: String,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
  },

  computed: {
    selectedEvent() {
      if (this.fields[`${this.cardNumber}CardEventSlug`].value) {
        const cardEvent = this.events.find(
          event =>
            event.slug === this.fields[`${this.cardNumber}CardEventSlug`].value,
        )

        if (cardEvent)
          return cardEvent
      }

      return null
    },
  },

  methods: {
    getVenueName(venue) {
      if (venue) {
        if (venue === 'None')
          return ''

        if (venue === 'JokersWild')
          return 'Joker\'s Wild'

        if (venue === 'MarinaVillage')
          return 'Marina Village'

        if (venue === 'ConferencesAndEvents')
          return 'Conference & Event Center'

        return venue
      }
    },

    getVenueUrl(venue) {
      if (venue === 'Aura')
        return '/things-to-do/entertainment/aura-nightclub'

      if (venue === 'JokersWild')
        return '/things-to-do/entertainment/jokers-wild-comedy-club'

      if (venue === 'Crush')
        return '/kids/teens/crush'

      if (venue === 'Theater')
        return '/things-to-do/entertainment/atlantis-theatre'
    },
  },
}
</script>

<template>
  <div class="flex relative">
    <div
      v-if="fields.firstCardMegaMode.value"
      class="absolute z-10 top-0 left-0 bg-glueblue-600 text-white -ml-4 -mt-4 p-6 flex flex-col items-center space-y-1"
    >
      <div class="text-xs tracking-widest uppercase font-thin font-sans">
        featured
      </div>
      <span class="h-[20px] border-r border-white/50">&nbsp;</span>
      <div class="text-base tracking-wider italic">
        Event
      </div>
    </div>

    <div
      v-if="fields[`${cardNumber}CardDisplayMode`].value === 'eventcard'" class="w-full  bg-white flex" :class="{
        'lg:w-[400px]': !fields.firstCardMegaMode.value,
        'lg:w-[800px]': fields.firstCardMegaMode.value,
      }"
    >
      <EventsModalEventCard :show-date-square="false" :event="selectedEvent" :siblings="[]" />
    </div>

    <div
      v-else
      class="w-full relative bg-white flex justify-stretch items-center text-center animation-ease-up flex-col"
      :class="{
        'lg:w-[400px]': !fields.firstCardMegaMode.value,
        'lg:w-[800px]': fields.firstCardMegaMode.value,
      }"
    >
      <div
        class="w-full h-[245px] flex justify-center bg-gradient-to-b from-orange-300 to-white" :class="{
          'h-[245px]': !fields.firstCardMegaMode.value,
          'h-[445px]': fields.firstCardMegaMode.value,
        }"
      >
        <VoixMedia
          :field="fields[`${cardNumber}CardImage`]" class="object-cover" :class="{
            'w-full': fields.firstCardMegaMode.value,
          }"
        />
      </div>

      <div
        class="w-full bg-white flex-grow p-10 py-10 flex text-left" :class="{
          'flex-col justify-between': !fields.firstCardMegaMode.value,
        }"
      >
        <div
          class="flex flex-col space-y-0.5" :class="{
            'w-3/4': fields.firstCardMegaMode.value,
          }"
        >
          <h3
            class="mb-2 headline-3 uppercase text-gray-800 line-clamp-3" :class="{
              'h-16': !fields.firstCardMegaMode.value,
            }"
          >
            {{ fields[`${cardNumber}CardTitle`].value }}
          </h3>

          <div v-if="fields[`${cardNumber}CardDate`].value" class="flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                <circle cx="12" cy="12" r="9" />
                <path d="M12 7v3.764a2 2 0 0 0 1.106 1.789L16 14" />
              </g>
            </svg>

            <div class="font-sans2 text-sm text-zinc-600">
              {{ fields[`${cardNumber}CardDate`].value }}
            </div>
          </div>
          <div class="flex items-center space-x-2 text-sm h-10">
            <svg
              v-if="getVenueUrl(fields[`${cardNumber}CardVenue`].value)" xmlns="http://www.w3.org/2000/svg"
              class="-ml-[3px] w-7 h-7" viewBox="0 0 24 24"
            >
              <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                <path
                  d="M5 9.923c0 4.852 4.244 8.864 6.123 10.402c.27.22.405.332.606.388c.156.044.386.044.542 0c.201-.056.336-.167.606-.388C14.756 18.787 19 14.775 19 9.923a6.885 6.885 0 0 0-2.05-4.895A7.04 7.04 0 0 0 12 3a7.04 7.04 0 0 0-4.95 2.028A6.884 6.884 0 0 0 5 9.923Z"
                />
                <path d="M10 9a2 2 0 1 0 4 0a2 2 0 0 0-4 0Z" />
              </g>
            </svg>
            <div>
              <a
                v-if="getVenueUrl(fields[`${cardNumber}CardVenue`].value)
                  && fields[`${cardNumber}CardVenue`].value
                " :href="getVenueUrl(fields[`${cardNumber}CardVenue`].value)"
                class="font-sans2 text-glueblue-500 font-light"
              >
                {{ getVenueName(fields[`${cardNumber}CardVenue`].value) }}
              </a>
              <div v-else-if="fields[`${cardNumber}CardVenue`].value" class="font-sans2 font-light">
                {{ getVenueName(fields[`${cardNumber}CardVenue`].value) }}
              </div>
            </div>
          </div>

          <div
            class="font-sans2 text-sm font-light text-gray-500" :class="{
              'pb-8': !fields.firstCardMegaMode.value,
            }"
          >
            {{ fields[`${cardNumber}CardCopy`].value }}
          </div>
        </div>
        <div
          class="flex-1 flex flex-col space-y-4" :class="{
            'justify-end': !fields.firstCardMegaMode.value,
          }"
        >
          <a
            v-if="fields[`${cardNumber}CardBookingUrl`].enabled"
            class="block w-full glue-btn bg-glueblue-600 text-white border-zinc-600 uppercase body-11 tracking-widest py-1 px-4" :href="fields[`${cardNumber}CardBookingUrl`].value"
            target="_blank"
          >
            Book Now
          </a>
          <VoixLink :field="fields[`${cardNumber}CardLink`]" class="block w-full btn-ghost" />
        </div>
      </div>
    </div>
  </div>
</template>
