<script>
import { animate, inView, stagger } from 'motion'

export default {
  name: 'WffEvent',

  props: {
    devise: { type: Object },
    event: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      expanded: null,
    }
  },

  mounted() {
    const root = this.$el
    inView(root, () => {
      const elements = this.$el.querySelectorAll('.animate')
      animate(elements, { y: [100, 0], opacity: [0, 1] }, { duration: 1, delay: stagger(0.3) })
    })
  },

  methods: {
    getVenueName(venue) {
      if (venue) {
        if (venue === 'JokersWild')
          return 'Joker\'s Wild'

        if (venue === 'MarinaVillage')
          return 'Marina Village'

        if (venue === 'ConferencesAndEvents')
          return 'Conference & Event Center'

        return venue
      }
    },
  },
}
</script>

<template>
  <div class="grid lg:grid-cols-2">
    <div class="animate">
      <img :src="event.image">
    </div>
    <div class="animate bg-zinc-100 p-6 font-sans2">
      <div class="text-glueblue-600 font-bold uppercase text-xl font-wff-headline">
        {{ event.title }}
      </div>
      <div class="text-glueblue-600 uppercase font-wff">
        <date-formatter :date="event.start_date" format="MMMM D, YYYY" />
      </div>
      <div class="text-glueblue-600 uppercase font-wff">
        <date-formatter :date="event.start_date" format="h:mm a" />
      </div>
      <div class="text-glueblue-600 font-bold uppercase font-wff">
        {{ getVenueName(event.venue) }}
      </div>

      <div class="mt-6" v-html="event.content" />

      <a
        v-for="(link, key) in event.links" :key="key"
        :href="link.href" target="link.target" :aria-label="`Learn more about ${event.title}`"
        class="inline-block  mt-2 border border-glueblue-600 py-2.5 px-3 text-glueblue-600 text-xs uppercase"
      >
        {{ link.text }}
      </a>

      <div v-if="event.details_button_link" class="mt-6">
        <a
          :href="event.details_button_link" target="_blank"
          class="border border-glueblue-600 pt-2.5 pb-2 px-6 uppercase text-glueblue-600 text-xs"
        >
          <span>{{ event.details_button_text }}</span>
        </a>
      </div>
    </div>
  </div>
</template>
