<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      canSee: true,
    }
  },
  computed: {
    colorStyles() {
      if (this.fields.colorOverride.enabled) {
        return {
          borderColor: this.fields.colorOverride.value,
          color: this.fields.colorOverride.value,
        }
      }
      return {}
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'General Title And Copy', group: 'Copy', layouts: ['*'] },
      preview: 'SlicesGlueTitlesAndTextGeneralTitleAndCopy.jpg',
      description: 'Title and copy',
      tags: ['Copy'],

      slots: [{ name: 'default' }, { name: 'bottom slot' }],
      fields: {
        mediaAbove: {
          type: 'media',
          label: 'Media Above',
          enabled: false,
          breakpoints: {
            default: {
              width: 600,
              height: 600,
            },
          },
          group: 'Image Above',
        },
        imageSize: {
          type: 'number',
          label: 'Image Size',
          group: 'Image Above',
        },
        textAlign: {
          type: 'select',
          label: 'Text Align',
          options: { left: 'On Left', center: 'In Center', right: 'On Right' },
          default: 'center',
        },
        subtitle: { type: 'text', label: 'Subtitle' },
        title: { type: 'text', label: 'Title' },
        titleSize: {
          type: 'select',
          label: 'Title Size',
          options: {
            small: 'Small',
            medium: 'Medium',
            large: 'Large',
          },
        },
        description: {
          type: 'wysiwyg',
          label: 'Description',
          enabled: true,
          group: 'Description',
        },
        descriptionSize: {
          type: 'select',
          label: 'Description Size',
          options: {
            small: 'Small',
            medium: 'Medium',
            large: 'Large',
            blockquote: 'Blockquote',
          },
          group: 'Description',
        },
        width: {
          type: 'select',
          label: 'Width',
          options: {
            small: 'Small',
            medium: 'Medium',
            large: 'Large',
            full: 'Full',
          },
          default: 'large',
        },
        link: { type: 'link', label: 'Link', enabled: false, group: 'Link 1' },
        linkStyle: {
          type: 'select',
          label: 'Link Style',
          group: 'Link 1',
          options: {
            ghost: 'Ghost',
            block: 'Block',
          },
          default: 'ghost',
        },
        link2: { type: 'link', label: 'Link 2', enabled: false, group: 'Link 2' },
        link2Style: {
          type: 'select',
          label: 'Link Style',
          group: 'Link 2',
          options: {
            ghost: 'Ghost',
            block: 'Block',
          },
          default: 'ghost',
        },
        colorOverride: { type: 'color', label: 'Color Override', enabled: false },
      },
      templates: [{
        label: 'General Title And Copy',
        fields: {
          description: { value: '<p><strong>Everyone is welcome to stay at any of our Resort\u2019s hotels.<\/strong> <br><em>Pick the one with the vibe that\u2019s right for you.<\/em><\/p>' },
          descriptionSize: { value: 'large' },
          position: { value: 'center' },
          subtitle: { value: 'one mythical resort' },
          textAlign: { value: 'center' },
          title: { value: 'Five distinct hotels to visit' },
          titleSize: { value: 'medium' },
          width: { value: 'large' },
          contain: { value: true },
        },
      }],
    })
  },
  methods: {
    isVisible() {
      this.canSee = true
    },

  },
}
</script>

<template>
  <div class="py-12 px-8">
    <div
      class="container mx-auto"
      :class="{
        'flex justify-end': fields.textAlign.value === 'right',
        'flex justify-center': fields.textAlign.value === 'center',
      }"
    >
      <div
        class="flex flex-col w-full"
        :class="{
          'text-right': fields.textAlign.value === 'right',
          'text-center': fields.textAlign.value === 'center',
          'max-w-[40rem]': fields.width.value === 'small',
          'max-w-[50rem]': fields.width.value === 'medium',
          'max-w-[60rem]': fields.width.value === 'large',
        }"
      >
        <slot name="default" />
        <div
          class="flex flex-col space-y-6 transform duration-500"
          :class="{
            'translate-y-12 opacity-0': !canSee,
            'opacity-100': canSee,
            'items-start': fields.textAlign.value === 'left',
            'items-center': fields.textAlign.value === 'center',
            'items-end': fields.textAlign.value === 'right',
          }"
        >
          <div v-if="fields.mediaAbove.enabled" class="">
            <VoixMedia :field="fields.mediaAbove" :style="{ width: fields.imageSize.value ? `${fields.imageSize.value}px` : 'auto' }" />
          </div>

          <div
            class="-mb-8 font-serif tracking-wider italic text-red-500"
            :class="{
              'justify-start': fields.textAlign.value === 'left',
              'justify-center': fields.textAlign.value === 'center',
              'justify-end': fields.textAlign.value === 'right',
              'text-3xl ': fields.titleSize.value === 'large',
              'text-2xl': fields.titleSize.value === 'medium',
              'text-xl': fields.titleSize.value === 'small',
            }"
          >
            {{ fields.subtitle.value }}
          </div>
          <div
            class="headline"
            :class="{
              'justify-start': fields.textAlign.value === 'left',
              'justify-center': fields.textAlign.value === 'center',
              'justify-end': fields.textAlign.value === 'right',
              'max-w-xl': fields.width.value === 'small',
              'max-w-2xl': fields.width.value === 'medium',
              'max-w-full': fields.width.value === 'large',
              'headline-1': fields.titleSize.value === 'large',
              'headline-2': fields.titleSize.value === 'medium',
              'headline-3': fields.titleSize.value === 'small',
            }"
            :style="colorStyles"
          >
            {{ fields.title.value }}
          </div>
          <div
            class="flex"
            :class="{
              'justify-start': fields.textAlign.value === 'left',
              'justify-center': fields.textAlign.value === 'center',
              'justify-end': fields.textAlign.value === 'right',
              'text-left': fields.textAlign.value === 'left',
              'text-right': fields.textAlign.value === 'right',
              'text-center ': fields.textAlign.value === 'center',
            }"
            :style="colorStyles"
          >
            <div
              :class="{
                'headline-1 !text-5xl italic':
                  !fields.descriptionSize
                  || fields.descriptionSize.value === 'blockquote',
                'body-lg':
                  !fields.descriptionSize
                  || fields.descriptionSize.value === 'large',
                'prose font-sans2 font-light':
                  fields.descriptionSize
                  && fields.descriptionSize.value === 'medium',
                'prose prose-sm font-sans2 font-light':
                  fields.descriptionSize
                  && fields.descriptionSize.value === 'small',
              }"
            >
              <VoixWysiwyg :field="fields.description" class="wysiwyg marker:text-glueblue-700 max-w-full" />
            </div>
          </div>
          <div
            class="flex items-center space-x-4"
            :class="{
              'justify-center': fields.textAlign.value === 'center',
              'justify-end': fields.textAlign.value === 'right',
            }"
          >
            <a
              v-if="fields.link.enabled"
              :class="{ 'btn-ghost': fields.linkStyle.value === 'ghost', 'btn-blue btn-sm': fields.linkStyle.value === 'block' }"
              :style="colorStyles"
              :href="fields.link.value?.href"
              :target="fields.link.value.target"
            >{{ fields.link.value.text }}</a>
            <a
              v-if="fields.link2 && fields.link2.enabled"
              :class="{ 'btn-ghost': fields.link2Style.value === 'ghost', 'btn-blue btn-sm': fields.link2Style.value === 'block' }"
              :style="colorStyles"
              :href="fields.link2.value?.href"
              :target="fields.link2.value.target"
            >{{ fields.link2.value.text }}</a>
          </div>
        </div>
        <slot name="bottom slot" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
html[data-theme="barbie-theme"],
.barbie-theme {
  .btn-ghost {
    @apply bg-barbie-500 text-white font-barbie-headline font-normal tracking-wide text-2xl border-transparent py-4 px-6;
  }

  .wysiwyg {
    ::marker {
      @apply text-barbie-500;
    }
  }
}
</style>
