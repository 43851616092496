<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixLinkFieldInterface, VoixMediaFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface } from '@voix/types'

const props = defineProps<{
  fields: {
    image: VoixMediaFieldInterface
    maxHeight: VoixTextFieldInterface
    videoId: VoixTextFieldInterface
    videoId2: VoixTextFieldInterface
    mode: VoixSelectFieldInterface
    logo: VoixMediaFieldInterface
    logoWidth: VoixSelectFieldInterface
    title: VoixTextFieldInterface
    subtitle: VoixTextFieldInterface
    link: VoixLinkFieldInterface
    showBookingWidget: VoixCheckboxFieldInterface
  }
}>()

defineSlice({
  name: {
    label: 'Main Hero',
    group: 'Heroes',
    layouts: ['*'],
  },
  preview: 'SlicesHeroesMainHero.jpg',
  description: 'The hero we should use on the majority of pages on AtlantisBahamas.',
  tags: ['Hero'],

  slots: [
    {
      name: 'default',
      allowedElements: [
        'SlicesHeroesHeroCopy',
      ],
    },
  ],
  fields: {
    image: {
      label: 'Image',
      type: 'media',
      breakpoints: {
        default: {
          width: 768,
          height: 500,
        },
        sm: {
          width: 768,
          height: 500,
        },
        md: {
          width: 1024,
          height: 576,
        },
        lg: {
          width: 1280,
          height: 720,
        },
        xl: {
          width: 1680,
          height: 945,
        },
      },
    },

    videoId: {
      label: 'Main Video ID',
      type: 'text',
      group: 'Video',
    },

    videoId2: {
      label: 'Preview Video ID',
      type: 'text',
      group: 'Video',
    },

    mode: {
      label: 'Mode',
      type: 'select',
      options: {
        noVideo: 'No Video',
        oneVideoAutoplay: 'One Video Autoplay',
        oneVideoNoAutoplay: 'One Video No Autoplay (Image placeholder)',
        twoVideos: 'Preview Video then Main on Click',
      },
      group: 'Video',
    },

    maxHeight: {
      label: 'Max Height',
      description: 'You must provide a unit. Example: 500px, 50vh, 50%, etc.',
      type: 'text',
      default: '50vh',
      enabled: false,
    },

    logo: {
      label: 'Logo',
      type: 'media',
      enabled: false,
      group: 'logo',
      breakpoints: {
        default: {
          width: 600,
        },
      },
    },

    logoWidth: {
      label: 'Logo Width',
      type: 'select',
      options: {
        '100%': '100%',
        '75%': '75%',
        '50%': '50%',
        '25%': '25%',
      },
      enabled: false,
      group: 'logo',
    },

    title: {
      label: 'Title',
      type: 'text',
      enabled: false,
      group: 'title',
    },

    subtitle: {
      label: 'Subtitle',
      type: 'text',
      enabled: false,
      group: 'title',
    },

    link: {
      label: 'Link',
      type: 'link',
      enabled: false,
      group: 'title',
    },
    showBookingWidget: {
      label: 'Show Booking Widget?',
      type: 'checkbox',
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      image: {
        default: 'https://images.unsplash.com/photo-1507525428034-b723cf961d3e?q=80&w=1746&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
    },
  }],
})

const maxHeight = computed(() => {
  if (!props.fields.maxHeight.enabled)
    return {}

  return {
    maxHeight: `${props.fields.maxHeight.value}`,
  }
})

const showMainVideo = ref(false)
const isVideoPlaying = computed(() => showMainVideo.value || props.fields.mode.value === 'oneVideoAutoplay')
const showTitleOverlay = computed(() => {
  if (props.fields.mode.value === 'twoVideos') {
    if (showMainVideo.value)
      return false

    return true
  }

  return props.fields.logo.enabled || props.fields.title.enabled || props.fields.subtitle.enabled || props.fields.link.enabled
})
</script>

<template>
  <div
    class="relative max-w-full overflow-hidden" :class="{
      'mb-16': fields.showBookingWidget.value,
    }"
  >
    <div class="relative overflow-hidden w-screen max-w-screen h-[85vh] lg:h-auto lg:aspect-video mx-0 px-0" :style="maxHeight">
      <VoixMedia :field="fields.image" class="w-full h-full object-cover" />
      <div class="absolute z-10 inset-0 h-48 gradient-t-b from-black/70 to-black/0" />

      <GlueMediaWistia
        v-if="fields.mode.value === 'twoVideos' && !showMainVideo && fields.videoId2.value"
        :video-id="fields.videoId2.value"
        :play-on-load="true"
        class="w-full h-full object-cover bg-blue-500"
      />
      <GlueMediaWistia
        v-if="fields.mode.value !== 'noVideo' && isVideoPlaying && fields.videoId.value"
        :video-id="fields.videoId.value"
        :play-on-load="isVideoPlaying"
        class="w-full h-full object-cover bg-blue-500"
      />

      <div
        v-if="showTitleOverlay "
        class="absolute z-30 inset-0 flex flex-col justify-center items-center text-white text-center  px-12"
      >
        <div v-if="fields.logo.enabled" class="flex justify-center mb-16">
          <VoixMedia
            :field="fields.logo" class="w-full max-w-[600px]" :style="{
              width: fields.logoWidth.value,
            }"
          />
        </div>

        <h1 v-if="fields.title.enabled || fields.subtitle.enabled" class="headline-1 flex flex-col justify-center items-center">
          <span v-if="fields.subtitle.enabled" class="text-sm font-medium mb-4" v-html="fields.subtitle.value" />
          <div v-if="fields.title.enabled" class="text-[40px] md:text-[60px] text-wrap" v-html="fields.title.value" />
        </h1>
        <div class="flex flex-col md:flex-row items-center md:space-x-8">
          <button
            v-if="(fields.mode.value === 'twoVideos' || fields.mode.value === 'oneVideoNoAutoplay') && !showMainVideo"
            :field="fields.link"
            class="mt-12 flex space-x-2 uppercase  !border-white/100"
            @click="showMainVideo = true"
          >
            <Icon name="heroicons:play" class="w-6 h-6" />
            <span>Play Full Video</span>
          </button>
          <VoixLink

            :field="fields.link"
            class="mt-12 btn-ghost btn-xs !border-white"
          />
        </div>
      </div>
    </div>

    <div
      v-if="fields.showBookingWidget.value"
      class="absolute z-50 hidden md:block bottom-0 left-0 right-0 pb-0 md:p-0"
    >
      <BookingForm
        class="bg-white translate-y-[50%] p-6 md:p-6 w-full md:w-3/4 md:mx-auto md:bottom-0 md:left-0 md:right-0 relative md:absolute z-40 hidden md:mt-0 lg:block"
      />
    </div>
  </div>
</template>
