<script>
// import { inView, animate } from 'motion';

export default {

  // mounted() {
  //   const waves = this.$el
  //   inView('.planet', () => {
  //     animate(waves, { transform: "rotate(360deg)" }, { duration: 10, repeat: Infinity, easing: "linear" })
  //   })
  // },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 284.35 104.18">
    <path
      fill="currentColor"
      d="m264.04,8.68c-5.42,0-8.16-1.49-10.81-2.93-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93C7.05,4.42,4.73,3.16,0,3.16V0c5.42,0,8.16,1.49,10.81,2.93,2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93v3.16c-4.73,0-7.05,1.26-9.5,2.59-2.65,1.44-5.39,2.93-10.81,2.93Z"
    />
    <path
      fill="currentColor"
      d="m264.04,20.62c-5.42,0-8.16-1.49-10.81-2.93-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59v-3.16c5.42,0,8.16,1.49,10.81,2.93,2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93v3.16c-4.73,0-7.05,1.26-9.5,2.59-2.65,1.44-5.39,2.93-10.81,2.93Z"
    />
    <path
      fill="currentColor"
      d="m264.04,32.56c-5.42,0-8.16-1.49-10.81-2.93-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59v-3.16c5.42,0,8.16,1.49,10.81,2.93,2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93v3.16c-4.73,0-7.05,1.26-9.5,2.59-2.65,1.44-5.39,2.93-10.81,2.93Z"
    />
    <path
      fill="currentColor"
      d="m264.04,44.49c-5.42,0-8.16-1.49-10.81-2.93-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59v-3.16c5.42,0,8.16,1.49,10.81,2.93,2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93v3.16c-4.73,0-7.05,1.26-9.5,2.59-2.65,1.44-5.39,2.93-10.81,2.93Z"
    />
    <path
      fill="currentColor"
      d="m264.04,56.43c-5.42,0-8.16-1.49-10.81-2.93-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59v-3.16c5.42,0,8.16,1.49,10.81,2.93,2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93v3.16c-4.73,0-7.05,1.26-9.5,2.59-2.65,1.44-5.39,2.93-10.81,2.93Z"
    />
    <path
      fill="currentColor"
      d="m264.04,68.37c-5.42,0-8.16-1.49-10.81-2.93-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59v-3.16c5.42,0,8.16,1.49,10.81,2.93,2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93v3.16c-4.73,0-7.05,1.26-9.5,2.59-2.65,1.44-5.39,2.93-10.81,2.93Z"
    />
    <path
      fill="currentColor"
      d="m264.04,80.31c-5.42,0-8.16-1.49-10.81-2.93-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59v-3.16c5.42,0,8.16,1.49,10.81,2.93,2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93v3.16c-4.73,0-7.05,1.26-9.5,2.59-2.65,1.44-5.39,2.93-10.81,2.93Z"
    />
    <path
      fill="currentColor"
      d="m264.04,92.24c-5.42,0-8.16-1.49-10.81-2.93-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59v-3.16c5.42,0,8.16,1.49,10.81,2.93,2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93v3.16c-4.73,0-7.05,1.26-9.5,2.59-2.65,1.44-5.39,2.93-10.81,2.93Z"
    />
    <path
      fill="currentColor"
      d="m264.04,104.18c-5.42,0-8.16-1.49-10.81-2.93-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59s-7.05,1.26-9.5,2.59c-2.65,1.44-5.39,2.93-10.81,2.93s-8.16-1.49-10.81-2.93c-2.45-1.33-4.77-2.59-9.5-2.59v-3.16c5.42,0,8.16,1.49,10.81,2.93,2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93s8.16,1.49,10.81,2.93c2.45,1.33,4.77,2.59,9.5,2.59s7.05-1.26,9.5-2.59c2.65-1.44,5.39-2.93,10.81-2.93v3.16c-4.73,0-7.05,1.26-9.5,2.59-2.65,1.44-5.39,2.93-10.81,2.93Z"
    />
  </svg>
</template>
