<script setup lang="ts">
defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  name: { label: 'All Events Index', group: 'Events', layouts: ['AtlantisMobileApp', 'Atlantisbahamas'] },
  preview: 'SlicesGlueEventsAllEventsIndex.jpg',
  description: '2022 refresh of the events system',
  tags: ['Events', 'Index'],
  fields: {
    headerTitle: { type: 'text', label: 'Header Title', default: '"Event Highlights"' },
    headerCopy: { type: 'textarea', label: 'Header Copy', default: '"Atlantis offers an incredible range of amazing events ranging from concerts by world-renowned artists to top-flight sporting events, all against the backdrop of Paradise."' },
    headerLink: { type: 'link', label: 'Header Link 1', enabled: false },
    headerLink2: { type: 'link', label: 'Header Link 2', enabled: false },
    firstCardMegaMode: { type: 'checkbox', label: 'Put First Card In Mega Mode' },
    firstCardDisplayMode: { type: 'select', label: 'First Card Display Mode', options: { eventcard: 'Event Card', textcard: 'Text Card' } },
    firstCardEventSlug: { type: 'text', label: 'First Card Event Slug' },
    firstCardImage: { type: 'media', label: 'First Card Image', breakpoints: { lg: { width: 430, height: 245 } } },
    firstCardTitle: { type: 'text', label: 'First Card Title' },
    firstCardCopy: { type: 'textarea', label: 'First Card Copy' },
    firstCardDate: { type: 'text', label: 'First Card Dates' },
    firstCardVenue: { type: 'select', label: 'First Card Venue', options: { None: 'None', Aura: 'Aura', Theatre: 'Theatre', Crush: 'Crush', BarSol: 'BarSol', JokersWild: 'JokersWild', MarinaVillage: 'MarinaVillage', AKA: 'AKA', ConferencesAndEvents: 'ConferencesAndEvents', Outdoor: 'Outdoor' } },
    firstCardLink: { type: 'link', label: 'First Card Link', enabled: true },
    firstCardBookingUrl: { type: 'text', label: 'First Card Booking Url', enabled: false },
    secondCardDisplayMode: { type: 'select', label: 'Second Card Display Mode', options: { eventcard: 'Event Card', textcard: 'Text Card' } },
    secondCardEventSlug: { type: 'text', label: 'Second Card Event Slug' },
    secondCardImage: { type: 'media', label: 'Second Card Image', breakpoints: { lg: { width: 430, height: 245 } } },
    secondCardTitle: { type: 'text', label: 'Second Card Title' },
    secondCardCopy: { type: 'textarea', label: 'Second Card Copy' },
    secondCardDate: { type: 'text', label: 'Second Card Dates' },
    secondCardVenue: { type: 'select', label: 'Second Card Venue', options: { None: 'None', Aura: 'Aura', Theatre: 'Theatre', Crush: 'Crush', BarSol: 'BarSol', JokersWild: 'JokersWild', MarinaVillage: 'MarinaVillage', AKA: 'AKA', ConferencesAndEvents: 'ConferencesAndEvents', Outdoor: 'Outdoor' } },
    secondCardLink: { type: 'link', label: 'Second Card Link', enabled: true },
    secondCardBookingUrl: { type: 'text', label: 'Second Card Booking Url', enabled: false },
  },
  templates: [{ label: 'All Events Index', fields: {
    firstCardCopy: { value: 'Experience the 2nd annual Nassau Paradise Island Wine & Food Festival, hosted by Atlantis.' },
    firstCardDisplayMode: { value: 'textcard' },
    firstCardImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/NPIWFF\/image004.jpg' },
    firstCardLink: { value: { text: 'learn more', target: '_self', relationship: null, href: '\/new-page' } },
    firstCardTitle: { value: 'wine & food festival' },
    headerLink: { value: { text: 'View Daily Atlantean', target: '_self', relationship: null, href: '\/new-page' } },
    headerLink2: { value: { text: 'View Daily Atlantean', target: '_self', relationship: null, href: '\/new-page' } },
    secondCardCopy: { value: 'Experience Miss Jackson\'s legendary music catalogue as she headlines an unforgettable concert on Saturday, April 27\nlive from Casuarina Beach.' },
    secondCardDisplayMode: { value: 'textcard' },
    secondCardImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/MMW\/Janet%20Jackson\/janet-j.jpg' },
    secondCardLink: { value: { text: 'learn more', target: '_self', relationship: null, href: '\/new-page' } },
    secondCardTitle: { value: 'janet jackson:Live at atlantis' },
    secondCardVenue: { value: 'None' },
  } }],
})

const events = ref([])
const mode = ref('all-events')

const filters = reactive({
  date: null,
  age: null,
  venue: null,
  category: {
    Nightlife: false,
    Culinary: false,
    Concerts: false,
    Kids: false,
    Holidays: false,
    Complementary: false,
  },
})

const selectedEvents = computed(() => {
  const categoryEvents = events.value.filter(() => {
    return true
  })

  // Filter the events
  let filteredByCategory = categoryEvents

  if (filters.category) {
    // Loop through each events to check if it has the categories needed
    filteredByCategory = categoryEvents.filter((event) => {
      // Loop through all the categories
      for (const category of Object.keys(filters.category)) {
        // Is it set to true (meaning checked in the filter?)
        if (filters.category[category]) {
          // If so we must find it otherwise we return false
          for (const eventCategory of event.categories) {
            if (
              eventCategory.name === category
              && filters.category[category]
            ) {
              return true
            }
          }
          return false
        }
      }

      return true
    })
  }

  let filteredByVenue = filteredByCategory

  if (filters.venue) {
    filteredByVenue = filteredByCategory.filter((event) => {
      return event.venue === filters.venue
    })
  }

  return filteredByVenue
})

watch(
  () => filters,
  () => {
    getEvents()
  },
  { deep: true },
)

function setMode(newMode) {
  mode.value = newMode

  if (mode.value === 'concerts') {
    filters.category = {
      Nightlife: true,
      Culinary: false,
      Concerts: true,
      Kids: false,
      Holidays: false,
    }
  }

  if (mode.value === 'family') {
    filters.category = {
      Nightlife: false,
      Culinary: true,
      Concerts: false,
      Kids: true,
      Holidays: true,
    }
  }

  if (mode.value === 'all-events') {
    filters.category = {
      Nightlife: false,
      Culinary: false,
      Concerts: false,
      Kids: false,
      Holidays: false,
    }
  }
}

async function getEvents() {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const d = new Date()
  let month = monthNames[d.getMonth()].toLowerCase()

  if (filters.date)
    month = filters.date.toLowerCase()

  $voixFetch(`/api/atlantis/events/Atlantis%20Events/months/${month}`).then((response) => {
    events.value = response.data
  })
}

onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search)
  const sectionToLoad = urlParams.get('section')
  if (sectionToLoad)
    setMode(sectionToLoad)

  getEvents()
})
</script>

<template>
  <div>
    <div v-if="fields">
      <div class="container mx-auto py-20 px-6 lg:px-0">
        <div class="flex flex-col lg:flex-row lg:space-x-16">
          <div class="w-full lg:w-[430px]">
            <div class="uppercase text-4xl font-sans font-light leading-snug antialiased tracking-wide">
              {{ fields.headerTitle.value }}
            </div>
            <div class="text-lg font-light font-sans2 antialiased">
              {{ fields.headerCopy.value }}
            </div>

            <div class="flex flex-col sm:flex-row lg:flex-col sm:space-x-4 lg:space-x-0 w-full lg:w-fit">
              <a
                v-if="fields.headerLink.enabled" :href="fields.headerLink.value?.href"
                class="mt-6 btn-ghost"
              >
                {{ fields.headerLink.value.text }}
              </a>
              <a
                v-if="fields.headerLink2.enabled" :href="fields.headerLink2.value?.href"
                class="mt-6 btn-ghost"
              >
                {{ fields.headerLink2.value.text }}
              </a>
            </div>
          </div>

          <div class="flex flex-col lg:flex-row lg:space-x-6 mt-8 lg:mt-0">
            <EventsFeaturedEventCard :fields="fields" :events="events" card-number="first" />
            <EventsFeaturedEventCard
              v-if="!fields.firstCardMegaMode.value" :fields="fields" :events="events"
              card-number="second"
            />
          </div>
        </div>
      </div>

      <div class="hidden lg:flex bg-orange-100 border-b border-t border-zinc-300">
        <button
          class="relative flex justify-center items-center space-x-3 w-1/3 py-6 hover:bg-white transition-colors duration-300"
          :class="{
            'bg-white  border-l border-orange-300': mode === 'all-events',
          }" @click="setMode('all-events')"
        >
          <span
            class="headline-x text-18" :class="{
              'text-red-500': mode === 'all-events',
            }"
          >All Events</span>

          <div v-show="mode === 'concerts'" class="absolute z-30 left-0 top-0 bottom-0 flex items-center py-3">
            <div class="h-full border-r-2 border-orange-200" />
          </div>
        </button>
        <button
          class="relative flex justify-center items-center space-x-3 w-1/3 py-6 hover:bg-white transition-colors duration-300"
          :class="{
            'bg-white border-r border-orange-300 ': mode === 'concerts',
          }" @click="setMode('concerts')"
        >
          <span
            class="headline-x text-18" :class="{
              'text-red-500': mode === 'concerts',
            }"
          >Concerts &amp; Nightlife</span>

          <div v-show="mode === 'all-events'" class="absolute z-30 right-0 top-0 bottom-0 flex items-center py-3">
            <div class="h-full border-r-2 border-orange-200" />
          </div>
        </button>
        <button
          class="flex justify-center items-center space-x-3 w-1/3 py-6 hover:bg-white transition-colors duration-300"
          :class="{
            'bg-white border-r border-l border-orange-300': mode === 'family',
          }" @click="setMode('family')"
        >
          <span
            class="headline-x text-18" :class="{
              'text-red-500': mode === 'family',
            }"
          >Family</span>
        </button>
      </div>

      <div>
        <EventsSearchFilters v-if="mode === 'all-events'" v-model="filters" :number-of-current-events="selectedEvents.length" />
        <div v-else class="py-5 w-full">
          &nbsp;
        </div>

        <div class="container m-12 px-4 md:px-0 mx-auto grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-4 gap-y-8">
          <EventsModalEventCard v-for="event in selectedEvents" :key="event.id + event.start_date" :event="event" :siblings="selectedEvents" />
        </div>

        <div v-show="selectedEvents.length < 1" class="container mx-auto lg:-mt-12 text-center pb-12">
          <div class="p-8 bg-orange-200">
            <div class="headline-2">
              No events with these settings
            </div>
            <p class="mt-3 font-sans2">
              Come back for updates as new events are always added. Until then
              please adjust the filters above to find events in other time
              periods, categories, venues and age settings.
            </p>
          </div>
        </div>

        <div>&nbsp;</div>
      </div>
    </div>
  </div>
</template>
