<script setup lang="ts">
import type { VoixLinkFieldInterface, VoixTextFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    title: VoixTextFieldInterface
    subtitle: VoixTextFieldInterface
    link: VoixLinkFieldInterface
  }
}>()
const target = ref(null)
const { isOutside } = useMouseInElement(target)
defineSlice({
  name: { label: 'Paranza Link with Arrow', group: 'Links', layouts: ['Paranza'] },
  preview: 'SlicesParanzaLinkWithArrow.jpg',
  tags: ['Link', 'Button'],
  fields: {
    title: { type: 'text', label: 'Title', name: 'title' },
    subtitle: { type: 'text', label: 'Subtitle', name: 'subtitle' },
    link: { type: 'link', label: 'Link', name: 'link' },
  },
  slots: [],
})
</script>

<template>
  <div ref="target" class="container flex justify-end max-w-5xl pr-6">
    <div class="flex flex-col space-y-2">
      <RestaurantMicroChromeTitle
        v-if="fields.title.value && fields.subtitle.value"
        :title="fields.title.value"
        :subtitle="fields.subtitle.value"
      />

      <div class="flex justify-end">
        <VoixLink
          :field="fields.link"
          class="duration-500 ease-out"
          :class="{ 'translate-x-4': !isOutside }"
        >
          <RestaurantMicroChromeParanzaArrow class="text-paranza-400 h-6 w-16" />
        </VoixLink>
      </div>
    </div>
  </div>
</template>
