<script>
export default {
  name: 'IconDining',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 100"
  >
    <path
      transform="translate(-25,-25)"
      fill="currentColor"
      d="M93.8,42.5l-9.2,16l-9.2-16l-9.2,16l-9.2-16l-19,33l19,33l9.2-16l9.2,16l9.2-16l9.2,16l19-33L93.8,42.5z
	M93.8,44.5L111.3,75H94l-8.9-15.5L93.8,44.5z M92.9,75H76.2l8.4-14.5L92.9,75z M75.3,44.5l8.7,15l-8.7,15l-8.7-15L75.3,44.5z
	M74.4,75H57.7l8.4-14.5L74.4,75z M56.8,44.5l8.7,15l-9,15.5H39.2L56.8,44.5z M56.8,106.5L39.2,76h17.3l8.9,15.5L56.8,106.5z
	M57.7,76h16.7L66,90.5L57.7,76z M75.3,106.5l-8.7-15l8.7-15l8.7,15L75.3,106.5z M76.2,76h16.7l-8.4,14.5L76.2,76z M93.8,106.5
	l-8.7-15L94,76h17.3L93.8,106.5z"
    />
  </svg>
</template>
