<script setup lang="ts">
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  name: { label: 'One To Three Cards With Red Circle Icon', group: 'Cards', layouts: ['Atlantisbahamas', 'AtlantisMobileApp'] },
  preview: 'SlicesGlueGenericCardsOneToThreeCardsWithRedCircleIcon.jpg',
  description: 'Empty container with child slices',
  tags: ['Card', 'Icon'],
  fields: {
    title: { type: 'text', label: 'Title' },
    description: { type: 'textarea', label: 'Description' },
    link: { type: 'link', label: 'Bottom Ghost Button', enabled: false },
    cardOne: { type: 'checkbox', label: 'Show Card One?', default: true },
    cardOneIcon: { type: 'select', label: 'Card One Icon', allowNull: true, options: { 'bed': 'Bed', 'hand': 'Hand', 'resort': 'Resort', 'beach': 'Beach', 'butler': 'Butler', 'casino': 'Casino', 'chat': 'Chat', 'cocktail': 'Cocktail', 'dining': 'Dining', 'document': 'Document', 'email': 'Email', 'events': 'Events', 'family': 'Family', 'flag': 'Flag', 'golf': 'Golf', 'groups': 'Groups', 'marine': 'Marine', 'office-chair': 'Office Chair', 'phone': 'Phone', 'pool': 'Pool', 'snorkel': 'Snorkel', 'spa': 'Spa', 'turtle': 'Turtle', 'water-park': 'WaterPark' }, enabled: true },
    cardOneTitle: { type: 'text', label: 'Card One Title' },
    cardOneCopy: { type: 'wysiwyg', label: 'Card One Copy' },
    cardOneLink: { type: 'link', label: 'Card One Link', enabled: false },
    cardTwo: { type: 'checkbox', label: 'Show Card Two?', default: true },
    cardTwoIcon: { type: 'select', label: 'Card Two Icon', allowNull: true, options: { 'bed': 'Bed', 'hand': 'Hand', 'resort': 'Resort', 'beach': 'Beach', 'butler': 'Butler', 'casino': 'Casino', 'chat': 'Chat', 'cocktail': 'Cocktail', 'dining': 'Dining', 'document': 'Document', 'email': 'Email', 'events': 'Events', 'family': 'Family', 'flag': 'Flag', 'golf': 'Golf', 'groups': 'Groups', 'marine': 'Marine', 'office-chair': 'Office Chair', 'phone': 'Phone', 'pool': 'Pool', 'snorkel': 'Snorkel', 'spa': 'Spa', 'turtle': 'Turtle', 'water-park': 'WaterPark' }, enabled: true },
    cardTwoTitle: { type: 'text', label: 'Card Two Title' },
    cardTwoCopy: { type: 'wysiwyg', label: 'Card Two Copy' },
    cardTwoLink: { type: 'link', label: 'Card Two Link', enabled: false },
    cardThree: { type: 'checkbox', label: 'Show Card Three?', default: true },
    cardThreeIcon: { type: 'select', label: 'Card Three Icon', allowNull: false, options: { 'bed': 'Bed', 'hand': 'Hand', 'resort': 'Resort', 'beach': 'Beach', 'butler': 'Butler', 'casino': 'Casino', 'chat': 'Chat', 'cocktail': 'Cocktail', 'dining': 'Dining', 'document': 'Document', 'email': 'Email', 'events': 'Events', 'family': 'Family', 'flag': 'Flag', 'golf': 'Golf', 'groups': 'Groups', 'marine': 'Marine', 'office-chair': 'Office Chair', 'phone': 'Phone', 'pool': 'Pool', 'snorkel': 'Snorkel', 'spa': 'Spa', 'turtle': 'Turtle', 'water-park': 'WaterPark' } },
    cardThreeTitle: { type: 'text', label: 'Card Three Title' },
    cardThreeCopy: { type: 'wysiwyg', label: 'Card Three Copy' },
    cardThreeLink: { type: 'link', label: 'Card Three Link', enabled: false },
  },
  templates: [{ label: 'One To Three Cards With Red Circle Icon', fields: { cardOneCopy: { value: '<p>We\u2019d love to speak<br><a href="tel:1-800-752-9711">1-800-752-9711<\/a><\/p>' }, cardOneIcon: { value: 'phone' }, cardOneTitle: { value: 'Call' }, cardThreeCopy: { value: 'Get to know us<br\/><a href="\/casino\/contact-casino-host" rel="noopener noreferrer nofollow">Meet our PDEs<\/a>' }, cardThreeIcon: { value: 'document' }, cardThreeTitle: { value: 'Meet Us' }, cardTwoCopy: { value: '<p>Connect with us via email <br><a href="mailto:CasinoVIPServices@atlantisparadise.com">CasinoVIPServices@atlantisparadise.com<\/a><\/p>' }, cardTwoIcon: { value: 'email' }, cardTwoTitle: { value: 'Email' }, description: { value: 'Our representatives are happy to help you with all your planning needs down to the smallest details.' }, link: { value: { text: 'Request RFP', target: '_self', relationship: null, href: '\/new-page' } }, title: { value: 'GET IN TOUCH' } } }],
})

const numberOfCards = computed(() => {
  let numberOfCards = 0
  if (props.fields.cardOne.value)
    numberOfCards += 1

  if (props.fields.cardTwo.value)
    numberOfCards += 1

  if (props.fields.cardThree.value)
    numberOfCards += 1

  return numberOfCards
})

function getSize(icon: string) {
  switch (icon) {
    case 'bed':
    case 'hand':
    case 'beach':
    case 'butler':
    case 'pool':
    case 'dining':
    case 'document':
    case 'golf':
    case 'water-park':
    case 'spa':
    case 'phone':
    case 'mail':
    case 'chat':
    case 'events':
    case 'family':
    case 'groups':
      return {
        width: '50',
        height: '40',
      }

    case 'resort':
    case 'casino':
    case 'cocktail':
      return {
        width: '50',
        height: '45',
      }

    case 'marine':
      return {
        width: '50',
        height: '50',
      }
  }
  return {
    width: '50',
    height: '40',
  }
}
</script>

<template>
  <div>
    <div class="container">
      <div class="flex flex-col items-center justify-center">
        <div
          class="text-3xl md:text-4xl tracking-widest uppercase font-sans font-light leading-tight mb-4 md:mb-0 text-center md:w-1/2"
        >
          {{ fields.title.value }}
        </div>
        <div
          class="font-sans2 text-center w-full md:w-2/5 px-8 mx-auto text-gray-500 md:text-gray-600 font-light text-base mb-8"
        >
          {{ fields.description.value }}
        </div>

        <a
          :href="fields.link.value?.href"
          class="md:hidden mb-8 btn-ghost uppercase text-xs text-gray-700 px-8 font-sans border-gray-500 inline-block text-center py-4 leading-none btn-active tracking-wider"
        >
          {{ fields.link.value.text }}
        </a>

        <div class="md:p-8  md:mx-0 flex flex-col items-center">
          <div
            :class="{
              'w-full md:w-1/3': numberOfCards === 1,
              'w-full md:w-2/3': numberOfCards === 2,
              'w-full': numberOfCards > 2,
            }"
          >
            <div
              class="bg-white font-sans2 text-15 text-gray-700 justify-center px-8 md:px-0 py-8 flex divide-gray-200 divide-y md:divide-y-0 md:divide-x"
              :class="{
                'flex-col md:flex-row md:flex-wrap': numberOfCards > 1,
                'flex-col': numberOfCards === 1,
              }"
            >
              <div
                v-if="fields.cardOne.value" class="py-12 bg-white px-16 flex flex-col items-center" :class="{
                  'md:w-full': numberOfCards === 1,
                  'w-full md:w-1/2': numberOfCards === 2,
                  'w-full md:w-1/3': numberOfCards > 2,
                }"
              >
                <div class="rounded-full w-24 h-24 text-white flex items-center justify-center bg-red-500">
                  <IconsIcon
                    :icon="fields.cardOneIcon.value" :width="getSize(fields.cardOneIcon.value).width"
                    :height="getSize(fields.cardOneIcon.value).height"
                  />
                </div>
                <div class="text-xl font-light font-sans pt-8 py-4 uppercase">
                  {{ fields.cardOneTitle.value }}
                </div>
                <div class="text-sm text-gray-500 text-center wysiwyg" v-html="fields.cardOneCopy.value" />
                <div>
                  <a
                    v-if="fields.cardOneLink.enabled" :href="fields.cardOneLink.value?.href"
                    class="hidden md:block mt-8 btn-ghost uppercase text-xs text-gray-700 px-8 font-sans border-gray-500 text-center py-4 leading-none btn-active tracking-wider"
                  >
                    {{ fields.cardOneLink.value.text }}
                  </a>
                </div>
              </div>

              <div
                v-if="fields.cardTwo.value" class="py-12 bg-white px-16 flex flex-col items-center" :class="{
                  'md:w-full': numberOfCards === 1,
                  'md:w-1/2': numberOfCards === 2,
                  'md:w-1/3': numberOfCards > 2,
                }"
              >
                <div class="rounded-full w-24 h-24 text-white flex items-center justify-center bg-red-500">
                  <IconsIcon
                    :icon="fields.cardTwoIcon.value" :width="getSize(fields.cardTwoIcon.value).width"
                    :height="getSize(fields.cardTwoIcon.value).height"
                  />
                </div>
                <div class="text-xl font-light font-sans pt-8 py-4 uppercase">
                  {{ fields.cardTwoTitle.value }}
                </div>
                <div class="text-sm text-gray-500 text-center wysiwyg" v-html="fields.cardTwoCopy.value" />
                <div>
                  <a
                    v-if="fields.cardTwoLink.enabled" :href="fields.cardTwoLink.value?.href"
                    class="hidden md:block mt-8 btn-ghost uppercase text-xs text-gray-700 px-8 font-sans border-gray-500 text-center py-4 leading-none btn-active tracking-wider"
                  >
                    {{ fields.cardTwoLink.value.text }}
                  </a>
                </div>
              </div>

              <div
                v-if="fields.cardThree.value" class="py-12 bg-white px-16 flex flex-col items-center" :class="{
                  'w-full': numberOfCards === 1,
                  'md:w-1/2': numberOfCards === 2,
                  'md:w-1/3': numberOfCards > 2,
                }"
              >
                <div class="rounded-full w-24 h-24 text-white flex items-center justify-center bg-red-500">
                  <IconsIcon
                    :icon="fields.cardThreeIcon.value" :width="getSize(fields.cardThreeIcon.value).width"
                    :height="getSize(fields.cardThreeIcon.value).height"
                  />
                </div>
                <div class="text-xl font-light font-sans pt-8 py-4 uppercase">
                  {{ fields.cardThreeTitle.value }}
                </div>
                <div class="text-sm text-gray-500 text-center wysiwyg" v-html="fields.cardThreeCopy.value" />
                <div>
                  <a
                    v-if="fields.cardThreeLink.enabled" :href="fields.cardThreeLink.value?.href"
                    class="hidden md:block mt-8 btn-ghost uppercase text-xs text-gray-700 px-8 font-sans border-gray-500  text-center py-4 leading-none btn-active tracking-wider"
                  >
                    {{ fields.cardThreeLink.value.text }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          v-if="fields.link.enabled" :href="fields.link.value?.href"
          class="hidden md:block mt-8 btn-ghost uppercase text-xs text-gray-700 px-8 font-sans border-gray-500  text-center py-4 leading-none btn-active tracking-wider"
        >
          {{ fields.link.value.text }}
        </a>
      </div>
    </div>
  </div>
</template>
