/** @type {import('tailwindcss').Config} */
import typography from '@tailwindcss/typography'

export default {
  content: [
    // Voix Styles
    '../../node_modules/@logicbomb-media/voix/dist/runtime/**/*.{vue,js}',
    './node_modules/@logicbomb-media/voix/dist/runtime/**/*.{vue,js}',

    './components/**/*.{vue,js}',
    './slices/**/*.{vue,js}',
    './layouts/**/*.vue',
    './pages/**/*.vue',
    './composables/**/*.{js,ts}',
    './plugins/**/*.{js,ts}',
    './App.{js,ts,vue}',
    './app.{js,ts,vue}',
    './error.{js,ts,vue}',
  ],
  safelist: ['pl-3', 'pl-6', 'pl-9', 'pl-12', 'object-left-top', 'object-top', 'object-right-top', 'object-left', 'object-center', 'object-right', 'object-left-bottom', 'object-bottom', 'object-right-bottom', 'bg-left-top', 'bg-top', 'bg-right-top', 'bg-left', 'bg-center', 'bg-right', 'bg-left-bottom', 'bg-bottom', 'bg-right-bottom'],
  theme: {
    extend: {
      fontSize: {
        '2xs': '11px',
        'xs': '0.75rem',
        'sm': '0.875rem',
        'base': '1rem',
        'lg': '1.125rem',
        'xl': '1.25rem',
        '2xl': '1.5rem',
        '3xl': '1.875rem',
        '4xl': '2.25rem',
        '5xl': '3rem',
        '6xl': '4rem',
        '11': '11px',
        '15': '15px',
        '18': '18px',
        '24': '24px',
        '32': '32px',
        '38': '38px',
      },

      fontFamily: {
        'sans': [
          'Josefin Sans',
          'system-ui',
          'BlinkMacSystemFont',
          '-apple-system',
          'Segoe UI',
          'Roboto',
          'Oxygen',
          'Ubuntu',
          'Cantarell',
          'Fira Sans',
          'Droid Sans',
          'Helvetica Neue',
          'sans-serif',
        ],
        'sans2': [
          'Lato',
          'system-ui',
          'BlinkMacSystemFont',
          '-apple-system',
          'Segoe UI',
          'Roboto',
          'Oxygen',
          'Ubuntu',
          'Cantarell',
          'Fira Sans',
          'Droid Sans',
          'Helvetica Neue',
          'sans-serif',
        ],
        'serif': [
          'freight-big-pro',
          'Constantia',
          'Lucida Bright',
          'Lucidabright',
          'Lucida Serif',
          'Lucida',
          'DejaVu Serif',
          'Bitstream Vera Serif',
          'Liberation Serif',
          'Georgia',
          'serif',
        ],
        'mono': [
          'Menlo',
          'Monaco',
          'Consolas',
          'Liberation Mono',
          'Courier New',
          'monospace',
        ],
        'script': ['Professor'],
        'wff-headline': [
          'novecento-sans-wide',
          'system-ui',
          'BlinkMacSystemFont',
          '-apple-system',
          'Segoe UI',
          'Roboto',
          'Oxygen',
          'Ubuntu',
          'Cantarell',
          'Fira Sans',
          'Droid Sans',
          'Helvetica Neue',
          'sans-serif',
        ],
        'wff-headline-2': [
          'CANegroni',
          'novecento-sans-wide',
          'system-ui',
          'BlinkMacSystemFont',
          '-apple-system',
          'Segoe UI',
          'Roboto',
          'Oxygen',
          'Ubuntu',
          'Cantarell',
          'Fira Sans',
          'Droid Sans',
          'Helvetica Neue',
          'sans-serif',
        ],
        'wff': [
          'bilo',
          'system-ui',
          'BlinkMacSystemFont',
          '-apple-system',
          'Segoe UI',
          'Roboto',
          'Oxygen',
          'Ubuntu',
          'Cantarell',
          'Fira Sans',
          'Droid Sans',
          'Helvetica Neue',
          'sans-serif',
        ],
        'annv': [
          'Antic Didone',
          'freight-big-pro',
          'Constantia',
          'Lucida Bright',
          'Lucidabright',
          'Lucida Serif',
          'Lucida',
          'DejaVu Serif',
          'Bitstream Vera Serif',
          'Liberation Serif',
          'Georgia',
          'serif',
        ],
        'mmw-headline': [
          'Apotek',
          'Lato',
          'Constantia',
          'Lucida Bright',
          'Lucidabright',
          'Lucida Serif',
          'Lucida',
          'DejaVu Serif',
          'Bitstream Vera Serif',
          'Liberation Serif',
          'Georgia',
          'serif',
        ],
        'mmw': [
          'Urbane',
          'Lato',
          'Constantia',
          'Lucida Bright',
          'Lucidabright',
          'Lucida Serif',
          'Lucida',
          'DejaVu Serif',
          'Bitstream Vera Serif',
          'Liberation Serif',
          'Georgia',
          'serif',
        ],
        'barbie-headline': [
          'League Gothic',
          'Josefin Sans',
          'system-ui',
          'BlinkMacSystemFont',
          '-apple-system',
          'Segoe UI',
          'Roboto',
          'Oxygen',
          'Ubuntu',
          'Cantarell',
          'Fira Sans',
          'Droid Sans',
          'Helvetica Neue',
          'sans-serif',
        ],
        'barbie': [
          'museo-sans',
          'Lato',
          'system-ui',
          'BlinkMacSystemFont',
          '-apple-system',
          'Segoe UI',
          'Roboto',
          'Oxygen',
          'Ubuntu',
          'Cantarell',
          'Fira Sans',
          'Droid Sans',
          'Helvetica Neue',
          'sans-serif',
        ],
        'barbie-display': [
          'alkaline',
          'Museo Sans',
          'Lato',
          'system-ui',
          'BlinkMacSystemFont',
          '-apple-system',
          'Segoe UI',
          'Roboto',
          'Oxygen',
          'Ubuntu',
          'Cantarell',
          'Fira Sans',
          'Droid Sans',
          'Helvetica Neue',
          'sans-serif',
        ],
        'cove-headline': [
          'Spectral',
          'freight-big-pro',
          'Constantia',
          'Lucida Bright',
          'Lucidabright',
          'Lucida Serif',
          'Lucida',
          'DejaVu Serif',
          'Bitstream Vera Serif',
          'Liberation Serif',
          'Georgia',
          'serif',
        ],
      },
      colors: {
        'wff': {
          primary: '#3957af',
          secondary: '#E78D88',
        },

        'paranza': {
          50: '#f9fbfa',
          100: '#dfe0da',
          400: '#819caf',
          500: '#91908b',
          900: '#2a2a2a',
        },

        'orange': {
          50: '#f5f2ea',
          100: '#f9f8f2', // Lighter Background
          200: '#f0ecdd', // Global Background
          300: '#dad4c5', // Select boxes on global background
          400: '#f6ad55',
          500: '#ed8936',
          600: '#dd6b20',
          700: '#c05621',
          800: '#845832',
          900: '#7b341e',
        },

        'gluegray': {
          200: '#ebeae8',
          300: '#e1dfde',
          400: '#d4d3d0',
        },

        'glueblue': {
          100: '#e9f9fc', // light blue hover
          200: '#bee3f8',
          300: '#d1eff7', // light blue selected
          400: '#1fb0d7', // Tealish blue for links
          500: '#4299e1',
          600: '#3957af', // Hover state for buttons
          700: '#254297', // Darker blue for buttons
          800: '#2c5282',
          900: '#2a4365',
        },

        'blue': {
          100: '#eff8ff',
          200: '#bcdefa',
          300: '#428bca',
          400: '#60a5fa',
          500: '#3b82f6',
          600: '#2563eb',
          700: '#1d4ed8',
          800: '#3255A4',
          900: '#12283a',
        },

        'gold': {
          200: '#eee6d0',
        },

        'cove': {
          primary: '#be914f',
          light: '#ebe3dd',
          dark: '#282829',
        },

        'golf': {
          'green': '#3255A4',
          'green-darkest': '#12283a',
          'action': '#00CBD5',
          'gold': '#ffd54f',
        },

        'faw': {
          'light-blue': '#f0f9fe',
          'blue': '#a0dbed',
          'red': '#f58885',
        },

        'mmw': {
          teal: '#0DC6C8',
          blue: '#0347D4',
          orange: '#FF5532',
        },

        'sapphire': '#325be7',
        'sapphire-light': '#cddbeb',
        'reef-pink': '#fdf6f3',

        'barbie': {
          50: '#fdfaf3',
          100: '#f395c7',
          300: '#fff200',
          400: '#05c3d3',
          500: '#ff3eb5',
          800: '#2d2d2c',
        },

        'summer2024': {
          200: '#f0ecdd',
          300: '#fadfdc',
          400: '#20b0d7',
          500: '#ed968b',
          600: '#e66b5b',
          700: '#d68b82',
        },

        'red': {
          100: '#fff5f5',
          200: '#eca289', // Summer Red
          300: '#EB897C', // Glue Red
          400: '#fc8181',
          500: '#eb897c',
          600: '#e53e3e',
          700: '#c53030',
          800: '#9b2c2c',
          900: '#742a2a',
        },
      },

      letterSpacing: {
        '1-2': '1.2px',
        '1-5': '1.5px',
      },

      lineHeight: {
        3: '.75rem',
        4: '1rem',
        5: '1.25rem',
        7: '1.75rem',
        8: '2rem',
        9: '2.25rem',
        10: '2.5rem',
        22: '22px',
        25: '25px',
        28: '28px',
        42: '42px',
        47: '47px',
      },
    },
    container: {
      center: true,
    },
  },
  plugins: [
    typography,
  ],
}
