<script>
export default {
  props: { devise: { type: Object, default: () => ({}) } },
  data: () => {
    return {
      isAllowed: true,
      complete: false,
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      data: '',
      group: '',
      agreeToTerms: false,
    }
  },

  computed: {
    isValid() {
      return this.firstname && this.lastname && this.email && this.agreeToTerms
    },
  },

  mounted() {
    if (
      window.location.search.includes(`code=${this.devise.fields.code.value}`)
      || window.location.search.includes(
        `code=${this.devise.fields.code.value.toLowerCase()}`,
      )
    ) {
      this.isAllowed = true
    }
  },

  methods: {
    async submitForm() {
      if (this.isValid) {
        const data = {
          form: 'scavenger-hunt-winning-form',
          first_name: this.firstname,
          last_name: this.lastname,
          email: this.email,
          agree_to_terms: true,
          data: {
            phone: this.phone,
            group: this.group,
          },
        }

        this.eventCounts = data.value

        const { response, error } = await $voixFetch(`/api/devise/form-signup`, {
          method: 'POST',
          body: data,
        })

        if (response)
          this.complete = true

        if (error) {
          const { errors } = error
          Object.keys(errors).forEach((error) => {
            window.deviseSettings.$bus.$emit('showError', errors[error][0])
          })
        }
      }
    },
  },
}
</script>

<template>
  <div v-if="isAllowed" class="container mx-auto">
    <div class="text-center">
      <div class="headline">
        {{ devise.fields.title.value }}
      </div>
      <div class="prose font-sans2 font-light" v-html="devise.fields.copy.value" />
    </div>

    <div class="flex justify-center">
      <div class="mt-16 max-w-xl w-full font-sans2">
        <form
          v-if="!complete"
          action="#"
          method="POST"
          class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          @submit.prevent="submitForm"
        >
          <div class="sm:col-span-2">
            <div class="flex justify-between">
              <label
                for="firstname"
                class="block text-sm font-medium text-gray-700"
              >First Name</label>
              <span id="email-description" class="text-sm text-gray-800">Required</span>
            </div>
            <div class="mt-1">
              <input
                id="firstname"
                v-model="firstname"
                type="text"
                name="firstname"
                autocomplete="firstname"
                class="block w-full shadow-sm sm:text-sm focus:ring-glueblue-500 focus:border-glueblue-500 border-gray-300 border py-2 px-4 rounded-md"
              >
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="flex justify-between">
              <label
                for="lastname"
                class="block text-sm font-medium text-gray-700"
              >Last Name</label>
              <span id="email-description" class="text-sm text-gray-800">Required</span>
            </div>
            <div class="mt-1">
              <input
                id="lastname"
                v-model="lastname"
                type="text"
                name="lastname"
                autocomplete="lastname"
                class="block w-full shadow-sm sm:text-sm focus:ring-glueblue-500 focus:border-glueblue-500 border-gray-300 border py-2 px-4 rounded-md"
              >
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="flex justify-between">
              <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
              <span id="email-description" class="text-sm text-gray-800">Required</span>
            </div>
            <div class="mt-1">
              <input
                id="email"
                v-model="email"
                name="email"
                type="email"
                autocomplete="email"
                class="block w-full shadow-sm sm:text-sm focus:ring-glueblue-500 focus:border-glueblue-500 border-gray-300 border py-2 px-4 rounded-md"
              >
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="flex justify-between">
              <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
            </div>
            <div class="mt-1">
              <input
                id="phone"
                v-model="phone"
                type="text"
                name="phone"
                autocomplete="tel"
                aria-describedby="phone-description"
                class="block w-full shadow-sm sm:text-sm focus:ring-glueblue-500 focus:border-glueblue-500 border-gray-300 border py-2 px-4 rounded-md"
              >
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="flex justify-between">
              <label for="phone" class="block text-sm font-medium text-gray-700">Group #</label>
              <span id="phone-description" class="text-sm text-gray-500">Optional</span>
            </div>
            <div class="mt-1">
              <input
                id="group"
                v-model="group"
                type="text"
                name="group"
                autocomplete="tel"
                aria-describedby="group-description"
                class="block w-full shadow-sm sm:text-sm focus:ring-glueblue-500 focus:border-glueblue-500 border-gray-300 border py-2 px-4 rounded-md"
              >
            </div>
          </div>

          <div class="sm:col-span-2 flex space-x-4">
            <input v-model="agreeToTerms" type="checkbox">
            <p>
              I am over the age of 18 and have read the
              <a href="/privacy" class="text-glueblue-600 underline">Privacy Policy</a>. By submitting this form, I confirm I would like to receive
              emails on promotions, special offers and updates from Atlantis
              Paradise Island and its affiliated brands.
            </p>
          </div>

          <div class="sm:col-span-2 flex justify-center">
            <button
              type="submit"
              class="inline-flex justify-center border border-transparent shadow-sm px-8 py-2 bg-glueblue-600 text-base font-medium text-white hover:bg-glueblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-glueblue-500 sm:text-sm disabled:opacity-50"
              :disabled="!isValid"
            >
              Submit
            </button>
          </div>
        </form>

        <div v-if="complete" class="headline-3">
          Thank you! We have received your information!
        </div>
      </div>
    </div>
  </div>
</template>
