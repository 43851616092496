<script setup lang="ts">
import type { VoixTextFieldInterface } from '@voix/types'

const props = defineProps<{
  fields: {
    title: VoixTextFieldInterface
    menu: VoixTextFieldInterface
    type: object
    default: () => ({})
  }
  slice: {
    type: object
    default: () => ({})
  }
}>()

defineSlice({
  name: { label: 'Barbie Sub Navigation', group: 'Barbie', layouts: ['Barbie'] },
  tags: ['Barbie', 'Navigation'],
  description: 'Barbie Sub Navigation',
  preview: 'SlicesBarbieSubNavigation.jpg',
  fields: {
    title: { type: 'text', label: 'Menu Title', enabled: false },
    menu: {
      type: 'text',
      label: 'Menu',
      default: '44',
    },
  },
  templates: [{
    label: 'Sub Navigation',
    fields: { menu: { value: '44' }, title: { value: 'Barbie Menu' } },
  }],
})

interface MenuInterface {
  data?: any
}

const menu: Ref<MenuInterface | null> = ref(null)

$voixFetch(`/api/devise/load-menu/${props.fields.menu.value}`).then((response) => {
  menu.value = response
})

const mobileMenuOpen = ref(false)
</script>

<template>
  <div
    class="flex justify-center bg-barbie-300 text-zinc-700 text-sm font-sans2 py-1.5 pt-3"
  >
    <ul
      v-if="menu?.data && menu.data[0] && menu.data[0].menu_items"
      class="relative top-[-4.5px] container flex flex-col md:flex-row px-4 space-y-3 md:space-y-0 md:space-x-8 md:h-auto"
      :class="{
        'h-[20px]': !mobileMenuOpen,
        'h-auto': mobileMenuOpen,
      }"
    >
      <li
        class="md:hidden text-zinc-600" :class="{
          hidden: mobileMenuOpen,
        }"
      >
        <button @click="mobileMenuOpen = true">
          <Icon name="heroicons:bars-3-20-solid" class="w-6 h-6 md:hidden" /> Menu
        </button>
      </li>
      <li
        v-for="menuItem in menu.data[0].menu_items" :key="menuItem.id" class="duration-500 ease-out" :class="{
          'opacity-0 md:opacity-100': !mobileMenuOpen,
          'opacity-100': mobileMenuOpen,
        }"
      >
        <a
          :href="menuItem.permalink"
          class=" transition duration-200 hover:underline underline-offset-2 "
        >{{ menuItem.name }}</a>
      </li>
    </ul>
  </div>
</template>
