<script setup lang="ts">
import type { VoixMediaFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    image: VoixMediaFieldInterface
    terms: VoixWysiwygFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Barbie Signup', group: 'Barbie', layouts: ['Barbie'] },
  tags: ['Barbie', 'Signup', 'Form'],
  preview: 'SlicesBarbieSignup.jpg',
  description: 'Barbie signup form',
  fields: {
    image: {
      label: 'Image',
      type: 'media',
      enabled: false,
      breakpoints: {
        default: {
          width: 400,
          height: 400,
        },
        lg: {
          width: 800,
          height: 800,
        },
      },
    },
    terms: {
      label: 'Terms',
      type: 'wysiwyg',
    },
  },
  templates: [{
    label: 'Default',
    fields: {
      image: {
        value: 'https://images.unsplash.com/photo-1563475454428-db1b68095419?q=80&w=300&h=300&auto=format&fit=crop',
      },
    },
  }],
})

const complete = ref(false)
const form = reactive({
  agree_to_terms: false,
  first_name: null,
  last_name: null,
  email: null,
  isTravelAgent: false,
  zipcode: null,
})

const errors: Ref<{
  [key: string]: string
}> = ref({})

const isErrors = computed(() => {
  return (Object.keys(errors.value).length > 0)
})

function validateForm() {
  errors.value = {}
  if (!form.first_name)
    errors.value.first_name = 'First name is required'

  if (!form.last_name)
    errors.value.last_name = 'Last name is required'

  if (!form.email)
    errors.value.email = 'Email is required'

  if (!form.zipcode)
    errors.value.zipcode = 'Zipcode is required'

  if (!form.agree_to_terms)
    errors.value.agree_to_terms = 'You must agree to the terms'
}

function submitForm() {
  validateForm()

  if (Object.keys(errors.value).length > 0)
    return

  const data = {
    form: 'barbie_signup_page',
    site_id: 1,
    first_name: form.first_name,
    last_name: form.last_name,
    email: form.email,
    data: {
      zipcode: form.zipcode,
      isTravelAgent: form.isTravelAgent,
    },
    agree_to_terms: form.agree_to_terms,
  }

  $voixFetch('/api/form-signup', { method: 'post', body: data }).then(() => {
    complete.value = true
  }).catch((e) => {
    const { errors } = e.response.data
    console.error(errors)
  })
}
</script>

<template>
  <div class="bg-barbie-500 font-barbie py-12 px-8">
    <div class="container mx-auto grid md:grid-cols-12 md:gap-12">
      <div v-if="fields.image.enabled" class="block relative md:col-span-4">
        <div class="border-8 border-white">
          <VoixMedia :field="fields.image" class="object-cover w-full h-full" />
        </div>
      </div>
      <ClientOnly>
        <div
          v-if="!complete" class="flex flex-col justify-center space-y-12 md:col-span-8 font-sans2 py-8 lg:pr-8"
          :class="{
            'md:col-start-3': !fields.image.enabled,
          }"
        >
          <div class="grid md:grid-cols-2 gap-12 px-4 md:px-0 ">
            <div>
              <label class="sr-only">First Name</label>
              <input
                v-model="form.first_name" type="text" placeholder="FIRST NAME"
                class="pb-1 border-b-2 border-white placeholder-white/80 text-white bg-transparent w-full text-lg outline-none"
              >
            </div>
            <div>
              <label class="sr-only">Last Name</label>
              <input
                v-model="form.last_name" type="text" placeholder="LAST NAME"
                class="pb-1 border-b-2 border-white placeholder-white/80 text-white bg-transparent w-full text-lg outline-none"
              >
            </div>
          </div>
          <div class="grid md:grid-cols-2 gap-12 px-4 md:px-0">
            <div>
              <label class="sr-only">Email</label>
              <input
                v-model="form.email" type="text" placeholder="EMAIL"
                class="pb-1 border-b-2 border-white placeholder-white/80 text-white bg-transparent w-full text-lg outline-none"
              >
            </div>
            <div>
              <label class="sr-only">Zipcode</label>
              <input
                v-model="form.zipcode" type="text" placeholder="ZIP CODE"
                class="pb-1 border-b-2 border-white placeholder-white/80 text-white bg-transparent w-full text-lg"
              >
            </div>
          </div>
          <ul v-if="isErrors" class="py-2 bg-red-700 text-red-100 rounded list-disc pl-8   px-8 md:px-4">
            <li v-for="(error, key) in errors" :key="key">
              @{{ error }}
            </li>
          </ul>
          <div>
            <div class="flex justify-center px-8 py-3 text-white font-barbie">
              <div>
                <label class="flex items-center space-x-4">
                  <input v-model="form.agree_to_terms" type="checkbox">
                  <div v-html="fields.terms.value" />
                </label>
              </div>
            </div>
            <fieldset class="flex justify-center">
              <input
                type="button" value="Sign Up" class="w-[300px] border border-white text-white font-barbie-headline text-[40px] uppercase py-4"
                @click="submitForm"
              >
            </fieldset>
          </div>
        </div>
        <div v-else class="col-span-7 py-16 lg:pr-8 flex justify-center items-center">
          <div class="text-white text-5xl font-barbie-display">
            Thank you for completing the form!
          </div>
        </div>
      </ClientOnly>
    </div>
  </div>
</template>
