<script setup lang="ts">
import orderBy from 'lodash.orderby'
import { useSlots } from 'vue'

const props = defineProps({
  devise: { type: Object },
  restaurants: {
    type: Array as PropType<AtlantisRestaurant[]>,
    required: true,
  },
})

const defaultFilters = {
  search: '',
  categories: [
    {
      name: 'Dining Type',
      // Note: Do not change the order due to automatic filters on specific dining category pages
      options: [
        {
          selected: false,
          name: 'Open Now',
        },
        {
          selected: false,
          name: 'Open Today',
        },
        {
          selected: false,
          name: 'Specialty Dining',
        },
        {
          selected: false,
          name: 'Casual Dining',
        },
        {
          selected: false,
          name: 'Quick Bites',
        },
        {
          selected: false,
          name: 'Bars, Lounges & Clubs',
        },
      ],
    },
    {
      name: 'Meal Period',
      options: [
        {
          selected: false,
          name: 'Open Now',
        },
        {
          selected: false,
          name: 'Open Today',
        },
        {
          selected: false,
          name: 'Breakfast',
        },
        {
          selected: false,
          name: 'Lunch',
        },
        {
          selected: false,
          name: 'Dinner',
        },
        {
          selected: false,
          name: 'Late Night',
        },
      ],
    },
    {
      name: 'Dining Location',
      options: [
        {
          selected: false,
          name: 'Royal & Casino',
        },
        {
          selected: false,
          name: 'Coral',
        },
        {
          selected: false,
          name: 'Cove & Reef',
        },
        {
          selected: false,
          name: 'The Reef',
        },
        {
          selected: false,
          name: 'Harborside',
        },
        {
          selected: false,
          name: 'Casino',
        },
        {
          selected: false,
          name: 'Marina Village',
        },
        {
          selected: false,
          name: 'Aquaventure',
        },
        {
          selected: false,
          name: 'Golf Course',
        },
        {
          selected: false,
          name: 'Indoor Dining',
        },
        {
          selected: false,
          name: 'Patio Dining',
        },
        {
          selected: false,
          name: 'Poolside Dining',
        },
      ],
    },
  ],
}
const slots = useSlots()
const filters: Ref<AtlantisDiningFilters> = ref({})
const showAll = ref(true)
const diningSearchResults = ref(null)

const enabledCategories: ComputedRef<{ [key: string]: string[] }> = computed(() => {
  const enabledCategories: { [key: string]: string[] } = {}

  if (filters.value && filters.value.categories) {
    filters.value.categories.forEach((category) => {
      category.options.forEach((option) => {
        if (option.selected) {
          if (!enabledCategories[category.name])
            enabledCategories[category.name] = []

          enabledCategories[category.name].push(option.name)
        }
      })
    })
  }

  return enabledCategories
})

const filteredRestaurants = computed(() => {
  const restaurants = orderBy(props.restaurants.filter(restaurant => !restaurant.filters.includes('Specialty Dining')), 'sort')
  const signatureRestaurants = props.restaurants.filter(restaurant => restaurant.filters.includes('Specialty Dining'))

  if (!filters.value.categories && !filters.value.search) {
    // @todo
    // window.bus.$emit('filteredRestaurantsUpdated', restaurants)
    return [...signatureRestaurants, ...restaurants]
  }

  if (Object.keys(enabledCategories.value).length < 1 && !filters.value.search) {
    // @todo
    // window.bus.$emit('filteredRestaurantsUpdated', props.restaurants)
    return [...signatureRestaurants, ...restaurants]
  }

  let filteredRestaurants: AtlantisRestaurant[] = props.restaurants

  const currentDate = new Date()
  const currentDay = currentDate.toLocaleString('en-us', { weekday: 'long' }).toLowerCase()
  const currentHour = currentDate.getHours()

  filteredRestaurants = filteredRestaurants.filter((restaurant: AtlantisRestaurant): boolean => {
    let totalFilters = 0
    let passedFiltersCount = 0
    for (const categoryName in enabledCategories.value) {
      totalFilters += enabledCategories.value[categoryName].length
      const passedFilters = enabledCategories.value[categoryName].filter((f: string): boolean => {
        if (f === 'Open Now') {
          const hoursDetails = restaurant.hours_details[currentDay]
          return !restaurant.closed
            && hoursDetails.some(timeSlot => currentHour >= timeSlot.open && currentHour < timeSlot.close)
        }
        if (f === 'Open Today') {
          return !restaurant.closed
            && Object.prototype.hasOwnProperty.call(restaurant.hours_details, currentDay)
            && restaurant.hours_details[currentDay].length > 0
        }

        return restaurant.filters.includes(f)
      })
      passedFiltersCount += passedFilters.length
    }
    return totalFilters === passedFiltersCount
  })

  if (filters.value.search) {
    filteredRestaurants = filteredRestaurants.filter((restaurant: AtlantisRestaurant) => {
      const restaurantName = restaurant.name.toLowerCase()
      const search = filters.value?.search ? filters.value.search.toLowerCase() : ''
      return restaurantName.includes(search)
    })
  }

  // @todo
  // window.bus.$emit('filteredRestaurantsUpdated', filteredRestaurants)
  return orderBy(filteredRestaurants, 'sort')
})

const topFilteredRestaurants = computed(() => {
  // // If there is a slot we'll show 8 restaurants, otherwise we'll show all
  if (slots.default)
    return filteredRestaurants.value.slice(0, 8)

  return filteredRestaurants
})

const bottomFilteredRestaurants = computed(() => {
  // If there is a slot we'll show some on the bottom of that slot.
  // Otherwise we'll show nothing on the 'bottom'
  if (slots.default) {
    if (showAll.value)
      return filteredRestaurants.value.slice(8)

    return filteredRestaurants.value.slice(8, 15)
  }
  return []
})

const route = useRoute()

function buildFilters() {
  if (!route.query?.filters && props.devise?.fields.category.value) {
    filters.value = defaultFilters

    for (let i = 0; i < (filters.value.categories?.length ?? 0); i++) {
      const foundCategory = filters.value.categories?.[i].options?.find(option => option.name === props.devise?.fields.category.value)

      if (foundCategory)
        foundCategory.selected = true
    }
  }
  else if (!route.query?.filters) {
    filters.value = defaultFilters
  }
  else {
    filters.value = JSON.parse(route.query.filters.toString()) as AtlantisDiningFilters
  }

  if (!filters.value)
    filters.value = defaultFilters
}

watch(() => route.query.filters, () => {
  buildFilters()
})

buildFilters()
</script>

<template>
  <div ref="diningSearchResults">
    <div class="container mb-4 lg:mb-24 lg:px-0 flex justify-center">
      <div v-if="devise" class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5 gap-y-10">
        <GlueDiningDiningCard
          v-for="restaurant in topFilteredRestaurants" :key="restaurant.id" :restaurant="restaurant"
          :show-dining-times="devise.fields.showDiningTimes.value"
        />
      </div>
    </div>

    <slot />

    <div class="container mt-24 pb-24 px-8 lg:px-0 flex justify-center">
      <div v-if="devise" class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5 gap-y-10">
        <GlueDiningDiningCard
          v-for="restaurant in bottomFilteredRestaurants" :key="restaurant.id" :restaurant="restaurant"
          :show-dining-times="devise.fields.showDiningTimes.value"
        />
      </div>
    </div>
  </div>
</template>
