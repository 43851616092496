<script setup lang="ts">
import type { VoixTextFieldInterface } from '@voix/types'
import z from 'zod'

const props = defineProps<{
  fields: {
    formName: VoixTextFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Email Unsubscribe', group: 'Email', layouts: ['Atlantisbahamas'] },
  slots: [{ name: 'default', allowedElements: [] }],
  description: '',
  tags: ['Subscribe', 'Form'],
  preview: 'SlicesEmailUnsubscribe.jpg',
  fields: {
    formName: {
      label: 'Form Name',
      type: 'text',
      default: 'AtlantisBahamasUnsubscribeForm',
    },
  },
  templates: [{
    label: 'Main Example',
  }],
})

const form = ref({
  email: '',
  unsubscribe: false,
})

const formSubmitted = ref(false)

const emailValid = computed(() => {
  const email = form.value.email
  return z.string().email().safeParse(email).success
})

function requestUnsubscribe() {
  if (!emailValid.value) {
    alert('Invalid email address. Please try again.')
    return
  }

  const data = {
    email: form.value.email,
    subscribed: !form.value.unsubscribe,

  }

  $fetch('/api/subscriptions/edit', {
    method: 'POST',

    body: JSON.stringify({
      datasource: 'FORMDATA',
      form_name: props.fields.formName.value,
      subscriptions: [data],
    }),
  })

    .then((res) => {
      if (res?.success) {
        formSubmitted.value = true
      }

      else {
        console.error(res)
        alert('There was an error submitting your request. Please try again.')
      }
    })
    .catch((err) => {
      console.error(err)
      alert('There was an error submitting your request. Please try again.')
    })
}

const formValid = computed(() => {
  return emailValid.value && form.value.unsubscribe
})
</script>

<template>
  <div class="py-12 px-8">
    <div class="container max-w-3xl prose">
      <h3>Email Subscription</h3>
      <p v-if="!formSubmitted">
        To unsubscribe from our email communications, please select the option below, then simply confirm your email address and click Submit.
      </p>

      <form v-if="!formSubmitted" method="post" data-netlify="true" @submit.prevent="requestUnsubscribe">
        <div class="space-y-5">
          <div class="space-x-2">
            <input id="unsubscribe" v-model="form.unsubscribe" type="checkbox" name="unsubscribe">
            <label class="font-light text-xl" form="unsubscribe">"I would like to unsubscribe from emails from Atlantis Bahamas"</label>
          </div>
          <div>
            <span>Email Address</span>
            <input v-model="form.email" type="input" name="email" class="px-4 py-2 w-full" placeholder="example@example.com">
          </div>
          <div>
            <input
              :disabled="!formValid"
              type="submit"
              class="btn glue-btn btn-sm bg-glueblue-700 text-white"
              :class="{ 'cursor-not-allowed': !formValid }"
            >
          </div>
        </div>
      </form>

      <div v-else>
        <p>Your request has been submitted. Please give us up to 48 hours to subscribe or unsubscribe you in our system.</p>
      </div>
    </div>
  </div>
</template>
