<script setup lang="ts">
import type { ElementInterface, SliceInterface } from '@voix/types'
import { resolveFieldsArray } from '@voix/composables/useField'

const props = defineProps({
  fields: {
    type: Object as PropType<Record<string, any>>,
    default: () => ({}),
  },
  slice: {
    type: Object as PropType<Record<string, any>>,
    default: () => ({}),
  },
})

defineSlice({
  name: { label: 'Categorized Gallery', group: 'Galleries', layouts: ['NPIWFF', 'Atlantisbahamas'] },
  preview: 'SlicesGlueGalleriesCategorizedGallery.jpg',
  slots: [{ name: 'default' }],
  description: 'Categorized Gallery',
  fields: { showCategories: { label: 'Show Categories?', type: 'checkbox', default: true } },
  templates: [{ label: 'Categorized Gallery', fields: [] }],
})

const gallery: Ref<null | { activeImage?: number }> = ref(null)
const mousingOver = ref(-1)
const showGallery = ref(false)
const selectedCategory: Ref<null | string> = ref(null)

const categories = [
  {
    key: 'categoryPoolsAndBeaches',
    label: 'Pools & Beaches',
  },
  {
    key: 'categoryAccommodations',
    label: 'Accommodations',
  },
  {
    key: 'categoryExperiences',
    label: 'Experiences',
  },
  {
    key: 'categoryDining',
    label: 'Dining',
  },
  {
    key: 'categoryMeetingsAndCelebrations',
    label: 'Meetings & Celebrations',
  },
  {
    key: 'categoryEntertainment',
    label: 'Entertainment',
  },
  {
    key: 'MyAtlantisMoment',
    label: '#MyAtlantisMoment',
  },
]

const pattern = [
  'col-start-1 col-span-3 lg:col-span-5 row-span-2 h-full',
  'col-span-2 h-full',
  'col-span-2 lg:col-span-5 lg:row-span-2 h-full',
  'col-span-2 lg:col-start-6 h-full',
  'col-span-3 lg:col-span-3 row-span-2 lg:row-span-1 h-full',
  'col-span-2 lg:col-span-3 h-full',
  'col-span-3 row-span-2 lg:col-span-6 h-full',
  'col-span-2 lg:col-span-3 h-full',
  'col-span-2 lg:col-span-3 h-full',
  'col-span-2 lg:col-span-5 lg:row-span-2 h-full',
  'col-span-3 lg:col-span-5 row-span-2  h-full',
  'col-span-2 lg:col-span-2 h-full',
  'hidden lg:block col-span-2 lg:col-span-2 h-full',
]

const categoryLabel = computed(() => {
  return selectedCategory.value ? selectedCategory.value : 'Showing All Categories'
})

const filteredImages = computed(() => {
  if (!selectedCategory.value)
    return props.slice.elements
  return props.slice.elements.filter((image: ElementInterface) => {
    const fieldsSelected = image.fields?.filter((field) => {
      return field.name === selectedCategory.value && field?.checkbox
    })
    console.log(fieldsSelected)
    return fieldsSelected && fieldsSelected.length > 0
  })
})

const filteredImagesForGallery = computed(() => {
  const gallery = []
  for (const image of filteredImages.value)
    gallery.push(processField(image).image)

  return gallery
})

function generateClasses(key: number) {
  return pattern[key % pattern.length]
}

function setCategory(category: string | null) {
  if (selectedCategory.value === category)
    selectedCategory.value = null
  else
    selectedCategory.value = category as string | null
}

function openGallery(key: number) {
  showGallery.value = true
  if (gallery.value)
    gallery.value.activeImage = key
}

function processField(field: SliceInterface) {
  return resolveFieldsArray(field)
}

onMounted(() => {
  const script = document.createElement('script')
  script.id = 'olapic-widget-ca9ae1daf17cc035aadfaa816e0a8168-script'
  script.src = 'https://widgets.olapic-cdn.com/ca9ae1daf17cc035aadfaa816e0a8168/en_US' // replace with your script file URL
  document.body.appendChild(script)
})
</script>

<template>
  <div class="py-16 ">
    <div class="px-4 lg:px-16">
      <div v-if="fields.showCategories.value" class="hidden lg:flex items-center space-x-12">
        <button
          class="font-sans uppercase tracking-wider border-b-2 offset-6"
          :class="{
            'text-zinc-500 border-transparent': selectedCategory !== null,
            'border-red-300': selectedCategory === null,
          }"
          @click="setCategory(null)"
        >
          All
        </button>
        <button
          v-for="category in categories"
          :key="category.key"
          class="relative font-sans uppercase tracking-wider border-b-2 offset-6"
          :class="{
            'text-zinc-500 border-transparent': category.key !== selectedCategory,
            'border-red-300': category.key === selectedCategory,
          }"
          @click="setCategory(category.key)"
        >
          <Icon v-if="category.key === 'MyAtlantisMoment'" class="absolute -left-[23px] top-[4px] w-5 h-5 text-red-300" name="simple-icons:instagram" />

          {{ category.label }}
        </button>
      </div>

      <div v-if="fields.showCategories.value" class="lg:hidden">
        <GlobalUICustomSelect
          class="flex p-0 -mb-2 w-full"
          dropdown-classes="bg-white w-72 shadow-2xl"
          button-classes="font-sans text-base uppercase tracking-wider border-red-300 border-b-2 offset-6 w-full"
          :match-width="false"
          :label="categoryLabel"
          :close-on-select="true"
        >
          <div class="font-sans2 p-3" @click="setCategory(null)">
            All Categories
          </div>
          <div
            v-for="(category, key) in categories"
            :key="key"
            class="relative font-sans2 p-3 capitalize"
            @click="setCategory(category.key)"
          >
            <Icon v-if="category.key === 'MyAtlantisMoment'" class="relative -top-[2px] -left-[2px] w-5 h-5 text-red-300" name="simple-icons:instagram" />
            {{ category.label }}
          </div>
        </GlobalUICustomSelect>
      </div>

      <div class="mt-6 grid grid-cols-5 lg:grid-cols-12 gap-2">
        <div
          v-for="(image, key) in filteredImages"
          :key="image.instance_id"
          class="relative bg-glueblue-600 overflow-hidden cursor-pointer"
          :class="generateClasses(key)"
          @mouseenter="mousingOver = key"
          @mouseleave="mousingOver = -1"
          @click="openGallery(key)"
        >
          <VoixMedia
            v-if="image"
            :field="processField(image).image"
            class="w-full h-full object-cover transition duration-500 ease-out"
            :class="{ 'scale-105': mousingOver === key }"
          />
        </div>

        <GlueGalleriesModalGallery
          v-if="showGallery"
          ref="gallery"
          :show="showGallery"
          :gallery="filteredImagesForGallery"
          small-key="smallThumbnail"
          @close="showGallery = false"
        />
      </div>
    </div>
    <div
      id="olapic-widget-ca9ae1daf17cc035aadfaa816e0a8168"
      class="px-0 lg:px-12"
      :class="{ hidden: selectedCategory !== 'MyAtlantisMoment' }"
    />
  </div>
</template>
