<script lang="ts" setup>
import type { VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    title: VoixTextFieldInterface
    offerTitle1: VoixTextFieldInterface
    offerDescription1: VoixTextFieldInterface
    offerId1: VoixTextFieldInterface
    offerTitle2: VoixTextFieldInterface
    offerDescription2: VoixTextFieldInterface
    offerId2: VoixTextFieldInterface
    offerTitle3: VoixTextFieldInterface
    offerDescription3: VoixTextFieldInterface
    offerId3: VoixTextFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Special Offers Savings', group: 'Specials', layouts: ['Atlantisbahamas'] },
  description: 'Special Offers Savings Slice',
  tags: ['Special'],

  fields: {
    title: {
      type: 'text',
      label: 'Title',
    },
    // Offer 1
    offerTitle1: {
      type: 'text',
      label: 'Title',
      group: 'Offer 1',
    },
    offerDescription1: {
      type: 'text',
      label: 'Description',
      group: 'Offer 1',
    },
    offerId1: {
      type: 'text',
      label: 'Offer Id',
      group: 'Offer 1',
    },
    // Offer 2
    offerTitle2: {
      type: 'text',
      label: 'Title',
      group: 'Offer 2',
    },
    offerDescription2: {
      type: 'text',
      label: 'Description',
      group: 'Offer 2',
    },
    offerId2: {
      type: 'text',
      label: 'Offer Id',
      group: 'Offer 2',
    },
    // Offer 3
    offerTitle3: {
      type: 'text',
      label: 'Title',
      group: 'Offer 3',
    },
    offerDescription3: {
      type: 'text',
      label: 'Description',
      group: 'Offer 3',
    },
    offerId3: {
      type: 'text',
      label: 'Offer Id',
      group: 'Offer 3',
    },
  },
  preview: 'SlicesSpecialOffersSavings.jpg',
  templates: [{
    label: 'Special Offers Savings',
    fields: {},
  }],
})

let pushToDataLayer = (title: string) => {}

onMounted(() => {
  pushToDataLayer = (title: string) => {
    if (window.dataLayer) {
      window.dataLayer.push({ offerName: title })
    }
  }
})
</script>

<template>
  <div class="w-full h-full pt-0 px-16">
    <!-- Title -->
    <div class="flex justify-center items-center w-full h-4">
      <p class="text-3xl sm:text-4xl text-center font-light uppercase tracking-wider">
        {{ fields.title.value }}
      </p>
    </div>

    <!-- Offers -->
    <div class="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-2 lg:gap-8 mt-16">
      <!-- Offer 1 -->
      <div class="flex flex-col justify-center items-center h-72 bg-white p-4">
        <p class="w-full lg:w-72 text-center text-2xl font-light uppercase tracking-wider">
          {{ fields.offerTitle1.value }}
        </p>

        <p class="text-sm font-light mt-2">
          {{ fields.offerDescription1.value }}
        </p>

        <BookingBookNow
          class="mt-8"
          :dropdown="false"
          :location="`Special - ${fields.offerTitle1.value}`"
          :special="Number(fields.offerId1.value)"
          @click="pushToDataLayer(`${fields.offerTitle1.value}`)"
        >
          <template #default="slotProps">
            <button
              class="py-3 px-6 leading-snug uppercase focus:outline-none font-sans text-xs"
              :class="{
                'bg-glueblue-700 text-white': slotProps.active,
                ' btn-ghost btn-active': !slotProps.active,
              }"
            >
              Book Now
            </button>
          </template>
        </BookingBookNow>
      </div>

      <!-- Offer 2 -->
      <div class="flex flex-col justify-center items-center h-72 bg-white p-4">
        <p class="w-full lg:w-72 text-center text-2xl font-light uppercase tracking-wider">
          {{ fields.offerTitle2.value }}
        </p>
        <p class="text-sm font-light mt-2">
          {{ fields.offerDescription2.value }}
        </p>

        <BookingBookNow
          class="mt-8"
          :dropdown="false"
          :location="`Special - ${fields.offerTitle2.value}`"
          :special="Number(fields.offerId2.value)"
          @click="pushToDataLayer(`${fields.offerTitle2.value}`)"
        >
          <template #default="slotProps">
            <button
              class="py-3 px-6 leading-snug uppercase focus:outline-none font-sans text-xs"
              :class="{
                'bg-glueblue-700 text-white': slotProps.active,
                ' btn-ghost btn-active': !slotProps.active,
              }"
            >
              Book Now
            </button>
          </template>
        </BookingBookNow>
      </div>

      <!-- Offer 3 -->
      <div class="flex flex-col justify-center items-center h-72 bg-white p-4">
        <p class="w-full lg:w-72 text-center text-2xl font-light uppercase tracking-wider">
          {{ fields.offerTitle3.value }}
        </p>
        <p class="text-sm font-light mt-2">
          {{ fields.offerDescription3.value }}
        </p>

        <BookingBookNow
          class="mt-8"
          :dropdown="false"
          :location="`Special - ${fields.offerTitle3.value}`"
          :special="Number(fields.offerId3.value)"
          @click="pushToDataLayer(`${fields.offerTitle3.value}`)"
        >
          <template #default="slotProps">
            <button
              class="py-3 px-6 leading-snug uppercase focus:outline-none font-sans text-xs"
              :class="{
                'bg-glueblue-700 text-white': slotProps.active,
                ' btn-ghost btn-active': !slotProps.active,
              }"
            >
              Book Now
            </button>
          </template>
        </BookingBookNow>
      </div>
    </div>
  </div>
</template>
