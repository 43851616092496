<script>
export default {
  name: 'CalendarIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 12">
    <path
      fill="currentcolor"
      fill-rule="evenodd"
      d="M.981 10.835H11.25v-5.01H.981v5.01zm0-8.65h1.67v1.164c0 .242.208.437.464.437.257 0 .464-.195.464-.437V2.185H5.65v1.223c0 .241.208.436.464.436.257 0 .464-.195.464-.436V2.185h1.948v1.223c0 .241.209.436.465.436.255 0 .464-.195.464-.436V2.185h1.793v2.766H.981V2.185zm10.732-.875H9.456V.495c0-.241-.209-.437-.464-.437-.256 0-.465.196-.465.437v.815H6.58V.495c0-.241-.207-.437-.464-.437-.256 0-.464.196-.464.437v.815H3.579V.437C3.579.195 3.372 0 3.115 0c-.256 0-.464.195-.464.437v.873H.517c-.257 0-.464.196-.464.438v9.523c0 .241.207.437.464.437h11.196c.256 0 .464-.196.464-.437V1.748c0-.242-.208-.438-.464-.438z"
    />
  </svg>
</template>
