<script>
export default {
  name: 'GlueBedIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 46 42"
  >
    <defs>
      <path
        id="hand-coin-prefix-a"
        d="M0.23 0.47L24.15 0.47 24.15 24.39 0.23 24.39z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path
        fill="currentcolor"
        stroke="#currentColor"
        stroke-width=".3"
        d="M42.042 33.587L22.651 40.99c-.354.135-.745.14-1.101.014L8.858 36.5v-7.573l4.707-2.505a1.585 1.585 0 011.405-.046l10.778 4.856c.533.24.785.855.572 1.4l-.183.468-.002.005-.004.007a1.1 1.1 0 01-1.4.653l-7.875-2.824a.717.717 0 10-.484 1.351l7.874 2.824c.28.1.567.148.85.148a2.55 2.55 0 002.213-1.289l13.779-3.427a1.582 1.582 0 011.224.191c.368.23.624.588.718 1.013a1.601 1.601 0 01-.988 1.835h0zm-34.62 5.594H1.436V25.906h5.986V39.181zm35.646-9.662a3.004 3.004 0 00-2.326-.365l-12.914 3.212a2.535 2.535 0 00-1.49-2.444L15.56 25.067a3.052 3.052 0 00-2.668.087L8.858 27.3v-2.112a.718.718 0 00-.718-.718H.718a.718.718 0 00-.718.718V39.9c0 .397.321.718.718.718H8.14a.717.717 0 00.718-.718v-1.876l12.212 4.333a3.034 3.034 0 002.094-.025l19.391-7.403a3.045 3.045 0 001.876-3.489 3 3 0 00-1.363-1.92h0z"
        transform="translate(1 -1)"
      />
      <g transform="translate(1 -1) translate(15 .53)">
        <mask id="hand-coin-prefix-b" fill="currentcolor">
          <use xlink:href="#hand-coin-prefix-a" />
        </mask>
        <path
          fill="currentcolor"
          stroke="#currentColor"
          stroke-width=".3"
          d="M12.19 1.905c5.803 0 10.524 4.721 10.524 10.525 0 5.803-4.721 10.524-10.524 10.524-5.803 0-10.524-4.721-10.524-10.524 0-5.803 4.72-10.524 10.524-10.524m0 22.484c6.595 0 11.96-5.366 11.96-11.96C24.15 5.835 18.785.47 12.19.47 5.595.47.23 5.835.23 12.43c0 6.595 5.365 11.96 11.96 11.96"
          mask="url(#hand-coin-prefix-b)"
        />
      </g>
      <path
        fill="currentcolor"
        stroke="#currentColor"
        stroke-width=".3"
        d="M27.13 17.01a2.135 2.135 0 01-2.132-2.132h-1.435a3.57 3.57 0 002.97 3.514v1.324h1.436v-1.374a3.57 3.57 0 002.728-3.464c0-1.913-1.706-2.357-2.952-2.681l-.747-.198c-1.455-.38-1.878-.594-1.878-1.292 0-1.175.956-2.132 2.132-2.132 1.175 0 2.13.957 2.13 2.132h1.437a3.573 3.573 0 00-2.85-3.494V5.87h-1.435v1.343a3.572 3.572 0 00-2.85 3.494c0 1.914 1.706 2.358 2.952 2.682l.748.197c1.455.379 1.878.594 1.878 1.292a2.135 2.135 0 01-2.132 2.132"
        transform="translate(1 -1)"
      />
    </g>
  </svg>
</template>
