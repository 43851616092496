<script setup lang="ts">
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  name: { label: 'Three Tab Filter', group: 'Activities', layouts: ['Atlantisbahamas', 'AtlantisMobileApp'] },
  preview: 'SlicesGlueExperiencesThreeTabFilter.jpg',
  slots: [{ name: 'default' }],
  description: 'Give the user the ability to search through experience',
  tags: ['Activities', 'Navigation'],
  fields: {
    sectionOneTitle: { label: 'Section One Title', type: 'text' },
    sectionOneIcon: { label: 'Section One Icon', type: 'select', options: { dolphin: 'Dolphin', fish: 'Fish', flower: 'Flower' } },
    sectionOneDescription: { label: 'Section One Description', type: 'wysiwyg' },
    sectionOneImage1: { label: 'Section One Image 1', type: 'media', breakpoints: { lg: { width: 710, height: 360 }, sm: { width: 360, height: 360 } } },
    sectionOneImage2: { label: 'Section One Image 2', type: 'media', breakpoints: { lg: { width: 710, height: 360 }, sm: { width: 360, height: 360 } } },
    sectionOneSlug: { label: 'Section One Slug', type: 'text' },
    sectionTwoTitle: { label: 'Section Two Title', type: 'text' },
    sectionTwoIcon: { label: 'Section Two Icon', type: 'select', options: { dolphin: 'Dolphin', fish: 'Fish', flower: 'Flower' } },
    sectionTwoDescription: { label: 'Section Two Description', type: 'wysiwyg' },
    sectionTwoImage1: { label: 'Section Two Image 1', type: 'media', breakpoints: { lg: { width: 710, height: 360 }, sm: { width: 360, height: 360 } } },
    sectionTwoImage2: { label: 'Section Two Image 2', type: 'media', breakpoints: { lg: { width: 710, height: 360 }, sm: { width: 360, height: 360 } } },
    sectionTwoSlug: { label: 'Section Two Slug', type: 'text' },
    sectionThreeTitle: { label: 'Section Three Title', type: 'text' },
    sectionThreeIcon: { label: 'Section Three Icon', type: 'select', options: { dolphin: 'Dolphin', fish: 'Fish', flower: 'Flower' } },
    sectionThreeDescription: { label: 'Section Three Description', type: 'wysiwyg' },
    sectionThreeImage1: { label: 'Section Three Image 1', type: 'media', breakpoints: { lg: { width: 710, height: 360 }, sm: { width: 360, height: 360 } } },
    sectionThreeImage2: { label: 'Section Three Image 2', type: 'media', breakpoints: { lg: { width: 710, height: 360 }, sm: { width: 360, height: 360 } } },
    sectionThreeSlug: { label: 'Section Three Slug', type: 'text' },
  },
  templates: [
    {
      label: 'Three Tab Filter',
      fields: {
        sectionOneIcon: { value: 'dolphin' },
        sectionOneImage1: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/experiences\/aquaventure\/beach-bed-swing.png' },
        sectionOneImage2: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis\/homepage\/heroes\/beach-horizon-hero.jpg' },
        sectionOneTitle: { value: 'Beaches' },
        sectionThreeIcon: { value: 'flower' },
        sectionThreeImage1: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis\/atlantis-aquaventure-private-cabanas-mayan-temple-pool.jpg' },
        sectionThreeImage2: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Rooms\/Coral_Towers\/page_gallery\/cabanas_1.jpg' },
        sectionThreeTitle: { value: 'Cabanas' },
        sectionTwoIcon: { value: 'fish' },
        sectionTwoImage1: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis\/homepage\/heroes\/looking-forward\/m-hero-overhead-cove-pool-at-2x.jpg' },
        sectionTwoImage2: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Rooms\/The_Cove_Atlantis\/Cain_at_The_Cove\/Gallery\/thecove_cain_ultrapool.jpg' },
        sectionTwoTitle: { value: 'Pools' },
      },
    },
  ],
})

const mode = ref('tab-1')
const tabHover = ref('')
const route = useRoute()
const router = useRouter()
// Note that this used to be slug based and is now based
// on a query parameter called "slug". I know... confusing
// but we're in a hurry here :)
function switchToSlug(slug: string) {
  router.push({
    path: route.path,
    query: { slug },
  })
}
function onTabHover(tab: 'tab-1' | 'tab-2' | 'tab-3', isHovering: boolean) {
  if (isHovering)
    tabHover.value = tab
  else tabHover.value = ''
}
function setMode(newMode: 'tab-1' | 'tab-2' | 'tab-3') {
  if (newMode === 'tab-1' && props.fields.sectionOneSlug.value)
    switchToSlug(props.fields.sectionOneSlug.value)

  if (newMode === 'tab-2' && props.fields.sectionTwoSlug.value)
    switchToSlug(props.fields.sectionTwoSlug.value)

  if (newMode === 'tab-3' && props.fields.sectionThreeSlug.value)
    switchToSlug(props.fields.sectionThreeSlug.value)

  mode.value = newMode
}
function loadImage(key: string) {
  if (key === 'dolphin')
    return '/imgs/logos/dolphin-cay-md.png'
  if (key === 'fish')
    return '/imgs/logos/marine-adventures-sm.png'
  if (key === 'flower')
    return '/imgs/logos/all-adventures-sm.png'
  return ''
}

// Select the tab based on the "slug" query parameter
if (route.query.slug === props.fields.sectionOneSlug.value)
  mode.value = 'tab-1'
if (route.query.slug === props.fields.sectionTwoSlug.value)
  mode.value = 'tab-2'
if (route.query.slug === props.fields.sectionThreeSlug.value)
  mode.value = 'tab-3'

provide('filterMode', () => mode.value)
</script>

<template>
  <div>
    <div class="flex flex-col lg:flex-row justify-center md:scale-75 lg:scale-100">
      <button
        class="relative flex justify-center items-center space-x-3 w-full lg:w-fit py-6 px-6 md:px-24 lg:rounded-t-3xl border-[2px] hover:bg-white transition-colors duration-300"
        :class="{
          'bg-red-500 border-red-500': mode === 'tab-1',
          'bg-[#f1eae8] border-white': mode !== 'tab-1' || tabHover === 'tab-1',
        }"
        @click="setMode('tab-1')"
        @mouseover="onTabHover('tab-1', true)"
        @mouseleave="onTabHover('tab-1', false)"
      >
        <div
          class="relative rounded-full w-8 h-8"
          :class="{
            'bg-red-500 border-2 border-red-500 ': mode === 'tab-1',
            'bg-orange-50 border-2 border-zinc-700 ': mode !== 'tab-1',
          }"
        >
          <div class="absolute inset-0 flex justify-center items-center">
            <div
              class="rounded-full w-6 h-6"
              :class="{
                'bg-zinc-700': mode !== 'tab-1',
                'bg-white': mode === 'tab-1',
              }"
              style="mask-size: contain; mask-repeat: no-repeat; mask-position: center"
              :style="{
                'mask-image': `url(${loadImage(fields.sectionOneIcon.value)})`,
              }"
            />
          </div>
        </div>
        <span
          class="headline-x text-18"
          :class="{
            'text-white': mode === 'tab-1' && tabHover !== 'tab-1',
            'text-red-500': mode === 'tab-1' && tabHover === 'tab-1',
          }"
        >
          {{ fields.sectionOneTitle.value }}
        </span>
      </button>
      <button
        class="flex justify-center items-center space-x-3 w-full lg:w-fit py-6 px-6 md:px-24 lg:rounded-t-3xl border-[2px] hover:bg-white transition-colors duration-300"
        :class="{
          'bg-red-500 border-red-500': mode === 'tab-2',
          'bg-[#f1eae8] border-white': mode !== 'tab-2' || tabHover === 'tab-2',
        }"
        @click="setMode('tab-2')"
        @mouseover="onTabHover('tab-2', true)"
        @mouseleave="onTabHover('tab-2', false)"
      >
        <div
          class="relative rounded-full w-8 h-8"
          :class="{
            'bg-red-500 border-2 border-red-500 ': mode === 'tab-2',
            'bg-orange-50 border-2 border-zinc-700 ': mode !== 'tab-2',
          }"
        >
          <div class="absolute inset-0 flex justify-center items-center">
            <div
              class="rounded-full w-6 h-6"
              :class="{
                'bg-zinc-700': mode !== 'tab-2',
                'bg-white': mode === 'tab-2',
              }"
              style="mask-size: contain; mask-repeat: no-repeat; mask-position: center"
              :style="{
                'mask-image': `url(${loadImage(fields.sectionTwoIcon.value)})`,
              }"
            />
          </div>
        </div>
        <span
          class="headline-x text-18"
          :class="{
            'text-white': mode === 'tab-2' && tabHover !== 'tab-2',
            'text-red-500': mode === 'tab-2' && tabHover === 'tab-2',
          }"
        >{{ fields.sectionTwoTitle.value }}</span>
      </button>
      <button
        class="relative flex justify-center items-center space-x-3 w-full lg:w-fit py-6 px-6 md:px-24 lg:rounded-t-3xl border-[2px] hover:bg-white transition-colors duration-300"
        :class="{
          'bg-red-500 border-red-500': mode === 'tab-3',
          'bg-[#f1eae8] border-white': mode !== 'tab-3' || tabHover === 'tab-3',
        }"
        @click="setMode('tab-3')"
        @mouseover="onTabHover('tab-3', true)"
        @mouseleave="onTabHover('tab-3', false)"
      >
        <div
          class="relative rounded-full w-8 h-8"
          :class="{
            'bg-red-500 border-2 border-red-500 ': mode === 'tab-3',
            'bg-orange-50 border-2 border-zinc-700 ': mode !== 'tab-3',
          }"
        >
          <div class="absolute inset-0 flex justify-center items-center">
            <div
              class="rounded-full w-6 h-6"
              :class="{
                'bg-zinc-700': mode !== 'tab-3',
                'bg-white': mode === 'tab-3',
              }"
              style="mask-size: contain; mask-repeat: no-repeat; mask-position: center"
              :style="{
                'mask-image': `url(${loadImage(fields.sectionThreeIcon.value)})`,
              }"
            />
          </div>
        </div>
        <span
          class="headline-x text-18"
          :class="{
            'text-white': mode === 'tab-3' && tabHover !== 'tab-3',
            'text-red-500': mode === 'tab-3' && tabHover === 'tab-3',
          }"
        >{{ fields.sectionThreeTitle.value }}</span>
      </button>
    </div>

    <div class="relative overflow-x-hidden px-8 py-12 lg:py-20">
      <div
        style="background-image: url(/imgs/backgrounds/pattern.jpg)"
        class="absolute z-0 inset-0 opacity-50"
      />
      <div class="relative z-10">
        <div
          v-if="mode === 'tab-1'"
          class="container mx-auto flex flex-col lg:flex-row items-center"
        >
          <div class="w-72 text-center flex flex-col items-center space-y-4 text-red-500">
            <div
              class="relative rounded-full w-12 h-12 bg-red-500 border-2 border-red-500"
            >
              <div class="absolute inset-0 flex justify-center items-center">
                <div
                  class="rounded-full w-10 h-10 bg-white"
                  style="
                    mask-image: url(/imgs/logos/dolphin-cay-sm.png);
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;
                  "
                />
              </div>
            </div>
            <span class="headline-2">{{ fields.sectionOneTitle.value }}</span>
            <VoixWysiwyg
              :field="fields.sectionOneDescription"
              class="font-sans2 font-light leading-tight text-zinc-500"
            />
          </div>
          <div
            class="mt-8 lg:mt-0 flex flex-col lg:flex-row items-center space-y-5 lg:space-y-0 lg:space-x-4 flex-1 mx-12"
          >
            <div class="aspect-video">
              <VoixMedia :field="fields.sectionOneImage1" class="w-full h-full object-cover" />
            </div>

            <div class="aspect-video">
              <VoixMedia :field="fields.sectionOneImage2" class="w-full h-full object-cover" />
            </div>
          </div>
        </div>
        <div
          v-else-if="mode === 'tab-2'"
          class="container mx-auto flex flex-col lg:flex-row items-center"
        >
          <div class="w-72 text-center flex flex-col items-center space-y-4 text-red-500">
            <div
              class="relative rounded-full w-12 h-12 bg-red-500 border-2 border-red-500"
            >
              <div class="absolute inset-0 flex justify-center items-center">
                <div
                  class="rounded-full w-10 h-10 bg-white"
                  style="
                    mask-image: url(/imgs/logos/marine-adventures-md.png);
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;
                  "
                />
              </div>
            </div>
            <span class="headline-2">{{ fields.sectionTwoTitle.value }}</span>
            <VoixWysiwyg
              :field="fields.sectionTwoDescription"
              class="font-sans2 font-light leading-tight text-zinc-500"
            />
          </div>
          <div
            class="mt-8 lg:mt-0 flex flex-col lg:flex-row items-center space-y-5 lg:space-y-0 lg:space-x-4 flex-1 mx-12"
          >
            <VoixMedia :field="fields.sectionTwoImage1" class="aspect-video" />

            <VoixMedia :field="fields.sectionTwoImage2" class="aspect-video" />
          </div>
        </div>
        <div
          v-else-if="mode === 'tab-3'"
          class="container mx-auto flex flex-col lg:flex-row items-center"
        >
          <div class="w-72 text-center flex flex-col items-center space-y-4 text-red-500">
            <div
              class="relative rounded-full w-12 h-12 bg-red-500 border-2 border-red-500"
            >
              <div class="absolute inset-0 flex justify-center items-center">
                <div
                  class="rounded-full w-10 h-10 bg-white"
                  style="
                    mask-image: url(/imgs/logos/all-adventures-md.png);
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;
                  "
                />
              </div>
            </div>
            <span class="headline-2">{{ fields.sectionThreeTitle.value }}</span>
            <VoixWysiwyg
              :field="fields.sectionThreeDescription"
              class="font-sans2 font-light leading-tight text-zinc-500"
            />
          </div>
          <div
            class="mt-8 lg:mt-0 flex flex-col lg:flex-row items-center space-y-5 lg:space-y-0 lg:space-x-4 flex-1 mx-12"
          >
            <VoixMedia :field="fields.sectionThreeImage1" class="aspect-video" />

            <VoixMedia :field="fields.sectionThreeImage2" class="aspect-video" />
          </div>
        </div>
      </div>
    </div>

    <div>
      <slot />
    </div>
  </div>
</template>
