<script>
export default {
  name: 'MusicMakingWavesEventCard',
  props: { devise: { type: Object, default: () => ({}) } },

  data() {
    return {
      showMoreInfo: false,
    }
  },

  computed: {
    isComplex() {
      return (
        (this.devise.fields.mode.value === 'complex'
        || this.devise.fields.mode.value === 'complex-right')
        && this.$voix.breakpoint.value !== 'tablet'
        && this.$voix.breakpoint.value !== 'mobile'
      )
    },

    numberOfLinks() {
      let numberOfLinks = 0
      if (this.devise.fields.getTicketsLink.enabled)
        numberOfLinks += 1

      if (this.devise.fields.roomAndTicketsLinks.enabled)
        numberOfLinks += 1

      if (this.devise.fields.moreInfoContent.enabled)
        numberOfLinks += 1

      return numberOfLinks
    },
  },
}
</script>

<template>
  <div
    class="grid"
    :class="{
      'w-full grid-cols-12': isComplex,
    }"
  >
    <div
      :class="{
        'col-span-10': isComplex,
        'col-start-3': devise.fields.mode.value === 'complex-right',
        'flex flex-col h-[550px]': !isComplex,
      }"
    >
      <div
        :class="{
          'h-[500px]': isComplex,
          'flex-1 overflow-hidden': !isComplex,
        }"
      >
        <VoixMedia :field="devise.fields.image" class="object-cover w-full h-full" />
      </div>
      <div>
        <div
          class="text-2xl font-mmw-headline uppercase py-8 px-8"
          :class="{
            'text-white bg-mmw-blue': devise.fields.color.value === 'blue',
            'text-mmw-orange bg-black': devise.fields.color.value === 'orange',
            'text-black bg-mmw-teal': devise.fields.color.value === 'teal',
            'text-center': !isComplex,
          }"
        >
          <div>{{ devise.fields.title.value }}</div>
          <div
            v-if="!isComplex"
            :class="{
              'text-mmw-teal ': devise.fields.color.value === 'blue',
              'text-white': devise.fields.color.value === 'orange',
              'text-black ': devise.fields.color.value === 'teal',
            }"
          >
            <DateFormatter :date="devise.fields.date.value" format="MMMM D" />
          </div>
        </div>
        <div
          v-if="numberOfLinks > 0"
          class="grid gap-[1px] bg-white"
          :class="{
            'grid-cols-1': numberOfLinks === 1 && isComplex,
            'grid-cols-2': numberOfLinks === 2 && isComplex,
            'grid-cols-3': numberOfLinks === 3 && isComplex,
          }"
        >
          <a
            v-if="devise.fields.getTicketsLink.enabled"
            :href="devise.fields.getTicketsLink.value?.href"
            :target="devise.fields.getTicketsLink.value.target"
            class="block py-5 text-white text-center font-mmw-headline uppercase"
            :class="{
              'bg-black': devise.fields.color.value === 'blue' || devise.fields.color.value === 'teal',
              'bg-mmw-orange': devise.fields.color.value === 'orange',
            }"
          >
            Get Tickets
          </a>
          <a
            v-if="devise.fields.roomAndTicketsLinks.enabled"
            :href="devise.fields.roomAndTicketsLinks.value?.href"
            :target="devise.fields.roomAndTicketsLinks.value.target"
            class="block py-5 text-white text-center font-mmw-headline uppercase"
            :class="{
              'bg-black': devise.fields.color.value === 'blue' || devise.fields.color.value === 'teal',
              'bg-mmw-orange': devise.fields.color.value === 'orange',
            }"
          >
            Get Room & Tickets
          </a>
          <button
            v-if="devise.fields.moreInfoContent.enabled"
            class="block py-5 text-white text-center font-mmw-headline uppercase"
            :class="{
              'bg-black/80':
                devise.fields.color.value === 'blue' || devise.fields.color.value === 'teal',
              'bg-mmw-orange/80': devise.fields.color.value === 'orange',
            }"
            @click="showMoreInfo = true"
          >
            More Info
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="isComplex"
      class="col-span-2 overflow-hidden flex flex-col"
      :class="{
        'col-start-1 row-start-1': devise.fields.mode.value === 'complex-right',
      }"
    >
      <div
        class="py-20 text-center font-mmw-headline font-bold text-2xl"
        :class="{
          'text-white bg-mmw-blue': devise.fields.color.value === 'blue',
          'text-black bg-mmw-orange': devise.fields.color.value === 'orange',
          'text-black bg-mmw-teal': devise.fields.color.value === 'teal',
        }"
      >
        <DateFormatter :date="devise.fields.date.value" format="MM.DD.YY" />
      </div>
      <div class="bg-red-500 flex-1 overflow-hidden grayscale">
        <div
          :style="{ backgroundImage: `url(${devise.fields.bawImage.value?.[0]?.url})` }"

          class="object-cover w-full h-full"
        />
      </div>
    </div>

    <Teleport v-if="showMoreInfo" to="#portal-modal">
      <div class="fixed inset-0 flex justify-center items-center">
        <div
          class="fixed z-10 inset-0 bg-white/75 cursor-pointer"
          @click="showMoreInfo = false"
        />
        <div class="relative z-20 w-full max-w-[900px] m-20 bg-gray-100">
          <div
            class="bg-black p-2 text-white flex justify-end cursor-pointer"
            @click="showMoreInfo = false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>

          <div class="grid lg:grid-cols-2 p-8">
            <div class="font-mmw pr-8" v-html="devise.fields.moreInfoContent.value" />
            <div class="flex justify-center items-center">
              <VoixMedia :field="devise.fields.moreInfoImage" />
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>
