<script setup lang="ts">
import type { SliceInterface, VoixLinkFieldInterface, VoixTextFieldInterface } from '@voix/types'
import type { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

defineProps<{
  slice: SliceInterface
  fields: {
    title: VoixTextFieldInterface
    link: VoixLinkFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Event Chef Slider', group: 'Yes, Chef', layouts: ['Npiwff'] },
  description: 'Event Chef Slider Slice',
  tags: ['Slider', 'Chef'],

  slots: [{ name: 'default', allowedElements: ['SlicesWffEventChefSliderCard'] }],
  fields: {
    title: { type: 'text', label: 'Title' },
    link: { type: 'link', label: 'Link' },
  },
  templates: [{
    label: 'Event Chef Slider',
    fields: {
      title: { value: 'Participating Chefs' },
      link: { value: { href: 'https://www.atlantisbahamas.com/wine-and-food-festival', target: '_blank', text: 'View All' } },
    },
  }],
})

const swiper: Ref<SwiperType | null> = ref(null)

const activeIndex = computed(() => {
  if (swiper.value)
    return swiper.value.activeIndex - 1

  return 1
})

function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}
function next() {
  if (swiper.value)
    swiper.value.slideNext()
}
function prev() {
  if (swiper.value)
    swiper.value.slidePrev()
}
function goToSlide(index: number) {
  if (swiper.value)
    swiper.value.slideTo(index)
}
</script>

<template>
  <div class="py-16 flex flex-col lg:flex-row max-w-full">
    <div
      class="px-8 lg:px-0 lg:pt-32 mb-10 lg:mb-0 w-full lg:w-1/3"
    >
      <div class="w-full lg:w-3/4 mx-auto">
        <!-- Title -->
        <div class="w-fit font-wff-headline text-wff-primary text-[32px] xl:text-[38px] font-extrabold uppercase tracking-widest">
          {{ fields.title.value }}
        </div>

        <!-- Link -->
        <a
          class="inline-block text-center border-2 px-6 pt-2 pb-4 mt-11 text-wff-primary border-wff-primary hover:bg-wff-primary hover:text-white duration-200 uppercase font-sans font-bold tracking-widest min-w-52"
          :href="fields.link.value?.href"
          :target="fields.link.value?.target"
        >
          {{ fields.link.value?.text }}
        </a>
      </div>
    </div>

    <!-- Slider -->
    <div
      v-if="slice.elements"
      class="relative clipping-slider overflow-hidde lg:w-[61.25%]"
    >
      <Swiper
        slides-per-view="auto"
        :space-between="10"
        :loop="true"
        :centered-slides="false"
        @swiper="onSwiper"
      >
        <SwiperSlide
          v-for="(s, key) in slice.elements"
          :key="key"
          class="flex"
        >
          <VoixSlice :slice="s as SliceInterface" :slice-index="key" />
        </SwiperSlide>
      </Swiper>

      <div class="flex justify-between mx-4 lg:ml-0">
        <!-- Slider Navigation -->
        <div class="flex items-center space-x-3 mt-4">
          <button
            class="w-10 h-10 flex justify-center items-center rounded-full border border-wff-secondary text-white hover:text-wff-secondary transition-colors duration-500 bg-wff-secondary hover:bg-white"
            @click="prev"
          >
            <svg
              class="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <button
            class="w-10 h-10 flex justify-center items-center rounded-full border border-wff-secondary text-white hover:text-wff-secondary transition-colors duration-500 bg-wff-secondary hover:bg-white"
            @click="next"
          >
            <svg
              class="w-7 h-7 transform rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>

        <!-- Slider Pagination -->
        <div class="flex space-x-3 mt-6">
          <button
            v-for="(s, key) in slice.elements"
            :key="key"
            class="h-1.5 w-1.5 rounded-full transistion-colors duration-500"
            :class="{
              'bg-gray-500': key !== activeIndex % 5,
              'bg-white': key === activeIndex % 5,
            }"
            @click="goToSlide(key)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto;
  height: auto;
}
</style>
