<script>
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  name: 'OlapicSlider',

  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    devise: { type: Object },
    arrowColor: {
      type: String,
      default: 'blue',
    },
  },

  data() {
    return {
      swiper: null,
      images: [],
    }
  },

  mounted() {
    this.getCollection()
    this.updateOnInit()
  },

  methods: {
    getCollection() {
      const streamId = this.devise?.stream?.text
        ? this.devise.fields.stream.value
        : '2162618781'

      const apiUrl = `/api/devise/olapic?stream_id=${streamId}`

      $voixFetch(apiUrl).then((response) => {
        this.images = response
      })
    },

    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    updateOnInit() {
      setTimeout(() => {
        if (this.$refs.mySwiper?.$el?.swiper) {
          this.$refs.mySwiper.$el.swiper.update()
          this.swiper = this.$refs.mySwiper.$el.swiper
        }
        else {
          this.updateOnInit()
        }
      }, 1000)
    },
  },
}
</script>

<template>
  <div>
    <Swiper
      ref="mySwiper"
      class="mt-12"
      slides-per-view="auto"
      :loop="false"
      :centered-slides="false"
      :space-between="20"
    >
      <SwiperSlide v-for="(image, key) in images" :key="key">
        <div class="relative w-40 h-40 lg:w-110 lg:h-110">
          <img :src="image.images.normal" class="bg-cover w-full h-full">
          <div class="absolute inset-0 flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="text-white opacity-50 w-6 h-6 lg:w-12 lg:h-12"
            >
              <path
                d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>

    <div class="flex justify-between mx-4 lg:ml-0">
      <div class="flex items-center space-x-3 my-8 ml-12">
        <button
          class="w-10 h-10 flex justify-center items-center rounded-full border transition-colors duration-500 hover:bg-white"
          :class="{
            'border-glueblue-600 text-glueblue-600': arrowColor === 'blue',
            'border-zinc-500 text-zinc-500': arrowColor === 'zinc',
          }"
          @click="prev"
        >
          <svg
            class="w-7 h-7"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button
          class="w-10 h-10 flex justify-center items-center rounded-full border transition-colors duration-500 hover:bg-white"
          :class="{
            'border-glueblue-600 text-glueblue-600': arrowColor === 'blue',
            'border-zinc-500 text-zinc-500': arrowColor === 'zinc',
          }"
          @click="next"
        >
          <svg
            class="w-7 h-7 transform rotate-180"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto;
  height: auto;
}
</style>

<style>
.swiper-wrapper {
  @apply items-stretch;
}
</style>
