<script>
export default {
  name: 'CoveLogo',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    viewBox="0 0 487 50" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <g>
      <path
        d="M306.631,25.111c0,13.407 11.228,24.313 25.03,24.313c14.438,0 25.327,-10.581 25.327,-24.611c0,-13.634 -10.969,-24.313 -24.97,-24.313c-13.999,0 -25.387,11.041 -25.387,24.611Zm7.503,0c0,-9.594 7.882,-17.107 17.943,-17.107c9.924,0 17.406,7.278 17.406,16.928c0,9.719 -7.611,17.048 -17.704,17.048c-9.894,-0 -17.645,-7.41 -17.645,-16.869Zm-76.818,0.398c-0,14.19 10.902,24.491 25.924,24.491c9.204,0 15.652,-2.669 22.257,-9.212l0.739,-0.732l-5.062,-5.652l-0.818,0.848c-4.875,5.051 -10.062,7.305 -16.818,7.305c-9.332,0 -18.779,-5.897 -18.779,-17.168c0,-9.559 7.672,-17.047 17.467,-17.047c6.387,-0 11.903,2.245 17.359,7.066l0.76,0.672l5.283,-5.118l-0.779,-0.786c-5.969,-6.031 -13.688,-9.219 -22.324,-9.219c-14.372,0 -25.209,10.556 -25.209,24.552Zm208.472,-23.419l-0,46.776l40.269,0l0,-7.444l-32.766,-0l-0,-13.027l20.238,0l-0,-7.444l-20.238,0l-0,-11.417l31.454,0l-0,-7.444l-38.957,0Zm-29.472,0l-16.918,35.614l-17.032,-35.614l-8.335,0l22.292,46.776l5.978,0l22.354,-46.776l-8.339,0Zm-278.597,0.001l-0,46.776l40.269,-0l0,-7.444l-32.765,0l0,-13.028l20.237,0l0,-7.443l-20.237,-0l0,-11.417l31.452,-0l0,-7.444l-38.956,-0Zm-37.096,-0l0,18.682l-26.458,0l0,-18.682l-7.502,-0l0,46.776l7.502,-0l0,-20.651l26.458,-0l0,20.651l7.503,-0l-0,-46.776l-7.503,-0Zm-100.623,-0l-0,7.444l16.294,-0l0,39.332l7.444,-0l0,-39.332l16.352,-0l0,-7.444l-40.09,-0Z"
        style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
      />
    </g>
  </svg>
</template>
