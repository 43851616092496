import { onMounted, onUnmounted, ref } from 'vue'

export function useStickyScroll(childEl: Ref<null | HTMLElement>, parentEl: Ref<null | HTMLElement>) {
  const isSticky = ref(false)
  const styles = ref({}) // Stores dynamic styles for the sticky element

  const updateStyles = () => {
    if (!childEl.value || !parentEl.value)
      return

    const parentRect = parentEl.value.getBoundingClientRect()
    const childRect = childEl.value.getBoundingClientRect()

    if (parentRect.top < 0 && parentRect.bottom > childRect.height) {
      // Stick to the top of the viewport
      isSticky.value = true
      styles.value = {
        position: 'fixed',
        top: '50px',
        left: `${childRect.left}px`,
        width: `${childRect.width}px`,
      }
    }
    else if (parentRect.bottom <= childRect.height) {
      // Scroll with the parent when reaching its bottom
      isSticky.value = false
      styles.value = {
        position: 'absolute',
        top: `${parentEl.value.offsetHeight - childRect.height}px`,
        left: '0',
        width: `${childRect.width}px`,
      }
    }
    else {
      // Reset to normal position
      isSticky.value = false
      styles.value = {}
    }
  }

  const handleScroll = () => {
    updateStyles()
  }

  onMounted(() => {
    console.log('here')
    window.addEventListener('scroll', handleScroll)
    updateStyles() // Initial update
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll)
  })

  return { isSticky, styles }
}
