<script>
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  name: 'Shopping',

  components: {
    Swiper,
    SwiperSlide,
  },
  props: { devise: { type: Object, default: () => ({}) } },

  data() {
    return {
      mode: 'crystal-cour',
      swiper: null,
    }
  },

  mounted() {
    this.updateOnInit()
  },

  methods: {
    goTo(mode) {
      this.mode = mode

      if (mode === 'crystal-cour')
        this.goToSlide(0)
      else if (mode === 'escape-cove')
        this.goToSlide(1)
      else if (mode === 'atlantis-logo')
        this.goToSlide(2)
      else if (mode === 'marina')
        this.goToSlide(3)
      else if (mode === 'pro-shop')
        this.goToSlide(4)
    },

    updateOnInit() {
      setTimeout(() => {
        if (this.$refs.mySwiper?.$el?.swiper) {
          this.$refs.mySwiper.$el.swiper.update()
          this.swiper = this.$refs.mySwiper.$el.swiper
        }
        else {
          this.updateOnInit()
        }
      }, 1000)
    },

    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    goToSlide(index) {
      this.swiper.slideTo(index)
    },
  },
}
</script>

<template>
  <div>
    <div class="hidden lg:block pt-12 pb-40">
      <div class="grid lg:grid-cols-4">
        <div class="flex justify-end py-24 px-12">
          <ul
            class="w-56 my-8 uppercase font-sans font-light text-sm pr-8 space-y-8 text-right"
          >
            <li
              class="cursor-pointer transition-all duration-200"
              :class="{
                'text-lg text-red-500 font-medium': mode === 'crystal-cour',
              }"
              @click="goTo('crystal-cour')"
            >
              CRYSTAL COURT SHOPS
            </li>
            <li
              class="cursor-pointer transition-all duration-200"
              :class="{
                'text-lg text-red-500 font-medium': mode === 'escape-cove',
              }"
              @click="goTo('escape-cove')"
            >
              ESCAPE AT THE COVE
            </li>
            <li
              class="cursor-pointer transition-all duration-200"
              :class="{
                'text-lg text-red-500 font-medium': mode === 'atlantis-logo',
              }"
              @click="goTo('atlantis-logo')"
            >
              ATLANTIS LOGO STORES
            </li>
            <li
              class="cursor-pointer transition-all duration-200"
              :class="{
                'text-lg text-red-500 font-medium': mode === 'marina',
              }"
              @click="goTo('marina')"
            >
              MARINA VILLAGE
            </li>
            <li
              class="cursor-pointer transition-all duration-200"
              :class="{
                'text-lg text-red-500 font-medium': mode === 'pro-shop',
              }"
              @click="goTo('pro-shop')"
            >
              PRO SHOP
            </li>
          </ul>
          <div class="relative w-1 bg-stone-300 mb-16">
            <!-- Track -->
            <div
              class="absolute top-0 left-0 right-0 w-1 bg-red-500 transition-all duration-300"
              :class="{
                'h-10': mode === 'crystal-cour',
                'h-[82px]': mode === 'escape-cove',
                'h-[136px]': mode === 'atlantis-logo',
                'h-[188px]': mode === 'marina',
                'h-[240px]': mode === 'pro-shop',
              }"
            />

            <!-- Handle -->
            <div
              class="absolute top-0 left-0 right-0 -ml-4 w-8 h-8 rounded-full bg-red-500 p-[2px] transition-all duration-300"
              :class="{
                'mt-7': mode === 'crystal-cour',
                'mt-[82px]': mode === 'escape-cove',
                'mt-[136px]': mode === 'atlantis-logo',
                'mt-[188px]': mode === 'marina',
                'mt-[240px]': mode === 'pro-shop',
              }"
            >
              <div
                class="w-full h-full rounded-full border-[0.5px] border-orange-100 p-[2px]"
              >
                <div
                  class="w-full h-full rounded-full border-[0.5px] border-orange-100 p-[2px]"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="relative col-span-3">
          <Swiper
            ref="mySwiper"
            :slides-per-view="1"
            :centered-slides="true"
            class="w-full h-[600px]"
          >
            <SwiperSlide class="w-full h-[600px]">
              <VoixMedia :field="devise.fields.courtImage" class="object-cover w-full h-full" />
            </SwiperSlide>
            <SwiperSlide class="w-full h-[600px]">
              <VoixMedia :field="devise.fields.escapeRoomImage" class="object-cover w-full h-full" />
            </SwiperSlide>
            <SwiperSlide class="w-full h-[600px]">
              <VoixMedia :field="devise.fields.logoStoreImage" class="object-cover w-full h-full" />
            </SwiperSlide>
            <SwiperSlide class="w-full h-[600px]">
              <VoixMedia :field="devise.fields.marineImage" class="object-cover w-full h-full" />
            </SwiperSlide>
            <SwiperSlide class="w-full h-[600px]">
              <VoixMedia :field="devise.fields.proShopImage" class="object-cover w-full h-full" />
            </SwiperSlide>
          </Swiper>

          <div class="absolute z-10 bottom-0 right-0 p-8 bg-white w-1/3 -mb-24">
            <div v-show="mode === 'crystal-cour'">
              <div
                class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
              >
                CRYSTAL COURT SHOPS
              </div>
              <div class="text-sm font-sans2 font-light antialiased mt-1">
                The Crystal Court Shops offer the finest in women’s and men’s
                fashions, featuring Amici, Cole’s of Nassau, Michael Kors,
                Gucci, Tory Burch, David Yurman, Balenciaga, Saint Laurent, and more”.
              </div>
            </div>
            <div v-show="mode === 'escape-cove'">
              <div
                class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
              >
                ESCAPE AT THE COVE
              </div>
              <div class="text-sm font-sans2 font-light antialiased mt-1">
                Even if you don’t usually engage in retail therapy, you’ll find
                yourself coveting nearly everything you see at Escape, The
                Cove’s perfectly curated designer boutique.
              </div>
            </div>
            <div v-show="mode === 'atlantis-logo'">
              <div
                class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
              >
                ATLANTIS LOGO STORES
              </div>
              <div class="text-sm font-sans2 font-light antialiased mt-1">
                The Logo Stores feature Atlantis souvenirs and gifts for adults
                and children, including t-shirts and caps, mugs, beach bags,
                Paradise Pals stuffed animals, postcards, towels, housewares,
                artwork and crafts by local artisans and more.
              </div>
            </div>
            <div v-show="mode === 'marina'">
              <div
                class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
              >
                MARINA VILLAGE
              </div>
              <div class="text-sm font-sans2 font-light antialiased mt-1">
                Discover everything your family will need for a fantastic day at
                the beach or find luxuries from fine jewelry, watches and
                clothing to crystal and everything in between!
              </div>
              <a v-if="devise.fields.marineLink.enabled" :href="devise.fields.marineLink.value?.href" :target="devise.fields.marineLink.value.target" class="mt-2 btn-ghost uppercase text-xs text-gray-700 px-8 font-sans border-gray-500 inline-block text-center py-4 leading-none btn-active tracking-wider">{{ devise.fields.marineLink.value.text }}</a>
            </div>
            <div v-show="mode === 'pro-shop'">
              <div
                class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
              >
                PRO SHOP
              </div>
              <div class="text-sm font-sans2 font-light antialiased mt-1">
                A full line of Atlantis sports apparel can be purchased in the
                Pro Shop. Rental tennis racquets and lessons with the pro are
                also available.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full lg:hidden overflow-hidden">
      <div
        class="font-sans2 uppercase font-bold font-stone-700 rounded-sm w-[1000px] transition-all duration-300"
        :class="{
          'ml-[40%]': mode === 'crystal-cour',
          'ml-[10%]': mode === 'escape-cove',
          '-ml-[90px]': mode === 'atlantis-logo',
          '-ml-[215px]': mode === 'marina',
          '-ml-[353px]': mode === 'pro-shop',
        }"
      >
        <ul class="flex space-x-8 mb-6">
          <li
            class="cursor-pointer"
            :class="{ 'text-red-500': mode === 'crystal-cour' }"
            @click="goTo('crystal-cour')"
          >
            CRYSTAL COURT SHOPS
          </li>
          <li
            class="cursor-pointer"
            :class="{ 'text-red-500': mode === 'escape-cove' }"
            @click="goTo('escape-cove')"
          >
            ESCAPE AT THE COVE
          </li>
          <li
            class="cursor-pointer"
            :class="{ 'text-red-500': mode === 'atlantis-logo' }"
            @click="goTo('atlantis-logo')"
          >
            ATLANTIS LOGO STORES
          </li>
          <li
            class="cursor-pointer"
            :class="{ 'text-red-500': mode === 'marina' }"
            @click="goTo('marina')"
          >
            MARINA VILLAGE
          </li>
          <li
            class="cursor-pointer"
            :class="{ 'text-red-500': mode === 'pro-shop' }"
            @click="goTo('pro-shop')"
          >
            PRO SHOP
          </li>
        </ul>

        <div class="relative h-2 bg-stone-300">
          <div
            class="absolute top-0 bottom-0 bg-red-500 rounded-sm transition-all duration-300"
            :class="{
              'w-[100px]': mode === 'crystal-cour',
              'w-[125px] ml-[115px]': mode === 'escape-cove',
              'w-[140px] ml-[240px]': mode === 'atlantis-logo',
              'w-[130px] ml-[380px]': mode === 'marina',
              'w-[100px] ml-[507px]': mode === 'pro-shop',
            }"
          />
        </div>
      </div>
      <div class="mt-4 mx-8">
        <VoixMedia v-if="mode === 'crystal-cour'" :field="devise.fields.courtImage" class="w-full h-full" />

        <VoixMedia v-if="mode === 'escape-cove'" :field="devise.fields.escapeRoomImage" class="w-full h-full" />

        <VoixMedia v-if="mode === 'atlantis-logo'" :field="devise.fields.logoStoreImage" class="w-full h-full" />

        <VoixMedia v-if="mode === 'marina'" :field="devise.fields.marineImage" class="w-full h-full" />

        <VoixMedia v-if="mode === 'pro-shop'" :field="devise.fields.proShopImage" class="w-full h-full" />

        <div class="p-8 bg-white">
          <div v-show="mode === 'crystal-cour'">
            <div
              class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
            >
              CRYSTAL COURT SHOPS
            </div>
            <div class="text-sm font-sans2 font-light antialiased mt-1">
              The Crystal Court Shops offer the finest in women’s and men’s
              fashions, featuring Amici, Cole’s of Nassau, Michael Kors, Gucci,
              Tory Burch, David Yurman, Balenciaga, Saint Laurent, and more”.
            </div>
          </div>
          <div v-show="mode === 'escape-cove'">
            <div
              class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
            >
              ESCAPE AT THE COVE
            </div>
            <div class="text-sm font-sans2 font-light antialiased mt-1">
              Even if you don’t usually engage in retail therapy, you’ll find
              yourself coveting nearly everything you see at Escape, The Cove’s
              perfectly curated designer boutique.
            </div>
          </div>
          <div v-show="mode === 'atlantis-logo'">
            <div
              class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
            >
              ATLANTIS LOGO STORES
            </div>
            <div class="text-sm font-sans2 font-light antialiased mt-1">
              The Logo Stores feature Atlantis souvenirs and gifts for adults
              and children, including t-shirts and caps, mugs, beach bags,
              Paradise Pals stuffed animals, postcards, towels, housewares,
              artwork and crafts by local artisans and more.
            </div>
          </div>
          <div v-show="mode === 'marina'">
            <div
              class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
            >
              MARINA VILLAGE
            </div>
            <div class="text-sm font-sans2 font-light antialiased mt-1">
              Discover everything your family will need for a fantastic day at
              the beach or find luxuries from fine jewelry, watches and clothing
              to crystal and everything in between!
            </div>
          </div>
          <div v-show="mode === 'pro-shop'">
            <div
              class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
            >
              PRO SHOP
            </div>
            <div class="text-sm font-sans2 font-light antialiased mt-1">
              A full line of Atlantis sports apparel can be purchased in the Pro
              Shop. Rental tennis racquets and lessons with the pro are also
              available.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
