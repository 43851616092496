<script setup lang="ts">
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
  sliceIndex: {
    type: Number,
    default: -1,
  },
})

defineSlice({
  name: { label: 'Accordion Content Thin', group: 'Accordion', layouts: ['*'] },
  preview: 'SlicesGlueAccordionsAccordionContentThin.jpg',
  description: 'Content that can be opened up to view more',
  tags: ['Accordion'],
  fields: {
    title: { type: 'text', label: 'Title', editorLabel: true },
    copy: { type: 'wysiwyg', label: 'Copy' },
  },
  templates: [{ label: 'Accordion Content Thin', fields: { copy: { value: '<p>\u2022 This offer is valid for new reservation only at The Coral, The Royal, The Cove and The Reef with a minimum 2-night stay.<\/p><p>\u2022 Up to 25% discount is off the Best Available Rates.<\/p><p>\u2022 Offer subject to VAT and Levies, Resort Fee and Mandatory Gratuities: 21% charge on nightly room rate (10% VAT, 4.4% Paradise Island Tourism and Development Association Levy (including VAT), and 6.6% Nassau Paradise Island Promotional Board Levy (including VAT)), daily Resort Fee ($59 plus a $5.90 VAT charge, totaling $64.90 at The Coral and The Royal, and $70 plus a $7 VAT charge, totaling $77 at The Cove and The Reef), and daily Mandatory Gratuities (up to $10 per person, per night, 12 and older, at The Coral and The Royal, and up to $18 per person, per night, 12 and older, at The Cove and The Reef.<\/p><p>\u2022 Offer is not combined with other offers and is not valid for group bookings.<\/p><p>\u2022 Offer subject to availability and blackout dates.<\/p><p>\u2022 Offer subject to Payment, Cancellation, and Refund Policies listed in the General Terms &amp; Conditions.<\/p><p>\u2022 This offer and terms and conditions are subject to change at the discretion of the resort.<\/p>' }, title: { value: 'Terms and Conditions' } } }],
})

const { activeItem, updateActiveItem } = inject('activeItem') as any
const style = inject('style')

const expanded = computed(() => activeItem.value === props.sliceIndex || activeItem.value === -1)

const accordionStyles = computed(() => {
  if (expanded.value === true) {
    return {
      height: `${getHeight()}px`,
    }
  }

  return {
    height: `0px`,
  }
})

function toggleExpanded() {
  if (!expanded.value)
    updateActiveItem(props.sliceIndex)
  else
    updateActiveItem(null)
}

const content: Ref<HTMLElement | null> = ref(null)

function getHeight() {
  if (content.value) {
    const style = getComputedStyle(content.value)
    const marginTop = Number.parseInt(style.marginTop)
    const marginBottom = Number.parseInt(style.marginBottom)
    const borderTopWidth = Number.parseInt(style.borderTopWidth) || 0
    const borderBottomWidth = Number.parseInt(style.borderBottomWidth) || 0

    return (
      content.value.scrollHeight
      + marginTop
      + marginBottom
      + borderTopWidth
      + borderBottomWidth
    )
  }
  return 0
}
</script>

<template>
  <div
    v-if="fields.title.value"
    class="py-6 md:py-10 w-full"

    :class="{
      'border border-gray-200 px-8 rounded-br-[30px] transition-colors duration-500':
        style === 'mmw',
      'bg-gray-100': style === 'mmw' && expanded,
    }"
  >
    <div class="relative text-lg pr-8 cursor-pointer" @click="toggleExpanded">
      <span
        :class="{
          'lg:text-2xl font-medium font-mmw': style === 'mmw',
        }"
      >
        {{ fields.title.value }}</span>
      <div
        class="text-gray-500 absolute right-0 top-0 transform duration-500 ease-in-out"
        :class="{ 'rotate-180': !expanded }"
      >
        <svg
          v-if="style === 'default'"
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 15l7-7 7 7"
          />
        </svg>

        <svg
          v-if="style === 'mmw'"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 lg:w-10 h-6 lg:h-10 text-gray-700"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
      </div>
    </div>
    <div
      class="font-light overflow-hidden transform-all duration-500 ease-out"
      :style="accordionStyles"
    >
      <div
        ref="content"
        class="mt-8 pt-8 border-t border-gray-300 wysiwyg prose prose-a:text-glueblue-500 prose-a:font-bold"
        v-html="fields.copy.value"
      />
    </div>
  </div>
</template>
