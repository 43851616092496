<script setup lang="ts">
import IconsBedIcon from '@/components/icons/IconsBedIcon.vue'
import IconsHandCoinIcon from '@/components/icons/IconsHandCoinIcon.vue'
import IconsResortIcon from '@/components/icons/IconsBuildingIcon.vue'
import IconsBeachIcon from '@/components/icons/IconsBeachIcon.vue'
import IconsButlerIcon from '@/components/icons/IconsButlerIcon.vue'
import IconsPoolIcon from '@/components/icons/IconsPoolIcon.vue'
import IconsCasinoIcon from '@/components/icons/IconsCasinoIcon.vue'
import IconsCocktailIcon from '@/components/icons/IconsCocktailIcon.vue'
import IconsDiningIcon from '@/components/icons/IconsDiningIcon.vue'
import IconsDocumentIcon from '@/components/icons/IconsDocument.vue'
import IconsGolfIcon from '@/components/icons/IconsGolfIcon.vue'
import IconsWaterParkIcon from '@/components/icons/IconsWaterParkIcon.vue'
import IconsSpaIcon from '@/components/icons/IconsSpaIcon.vue'
import IconsEventsIcon from '@/components/icons/IconsEventsIcon.vue'
import IconsFamilyIcon from '@/components/icons/IconsFamilyIcon.vue'
import IconsGroupsIcon from '@/components/icons/IconsGroupsIcon.vue'
import IconsMarineIcon from '@/components/icons/IconsMarineIcon.vue'
import IconsTurtleIcon from '@/components/icons/IconsTurtleIcon.vue'
import IconsSnorkelIcon from '@/components/icons/IconsSnorkelIcon.vue'
import IconsFlagIcon from '@/components/icons/IconsFlagIcon.vue'
import IconsOfficeChairIcon from '@/components/icons/IconsOfficeChairIcon.vue'
import IconsPhoneIcon from '@/components/icons/IconsPhone.vue'
import IconsEmailIcon from '@/components/icons/IconsEmail.vue'
import IconsChatIcon from '@/components/icons/IconsChat.vue'
import IconsBoatIcon from '@/components/icons/IconsBoat.vue'
import IconsPigIcon from '@/components/icons/IconsPig.vue'
import IconsCityIcon from '@/components/icons/IconsCity.vue'
import IconsAnniversaryIcon from '@/components/icons/IconsAnniversary.vue'

const props = defineProps({
  icon: { type: String },
  iconColor: {
    type: String,
    default: 'bg-red-500',
  },
})

const icons: { [key: string]: Component } = {
  IconsBedIcon,
  IconsHandCoinIcon,
  IconsResortIcon,
  IconsBeachIcon,
  IconsButlerIcon,
  IconsPoolIcon,
  IconsCasinoIcon,
  IconsCocktailIcon,
  IconsDiningIcon,
  IconsDocumentIcon,
  IconsGolfIcon,
  IconsWaterParkIcon,
  IconsSpaIcon,
  IconsEventsIcon,
  IconsFamilyIcon,
  IconsGroupsIcon,
  IconsMarineIcon,
  IconsTurtleIcon,
  IconsSnorkelIcon,
  IconsFlagIcon,
  IconsOfficeChairIcon,
  IconsPhoneIcon,
  IconsEmailIcon,
  IconsChatIcon,
  IconsBoatIcon,
  IconsPigIcon,
  IconsCityIcon,
  IconsAnniversaryIcon,
}

const currentIcon: ComputedRef<string> = computed(() => {
  if (props.icon === 'water-park')
    return 'IconsWaterParkIcon'

  if (props.icon === 'office-chair')
    return 'IconsOfficeChairIcon'

  if (props.icon === 'hand')
    return 'IconsHandCoinIcon'

  if (!props.icon)
    return 'IconsBedIcon'

  return `Icons${
    props.icon.charAt(0).toUpperCase() + props.icon.substring(1)
  }Icon`
})
</script>

<template>
  <Component :is="icons[currentIcon]" />
</template>
