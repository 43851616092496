<script setup lang="ts">
import { getCurrentBreakpoint } from '@voix/composables/useBreakpoints'
import { usePageStore } from '@voix/store/pageStore'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({

  name: { label: 'Icon Card', group: 'Glue', layouts: ['*'] },
  preview: 'SlicesGlueGenericCardsIconCard.jpg',
  description: 'Card with Icon in the middle',
  tags: ['Card', 'Icon'],
  fields: {
    style: { type: 'select', label: 'Style', options: { bigDescription: 'Big Description (Title Ignored)', titleDescription: 'Title Description' }, default: 'bigDescription' },
    size: { type: 'select', label: 'Size', allowNull: false, options: { small: 'Small', large: 'Large' }, default: 'large' },
    image: {
      type: 'media',
      label: 'Image',
      breakpoints: {
        default: {
          width: 315,
          height: 350,
        },
        lg: {
          width: 455,
          height: 510,
        },

      },
    },
    icon: { type: 'select', label: 'Icon', options: { 'bed': 'Bed', 'hand': 'Hand', 'resort': 'Resort', 'beach': 'Beach', 'butler': 'Butler', 'pool': 'Pool', 'casino': 'Casino', 'cocktail': 'Cocktail', 'dining': 'Dining', 'golf': 'Golf', 'water-park': 'WaterPark', 'spa': 'Spa', 'events': 'Events', 'family': 'Family', 'groups': 'Groups', 'marine': 'Marine', 'snorkel': 'Snorkel', 'office-chair': 'Office Chair', 'flag': 'Flag', 'turtle': 'Turtle', 'pig': 'Pig', 'boat': 'Boat', 'city': 'City', 'anniversary': 'Anniversary' }, enabled: true },
    title: { type: 'text', label: 'Title' },
    description: { type: 'wysiwyg', label: 'Description' },

    link: { type: 'link', label: 'Link', enabled: false },
    alignment: { type: 'select', label: 'Alignment (Only for Title Description Style)', options: { 'text-left': 'Left', 'text-center': 'Center', 'text-right': 'Right' }, default: 'text-left' },
    descriptionAlignment: { type: 'select', label: 'Alignment (Overrides just for description)', options: { 'none': 'None', 'text-left': 'Left', 'text-center': 'Center', 'text-right': 'Right' }, default: 'none' },
  },
  templates: [{
    label: 'Big Description Icon Card',
    fields: {
      alignment: { value: 'text-left' },
      description: { value: '21 restaurants to savor worldly cuisines' },
      icon: { value: 'dining' },
      image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/glue\/06-sip-sip-lunch-211-at-3x.jpg' },
      size: { value: 'small' },
      style: { value: 'bigDescription' },
      link: { value: { text: 'view all dining', target: '_self', relationship: null, href: '\/new-page' }, enabled: true },
    },
  }, {
    label: 'Title Description Icon Card',
    fields: {
      alignment: { value: 'text-left' },
      description: { value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at dignissim mi, at ultrices quam. Cras tincidunt et magna nec sodales. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed at molestie libero. Suspendisse non molestie metus. Aenean suscipit nunc at imperdiet aliquet. Sed consequat ultricies eros eu commodo. Ut dictum fringilla elit, nec fringilla quam congue vel. Nulla fringilla odio vel felis rhoncus, quis sodales odio condimentum.' },
      icon: { value: 'dining' },
      image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/glue\/06-sip-sip-lunch-211-at-3x.jpg' },
      size: { value: 'small' },
      style: { value: 'titleDescription' },
      link: { value: { text: 'view all dining', target: '_self', relationship: null, href: '\/new-page' }, enabled: true },
    },
  }],
})

const size = computed(() => {
  switch (props.fields.icon.value) {
    case 'bed':
    case 'hand':
    case 'beach':
    case 'butler':
    case 'pool':
    case 'dining':
    case 'golf':
    case 'water-park':
    case 'spa':
    case 'events':
    case 'family':
    case 'groups':
      return {
        width: '50',
        height: '40',
      }

    case 'resort':
    case 'casino':
    case 'cocktail':
      return {
        width: '50',
        height: '45',
      }

    case 'marine':
      return {
        width: '50',
        height: '50',
      }

    case 'anniversary':
      return {
        width: '55',
        height: '55',
      }
  }
  return {
    width: '50',
    height: '40',
  }
})
</script>

<template>
  <div>
    <div class="flex-none bg-white body pb-1 icon-card">
      <div class="h-[350px] lg:h-[510px]">
        <VoixMedia loading="eager" :field="fields.image" class="w-full h-full object-cover" />
      </div>

      <div v-if="fields.icon.enabled" class="flex justify-center -translate-y-1/2">
        <div
          class="rounded-full w-24 h-24 text-white flex items-center justify-center hotel-theme-bg hotel-theme-fg bg-red-500"
        >
          <IconsIcon
            :icon="fields.icon.value"
            :width="size.width"
            :height="size.height"
          />
        </div>
      </div>
      <div
        v-if="fields.style.value === 'bigDescription'"
        class="px-24 pb-10 flex justify-center items-center uppercase tracking-wider leading-relaxed text-center text-lg"
        v-html="fields.description?.value"
      />
      <div
        v-if="fields.style.value === 'titleDescription'"
        class="px-8 pb-8"
        :class="[fields.alignment.value]"
      >
        <div class="headline-3">
          {{ fields.title.value }}
        </div>
        <VoixWysiwyg class="text-zinc-500 wysiwyg pt-5" :field="fields.description" :class="[fields.descriptionAlignment.value]" />
      </div>
      <div
        v-if="fields.link.enabled"
        class="mb-8 flex px-8"
        :class="{
          'justify-center': fields.style.value === 'bigDescription' || fields.alignment.value === 'text-center',
          'justify-end': fields.alignment.value === 'text-right',
        }"
      >
        <a
          class="btn-ghost"
          :href="fields.link.value?.href"
        >
          {{ fields.link.value.text }}
        </a>
      </div>
    </div>
  </div>
</template>
