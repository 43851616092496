<script setup lang="ts">
import { useCanSee } from '~/composables/useCanSee'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
  sliceIndex: {
    type: Number,
    default: 0,
  },
})

defineSlice({
  name: { label: 'History Item', group: 'History', layouts: ['Atlantisbahamas'] },
  preview: 'SlicesGlueHistoryHistoryItem.jpg',
  description: 'History Item',
  tags: ['History'],
  fields: {
    year: { type: 'text', label: 'Year' },
    title: { type: 'text', label: 'Title' },
    copy: { type: 'wysiwyg', label: 'Copy' },
    image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 838, height: 524 }, default: { width: 324, height: 203 } } },
  },
  templates: [{ label: 'History Item', fields: { copy: { value: '<p>American musician Pharrell Williams and David Grutman of Groot Hospitality will partner with Atlantis to open a new resort. The Somewhere Else resort to be located at what was the Beach Tower is set to offer \u201Ca unique and immersive experience\u201D in The Bahamas. Somewhere Else will reportedly feature over 400 guest rooms and suites alongside an array of vibrant, top-tier dining venues, lushly landscaped grounds, multiple pools and recreation areas, luxury amenities, and live entertainment.&nbsp;<\/p>' }, image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/History\/somewhere-else-sketch.jpg' }, title: { value: 'Somewhere Else Opens' }, year: { value: '2025 & beyond' } } }],
})

const canSee = ref(false)
const open = ref(false)

const isEven = computed(() => {
  return props.sliceIndex % 2 === 0
})

function isVisible() {
  canSee.value = true
}
function isHidden() {
  canSee.value = false
}
const timelineItem = ref(null)
useCanSee(timelineItem, isVisible, isHidden)
</script>

<template>
  <div ref="timelineItem">
    <div
      class="flex flex-col"
      :class="{ 'md:flex-row': isEven, 'md:flex-row-reverse': !isEven }"
    >
      <div
        class="md:w-1/2 group"
        :class="{
          'transform translate-x-[-2px]': !isEven,
        }"
      >
        <div
          class="pt-12 flex flex-col-reverse transform duration-500 ease-in-out items-center md:items-stretch"
          :class="{
            'border-r-2 border-gray-300': !open && isEven,
            'border-r-3 border-red-300': open && isEven,
            'border-l-2 border-gray-300': !open && !isEven,
            'border-l-3 border-red-300': open && !isEven,
            'md:flex-row': isEven,
            'md:flex-row-reverse': !isEven,
          }"
        >
          <div class="py-4 md:w-3/4 flex flex-col" :class="{ 'items-end': isEven }">
            <div
              class="md:w-4/5 text-center flex flex-col items-center md:items-start"
              :class="{ 'md:text-right': isEven, 'md:text-left': !isEven }"
            >
              <div
                class="headline-2 transform transition-all duration-1000 ease-in-out"
                :class="{
                  'opacity-0': !canSee,
                  '-translate-x-12': !canSee && isEven,
                  'translate-x-12': !canSee && !isEven,
                  'translate-x-0 opacity-100': canSee,
                }"
              >
                {{ fields.title.value }}
              </div>
              <div
                class="mt-4 wysiwyg font-sans2 body prose overflow-hidden transform transition-all duration-1000 ease-in-out px-8 md:px-0"
                :class="{
                  'max-h-16': !open,
                  'max-h-[500px]': open,
                  'opacity-0': !canSee,
                  '-translate-x-12': !canSee && isEven,
                  'translate-x-12': !canSee && !isEven,
                  'translate-y-0 opacity-100': canSee,
                }"
                v-html="fields.copy.value"
              />
              <button
                class="mt-6 flex items-center font-sans2 font-bold text-gray-500 space-x-1"
                :class="{ 'md:self-end': isEven }"
                @click="open = !open"
              >
                <span class="text-sm">{{ open ? "Showless" : "Showmore" }}</span>
                <svg
                  width="14"
                  height="9"
                  viewBox="0 0 14 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="text-red-300 transform duration-300"
                  :class="{ 'rotate-180': open }"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.1252 0.739506L7.01691 6.29388L1.87439 0.739505C1.18871 -0.0227662 0.0230233 1.21137 0.708696 1.97381L6.53715 8.29029C6.77718 8.54446 7.22282 8.58069 7.46286 8.32653L13.2913 1.97381C13.977 1.21154 12.8112 -0.0227652 12.1256 0.739506L12.1252 0.739506Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="flex justify-end items-start md:w-1/4 px-12 md:px-0">
            <div
              class="flex items-center w-full"
              :class="{
                'md:translate-x-[40px]': isEven,
                'md:-translate-x-[40px] flex-row-reverse': !isEven,
              }"
            >
              <div
                class="hidden md:block rounded-full w-4 h-4 flex-none transition duration-300 ease-in-out"
                :class="{ 'bg-gray-300': !open, 'bg-red-300': open }"
              />
              <div
                class="w-full border-b-2 flex-1 transform duration-500 ease-in-out"
                :class="{
                  ' border-gray-300': !open,
                  'border-red-300': open,
                  'mr-4': isEven,
                  'ml-4': !isEven,
                }"
              />
              <div class="headline-2 bg-orange-100 py-4">
                <span
                  class="font-medium transform duration-500 ease-in-out"
                  :class="{
                    ' text-gray-400': !open,
                    'text-red-300': open,
                  }"
                >{{ fields.year.value }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="pt-6 transform duration-500 ease-in-out flex cursor-pointer justify-center"
          :class="{
            'border-r-2 border-gray-300': !open && isEven,
            'border-r-3 border-red-300': open && isEven,
            'border-l-2 border-gray-300': !open && !isEven,
            'border-l-3 border-red-300': open && !isEven,
          }"
          @click="open = !open"
        >
          <div
            class="relative transform delay-250 duration-500 ease-in-out transition-all duration-[1.5s]"
            :class="{
              'w-1/2 rounded-xl overflow-hidden': !open,
              'w-full': open,
              '-translate-x-32': !canSee && isEven,
              'translate-x-32': !canSee && !isEven,
              'opacity-0': !canSee,
              'translate-x-0 opacity-100': canSee,
            }"
          >
            <VoixMedia :field="fields.image" class="object-cover w-full h-full transition-all duration-1000" draggable="false" :class="{ ' grayscale contrast-50 group-hover:grayscale-0 group-hover:contrast-100': !open, ' grayscale-0 contrast-100': open }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
