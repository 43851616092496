<script>
export default {
  name: 'PersonIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 13">
    <path
      fill="currentcolor"
      d="M13.264 13C12.614 10.106 10.06 7.944 7 7.944S1.386 10.106.736 13H0c.662-3.297 3.544-5.778 7-5.778S13.338 9.703 14 13h-.736zM7 7.222c-1.973 0-3.572-1.617-3.572-3.61C3.428 1.616 5.028 0 7 0s3.572 1.617 3.572 3.611c0 1.994-1.6 3.611-3.572 3.611zm0-6.5c-1.578 0-2.858 1.294-2.858 2.89C4.142 5.206 5.422 6.5 7 6.5s2.857-1.293 2.857-2.889C9.857 2.016 8.578.722 7 .722z"
    />
  </svg>
</template>
