<script setup lang="ts">
defineProps<{
  fields: {
    files: VoixFilesFieldInterface
    columns: VoixNumberFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Stacked File List', group: 'File List', layouts: ['Reef'] },
  preview: 'SlicesFilelistStacked.jpg',
  description: 'A stack list of files',
  tags: ['File'],
  fields: {
    files: {
      type: 'files',
      label: 'Files',
      multiple: true,
    },
    columns: {
      type: 'number',
      label: 'Columns',
      default: '1',
      max: 3,
      min: 1,
    },
  },
  templates: [{
    label: 'File List',
    fields: {
      files: {
        value: [
        ],
      },
      colummns: {
        value: '1',
      },
    },
  }],
})

function fileName(url: string) {
  // Get the file name from the url
  const file = url.split('/').pop()
  // Get rid of the extension
  const fileWithoutExt = file.split('.').slice(0, -1).join('.').replace(/_/g, ' ')

  // break up the file into words when there is a capital letter, underscore, or dash
  const words = fileWithoutExt.split(/(?=[A-Z])|_|-/)

  // Uppercase the first letter of each word
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1))

  // Join the words back together with a space
  const unslug = capitalizedWords.join(' ')

  // Return the file name with the first letter capitalized
  return unslug.charAt(0).toUpperCase() + unslug.slice(1)
}
</script>

<template>
  <div>
    <ul class="container divide-y  divide-zinc-300 border-b border-zinc-300 grid" :style="{ gridTemplateColumns: `repeat(${fields.columns.value}, minmax(0, 1fr))` }">
      <li v-for="file in fields.files.value" :key="file.id" class="flex space-x-2 items-center py-3 px-4 first:border-t border-zinc-300">
        <Icon name="heroicons:document-arrow-down" class="w-6 h-6" />
        <a :href="file.url" target="_blank" class="">
          <span v-if="file.title">{{ file.title }}</span>
          <span v-else>{{ fileName(file.url) }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>
