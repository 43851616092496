<script>
import dayjs from 'dayjs'
import { getCurrentBreakpoint } from '@voix/composables/useBreakpoints'

export default {
  name: 'GlueSpecialOfferDetails',

  props: {
    special: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      breakpoint: getCurrentBreakpoint(),
      showTerms: false,
      showBestRate: false,
      showHurricane: false,
    }
  },
  computed: {
    formattedValidRange() {
      return `${dayjs(this.special.travel_from_date).format(
        'MMMM D, YYYY',
      )} - ${dayjs(this.special.travel_until_date).format(
        'MMMM D, YYYY',
      )}`
    },
    formattedBookBy() {
      return `${dayjs(this.special.book_by_date).format('MMMM D, YYYY')}`
    },
    titleHeight() {
      if (this.breakpoint !== 'mobile' && this.breakpoint !== 'tablet')
        return { height: '80px' }

      return {}
    },
  },
}
</script>

<template>
  <div v-if="special.included_items?.length">
    <div>
      <div
        class="text-center text-3xl md:text-4xl tracking-widest uppercase font-sans font-light leading-tight mb-4 md:mb-0"
      >
        Special offer details
      </div>
      <div
        class="text-center font-sans md:font-sans2 uppercase md:normal-case w-2/3 mx-auto text-gray-500 md:text-gray-600 font-normal md:font-light text-sm md:text-xl mb-2"
      >
        This offer includes:
      </div>

      <div class="p-6 md:p-8 md:mx-6 md:mx-0 flex flex-col items-center">
        <div
          class="text-center flex flex-col md:flex-row md:flex-wrap w-full justify-center"
        >
          <div
            v-for="(item, n) in special.included_items"
            :key="n"
            class="bg-white w-full md:w-1/3 md:border-r border-b border-gray-300 last:border-r-0 flex flex-col items-center p-12 pb-0 md:pb-12 md:pt-20"
          >
            <div
              class="bg-red-500 rounded-full h-24 w-24 text-white flex justify-center items-center mb-8"
            >
              <IconsIcon :icon="item.icon" class="h-12 w-12" />
            </div>
            <div
              class="font-light text-red-500  font-serif italic mb-1 text-2xl tracking-wider"
            >
              {{ item.superscript }}
            </div>
            <div
              :style="titleHeight"
              class="uppercase text-2xl font-light font-sans tracking-wider text-gray-600"
            >
              {{ item.title }}
            </div>
            <div class="font-sans2 text-gray-500">
              {{ item.description }}
            </div>

            <div
              class="bg-gray-300 w-full mt-10 md:hidden"
              style="height: 1px"
            />
          </div>
        </div>
        <div
          :class="{
            'w-full md:w-1/3': special.included_items?.length === 1,
            'w-full md:w-2/3': special.included_items?.length === 2,
            'w-full': special.included_items?.length > 2,
          }"
        >
          <div
            class="text-center bg-white font-sans2 text-15 text-gray-700 flex justify-center py-4"
            :class="{
              'flex-col md:flex-row md:flex-wrap':
                special.included_items?.length > 1,
              'flex-col': special.included_items?.length === 1,
            }"
          >
            <div class="mx-8 mb-4 md:mb-0">
              <span class="font-bold">Offer valid from:</span>
              {{ formattedValidRange }}
            </div>
            <div class="mx-8">
              <span class="font-bold">Book By:</span>
              {{ formattedBookBy }}
            </div>
          </div>
        </div>
        <div
          v-if="special.terms"
          class="text-center bg-gray-100 -mx-8 -mb-8 p-4 text-xs italic font-sans2"
          :class="{
            'w-full md:w-1/3': special.included_items?.length === 1,
            'w-full md:w-2/3': special.included_items?.length === 2,
            'w-full': special.included_items?.length > 2,
          }"
        >
          <div>
            <a
              href="#"
              class="underline text-gray-600"
              @click.prevent="showTerms = !showTerms"
            >View Terms and Conditions</a>
            for complete details
          </div>
          <div v-if="showTerms" class="mt-8">
            <div class="text-red-500 text-3xl italic font-serif">
              Terms and Conditions
            </div>
            <div
              class="font-sans2 text-left not-italic pt-4 pl-8 pr-8 pb-10"
              v-html="special.terms"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        special.show_best_rate_guarantee
          || special.show_hurricane_guarantee
      "
      class="flex justify-center py-16 mx-8"
    >
      <div class="mx-2 md:mx-8">
        <img
          v-if="special.show_best_rate_guarantee"
          src="/imgs/specials-icons/bestrate-guarantee.gif"
          class="cursor-pointer"
          alt="Best Rate Guarantee"
          @click="showBestRate = true"
        >
      </div>
      <div class="mx-2 md:mx-8">
        <img
          v-if="special.show_hurricane_guarantee"
          src="/imgs/specials-icons/hurricane-guarantee.gif"
          class="cursor-pointer"
          alt="Worry Free Hurricane"
          @click="showHurricane = true"
        >
      </div>
    </div>

    <Teleport v-if="showBestRate" to="#portal-modal">
      <GlueUiModal @close="showBestRate = false">
        <div class="text-red-500 text-3xl italic font-serif">
          Best Rate Guarantee
        </div>
        <div
          class="font-sans2 text-left"
          v-html="special.best_rate_guarantee_text"
        />
      </GlueUiModal>
    </Teleport>

    <Teleport v-if="showHurricane" to="#portal-modal">
      <GlueUiModal @close="showHurricane = false">
        <div class="text-red-500 text-3xl italic font-serif">
          Worry Free Hurricane Guarantee
        </div>
        <div
          class="font-sans2 text-left"
          v-html="special.hurricane_guarantee_text"
        />
      </GlueUiModal>
    </Teleport>
  </div>
</template>
