<script setup lang="ts">
import type { VoixCheckboxFieldConfigurationInterface, VoixMediaFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'
import { useStorage } from '@vueuse/core'

const props = defineProps<{
  fields: {
    // Header
    title: VoixTextFieldInterface
    // Start Page
    startCopy: VoixWysiwygFieldInterface
    startPrizeTitle: VoixTextFieldInterface
    startPrize1: VoixTextFieldInterface
    startPrize2: VoixTextFieldInterface
    startPrize3: VoixTextFieldInterface
    startImage: VoixMediaFieldInterface
    // Form Page
    formTitle: VoixTextFieldInterface
    formSubtitle: VoixTextFieldInterface
    // Upload Page
    uploadTitle: VoixTextFieldInterface
    uploadRequirements1: VoixTextFieldInterface
    uploadRequirements2: VoixTextFieldInterface
    uploadRequirements3: VoixTextFieldInterface
    uploadRequirements4: VoixTextFieldInterface
    // Complete Page
    completeCopy: VoixWysiwygFieldInterface
    completeImage: VoixMediaFieldInterface
    // Eligible Page
    eligibleCopy: VoixWysiwygFieldInterface
    // Reenrty Page
    reentryCopy: VoixWysiwygFieldInterface
    // Prizes Page
    prizesCopy: VoixWysiwygFieldInterface
    // Rules Copy
    rulesCopy: VoixWysiwygFieldInterface
    // End Page
    endContest: VoixCheckboxFieldConfigurationInterface
    endCopy: VoixWysiwygFieldInterface
    endImage: VoixMediaFieldInterface
    // Footer
    footerCopy: VoixWysiwygFieldInterface
  }
}>()
const { $csrfFetch } = useNuxtApp()
const url = useRequestURL()

defineSlice({
  name: {
    label: 'Barbie Contest',
    group: 'Barbie Contest',
    layouts: ['Barbie'],
  },
  tags: ['Barbie', 'Contest'],
  preview: 'SlicesBarbieSignup.jpg',
  description: 'Barbie Contest',
  fields: {
    // Header
    title: {
      label: 'Title',
      type: 'text',
    },
    // Start Page
    startCopy: {
      label: 'Copy',
      type: 'wysiwyg',
      group: 'Start Page',
    },
    startPrizeTitle: {
      label: 'Prize Title',
      type: 'text',
      group: 'Start Page',
    },
    startPrize1: {
      label: 'Prize 1',
      type: 'text',
      group: 'Start Page',
    },
    startPrize2: {
      label: 'Prize 2',
      type: 'text',
      group: 'Start Page',
    },
    startPrize3: {
      label: 'Prize 3',
      type: 'text',
      group: 'Start Page',
    },
    startImage: {
      label: 'Image',
      type: 'media',
      group: 'Start Page',
      breakpoints: {
        default: {
          width: 1200,
          height: 600,
        },
      },
    },
    // Form Page
    formTitle: {
      label: 'Title',
      type: 'text',
      group: 'Form Page',
    },
    formSubtitle: {
      label: 'Subtitle',
      type: 'text',
      group: 'Form Page',
    },
    // Upload Page
    uploadTitle: {
      label: 'Title',
      type: 'text',
      group: 'Upload Page',
    },
    uploadRequirements1: {
      label: 'Requirements 1',
      type: 'text',
      group: 'Upload Page',
    },
    uploadRequirements2: {
      label: 'Requirements 2',
      type: 'text',
      group: 'Upload Page',
    },
    uploadRequirements3: {
      label: 'Requirements 3',
      type: 'text',
      group: 'Upload Page',
    },
    uploadRequirements4: {
      label: 'Requirements 4',
      type: 'text',
      group: 'Upload Page',
    },
    // Complete Page
    completeCopy: {
      label: 'Copy',
      type: 'wysiwyg',
      group: 'Complete Page',
    },
    completeImage: {
      label: 'Image',
      type: 'media',
      group: 'Complete Page',
      breakpoints: {
        default: {
          width: 1200,
          height: 600,
        },
      },
    },
    // Eligible Page
    eligibleCopy: {
      label: 'Copy',
      type: 'wysiwyg',
      group: 'Eligible Page',
    },
    // Reenrty Page
    reentryCopy: {
      label: 'Copy',
      type: 'wysiwyg',
      group: 'Reentry Page',
    },
    // Prizes Page
    prizesCopy: {
      label: 'Copy',
      type: 'wysiwyg',
      group: 'Prizes Page',
    },
    // End Page
    endContest: {
      label: 'End Contest',
      type: 'checkbox',
      group: 'End Page',
      default: false,
    },
    endCopy: {
      label: 'Copy',
      type: 'wysiwyg',
      group: 'End Page',
    },
    endImage: {
      label: 'Image',
      type: 'media',
      group: 'End Page',
      breakpoints: {
        default: {
          width: 1200,
          height: 600,
        },
      },
    },
    // Rules Copy
    rulesCopy: {
      label: 'Rules Copy',
      type: 'wysiwyg',
    },
    // Footer
    footerCopy: {
      label: 'Footer Copy',
      type: 'wysiwyg',
    },
  },
  templates: [{
    label: 'Default',
    fields: {
      title: {
        value: 'Welcome to Barbie Atlantis',
      },
      startCopy: {
        value: 'Enter to win a trip to Atlantis Bahamas',
      },
      startImage: {
        value: 'https://images.unsplash.com/photo-1563475454428-db1b68095419?q=80&w=300&h=300&auto=format&fit=crop',
      },
    },
  }],
})

// State
const page = ref('start')
const previousPage = ref('start')
const showOfficialRules = ref(false)
const uploadStatus = ref('pending')
const formDefaults = {
  first_name: '',
  last_name: '',
  email: '',
  zipcode: '',
  phone: '',
  dob: '',
  optin_rules: false,
  optin_marketing: false,
  video_url: '',
}
const form = useStorage('form', Object.assign({}, formDefaults))
const dobDefaults = {
  year: '',
  month: '',
  day: '',
}
const dob = useStorage('dob', Object.assign({}, dobDefaults))
const errors: Ref<{ [key: string]: string }> = ref({})
const isErrors = computed(() => {
  return (Object.keys(errors.value).length > 0)
})

// Computed
const month = computed(() => dob.value.month)
const day = computed(() => dob.value.day)

// Methods
function setPage(value: string) {
  page.value = value
  if (value !== 'prizes')
    previousPage.value = value
  nextTick(() => scrollToTop())
}
function scrollToTop() {
  const element = document.getElementById('top')
  element?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  })
}
function validateFields() {
  errors.value = {}
  if (!form.value.first_name)
    errors.value.first_name = 'First Name is required'

  if (!form.value.last_name)
    errors.value.last_name = 'Last Name is required'

  if (!form.value.email.includes('@'))
    errors.value.email = 'Email is required'

  if (!(form.value.zipcode.length >= 5))
    errors.value.zipcode = 'Zipcode is required'

  if (!(form.value.phone.length >= 10))
    errors.value.phone = 'Phone Number is required'

  if (form.value.dob.length !== 10)
    errors.value.dob = 'Date of Birth is required'

  if (!form.value.optin_rules)
    errors.value.optin_rules = 'You must certify that you are at least 18 years of age and agree to the rules'

  if (!form.value.optin_marketing)
    errors.value.optin_marketing = 'You must agree to receive marketing communications'
}
function validateAge(dob: string) {
  const birthdate = new Date(dob)
  const today = new Date()
  let age = today.getFullYear() - birthdate.getFullYear()
  // Adjust age if the birthdate has not passed yet this year
  if (today.getMonth() < birthdate.getMonth() || (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate())) {
    age--
  }

  if (isNaN(birthdate) || dob.length !== 10)
    return false

  return age >= 18
}
function validateForm() {
  formatBirthdate()
  validateFields()

  if (Object.keys(errors.value).length > 0)
    return

  if (validateAge(form.value.dob))
    setPage('upload')
  else
    setPage('eligible')
}
function formatBirthdate() {
  const birthdate = {
    year: dob.value.year.trim(),
    month: dob.value.month.trim(),
    day: dob.value.day.trim(),
  }

  if (dob.value.day.trim().length === 1) {
    birthdate.day = `0${dob.value.day}`
  }

  if (dob.value.month.trim().length === 1) {
    birthdate.month = `0${dob.value.month}`
  }

  form.value.dob = `${birthdate.year}-${birthdate.month}-${birthdate.day}`
  form.value.dob.trim()
}
function submitForm() {
  const data = {
    first_name: form.value.first_name,
    last_name: form.value.last_name,
    email: form.value.email,
    zip: form.value.zipcode,
    phone: form.value.phone,
    dob: form.value.dob,
    optin_rules: form.value.optin_rules ? 'yes' : 'no',
    optin_marketing: form.value.optin_marketing ? 'yes' : 'no',
    video_url: form.value.video_url,
  }

  $csrfFetch('/api/barbie/registration', { method: 'post', body: data })
    .then((response) => {
      if (response?.status === 'redirect' && response?.redirect === 'already_entered') {
        setPage('rentry')
      }
      else {
        setPage('complete')
        form.value = Object.assign({}, formDefaults)
      }
    })
    .catch((e) => {
      const { errors } = e.response.data
      console.error(errors)
    })
}
function onUploadComplete(response: any) {
  uploadStatus.value = 'complete'
  form.value.video_url = response.data

  if (response.error) {
    uploadStatus.value = 'error'
    console.error('Video Upload Error: ', response.error)
  }
  else {
    setTimeout(() => submitForm(), 500)
  }
}
function onUploadProgress() {
  uploadStatus.value = 'in-progress'
}
function openPage(url: string) {
  window.open(url, '_blank')
}
function focusNextElement() {
  const focusedElement = document.activeElement
  const focusableElements = Array.from(document.querySelectorAll('input'))
  const currentIndex = focusedElement ? focusableElements.indexOf(focusedElement as HTMLInputElement) : -1
  const nextIndex = (currentIndex + 1) % focusableElements.length
  focusableElements[nextIndex].focus()
}

// Watcher
watch(month, (newValue, oldValue) => {
  if (oldValue.length === 1 && newValue.length === 2)
    focusNextElement()
})
watch(day, (newValue, oldValue) => {
  if (oldValue.length === 1 && newValue.length === 2)
    focusNextElement()
})

// Lifecycle
onMounted(() => {
  if (props.fields.endContest.value)
    page.value = 'end'
  else
    page.value = 'start'
})
</script>

<template>
  <div class="flex flex-col justify-center items-center bg-barbie-500 font-barbie px-8">
    <!-- Title -->
    <div class="container mx-auto pt-12 pb-6 text-center px-8">
      <div class="text-5xl md:text-[72px] font-barbie-headline text-white leading-tight uppercase">
        {{ fields.title.value }}
      </div>
      <div class="mt-1md:-mt-6 text-[35px] leading-8 text-white">
        Where Atlantis <span class="font-barbie-display text-[48px]">dreams</span> come true
      </div>
    </div>

    <!-- Top Anchor -->
    <div id="top" />

    <!-- Start -->
    <section v-show="page === 'start'" class="flex flex-col justify-center items-center">
      <!-- Copy -->
      <VoixWysiwyg class="my-6 text-white w-full lg:w-3/5 mx-auto" :field="fields.startCopy" />

      <!-- Prizes -->
      <div class="flex flex-col flex-wrap justify-start lg:justify-center items-center lg:space-x-8 text-white">
        <p class="flex p-2 font-bold leading-none">
          {{ fields.startPrizeTitle.value }}
        </p>

        <p class="flex p-2 leading-none">
          <svg class="flex-none w-4 mr-2" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
            <path
              style="fill: #FFFF00;" d="M438.6,41.5l90.2,182.8c6.3,12.7,18.4,21.5,32.4,23.6l201.8,29.3c35.3,5.1,49.4,48.6,23.9,73.5L641,493
            c-10.1,9.9-14.8,24.2-12.4,38.1l34.5,201c6,35.2-30.9,62-62.5,45.4l-180.5-94.9c-12.5-6.6-27.6-6.6-40.1,0l-180.5,94.9
            c-31.6,16.6-68.6-10.2-62.5-45.4l34.5-201c2.4-14-2.2-28.2-12.4-38.1l-146-142.3c-25.6-24.9-11.5-68.4,23.9-73.5l201.8-29.3
            c14-2,26.2-10.8,32.4-23.6l90.2-182.8C377.2,9.5,422.8,9.5,438.6,41.5z"
            />
          </svg>
          <span>
            {{ fields.startPrize1.value }}
          </span>
        </p>

        <p class="flex p-2 leading-none">
          <svg class="flex-none w-4 mr-2" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
            <path
              style="fill: #FFFF00;" d="M438.6,41.5l90.2,182.8c6.3,12.7,18.4,21.5,32.4,23.6l201.8,29.3c35.3,5.1,49.4,48.6,23.9,73.5L641,493
            c-10.1,9.9-14.8,24.2-12.4,38.1l34.5,201c6,35.2-30.9,62-62.5,45.4l-180.5-94.9c-12.5-6.6-27.6-6.6-40.1,0l-180.5,94.9
            c-31.6,16.6-68.6-10.2-62.5-45.4l34.5-201c2.4-14-2.2-28.2-12.4-38.1l-146-142.3c-25.6-24.9-11.5-68.4,23.9-73.5l201.8-29.3
            c14-2,26.2-10.8,32.4-23.6l90.2-182.8C377.2,9.5,422.8,9.5,438.6,41.5z"
            />
          </svg>
          {{ fields.startPrize2.value }}
        </p>

        <p class="flex p-2 leading-none">
          <svg class="flex-none w-4 mr-2" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
            <path
              style="fill: #FFFF00;" d="M438.6,41.5l90.2,182.8c6.3,12.7,18.4,21.5,32.4,23.6l201.8,29.3c35.3,5.1,49.4,48.6,23.9,73.5L641,493
            c-10.1,9.9-14.8,24.2-12.4,38.1l34.5,201c6,35.2-30.9,62-62.5,45.4l-180.5-94.9c-12.5-6.6-27.6-6.6-40.1,0l-180.5,94.9
            c-31.6,16.6-68.6-10.2-62.5-45.4l34.5-201c2.4-14-2.2-28.2-12.4-38.1l-146-142.3c-25.6-24.9-11.5-68.4,23.9-73.5l201.8-29.3
            c14-2,26.2-10.8,32.4-23.6l90.2-182.8C377.2,9.5,422.8,9.5,438.6,41.5z"
            />
          </svg>
          {{ fields.startPrize3.value }}
        </p>
      </div>

      <!-- Button -->
      <button
        class="w-full md:w-[300px] border border-white text-white font-barbie-headline text-[32px] md:text-[40px] uppercase py-2 md:py-4 px-12 mt-8 mb-10"
        @click="setPage('form')"
      >
        Enter Contest
      </button>

      <!-- Image -->
      <VoixMedia :field="fields.startImage" class="w-full h-full" />
    </section>

    <!-- Form -->
    <section v-show="page === 'form'" class="flex flex-col justify-center items-center md:px-32">
      <!-- Title -->
      <div class="mt-6 mb-2 font-barbie-headline text-center text-white text-[48px] leading-tight uppercase">
        {{ fields.formTitle.value }}
      </div>

      <!-- Subtitle -->
      <p class="mb-6 text-center text-white font-bold leading-none">
        {{ fields.formSubtitle.value }}
      </p>

      <!-- Fields -->
      <form class="w-full space-y-6 lg:space-y-16 p-4">
        <!-- First Name & Last Name -->
        <div class="flex flex-row flex-wrap lg:flex-nowrap justify-between space-x-0 lg:space-x-12 space-y-6 lg:space-y-0">
          <div class="w-full">
            <label class="sr-only">First Name</label>
            <input
              v-model="form.first_name" type="text" placeholder="FIRST NAME"
              class="pb-1 border-b-2 border-white rounded-none placeholder-white/80 text-white bg-transparent w-full text-lg outline-none"
            >
          </div>

          <div class="w-full">
            <label class="sr-only">Last Name</label>
            <input
              v-model="form.last_name" type="text" placeholder="LAST NAME"
              class="pb-1 border-b-2 border-white rounded-none placeholder-white/80 text-white bg-transparent w-full text-lg outline-none"
            >
          </div>
        </div>

        <!-- Email & Zip Code -->
        <div class="flex flex-row flex-wrap lg:flex-nowrap justify-between space-x-0 lg:space-x-12 space-y-6 lg:space-y-0">
          <div class="w-full">
            <label class="sr-only">Email</label>
            <input
              v-model="form.email" type="email" placeholder="EMAIL"
              class="pb-1 border-b-2 border-white rounded-none placeholder-white/80 text-white bg-transparent w-full text-lg outline-none"
            >
          </div>

          <div class="w-full">
            <label class="sr-only">Zipcode</label>
            <input
              v-model="form.zipcode" type="text" placeholder="ZIP CODE"
              class="pb-1 border-b-2 border-white rounded-none placeholder-white/80 text-white bg-transparent w-full text-lg outline-none"
            >
          </div>
        </div>

        <!-- Phone & Date of Birth -->
        <div class="flex flex-row flex-wrap lg:flex-nowrap justify-between space-x-0 lg:space-x-12 space-y-6 lg:space-y-0">
          <div class="w-full">
            <label class="sr-only">Phone</label>
            <input
              v-model="form.phone" type="tel" placeholder="PHONE NUMBER"
              class="pb-1 border-b-2 border-white rounded-none placeholder-white/80 text-white bg-transparent w-full text-lg outline-none"
            >
          </div>

          <div class="flex w-full relative">
            <label class="absolute top-10 left-0 text-white">Date of Birth</label>
            <input
              v-model="dob.month" type="text" maxlength="2" placeholder="MM"
              class="pb-1 border-b-2 border-white rounded-none placeholder-white/80 text-white bg-transparent w-full text-lg outline-none"
            >
            <span class="text-white text-2xl mx-3 leading-snug">/</span>
            <input
              v-model="dob.day" type="text" maxlength="2" placeholder="DD"
              class="pb-1 border-b-2 border-white rounded-none placeholder-white/80 text-white bg-transparent w-full text-lg outline-none"
            >
            <span class="text-white text-2xl mx-3 leading-snug">/</span>
            <input
              v-model="dob.year" type="text" maxlength="4" placeholder="YYYY"
              class="pb-1 border-b-2 border-white rounded-none placeholder-white/80 text-white bg-transparent w-full text-lg outline-none"
            >
          </div>
        </div>
      </form>

      <!-- Error -->
      <ul v-if="isErrors" class="mt-8 px-8 py-4 bg-white text-black rounded list-disc">
        <li v-for="(error, key) in errors" :key="key">
          {{ error }}
        </li>
      </ul>

      <!-- Terms -->
      <div class="flex flex-col justify-center items-center mt-8 mb-12">
        <!-- Terms 1 -->
        <div class="flex items-center w-full p-2">
          <input v-model="form.optin_rules" type="checkbox" class="mx-4">
          <p class="text-white text-sm md:text-base">
            I certify that I am a legal resident of the 48 contiguous US or DC, and a parent/legal guardian of at least 1 child under 18, and have read and agree to the
            <span
              class="font-bold border-[#fff200] border-b-2 cursor-pointer"
              @click="showOfficialRules = true"
            >
              Official Rules
            </span>.
          </p>
        </div>

        <!-- Terms 2 -->
        <div class="flex items-center w-full p-2">
          <input v-model="form.optin_marketing" type="checkbox" class="mx-4">
          <p class="text-white text-sm md:text-base">
            I have read the
            <span
              class="font-bold border-[#fff200] border-b-2 cursor-pointer"
              @click="openPage('/privacy')"
            >
              Privacy Policy
            </span>.
            By submitting this form, I confirm I would like to receive emails on promotions, special offers and updates from Atlantis Paradise Island and it’s affiliated brands.
          </p>
        </div>
      </div>

      <!-- Submit -->
      <button
        class="w-full md:w-[300px] border border-white text-white font-barbie-headline text-[32px] md:text-[40px] uppercase py-2 md:py-4 px-12"
        @click="validateForm()"
      >
        Continue
      </button>
    </section>

    <!-- Upload -->
    <section v-show="page === 'upload'" class="flex flex-col justify-center items-center w-full">
      <!-- Title -->
      <div class="my-6 font-barbie-headline text-center text-white text-[48px] leading-tight uppercase">
        {{ fields.uploadTitle.value }}
      </div>

      <!-- Requirements -->
      <div class="flex flex-row flex-wrap justify-start lg:justify-center items-center lg:space-x-8 font-barbie-headline text-white text-24 lg:text-32 leading-tight uppercase">
        <p class="p-2 flex">
          <svg class="w-6 lg:w-8 mr-3" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
            <path
              style="fill: #FFFF00;" d="M438.6,41.5l90.2,182.8c6.3,12.7,18.4,21.5,32.4,23.6l201.8,29.3c35.3,5.1,49.4,48.6,23.9,73.5L641,493
            c-10.1,9.9-14.8,24.2-12.4,38.1l34.5,201c6,35.2-30.9,62-62.5,45.4l-180.5-94.9c-12.5-6.6-27.6-6.6-40.1,0l-180.5,94.9
            c-31.6,16.6-68.6-10.2-62.5-45.4l34.5-201c2.4-14-2.2-28.2-12.4-38.1l-146-142.3c-25.6-24.9-11.5-68.4,23.9-73.5l201.8-29.3
            c14-2,26.2-10.8,32.4-23.6l90.2-182.8C377.2,9.5,422.8,9.5,438.6,41.5z"
            />
          </svg>
          {{ fields.uploadRequirements1.value }}
        </p>
        <p class="p-2 flex">
          <svg class="w-6 lg:w-8 mr-3" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
            <path
              style="fill: #FFFF00;" d="M438.6,41.5l90.2,182.8c6.3,12.7,18.4,21.5,32.4,23.6l201.8,29.3c35.3,5.1,49.4,48.6,23.9,73.5L641,493
            c-10.1,9.9-14.8,24.2-12.4,38.1l34.5,201c6,35.2-30.9,62-62.5,45.4l-180.5-94.9c-12.5-6.6-27.6-6.6-40.1,0l-180.5,94.9
            c-31.6,16.6-68.6-10.2-62.5-45.4l34.5-201c2.4-14-2.2-28.2-12.4-38.1l-146-142.3c-25.6-24.9-11.5-68.4,23.9-73.5l201.8-29.3
            c14-2,26.2-10.8,32.4-23.6l90.2-182.8C377.2,9.5,422.8,9.5,438.6,41.5z"
            />
          </svg>
          {{ fields.uploadRequirements2.value }}
        </p>
        <p class="p-2 flex">
          <svg class="w-6 lg:w-8 mr-3" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
            <path
              style="fill: #FFFF00;" d="M438.6,41.5l90.2,182.8c6.3,12.7,18.4,21.5,32.4,23.6l201.8,29.3c35.3,5.1,49.4,48.6,23.9,73.5L641,493
            c-10.1,9.9-14.8,24.2-12.4,38.1l34.5,201c6,35.2-30.9,62-62.5,45.4l-180.5-94.9c-12.5-6.6-27.6-6.6-40.1,0l-180.5,94.9
            c-31.6,16.6-68.6-10.2-62.5-45.4l34.5-201c2.4-14-2.2-28.2-12.4-38.1l-146-142.3c-25.6-24.9-11.5-68.4,23.9-73.5l201.8-29.3
            c14-2,26.2-10.8,32.4-23.6l90.2-182.8C377.2,9.5,422.8,9.5,438.6,41.5z"
            />
          </svg>
          {{ fields.uploadRequirements3.value }}
        </p>
        <p class="p-2 flex">
          <svg class="w-6 lg:w-8 mr-3" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
            <path
              style="fill: #FFFF00;" d="M438.6,41.5l90.2,182.8c6.3,12.7,18.4,21.5,32.4,23.6l201.8,29.3c35.3,5.1,49.4,48.6,23.9,73.5L641,493
            c-10.1,9.9-14.8,24.2-12.4,38.1l34.5,201c6,35.2-30.9,62-62.5,45.4l-180.5-94.9c-12.5-6.6-27.6-6.6-40.1,0l-180.5,94.9
            c-31.6,16.6-68.6-10.2-62.5-45.4l34.5-201c2.4-14-2.2-28.2-12.4-38.1l-146-142.3c-25.6-24.9-11.5-68.4,23.9-73.5l201.8-29.3
            c14-2,26.2-10.8,32.4-23.6l90.2-182.8C377.2,9.5,422.8,9.5,438.6,41.5z"
            />
          </svg>
          {{ fields.uploadRequirements4.value }}
        </p>
      </div>

      <!-- Uploader -->
      <div
        v-show="uploadStatus !== 'complete'"
        id="uploader"
        class="flex flex-col justify-center items-center h-full lg:h-[400px] w-full lg:w-[632px] mt-8 bg-[#fdfdfd70] border-[12px] border-white p-8 cursor-pointer"
      >
        <!-- Status: Pending or In-Progress -->
        <p
          v-show="uploadStatus !== 'error'"
          class="font-barbie-headline text-center text-white text-32 lg:text-5xl leading-tight uppercase"
        >
          {{ uploadStatus === 'pending' ? 'Upload Video' : 'Uploading Video...' }}
        </p>

        <!-- Status: Error -->
        <p
          v-show="uploadStatus === 'error'"
          class="font-barbie-headline text-center text-white text-32 lg:text-5xl leading-tight uppercase"
        >
          Error Uploading!
        </p>

        <!-- Uploader -->
        <BarbieVideoUploader :multiple="false" class="w-full" @file-upload-complete="onUploadComplete" @file-upload-progress="onUploadProgress" />

        <!-- CTA: Pending -->
        <p
          v-show="uploadStatus === 'pending'"
          class="font-barbie-headline text-center text-white text-24 lg:text-3xl leading-tight uppercase"
        >
          Click Here To Select Video <br> Or Drag And Drop
        </p>

        <!-- CTA: Error -->
        <p
          v-show="uploadStatus === 'error'"
          class="font-barbie-headline text-center text-white text-24 lg:text-3xl leading-tight uppercase"
        >
          Please Review Requirements And Try Again
        </p>
      </div>

      <!-- Complete -->
      <div
        v-show="uploadStatus === 'complete'"
        class="flex flex-col justify-center items-center h-full lg:h-[400px] w-full lg:w-[632px] mt-8 bg-[#00000070] border-[12px] border-white p-8"
        @click="setPage('complete')"
      >
        <p class="font-barbie-headline text-center text-white text-32 lg:text-5xl  leading-tight uppercase">
          Upload Complete
        </p>
      </div>
    </section>

    <!-- Complete -->
    <section v-show="page === 'complete'" class="flex flex-col justify-center items-center">
      <!-- Copy -->
      <VoixWysiwyg class="my-6 font-barbie-headline text-center text-white text-5xl leading-tight" :field="fields.completeCopy" />

      <!-- Social Share -->
      <SocialShare
        network="facebook"
        title="I just entered the Barbie Family Spring Break Getaway Contest!"
        class="w-fit text-white border-[1px] p-3 mb-8"
        :url="url"
        :style="true"
        :label="true"
      >
        <template #label>
          Share With Friends
        </template>
      </SocialShare>

      <!-- Image -->
      <VoixMedia :field="fields.completeImage" class="w-full h-full" />
    </section>

    <!-- Eligible -->
    <section v-show="page === 'eligible'" class="flex flex-col justify-center items-center mt-2">
      <!-- Copy -->
      <VoixWysiwyg class="my-6 font-barbie-headline text-center text-white text-5xl leading-tight" :field="fields.eligibleCopy" />
    </section>

    <!-- Rentry -->
    <section v-show="page === 'rentry'" class="flex flex-col justify-center items-center mt-2">
      <!-- Copy -->
      <VoixWysiwyg class="my-6 font-barbie-headline text-center text-white text-5xl leading-tight" :field="fields.reentryCopy" />
    </section>

    <!-- Official Rules -->
    <section class="flex justify-center">
      <Teleport to="#portal-modal">
        <!-- Official Rules -->
        <div v-if="showOfficialRules" class="fixed z-40 inset-0">
          <div class="absolute z-30 inset-0 bg-black/75" @click="showOfficialRules = false" />
          <div class="absolute z-40 inset-0 flex justify-center items-center pointer-events-none">
            <div class="relative bg-white w-full w-[80vw] lg:w-[65vw] mx-8 p-8 pointer-events-auto border-t-8 border-red-500">
              <button class="absolute top-[-8px] right-0 z-10 bg-[#eb897c] rounded-b-lg rounded-br-none" @click="showOfficialRules = false">
                <Icon class="w-8 h-8 text-white" name="heroicons:x-mark-20-solid" />
              </button>
              <VoixWysiwyg class="w-full h-[75vh] overflow-auto font-medium text-black" :field="fields.rulesCopy" />
            </div>
          </div>
        </div>
      </Teleport>
    </section>

    <!-- Prizes -->
    <section v-show="page === 'prizes'" class="flex flex-col justify-center items-center w-full">
      <!-- Copy -->
      <VoixWysiwyg class="my-6 font-medium text-white w-3/4 mb-12" :field="fields.prizesCopy" />

      <!-- Button -->
      <button
        class="w-full md:w-[300px] border border-white text-white font-barbie-headline text-[32px] md:text-[40px] uppercase py-2 md:py-4"
        @click="setPage(previousPage)"
      >
        Back
      </button>
    </section>

    <!-- End -->
    <section v-if="page === 'end'" class="flex flex-col justify-center items-center mt-2">
      <!-- Copy -->
      <VoixWysiwyg class="my-6 font-barbie-headline text-center text-white text-5xl leading-tight" :field="fields.endCopy" />

      <!-- Image -->
      <VoixMedia :field="fields.endImage" class="w-full h-full" />
    </section>

    <!-- Navigation -->
    <div class="flex flex-col lg:flex-row justify-center items-center lg:space-x-24 space-y-6 lg:space-y-0 mt-6 lg:mt-20 mb-6">
      <!-- Official Rules: Desktop -->
      <button
        class="hidden lg:flex w-fit text-white font-barbie-headline text-[40px] uppercase leading-none"
        @click="showOfficialRules = true"
      >
        <svg class="w-10 lg:w-14 relative bottom-4 right-1" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
          <path
            style="fill: #FEFF01;" d="M498.6,145.9c-12.9,0-31.1-0.1-49.4,0.1c-1.6,0-3.2,1.3-4.8,2.1c1,1.6,1.6,3.8,3.1,4.6
            c30.9,17.7,62.7,34.7,83.5,65.2c14.3,20.8,23.1,44.5,30.7,68.5c0.6,1.8-0.9,4.2-1.5,6.3c-1.5-1.1-3.6-1.9-4.5-3.3
            c-30.3-46.4-71.7-75.5-127.3-83c-3.4-0.5-6.4-4.2-9.8-4.7c-33.2-4.2-18.9,20.8-17,32.4c27.1,168.5,78.3,330.1,142.9,487.7
            c4.6,11.1,10,21.9,16.1,32.3c5.8,9.8-1.5,12.5-7.5,14.8c-16.4,6.1-33.2,11.3-49.8,17c-7.5,2.6-11.9-0.7-14.2-7.5
            c-17.4-52.8-35.9-105.3-51.7-158.6c-14.2-47.9-25.6-96.7-37.9-145.2c-6.8-26.7-13.4-53.6-19-80.6c-5.7-27.4-9.2-55.3-15.2-82.6
            c-6.3-28.8-12.6-57.4-12.6-87c0-10.4-6.5-8.8-12.3-6.3c-4,1.7-7,5.3-10.6,8c-17.7,12.9-31.5,29.6-44.5,46.8
            c-6.5,8.6-11.1,18.8-15.7,28.6c-11.4,24-17.1,49.1-13.2,75.8c0.5,3.1,1.6,6.1,2.6,9.1c1.8,5.6,5.6,13.2,0.4,16.1
            c-3.6,2-7.6-6.2-10.1-11c-16.3-31.7-33-64.1-27.5-101c5.5-37.3,18.9-72.2,44.9-101.1c1.8-2,3.7-6.5,2.8-7.7
            c-3.4-4.3-8.2-1.4-11.6,0.4c-20.2,10.6-40.9,20.5-60,32.8c-15.3,9.8-26.3,24.4-35.8,40.5c-7.2,12-11.2,24.1-13,37.5
            c-0.1,0.8-1.6,2.2-1.9,2c-1.1-0.6-2.2-1.4-2.8-2.4c-10.8-18.6-6.6-36.9-2.9-57.2c7.4-40.6,32.3-66.4,63.8-87.9
            c21.1-14.4,44.4-24.4,69.2-30.8c1.2-0.3,2.1-1.7,3.2-2.5c-1.7-1.6-3.2-3.9-5.2-4.6c-23.9-8.7-45,3.4-66.8,9.7
            c-0.7,0.2-1.3,0.6-2,0.9c-8.4,3.9-20.3,11.3-25.1,2.1c-4.8-9,9.9-12.2,16.3-17c39.4-29.4,84.4-50.9,132.2-35.5
            c38.6,12.4,49.1-20.6,72.7-31c57.7-25.3,112.7-36,165.6,9.1c5.3,4.6,10.8,9.2,15,14.8c2.3,3-0.9,4-3.8,2.7c-0.9-0.4-2-0.6-2.9-1
            c-25.5-13.2-52-16.6-79.1-6.5c-17.7,6.6-34.6,15.2-51.8,23.1c-1.3,0.6-2,2.5-3,3.9c3.1,1.5,6.3,4.5,9.4,4.4
            c24.8-0.2,49.6-3.3,74.2,2.8c37.6,9.3,65.8,31.7,87,63.6c4.7,7.1,9.3,14.2,13.2,21.7c1.3,2.6,1.5,8.6,0.2,9.3
            c-5.9,3-8.2-2.5-11.8-6C581.7,150.7,544.8,141.4,498.6,145.9z"
          />
        </svg>
        Official Rules
      </button>

      <!-- Prizes -->
      <button
        class="flex w-fit relative bottom-0 lg:bottom-6 text-white font-barbie-headline text-[48px] lg:text-[56px] uppercase leading-none"
        @click="setPage('prizes')"
      >
        <svg class="w-14 lg:w-16 relative bottom-0 lg:bottom-1 right-1" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
          <path
            style="fill: #FEFF01;" d="M498.6,145.9c-12.9,0-31.1-0.1-49.4,0.1c-1.6,0-3.2,1.3-4.8,2.1c1,1.6,1.6,3.8,3.1,4.6
            c30.9,17.7,62.7,34.7,83.5,65.2c14.3,20.8,23.1,44.5,30.7,68.5c0.6,1.8-0.9,4.2-1.5,6.3c-1.5-1.1-3.6-1.9-4.5-3.3
            c-30.3-46.4-71.7-75.5-127.3-83c-3.4-0.5-6.4-4.2-9.8-4.7c-33.2-4.2-18.9,20.8-17,32.4c27.1,168.5,78.3,330.1,142.9,487.7
            c4.6,11.1,10,21.9,16.1,32.3c5.8,9.8-1.5,12.5-7.5,14.8c-16.4,6.1-33.2,11.3-49.8,17c-7.5,2.6-11.9-0.7-14.2-7.5
            c-17.4-52.8-35.9-105.3-51.7-158.6c-14.2-47.9-25.6-96.7-37.9-145.2c-6.8-26.7-13.4-53.6-19-80.6c-5.7-27.4-9.2-55.3-15.2-82.6
            c-6.3-28.8-12.6-57.4-12.6-87c0-10.4-6.5-8.8-12.3-6.3c-4,1.7-7,5.3-10.6,8c-17.7,12.9-31.5,29.6-44.5,46.8
            c-6.5,8.6-11.1,18.8-15.7,28.6c-11.4,24-17.1,49.1-13.2,75.8c0.5,3.1,1.6,6.1,2.6,9.1c1.8,5.6,5.6,13.2,0.4,16.1
            c-3.6,2-7.6-6.2-10.1-11c-16.3-31.7-33-64.1-27.5-101c5.5-37.3,18.9-72.2,44.9-101.1c1.8-2,3.7-6.5,2.8-7.7
            c-3.4-4.3-8.2-1.4-11.6,0.4c-20.2,10.6-40.9,20.5-60,32.8c-15.3,9.8-26.3,24.4-35.8,40.5c-7.2,12-11.2,24.1-13,37.5
            c-0.1,0.8-1.6,2.2-1.9,2c-1.1-0.6-2.2-1.4-2.8-2.4c-10.8-18.6-6.6-36.9-2.9-57.2c7.4-40.6,32.3-66.4,63.8-87.9
            c21.1-14.4,44.4-24.4,69.2-30.8c1.2-0.3,2.1-1.7,3.2-2.5c-1.7-1.6-3.2-3.9-5.2-4.6c-23.9-8.7-45,3.4-66.8,9.7
            c-0.7,0.2-1.3,0.6-2,0.9c-8.4,3.9-20.3,11.3-25.1,2.1c-4.8-9,9.9-12.2,16.3-17c39.4-29.4,84.4-50.9,132.2-35.5
            c38.6,12.4,49.1-20.6,72.7-31c57.7-25.3,112.7-36,165.6,9.1c5.3,4.6,10.8,9.2,15,14.8c2.3,3-0.9,4-3.8,2.7c-0.9-0.4-2-0.6-2.9-1
            c-25.5-13.2-52-16.6-79.1-6.5c-17.7,6.6-34.6,15.2-51.8,23.1c-1.3,0.6-2,2.5-3,3.9c3.1,1.5,6.3,4.5,9.4,4.4
            c24.8-0.2,49.6-3.3,74.2,2.8c37.6,9.3,65.8,31.7,87,63.6c4.7,7.1,9.3,14.2,13.2,21.7c1.3,2.6,1.5,8.6,0.2,9.3
            c-5.9,3-8.2-2.5-11.8-6C581.7,150.7,544.8,141.4,498.6,145.9z"
          />
        </svg>

        <span class="border-[#fff200] border-b-8">
          Official Prizes!
        </span>
      </button>

      <!-- Official Rules: Mobile -->
      <button
        class="flex lg:hidden w-fit text-white font-barbie-headline text-[40px] uppercase leading-none"
        @click="showOfficialRules = true"
      >
        <svg class="w-10 lg:w-14 relative bottom-1 right-1" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
          <path
            style="fill: #FEFF01;" d="M498.6,145.9c-12.9,0-31.1-0.1-49.4,0.1c-1.6,0-3.2,1.3-4.8,2.1c1,1.6,1.6,3.8,3.1,4.6
            c30.9,17.7,62.7,34.7,83.5,65.2c14.3,20.8,23.1,44.5,30.7,68.5c0.6,1.8-0.9,4.2-1.5,6.3c-1.5-1.1-3.6-1.9-4.5-3.3
            c-30.3-46.4-71.7-75.5-127.3-83c-3.4-0.5-6.4-4.2-9.8-4.7c-33.2-4.2-18.9,20.8-17,32.4c27.1,168.5,78.3,330.1,142.9,487.7
            c4.6,11.1,10,21.9,16.1,32.3c5.8,9.8-1.5,12.5-7.5,14.8c-16.4,6.1-33.2,11.3-49.8,17c-7.5,2.6-11.9-0.7-14.2-7.5
            c-17.4-52.8-35.9-105.3-51.7-158.6c-14.2-47.9-25.6-96.7-37.9-145.2c-6.8-26.7-13.4-53.6-19-80.6c-5.7-27.4-9.2-55.3-15.2-82.6
            c-6.3-28.8-12.6-57.4-12.6-87c0-10.4-6.5-8.8-12.3-6.3c-4,1.7-7,5.3-10.6,8c-17.7,12.9-31.5,29.6-44.5,46.8
            c-6.5,8.6-11.1,18.8-15.7,28.6c-11.4,24-17.1,49.1-13.2,75.8c0.5,3.1,1.6,6.1,2.6,9.1c1.8,5.6,5.6,13.2,0.4,16.1
            c-3.6,2-7.6-6.2-10.1-11c-16.3-31.7-33-64.1-27.5-101c5.5-37.3,18.9-72.2,44.9-101.1c1.8-2,3.7-6.5,2.8-7.7
            c-3.4-4.3-8.2-1.4-11.6,0.4c-20.2,10.6-40.9,20.5-60,32.8c-15.3,9.8-26.3,24.4-35.8,40.5c-7.2,12-11.2,24.1-13,37.5
            c-0.1,0.8-1.6,2.2-1.9,2c-1.1-0.6-2.2-1.4-2.8-2.4c-10.8-18.6-6.6-36.9-2.9-57.2c7.4-40.6,32.3-66.4,63.8-87.9
            c21.1-14.4,44.4-24.4,69.2-30.8c1.2-0.3,2.1-1.7,3.2-2.5c-1.7-1.6-3.2-3.9-5.2-4.6c-23.9-8.7-45,3.4-66.8,9.7
            c-0.7,0.2-1.3,0.6-2,0.9c-8.4,3.9-20.3,11.3-25.1,2.1c-4.8-9,9.9-12.2,16.3-17c39.4-29.4,84.4-50.9,132.2-35.5
            c38.6,12.4,49.1-20.6,72.7-31c57.7-25.3,112.7-36,165.6,9.1c5.3,4.6,10.8,9.2,15,14.8c2.3,3-0.9,4-3.8,2.7c-0.9-0.4-2-0.6-2.9-1
            c-25.5-13.2-52-16.6-79.1-6.5c-17.7,6.6-34.6,15.2-51.8,23.1c-1.3,0.6-2,2.5-3,3.9c3.1,1.5,6.3,4.5,9.4,4.4
            c24.8-0.2,49.6-3.3,74.2,2.8c37.6,9.3,65.8,31.7,87,63.6c4.7,7.1,9.3,14.2,13.2,21.7c1.3,2.6,1.5,8.6,0.2,9.3
            c-5.9,3-8.2-2.5-11.8-6C581.7,150.7,544.8,141.4,498.6,145.9z"
          />
        </svg>
        Official Rules
      </button>

      <!-- Privacy Policy -->
      <button
        class="flex w-fit text-white font-barbie-headline text-[40px] uppercase leading-none"
        @click="openPage('/privacy')"
      >
        <svg class="w-10 lg:w-14 relative bottom-1 lg:bottom-4 right-1" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
          <path
            style="fill: #FEFF01;" d="M498.6,145.9c-12.9,0-31.1-0.1-49.4,0.1c-1.6,0-3.2,1.3-4.8,2.1c1,1.6,1.6,3.8,3.1,4.6
            c30.9,17.7,62.7,34.7,83.5,65.2c14.3,20.8,23.1,44.5,30.7,68.5c0.6,1.8-0.9,4.2-1.5,6.3c-1.5-1.1-3.6-1.9-4.5-3.3
            c-30.3-46.4-71.7-75.5-127.3-83c-3.4-0.5-6.4-4.2-9.8-4.7c-33.2-4.2-18.9,20.8-17,32.4c27.1,168.5,78.3,330.1,142.9,487.7
            c4.6,11.1,10,21.9,16.1,32.3c5.8,9.8-1.5,12.5-7.5,14.8c-16.4,6.1-33.2,11.3-49.8,17c-7.5,2.6-11.9-0.7-14.2-7.5
            c-17.4-52.8-35.9-105.3-51.7-158.6c-14.2-47.9-25.6-96.7-37.9-145.2c-6.8-26.7-13.4-53.6-19-80.6c-5.7-27.4-9.2-55.3-15.2-82.6
            c-6.3-28.8-12.6-57.4-12.6-87c0-10.4-6.5-8.8-12.3-6.3c-4,1.7-7,5.3-10.6,8c-17.7,12.9-31.5,29.6-44.5,46.8
            c-6.5,8.6-11.1,18.8-15.7,28.6c-11.4,24-17.1,49.1-13.2,75.8c0.5,3.1,1.6,6.1,2.6,9.1c1.8,5.6,5.6,13.2,0.4,16.1
            c-3.6,2-7.6-6.2-10.1-11c-16.3-31.7-33-64.1-27.5-101c5.5-37.3,18.9-72.2,44.9-101.1c1.8-2,3.7-6.5,2.8-7.7
            c-3.4-4.3-8.2-1.4-11.6,0.4c-20.2,10.6-40.9,20.5-60,32.8c-15.3,9.8-26.3,24.4-35.8,40.5c-7.2,12-11.2,24.1-13,37.5
            c-0.1,0.8-1.6,2.2-1.9,2c-1.1-0.6-2.2-1.4-2.8-2.4c-10.8-18.6-6.6-36.9-2.9-57.2c7.4-40.6,32.3-66.4,63.8-87.9
            c21.1-14.4,44.4-24.4,69.2-30.8c1.2-0.3,2.1-1.7,3.2-2.5c-1.7-1.6-3.2-3.9-5.2-4.6c-23.9-8.7-45,3.4-66.8,9.7
            c-0.7,0.2-1.3,0.6-2,0.9c-8.4,3.9-20.3,11.3-25.1,2.1c-4.8-9,9.9-12.2,16.3-17c39.4-29.4,84.4-50.9,132.2-35.5
            c38.6,12.4,49.1-20.6,72.7-31c57.7-25.3,112.7-36,165.6,9.1c5.3,4.6,10.8,9.2,15,14.8c2.3,3-0.9,4-3.8,2.7c-0.9-0.4-2-0.6-2.9-1
            c-25.5-13.2-52-16.6-79.1-6.5c-17.7,6.6-34.6,15.2-51.8,23.1c-1.3,0.6-2,2.5-3,3.9c3.1,1.5,6.3,4.5,9.4,4.4
            c24.8-0.2,49.6-3.3,74.2,2.8c37.6,9.3,65.8,31.7,87,63.6c4.7,7.1,9.3,14.2,13.2,21.7c1.3,2.6,1.5,8.6,0.2,9.3
            c-5.9,3-8.2-2.5-11.8-6C581.7,150.7,544.8,141.4,498.6,145.9z"
          />
        </svg>
        Privacy Policy
      </button>
    </div>

    <!-- Footer -->
    <VoixWysiwyg class="my-8 font-medium text-white text-sm md:text-base" :field="fields.footerCopy" />
  </div>
</template>
