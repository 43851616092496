<script>
export default {
  name: 'IconDining',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 100"
  >
    <path
      transform="translate(-25,-25)"
      fill="currentColor"
      d="M105,49.3H75.3h-1.2H60.7h-1.2H45L59.9,75L45,100.7h29.7h1.2h13.4h1.2H105L90.1,75L105,49.3z M75.9,50.3h27.4
	L89.5,74l-6.7-11.6l0,0l-4.2-7.3L75.9,50.3z M89,75l-6.7,11.6L75.6,75l6.7-11.6L89,75z M61.3,50.3h13.4l5.3,9.2l1.7,2.9L75,74
	l-6.7-11.6l0,0l-2.4-4.1L61.3,50.3z M74.4,75l-6.7,11.6L61,75l6.7-11.6L74.4,75z M46.8,50.3h13.4l7,12.1L60.5,74L46.8,50.3z
	M46.8,99.7L60.5,76l13.7,23.7H46.8z M75.3,99.7l-7-12.1L75,76l13.7,23.7H75.3z M103.2,99.7H89.8l-7-12.1L89.5,76L103.2,99.7z"
    />
  </svg>
</template>
