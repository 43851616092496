<script>
export default {
  name: 'Special',
  props: {
    devise: { type: Object },
    special: {
      type: Object,
      required: true,
    },
    featured: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
    track: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<template>
  <GlueSpecialsSpecialsLayoutsFeatureSpecial
    v-if="featured"
    :special="special"
    :track="track"
    :is-left="index % 2 === 0"
  />
  <GlueSpecialsSpecialsLayoutsBigSpecial v-else-if="big" :special="special" :track="track" />
  <GlueSpecialsSpecialsLayoutsSmallSpecial v-else :special="special" :track="track" />
</template>
