<script setup lang="ts">
const props = defineProps<{
  videos: {
    name: string
    video: string
    poster: string
  }[]
}>()

const winnerVideo = computed(() => {
  return props.videos.filter(video => video.name === 'Tenzel Family')
})
const finalistVideos = computed(() => {
  return props.videos.filter(video => video.name !== 'Tenzel Family')
})

const videoToPlay: Ref<null | number> = ref(null)
</script>

<template>
  <div class="flex flex-col justify-center">
    <!-- Winner Video -->
    <div class="flex flex-row justify-center">
      <div
        v-for="(video, index) in winnerVideo"
        :key="index"
        class="w-full md:w-1/2 p-4"
      >
        <div class="relative cursor-pointer" @click="videoToPlay = index">
          <img
            :src="video.poster" :alt="video.name"
            class="w-full aspect-image bg-black border-4 border-white object-contain aspect-video"
          >
          <!-- play video icon button -->
          <Icon name="heroicons:play" class="absolute inset-0 m-auto w-16 h-16 text-white cursor-pointer" />
        </div>
        <div class="mt-4 md:mt-0 md:mb-0 col-span-12 md:col-span-4 flex flex-col sm:flex-row md:flex-col justify-center items-center">
          <div class="my-4 text-3xl font-bold min-w-[50%]">
            Tenzel Family
          </div>
        </div>
      </div>
    </div>


    <div class="mt-16 text-[39px] sm:text-[48px] md:text-[60px] lg:text-[64px] xl:text-[72px] font-barbie-headline text-white leading-tight uppercase">
      First Prize Winners
    </div>

    <!-- Finalist Videos -->
    <div class="flex flex-wrap flex-row justify-center">
      <div
        v-for="(video, index) in finalistVideos"
        :key="index + 1"
        class="w-full md:w-1/2 p-4 md:p-12"
      >
        <div class="relative cursor-pointer" @click="videoToPlay = index + 1">
          <img
            :src="video.poster" :alt="video.name"
            class="w-full aspect-image bg-black border-4 border-white object-contain aspect-video"
          >
          <!-- play video icon button -->
          <Icon name="heroicons:play" class="absolute inset-0 m-auto w-16 h-16 text-white cursor-pointer" />
        </div>
        <div class="mt-4 md:mt-0 md:mb-0 col-span-12 md:col-span-4 flex flex-col sm:flex-row md:flex-col justify-center items-center">
          <div class="my-4 text-3xl font-bold min-w-[50%]">
            {{ video.name }}
          </div>
        </div>
      </div>
    </div>

    <Teleport v-if="videoToPlay !== null" to="#portal-modal">
      <div
        class="p-12 lg:p-12 fixed w-screen h-screen z-100 flex flex-col justify-center items-center"
      >
        <div class="absolute z-0 top-0 left-0 w-screen h-screen text-white bg-black opacity-50" @click="videoToPlay = null" />
        <div class="relative">
          <Icon name="heroicons:x-mark-20-solid" class="cursor-pointer text-white w-12 h-12 z-10 absolute top-0 right-0" @click="videoToPlay = null" />
          <video
            :key="videoToPlay"
            :src="videos[videoToPlay].video"
            :poster="videos[videoToPlay].poster"
            controls
            controlsList="nodownload"
            disablePictureInPicture
            class="w-full max-w-[90vw] max-h-[80vh] aspect-video z-10 bg-black"
          />
          <div class="pointer-events-none my-4 text-3xl font-bold text-white z-10">
            {{ videos[videoToPlay].name }}
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>
