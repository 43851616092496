<script>
export default {
  name: 'BlogFilters',

  components: {
    GlueUiCustomSelect: () => import('@/components/glue/ui/GlueUiCustomSelect.vue'),
  },

  props: {
    devise: { type: Object },
    modelValue: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
  },

  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('input', modelValue)
      },
    },

    categoryLabel() {
      const category = this.categories.find(
        category => category.id === this.modelValue.category,
      )
      return category ? category.name : 'Showing All Categories'
    },
  },

  mounted() {
    if (this.currentPage && this.currentPage.slug.includes('{category?}')) {
      const encodedCategory = window.location.href.split('/')[4]
      if (encodedCategory) {
        const decodedCategory = decodeURI(encodedCategory)
        const category = this.categories.find((category) => {
          return category.name.toLowerCase() === decodedCategory.toLowerCase()
        })
        if (category)
          this.setCategory(category.id)
      }
    }
  },

  methods: {
    setTag(id) {
      if (this.modelValue.tag === id)
        this.modelValue.tag = null
      else
        this.modelValue.tag = id
    },

    setCategory(id) {
      this.modelValue.category = id
    },
  },
}
</script>

<template>
  <div class="py-12 px-4 lg:px-8 lg:px-20">
    <!-- Categories -->
    <div class="hidden lg:block font-sans2 uppercase font-bold text-zinc-700">
      Categories
    </div>
    <div class="mt-2 hidden lg:flex divide-x-2 divide-zinc-300">
      <button
        class="inline-block px-6 first:pl-0 last-pr-0 font-sans2 uppercase tracking-wider text-center"
        :class="{
          'text-zinc-500 ': modelValue.category !== null,
          'underline decoration-2 underline-offset-4 decoration-red-300':
            modelValue.category === null,
        }"
        @click="modelValue.category = null"
      >
        All
      </button>
      <button
        v-for="category in categories"
        :key="category.id"
        class="inline-block px-6 first:pl-0 last-pr-0 font-sans2 uppercase tracking-wider text-center"
        :class="{
          'text-zinc-500 ': modelValue.category !== category.id,
          'underline decoration-2 underline-offset-4 decoration-red-300':
            modelValue.category === category.id,
        }"
        @click="setCategory(category.id)"
      >
        {{ category.name }}
      </button>
    </div>

    <div class="lg:hidden">
      <GlueUiCustomSelect
        class="flex p-0 -mb-2 w-full"
        dropdown-classes="bg-white w-72 shadow-2xl"
        button-classes="font-sans text-xl uppercase tracking-wider border-red-300 border-b-2 offset-6 w-full"
        :match-width="false"
        :label="categoryLabel"
        :close-on-select="true"
      >
        <div
          v-for="category in categories"
          :key="category.id"
          class="font-sans2 p-3"
          @click="setCategory(category.id)"
        >
          {{ category.name }}
        </div>
      </GlueUiCustomSelect>
    </div>

    <!-- Tags -->
    <div
      class="mt-10 flex flex-col lg:flex-row items-start lg:items-center lg:space-x-6 font-sans2"
    >
      <span>Tags</span>
      <div class="flex items-center flex-wrap lg:flex-no-wrap">
        <button
          v-for="tag in tags"
          :key="tag.id"
          class="rounded py-1.5 px-2.5 text-xs m-1"
          :class="{
            'bg-glueblue-600 text-white': modelValue.tag === tag.id,
            'bg-white text-zinc-700': modelValue.tag !== tag.id,
          }"
          @click="setTag(tag.id)"
        >
          {{ tag.name }}
        </button>
      </div>
    </div>
  </div>
</template>
