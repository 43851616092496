<script setup lang="ts">
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({

  name: { label: 'Activity Iframe Page', group: 'Activities', layouts: ['Atlantisbahamas'] },
  preview: 'SlicesGlueActivitiesActivityIframePage.jpg',
  description: 'Used on pages that will displayed in modals',
  tags: ['Activity', 'iframe'],
  fields: {
    title: { label: 'Title', type: 'text' },
    description: { label: 'Large Description', type: 'wysiwyg' },
    largeCardImage: {
      label: 'Image',
      type: 'media',
      breakpoints: { lg: { width: 1200, height: 440 }, sm: { width: 400, height: 400 } },
    },
    link: { label: 'Top Link', type: 'link', enabled: true },
    link2: { label: 'Bottom Link', type: 'link', enabled: false },
    galleryEnabled: {
      label: 'Enable Gallery',
      type: 'checkbox',
      group: 'Gallery',
      default: false,
    },
    galleryImage1: {
      label: 'Gallery Image 1',
      type: 'media',
      group: 'Gallery',
      breakpoints: { default: { width: 1600, height: 1600 } },
    },
    galleryImage2: {
      label: 'Gallery Image 2',
      type: 'media',
      group: 'Gallery',
      breakpoints: { default: { width: 1600, height: 1600 } },
    },
    galleryImage3: {
      label: 'Gallery Image 3',
      type: 'media',
      group: 'Gallery',
      breakpoints: { default: { width: 1600, height: 1600 } },
    },
    galleryImage4: {
      label: 'Gallery Image 4',
      type: 'media',
      group: 'Gallery',
      breakpoints: { default: { width: 1600, height: 1600 } },
    },
    wistiaId: {
      type: 'text',
      label: 'Wistia ID',
      enabled: false,
      description: 'Save and refresh page to load video settings.',
    },
    videoMode: {
      type: 'select',
      label: 'Video Playback Settings',
      options: {
        clickToActivate: 'Click to Activate',
        playAutomatically: 'Play Automatically',
      },
      description: 'Save and refresh page to load video settings.',
    },
    videoControlsLocation: {
      type: 'select',
      label: 'Video Controls Location',
      options: {
        'top-left': 'Top Left',
        'top-center': 'Top Center',
        'top-right': 'Top Right',
        'middle-left': 'Middle Left',
        'middle-center': 'Middle Center',
        'middle-right': 'Middle Right',
        'bottom-left': 'Bottom Left',
        'bottom-center': 'Bottom Center',
        'bottom-right': 'Bottom Right',
      },
      description: 'Save and refresh page to load video settings.',
    },
    duration: {
      label: 'Duration (in Minutes) or a String',
      type: 'text',
      description:
        'Provide the duration in minutes or a string like "All Day", "Dusk to Dawn" or "Super duper long"',
    },
    ages: {
      label: 'Ages',
      type: 'select',
      options: {
        'Everyone': 'Everyone',
        '3-6': '3-6 Years',
        '4': '4 Years +',
        '5': '5 Years +',
        '6': '6 Years +',
        '8': '8 Years +',
        '10': '10 Years +',
        '12': '12 Years +',
        '13': '13 Years +',
        '14': '14 Years +',
        '16': '16 Years +',
      },
    },
    dolphins: { label: 'Dolphins', type: 'checkbox' },
    stingrays: { label: 'Stingrays', type: 'checkbox' },
    seaTurtles: { label: 'Sea Turtles', type: 'checkbox' },
    seaLions: { label: 'Sea Lions', type: 'checkbox' },
    sharks: { label: 'Sharks', type: 'checkbox' },
    activityLevel: {
      label: 'Activity Level',
      type: 'select',
      options: { 'Very Low': 'Very Low', 'Low': 'Low', 'Medium': 'Medium', 'High': 'High' },
    },
    price: { label: 'Price', type: 'number', enabled: false },
    swimmingRequired: { label: 'Swimming Required', type: 'checkbox' },
  },
  templates: [
    {
      label: 'Activity Iframe Page',
      fields: {
        title: { value: 'Swim in wonder' },
        description: {
          value:
            '<p>Explore the opportunity to meet and interact with one of the world\u2019s most charismatic animals in an unparalleled encounter that is enriching for both the guests and the dolphins. This deep-water adventure is customized for comfortable swimmers ages 6 and older. Learn what dolphins eat, how they exercise, and their unique ways of communicating with one another. Top it off by swimming and snorkeling with these magnificent marine mammals for a once-in-a-lifetime experience. You must be 6 years of age or older to participate in the Swim In Wonder experience.<\/p><p>Your experience begins with an orientation and includes a 30-minute, in-water interaction in a protected, enclosed environment.<\/p><p><strong>Age Requirements:<\/strong>&nbsp;Minimum age of 6 years. Guests under 13 years must be accompanied by a participating parent or guardian.<\/p><p><strong>What\u2019s Included:<\/strong><br>\u00B7 Wetsuits, personal lockers, and beach towels.<br>\u00B7 A group photographer is available. Group pictures can be purchased at an additional cost.<\/p><p><strong>Tips:<\/strong><br>\u00B7 Life vests are available for all guests.<br>\u00B7 To ensure the safety of our animals, personal cameras, cell phones, and other electronic devices cannot be used during your program.<\/p><p><\/p>',
        },
        link: {
          value: {
            text: 'BOOK YOUR EXPERIENCE TODAY',
            target: '_self',
            relationship: null,
            href: '\/new-page',
          },
        },
        largeCardImage: {
          value:
            'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/N_Homepage\/Heros_8_28_17\/Hero_Image_DolphinCay.jpg',
        },
        duration: { value: '60' },
        ages: { value: '6' },
        activityLevel: { value: 'Medium' },
        swimmingRequired: { value: true },
      },
    },
  ],
})

// State
const showVideo = ref(false)
const showGallery = ref(false)
const activeImage = ref(0)

// Computed
const duration = computed(() => {
  const duration = props.fields.duration.value
  if (duration === 'all-day')
    return 'All Day'

  if (duration === 'dusk-to-dawn')
    return 'Dusk to Dawn'

  console.log(isNaN(duration))

  if (!isNaN(duration)) {
    if (duration < 120)
      return `${duration} Min`

    return `${duration / 60} Hours`
  }

  return duration
})
const age = computed(() => {
  const age = props.fields.ages.value
  if (!Number.isNaN(age))
    return `${age} Years +`

  if (age === '3-6')
    return '3-6 Years'

  return 'All Ages'
})
const next = computed(() => {
  const urlSearchParams = process.client
    ? new URLSearchParams(window.location.search)
    : { entries: () => [] }
  const params = Object.fromEntries(urlSearchParams.entries())
  return { text: params.next, image: params.nextImage }
})
const previous = computed(() => {
  const urlSearchParams = process.client
    ? new URLSearchParams(window.location.search)
    : { entries: () => [] }
  const params = Object.fromEntries(urlSearchParams.entries())
  return { text: params.previous, image: params.previousImage }
})
const animalTypeIcon = computed(() => {
  const animals = []
  if (props.fields.dolphins.value)
    animals.push('dolphins')

  if (props.fields.stingrays.value)
    animals.push('stingrays')

  if (props.fields.seaTurtles.value)
    animals.push('seaTurtles')

  if (props.fields.seaLions.value)
    animals.push('seaLions')

  if (props.fields.sharks.value)
    animals.push('sharks')

  if (animals.length === 0 || animals.length > 1)
    return 'multiple'

  return animals[0]
})
const animalType = computed(() => {
  const animals = []
  if (props.fields.dolphins.value)
    animals.push('Dolphins')

  if (props.fields.stingrays.value)
    animals.push('Stingrays')

  if (props.fields.seaTurtles.value)
    animals.push('Sea Turtles')

  if (props.fields.seaLions.value)
    animals.push('Sea Lions')

  if (props.fields.sharks.value)
    animals.push('Sharks')

  if (animals.length === 0 || animals.length > 1)
    return 'Multiple'

  return animals[0]
})
const gallery = computed(() => {
  const gallery = []

  if (props.fields.galleryImage1.value)
    gallery.push(props.fields.galleryImage1)

  if (props.fields.galleryImage2.value)
    gallery.push(props.fields.galleryImage2)

  if (props.fields.galleryImage3.value)
    gallery.push(props.fields.galleryImage3)

  if (props.fields.galleryImage4.value)
    gallery.push(props.fields.galleryImage4)

  return gallery
})

// Methods
function openGallery(image: number) {
  showGallery.value = true
  activeImage.value = image
}
function closeGallery() {
  showGallery.value = false
}
function prevImage() {
  if (activeImage.value > 0)
    activeImage.value--
}
function nextImage() {
  if (activeImage.value < gallery.value.length - 1)
    activeImage.value++
}
function goNext() {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  window.parent.postMessage(`gotoNextActivity${params.currentIndex}`)
}
function goPrevious() {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  window.parent.postMessage(`gotoPreviousActivity${params.currentIndex}`)
}
function goTo(link: string) {
  if (link) {
    if (window.parent)
      window.parent.location = link

    window.location.href = link
  }
}

// Lifecycle
onMounted(() => {
  if (
    props.fields.wistiaId.enabled
    && props.fields.videoMode?.value === 'playAutomatically'
  ) {
    showVideo.value = true
  }
})
</script>

<template>
  <div>
    <!-- Header -->
    <div class="relative overflow-hidden max-h-[62vh]">
      <!-- Media -->
      <div class="flex justify-center items-center relative">
        <!-- Image -->
        <VoixMedia
          v-if="
            (!showVideo
              && props.fields.wistiaId.enabled
              && props.fields.videoMode?.value === 'clickToActivate')
              || !props.fields.wistiaId.enabled
          "
          :field="props.fields.largeCardImage"
          class="w-full max-h-[62vh] object-cover"
        />

        <!-- Wistia Video -->
        <glue-media-wistia
          v-if="props.fields.wistiaId.enabled && showVideo"
          :video-id="props.fields.wistiaId?.value"
          controls-height="half"
          :controls-location="props.fields.videoControlsLocation?.value"
          class="w-full h-full aspect-video image-cover"
        />

        <!-- Vistia Play Button -->
        <div
          v-if="
            props.fields.wistiaId.enabled
              && props.fields.videoMode?.value === 'clickToActivate'
              && !showVideo
          "
          class="absolute flex justify-center items-center z-20 inset-0 m-8 text-zinc-100"
        >
          <button @click="showVideo = true">
            <svg
              width="100"
              height="100"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="31"
                cy="31"
                r="30"
                fill="black"
                fill-opacity="0.4"
                stroke="white"
                stroke-width="1.5"
              />

              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                transform="translate(24 21)"
                d="M16.4265 9.12584C17.1123 9.50685 17.1123 10.4932 16.4265 10.8742L1.48564 19.1746C0.819113 19.5449 0 19.063 0 18.3005L0 1.69951C0 0.93703 0.819113 0.455062 1.48564 0.825357L16.4265 9.12584Z"
                fill="white"
              />
            </svg>
          </button>
        </div>

        <div class="absolute inset-0 bg-gradient-to-t from-black/40 to-black/0" />
      </div>

      <!-- Title -->
      <div class="absolute bottom-0 left-0 m-8 headline-3 text-white">
        {{ props.fields.title.value }}
      </div>
    </div>

    <!-- Content -->
    <div class="py-10 flex flex-col-reverse px-8 md:grid md:grid-cols-12 gap-8 bg-white">
      <!-- Details -->
      <div class="col-span-3 pb-56 md:pb-0">
        <div class="grid grid-cols-2 gap-y-8 font-sans2 text-zinc-500 text-sm">
          <!-- Duration -->
          <div class="flex flex-col items-center space-y-1">
            <div class="flex flex-col items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38" class="w-8 h-8">
                <g transform="translate(0.7 0.7)">
                  <path
                    d="M0,18.131A18.039,18.039,0,0,1,17.946,0,18.039,18.039,0,0,1,35.892,18.131,18.039,18.039,0,0,1,17.946,36.262,18.039,18.039,0,0,1,0,18.131Z"
                    fill="none"
                    stroke="#ea887b"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="1.4"
                  />
                  <path
                    d="M0,0V8.9l5.127,2.753"
                    transform="translate(16.664 10.361)"
                    fill="none"
                    stroke="#ea887b"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="1.4"
                  />
                </g>
              </svg>
            </div>
            <div>{{ duration }}</div>
          </div>

          <!-- Age -->
          <div class="flex flex-col items-center space-y-1">
            <div class="flex flex-col items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38" class="w-8 h-8">
                <g id="Group_5" data-name="Group 5" transform="translate(0.7 0.7)">
                  <path
                    id="Stroke_1"
                    data-name="Stroke 1"
                    d="M0,9.435a9.4,9.4,0,1,1,9.4,9.435A9.416,9.416,0,0,1,0,9.435Z"
                    transform="translate(6.265)"
                    fill="none"
                    stroke="#ea887b"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="1.4"
                  />
                  <path
                    id="Stroke_3"
                    data-name="Stroke 3"
                    d="M0,16.355a16.288,16.288,0,1,1,32.576,0"
                    transform="translate(0 20.129)"
                    fill="none"
                    stroke="#ea887b"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="1.4"
                  />
                </g>
              </svg>
            </div>
            <div>{{ age }}</div>
          </div>

          <!-- Animal Type -->
          <div class="flex flex-col items-center space-y-1 pr-4">
            <GlueExperienceMarineIcon :animal="animalTypeIcon" />
            <div>{{ animalType }}</div>
          </div>

          <!-- Price -->
          <div
            v-if="props.fields.price.enabled"
            class="flex flex-col items-center space-y-1"
          >
            <div class="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64.141 64.183"
                class="w-8 h-8"
              >
                <g transform="translate(0 0)">
                  <path
                    d="M27.416,64.169a3.867,3.867,0,0,1-2.755-1.143L1.139,39.368a3.965,3.965,0,0,1,0-5.562L33.577,1.156A3.921,3.921,0,0,1,36.332,0h.039L60.263.193a3.861,3.861,0,0,1,2.755,1.168,3.906,3.906,0,0,1,1.123,2.788l-.192,24.726a3.965,3.965,0,0,1-1.2,2.813L30.121,63.1a3.89,3.89,0,0,1-2.692,1.079Z"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#ea887b"
                    stroke-width="2"
                  />
                  <path
                    d="M0,4.493A4.49,4.49,0,1,1,4.49,8.986,4.5,4.5,0,0,1,0,4.493Z"
                    transform="translate(47.464 7.702)"
                    fill="none"
                    stroke="#ea887b"
                    stroke-width="2"
                  />
                </g>
              </svg>
            </div>
            <div>${{ props.fields.price.value }}</div>
          </div>

          <!-- Swimming Required -->
          <div
            v-if="props.fields.swimmingRequired.value"
            class="col-span-1 flex flex-col items-center space-y-1"
          >
            <div class="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="111"
                height="45.001"
                viewBox="0 0 111 45.001"
                class="w-14 h-8"
              >
                <g transform="translate(0 0)">
                  <g transform="translate(0 0)">
                    <path
                      d="M0,11.642A11.573,11.573,0,0,1,11.487,0,11.572,11.572,0,0,1,22.973,11.642,11.58,11.58,0,0,1,11.487,23.284,11.572,11.572,0,0,1,0,11.642Z"
                      transform="translate(75.821 5.615)"
                      fill="none"
                      stroke="#ea887b"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M59.08,37.126a73.971,73.971,0,0,1-28.711,4.716,59.065,59.065,0,0,1-14.83-2.548C5.549,36.164.227,31.461,0,31.257L.869,30.2c.054.04,5.4,4.77,15.151,7.792a58.176,58.176,0,0,0,14.336,2.481L53.489,29.4c.067-.027,3.29-1.342,4.293-4.215.6-1.735.281-3.7-.937-5.869l-.187-.339c-2.179-3.877-4.051-7.224-8.424-5.014L31.345,21.851c-.214.109-5.536,2.643-7.649-.772a3.334,3.334,0,0,1-.548-2.589c.508-2.114,3.463-3.875,8.825-7.074l.428-.258C39.729,6.78,52.233,1.007,52.353.953A6.8,6.8,0,0,1,57.167.166a6.135,6.135,0,0,1,3.785,3.1c3,5.123,17.478,28,17.625,28.231l-.054.04a42.3,42.3,0,0,1,20.312,3.321l.014.027c8.2,3.713,11.728,9.026,11.874,9.257L109.68,45C109.533,44.783,95.4,23.979,59.08,37.126Z"
                      fill="none"
                      stroke="#ea887b"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div class="text-center">
              Swimming Required
            </div>
          </div>
        </div>
      </div>

      <!-- Description -->
      <div
        class="col-span-6 font-sans2 text-zinc-600 font-light prose"
        v-html="props.fields.description.value"
      />

      <!-- Aside -->
      <div class="col-span-3 pr-8">
        <!-- Links -->
        <VoixLink
          v-if="props.fields.link.enabled"
          :field="fields.link"
          class="block w-full text-center text-white bg-glueblue-600 font-bold px-8 py-4 leading-snug uppercase focus:outline-none font-sans text-xs"
        />
        <VoixLink
          v-if="props.fields.link2.enabled"
          :field="fields.link2"
          class="block w-full text-center text-white bg-glueblue-600 font-bold px-8 py-4 leading-snug uppercase focus:outline-none font-sans text-xs mt-4"
        />

        <!-- Gallery -->
        <div v-if="props.fields.galleryEnabled.value" class="w-full font-bold text-sm text-left uppercase tracking-wide mt-12 mb-2">
          Photo Gallery
        </div>
        <div v-if="props.fields.galleryEnabled.value" class="grid grid-cols-2 gap-2">
          <VoixMedia :field="props.fields.galleryImage1" class="w-full h-full cursor-pointer" @click="openGallery(0)" />
          <VoixMedia :field="props.fields.galleryImage2" class="w-full h-full cursor-pointer" @click="openGallery(1)" />
          <VoixMedia :field="props.fields.galleryImage3" class="w-full h-full cursor-pointer" @click="openGallery(2)" />
          <VoixMedia :field="props.fields.galleryImage4" class="w-full h-full cursor-pointer" @click="openGallery(3)" />
        </div>

        <button
          v-if="next.image"
          class="flex items-center border border-gray-200 w-full group hover:bg-glueblue-600 hover:text-white transition duration-300 ease-in-out"
          @click="goNext"
        >
          <div class="flex-none w-24 h-24">
            <img :src="next.image" class="w-full h-full object-cover">
          </div>
          <div class="font-sans uppercase px-6 text-left">
            <div class="text-xs text-stone-300 group-hover:text-white">
              Next
            </div>
            <div>{{ next.text }}</div>
          </div>
        </button>
        <button
          v-if="previous.image"
          class="flex items-center border border-gray-200 w-full group hover:bg-glueblue-600 hover:text-white transition duration-300 ease-in-out"
          @click="goPrevious"
        >
          <div class="flex-none w-24 h-24">
            <img :src="previous.image" class="w-full h-full object-cover">
          </div>
          <div class="font-sans uppercase px-6 text-left">
            <div class="text-xs text-stone-300 group-hover:text-white">
              Previous
            </div>
            <div>{{ previous.text }}</div>
          </div>
        </button>
      </div>
    </div>

    <!-- Gallery Modal -->
    <Teleport v-if="showGallery" to="#portal-modal">
      <GlueUiModal :darkmode="true" modal-classes="w-full mx-4 overflow" @close="closeGallery">
        <div class="pointer-events-none">
          <div class="relative flex flex-col justify-between" style="height: 90vh">
            <div class="mb-4 text-white" style="max-height: 65vh">
              <div
                v-for="(image, key, index) in gallery"
                :key="index"
                class="w-full flex flex-col justify-center items-center"
              >
                <div v-if="activeImage === key">
                  <div
                    class="w-full h-[68vh] overflow-hidden flex justify-center items-center bg-center bg-contain bg-no-repeat relative"
                  >
                    <VoixMedia :field="image" class="w-full h-full object-contain" />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="absolute z-20 inset-0 flex justify-between items-center px-8 pointer-events-none "
            >
              <div
                class="pointer-events-auto cursor-pointer rounded-full w-10 h-10 flex justify-center items-center transition-all duration-200 hover:bg-glueblue-600"
                style="
                height: 2.5rem;
                width: 2.5rem;
                box-shadow: 0 0 1px 0px white inset, 0 0 1px 1px white;
                margin-top: -1.25rem;
              "
                @click="prevImage"
              >
                <IconsArrow class="w-5 text-gray-100" />
              </div>
              <div
                class="pointer-events-auto cursor-pointer rounded-full w-10 h-10 flex justify-center items-center transition-all duration-200 hover:bg-glueblue-600"
                style="
                height: 2.5rem;
                width: 2.5rem;
                box-shadow: 0 0 1px 0px white inset, 0 0 1px 1px white;
                margin-top: -1.25rem;
              "
                @click="nextImage"
              >
                <IconsArrow class="w-5 transform rotate-180 text-gray-100" />
              </div>
            </div>

            <div class="container mx-auto select-none">
              <div
                class="hidden md:flex justify-center w-full pt-2 pb-4 overflow-x-scroll no-scrollbar max-w-full transform"
                style="max-height: 25vh"
              >
                <div
                  v-for="(image, key, index) in gallery"
                  :id="`imageThumb-${index}`"
                  :key="index"
                  class="mr-2 cursor-pointer pointer-events-auto flex-shrink-0 duration-200 transform border-2"
                  :class="{
                    'border-white -translate-y-2': activeImage === key,
                    'border-transparent': activeImage !== key,
                  }"
                  @click="activeImage = key"
                >
                  <div class="w-full text-white">
                    <VoixMedia :field="image" class="h-32" draggable="false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GlueUiModal>
    </Teleport>
  </div>
</template>
