<script>
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  name: 'CircleSlider',

  components: {
    Swiper,
    SwiperSlide,

  },
  props: { devise: { type: Object, default: () => ({}) } },

  data() {
    return {
      swiper: null,
    }
  },

  mounted() {
    this.updateOnInit()
  },

  methods: {
    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    updateOnInit() {
      setTimeout(() => {
        if (this.$refs.mySwiper?.$el?.swiper) {
          this.$refs.mySwiper.$el.swiper.update()
          this.swiper = this.$refs.mySwiper.$el.swiper
        }
        else {
          this.updateOnInit()
        }
      }, 1000)
    },
  },
}
</script>

<template>
  <div class="overflow-hidden">
    <div class="container mx-auto">
      <div
        class="text-zinc-700 font-sans font-thin text-center uppercase text-2xl lg:text-3xl lg:leading-[65px]"
      >
        {{ devise.fields.title.value }}
      </div>
      <div v-if="devise.fields.link.enabled" class="mt-3 flex justify-center">
        <a
          :href="devise.fields.link.value?.href"
          class="btn-ghost"
        >
          {{ devise.fields.link.value.text }}
        </a>
      </div>
    </div>

    <Swiper
      ref="mySwiper"
      class="mt-12"
      slides-per-view="auto"
      :space-between="25"
      :loop="devise.slice.elements.length > 1"
      :centered-slides="false"
    >
      <SwiperSlide v-for="(slice, key) in devise.slice.elements" :key="key">
        <VoixSlice :slice="slice" :slice-index="key" />
      </SwiperSlide>
    </Swiper>

    <div class="flex justify-between mx-4 lg:ml-0">
      <div class="flex items-center space-x-3 my-8 ml-12">
        <button
          class="w-10 h-10 pointer-events-auto flex justify-center items-center rounded-full border border-zinc-500 text-zinc-500 transition-colors duration-500 hover:bg-white"
          @click="prev"
        >
          <svg
            class="w-7 h-7"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button
          class="w-10 h-10 pointer-events-auto flex justify-center items-center rounded-full border border-zinc-500 text-zinc-500 transition-colors duration-500 hover:bg-white"
          @click="next"
        >
          <svg
            class="w-7 h-7 transform rotate-180"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto;
  height: auto;
}

.swiper-wrapper {
  @apply items-stretch;
}
</style>
