<script>
import resolveConfig from 'tailwindcss/resolveConfig'
import twConfig from '~/tailwind.config.js'

const tailwindConfig = resolveConfig(twConfig)

export default {
  name: 'CustomSelect',
  components: {
    ChevronIcon: () => import('@/components/icons/IconsChevron.vue'),
  },
  props: {
    devise: { type: Object },
    /*
    Colors prop example
    {
      selectBox: {
        closed: {
          bg: #000,
          text: #fff,
        },
        open: {
          bg: 'pink',
          text: 'black',
        },
      },
      dropdown: {
        bg: 'bg-white',
      },
      dropdownOptions: {
        disabled: {
          bg: 'orange',
          text: 'black',
        },
        enabled: {
          bg: 'pink',
          text: 'black',
        },
        hoverClass: 'hover:bg-glueblue-300'
      },
    },
    */
    colors: {},
    openOn: {
      type: String,
      default: 'click', // Other option is "hover"
    },
    label: {
      type: String,
      default: 'Please select an option',
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    matchWidth: {
      type: Boolean,
      default: false,
    },
    buttonClasses: {
      type: String,
      default: 'w-full px-4 py-3 pt-4',
    },
    dropdownClasses: {
      type: String,
      default: '',
    },
    dropdownStyles: {
      type: Object,
      default: () => ({}),
    },
    dim: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDropdown: false,
      scrollTop: 0,
      currentlyHoveringOption: null,
      defaultColors: {
        selectBox: {
          closed: {
            bg: 'bg-orange-300',
            text: 'text-orange-800',
          },
          open: {
            bg: 'bg-white',
            text: 'text-gray-800',
          },
        },
        dropdown: {
          bg: 'bg-white',
        },
        dropdownOptions: {
          disabled: {
            bg: 'bg-white',
            text: 'text-gray-500',
          },
          enabled: {
            bg: 'bg-white',
            text: 'text-gray-800',
          },
          hoverClass: 'hover:bg-glueblue-300 hover:text-gray-800',
        },
      },
    }
  },
  computed: {
    tailwindColors() {
      return tailwindConfig.theme.extend.colors
    },
    finalColors() {
      return Object.assign({}, this.defaultColors, this.colors)
    },
    dropdownButtonStyles() {
      const openClosed = this.showDropdown ? 'open' : 'closed'
      let width = ''

      if (
        this.matchWidth
        && this.$refs.dropdownlist
        && this.$refs.dropdownbutton
      ) {
        width = `${this.$refs.dropdownlist.offsetWidth}px`
      }

      return {
        width,
        color: this.finalColors.selectBox[openClosed].text,
        backgroundColor: this.finalColors.selectBox[openClosed].bg,
      }
    },
    optionsDown() {
      let leftRightStyles = ``
      let upDownStyles = ``
      if (this.$refs.dropdownlist) {
        leftRightStyles = `left-0`
        const formBounds = this.$refs.dropdownlist.getBoundingClientRect()

        if (formBounds.right > window.innerWidth)
          leftRightStyles = ' right-0'

        if (formBounds.bottom > window.innerHeight)
          upDownStyles = ` bottom-0 mb-10 z-30`
        else
          upDownStyles = ` top-0 mt-10 z-30`
      }

      let styles = `absolute w-auto ${leftRightStyles} ${upDownStyles}`

      if (!this.showDropdown)
        styles += ` opacity-0 pointer-events-none`

      return styles
    },
    dropdownComputedClasses() {
      const hidden = this.showDropdown ? '' : 'opacity-0 pointer-events-none'
      return `relative z-50 text-sm font-sans2 ${hidden} ${this.dropdownClasses}`
    },
    finalDropdownStyles() {
      const styles = this.dropdownStyles

      if (!styles.backgroundColor)
        styles.backgroundColor = this.finalColors.dropdown.bg

      return styles
    },
    chevronStyle() {
      const style = {
        width: '15px',
        height: '7px',
        marginTop: '-1px',
      }
      if (!this.showDropdown)
        style.transform = 'rotate(180deg)'

      return style
    },
  },
  mounted() {
    this.scrollTop = document.documentElement.scrollTop
    window.addEventListener('scroll', () => {
      this.scrollTop = document.documentElement.scrollTop
    })
  },
  render(h) {
    const defaultSlot = this.$slots.default
    // @todo how do we get the defaultSlot now
    if (false && defaultSlot) {
      defaultSlot.forEach((vnode) => {
        if (vnode.data) {
          vnode.data.staticClass = `${vnode.data.staticClass} cursor-pointer font-base font-sans ${this.finalColors.dropdownOptions.hoverClass}`
          vnode.data.styles = this.optionStyles
        }
      })

      const options = []
      const blocker = []

      blocker.push(
        h('div', {
          class: {
            'fixed inset-0 z-20': true,
            ' bg-white opacity-0': this.dim,
            'hidden': !this.showDropdown,
          },
          on: {
            click: () => {
              if (this.openOn === 'click')
                this.showDropdown = !this.showDropdown
            },
            mouseover: () => {
              if (this.openOn === 'hover')
                this.showDropdown = false
            },
          },
        }),
      )

      options.push(
        h(
          'div',
          {
            class: this.dropdownComputedClasses,
            style: this.finalDropdownStyles,
          },
          defaultSlot,
        ),
      )

      return h(
        'div',
        {
          class: 'relative',
        },
        [
          blocker,
          h(
            'div',
            {
              ref: 'dropdownbutton',
              class: `${this.buttonClasses} cursor-pointer relative flex justify-between items-center text-xs relative z-0`,
              style: this.dropdownButtonStyles,

              on: {
                click: () => {
                  if (this.openOn === 'click')
                    this.showDropdown = !this.showDropdown
                },
                mouseover: () => {
                  if (this.openOn === 'hover')
                    this.showDropdown = true
                },
              },
            },
            [
              h(
                'span',
                {
                  class: 'mr-4 truncate font-sans uppercase',
                  style: { 'line-height': '2.1' },
                },
                this.label,
              ),
              h('chevron-icon', {
                class: 'mr-1',
                style: this.chevronStyle,
              }),
            ],
          ),
          h(
            'div',
            {
              ref: 'dropdownlist',
              class: this.optionsDown,
              on: {
                click: () => {
                  if (this.closeOnSelect)
                    this.showDropdown = !this.showDropdown
                },

                mouseleave: () => {
                  // this.showDropdown = false;
                },
              },
            },
            options,
          ),
        ],
      )
    }
    return null
  },
}
</script>
