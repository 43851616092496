<script>
export default {
  name: 'IconDining',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 100"
  >
    <path
      transform="translate(-25,-25)"
      fill="currentColor"
      d="M113.5,72c0-19.6-15.9-35.5-35.5-35.5c-10.5,0-20,4.6-26.5,12c-1.1,0.9-2.1,1.9-3,3c-7.3,6.5-12,16-12,26.5
	c0,19.6,15.9,35.5,35.5,35.5c10.5,0,20-4.6,26.5-12c1.1-0.9,2.1-1.9,3-3C108.9,92,113.5,82.5,113.5,72z M42.5,72
	c0,19.6,15.9,35.5,35.5,35.5c6,0,11.6-1.5,16.6-4.1c-5.6,3.9-12.3,6.1-19.6,6.1C56,109.5,40.5,94,40.5,75c0-7.3,2.3-14,6.1-19.6
	C44,60.4,42.5,66,42.5,72z M50.8,50.8c5.9-4.6,13.2-7.3,21.2-7.3c19,0,34.5,15.5,34.5,34.5c0,8-2.7,15.3-7.3,21.2
	c-5.9,4.6-13.2,7.3-21.2,7.3C59,106.5,43.5,91,43.5,72C43.5,64,46.2,56.7,50.8,50.8z M107.5,78c0-19.6-15.9-35.5-35.5-35.5
	c-6,0-11.6,1.5-16.6,4.1c5.6-3.9,12.3-6.1,19.6-6.1c19,0,34.5,15.5,34.5,34.5c0,7.3-2.3,14-6.1,19.6C106,89.6,107.5,84,107.5,78z
	M72,112.5C53,112.5,37.5,97,37.5,78c0-7.3,2.3-14,6.1-19.6C41,63.4,39.5,69,39.5,75c0,19.6,15.9,35.5,35.5,35.5
	c6,0,11.6-1.5,16.6-4.1C86,110.2,79.3,112.5,72,112.5z M110.5,75c0-19.6-15.9-35.5-35.5-35.5c-6,0-11.6,1.5-16.6,4.1
	c5.6-3.9,12.3-6.1,19.6-6.1c19,0,34.5,15.5,34.5,34.5c0,7.3-2.3,14-6.1,19.6C109,86.6,110.5,81,110.5,75z"
    />
  </svg>
</template>
