<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixLinkFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'
import { inView } from 'motion'

defineProps<{
  fields: {
    title: VoixWysiwygFieldInterface
    subtitle: VoixWysiwygFieldInterface
    description: VoixWysiwygFieldInterface
    link: VoixLinkFieldInterface
    bookingButtonLabel: VoixTextFieldInterface
    cobrand: VoixTextFieldInterface
    showTrees: VoixCheckboxFieldInterface
    showLines: VoixCheckboxFieldInterface
    showDots: VoixCheckboxFieldInterface
  }
}>()

const canSee = ref(false)

defineSlice({
  name: { label: 'NPIWFF: General Title And Copy', group: 'Copy', layouts: ['Atlantisbahamas', 'Summer2024', 'Npiwff', 'NPIWFF'] },
  preview: 'SlicesWffGeneralTitleAndCopy.jpg',
  description: 'Title and copy',
  tags: ['Copy'],

  slots: [{ name: 'default' }],
  fields: {
    title: { type: 'wysiwyg', label: 'Title' },
    subtitle: { type: 'wysiwyg', label: 'Subtitle' },
    description: { type: 'wysiwyg', label: 'Description' },
    link: { type: 'link', label: 'Link', enabled: false },
    bookingButtonLabel: { type: 'text', label: 'Booking Button Label', enabled: false },
    cobrand: { type: 'text', label: 'Booking Button Cobrand' },
    showTrees: { type: 'checkbox', label: 'Show Trees' },
    showLines: { type: 'checkbox', label: 'Show Lines' },
    showDots: { type: 'checkbox', label: 'Show Dots' },
  },
  templates: [{
    label: 'General Title And Copy',
    fields: {
      cobrand: { value: 'apiWebFriendsandFamily' },
      title: { value: 'TItle' },
      subtitle: { value: 'Sub TItle' },
      description: { value: 'Description' },
      bookingButtonLabel: { value: 'Book Now' },
      showTrees: { value: true },
      showLines: { value: true },
      showDots: { value: true },
    },
  }],
})

const root = ref(null)
onMounted(() => {
  inView(root.value, () => {
    canSee.value = true
  })
})
</script>

<template>
  <div ref="root" class="py-24">
    <div class="relative container flex justify-center">
      <WffSvgsWffPlanets v-if="fields.showDots.value" class="absolute left-8 top-0 w-40" />
      <WffSvgsWffWavyLines v-if="fields.showLines.value" class="absolute left-8 bottom-0 w-72 text-faw-blue" />
      <div class="px-8 xl:px-0 small-container pb-24">
        <div class="flex flex-col mb-12 w-full text-center lg:px-16">
          <slot />
          <div
            class="flex flex-col space-y-6 transform duration-500" :class="{
              'translate-y-12 opacity-0': !canSee,
              'opacity-100': canSee,
            }"
          >
            <VoixWysiwyg
              class="w-full flex items-center justify-center font-wff-headline text-glueblue-600 text-4xl font-bold uppercase"
              :field="fields.title"
            />
            <VoixWysiwyg class="w-full wysiwyg font-wff uppercase text-glueblue-600 text-2xl" :field="fields.subtitle" />
            <VoixWysiwyg class="body" :field="fields.description" />
            <div class="flex items-center justify-center space-x-4">
              <a
                v-if="fields.link.enabled"
                class="inline-block text-center border border-glueblue-600 hover:bg-glueblue-600 text-glueblue-600 hover:text-white duration-200 px-6 py-3 leading-snug uppercase focus:outline-none font-sans text-xs min-w-[100px]"
                :href="fields.link.value?.href"
                :target="fields.link.value.target"
              >{{
                fields.link.value.text }}</a>

              <BookingBookNow
                v-if="fields.bookingButtonLabel.enabled" :cobrand="fields.cobrand.value"
                location="wff-title-and-copy" disableable
              >
                <button class="inline-block text-center bg-glueblue-600 hotel-theme-bg hotel-theme-fg hover:text-white text-glueblue-200 duration-200 px-6 py-3 leading-snug uppercase focus:outline-none font-sans text-xs min-w-[100px]">
                  {{ fields.bookingButtonLabel.value }}
                </button>
              </BookingBookNow>
            </div>
          </div>
        </div>
      </div>
      <WffSvgsWffTree v-if="fields.showTrees.value" class="absolute right-8 bottom-0 w-28 text-glueblue-600" />
      <WffSvgsWffTree v-if="fields.showTrees.value" class="absolute right-4 bottom-0 w-16 text-glueblue-600" />
    </div>
  </div>
</template>
