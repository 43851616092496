<script setup lang="ts">
import type { ModelRef } from 'vue'

defineProps({
  devise: { type: Object },
  numberOfCurrentEvents: {
    type: Number,
    required: true,
  },
})

const showMobileFilters = ref(false)
const eventCounts: Ref<{ name: string, count: number }[] | null> = ref(null)

const dateOptions = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const venueOptions = {
  Aura: 'Aura',
  Crush: 'Crush',
  BarSol: 'Bar Sol',
  JokersWild: 'Joker\'s Wild',
  Theater: 'Atlantis Theater',
  MarinaVillage: 'Marina Village',
  AKA: 'Atlantis Kids Adventures (AKA)',
  ConferencesAndEvents: 'Conferences & Events',
  Outdoor: 'Outdoor Venue',
}

const modelValue: ModelRef<{ category: { [key: string]: boolean }, venue: string, date: string } | undefined> = defineModel()

const categoriesSelected = computed(() => {
  if (!modelValue.value)
    return []

  return Object.entries(modelValue.value.category)
    .filter(([, value]) => value)
    .map(([key]) => ({
      name: key,
    }))
})

function clearSelectedCategories() {
  if (modelValue.value) {
    Object.keys(modelValue.value.category).forEach((key: any) => {
      if (modelValue.value)
        modelValue.value.category[key] = false
    })
  }
}

function getEventCount(month: string) {
  if (eventCounts.value) {
    for (const index of Object.keys(eventCounts.value)) {
      if (eventCounts.value[Number(index)].name === month)
        return eventCounts.value[Number(index)].count
    }
  }
  return 0
}

function toggleMobileFilters() {
  showMobileFilters.value = !showMobileFilters.value
}

const { data } = await $voixNuxtApi(`/api/atlantis/events/Atlantis%20Events/months`)
eventCounts.value = data.value as { name: string, count: number }[]
</script>

<template>
  <div v-if="modelValue" class="py-8 w-full flex justify-center">
    <div
      class="relative max-w-[1200px] w-full px-12 flex flex-col lg:flex-row justify-center space-y-8 md:space-y-4 lg:space-y-0 lg:space-x-12 items-center"
    >
      <div class="headline-3 w-48 text-center lg:text-left">
        {{ numberOfCurrentEvents }} Events
      </div>

      <div class="relative grid grid-cols-12 gap-8">
        <div
          class="col-span-12 md:col-span-3 text-zinc-700 flex justify-center items-center font-sans2 uppercase font-bold space-x-2"
          @click="toggleMobileFilters"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 31" class="w-8 h-8">
            <rect width="39" height="2" transform="translate(0 5)" fill="currentColor" />
            <rect width="39" height="2" transform="translate(0 24)" fill="currentColor" />
            <rect width="39" height="2" transform="translate(0 15)" fill="currentColor" />
            <g
              transform="translate(23)"
              fill="#f5f2ea"
              stroke="currentColor"
              stroke-miterlimit="10"
              stroke-width="2"
            >
              <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
              <circle cx="5.5" cy="5.5" r="4.5" fill="none" />
            </g>
            <g
              transform="translate(5 10)"
              fill="#f5f2ea"
              stroke="currentColor"
              stroke-miterlimit="10"
              stroke-width="2"
            >
              <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
              <circle cx="5.5" cy="5.5" r="4.5" fill="none" />
            </g>
            <g
              transform="translate(18 20)"
              fill="#f5f2ea"
              stroke="currentColor"
              stroke-miterlimit="10"
              stroke-width="2"
            >
              <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
              <circle cx="5.5" cy="5.5" r="4.5" fill="none" />
            </g>
          </svg>

          <span>Filter By</span>
        </div>

        <!-- Desktop Filters -->
        <div class="col-span-3 hidden lg:block -mt-3">
          <GlobalUICustomSelect
            class="flex p-0"
            button-classes="uppercase font-bold text-xs text-center w-full text-zinc-500"
            dropdown-classes=" bg-white w-92  grid grid-cols-2 shadow-2xl"
            :match-width="false"
            label="Type"
            :close-on-select="false"
          >
            <label
              v-for="(isOn, category) in modelValue.category"
              :key="category"
              class="block p-4 uppercase text-xs bg-white"
            >
              <div class="flex items-center space-x-2">
                <div class="flex items-center">
                  <button class="border border-zinc-400 px-0.5 rounded-sm" @click="modelValue.category[category] = !modelValue.category[category]">

                    <Icon
                      class="w-4 h-4" :class="{
                        'opacity-0': !modelValue.category[category],
                      }"
                      name="heroicons:check-20-solid"
                    />
                  </button>
                </div>
                <span>{{ category }}</span>
              </div>
            </label>
          </GlobalUICustomSelect>

          <div
            class="pl-4 truncate font-sans2 text-xs w-full flex justify-center space-x-1.5 items-center font-light"
          >
            <template v-if="categoriesSelected.length">
              <span>{{
                categoriesSelected.map((category) => `${category.name}`).join(", ")
              }}</span>
              <button @click="clearSelectedCategories">
                <svg
                  class="w-3 h-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </template>
            <span v-else class="text-center w-full italic text-gray-500">
              Nothing Selected
            </span>
          </div>
        </div>

        <div class="col-span-3 hidden lg:block -mt-3">
          <GlobalUICustomSelect
            class="flex"
            button-classes="uppercase font-bold text-xs text-center w-full text-zinc-500"
            dropdown-classes=" bg-white shadow-2xl"
            :match-width="false"
            label="Venues"
            :close-on-select="true"
          >
            <label
              v-for="(venue, key) in venueOptions"
              :key="key"
              class="block p-4 uppercase text-xs"
              @click="modelValue.venue = key"
            >
              <div class="flex items-center space-x-2">
                <span>{{ venue }}</span>
              </div>
            </label>
          </GlobalUICustomSelect>

          <div class="pl-4 truncate font-sans2 text-xs w-full flex justify-center items-center font-light">
            <template v-if="modelValue.venue">
              {{ venueOptions[modelValue.venue as keyof typeof venueOptions] }}

              <button class="ml-2" @click="modelValue.venue = ''">
                <svg
                  class="w-3 h-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </template>
            <span v-else class="text-center w-full italic text-gray-500">
              Nothing Selected
            </span>
          </div>
        </div>

        <div class="col-span-3 hidden lg:block -mt-3">
          <GlobalUICustomSelect
            class="flex"
            button-classes="uppercase font-bold text-xs text-center w-full text-zinc-500"
            dropdown-classes=" bg-white shadow-2xl grid grid-cols-2 w-72"
            :match-width="false"
            label="Month"
            :close-on-select="true"
          >
            <label
              v-for="(date, key) in dateOptions"
              :key="key"
              class="block p-4 uppercase text-xs"
              @click="getEventCount(date) ? (modelValue.date = date) : null"
            >
              <div
                class="flex items-center space-x-2"
                :class="{ 'opacity-50': getEventCount(date) === 0 }"
              >
                <span>{{ date }} ({{ getEventCount(date) }}) </span>
              </div>
            </label>
          </GlobalUICustomSelect>

          <div
            class="truncate font-sans2 text-xs w-full flex justify-center items-center font-light space-x-2"
          >
            <template v-if="modelValue.date">
              <span>{{ modelValue.date }}</span>

              <button @click="modelValue.date = ''">
                <svg
                  class="w-3 h-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </template>
            <span v-else class="text-center w-full italic text-gray-500">
              Nothing Selected
            </span>
          </div>
        </div>
      </div>

      <!-- Mobile Filters -->
      <div
        v-show="showMobileFilters"
        class="lg:hidden absolute z-30 top-20 left-0 right-0 mt-1"
      >
        <button class="absolute top-[-30px] right-8" @click="toggleMobileFilters">
          <Icon
            class="w-6 h-6 text-gray-500"
            name="heroicons:x-circle"
          />
        </button>
        <div class="bg-white py-2 px-2 shadow-xl flex justify-center flex-col space-y-2 mx-8 divide-y divide-gray-300">
          <div class="relative z-30">
            <GlobalUICustomSelect
              class="flex"
              button-classes="uppercase font-bold text-sm text-center w-full text-zinc-500"
              dropdown-classes="bg-white w-72 grid grid-cols-2 shadow-2xl"
              :match-width="false"
              label="Category"
              :close-on-select="false"
            >
              <label
                v-for="(isOn, category) in modelValue.category"
                :key="category"
                class="block p-4 uppercase text-xs bg-white"
              >
                <div class="flex items-center space-x-2">
                  <div class="flex items-center">
                    <button class="border border-zinc-400 px-0.5 rounded-sm" @click="modelValue.category[category] = !modelValue.category[category]">

                      <Icon
                        class="w-4 h-4" :class="{
                          'opacity-0': !modelValue.category[category],
                        }"
                        name="heroicons:check-20-solid"
                      />
                    </button>
                  </div>
                  <span>{{ category }}</span>
                </div>
              </label>
            </GlobalUICustomSelect>
            <div
              class="pl-4 truncate font-sans2 text-xs w-full flex justify-center items-center font-light"
            >
              <template v-if="categoriesSelected.length">
                <button @click="clearSelectedCategories">
                  <svg
                    class="w-3 h-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
                {{ categoriesSelected.map((category) => `${category.name}`).join(", ") }}
              </template>
              <span v-else class="text-center w-full italic text-gray-500">
                Nothing Selected
              </span>
            </div>
          </div>
          <div class="-mt-3 relative z-10">
            <GlobalUICustomSelect
              class="flex"
              button-classes="uppercase font-bold text-sm text-center w-full text-zinc-500"
              dropdown-classes=" bg-white shadow-2xl relative z-20 grid grid-cols-2"
              :match-width="false"
              label="Venue"
              :close-on-select="true"
            >
              <label
                v-for="(venue, key) in venueOptions"
                :key="key"
                class="block p-4 uppercase text-xs"
                @click="modelValue.venue = venue"
              >
                <div class="flex items-center space-x-2">
                  <span>{{ venue }}</span>
                </div>
              </label>
            </GlobalUICustomSelect>
            <div
              class="pl-4 truncate font-sans2 text-xs w-full flex justify-center items-center font-light"
            >
              <template v-if="modelValue.venue">
                {{ modelValue.venue }}
                <button class="ml-2" @click="modelValue.venue = ''">
                  <svg
                    class="w-3 h-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </template>
              <span v-else class="text-center w-full italic text-gray-500">
                Nothing Selected
              </span>
            </div>
          </div>
          <div class="-mt-3 relative z-10">
            <GlobalUICustomSelect
              class="flex"
              button-classes="uppercase font-bold text-sm text-center w-full text-zinc-500"
              dropdown-classes=" bg-white shadow-2xl relative z-20 grid grid-cols-2"
              :match-width="false"
              label="Date"
              :close-on-select="true"
            >
              <label
                v-for="(date, key) in dateOptions"
                :key="key"
                class="block p-4 uppercase text-xs"
                @click="modelValue.date = date"
              >
                <div class="flex items-center space-x-2">
                  <span>{{ date }}</span>
                </div>
              </label>
            </GlobalUICustomSelect>
            <div
              class="pl-4 truncate font-sans2 text-xs w-full flex items-center font-light flex"
            >
              <template v-if="modelValue.date">
                {{ modelValue.date }}
                <button class="ml-2" @click="modelValue.date = null">
                  <svg
                    class="w-3 h-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </template>
              <span v-else class="text-center w-full italic text-gray-500">
                Nothing Selected
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
