<script>
import { usePageStore } from '@voix/store/pageStore'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { DatePicker } from 'v-calendar'

export default {
  components: {
    DatePicker,
  },
  props: {
    devise: { type: Object },
    origin: {
      type: String,
      default: 'http://AtlantisBahamas.com/',
    },
    prefillCheckIn: {
      type: String,
    },
    prefillCheckOut: {
      type: String,
    },
  },
  data() {
    return {
      currentPage: null,
      errors: [],
      date: dayjs().add(1, 'day').toDate(),
      checkin: dayjs().add(1, 'day').toDate(),
      checkout: dayjs().add(1, 'day').toDate(),
      tracking: {
        nav: null,
        discount_code: null,
        discount_code_air: null,
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        specialid: null,
        special_id_air: null,
      },
    }
  },
  computed: {
    formattedCheckinDate() {
      if (this.devise.fields.mode.value === 'duo')
        return this.checkin ? dayjs(this.checkin).format('MM/DD/YY') : ''

      return this.date ? dayjs(this.date).format('MM/DD/YY') : ''
    },
    formattedCheckoutDate() {
      if (this.devise.fields.mode.value === 'duo')
        return this.checkout ? dayjs(this.checkout).format('MM/DD/YY') : ''

      return this.date ? dayjs(this.date).format('MM/DD/YY') : ''
    },

    formTarget() {
      return this.inIframe() ? '_top' : '_self'
    },
    minDate() {
      if (this.tracking.book_limit_min_date !== null)
        return dayjs(this.tracking.book_limit_min_date).toDate()

      return dayjs().toDate()
    },
    maxDate() {
      if (this.tracking.book_limit_max_date !== null)
        return dayjs(this.tracking.book_limit_max_date).toDate()

      return dayjs().add(540, 'days').toDate()
    },
  },

  watch: {
    checkin(newValue) {
      this.checkout = newValue
    },
  },

  mounted() {
    const pageStore = usePageStore()
    this.currentPage = pageStore.currentPage

    this.retrieveTracking()
    this.prefillCheckInForm()
  },
  methods: {
    retrieveTracking() {
      const apiUrl = `/api/devise/pages/${this.currentPage.id}/booking-engine-parameters`

      $voixFetch(apiUrl).then((response) => {
        this.tracking = Object.assign({}, response)

        if (this.currentPage.site_id === 1) {
          if (window.dataLayer)
            window.dataLayer.push({ CoBrand: this.tracking.nav })
        }
      })
    },

    prefillCheckInForm() {
      if (this.prefillCheckIn !== '')
        this.checkin = new Date(this.prefillCheckIn)

      if (this.prefillCheckOut !== '')
        this.checkout = new Date(this.prefillCheckOut)
    },

    submit() {
      this.validateForm()
        .then(() => {
          if (this.currentPage.site_id === 1) {
            const bookingSubmittionCookie = useCookie('booking-submission-id')
            const bookingSubmittionFrom = useCookie('booking-submitted-from')
            bookingSubmittionCookie.value = uuidv4()

            const formParams = new URLSearchParams(
              new FormData(this.$refs.masteroftheuniverse),
            ).toString()

            const from = window.location.href

            bookingSubmittionFrom.value = from

            $voixFetch(`/user-booking-interstitial?${formParams}&submission_id=${bookingSubmittionCookie.value}&from=${from}`)
          }

          const bookingSubmittedFromCookie = useCookie(
            'booking-submitted-from',
          )
          bookingSubmittedFromCookie.value = window.location.href

          const bookingParamsCookie = useCookie('booking-params')
          bookingParamsCookie.value = JSON.stringify(this.booking)

          const bookingParamsWaitlistCookie = useCookie(
            'booking-params-waitlist',
          )
          bookingParamsWaitlistCookie.value = JSON.stringify(this.booking)

          this.$refs.bookingform.submit()
        })
        .catch((errors) => {
          console.log('Form Submission Error', errors)

          if (typeof errors === 'object')
            errors.map(error => this.showError(error))
        })
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        const errors = []
        const start = dayjs(this.checkin)
        const end = dayjs(this.checkout)

        if (this.tracking.book_limit_max_days === null) {
          if (end.diff(start, 'days') > 30)
            errors.push('Please call 1-800 Atlantis for bookings greater than 30 days.')
        }
        else if (end.diff(start, 'days') > this.tracking.book_limit_max_days) {
          errors.push(
            `Bookings over ${this.tracking.book_limit_max_days} nights not permitted. Please adjust your search to find results.`,
          )
        }

        if (errors?.length > 0)
          reject(errors)
        else resolve()
      })
    },

    gaFormat(date) {
      const year = date.getFullYear()
      let month = (1 + date.getMonth()).toString()
      month = month?.length > 1 ? month : `0${month}`
      let day = date.getDate().toString()
      day = day?.length > 1 ? day : `0${day}`

      return `${month}/${day}/${year}`
    },

    showError(error) {
      this.errors.push(error)
    },

    inIframe() {
      try {
        return window.self !== window.top
      }
      catch (e) {
        return true
      }
    },
  },
}

// http://localhost:3000/things-to-do/dolphin-cay#cal
</script>

<template>
  <div
    class="bg-white w-full flex justify-center items-center flex-col md:flex-row"
    :class="{ 'flex-col-reverse': devise.fields.mode.value === 'duo' }"
  >
    <div class="md:w-1/2 p-8">
      <h2 class="headline-2 uppercase mb-4 text-center" v-html="devise.fields.title.value" />

      <div
        class="mb-6 text-center text-sm font-sans2 text-gray-600"
        v-html="devise.fields.copy.value"
      />
      <ClientOnly>
        <div class="flex flex-col justify-center">
          <div
            v-if="devise.fields.mode.value === 'inline'"
            class="pb-6 md:pb-0 flex justify-center"
          >
            <DatePicker
              v-model="date"
              :is-inline="true"
              class="calendar-picker"
              popover-visibility="focus"
              :min-date="minDate"
            />
          </div>
          <div class="mx-8 text-center">
            <div v-if="devise.fields.mode.value !== 'inline'" class="flex justify-center mb-6">
              <fieldset class="mr-4">
                <label>FROM</label>
                <DatePicker
                  v-model="checkin"
                  popover-direction="above"
                  class="calendar-picker"
                  popover-visibility="focus"
                  :min-date="minDate"
                >
                  <template #default="slotProps">
                    <div class="flex w-full" @click.prevent="slotProps.togglePopover">
                      <input
                        type="text"
                        placeholder="Check In"
                        :value="formattedCheckinDate"
                        class="w-full font-sans px-4 py-5 uppercase outline-none bg-white text-gray-600"
                        readonly="true"
                      >
                    </div>
                  </template>
                </DatePicker>
              </fieldset>
              <fieldset>
                <label>TO</label>
                <DatePicker
                  v-model="checkout"
                  popover-direction="above"
                  class="calendar-picker"
                  :min-date="minDate"
                >
                  <template #default="slotProps">
                    <div class="flex w-full" @click.prevent="slotProps.togglePopover">
                      <input
                        type="text"
                        placeholder="Check Out"
                        :value="formattedCheckoutDate"
                        class="w-full font-sans px-4 py-5 uppercase outline-none bg-white text-gray-600"
                        readonly="true"
                      >
                    </div>
                  </template>
                </DatePicker>
              </fieldset>
            </div>
            <ClientOnly>
              <form
                ref="bookingform"
                action="https://secure.atlantisbahamas.com/travel/arc_waiting.cfm"
                method="GET"
                :target="formTarget"
                class="mb-6"
              >
                <input type="hidden" name="nav" :value="tracking.nav" data-testid="mini-inline-booking-nav">
                <input type="hidden" name="origin" :value="origin">
                <input type="hidden" name="activity1" value="true">
                <input type="hidden" name="area2" value="|airport%3ANAS">
                <input type="hidden" name="displayarea2" value="NAS">
                <input type="hidden" name="date1" :value="formattedCheckinDate">
                <input type="hidden" name="date2" :value="formattedCheckoutDate">
                <ul v-if="errors?.length > 0" class="font-sans mb-4">
                  <li
                    v-for="(error, key) in errors"
                    :key="key"
                    class="p-4 mb-2 bg-blue-600 text-white rounded"
                  >
                    {{ error }}
                  </li>
                </ul>
                <button class="form-button bg-glueblue-600 text-white uppercase text-xs tracking-widest py-4 px-6 font-bold" @click.prevent="submit">
                  {{ devise.fields.buttonLabel.value }}
                </button>
              </form>
            </ClientOnly>
          </div>
        </div>
      </ClientOnly>
    </div>
    <div v-if="devise.fields?.image?.value && devise.fields.image.value.length" class="md:w-1/2">
      <VoixMedia :field="devise.fields.image" class="object-cover w-full h-full" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calendar-picker {
@apply relative font-sans z-40;

  .vc-popover-content-wrapper {
    @apply z-[50];
  }

  .vc-title {
    @apply text-gray-600 text-sm uppercase font-normal;
  }

  .vc-weekday {
    @apply text-xs text-glueblue-600
  }

  .vc-day {
    @apply px-1 py-1
  }

  .booking-calendar-footer {
    @apply font-sans2 font-bold;
  }
}

html[data-theme="barbie-theme"],
.barbie-theme {

  .form-button  {
    @apply bg-barbie-500 text-white font-barbie-headline font-normal tracking-wide text-2xl border-transparent py-4 px-6;
  }

}
</style>
