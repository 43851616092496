<script setup lang="ts">
import { resolveFieldsArray } from '@voix/composables/useField'

const props = defineProps<{
  slice: {
    elements: any[]
  }
}>()

defineSlice({
  name: { label: 'Tab System Container', group: 'Tab System', layouts: ['Atlantisbahamas'] },
  preview: 'SlicesGlueTabsystemTabSystemContainer.jpg',
  description: 'Tab System parent container',
  tags: ['Container', 'Navigation'],

  slots: [{ name: 'default', allowedElements: ['SlicesGlueTabsystemTabSystemTab'] }],
  templates: [{ label: 'Tab System Container', fields: [] }],
})

const currentSection = ref(0)

const tabs = computed(() => {
  const tabElements = props.slice.elements

  // Filter the tabs to only so elements that have a label 'tab system tab' when lowercased
  return tabElements.filter((element) => {
    return element.label.toLowerCase() === 'tab system tab'
  })
})

const tabFields = computed(() => {
  // Convert the tab fields to true fields
  return tabs.value.map((element) => {
    return resolveFieldsArray(element)
  })
})
</script>

<template>
  <div class="flex justify-center bg-orange-100">
    <div class="container">
      <div>
        <div class="hidden md:flex flex-row justify-center mb-12">
          <div
            v-for="(tab, index) in tabFields"
            :key="index"
            class="
          cursor-pointer
          md:w-1/5
          p-4
          text-center
          transform
          duration-500
          border border-gray-200
        "
            :class="{
              'bg-white shadow  md:-translate-y-2': currentSection === index,
              'bg-gray-100': currentSection !== index,
            }"
            @click="currentSection = index"
          >
            <div class="flex flex-col justify-end items-center h-full">
              <VoixMedia
                v-if="tab.tabImage"
                :field="tab.tabImage"
              />
              <div v-if="tab" class="font-sans mt-4">
                {{ tab.tabLabel.value }}
              </div>
            </div>
          </div>
        </div>

        <div>&nbsp;</div>

        <div class="flex justify-center px-4 md:hidden mt-4">
          <div class="w-full">
            <label for="tabselect" class="font-sans2 mb-1 text-sm text-gray-600">Select the content section</label>
            <div class="relative w-full">
              <select
                id="tabselect"
                v-model="currentSection"
                name="tabselect"
                class="
              appearance-none
              w-full
              rounded
              px-4
              py-2
              font-sans2
              text-gray-600
            "
              >
                <option v-for="(tab, index) in tabFields" :key="index" :value="index">
                  <template v-if="tab.tabLabel?.value">
                    {{ tab.tabLabel.value }}
                  </template>
                </option>
              </select>
              <svg
                class="
              absolute
              right-0
              top-0
              mt-3
              mr-4
              w-5
              h-5
              text-gray-500
              pointer-events-none
            "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          </div>
        </div>

        <div class="">
          <template v-for="(s, key) in tabs" :key="s.id">
            <template v-if="key === currentSection">
              <VoixSlice :slice="s" :slice-index="key" />
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
