<script>
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  name: 'Shopping',

  components: {
    Swiper,
    SwiperSlide,
  },
  props: { devise: { type: Object, default: () => ({}) } },

  data() {
    return {
      mode: 'personal-training',
      swiper: null,
    }
  },

  mounted() {
    this.updateOnInit()
  },

  methods: {
    goTo(mode) {
      this.mode = mode

      if (mode === 'personal-training')
        this.goToSlide(0)
      else if (mode === 'yoga')
        this.goToSlide(1)
      else if (mode === 'cycling')
        this.goToSlide(2)
      else if (mode === 'boot-camp')
        this.goToSlide(3)
      else if (mode === 'wellness-seminars')
        this.goToSlide(4)
    },

    updateOnInit() {
      setTimeout(() => {
        if (this.$refs.mySwiper?.$el?.swiper) {
          this.$refs.mySwiper.$el.swiper.update()
          this.swiper = this.$refs.mySwiper.$el.swiper
        }
        else {
          this.updateOnInit()
        }
      }, 1000)
    },

    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    goToSlide(index) {
      this.swiper.slideTo(index)
    },
  },
}
</script>

<template>
  <div>
    <div class="hidden lg:block pt-12 pb-40">
      <div class="grid lg:grid-cols-4">
        <div class="flex justify-end py-24 px-12">
          <ul
            class="w-56 my-8 uppercase font-sans font-light text-sm pr-8 space-y-8 text-right"
          >
            <li
              class="cursor-pointer transition-all duration-200"
              :class="{
                'text-lg text-red-500 font-medium':
                  mode === 'personal-training',
              }"
              @click="goTo('personal-training')"
            >
              PERSONAL TRAINING
            </li>
            <li
              class="cursor-pointer transition-all duration-200"
              :class="{
                'text-lg text-red-500 font-medium': mode === 'yoga',
              }"
              @click="goTo('yoga')"
            >
              YOGA
            </li>
            <li
              class="cursor-pointer transition-all duration-200"
              :class="{
                'text-lg text-red-500 font-medium': mode === 'cycling',
              }"
              @click="goTo('cycling')"
            >
              CYCLING
            </li>
            <li
              class="cursor-pointer transition-all duration-200"
              :class="{
                'text-lg text-red-500 font-medium': mode === 'boot-camp',
              }"
              @click="goTo('boot-camp')"
            >
              BOOT CAMP
            </li>
            <li
              class="cursor-pointer transition-all duration-200"
              :class="{
                'text-lg text-red-500 font-medium':
                  mode === 'wellness-seminars',
              }"
              @click="goTo('wellness-seminars')"
            >
              WELLNESS SEMINARS
            </li>
          </ul>
          <div class="relative w-1 bg-stone-300 mb-16">
            <!-- Track -->
            <div
              class="absolute top-0 left-0 right-0 w-1 bg-red-500 transition-all duration-300"
              :class="{
                'h-10': mode === 'personal-training',
                'h-[82px]': mode === 'yoga',
                'h-[136px]': mode === 'cycling',
                'h-[188px]': mode === 'boot-camp',
                'h-[240px]': mode === 'wellness-seminars',
              }"
            />

            <!-- Handle -->
            <div
              class="absolute top-0 left-0 right-0 -ml-4 w-8 h-8 rounded-full bg-red-500 p-[2px] transition-all duration-300"
              :class="{
                'mt-7': mode === 'personal-training',
                'mt-[82px]': mode === 'yoga',
                'mt-[136px]': mode === 'cycling',
                'mt-[188px]': mode === 'boot-camp',
                'mt-[240px]': mode === 'wellness-seminars',
              }"
            >
              <div
                class="w-full h-full rounded-full border-[0.5px] border-orange-100 p-[2px]"
              >
                <div
                  class="w-full h-full rounded-full border-[0.5px] border-orange-100 p-[2px]"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="relative col-span-3">
          <Swiper
            ref="mySwiper"
            :slides-per-view="1"
            :centered-slides="true"
            class="w-full h-[600px]"
          >
            <SwiperSlide class="w-full h-[600px]">
              <VoixMedia :field="devise.fields.ptrainingImage" class="object-cover w-full h-full" />
            </SwiperSlide>
            <SwiperSlide class="w-full h-[600px]">
              <VoixMedia :field="devise.fields.yogaImage" class="object-cover w-full h-full" />
            </SwiperSlide>
            <SwiperSlide class="w-full h-[600px]">
              <VoixMedia :field="devise.fields.cyclingImage" class="object-cover w-full h-full" />
            </SwiperSlide>
            <SwiperSlide class="w-full h-[600px]">
              <VoixMedia :field="devise.fields.bootCampImage" class="object-cover w-full h-full" />
            </SwiperSlide>
            <SwiperSlide class="w-full h-[600px]">
              <VoixMedia :field="devise.fields.seminarsImage" class="object-cover w-full h-full" />
            </SwiperSlide>
          </Swiper>

          <div class="absolute z-10 bottom-0 right-0 p-8 bg-white w-1/3 -mb-24">
            <div v-show="mode === 'personal-training'">
              <div
                class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
              >
                PERSONAL TRAINING
              </div>
              <div class="text-sm font-sans2 font-light antialiased mt-1">
                Our fitness professionals will design an exercise plan based on
                your body’s particular needs to help you achieve the results you
                have always wanted.
              </div>
            </div>
            <div v-show="mode === 'yoga'">
              <div
                class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
              >
                YOGA
              </div>
              <div class="text-sm font-sans2 font-light antialiased mt-1">
                Stretch your body beyond its normal paradigm with private or
                group Yoga Classes in our Yoga pavilion. Group classes are
                available Monday- Friday.
              </div>
            </div>
            <div v-show="mode === 'cycling'">
              <div
                class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
              >
                CYCLING
              </div>
              <div class="text-sm font-sans2 font-light antialiased mt-1">
                A very high-intensity cardiovascular workout set to high energy
                music to get your heart pumping. Monday - Friday.
              </div>
            </div>
            <div v-show="mode === 'boot-camp'">
              <div
                class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
              >
                BOOT CAMP
              </div>
              <div class="text-sm font-sans2 font-light antialiased mt-1">
                Time to work up a sweat with this incredibly fun, high calorie
                burning class. This is an all body workout that combines many
                different exercises to get your heart pumping
              </div>
            </div>
            <div v-show="mode === 'wellness-seminars'">
              <div
                class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
              >
                WELLNESS SEMINARS
              </div>
              <div class="text-sm font-sans2 font-light antialiased mt-1">
                Wellness seminars are available throughout your stay and are
                complimentary, please contact the fitness center for a schedule
                of seminars, select from: How to Increase Your Metabolism
                Secrets to a Flatter Stomach Detox for Health and Weight Loss
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full lg:hidden overflow-hidden">
      <div
        class="font-sans2 uppercase font-bold font-stone-700 rounded-sm w-[1000px] transition-all duration-300"
        :class="{
          'ml-[40%]': mode === 'personal-training',
          'ml-[10%]': mode === 'yoga',
          '-ml-[90px]': mode === 'cycling',
          '-ml-[215px]': mode === 'boot-camp',
          '-ml-[353px]': mode === 'wellness-seminars',
        }"
      >
        <ul class="flex space-x-8 mb-6">
          <li
            class="cursor-pointer"
            :class="{ 'text-red-500': mode === 'personal-training' }"
            @click="goTo('personal-training')"
          >
            CRYSTAL COURT SHOPS
          </li>
          <li
            class="cursor-pointer"
            :class="{ 'text-red-500': mode === 'yoga' }"
            @click="goTo('yoga')"
          >
            ESCAPE AT THE COVE
          </li>
          <li
            class="cursor-pointer"
            :class="{ 'text-red-500': mode === 'cycling' }"
            @click="goTo('cycling')"
          >
            ATLANTIS LOGO STORES
          </li>
          <li
            class="cursor-pointer"
            :class="{ 'text-red-500': mode === 'boot-camp' }"
            @click="goTo('boot-camp')"
          >
            MARINA VILLAGE
          </li>
          <li
            class="cursor-pointer"
            :class="{ 'text-red-500': mode === 'wellness-seminars' }"
            @click="goTo('wellness-seminars')"
          >
            PRO SHOP
          </li>
        </ul>

        <div class="relative h-2 bg-stone-300">
          <div
            class="absolute top-0 bottom-0 bg-red-500 rounded-sm transition-all duration-300"
            :class="{
              'w-[100px]': mode === 'personal-training',
              'w-[125px] ml-[115px]': mode === 'yoga',
              'w-[140px] ml-[240px]': mode === 'cycling',
              'w-[130px] ml-[380px]': mode === 'boot-camp',
              'w-[100px] ml-[507px]': mode === 'wellness-seminars',
            }"
          />
        </div>
      </div>
      <div class="mt-4 mx-8">
        <VoixMedia v-if="mode === 'personal-training'" :field="devise.fields.ptrainingImage" class="w-full h-full" />

        <VoixMedia v-if="mode === 'yoga'" :field="devise.fields.yogaImage" class="w-full h-full" />

        <VoixMedia v-if="mode === 'cycling'" :field="devise.fields.cyclingImage" class="w-full h-full" />

        <VoixMedia v-if="mode === 'boot-camp'" :field="devise.fields.bootCampImage" class="w-full h-full" />

        <VoixMedia v-if="mode === 'wellness-seminars'" :field="devise.fields.seminarsImage" class="w-full h-full" />

        <div class="p-8 bg-white">
          <div v-show="mode === 'personal-training'">
            <div
              class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
            >
              PERSONAL TRAINING
            </div>
            <div class="text-sm font-sans2 font-light antialiased mt-1">
              Our fitness professionals will design an exercise plan based on
              your body’s particular needs to help you achieve the results you
              have always wanted.
            </div>
          </div>
          <div v-show="mode === 'yoga'">
            <div
              class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
            >
              YOGA
            </div>
            <div class="text-sm font-sans2 font-light antialiased mt-1">
              Stretch your body beyond its normal paradigm with private or group
              Yoga Classes in our Yoga pavilion. Group classes are available
              Monday- Friday.
            </div>
          </div>
          <div v-show="mode === 'cycling'">
            <div
              class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
            >
              CYCLING
            </div>
            <div class="text-sm font-sans2 font-light antialiased mt-1">
              A very high-intensity cardiovascular workout set to high energy
              music to get your heart pumping. Monday - Friday.
            </div>
          </div>
          <div v-show="mode === 'boot-camp'">
            <div
              class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
            >
              BOOT CAMP
            </div>
            <div class="text-sm font-sans2 font-light antialiased mt-1">
              Time to work up a sweat with this incredibly fun, high calorie
              burning class. This is an all body workout that combines many
              different exercises to get your heart pumping
            </div>
          </div>
          <div v-show="mode === 'wellness-seminars'">
            <div
              class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
            >
              WELLNESS SEMINARS
            </div>
            <div class="text-sm font-sans2 font-light antialiased mt-1">
              Wellness seminars are available throughout your stay and are
              complimentary, please contact the fitness center for a schedule of
              seminars, select from: How to Increase Your Metabolism Secrets to
              a Flatter Stomach Detox for Health and Weight Loss
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
