<script>
import { Filter } from 'bad-words'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(utc)

export default {
  name: 'MemoryGame',
  props: { devise: { type: Object, default: () => ({}) } },

  data() {
    return {
      isFullscreen: false,
      numberOfCards: 16, // 4, 16, 36, 64, 100,
      availableCardBacks: 74,
      moves: 0,
      clickingAllowed: true,
      startTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      currentTime: 0,
      tick: null,
      flippedCards: [],
      matchedCards: [],
      possibleImages: [],

      showGetStartedModal: true,
      showWinningModal: false,
      winningStats: {
        moves: 0,
        time: 0,
      },

      highScores: {
        moves: [],
        time: [],
      },

      scoreSubmission: {
        initials: '',
        email: '',
        email_opt_in: true,
        agree: false,
      },

      scoreSubmitted: false,
    }
  },

  computed: {
    difficulty() {
      if (this.numberOfCards === 4)
        return 'Very Easy'
      else if (this.numberOfCards === 16)
        return 'Easy'
      else if (this.numberOfCards === 36)
        return 'Normal'
      else if (this.numberOfCards === 64)
        return 'Hard'
      else if (this.numberOfCards === 100)
        return 'Incredibly Hard'

      return 'Very Easy'
    },

    shuffledImages() {
      // changed to spread operator to avoid mutating the original array
      return [...this.possibleImages].sort(() => Math.random() - 0.5)
    },

    formattedCurrentTime() {
      const secondsAgo = dayjs().subtract(this.currentTime, 'second')
      const dur = dayjs.duration(dayjs().diff(secondsAgo))

      return dayjs.utc(dur.asMilliseconds()).format('mm:ss') // "00:01:00"
    },

    gameWon() {
      if (this.matchedCards.length === this.numberOfCards)
        return true

      return false
    },

    scoreSubmissionValid() {
      const badWordFilter = new Filter()
      const word = this.scoreSubmission.initials

      return (
        !badWordFilter.isProfane(word)
        && this.scoreSubmission.initials !== 'fuc'
        && this.scoreSubmission.initials.length >= 2
        && this.scoreSubmission.email.length > 5
        && this.scoreSubmission.agree
      )
    },

    topTenHighScoresMoves() {
      return this.highScores.moves.slice(0, 10)
    },
    topTenHighScoresTime() {
      return this.highScores.time.slice(0, 10)
    },
  },

  watch: {
    numberOfCards() {
      this.teardown()
    },

    gameWon(newValue) {
      // If the gameWon is set to true
      if (newValue) {
        this.getHighScores()

        this.showWinningModal = true

        this.winningStats = {
          moves: `${this.moves}`,
          time: `${this.currentTime}`,
        }
      }
    },
  },

  mounted() {
    this.setup()
    this.getHighScores()
  },

  methods: {
    randomSelect(x) {
      const nums = new Set([...Array.from({ length: this.availableCardBacks })].map((_, i) => i + 1)) // Create a set of numbers from 1 to 50
      const result = []
      for (let i = 0; i < x; i++) {
        const randomNum = [...nums][Math.floor(Math.random() * nums.size)] // Select a random number from the set
        nums.delete(randomNum) // Remove the selected number from the set
        result.push(randomNum) // Add the selected number to the result array
      }
      return result
    },

    setup() {
      const halfTheCards = this.numberOfCards / 2
      const nums = this.randomSelect(halfTheCards)

      for (let i = 0; i < halfTheCards; i++) {
        const randomNum = nums[i]

        this.possibleImages.push({
          id: randomNum,
          img: `/imgs/memory-game/memory-game-${randomNum}.jpg`,
        })
        this.possibleImages.push({
          id: randomNum,
          img: `/imgs/memory-game/memory-game-${randomNum}.jpg`,
        })
      }
    },

    startGame() {
      this.tick = setInterval(() => {
        this.currentTime = dayjs().diff(this.startTime, 'second')
      }, 1000)
    },

    teardown() {
      clearInterval(this.tick)

      this.tick = null
      this.moves = 0
      this.clickingAllowed = true
      this.startTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
      this.currentTime = 0
      this.flippedCards = []
      this.matchedCards = []
      this.possibleImages = []

      this.$nextTick(() => {
        this.setup()
        this.startGame()
      })
    },

    numberOfColumns() {
      if (this.numberOfCards === 100) {
        if (this.$voix.breakpoint.value === 'default' || this.$voix.breakpoint.value === 'sm')
          return 8

        return 10
      }
      else if (this.numberOfCards === 64) {
        return 8
      }
      else if (this.numberOfCards === 36) {
        if (this.$voix.breakpoint.value === 'default' || this.$voix.breakpoint.value === 'sm')
          return 4

        return 6
      }
      else if (this.numberOfCards === 16) {
        return 4
      }
      else if (this.numberOfCards === 4) {
        return 2
      }
    },

    // This determines if it is a "dark space" on a checkboard pattern so we can alternate
    // logos on the back of the cards.
    isDarkSpace(spaceNumber) {
      const numberOfColumns = this.numberOfColumns()

      const row = Math.floor(spaceNumber / numberOfColumns)
      const col = spaceNumber % numberOfColumns

      if ((row + col) % 2 === 0)
        return true

      return false
    },

    resetGame() {
      this.teardown()
      this.showWinningModal = false
      this.showGetStartedModal = false
      this.scoreSubmitted = false
    },

    flip(n) {
      if (
        this.clickingAllowed
        && !this.flippedCards.includes(n)
        && !this.matchedCards.includes(n)
      ) {
        this.moves += 1
        this.flippedCards.push(n)

        if (this.flippedCards.length > 1) {
          this.clickingAllowed = false

          setTimeout(() => {
            if (
              this.shuffledImages[this.flippedCards[0]].id
              === this.shuffledImages[this.flippedCards[1]].id
            ) {
              this.matchedCards.push(...this.flippedCards)
            }

            this.flippedCards = []
            this.clickingAllowed = true
          }, 750)
        }
      }
    },

    getHighScores() {
      const difficulty = this.slugify(this.difficulty)
      $voixFetch(`/api/devise/leader-boards/${difficulty}?order=time&dir=asc`)
        .then(response => response.json())
        .then((response) => {
          this.highScores.time = response.data
        })

      $voixFetch(`/api/devise/leader-boards/${difficulty}?order=moves&dir=asc`)
        .then(response => response.json())
        .then((response) => {
          this.highScores.moves = response.data
        })
    },

    submitScore() {
      $voixFetch(`/api/devise/leader-boards`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          initials: this.scoreSubmission.initials,
          email: this.scoreSubmission.email,
          difficulty: this.slugify(this.difficulty),
          moves: this.winningStats.moves,
          time: this.winningStats.time,
          agree: this.scoreSubmission.agree,
          email_opt_in: this.scoreSubmission.email_opt_in,
        }),
      })
        .then(response => response.json())
        .then(() => {
          this.scoreSubmitted = true
          this.getHighScores()
        })
    },

    slugify(str) {
      if (!str)
        return ''

      return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')
    },

    formatSeconds(seconds) {
      return dayjs.utc(seconds * 1000).format('mm:ss')
    },

    requestFullscreen() {
      const element = this.$el

      if (element.requestFullscreen) {
        element.requestFullscreen()
        this.isFullscreen = true
      }
      else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
        this.isFullscreen = true
      }
      else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
        this.isFullscreen = true
      }
      else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
        this.isFullscreen = true
      }
    },

    requestExitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
        this.isFullscreen = false
      }
      else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
        this.isFullscreen = false
      }
      else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
        this.isFullscreen = false
      }
      else if (document.msExitFullscreen) {
        document.msExitFullscreen()
        this.isFullscreen = false
      }
    },

    toggleFullscreen() {
      if (this.isFullscreen)
        this.requestExitFullscreen()
      else
        this.requestFullscreen()
    },
  },
}
</script>

<template>
  <div id="memory-game" class=" relative flex flex-col lg:flex-row justify-center py-12 ">
    <div class="container font-sans2 font-light">
      <div
        class="flex flex-col lg:flex-row space-y-4 items-center justify-between bg-orange-300 p-4 border-b-4 border-white"
      >
        <div class="grid grid-cols-2 gap-4 lg:flex lg:items-center lg:space-x-8 space-x-4 text-lg">
          <div class="flex items-center space-x-2">
            <div class="text-sm uppercase font-bold text-gray-600">
              Moves:
            </div>
            <div class="bg-white px-4 py-1.5 rounded-lg font-bold text-glueblue-600 text-2xl">
              {{ moves }}
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="text-sm uppercase font-bold text-gray-600">
              Time:
            </div>
            <div class="bg-white px-4 py-1.5 rounded-lg font-bold text-glueblue-600 text-2xl">
              {{ formattedCurrentTime }}
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="text-sm uppercase font-bold text-gray-600">
              Pairs:
            </div>
            <div class="bg-white px-4 py-1.5 rounded-lg font-bold text-glueblue-600 text-2xl">
              {{ numberOfCards / 2 }}
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="text-sm uppercase font-bold text-gray-600">
              Difficulty Level:
            </div>
            <select
              v-model="numberOfCards"
              class="text-sm bg-orange-100 py-2 px-4 rounded text-lg text-glueblue-600 font-bold"
            >
              <option :value="4">
                Very Easy
              </option>
              <option :value="16">
                Easy
              </option>
              <option :value="36">
                Normal
              </option>
              <option :value="64">
                Hard
              </option>
              <option :value="100">
                Incredibly Hard
              </option>
            </select>
          </div>
        </div>

        <div>
          <button class="-mt-4 btn-glue bg-glueblue-600 text-white px-6 py-2" @click="toggleFullscreen">
            {{ isFullscreen ? `ExitFullscreen` : 'EnterFullscreen' }}
          </button>
        </div>
      </div>

      <div
        class="grid gap-2 p-4 bg-orange-100 " :class="{
          'grid-cols-8 lg:grid-cols-10': numberOfCards === 100,
          'grid-cols-8': numberOfCards === 64,
          'grid-cols-4 md:grid-cols-6': numberOfCards === 36,
          'grid-cols-4': numberOfCards === 16,
          'grid-cols-2': numberOfCards === 4,
          'overflow-y-scroll max-h-[80vh]': isFullscreen,
        }"
      >
        <div
          v-for="n in numberOfCards" :key="n" class="relative bg-gray-200 text-white" :class="{
            'h-16 md:h-20': numberOfCards === 100,
            'h-16 md:h-24': numberOfCards === 64,
            'h-16 md:h-28': numberOfCards === 36,
            'h-16 md:h-32': numberOfCards === 16,
            'h-24 md:h-32 lg:h-96': numberOfCards === 4,
            'cursor-pointer':
              !flippedCards.includes(n - 1)
              || !matchedCards.includes(n - 1)
              || clickingAllowed,
            'cursor-not-allowed':
              flippedCards.includes(n - 1)
              || matchedCards.includes(n - 1)
              || !clickingAllowed,
          }" @click="flip(n - 1)"
        >
          <div
            class="absolute inset-0 bg-glueblue-600 rounded-lg overflow-hidden flex items-center justify-center" style="
              perspective: 1000px;
              backface-visibility: hidden;
              transform-style: preserve-3d;
              transition: transform 0.5s ease-in-out;
            " :style="{
              transform: `rotateY(${flippedCards.includes(n - 1) || matchedCards.includes(n - 1)
                ? 180
                : 0
                }deg)`,
            }"
          >
            <div class="relative w-full h-full">
              <!-- Holiday Cards -->
              <img v-if="isDarkSpace(n - 1)" src="/imgs/memory-game/backs-holiday-dark.jpg" class="w-full h-full object-cover">
              <img v-else src="/imgs/memory-game/backs-holiday-light.jpg" class="w-full h-full object-cover">
              <div
                class="absolute inset-0 flex justify-center items-center" :class="{
                  'text-glueblue-700': !isDarkSpace(n - 1),
                }"
              >
                <GlueSvgsAtlantisLogo class="w-1/2" />
              </div>
              <!-- regular cards  -->
              <!-- <div class="absolute inset-0 flex justify-center items-center" :class="{
                'md:p-8': numberOfCards <= 35,
                'md:p-2': numberOfCards > 34,
              }">
                <glue-svgs-anniversary-logo v-if="isDarkSpace(n - 1)" class="w-1/2"></glue-svgs-anniversary-logo>
                <glue-svgs-atlantis-logo v-else class="w-1/2"></glue-svgs-atlantis-logo>
              </div> -->
            </div>
          </div>
          <div
            class="absolute inset-0 rounded-lg overflow-hidden transform" :class="{
              'scale-75': matchedCards.includes(n - 1),
            }" style="
              perspective: 1000px;
              backface-visibility: hidden;
              transform-style: preserve-3d;
              transition: transform 0.5s ease-in-out;
            " :style="{
              transform: `rotateY(${flippedCards.includes(n - 1) || matchedCards.includes(n - 1)
                ? 0
                : 180
                }deg)`,
          }"
          >
            <img v-if="shuffledImages[n - 1]" :src="shuffledImages[n - 1].img" class="object-cover w-full h-full">
          </div>
        </div>
      </div>
    </div>

    <div v-if="showGetStartedModal" class="absolute inset-0 flex justify-center items-center backdrop-blur-sm">
      <div class="bg-white p-12 w-full font-sans2 flex flex-col items-center">
        <div class="font-sans text-2xl font-bold uppercase text-center">
          Ready?
        </div>
        <p>Match the pairs. See how fast you can get them all!</p>
        <p>
          <a class="underline text-glueblue-600" :href="devise.fields.instructions.value?.href" :target="devise.fields.instructions.value.target">
            {{ devise.fields.instructions.value.text }}
          </a>
        </p>
        <div class="mt-8">
          <div class="text-sm text-center uppercase font-bold text-gray-600">
            Difficulty Level
          </div>
          <select
            v-model.number="numberOfCards"
            class="text-sm text-center bg-orange-100 py-2 px-4 rounded text-lg text-glueblue-600 font-bold"
          >
            <option :value="4">
              Very Easy
            </option>
            <option :value="16">
              Easy
            </option>
            <option :value="36">
              Normal
            </option>
            <option :value="64">
              Hard
            </option>
            <option :value="100">
              Incredibly Hard
            </option>
          </select>
        </div>

        <button class="mt-4 glue-btn btn-2xs bg-glueblue-600 text-white" @click="resetGame">
          Start Matching
        </button>
      </div>
    </div>

    <div v-if="showWinningModal" class="absolute left-0 right-0 top-0 mt-56 lg:hidden flex justify-center items-center">
      <div class="bg-white p-8 lg:p-20 pb-12 font-sans2 w-full flex flex-col items-center">
        <div class="font-sans text-4xl font-light uppercase text-center">
          You win!
        </div>
        <a href="#score-details" class="mt-2 md:mt-0 glue-btn btn-2xs bg-glueblue-600 text-white">
          See Score Details
        </a>
      </div>
    </div>

    <div v-if="showWinningModal" id="score-details" class="lg:absolute lg:inset-0 flex justify-center items-center">
      <div class="bg-white p-8 lg:p-20 pb-12 font-sans2 flex flex-col items-center">
        <div class="font-sans text-2xl lg:text-4xl font-light uppercase text-center">
          Congratulations!
        </div>
        <div class="mt-6 grid grid-cols-2 gap-y-4 gap-x-4">
          <div class="bg-glueblue-400 text-white px-4 lg:px-12 py-4 text-center font-sans uppercase">
            <div class="font-light text-xs">
              Your Time
            </div>
            <div class="text-2xl">
              {{ formatSeconds(winningStats.time) }}
            </div>
          </div>
          <div class="bg-glueblue-400 text-white px-4 lg:px-12 py-4 text-center font-sans uppercase">
            <div class="font-light text-xs">
              Your Moves
            </div>
            <div class="text-2xl">
              {{ winningStats.moves }}
            </div>
          </div>
        </div>

        <div class="mt-8 pt-4 border-t border-glueblue-300 grid md:grid-cols-2 gap-x-8 gap-y-6">
          <div>
            <div class="mb-4 font-sans uppercase text-xs border-b border-glueblue-300">
              Best Time on {{ difficulty }} Difficulty
            </div>
            <div v-for="(score, key) in topTenHighScoresTime" :key="key" class="flex justify-between">
              <div class="font-bold text-glueblue-600 uppercase">
                #{{ key + 1 }}
              </div>
              <div class="font-bold text-glueblue-600 uppercase">
                {{ score.initials }}
              </div>
              <div>
                {{ formatSeconds(score.time) }}
              </div>
            </div>
          </div>
          <div>
            <div class="mb-4 font-sans uppercase text-xs border-b border-glueblue-300">
              Least Moves on Normal Difficulty
            </div>
            <div v-for="(score, key) in topTenHighScoresMoves" :key="key" class="flex justify-between">
              <div class="font-bold text-glueblue-600 uppercase">
                #{{ key + 1 }}
              </div>
              <div class="font-bold text-glueblue-600 uppercase">
                {{ score.initials }}
              </div>
              <div>{{ score.moves }}</div>
            </div>
          </div>
        </div>

        <div class="mt-5 text-lg text-center">
          Submit your score to the leaderboard
        </div>

        <template v-if="!scoreSubmitted">
          <div class="mt-2 flex flex-col md:flex-row items-center justify-center">
            <input
              v-model="scoreSubmission.initials" type="text" maxlength="3"
              class="bg-orange-50 text-zinc-500 px-4 py-3 mr-1 md:w-24" placeholder="Initials"
            >
            <input
              v-model="scoreSubmission.email" type="email"
              class="mt-2 md:mt-0 bg-orange-50 text-zinc-500 px-4 py-3 mr-1" placeholder="Your Email"
            >
            <button
              class="mt-2 md:mt-0 glue-btn btn-2xs bg-glueblue-600 text-white" :disabled="!scoreSubmissionValid"
              @click="submitScore"
            >
              Submit
            </button>
          </div>
          <div class="mt-1 text-zinc-500 text-sm">
            <label class="flex items-center space-x-2">
              <input v-model="scoreSubmission.agree" type="checkbox">
              <span v-html="devise.fields.terms.value" />
            </label>
            <label class="flex items-center space-x-2">
              <input v-model="scoreSubmission.email_opt_in" type="checkbox">
              <span v-html="devise.fields.emailOptInText.value" />
            </label>
          </div>
        </template>

        <div v-if="scoreSubmitted">
          Score Submitted!
        </div>

        <button class="glue-btn text-xs font-bold mt-12 border border-glueblue-600 text-glueblue-600 " @click="resetGame">
          Play Again
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
#memory-game:fullscreen {
  @apply bg-glueblue-700 shadow-2xl;
}
</style>
