<script setup lang="ts">
import type { FieldInterface, SliceInterface, VoixLinkFieldInterface, VoixSelectFieldInterface } from '@voix/types'
import { resolveFieldsArray } from '@voix/composables/useField'

const props = defineProps<{
  slice: SliceInterface
  fields: {
    units: VoixSelectFieldInterface
    link: VoixLinkFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Gallery Container', group: 'Galleries', layouts: ['Atlantisbahamas', 'Barbie'] },
  slots: [{ name: 'default', allowedElements: ['SlicesGalleryGalleryImage'] }],
  description: 'Container for gallery slices to make a grid gallery',
  tags: ['Gallery'],
  preview: 'SlicesGalleryGalleryContainer.jpg',
  fields: {
    units: {
      type: 'select',
      label: 'Gallery Units',
      options: {
        thirds: 'Thirds',
        fourths: 'Fourths',
      },
      default: 'thirds',
    },
    link: { type: 'link', label: 'Link', enabled: false },
  },
  templates: [{
    label: 'Gallery Container',
    fields: {
      units: { value: 'thirds' },

    },
  }],
})

const processedSliceFields = []
props.slice.elements?.forEach((slice) => {
  processedSliceFields.push(resolveFieldsArray(slice))
})
</script>

<template>
  <div class="flex justify-center">
    <div class="container w-full text-center mb-24">
      <div class="clearfix photo-grid md:grid grid-cols-12">
        <VoixSlice
          v-for="(element, key) in props.slice.elements" :key="element.id" :slice="element" :class="{
            'md:col-span-3': !processedSliceFields[key].size || processedSliceFields[key].size?.value === 'grid-item--onefourth',
            'md:col-span-4': processedSliceFields[key].size?.value === 'grid-item--onethird',
            'md:col-span-6': processedSliceFields[key].size?.value === 'grid-item--half',
            'md:col-span-8': processedSliceFields[key].size?.value === 'grid-item--twothirds',
            'md:col-span-12': processedSliceFields[key].size?.value === 'grid-item--full',
          }"
        />
      </div>
      <a
        v-if="fields.link.enabled" class="btn btn-2xs text-white bg-black relative z-10"
        style="top:-2.5em"
        :href="fields.link.value?.href"
        :target="fields.link.value?.target"
      >{{ fields.link.value?.text }}</a>
    </div>
  </div>
</template>
