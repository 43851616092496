<script setup lang="ts">
import type { VoixMediaFieldInterface } from '@voix/types'

const { imageAspect = 'auto' } = defineProps<{
  image?: VoixMediaFieldInterface
  imageAspect?: 'video' | 'square' | 'tall' | 'auto'
  title?: string | null
  description?: string | null
  url?: string | null
}>()

const target = ref(null)
const { isOutside } = useMouseInElement(target)
</script>

<template>
  <div ref="target" class="flex flex-col space-y-6" :class="{ 'cursor-pointer': url }">
    <div
      v-if="image" class="relative " :class="{
        'aspect-video': imageAspect === 'video',
        'aspect-square': imageAspect === 'square',
        'aspect-[3/4]': imageAspect === 'tall',
        'aspect-auto': imageAspect === 'auto',
      }"
    >
      <VoixMedia
        v-if="image.enabled"
        :field="image"
        class="object-cover h-full w-full"
        :class="{ absolute: imageAspect !== 'auto' }"
        width="600" height="600"
      />
    </div>

    <div class="flex flex-col space-y-2">
      <div v-if="title" class="text-sm uppercase font-medium text-zinc-400">
        {{ title }}
      </div>
      <div
        v-if="description"
        class="mt-3 prose prose-sm prose-zinc leading-snug font-light"
        width="600"
        height="600" v-html="description"
      />

      <div v-if="url" class="pt-4 flex justify-end">
        <a :href="url" class="duration-500 ease-out" :class="{ 'translate-x-4': !isOutside }">
          <RestaurantMicroChromeParanzaArrow class="text-paranza-400 h-6 w-16" />
        </a>
      </div>
    </div>
  </div>
</template>
