<script lang="ts" setup>
import type { VoixWysiwygFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    terms: VoixWysiwygFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Special Offers Terms', group: 'Specials', layouts: ['Atlantisbahamas'] },
  description: 'Special Offers Terms & Conditions Slice',
  tags: ['Special', 'Terms', 'Copy'],

  fields: {
    terms: {
      type: 'wysiwyg',
      label: 'Terms & Conditions',
    },
  },
  preview: 'SlicesSpecialOffersTerms.jpg',
  templates: [{
    label: 'Special Offers Terms & Conditions',
    fields: {},
  }],
})

const showTerms = ref(false)
</script>

<template>
  <div class="w-full h-full pt-0 px-16">
    <!-- Terms Anchor -->
    <div id="terms" class="w-full h-24" />

    <!-- Terms -->
    <div class="w-full h-full">
      <!-- Accordion -->
      <div
        class="flex flex-row justify-between items-center w-full h-full p-2 border-b-[1px] border-[#c8c6c0] cursor-pointer"
        @click="showTerms = !showTerms"
      >
        <p class="text-sm font-medium uppercase ">
          Terms & Conditions
        </p>

        <Icon
          v-if="!showTerms"
          class="w-8 h-8 text-black"
          name="heroicons:chevron-down-20-solid"
        />

        <Icon
          v-if="showTerms"
          class="w-8 h-8 text-black"
          name="heroicons:chevron-up-20-solid"
        />
      </div>

      <!-- Content -->
      <VoixWysiwyg
        class="w-full font-light duration-500 delay-200 ease-out overflow-hidden interpolate-size prose-li:ml-5 prose-li:p-1 prose-li:list-disc"
        :class="{
          'h-0 opacity-0': !showTerms,
          'h-fit opacity-100 py-4': showTerms,
        }"
        :field="fields.terms"
      />
    </div>
  </div>
</template>

<style scoped>
.interpolate-size {
  interpolate-size: allow-keywords;
}
</style>
