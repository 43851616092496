<script>
export default {}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 442.47 86.23">
    <g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M174.33,40.88h1.16v5.83c0,16.21,13.19,29.4,29.4,29.4s29.4-13.19,29.4-29.4h1.16c0,8.16-3.18,15.84-8.95,21.61-5.77,5.77-13.45,8.95-21.61,8.95s-15.84-3.18-21.61-8.95c-5.77-5.77-8.95-13.45-8.95-21.61v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M177.27,40.88h1.16v5.83c0,14.58,11.86,26.45,26.45,26.45s26.45-11.86,26.45-26.45h1.16c0,7.37-2.87,14.31-8.09,19.52-5.21,5.21-12.15,8.09-19.52,8.09s-14.31-2.87-19.52-8.09c-5.21-5.21-8.09-12.15-8.09-19.52v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M180.22,40.88h1.16v5.83c0,12.96,10.54,23.5,23.5,23.5s23.5-10.54,23.5-23.5h1.16c0,13.6-11.06,24.66-24.66,24.66s-24.66-11.06-24.66-24.66v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M183.17,40.88h1.16v5.83c0,11.33,9.22,20.55,20.55,20.55s20.55-9.22,20.55-20.55h1.16c0,11.97-9.74,21.71-21.71,21.71s-21.71-9.74-21.71-21.71v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M186.12,40.88h1.16v5.83c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6h1.16c0,10.35-8.42,18.76-18.76,18.76s-18.76-8.42-18.76-18.76v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M189.07,40.88h1.16v5.83c0,8.08,6.57,14.65,14.65,14.65s14.65-6.57,14.65-14.65h1.16c0,8.72-7.09,15.81-15.81,15.81s-15.81-7.09-15.81-15.81v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M192.02,40.88h1.16v5.83c0,6.45,5.25,11.7,11.7,11.7s11.7-5.25,11.7-11.7h1.16c0,7.09-5.77,12.86-12.86,12.86s-12.86-5.77-12.86-12.86v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M194.97,40.88h1.16v5.83c0,4.83,3.93,8.75,8.75,8.75s8.75-3.93,8.75-8.75h1.16c0,5.47-4.45,9.92-9.92,9.92s-9.92-4.45-9.92-9.92v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M197.92,40.88h1.16v5.83c0,3.2,2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8h1.16c0,3.84-3.13,6.97-6.97,6.97s-6.97-3.13-6.97-6.97v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M200.86,40.88h1.16v5.83c0,1.57,1.28,2.86,2.86,2.86s2.86-1.28,2.86-2.86h1.16c0,2.22-1.8,4.02-4.02,4.02s-4.02-1.8-4.02-4.02v-5.83Z"
          />
        </g>
        <rect fill="currentColor" x="207.74" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="210.69" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="213.64" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="216.59" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="219.53" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="222.48" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="225.43" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="228.38" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="231.33" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="234.28" y="40.88" width="1.16" height="5.83" />
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M171.47,4.65c8.16,0,15.84,3.18,21.61,8.95,5.77,5.77,8.95,13.45,8.95,21.61v5.83h-1.16v-5.83c0-16.21-13.19-29.4-29.4-29.4s-29.4,13.19-29.4,29.4h-1.16c0-8.16,3.18-15.84,8.95-21.61,5.77-5.77,13.45-8.95,21.61-8.95Z"
          />
          <path
            fill="currentColor"
            d="M171.47,7.6c7.37,0,14.31,2.87,19.52,8.09,5.21,5.21,8.09,12.15,8.09,19.52v5.83h-1.16v-5.83c0-14.58-11.86-26.45-26.45-26.45s-26.45,11.86-26.45,26.45h-1.16c0-7.37,2.87-14.31,8.09-19.52,5.21-5.21,12.15-8.09,19.52-8.09Z"
          />
          <path
            fill="currentColor"
            d="M171.47,10.55c13.6,0,24.66,11.06,24.66,24.66v5.83h-1.16v-5.83c0-12.96-10.54-23.5-23.5-23.5s-23.5,10.54-23.5,23.5h-1.16c0-13.6,11.06-24.66,24.66-24.66Z"
          />
          <path
            fill="currentColor"
            d="M171.47,13.5c11.97,0,21.71,9.74,21.71,21.71v5.83h-1.16v-5.83c0-11.33-9.22-20.55-20.55-20.55s-20.55,9.22-20.55,20.55h-1.16c0-11.97,9.74-21.71,21.71-21.71Z"
          />
          <path
            fill="currentColor"
            d="M171.47,16.45c10.35,0,18.76,8.42,18.76,18.76v5.83h-1.16v-5.83c0-9.7-7.9-17.6-17.6-17.6s-17.6,7.9-17.6,17.6h-1.16c0-10.35,8.42-18.76,18.76-18.76Z"
          />
          <path
            fill="currentColor"
            d="M171.47,19.4c8.72,0,15.81,7.09,15.81,15.81v5.83h-1.16v-5.83c0-8.08-6.57-14.65-14.65-14.65s-14.65,6.57-14.65,14.65h-1.16c0-8.72,7.09-15.81,15.81-15.81Z"
          />
          <path
            fill="currentColor"
            d="M171.47,22.35c7.09,0,12.86,5.77,12.86,12.86v5.83h-1.16v-5.83c0-6.45-5.25-11.7-11.7-11.7s-11.7,5.25-11.7,11.7h-1.16c0-7.09,5.77-12.86,12.86-12.86Z"
          />
          <path
            fill="currentColor"
            d="M171.47,25.29c5.47,0,9.92,4.45,9.92,9.92v5.83h-1.16v-5.83c0-4.83-3.93-8.75-8.75-8.75s-8.75,3.93-8.75,8.75h-1.16c0-5.47,4.45-9.92,9.92-9.92Z"
          />
          <path
            fill="currentColor"
            d="M171.47,28.24c3.84,0,6.97,3.13,6.97,6.97v5.83h-1.16v-5.83c0-3.2-2.6-5.8-5.8-5.8s-5.8,2.6-5.8,5.8h-1.16c0-3.84,3.13-6.97,6.97-6.97Z"
          />
          <path
            fill="currentColor"
            d="M171.47,31.19c2.22,0,4.02,1.8,4.02,4.02v5.83h-1.16v-5.83c0-1.57-1.28-2.86-2.86-2.86s-2.86,1.28-2.86,2.86h-1.16c0-2.22,1.8-4.02,4.02-4.02Z"
          />
        </g>
        <rect fill="currentColor" x="167.45" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="164.5" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="161.55" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="158.6" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="155.66" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="152.71" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="149.76" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="146.81" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="143.86" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="140.91" y="35.21" width="1.16" height="5.83" />
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M107.5,40.88h1.16v5.83c0,16.21,13.19,29.4,29.4,29.4s29.4-13.19,29.4-29.4h1.16c0,8.16-3.18,15.84-8.95,21.61-5.77,5.77-13.45,8.95-21.61,8.95s-15.84-3.18-21.61-8.95c-5.77-5.77-8.95-13.45-8.95-21.61v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M110.45,40.88h1.16v5.83c0,14.58,11.86,26.45,26.45,26.45s26.45-11.86,26.45-26.45h1.16c0,7.37-2.87,14.31-8.09,19.52-5.21,5.21-12.15,8.09-19.52,8.09s-14.31-2.87-19.52-8.09c-5.21-5.21-8.09-12.15-8.09-19.52v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M113.4,40.88h1.16v5.83c0,12.96,10.54,23.5,23.5,23.5s23.5-10.54,23.5-23.5h1.16c0,13.6-11.06,24.66-24.66,24.66s-24.66-11.06-24.66-24.66v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M116.34,40.88h1.16v5.83c0,11.33,9.22,20.55,20.55,20.55s20.55-9.22,20.55-20.55h1.16c0,11.97-9.74,21.71-21.71,21.71s-21.71-9.74-21.71-21.71v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M119.29,40.88h1.16v5.83c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6h1.16c0,10.35-8.42,18.76-18.76,18.76s-18.76-8.42-18.76-18.76v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M122.24,40.88h1.16v5.83c0,8.08,6.57,14.65,14.65,14.65s14.65-6.57,14.65-14.65h1.16c0,8.72-7.09,15.81-15.81,15.81s-15.81-7.09-15.81-15.81v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M125.19,40.88h1.16v5.83c0,6.45,5.25,11.7,11.7,11.7s11.7-5.25,11.7-11.7h1.16c0,7.09-5.77,12.86-12.86,12.86s-12.86-5.77-12.86-12.86v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M128.14,40.88h1.16v5.83c0,4.83,3.93,8.75,8.75,8.75s8.75-3.93,8.75-8.75h1.16c0,5.47-4.45,9.92-9.92,9.92s-9.92-4.45-9.92-9.92v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M131.09,40.88h1.16v5.83c0,3.2,2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8h1.16c0,3.84-3.13,6.97-6.97,6.97s-6.97-3.13-6.97-6.97v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M134.04,40.88h1.16v5.83c0,1.57,1.28,2.86,2.86,2.86s2.86-1.28,2.86-2.86h1.16c0,2.22-1.8,4.02-4.02,4.02s-4.02-1.8-4.02-4.02v-5.83Z"
          />
        </g>
        <rect fill="currentColor" x="140.91" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="143.86" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="146.81" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="149.76" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="152.71" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="155.66" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="158.6" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="161.55" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="164.5" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="167.45" y="40.88" width="1.16" height="5.83" />
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M104.64,4.65c8.16,0,15.84,3.18,21.61,8.95,5.77,5.77,8.95,13.45,8.95,21.61v5.83h-1.16v-5.83c0-16.21-13.19-29.4-29.4-29.4s-29.4,13.19-29.4,29.4h-1.16c0-8.16,3.18-15.84,8.95-21.61,5.77-5.77,13.45-8.95,21.61-8.95Z"
          />
          <path
            fill="currentColor"
            d="M104.64,7.6c7.37,0,14.31,2.87,19.52,8.09,5.21,5.21,8.09,12.15,8.09,19.52v5.83h-1.16v-5.83c0-14.58-11.86-26.45-26.45-26.45s-26.45,11.86-26.45,26.45h-1.16c0-7.37,2.87-14.31,8.09-19.52,5.21-5.21,12.15-8.09,19.52-8.09Z"
          />
          <path
            fill="currentColor"
            d="M104.64,10.55c13.6,0,24.66,11.06,24.66,24.66v5.83h-1.16v-5.83c0-12.96-10.54-23.5-23.5-23.5s-23.5,10.54-23.5,23.5h-1.16c0-13.6,11.06-24.66,24.66-24.66Z"
          />
          <path
            fill="currentColor"
            d="M104.64,13.5c11.97,0,21.71,9.74,21.71,21.71v5.83h-1.16v-5.83c0-11.33-9.22-20.55-20.55-20.55s-20.55,9.22-20.55,20.55h-1.16c0-11.97,9.74-21.71,21.71-21.71Z"
          />
          <path
            fill="currentColor"
            d="M104.64,16.45c10.35,0,18.76,8.42,18.76,18.76v5.83h-1.16v-5.83c0-9.7-7.9-17.6-17.6-17.6s-17.6,7.9-17.6,17.6h-1.16c0-10.35,8.42-18.76,18.76-18.76Z"
          />
          <path
            fill="currentColor"
            d="M104.64,19.4c8.72,0,15.81,7.09,15.81,15.81v5.83h-1.16v-5.83c0-8.08-6.57-14.65-14.65-14.65s-14.65,6.57-14.65,14.65h-1.16c0-8.72,7.09-15.81,15.81-15.81Z"
          />
          <path
            fill="currentColor"
            d="M104.64,22.35c7.09,0,12.86,5.77,12.86,12.86v5.83h-1.16v-5.83c0-6.45-5.25-11.7-11.7-11.7s-11.7,5.25-11.7,11.7h-1.16c0-7.09,5.77-12.86,12.86-12.86Z"
          />
          <path
            fill="currentColor"
            d="M104.64,25.29c5.47,0,9.92,4.45,9.92,9.92v5.83h-1.16v-5.83c0-4.83-3.93-8.75-8.75-8.75s-8.75,3.93-8.75,8.75h-1.16c0-5.47,4.45-9.92,9.92-9.92Z"
          />
          <path
            fill="currentColor"
            d="M104.64,28.24c3.84,0,6.97,3.13,6.97,6.97v5.83h-1.16v-5.83c0-3.2-2.6-5.8-5.8-5.8s-5.8,2.6-5.8,5.8h-1.16c0-3.84,3.13-6.97,6.97-6.97Z"
          />
          <path
            fill="currentColor"
            d="M104.64,31.19c2.22,0,4.02,1.8,4.02,4.02v5.83h-1.16v-5.83c0-1.57-1.28-2.86-2.86-2.86s-2.86,1.28-2.86,2.86h-1.16c0-2.22,1.8-4.02,4.02-4.02Z"
          />
        </g>
        <rect fill="currentColor" x="100.62" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="97.68" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="94.73" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="91.78" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="88.83" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="85.88" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="82.93" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="79.98" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="77.03" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="74.08" y="35.21" width="1.16" height="5.83" />
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M40.65,40.88h1.16v5.83c0,16.21,13.19,29.4,29.4,29.4s29.4-13.19,29.4-29.4h1.16c0,8.16-3.18,15.84-8.95,21.61-5.77,5.77-13.45,8.95-21.61,8.95s-15.84-3.18-21.61-8.95c-5.77-5.77-8.95-13.45-8.95-21.61v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M43.6,40.88h1.16v5.83c0,14.58,11.86,26.45,26.45,26.45s26.45-11.86,26.45-26.45h1.16c0,7.37-2.87,14.31-8.09,19.52-5.21,5.21-12.15,8.09-19.52,8.09s-14.31-2.87-19.52-8.09c-5.21-5.21-8.09-12.15-8.09-19.52v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M46.55,40.88h1.16v5.83c0,12.96,10.54,23.5,23.5,23.5s23.5-10.54,23.5-23.5h1.16c0,13.6-11.06,24.66-24.66,24.66s-24.66-11.06-24.66-24.66v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M49.5,40.88h1.16v5.83c0,11.33,9.22,20.55,20.55,20.55s20.55-9.22,20.55-20.55h1.16c0,11.97-9.74,21.71-21.71,21.71s-21.71-9.74-21.71-21.71v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M52.45,40.88h1.16v5.83c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6h1.16c0,10.35-8.42,18.76-18.76,18.76s-18.76-8.42-18.76-18.76v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M55.4,40.88h1.16v5.83c0,8.08,6.57,14.65,14.65,14.65s14.65-6.57,14.65-14.65h1.16c0,8.72-7.09,15.81-15.81,15.81s-15.81-7.09-15.81-15.81v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M58.34,40.88h1.16v5.83c0,6.45,5.25,11.7,11.7,11.7s11.7-5.25,11.7-11.7h1.16c0,7.09-5.77,12.86-12.86,12.86s-12.86-5.77-12.86-12.86v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M61.29,40.88h1.16v5.83c0,4.83,3.93,8.75,8.75,8.75s8.75-3.93,8.75-8.75h1.16c0,5.47-4.45,9.92-9.92,9.92s-9.92-4.45-9.92-9.92v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M64.24,40.88h1.16v5.83c0,3.2,2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8h1.16c0,3.84-3.13,6.97-6.97,6.97s-6.97-3.13-6.97-6.97v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M67.19,40.88h1.16v5.83c0,1.57,1.28,2.86,2.86,2.86s2.86-1.28,2.86-2.86h1.16c0,2.22-1.8,4.02-4.02,4.02s-4.02-1.8-4.02-4.02v-5.83Z"
          />
        </g>
        <rect fill="currentColor" x="74.06" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="77.01" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="79.96" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="82.91" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="85.86" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="88.81" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="91.76" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="94.71" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="97.66" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="100.6" y="40.88" width="1.16" height="5.83" />
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M37.79,4.65c8.16,0,15.84,3.18,21.61,8.95,5.77,5.77,8.95,13.45,8.95,21.61v5.83h-1.16v-5.83c0-16.21-13.19-29.4-29.4-29.4S8.4,19,8.4,35.21h-1.16c0-8.16,3.18-15.84,8.95-21.61,5.77-5.77,13.45-8.95,21.61-8.95Z"
          />
          <path
            fill="currentColor"
            d="M37.79,7.6c7.37,0,14.31,2.87,19.52,8.09,5.21,5.21,8.09,12.15,8.09,19.52v5.83h-1.16v-5.83c0-14.58-11.86-26.45-26.45-26.45S11.35,20.63,11.35,35.21h-1.16c0-7.37,2.87-14.31,8.09-19.52,5.21-5.21,12.15-8.09,19.52-8.09Z"
          />
          <path
            fill="currentColor"
            d="M37.79,10.55c13.6,0,24.66,11.06,24.66,24.66v5.83h-1.16v-5.83c0-12.96-10.54-23.5-23.5-23.5S14.3,22.25,14.3,35.21h-1.16c0-13.6,11.06-24.66,24.66-24.66Z"
          />
          <path
            fill="currentColor"
            d="M37.79,13.5c11.97,0,21.71,9.74,21.71,21.71v5.83h-1.16v-5.83c0-11.33-9.22-20.55-20.55-20.55s-20.55,9.22-20.55,20.55h-1.16c0-11.97,9.74-21.71,21.71-21.71Z"
          />
          <path
            fill="currentColor"
            d="M37.79,16.45c10.35,0,18.76,8.42,18.76,18.76v5.83h-1.16v-5.83c0-9.7-7.9-17.6-17.6-17.6s-17.6,7.9-17.6,17.6h-1.16c0-10.35,8.42-18.76,18.76-18.76Z"
          />
          <path
            fill="currentColor"
            d="M37.79,19.4c8.72,0,15.81,7.09,15.81,15.81v5.83h-1.16v-5.83c0-8.08-6.57-14.65-14.65-14.65s-14.65,6.57-14.65,14.65h-1.16c0-8.72,7.09-15.81,15.81-15.81Z"
          />
          <path
            fill="currentColor"
            d="M37.79,22.35c7.09,0,12.86,5.77,12.86,12.86v5.83h-1.16v-5.83c0-6.45-5.25-11.7-11.7-11.7s-11.7,5.25-11.7,11.7h-1.16c0-7.09,5.77-12.86,12.86-12.86Z"
          />
          <path
            fill="currentColor"
            d="M37.79,25.29c5.47,0,9.92,4.45,9.92,9.92v5.83h-1.16v-5.83c0-4.83-3.93-8.75-8.75-8.75s-8.75,3.93-8.75,8.75h-1.16c0-5.47,4.45-9.92,9.92-9.92Z"
          />
          <path
            fill="currentColor"
            d="M37.79,28.24c3.84,0,6.97,3.13,6.97,6.97v5.83h-1.16v-5.83c0-3.2-2.6-5.8-5.8-5.8s-5.8,2.6-5.8,5.8h-1.16c0-3.84,3.13-6.97,6.97-6.97Z"
          />
          <path
            fill="currentColor"
            d="M37.79,31.19c2.22,0,4.02,1.8,4.02,4.02v5.83h-1.16v-5.83c0-1.57-1.28-2.86-2.86-2.86s-2.86,1.28-2.86,2.86h-1.16c0-2.22,1.8-4.02,4.02-4.02Z"
          />
        </g>
        <rect fill="currentColor" x="33.78" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="30.83" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="27.88" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="24.93" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="21.98" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="19.03" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="16.08" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="13.13" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="10.19" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="7.24" y="35.21" width="1.16" height="5.83" />
      </g>
    </g>
    <g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M374.86,40.88h1.16v5.83c0,16.21,13.19,29.4,29.4,29.4s29.4-13.19,29.4-29.4h1.16c0,8.16-3.18,15.84-8.95,21.61-5.77,5.77-13.45,8.95-21.61,8.95s-15.84-3.18-21.61-8.95c-5.77-5.77-8.95-13.45-8.95-21.61v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M377.81,40.88h1.16v5.83c0,14.58,11.86,26.45,26.45,26.45s26.45-11.86,26.45-26.45h1.16c0,7.37-2.87,14.31-8.09,19.52-5.21,5.21-12.15,8.09-19.52,8.09s-14.31-2.87-19.52-8.09c-5.21-5.21-8.09-12.15-8.09-19.52v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M380.76,40.88h1.16v5.83c0,12.96,10.54,23.5,23.5,23.5s23.5-10.54,23.5-23.5h1.16c0,13.6-11.06,24.66-24.66,24.66s-24.66-11.06-24.66-24.66v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M383.71,40.88h1.16v5.83c0,11.33,9.22,20.55,20.55,20.55s20.55-9.22,20.55-20.55h1.16c0,11.97-9.74,21.71-21.71,21.71s-21.71-9.74-21.71-21.71v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M386.66,40.88h1.16v5.83c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6h1.16c0,10.35-8.42,18.76-18.76,18.76s-18.76-8.42-18.76-18.76v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M389.61,40.88h1.16v5.83c0,8.08,6.57,14.65,14.65,14.65s14.65-6.57,14.65-14.65h1.16c0,8.72-7.09,15.81-15.81,15.81s-15.81-7.09-15.81-15.81v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M392.56,40.88h1.16v5.83c0,6.45,5.25,11.7,11.7,11.7s11.7-5.25,11.7-11.7h1.16c0,7.09-5.77,12.86-12.86,12.86s-12.86-5.77-12.86-12.86v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M395.5,40.88h1.16v5.83c0,4.83,3.93,8.75,8.75,8.75s8.75-3.93,8.75-8.75h1.16c0,5.47-4.45,9.92-9.92,9.92s-9.92-4.45-9.92-9.92v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M398.45,40.88h1.16v5.83c0,3.2,2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8h1.16c0,3.84-3.13,6.97-6.97,6.97s-6.97-3.13-6.97-6.97v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M401.4,40.88h1.16v5.83c0,1.57,1.28,2.86,2.86,2.86s2.86-1.28,2.86-2.86h1.16c0,2.22-1.8,4.02-4.02,4.02s-4.02-1.8-4.02-4.02v-5.83Z"
          />
        </g>
        <rect fill="currentColor" x="408.28" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="411.23" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="414.17" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="417.12" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="420.07" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="423.02" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="425.97" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="428.92" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="431.87" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="434.82" y="40.88" width="1.16" height="5.83" />
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M372.01,4.65c8.16,0,15.84,3.18,21.61,8.95,5.77,5.77,8.95,13.45,8.95,21.61v5.83h-1.16v-5.83c0-16.21-13.19-29.4-29.4-29.4s-29.4,13.19-29.4,29.4h-1.16c0-8.16,3.18-15.84,8.95-21.61,5.77-5.77,13.45-8.95,21.61-8.95Z"
          />
          <path
            fill="currentColor"
            d="M372.01,7.6c7.37,0,14.31,2.87,19.52,8.09,5.21,5.21,8.09,12.15,8.09,19.52v5.83h-1.16v-5.83c0-14.58-11.86-26.45-26.45-26.45s-26.45,11.86-26.45,26.45h-1.16c0-7.37,2.87-14.31,8.09-19.52,5.21-5.21,12.15-8.09,19.52-8.09Z"
          />
          <path
            fill="currentColor"
            d="M372.01,10.55c13.6,0,24.66,11.06,24.66,24.66v5.83h-1.16v-5.83c0-12.96-10.54-23.5-23.5-23.5s-23.5,10.54-23.5,23.5h-1.16c0-13.6,11.06-24.66,24.66-24.66Z"
          />
          <path
            fill="currentColor"
            d="M372.01,13.5c11.97,0,21.71,9.74,21.71,21.71v5.83h-1.16v-5.83c0-11.33-9.22-20.55-20.55-20.55s-20.55,9.22-20.55,20.55h-1.16c0-11.97,9.74-21.71,21.71-21.71Z"
          />
          <path
            fill="currentColor"
            d="M372.01,16.45c10.35,0,18.76,8.42,18.76,18.76v5.83h-1.16v-5.83c0-9.7-7.9-17.6-17.6-17.6s-17.6,7.9-17.6,17.6h-1.16c0-10.35,8.42-18.76,18.76-18.76Z"
          />
          <path
            fill="currentColor"
            d="M372.01,19.4c8.72,0,15.81,7.09,15.81,15.81v5.83h-1.16v-5.83c0-8.08-6.57-14.65-14.65-14.65s-14.65,6.57-14.65,14.65h-1.16c0-8.72,7.09-15.81,15.81-15.81Z"
          />
          <path
            fill="currentColor"
            d="M372.01,22.35c7.09,0,12.86,5.77,12.86,12.86v5.83h-1.16v-5.83c0-6.45-5.25-11.7-11.7-11.7s-11.7,5.25-11.7,11.7h-1.16c0-7.09,5.77-12.86,12.86-12.86Z"
          />
          <path
            fill="currentColor"
            d="M372.01,25.29c5.47,0,9.92,4.45,9.92,9.92v5.83h-1.16v-5.83c0-4.83-3.93-8.75-8.75-8.75s-8.75,3.93-8.75,8.75h-1.16c0-5.47,4.45-9.92,9.92-9.92Z"
          />
          <path
            fill="currentColor"
            d="M372.01,28.24c3.84,0,6.97,3.13,6.97,6.97v5.83h-1.16v-5.83c0-3.2-2.6-5.8-5.8-5.8s-5.8,2.6-5.8,5.8h-1.16c0-3.84,3.13-6.97,6.97-6.97Z"
          />
          <path
            fill="currentColor"
            d="M372.01,31.19c2.22,0,4.02,1.8,4.02,4.02v5.83h-1.16v-5.83c0-1.57-1.28-2.86-2.86-2.86s-2.86,1.28-2.86,2.86h-1.16c0-2.22,1.8-4.02,4.02-4.02Z"
          />
        </g>
        <rect fill="currentColor" x="367.99" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="365.04" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="362.09" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="359.14" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="356.19" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="353.24" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="350.3" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="347.35" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="344.4" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="341.45" y="35.21" width="1.16" height="5.83" />
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M308.04,40.88h1.16v5.83c0,16.21,13.19,29.4,29.4,29.4s29.4-13.19,29.4-29.4h1.16c0,8.16-3.18,15.84-8.95,21.61-5.77,5.77-13.45,8.95-21.61,8.95s-15.84-3.18-21.61-8.95c-5.77-5.77-8.95-13.45-8.95-21.61v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M310.98,40.88h1.16v5.83c0,14.58,11.86,26.45,26.45,26.45s26.45-11.86,26.45-26.45h1.16c0,7.37-2.87,14.31-8.09,19.52-5.21,5.21-12.15,8.09-19.52,8.09s-14.31-2.87-19.52-8.09c-5.21-5.21-8.09-12.15-8.09-19.52v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M313.93,40.88h1.16v5.83c0,12.96,10.54,23.5,23.5,23.5s23.5-10.54,23.5-23.5h1.16c0,13.6-11.06,24.66-24.66,24.66s-24.66-11.06-24.66-24.66v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M316.88,40.88h1.16v5.83c0,11.33,9.22,20.55,20.55,20.55s20.55-9.22,20.55-20.55h1.16c0,11.97-9.74,21.71-21.71,21.71s-21.71-9.74-21.71-21.71v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M319.83,40.88h1.16v5.83c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6h1.16c0,10.35-8.42,18.76-18.76,18.76s-18.76-8.42-18.76-18.76v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M322.78,40.88h1.16v5.83c0,8.08,6.57,14.65,14.65,14.65s14.65-6.57,14.65-14.65h1.16c0,8.72-7.09,15.81-15.81,15.81s-15.81-7.09-15.81-15.81v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M325.73,40.88h1.16v5.83c0,6.45,5.25,11.7,11.7,11.7s11.7-5.25,11.7-11.7h1.16c0,7.09-5.77,12.86-12.86,12.86s-12.86-5.77-12.86-12.86v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M328.68,40.88h1.16v5.83c0,4.83,3.93,8.75,8.75,8.75s8.75-3.93,8.75-8.75h1.16c0,5.47-4.45,9.92-9.92,9.92s-9.92-4.45-9.92-9.92v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M331.63,40.88h1.16v5.83c0,3.2,2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8h1.16c0,3.84-3.13,6.97-6.97,6.97s-6.97-3.13-6.97-6.97v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M334.58,40.88h1.16v5.83c0,1.57,1.28,2.86,2.86,2.86s2.86-1.28,2.86-2.86h1.16c0,2.22-1.8,4.02-4.02,4.02s-4.02-1.8-4.02-4.02v-5.83Z"
          />
        </g>
        <rect fill="currentColor" x="341.45" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="344.4" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="347.35" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="350.3" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="353.24" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="356.19" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="359.14" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="362.09" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="365.04" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="367.99" y="40.88" width="1.16" height="5.83" />
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M305.18,4.65c8.16,0,15.84,3.18,21.61,8.95,5.77,5.77,8.95,13.45,8.95,21.61v5.83h-1.16v-5.83c0-16.21-13.19-29.4-29.4-29.4s-29.4,13.19-29.4,29.4h-1.16c0-8.16,3.18-15.84,8.95-21.61s13.45-8.95,21.61-8.95Z"
          />
          <path
            fill="currentColor"
            d="M305.18,7.6c7.37,0,14.31,2.87,19.52,8.09,5.21,5.21,8.09,12.15,8.09,19.52v5.83h-1.16v-5.83c0-14.58-11.86-26.45-26.45-26.45s-26.45,11.86-26.45,26.45h-1.16c0-7.37,2.87-14.31,8.09-19.52,5.21-5.21,12.15-8.09,19.52-8.09Z"
          />
          <path
            fill="currentColor"
            d="M305.18,10.55c13.6,0,24.66,11.06,24.66,24.66v5.83h-1.16v-5.83c0-12.96-10.54-23.5-23.5-23.5s-23.5,10.54-23.5,23.5h-1.16c0-13.6,11.06-24.66,24.66-24.66Z"
          />
          <path
            fill="currentColor"
            d="M305.18,13.5c11.97,0,21.71,9.74,21.71,21.71v5.83h-1.16v-5.83c0-11.33-9.22-20.55-20.55-20.55s-20.55,9.22-20.55,20.55h-1.16c0-11.97,9.74-21.71,21.71-21.71Z"
          />
          <path
            fill="currentColor"
            d="M305.18,16.45c10.35,0,18.76,8.42,18.76,18.76v5.83h-1.16v-5.83c0-9.7-7.9-17.6-17.6-17.6s-17.6,7.9-17.6,17.6h-1.16c0-10.35,8.42-18.76,18.76-18.76Z"
          />
          <path
            fill="currentColor"
            d="M305.18,19.4c8.72,0,15.81,7.09,15.81,15.81v5.83h-1.16v-5.83c0-8.08-6.57-14.65-14.65-14.65s-14.65,6.57-14.65,14.65h-1.16c0-8.72,7.09-15.81,15.81-15.81Z"
          />
          <path
            fill="currentColor"
            d="M305.18,22.35c7.09,0,12.86,5.77,12.86,12.86v5.83h-1.16v-5.83c0-6.45-5.25-11.7-11.7-11.7s-11.7,5.25-11.7,11.7h-1.16c0-7.09,5.77-12.86,12.86-12.86Z"
          />
          <path
            fill="currentColor"
            d="M305.18,25.29c5.47,0,9.92,4.45,9.92,9.92v5.83h-1.16v-5.83c0-4.83-3.93-8.75-8.75-8.75s-8.75,3.93-8.75,8.75h-1.16c0-5.47,4.45-9.92,9.92-9.92Z"
          />
          <path
            fill="currentColor"
            d="M305.18,28.24c3.84,0,6.97,3.13,6.97,6.97v5.83h-1.16v-5.83c0-3.2-2.6-5.8-5.8-5.8s-5.8,2.6-5.8,5.8h-1.16c0-3.84,3.13-6.97,6.97-6.97Z"
          />
          <path
            fill="currentColor"
            d="M305.18,31.19c2.22,0,4.02,1.8,4.02,4.02v5.83h-1.16v-5.83c0-1.57-1.28-2.86-2.86-2.86s-2.86,1.28-2.86,2.86h-1.16c0-2.22,1.8-4.02,4.02-4.02Z"
          />
        </g>
        <rect fill="currentColor" x="301.16" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="298.21" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="295.26" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="292.31" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="289.37" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="286.42" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="283.47" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="280.52" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="277.57" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="274.62" y="35.21" width="1.16" height="5.83" />
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M241.19,40.88h1.16v5.83c0,16.21,13.19,29.4,29.4,29.4s29.4-13.19,29.4-29.4h1.16c0,8.16-3.18,15.84-8.95,21.61-5.77,5.77-13.45,8.95-21.61,8.95s-15.84-3.18-21.61-8.95c-5.77-5.77-8.95-13.45-8.95-21.61v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M244.14,40.88h1.16v5.83c0,14.58,11.86,26.45,26.45,26.45s26.45-11.86,26.45-26.45h1.16c0,7.37-2.87,14.31-8.09,19.52-5.21,5.21-12.15,8.09-19.52,8.09s-14.31-2.87-19.52-8.09c-5.21-5.21-8.09-12.15-8.09-19.52v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M247.09,40.88h1.16v5.83c0,12.96,10.54,23.5,23.5,23.5s23.5-10.54,23.5-23.5h1.16c0,13.6-11.06,24.66-24.66,24.66s-24.66-11.06-24.66-24.66v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M250.03,40.88h1.16v5.83c0,11.33,9.22,20.55,20.55,20.55s20.55-9.22,20.55-20.55h1.16c0,11.97-9.74,21.71-21.71,21.71s-21.71-9.74-21.71-21.71v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M252.98,40.88h1.16v5.83c0,9.7,7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6h1.16c0,10.35-8.42,18.76-18.76,18.76s-18.76-8.42-18.76-18.76v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M255.93,40.88h1.16v5.83c0,8.08,6.57,14.65,14.65,14.65s14.65-6.57,14.65-14.65h1.16c0,8.72-7.09,15.81-15.81,15.81s-15.81-7.09-15.81-15.81v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M258.88,40.88h1.16v5.83c0,6.45,5.25,11.7,11.7,11.7s11.7-5.25,11.7-11.7h1.16c0,7.09-5.77,12.86-12.86,12.86s-12.86-5.77-12.86-12.86v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M261.83,40.88h1.16v5.83c0,4.83,3.93,8.75,8.75,8.75s8.75-3.93,8.75-8.75h1.16c0,5.47-4.45,9.92-9.92,9.92s-9.92-4.45-9.92-9.92v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M264.78,40.88h1.16v5.83c0,3.2,2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8h1.16c0,3.84-3.13,6.97-6.97,6.97s-6.97-3.13-6.97-6.97v-5.83Z"
          />
          <path
            fill="currentColor"
            d="M267.73,40.88h1.16v5.83c0,1.57,1.28,2.86,2.86,2.86s2.86-1.28,2.86-2.86h1.16c0,2.22-1.8,4.02-4.02,4.02s-4.02-1.8-4.02-4.02v-5.83Z"
          />
        </g>
        <rect fill="currentColor" x="274.6" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="277.55" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="280.5" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="283.45" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="286.4" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="289.35" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="292.29" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="295.24" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="298.19" y="40.88" width="1.16" height="5.83" />
        <rect fill="currentColor" x="301.14" y="40.88" width="1.16" height="5.83" />
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M238.33,4.65c8.16,0,15.84,3.18,21.61,8.95,5.77,5.77,8.95,13.45,8.95,21.61v5.83h-1.16v-5.83c0-16.21-13.19-29.4-29.4-29.4s-29.4,13.19-29.4,29.4h-1.16c0-8.16,3.18-15.84,8.95-21.61,5.77-5.77,13.45-8.95,21.61-8.95Z"
          />
          <path
            fill="currentColor"
            d="M238.33,7.6c7.37,0,14.31,2.87,19.52,8.09,5.21,5.21,8.09,12.15,8.09,19.52v5.83h-1.16v-5.83c0-14.58-11.86-26.45-26.45-26.45s-26.45,11.86-26.45,26.45h-1.16c0-7.37,2.87-14.31,8.09-19.52,5.21-5.21,12.15-8.09,19.52-8.09Z"
          />
          <path
            fill="currentColor"
            d="M238.33,10.55c13.6,0,24.66,11.06,24.66,24.66v5.83h-1.16v-5.83c0-12.96-10.54-23.5-23.5-23.5s-23.5,10.54-23.5,23.5h-1.16c0-13.6,11.06-24.66,24.66-24.66Z"
          />
          <path
            fill="currentColor"
            d="M238.33,13.5c11.97,0,21.71,9.74,21.71,21.71v5.83h-1.16v-5.83c0-11.33-9.22-20.55-20.55-20.55s-20.55,9.22-20.55,20.55h-1.16c0-11.97,9.74-21.71,21.71-21.71Z"
          />
          <path
            fill="currentColor"
            d="M238.33,16.45c10.35,0,18.76,8.42,18.76,18.76v5.83h-1.16v-5.83c0-9.7-7.9-17.6-17.6-17.6s-17.6,7.9-17.6,17.6h-1.16c0-10.35,8.42-18.76,18.76-18.76Z"
          />
          <path
            fill="currentColor"
            d="M238.33,19.4c8.72,0,15.81,7.09,15.81,15.81v5.83h-1.16v-5.83c0-8.08-6.57-14.65-14.65-14.65s-14.65,6.57-14.65,14.65h-1.16c0-8.72,7.09-15.81,15.81-15.81Z"
          />
          <path
            fill="currentColor"
            d="M238.33,22.35c7.09,0,12.86,5.77,12.86,12.86v5.83h-1.16v-5.83c0-6.45-5.25-11.7-11.7-11.7s-11.7,5.25-11.7,11.7h-1.16c0-7.09,5.77-12.86,12.86-12.86Z"
          />
          <path
            fill="currentColor"
            d="M238.33,25.29c5.47,0,9.92,4.45,9.92,9.92v5.83h-1.16v-5.83c0-4.83-3.93-8.75-8.75-8.75s-8.75,3.93-8.75,8.75h-1.16c0-5.47,4.45-9.92,9.92-9.92Z"
          />
          <path
            fill="currentColor"
            d="M238.33,28.24c3.84,0,6.97,3.13,6.97,6.97v5.83h-1.16v-5.83c0-3.2-2.6-5.8-5.8-5.8s-5.8,2.6-5.8,5.8h-1.16c0-3.84,3.13-6.97,6.97-6.97Z"
          />
          <path
            fill="currentColor"
            d="M238.33,31.19c2.22,0,4.02,1.8,4.02,4.02v5.83h-1.16v-5.83c0-1.57-1.28-2.86-2.86-2.86s-2.86,1.28-2.86,2.86h-1.16c0-2.22,1.8-4.02,4.02-4.02Z"
          />
        </g>
        <rect fill="currentColor" x="234.31" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="231.37" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="228.42" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="225.47" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="222.52" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="219.57" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="216.62" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="213.67" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="210.72" y="35.21" width="1.16" height="5.83" />
        <rect fill="currentColor" x="207.77" y="35.21" width="1.16" height="5.83" />
      </g>
    </g>
  </svg>
</template>

<style lang="scss" scoped></style>
