<script setup lang="ts">
import { getCurrentInstance } from 'vue'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({

  name: { label: 'Accordion Container', group: 'Accordions', layouts: ['*'] },
  preview: 'SlicesGlueAccordionsAccordionContainer.jpg',
  slots: [{ name: 'default' }],
  description: 'Contains and groups accordion slices',
  tags: ['Accordion'],
  fields: {
    style: {
      type: 'select',
      label: 'Style',
      options: {
        default: 'Default',
        mmw: 'Music Making Waves',
      },
      default: 'default',
    },
    title: { type: 'text', label: 'Title', enabled: false },
    introduction: { type: 'textarea', label: 'Introduction', enabled: false },
    link: { type: 'link', label: 'Link', enabled: false },
  },
  templates: [
    {
      label: 'Accordion Container',
      fields: {
        style: { value: 'default' },
      },
    },
  ],
})

const activeItem: Ref<null | number> = ref(null)

function updateActiveItem(item: number | null) {
  activeItem.value = item
}

provide('activeItem', { activeItem, updateActiveItem })
provide('style', props.fields.style.value)

const noPaddingIfChild = computed(() => {
  const instance = getCurrentInstance()
  if (instance !== null) {
    if (instance.parent && instance.parent.attrs?.slice) {
      return {
        padding: 0,
      }
    }
  }
  return {}
})

const expansionLabel = computed(() => {
  return activeItem.value === null || activeItem.value !== -1 ? 'Expand All' : 'Close All'
})

function expandAllToggle() {
  if (activeItem.value === null || activeItem.value > -1)
    updateActiveItem(-1)
  else
    updateActiveItem(null)
}
</script>

<template>
  <div>
    <div v-if="fields" class="flex flex-col items-center px-8">
      <div
        v-if="fields.title.enabled"
        :class="{
          'headline': fields.style.value === 'default',
          'font-mmw-headline font-bold uppercase text-32':
            fields.style.value === 'mmw',
        }"
      >
        {{ fields.title.value }}
      </div>
      <div
        v-if="fields.introduction.enabled"
        class="mt-4 prose"
        :class="{
          'font-sans2': fields.style.value === 'default',
          'font-mmw': fields.style.value === 'mmw',
        }"
      >
        {{ fields.introduction.value }}
      </div>

      <a
        v-if="fields.link.enabled"
        :href="fields.link.value?.href"
        :target="fields.link.value.target"
        class="mt-8"
        :class="{
          'font-sans2 underline underline-glueblue-300':
            fields.style.value === 'default',
          'font-mmw-headline font-bold uppercase py-4 px-6 rounded-tl-3xl rounded-br-3xl bg-mmw-teal text-white':
            fields.style.value === 'mmw',
        }"
      >
        {{ fields.link.value.text }}
      </a>
    </div>

    <div class="container flex flex-col items-center" :style="noPaddingIfChild">
      <div class="w-full flex justify-end px-28 mb-4">
        <button
          v-if="slice.elements && slice.elements.length > 1"
          type="button"
          class="text-xs tracking-widest font-sans2 uppercase font-light focus:outline-none"
          @click="expandAllToggle"
        >
          {{ expansionLabel }}
        </button>
      </div>
      <div
        class="bg-white mx-auto text-left font-sans2 divide-y divide-gray-400 px-4 lg:px-16 w-full"
        style="max-width: 1200px"
      >
        <VoixSlice
          v-for="(element, key) in slice.elements"
          :key="element.id"
          :slice="element"
          :slice-index="key"
        />
      </div>
    </div>
  </div>
</template>
