<script>
export default {
  name: 'Hero',

  props: {
    devise: { type: Object },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loaded: true,
      navigationHeight: 150,
    }
  },

  watch: {
    breakpoint() {
      this.positionHero()
    },
  },

  mounted() {
    this.positionHero()
  },

  methods: {
    positionHero() {
      setTimeout(() => {
        this.refreshNavigationHeight()
        this.loaded = true
      }, 500)
    },
    refreshNavigationHeight() {
      const navigationEl = document.getElementById('navigation')
      if (navigationEl)
        this.navigationHeight = navigationEl.offsetHeight
    },

    scrollDown() {
      const el = this.$el.getBoundingClientRect()
      window.scroll({ top: el.height, behavior: 'smooth' })
    },
  },
}
</script>

<template>
  <div class="relative">
    <div class="w-full relative hero-slide pointer-events-none bg-black">
      <iframe
        ref="video"
        :src="`https://fast.wistia.net/embed/iframe/${devise.fields.wistiaId.value}?videoFoam=true&autoPlay=true&muted=true&loop=true&playerColor=254297`"
        allow="autoplay; fullscreen"
        allowtransparency="true"
        frameborder="0"
        scrolling="no"
        class="w-full h-full pointer-events-auto"
        name="wistia_embed"
        :style="{ 'aspect-ratio': devise.fields.aspectRatio.value }"
        msallowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
      />
    </div>
    <a
      v-if="devise.fields.link.enabled"
      :href="devise.fields.link.value?.href"
      class="absolute z-20 inset-0"
    />
    <div
      v-if="!devise.fields.hideDownArrow.value"
      class="absolute z-30 bottom-0 mb-16 left-0 right-0 flex justify-center"
    >
      <div class="slider-button mb-8" @click="scrollDown">
        <IconsArrow class="w-5 text-white transform -rotate-90" />
      </div>
    </div>
  </div>
</template>
