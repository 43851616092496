<script>
export default {
  name: 'GlueWaterBottleIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg viewBox="0 0 29 44" version="1.1">
    <path
      fill="currentcolor"
      d="M20.8962,43.776 L2.052,43.776 C1.11781303,43.776 0.359446883,43.0206994 0.35568,42.08652 L0.35568,15.732 C0.35634458,14.7957276 0.823085243,13.9212299 1.60056,13.39956 L4.2066,11.6622 C4.32274584,11.5842314 4.39209063,11.4532468 4.39128,11.31336 L4.39128,10.75932 C4.39128,10.3815572 4.69751723,10.07532 5.07528,10.07532 L17.86608,10.07532 C18.2438428,10.07532 18.55008,10.3815572 18.55008,10.75932 L18.55008,11.31336 C18.5507825,11.4545555 18.6228473,11.5858164 18.7416,11.6622 L21.3408,13.39272 C22.1141556,13.9196832 22.5754703,14.7961811 22.5720573,15.732 L22.5720573,42.066 C22.5796023,43.0002206 21.8303836,43.7647295 20.8962,43.776 L20.8962,43.776 Z M5.75244,11.46384 C5.71592229,12.0169527 5.42550037,12.5220343 4.96584,12.83184 L2.36664,14.56236 C1.97319514,14.8229487 1.73284989,15.2601725 1.72368,15.732 L1.72368,42.066 C1.71995085,42.1554211 1.75288823,42.2424889 1.81486899,42.3070522 C1.87684974,42.3716155 1.96250125,42.4080001 2.052,42.4080001 L20.8962,42.4080001 C21.0748882,42.4080001 21.2207973,42.2651694 21.22452,42.08652 L21.22452,15.732 C21.2230265,15.2489437 20.9822005,14.7980355 20.58156,14.52816 L17.9892,12.825 C17.5227631,12.5196441 17.2265112,12.0132306 17.18892,11.457 L5.75244,11.46384 Z"
    />
    <path
      fill="currentcolor"
      d="M24.7608,10.3010679 L19.21356,10.3010679 C18.8357972,10.3010679 18.52956,9.99480277 18.52956,9.61704 L18.52956,5.73876 C18.5380442,5.69815849 18.5380442,5.65624151 18.52956,5.61564 C18.432002,5.42203546 18.432002,5.19364454 18.52956,5.00004 C18.6256028,4.80146294 18.8115163,4.66139113 19.02888,4.62384 C19.1085396,4.61714643 19.1886204,4.61714643 19.26828,4.62384 C20.4836035,4.60295404 21.6775857,4.30094678 22.75668,3.74148 C23.8651249,2.98138622 25.3039648,2.89969384 26.49132,3.52944 C27.6852782,4.14329427 28.4275922,5.38136784 28.40652,6.72372 C28.3765526,8.71357654 26.7508672,10.3087612 24.7608,10.3010679 Z M19.89756,8.93304 L24.7608,8.93304 C25.9967552,8.92557179 26.9968518,7.92547523 27.00432,6.68952 L27.00432,6.68952 C27.0172493,5.86110316 26.5584264,5.09728235 25.821,4.7196 C25.0815905,4.32871678 24.1866548,4.3787279 23.4954,4.84956 C22.3903847,5.47153284 21.1620827,5.84282566 19.89756,5.93712 L19.89756,8.93304 Z"
    />
    <path
      fill="currentcolor"
      d="M15.17796,5.41044 L7.77024,5.41044 C7.39247723,5.41044 7.08624,5.10420277 7.08624,4.72644 L7.08624,1.69632 C7.08624,0.759468333 7.84570833,0 8.78256,0 L14.16564,0 C15.1024917,0 15.86196,0.759468333 15.86196,1.69632 L15.86196,4.72644 C15.86196,5.10420277 15.5557228,5.41044 15.17796,5.41044 Z M8.45424,4.04244 L14.49396,4.04244 L14.49396,1.69632 C14.49396,1.51499387 14.3469661,1.368 14.16564,1.368 L8.78256,1.368 C8.60276039,1.37163342 8.45787342,1.51652039 8.45424,1.69632 L8.45424,4.04244 Z"
    />
    <path
      fill="currentcolor"
      d="M18.20808,11.46384 L4.74012,11.46384 C3.8086031,11.4600914 3.0543886,10.7058769 3.05064,9.77436 L3.05064,5.73192 C3.0543886,4.8004031 3.8086031,4.0461886 4.74012,4.04244 L18.20808,4.04244 C19.1395969,4.0461886 19.8938114,4.8004031 19.89756,5.73192 L19.89756,9.77436 C19.8938114,10.7058769 19.1395969,11.4600914 18.20808,11.46384 L18.20808,11.46384 Z M4.74012,5.41044 C4.6548582,5.41044 4.57308851,5.44431012 4.51279931,5.50459931 C4.45251012,5.56488851 4.41864,5.6466582 4.41864,5.73192 L4.41864,9.77436 C4.41864,9.9519085 4.5625715,10.09584 4.74012,10.09584 L18.20808,10.09584 C18.3856285,10.09584 18.52956,9.9519085 18.52956,9.77436 L18.52956,5.73192 C18.52956,5.6466582 18.4956899,5.56488851 18.4354007,5.50459931 C18.3751115,5.44431012 18.2933418,5.41044 18.20808,5.41044 L4.74012,5.41044 Z"
    />
  </svg>
</template>
