<script>
export default {
  name: 'GlueWifiIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 45 30"
  >
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(0 -1) translate(0 .24)">

        <path
          fill="currentcolor"
          d="M44.294 10.31a1.074 1.074 0 00-.133-.145C38.751 4.236 30.918.761 22.248.761S5.745 4.235.334 10.165a1.009 1.009 0 101.481 1.354c5.022-5.503 12.3-8.742 20.433-8.742 8.132 0 15.41 3.238 20.433 8.742a1.007 1.007 0 101.613-1.21"
          mask="url(#wifi_prefix_b)"
        />
      </g>
      <path
        fill="currentcolor"

        d="M37.592 15.935C33.754 11.899 28.24 9.57 22.248 9.57c-5.993 0-11.506 2.328-15.344 6.364a1.008 1.008 0 101.45 1.387c3.44-3.617 8.423-5.735 13.894-5.735s10.454 2.118 13.895 5.735a1.008 1.008 0 101.45-1.387"
        transform="translate(0 -1)"
      />
      <path
        fill="currentcolor"

        d="M31.007 20.944c-2.268-2.172-5.445-3.308-8.759-3.308s-6.492 1.136-8.76 3.308a1.009 1.009 0 101.387 1.45c1.836-1.76 4.521-2.742 7.373-2.742 2.852 0 5.536.982 7.373 2.742a1.008 1.008 0 101.386-1.45M22.248 24.19a3.04 3.04 0 00-3.025 3.024 3.04 3.04 0 003.025 3.025 3.04 3.04 0 003.025-3.025 3.04 3.04 0 00-3.025-3.024"
        transform="translate(0 -1)"
      />
    </g>
  </svg>
</template>
