<script>
import { animate, inView } from 'motion'

export default {

  mounted() {
    const solarSystem = this.$el
    inView('.planet', () => {
      animate(solarSystem, { transform: 'rotate(360deg)' }, { duration: 10, repeat: Number.POSITIVE_INFINITY, easing: 'linear' })
    })
  },
}
</script>

<template>
  <svg id="wff-system" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 110">
    <ellipse class="planet" fill="#eb897c" cx="55" cy="55" rx="50" ry="50" />
    <ellipse class="moon" fill="#9fdceb" cx="100" cy="100" rx="5" ry="5" />
  </svg>
</template>
