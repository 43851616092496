<script>
export default {
  name: 'Phone',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="currentcolor"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g stroke="none" stroke-width="1" fill="currentcolor" fill-rule="evenodd">
        <path
          id="Path"
          d="M32.28,11.04 C31.4,8.84 30.08,6.88 28.4,5.2 C26.72,3.52 24.76,2.24 22.56,1.32 C21.48,0.88 20.36,0.56 19.24,0.32 C18.84,0.24 18.44,0.52 18.36,0.92 C18.28,1.32 18.56,1.72 18.96,1.8 C20,2 21.04,2.28 22,2.68 C24,3.48 25.8,4.68 27.32,6.24 C28.84,7.76 30.04,9.56 30.88,11.56 C31.28,12.56 31.6,13.56 31.76,14.6 C31.84,14.96 32.12,15.2 32.48,15.2 C32.52,15.2 32.56,15.2 32.6,15.2 C33,15.12 33.28,14.72 33.2,14.32 C33.04,13.24 32.72,12.12 32.28,11.04 Z"
        />
        <path
          id="Path"
          d="M17.68,6.64 C18.52,6.76 19.36,7 20.12,7.32 C21.52,7.88 22.76,8.72 23.8,9.76 C24.88,10.84 25.68,12.08 26.24,13.44 C26.56,14.24 26.8,15.04 26.92,15.88 C26.96,16.24 27.28,16.52 27.64,16.52 C27.68,16.52 27.72,16.52 27.76,16.52 C28.16,16.44 28.44,16.08 28.36,15.68 C28.2,14.72 27.96,13.8 27.6,12.88 C26.96,11.32 26.04,9.88 24.84,8.68 C23.64,7.48 22.24,6.52 20.64,5.92 C19.76,5.56 18.8,5.28 17.88,5.16 C17.48,5.08 17.08,5.36 17.04,5.76 C17,6.16 17.28,6.56 17.68,6.64 Z"
        />
        <path
          id="Path"
          d="M16.44,11.48 C17.92,11.6 19.28,12.24 20.32,13.28 C21.36,14.32 22,15.68 22.12,17.16 C22.16,17.56 22.48,17.84 22.88,17.84 C22.92,17.84 22.92,17.84 22.96,17.84 C23.36,17.8 23.68,17.44 23.64,17.04 C23.48,15.24 22.72,13.52 21.4,12.24 C20.12,10.96 18.4,10.16 16.6,10 C16.2,9.96 15.84,10.28 15.8,10.68 C15.76,11.08 16.04,11.44 16.44,11.48 Z"
        />
        <path
          id="Shape"
          d="M8.52,25.08 L8.52,25.08 C10.96,27.48 13.68,29.48 16.68,31.04 C19.52,32.52 22.04,33.28 24.36,33.28 C24.88,33.28 25.4,33.24 25.88,33.16 C27.04,33 28.16,32.6 29.24,32.04 C30.28,31.48 31.2,30.72 32,29.84 C33,28.72 33.4,27.56 33.2,26.48 C33.04,25.72 32.6,25.04 31.92,24.48 C31.36,24.04 30.68,23.72 30.08,23.48 C28.12,22.68 26.12,22.16 24.4,21.76 C23.8,21.64 22.96,21.4 22.12,21.72 C21.48,21.96 21.08,22.44 20.72,22.88 C20.6,23.04 20.44,23.2 20.32,23.36 C20,23.68 19.12,23.52 18.04,22.92 C16.8,22.28 15.32,21.12 13.92,19.72 C12.56,18.36 11.36,16.84 10.72,15.6 C10.12,14.52 9.96,13.64 10.28,13.32 C10.4,13.2 10.56,13.08 10.76,12.92 C11.2,12.56 11.68,12.16 11.92,11.52 C12.24,10.68 12.04,9.8 11.88,9.24 C11.48,7.52 10.96,5.52 10.16,3.56 C9.92,2.92 9.6,2.28 9.16,1.72 C8.6,1.04 7.92,0.56 7.16,0.44 C6.12,0.24 4.96,0.64 3.8,1.64 C2.92,2.4 2.16,3.32 1.6,4.4 C1.04,5.48 0.64,6.56 0.48,7.76 C0.08,10.48 0.76,13.48 2.6,16.96 C4.16,19.92 6.16,22.68 8.52,25.08 Z M1.92,7.92 C2.2,5.92 3.24,4.04 4.76,2.72 C5.32,2.24 6.12,1.72 6.88,1.88 C7.28,1.96 7.68,2.24 8,2.64 C8.32,3.04 8.52,3.52 8.76,4.12 C9.48,6 10,7.96 10.4,9.6 C10.52,10.16 10.64,10.64 10.48,11.04 C10.4,11.32 10.08,11.52 9.76,11.8 C9.56,11.96 9.36,12.12 9.16,12.32 C8.84,12.64 8.48,13.24 8.6,14.24 C8.68,14.84 8.92,15.56 9.36,16.36 C10.08,17.72 11.32,19.32 12.84,20.8 C14.36,22.28 15.92,23.52 17.28,24.28 C18.08,24.68 18.76,24.96 19.4,25.04 C19.52,25.04 19.64,25.08 19.76,25.08 C20.52,25.08 21.04,24.76 21.32,24.48 C21.52,24.28 21.68,24.08 21.84,23.88 C22.12,23.56 22.32,23.28 22.6,23.16 C22.96,23 23.48,23.12 24.04,23.24 C25.68,23.64 27.64,24.12 29.52,24.88 C30.12,25.12 30.6,25.36 31,25.64 C31.4,25.96 31.68,26.36 31.76,26.76 C31.92,27.52 31.36,28.32 30.92,28.88 C29.6,30.4 27.72,31.44 25.72,31.72 C23.32,32.08 20.6,31.44 17.4,29.76 C14.52,28.24 11.92,26.36 9.6,24.08 C7.32,21.76 5.44,19.16 3.92,16.28 C2.2,13.04 1.56,10.32 1.92,7.92 Z"
        />
      </g>
    </g>
  </svg>
</template>
