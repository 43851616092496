<script setup lang="ts">
defineProps({
  fields: {
    type: Object as PropType<Record<string, any>>,
    default: () => {},
  },
  slice: {
    type: Object as PropType<Record<string, any>>,
    default: () => {},
  },
})

defineSlice({
  name: { label: 'Exit Intent Popup', group: 'MariGold', layouts: ['Atlantisbahamas'] },
  preview: 'SlicesMarigoldAtlantisGrowPopup.jpg',
  fields: {},
})

onMounted(() => {
  const script = document.createElement('script')
  script.src = `https://us-x.wayin.com/lightbox/eecee277-8b8b-4bd6-85e6-21708e1a9439?mode=adaptive&width=80&height=80&launchMethod=event&eventType=exit-intent&transparency=50`
  script.async = true

  const widget = document.getElementById('marigold-widget')

  if (widget)
    widget.appendChild(script)
})
</script>

<template>
  <div>
    <div id="marigold-widget" />
  </div>
</template>

<style>
#marigold-widget .ngxModal {
  z-index: 10000 !important;
}
</style>
