<script>
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { Swiper, SwiperSlide } from 'swiper/vue'

dayjs.extend(isBetween)

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    featured: {
      type: Boolean,
      default: false,
    },
    track: {
      type: String,
      default: null,
    },
    filters: {
      type: Object,
      required: true,
    },
    slider: {
      type: Boolean,
      default: false,
    },
    pageSpecials: {
      type: Array,
      required: true,
    },
    pageSpecialsCategories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      ready: false,
      bleedWidthStyles: {
        width: '100%',
      },
      rerendering: false,
    }
  },
  computed: {
    windowWidth() {
      return window.innerWidth
    },
    pageSpecialsForCategory() {
      // atl asked to include featured so just returning filteredSpecials
      return this.filteredSpecials
    },
    filteredSpecials() {
      let specials = this.pageSpecials

      if (
        this.filters.dates
        && this.filters.dates.start
        && this.filters.dates.end
      ) {
        const start = dayjs(this.filters.dates.start)
        const end = dayjs(this.filters.dates.end)

        if (specials) {
          specials = specials.filter((special) => {
            if (this.featured && special.featured)
              return true

            const travelTo = dayjs(special.travel_from_date)
            const travelUntil = dayjs(special.travel_until_date)

            if (travelTo.isBetween(start, end))
              return true

            if (travelUntil.isBetween(start, end))
              return true

            if (start.isBetween(travelTo, travelUntil))
              return true

            if (end.isBetween(travelTo, travelUntil))
              return true
          })
        }
      }

      if (specials) {
        specials = specials.filter((special) => {
          if (this.featured && special.featured)
            return true

          if (this.filteredCategories.length) {
            return this.filteredCategories.find((fc) => {
              return special.categories.find(sc => sc.id === fc.id)
            })
          }
          else {
            return true
          }
        })
      }

      return specials
    },

    filteredCategories() {
      if (this.pageSpecialsCategories && this.pageSpecialsCategories.data) {
        return this.pageSpecialsCategories.data.filter((category) => {
          const filterCategory = this.filters.categories.find((fc) => {
            return fc.id === category.id
          })
          if (filterCategory)
            return filterCategory.selected

          return false
        })
      }
      return []
    },
  },
  watch: {
    filters() {
      this.rerendering = true
      setTimeout(() => {
        this.rerendering = false
      }, 200)
    },
  },
  mounted() {
    if (this.featured) {
      this.calculateCategoryWidth()
      window.addEventListener('resize', this.calculateCategoryWidth)
    }

    setTimeout(() => {
      this.ready = true
    }, 2000)
  },
  methods: {
    calculateCategoryWidth() {
      const windowWidth = window.innerWidth
      if (windowWidth > 1680) {
        this.bleedWidthStyles.width = '1400px'
      }
      else if (windowWidth > 1120) {
        const bleedWidth = (windowWidth - 1120) / 2 + 1112
        this.bleedWidthStyles.width = `${bleedWidth}px`
      }
      else {
        this.bleedWidthStyles.width = '100%'
      }
    },
  },
}
</script>

<template>
  <div
    v-if="
      pageSpecials.length
        && pageSpecialsForCategory.length
    "
  >
    <div class="container mb-20">
      <div :style="bleedWidthStyles">
        <div class="max-w-72 mb-10 md:mb-6 px-8 md:px-0 text-gray-800">
          <div
            class="font-sans text-3xl md:text-4xl font-light uppercase leading-tight mb-2 tracking-wider"
          >
            <template v-if="category.name !== 'Featured Offers'">
              {{
                category.name
              }}
            </template>
            <template v-else>
              Featured Offer
            </template>
          </div>
          <p
            v-if="featured"
            class="font-sans2 font-light block text-18 text-gray-600"
          >
            Explore some of our special offers with the best rates guaranteed.
          </p>
        </div>

        <template v-if="featured">
          <div v-for="special in pageSpecialsForCategory" :key="special.id">
            <GlueSpecialsSpecial :track="track" :special="special" :big="true" />
          </div>
        </template>
        <template v-else>
          <template v-if="!slider">
            <div
              class="flex flex-wrap justify-center px-0 md:justify-start md:last:pr-0"
            >
              <div
                v-for="special in pageSpecialsForCategory"
                :key="`non-slider-${special.id}`"
                class="w-full md:w-1/2 lg:w-1/3 md:pr-6 pb-12 md:pb-32 first:pl-0 flex flex-col"
              >
                <GlueSpecialsSpecial
                  :track="track"
                  :special="special"
                  :big="false"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-if="ready && !rerendering"
              id="specials-categories-mobile-slider"
              class="-mt-8 md:-mt-16"
            >
              <Swiper
                ref="mySwiper" :slides-per-view="1" :loop="pageSpecialsForCategory.length > 1" :centered-slides="true"
                class="clipping-slider border-white" :class="{
                  'border-t-10': showTopBorder,
                  'border-b-10': showBottomBorder,
                }"
              >
                <SwiperSlide v-for="(special, key) in pageSpecialsForCategory" :key="key">
                  <GlueSpecialsSpecial
                    :track="track"
                    :special="special"
                    :big="false"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
