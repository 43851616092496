<script>
export default {
  name: 'GlueShuttleIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 43">
    <path
      fill="currentcolor"
      fill-rule="evenodd"
      stroke="#F7F5F0"
      stroke-width=".5"
      d="M33.526 36.314h3.717v2.788a.907.907 0 01-.929.929h-1.858a.907.907 0 01-.93-.929v-2.788zm-26.95 0h3.717v2.788a.907.907 0 01-.93.929H7.505a.907.907 0 01-.929-.929v-2.788zm11.616-5.112h7.435v-1.858h-7.435v1.858zm-.929-3.717c-.487 0-.929.443-.929.93v3.717c0 .487.442.93.929.93h9.293c.487 0 .929-.443.929-.93v-3.717c0-.487-.442-.93-.929-.93h-9.293zm14.869-.464a1.38 1.38 0 011.394 1.394 1.38 1.38 0 01-1.394 1.394 1.38 1.38 0 01-1.394-1.394 1.38 1.38 0 011.394-1.394h0zm-20.445 0a1.38 1.38 0 011.394 1.394 1.38 1.38 0 01-1.394 1.394 1.38 1.38 0 01-1.394-1.394 1.38 1.38 0 011.394-1.394h0zm20.445-1.859a3.266 3.266 0 00-3.252 3.253 3.267 3.267 0 003.252 3.253 3.267 3.267 0 003.253-3.253 3.267 3.267 0 00-3.253-3.253h0zm-20.445 0a3.267 3.267 0 00-3.253 3.253 3.267 3.267 0 003.253 3.253 3.268 3.268 0 003.253-3.253 3.267 3.267 0 00-3.253-3.253h0zm-5.111 9.294h30.667V22.374H6.576v12.082zm32.526-17.194h1.858v-6.505h-1.858v6.505zm-36.243 0h1.858v-6.505H2.859v6.505zm19.98 3.253h14.404V7.505H22.839v13.01zm-16.263 0H20.98V7.505H6.576v13.01zm.929-17.657h28.809c.531 0 .929.398.929.93v1.858H6.576V3.788c0-.532.398-.93.929-.93h0zm0-1.858a2.803 2.803 0 00-2.788 2.788v5.111H2.859A1.875 1.875 0 001 10.757v6.506c0 1.013.845 1.859 1.859 1.859h1.858v19.98a2.803 2.803 0 002.788 2.788h1.858a2.803 2.803 0 002.788-2.788v-2.788h19.517v2.788a2.803 2.803 0 002.788 2.788h1.858a2.803 2.803 0 002.788-2.788v-19.98h1.859a1.876 1.876 0 001.858-1.859v-6.506a1.875 1.875 0 00-1.858-1.858h-1.859V3.788A2.803 2.803 0 0036.314 1H7.505z"
    />
  </svg>
</template>
