<script setup lang="ts">
import type { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  name: { label: 'Featured Bed Types', group: 'Rooms', layouts: ['Atlantisbahamas', 'AtlantisMobileApp'] },
  preview: 'SlicesGlueStayFeaturedBedTypes.jpg',
  description: 'List of the featured bed types teaser used on the tower pages',
  tags: ['Room', 'Bed Type', 'Hotel', 'Stay'],

  slots: [{ name: 'default' }],
  fields: {
    link: { type: 'link', label: 'Link' },
  },
  templates: [{
    label: 'Featured Bed Types',
    fields: {
      link: {
        value: { text: 'See all suites', target: '_self', relationship: null, href: '\/new-page' },
      },
    },
  }],
})

const swiper: Ref<SwiperType | null> = ref(null)

function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}

function next() {
  if (!swiper.value)
    return
  swiper.value.slideNext()
}

function prev() {
  if (!swiper.value)
    return
  swiper.value.slidePrev()
}
</script>

<template>
  <div class="relative flex justify-center small-container self-stretch">
    <div class="w-full">
      <div class="mb-20 w-full">
        <div class="relative">
          <div class="mb-8">
            <Swiper
              :slides-per-view="1"
              :loop="slice.elements[0].elements.length > 1"
              :centered-slides="true"
              @swiper="onSwiper"
            >
              <SwiperSlide
                v-for="(element, key) in slice.elements[0].elements"
                :key="key"
              >
                <VoixSlice :slice="element" :slice-index="key" />
              </SwiperSlide>
            </Swiper>
          </div>

          <div
            v-if="slice.elements[0].elements.length > 1"
            class="absolute z-30 bottom-0 mb-4 p-12 pointer-events-none flex space-x-3 justify-between items-center inset-y-full lg:inset-0"
          >
            <button
              class="pointer-events-auto flex relative lg:z-50 top-0 bottom-0 left-0 -mt-8 justify-center items-center"
              @click="prev"
            >
              <div class="slider-button bg-orange-50 text-gray-500">
                <svg
                  class="w-7 h-7 pointer-events-none"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </button>
            <button
              class="pointer-events-auto flex relative lg:z-50 top-0 bottom-0 right-0 -mt-8 justify-center items-center"
              @click="next"
            >
              <div class="slider-button bg-orange-50 text-gray-500">
                <svg
                  class="w-7 h-7 transform rotate-180 pointer-events-none"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>

        <div class="w-full text-center pt-8">
          <a
            :href="fields.link.value?.href"
            class="glue-btn btn-ghost border-zinc-600 text-xs tracking-widest p-3 pt-5 px-16 text-zinc-700"
          >{{ fields.link.value.text }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
