<script setup lang="ts">
import { resolveFieldsArray } from '@voix/composables/useField'
import { Swiper, SwiperSlide } from 'swiper/vue'

import { onMounted, onUnmounted } from 'vue'
// Import Swiper styles
import 'swiper/css'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
  siblings: {
    type: Array,
    default: () => ([]),
  },
})

defineSlice({

  name: { label: 'Experience Card', group: 'Activities', layouts: ['Atlantisbahamas'] },
  preview: 'SlicesGlueExperiencesExperienceCard.jpg',
  description: 'Results from search',
  tags: ['Activities', 'Card'],
  fields: {
    type: { label: 'Type', type: 'select', options: { 'uncategorized': 'Uncategorized', 'marine-adventures': 'Marine Adventures', 'dolphin-cay': 'Dolphin Cay' } },
    title: { label: 'Title', type: 'text', editorLabel: true },
    description: { label: 'Short Description', type: 'textarea' },
    iframe: { label: 'Iframe URL', type: 'text' },
    duration: { label: 'Duration', type: 'select', options: { '15': '15 minutes', '20': '20 minutes', '30': '30 minutes', '45': '45 minutes', '60': '1 hour', '90': '1 hour 30 minutes', '120': '2 hours', '180': '3 hours', '240': '4 hours', '300': '5 hours', '360': '6 hours', 'all-day': 'All Day' } },
    ages: { label: 'Ages', type: 'select', options: { 'All Ages': 'Everyone', '3-6': '3-6 Years', '4': '4 Years +', '6': '6 Years +', '8': '8 Years +', '10': '10 Years +', '12': '12 Years +', '13': '13 Years +', '14': '14 Years +', '16': '16 Years +' } },
    dolphins: { label: 'Dolphins', type: 'checkbox' },
    stingrays: { label: 'Stingrays', type: 'checkbox' },
    seaTurtles: { label: 'Sea Turtles', type: 'checkbox' },
    seaLions: { label: 'Sea Lions', type: 'checkbox' },
    sharks: { label: 'Sharks', type: 'checkbox' },
    activityLevel: { label: 'Activity Level', type: 'select', options: { 'Very Low': 'Very Low', 'Low': 'Low', 'Medium': 'Medium', 'High': 'High' } },
    price: { label: 'Price', type: 'number', enabled: false },
    swimmingRequired: { label: 'Swimming Required', type: 'checkbox' },
    bookLink: { label: 'Booking Link', type: 'link', enabled: false },
    image1: { label: 'Image 1', type: 'media', enabled: false, breakpoints: { lg: { width: 720, height: 370 }, sm: { width: 500, height: 300 } } },
    image2: { label: 'Image 2', type: 'media', enabled: false, breakpoints: { lg: { width: 720, height: 370 }, sm: { width: 500, height: 300 } } },
    image3: { label: 'Image 3', type: 'media', enabled: false, breakpoints: { lg: { width: 720, height: 370 }, sm: { width: 500, height: 300 } } },
  },
  templates: [{ label: 'Experience Card', fields: { activityLevel: { value: 'Low' }, ages: { value: 'All Ages' }, bookLink: { value: { text: 'Book Now', target: '_self', relationship: null, href: '\/new-page' } }, description: { value: 'A new encounter with young sea lion pups. In this intimate, engaging experience, you\u2019ll discover all the fascinating ways pups change and grow as they age.' }, dolphins: { value: true }, duration: { value: '30' }, iframe: { value: '\/thingstodo\/wateradventures\/pupventure?nochrome=true' }, image1: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis-marine-programs-dolphin-cay-sea-lions-pupventure.jpg' }, seaLions: { value: true }, seaTurtles: { value: true }, swimmingRequired: { value: true }, title: { value: 'Pupventure' }, type: { value: 'dolphin-cay' } } }],
})

const registerActivityCard = inject('registerActivityCard')

const showModal = ref(false)
const swiper = ref(null)
const iframeIndex = ref(null)
function navigateIframe(event) {
  if (typeof event.data === 'string') {
    if (event.data.includes(`gotoNextActivity${iframeIndex.value}`))
      goToNextIframe()

    if (event.data.includes(`gotoPreviousActivity${iframeIndex.value}`))
      goToPrevIframe()
  }
}

const age = computed(() => {
  const age = props.fields.ages.value
  if (Number.isNaN(age))
    return age

  return `${age} Years +`
})

const activeIndex = computed(() => {
  if (swiper.value)
    return Number.parseInt(swiper.value.activeIndex)

  return null
})

const numberOfImages = computed(() => {
  let imageNumber = 0

  if (props.fields.image1.enabled)
    imageNumber++

  if (props.fields.image2.enabled)
    imageNumber++

  if (props.fields.image3.enabled)
    imageNumber++

  return imageNumber
})

const animalTypeIcon = computed(() => {
  const animals = []
  if (props.fields.dolphins.value)
    animals.push('dolphins')

  if (props.fields.stingrays.value)
    animals.push('stingrays')

  if (props.fields.seaTurtles.value)
    animals.push('seaTurtles')

  if (props.fields.seaLions.value)
    animals.push('seaLions')

  // if (props.fields.sharks.value) {
  //   animals.push("sharks");
  // }

  if (animals.length === 0 || animals.length > 1)
    return 'multiple'

  return animals[0]
})

const animalTypeText = computed(() => {
  const animals = []
  if (props.fields.dolphins.value)
    animals.push('Dolphins')

  if (props.fields.stingrays.value)
    animals.push('Stingrays')

  if (props.fields.seaTurtles.value)
    animals.push('Sea Turtles')

  if (props.fields.seaLions.value)
    animals.push('Sea Lions')

  // if (props.fields.sharks.value) {
  //   animals.push("Sharks");
  // }

  if (animals.length === 0 || animals.length > 1)
    return 'Multiple'

  return animals[0]
})

const currentIframeData = computed(() => {
  return props.siblings[iframeIndex.value]
})

const currentIframeUrl = computed(() => {
  const currentIframeFields = resolveFieldsArray(currentIframeData.value)
  const iframeUrl = new URL(
    `${window.location.protocol}//${window.location.hostname}:${window.location.port !== 80 || window.location.port !== 443 ? window.location.port : ''}${currentIframeFields.iframe.value}`,
  )

  if (props.siblings[iframeIndex.value - 1]) {
    const prevIframeFields = resolveFieldsArray(props.siblings[iframeIndex.value - 1])
    iframeUrl.searchParams.append('previous', prevIframeFields.title.value)
    iframeUrl.searchParams.append('previousImage', prevIframeFields.image1.value.find(image => image.breakpoint === 'sm').url)
  }

  if (props.siblings[iframeIndex.value + 1]) {
    const nextIframeFields = resolveFieldsArray(props.siblings[iframeIndex.value + 1])
    iframeUrl.searchParams.append('next', nextIframeFields.title.value)
    iframeUrl.searchParams.append('nextImage', nextIframeFields.image1.value.find(image => image.breakpoint === 'sm').url)
  }

  iframeUrl.searchParams.append('currentIndex', iframeIndex.value)
  iframeUrl.searchParams.append('nochrome', 'true')
  return iframeUrl.href
})

registerActivityCard({ slice: props.slice, fields: props.fields })

onMounted(() => {
  window.addEventListener('message', navigateIframe)
})

onUnmounted(() => {
  window.removeEventListener('message', navigateIframe)
})

function openPage(url: string) {
  window.open(url, '_blank')
}

function openModal() {
  const siblingInstance = props.siblings.find(
    sibling => sibling.id === props.slice.id,
  )
  iframeIndex.value = props.siblings.indexOf(siblingInstance)

  showModal.value = true
}

function next() {
  swiper.value.slideNext()
}

function prev() {
  swiper.value.slidePrev()
}

function goToSlide(index) {
  swiper.value.slideTo(index)
}

function goToPrevIframe() {
  if (iframeIndex.value === 0)
    iframeIndex.value = props.siblings.length - 1
  else
    iframeIndex.value = iframeIndex.value - 1
}

function goToNextIframe() {
  if (iframeIndex.value === props.siblings.length - 1)
    iframeIndex.value = 0
  else
    iframeIndex.value = iframeIndex.value + 1
}
</script>

<template>
  <div class="relative flex flex-col font-sans2 font-light text-sm bg-white w-full">
    <div
      class="absolute z-10 top-0 left-0 -ml-3 -mt-3 w-20 h-20 bg-white flex justify-center items-center"
    >
      <div
        v-if="fields.type.value === 'dolphin-cay'"
        class="rounded-full w-10 h-10 bg-red-500"
        style="
          mask-image: url(/imgs/logos/dolphin-cay-md.png);
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
        "
      />
      <div
        v-else
        class="rounded-full w-10 h-10 bg-red-500"
        style="
          mask-image: url(/imgs/logos/marine-adventures-md.png);
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
        "
      />
    </div>
    <div class="relative">
      <Swiper
        :slides-per-view="1"
        :loop="fields.image2.enabled"
        :centered-slides="true"
        class="max-w-[90vw] lg:w-full"
      >
        <SwiperSlide v-if="fields.image1.enabled">
          <div>
            <VoixMedia :field="fields.image1" />
          </div>
        </SwiperSlide>
        <SwiperSlide v-if="fields.image2.enabled">
          <div>
            <VoixMedia :field="fields.image2" />
          </div>
        </SwiperSlide>
        <SwiperSlide v-if="fields.image3.enabled">
          <div>
            <VoixMedia :field="fields.image3" />
          </div>
        </SwiperSlide>
      </Swiper>

      <div
        v-if="numberOfImages > 1"
        class="absolute z-10 inset-0 flex justify-center items-center px-4"
      >
        <div
          class="absolute inset-0 z-10 flex justify-between items-center px-4 pointer-events-none"
        >
          <button class="slider-button border-gray-500" @click="prev">
            <svg
              class="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>

          <button class="slider-button border-gray-500" @click="next">
            <svg
              class="w-7 h-7 transform rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div class="absolute inset-0 flex justify-center items-end space-x-3 pb-4">
          <button
            v-for="n in numberOfImages"
            :key="n"
            class="h-1.5 w-1.5 rounded-full transistion-colors duration-500"
            :class="{
              'bg-gray-500': n !== activeIndex,
              'bg-white': n === activeIndex,
            }"
            @click="goToSlide(n)"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col items-start justify-between flex-1 p-6">
      <div class="w-full">
        <div class="headline-3">
          {{ fields.title.value }}
        </div>
        <div class="flex flex-col lg:flex-row justify-between items-center">
          <div class="text-zinc-500 w-full lg:w-1/2">
            {{ fields.description.value }}
          </div>

          <div class="mt-8 lg:mt-0 flex space-x-4 text-center text-2xs">
            <div class="flex flex-col items-center space-y-1 w-14">
              <div class="text-red-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 38 38"
                  class="w-8 h-8"
                >
                  <g id="Group_5" data-name="Group 5" transform="translate(0.7 0.7)">
                    <path
                      id="Stroke_1"
                      data-name="Stroke 1"
                      d="M0,9.435a9.4,9.4,0,1,1,9.4,9.435A9.416,9.416,0,0,1,0,9.435Z"
                      transform="translate(6.265)"
                      fill="none"
                      stroke="#ea887b"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.4"
                    />
                    <path
                      id="Stroke_3"
                      data-name="Stroke 3"
                      d="M0,16.355a16.288,16.288,0,1,1,32.576,0"
                      transform="translate(0 20.129)"
                      fill="none"
                      stroke="#ea887b"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.4"
                    />
                  </g>
                </svg>
              </div>
              <div>{{ age }}</div>
            </div>
            <div class="flex flex-col items-center space-y-1 pr-4 w-14">
              <glue-experience-marine-icon :animal="animalTypeIcon" />
              <div>{{ animalTypeText }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 w-full gap-8">
        <button
          v-if="fields.iframe.value"
          class="flex items-center justify-center w-full mt-5 text-center border border-zinc-500 text-zinc-700 font-bold px-6 py-4 leading-snug uppercase focus:outline-none font-sans text-2xs"
          @click="openPage(fields.iframe.value)"
        >
          Learn More
        </button>
        <a
          v-if="fields.bookLink.enabled"
          :href="fields.bookLink.value?.href"
          :target="fields.bookLink.value.target"
          class="flex items-center justify-center w-full mt-5 text-center bg-glueblue-600 text-white font-bold px-6 py-4 leading-snug uppercase focus:outline-none font-sans text-2xs"
        >
          {{ fields.bookLink.value.text }}
        </a>
      </div>
    </div>

    <Teleport v-if="showModal" to="#portal-modal">
      <GlueUiModal
        modal-classes="relative max-w-[1400px] w-full  h-[85vh] flex flex-col"
        @close="showModal = false"
      >
        <!-- Iframe -->
        <template v-if="currentIframeData">
          <iframe
            :src="currentIframeUrl"
            class="w-full border-0 flex-1 overflow-y-scroll"
          />
        </template>
      </GlueUiModal>
    </Teleport>
  </div>
</template>
