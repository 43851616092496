<script>
export default {
  name: 'IconDining',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 100"
  >
    <path
      transform="translate(-25,-25)"
      fill="currentColor"
      d="M85.3,49.3c-3.7,0-7.1,1-10.1,2.6c-3-1.7-6.4-2.6-10.1-2.6c-11.4,0-20.6,9.3-20.6,20.7v31.6h20.1h21.1H106V70
			C106,58.6,96.7,49.3,85.3,49.3z M64.7,100.6H45.6V70c0-10.9,8.8-19.7,19.6-19.7c3.3,0,6.4,0.8,9.1,2.2c-5.7,3.7-9.6,10.1-9.6,17.4
			V100.6z M84.8,100.6H65.7V70c0-7.2,3.8-13.4,9.6-16.9c5.7,3.4,9.6,9.7,9.6,16.9V100.6z M105,100.6H85.9V70
			c0-7.3-3.8-13.8-9.6-17.4c2.7-1.4,5.8-2.2,9.1-2.2c10.8,0,19.6,8.8,19.6,19.7V100.6z M85.3,44c-3.5,0-6.8,0.7-10.1,2.1
			c-3.2-1.4-6.6-2.1-10.1-2.1C50.9,44,39.3,55.7,39.3,70V107h72V70C111.3,55.7,99.7,44,85.3,44z M110.3,105.9h-70V70
			c0-13.8,11.2-25,24.9-25c3.4,0,6.7,0.7,9.9,2.1l0.2,0.1l0.2-0.1c3.1-1.4,6.5-2.1,9.9-2.1c13.8,0,24.9,11.2,24.9,25V105.9z"
    />
  </svg>
</template>
