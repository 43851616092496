<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  listingTypeKey: {
    type: String,
    required: true,
  },
  allListings: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(['deleteListing'])

const listings = computed(() => {
  return !props.allListings.length
    ? []
    : props.allListings.filter(
      (listing: any) => listing.type === props.listingTypeKey,
    )
})
</script>

<template>
  <div class="mb-16">
    <h2 class="text-center mb-4 uppercase">
      {{ title }}
    </h2>
    <div class="flex justify-center flex-wrap">
      <div
        v-if="!listings.length"
        class="p-8 shadow text-center font-sans2 bg-zinc-300 uppercase text-xs w-full"
      >
        Sorry, there are no listings at this time
      </div>
      <ReefProperty
        v-for="listing in listings"
        :key="listing.id"
        :listing="listing"
        @delete-listing="emit('deleteListing', listing)"
      />
    </div>
  </div>
</template>
