<script setup lang="ts">
// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper/modules'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

const props = defineProps<{
  slice: any
  fields: {
    navigation: {
      value: boolean
    }
    pagination: {
      value: boolean
    }
    slidesPerView: {
      value: number
    }
    spaceBetween: {
      value: number
    }
  }
}>()

const emit = defineEmits(['onSwiper', 'onSlideChange'])

const modules = computed(() => {
  const modules = []
  if (props.fields.navigation.value)
    modules.push(Navigation)

  if (props.fields.pagination.value)
    modules.push(Pagination)

  return modules
})

function onSwiper(swiper: any) {
  emit('onSwiper', swiper)
}

function onSlideChange(swiper: any) {
  emit('onSlideChange', swiper)
}
defineSlice({
  name: { label: 'General Slider', group: 'Sliders', layouts: ['Paranza'] },
  tags: ['Slider'],
  fields: {
    navigation: { type: 'checkbox', label: 'Navigation', default: true, name: 'navigation' },
    pagination: { type: 'checkbox', label: 'Pagination', default: true, name: 'pagination' },
    slidesPerView: { type: 'number', label: 'Slides Per View', default: 1, name: 'slidesPerView' },
    spaceBetween: { type: 'number', label: 'Space Between', default: 0, name: 'spaceBetween' },
  },
  slots: [{ name: 'default', allowedElements: [] }],
  preview: 'GeneralSlider.png',
})
</script>

<template>
  <Swiper
    :modules="modules"
    :slides-per-view="fields.slidesPerView.value"
    :space-between="fields.spaceBetween.value"
    @swiper="onSwiper"
    @slide-change="onSlideChange"
  >
    <SwiperSlide v-for="element in slice.elements" :key="element.id">
      <VoixSlice :slice="element" />
    </SwiperSlide>
  </Swiper>
</template>
