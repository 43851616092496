<script setup lang="ts">
import VoixToasts from '@voix/components/chrome/messages/VoixToasts.vue'
import { useVoixToast } from '@voix/composables/useVoixToast'
import { useStorage } from '@vueuse/core'
import { Filter } from 'bad-words'

// Interfaces
interface User {
  id: string | null
  name: string
  gameId: number
  games: Game[]
}
interface Game {
  id: number
  code: string
  complete: boolean
  name: string
  players: Player[]
}
interface Player {
  id: number
  name: string
  holes: number[]
}
interface Group {
  name: string
  code: string
  codeInput: string[]
  playerInput: string
  playerIndex: number
  play: boolean
}
interface Score {
  name: string
  value: number
}

defineSlice({
  name: { label: 'Golf Score Card', group: 'Golf', layouts: ['AtlantisMobileApp'] },
  preview: 'GolfScoreCard.jpg',
  description: 'Golf Score Card Slice',
  templates: [{
    label: 'Default',
    fields: {
    },
  }],
})

const voixToast = useVoixToast()

// State
const debug = true
const pages = ref([
  'start',
  'game',
  'leaderboard',
  'map',
  'play-solo',
  'start-group',
  'join-group',
  'add-players',
  'hole-1',
  'hole-2',
  'hole-3',
  'hole-4',
  'hole-5',
  'hole-6',
  'hole-7',
  'hole-8',
  'hole-9',
  'hole-10',
  'hole-11',
  'hole-12',
  'hole-13',
  'hole-14',
  'hole-15',
  'hole-16',
  'hole-17',
  'hole-18',
])
const pageHistory = ref(['start'])
const editStroke = ref(false)
const showFullScoreCard = ref(false)
const user = useStorage<User>('user', {
  id: null,
  name: '',
  gameId: -1,
  games: [],
})
const group = ref<Group>({
  name: '',
  code: '',
  codeInput: [],
  playerInput: '',
  playerIndex: 0,
  play: false,
})
const leaderboard = ref<Score[]>([])
const leaderboardType = ref('individual_scores')
const leaderboardTypes = ['group_averages', 'group_averages_to_par', 'group_totals', 'group_totals_to_par', 'individual_scores', 'individual_scores_to_par']
const holes = [
  {
    hole: 1,
    par: 3,
    inches: 425,
    tip: 'The devilish sand trap in on hole 1 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 130 280" class="scale-125 translate-x-5" version="1.1" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
  <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
    <path class="fill-green-500" fill-rule="evenodd" clip-rule="evenodd" d="M35.998,260.595c0,0 -11.543,-7.25 -13.235,-11.478c-1.691,-4.229 -4.05,-24.124 -5.753,-29.09c-1.703,-4.966 -7.234,-19.192 -7.73,-24.546c-0.496,-5.355 -0.566,-27.441 1.681,-38.158c2.247,-10.718 1.435,-15.4 5.378,-22.875c3.944,-7.476 13.929,-19.034 15.147,-23.052c1.218,-4.019 1.722,-26.415 -0.477,-29.368c-2.199,-2.953 1.978,5.53 2.736,-7.87c0.757,-13.401 0.679,-22.008 5.973,-25.707c5.294,-3.7 17.708,-10.265 26.504,-12.068c8.796,-1.803 15.554,-3.572 20.145,1.197c4.59,4.77 7.865,10.849 11.447,12.739c3.582,1.889 6.858,0.913 9.639,8.064c2.78,7.152 5.172,10.35 1.639,17.183c-3.533,6.833 -7.002,13.93 -13.809,16.754c-6.808,2.824 -8.821,-1.122 -14.808,3.138c-5.987,4.261 -7.992,10.854 -10.453,16.577c-2.461,5.722 -7.216,12.984 -7.438,17.689c-0.221,4.705 0.806,3.201 -0.958,8.419c-1.763,5.218 -6.159,9.662 -8.623,19.18c-2.464,9.517 -1.444,10.927 -0.666,18.43c0.779,7.503 2.578,7.058 1.974,15.694c-0.605,8.635 0.568,9.308 -1.171,15.13c-1.739,5.821 -2.36,4.589 -3.145,13.153c-0.786,8.563 -0.229,9.54 -0.128,16.367c0.1,6.827 4.302,15.939 0.168,20.104c-4.135,4.165 -6.117,3.675 -9.296,4.388c-3.18,0.713 -4.741,0.006 -4.741,0.006Z"/>
    <path class="fill-orange-400" d="M15.81,135.527c0,-0 -11.557,-8.921 -13.141,-11.743c-1.583,-2.823 -3.313,-9.85 -2.429,-16.248c0.883,-6.397 1.957,-14.428 11.54,-17.318c9.584,-2.89 11.902,-3.769 16.776,-7.93c4.873,-4.161 4.679,6.879 4.003,15.256c-0.676,8.378 0.86,11.669 -1.41,15.245c-2.27,3.575 -8.801,12.192 -11.043,15.212c-2.243,3.02 -4.296,7.526 -4.296,7.526Z"/>
    <path class="fill-orange-400" d="M62.459,135.527c0,-0 8.195,-14.683 27.312,-9.013c19.117,5.669 10.349,-14.473 7.601,-18.06c-2.749,-3.588 -5.34,-7.167 -4.086,-10.731c1.255,-3.565 0.738,-5.068 0.738,-5.068c0,-0 -9.639,-1.835 -14.279,3.298c-4.64,5.132 -10.097,14.704 -11.424,18.294c-1.326,3.591 -5.702,10.059 -5.877,13.23c-0.174,3.171 0.015,8.05 0.015,8.05Z"/>
    <path class="fill-orange-400" d="M77.158,34.513c0,-0 3.835,-1.935 3.394,-5.67c-0.441,-3.734 0.558,-12.097 3.525,-16.5c2.967,-4.402 5.145,-12.18 9.825,-12.281c4.68,-0.101 7.101,-0.563 9.615,3.947c2.515,4.509 6.916,8.552 7.346,13.554c0.429,5.002 -2.776,8.51 -7.128,12.383c-4.353,3.874 -7.64,5.368 -8.899,9.025c-1.259,3.657 0.093,3.194 -1.646,5.753c-1.74,2.559 -8.1,-7.996 -8.1,-7.996c0,-0 0.088,-1.685 -3.017,-2.215c-3.104,-0.531 -4.915,-0 -4.915,-0Z"/>
  </g>

  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M35.998,260.595c0,0 -11.543,-7.25 -13.235,-11.478c-1.691,-4.229 -4.05,-24.124 -5.753,-29.09c-1.703,-4.966 -7.234,-19.192 -7.73,-24.546c-0.496,-5.355 -0.566,-27.441 1.681,-38.158c2.247,-10.718 1.435,-15.4 5.378,-22.875c3.944,-7.476 13.929,-19.034 15.147,-23.052c1.218,-4.019 1.722,-26.415 -0.477,-29.368c-2.199,-2.953 1.978,5.53 2.736,-7.87c0.757,-13.401 0.679,-22.008 5.973,-25.707c5.294,-3.7 17.708,-10.265 26.504,-12.068c8.796,-1.803 15.554,-3.572 20.145,1.197c4.59,4.77 7.865,10.849 11.447,12.739c3.582,1.889 6.858,0.913 9.639,8.064c2.78,7.152 5.172,10.35 1.639,17.183c-3.533,6.833 -7.002,13.93 -13.809,16.754c-6.808,2.824 -8.821,-1.122 -14.808,3.138c-5.987,4.261 -7.992,10.854 -10.453,16.577c-2.461,5.722 -7.216,12.984 -7.438,17.689c-0.221,4.705 0.806,3.201 -0.958,8.419c-1.763,5.218 -6.159,9.662 -8.623,19.18c-2.464,9.517 -1.444,10.927 -0.666,18.43c0.779,7.503 2.578,7.058 1.974,15.694c-0.605,8.635 0.568,9.308 -1.171,15.13c-1.739,5.821 -2.36,4.589 -3.145,13.153c-0.786,8.563 -0.229,9.54 -0.128,16.367c0.1,6.827 4.302,15.939 0.168,20.104c-4.135,4.165 -6.117,3.675 -9.296,4.388c-3.18,0.713 -4.741,0.006 -4.741,0.006Z"/>
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M31.847,246.444l-0.692,-4.553c-0.044,-0.286 0.153,-0.553 0.439,-0.596l10.341,-1.571c0.286,-0.044 0.553,0.153 0.596,0.439l0.692,4.552c0.043,0.286 -0.153,0.553 -0.439,0.597l-10.341,1.571c-0.286,0.043 -0.553,-0.154 -0.596,-0.439Z"/>
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M76.248,61.948c0,-0.887 0.72,-1.607 1.608,-1.607c0.887,-0 1.608,0.72 1.608,1.607c-0,0.888 -0.721,1.608 -1.608,1.608c-0.888,-0 -1.608,-0.72 -1.608,-1.608Z"/>
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M15.81,135.527c0,-0 -11.557,-8.921 -13.141,-11.743c-1.583,-2.823 -3.313,-9.85 -2.429,-16.248c0.883,-6.397 1.957,-14.428 11.54,-17.318c9.584,-2.89 11.902,-3.769 16.776,-7.93c4.873,-4.161 4.679,6.879 4.003,15.256c-0.676,8.378 0.86,11.669 -1.41,15.245c-2.27,3.575 -8.801,12.192 -11.043,15.212c-2.243,3.02 -4.296,7.526 -4.296,7.526Z"/>
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M62.459,135.527c0,-0 8.195,-14.683 27.312,-9.013c19.117,5.669 10.349,-14.473 7.601,-18.06c-2.749,-3.588 -5.34,-7.167 -4.086,-10.731c1.255,-3.565 0.738,-5.068 0.738,-5.068c0,-0 -9.639,-1.835 -14.279,3.298c-4.64,5.132 -10.097,14.704 -11.424,18.294c-1.326,3.591 -5.702,10.059 -5.877,13.23c-0.174,3.171 0.015,8.05 0.015,8.05Z"/>
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M77.158,34.513c0,-0 3.835,-1.935 3.394,-5.67c-0.441,-3.734 0.558,-12.097 3.525,-16.5c2.967,-4.402 5.145,-12.18 9.825,-12.281c4.68,-0.101 7.101,-0.563 9.615,3.947c2.515,4.509 6.916,8.552 7.346,13.554c0.429,5.002 -2.776,8.51 -7.128,12.383c-4.353,3.874 -7.64,5.368 -8.899,9.025c-1.259,3.657 0.093,3.194 -1.646,5.753c-1.74,2.559 -8.1,-7.996 -8.1,-7.996c0,-0 0.088,-1.685 -3.017,-2.215c-3.104,-0.531 -4.915,-0 -4.915,-0Z"/>
  </g>
</svg>`,
  },
  {
    hole: 2,
    par: 3,
    inches: 510,
    tip: 'The devilish sand trap in on hole 2 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 111 261" class="translate-x-6 -rotate-[10deg]" version="1.1" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">

  <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
    <path class="fill-green-500" fill-rule="evenodd" clip-rule="evenodd" d="M1.521,232.561c2.793,2.094 2.002,6.767 9.938,7.147c7.936,0.381 18.03,-0.584 25.315,-5.282c7.286,-4.697 18.392,-16.434 19.923,-20.564c1.53,-4.13 14.843,-17.405 22.507,-27.946c7.664,-10.54 11.636,-22.292 11.197,-31.181c-0.44,-8.889 -3.111,-17.374 -3.86,-26.768c-0.748,-9.394 0.736,-18.332 1.547,-27.152c0.812,-8.82 -3.823,-11.796 2.351,-19.139c6.175,-7.343 10.182,-22.104 10.945,-30.538c0.762,-8.435 4.468,-14.766 2.729,-24.899c-1.739,-10.133 -7.279,-18.095 -11.709,-20.527c-4.43,-2.432 -6.926,-6.108 -14.597,-5.677c-7.671,0.43 -10.517,3.637 -16.195,7.548c-5.679,3.911 -7.811,5.92 -9.986,13.668c-2.176,7.748 -2.913,11.054 -1.503,18.5c1.41,7.446 2.405,15.913 2.805,23.2c0.399,7.288 2.348,15.391 0.545,20.471c-1.803,5.081 -1.094,4.872 -6.067,7.932c-4.973,3.061 -6.499,-1.281 -7.939,7.336c-1.44,8.616 -2.213,11.722 -2.866,22.302c-0.654,10.58 -2.55,13.469 -1.612,18.387c0.937,4.918 -3.016,5.69 -0,12.699c3.016,7.01 4.954,8.147 6.92,9.948c1.965,1.801 4.37,1.936 2.938,6.427c-1.431,4.491 -3.519,9.158 -7.066,13.689c-3.546,4.531 -9.047,8.39 -13.276,10.874c-4.23,2.484 -11.171,5.535 -14.898,11.177c-3.728,5.643 -8.703,15 -8.852,19.457c-0.148,4.456 -2.026,6.817 0.766,8.911Z"/>
    <path class="fill-orange-400" d="M26.754,115.078c-0,-0 -0.836,-6.899 1.733,-9.374c2.568,-2.475 6.174,-7.289 8.486,-8.133c2.312,-0.844 5.128,-6.054 7.203,-4.593c2.074,1.461 2.969,4.928 3.471,9.184c0.501,4.257 1.301,7.362 -0.018,10.607c-1.32,3.246 -0.844,6.901 0.284,9.521c1.128,2.62 1.759,6.111 0.882,9.022c-0.876,2.911 -2.048,6.397 -4.103,8.803c-2.054,2.406 -3.465,4.703 -4.358,6.22c-0.892,1.516 0.085,1.554 -2.262,3.488c-2.347,1.934 -3.083,1.767 -3.083,1.767c-0,-0 -1.398,-5.884 -3.135,-9.849c-1.737,-3.965 -1.682,-12.737 -2.566,-16.18c-0.884,-3.443 -2.534,-10.483 -2.534,-10.483Z"/>    
  </g>

  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M1.521,232.561c2.793,2.094 2.002,6.767 9.938,7.147c7.936,0.381 18.03,-0.584 25.315,-5.282c7.286,-4.697 18.392,-16.434 19.923,-20.564c1.53,-4.13 14.843,-17.405 22.507,-27.946c7.664,-10.54 11.636,-22.292 11.197,-31.181c-0.44,-8.889 -3.111,-17.374 -3.86,-26.768c-0.748,-9.394 0.736,-18.332 1.547,-27.152c0.812,-8.82 -3.823,-11.796 2.351,-19.139c6.175,-7.343 10.182,-22.104 10.945,-30.538c0.762,-8.435 4.468,-14.766 2.729,-24.899c-1.739,-10.133 -7.279,-18.095 -11.709,-20.527c-4.43,-2.432 -6.926,-6.108 -14.597,-5.677c-7.671,0.43 -10.517,3.637 -16.195,7.548c-5.679,3.911 -7.811,5.92 -9.986,13.668c-2.176,7.748 -2.913,11.054 -1.503,18.5c1.41,7.446 2.405,15.913 2.805,23.2c0.399,7.288 2.348,15.391 0.545,20.471c-1.803,5.081 -1.094,4.872 -6.067,7.932c-4.973,3.061 -6.499,-1.281 -7.939,7.336c-1.44,8.616 -2.213,11.722 -2.866,22.302c-0.654,10.58 -2.55,13.469 -1.612,18.387c0.937,4.918 -3.016,5.69 -0,12.699c3.016,7.01 4.954,8.147 6.92,9.948c1.965,1.801 4.37,1.936 2.938,6.427c-1.431,4.491 -3.519,9.158 -7.066,13.689c-3.546,4.531 -9.047,8.39 -13.276,10.874c-4.23,2.484 -11.171,5.535 -14.898,11.177c-3.728,5.643 -8.703,15 -8.852,19.457c-0.148,4.456 -2.026,6.817 0.766,8.911Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M6.166,224.192l3.289,-3.223c0.207,-0.202 0.538,-0.199 0.741,0.008l7.32,7.471c0.202,0.207 0.199,0.539 -0.008,0.741l-3.289,3.222c-0.207,0.203 -0.538,0.199 -0.741,-0.007l-7.32,-7.472c-0.202,-0.206 -0.199,-0.538 0.008,-0.74Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M75.153,20.94c-0,-0.887 0.72,-1.607 1.607,-1.607c0.888,-0 1.608,0.72 1.608,1.607c0,0.888 -0.72,1.608 -1.608,1.608c-0.887,-0 -1.607,-0.72 -1.607,-1.608Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M26.754,115.078c-0,-0 -0.836,-6.899 1.733,-9.374c2.568,-2.475 6.174,-7.289 8.486,-8.133c2.312,-0.844 5.128,-6.054 7.203,-4.593c2.074,1.461 2.969,4.928 3.471,9.184c0.501,4.257 1.301,7.362 -0.018,10.607c-1.32,3.246 -0.844,6.901 0.284,9.521c1.128,2.62 1.759,6.111 0.882,9.022c-0.876,2.911 -2.048,6.397 -4.103,8.803c-2.054,2.406 -3.465,4.703 -4.358,6.22c-0.892,1.516 0.085,1.554 -2.262,3.488c-2.347,1.934 -3.083,1.767 -3.083,1.767c-0,-0 -1.398,-5.884 -3.135,-9.849c-1.737,-3.965 -1.682,-12.737 -2.566,-16.18c-0.884,-3.443 -2.534,-10.483 -2.534,-10.483Z" />
  </g>
</svg>`,
  },
  {
    hole: 3,
    par: 3,
    inches: 340,
    tip: 'The devilish sand trap in on hole 3 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 140 175" class="scale-125 translate-x-5 -rotate-45" version="1.1" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">

  <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
   <path class="fill-green-500" d="M4.342,156.911c-0,0 1.159,3.05 6.514,5.246c5.354,2.196 11.807,3.766 14.531,2.384c2.724,-1.381 9.608,-10.549 13.252,-11.844c3.644,-1.296 16.545,-12.882 20.522,-14.486c3.976,-1.604 45.95,-33.257 51.517,-38.401c5.567,-5.144 21.27,-29.993 21.314,-44.724c0.043,-14.731 -1.566,-26.847 -7.103,-32.89c-5.536,-6.043 -18.773,-19.091 -26.482,-20.761c-7.708,-1.671 -27.012,-2.7 -31.626,1.686c-4.613,4.385 -9.347,13.193 -11.926,25.66c-2.578,12.467 -4.307,12 -7.676,19.431c-3.369,7.432 -8.663,4.961 -11.469,21.727c-2.805,16.767 -8.522,31.578 -8.522,31.578c-0,-0 0.536,9.251 -2.732,12.598c-3.269,3.348 -11.007,7.526 -13.6,12.127c-2.593,4.601 -5.667,9.814 -8.413,12.393c-2.746,2.578 -2.894,4.769 -1.829,10.292c1.065,5.522 3.728,7.984 3.728,7.984Z"/>
    <path class="fill-orange-400" d="M25.187,74.787c1.994,-2.419 6.334,-9.535 9.327,-12.708c2.993,-3.174 10.135,-10.364 12.679,-14.657c2.544,-4.293 5.32,-9.147 5.32,-9.147c-0,-0 10.506,8.682 12.224,13.2c1.717,4.517 0.251,9.985 1.209,13.541c0.958,3.557 3.287,5.635 2.539,11.076c-0.748,5.441 -3.242,11.372 -6.934,13.905c-3.692,2.532 -10.26,3.585 -11.391,6.212c-1.13,2.628 0.032,4.306 -3.591,7.051c-3.623,2.744 -8.921,4.122 -11.209,5.28c-2.289,1.159 -10.909,5.575 -10.909,5.575c-0,0 -5.111,-13.751 -5.022,-18.888c0.088,-5.137 1.085,-11.67 2.307,-14.358c1.221,-2.689 1.458,-3.664 3.451,-6.082Z" />
 </g>

  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M4.342,156.911c-0,0 1.159,3.05 6.514,5.246c5.354,2.196 11.807,3.766 14.531,2.384c2.724,-1.381 9.608,-10.549 13.252,-11.844c3.644,-1.296 16.545,-12.882 20.522,-14.486c3.976,-1.604 45.95,-33.257 51.517,-38.401c5.567,-5.144 21.27,-29.993 21.314,-44.724c0.043,-14.731 -1.566,-26.847 -7.103,-32.89c-5.536,-6.043 -18.773,-19.091 -26.482,-20.761c-7.708,-1.671 -27.012,-2.7 -31.626,1.686c-4.613,4.385 -9.347,13.193 -11.926,25.66c-2.578,12.467 -4.307,12 -7.676,19.431c-3.369,7.432 -8.663,4.961 -11.469,21.727c-2.805,16.767 -8.522,31.578 -8.522,31.578c-0,-0 0.536,9.251 -2.732,12.598c-3.269,3.348 -11.007,7.526 -13.6,12.127c-2.593,4.601 -5.667,9.814 -8.413,12.393c-2.746,2.578 -2.894,4.769 -1.829,10.292c1.065,5.522 3.728,7.984 3.728,7.984Z"/>
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M11.075,147.457l3.744,-2.681c0.235,-0.168 0.562,-0.114 0.731,0.121l6.088,8.506c0.168,0.235 0.114,0.562 -0.121,0.73l-3.745,2.681c-0.235,0.168 -0.562,0.114 -0.73,-0.121l-6.088,-8.506c-0.169,-0.235 -0.114,-0.562 0.121,-0.73Z"/>
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M87.145,35.849c0,-0.888 0.721,-1.608 1.608,-1.608c0.888,0 1.608,0.72 1.608,1.608c-0,0.887 -0.72,1.607 -1.608,1.607c-0.887,0 -1.608,-0.72 -1.608,-1.607Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M25.187,74.787c1.994,-2.419 6.334,-9.535 9.327,-12.708c2.993,-3.174 10.135,-10.364 12.679,-14.657c2.544,-4.293 5.32,-9.147 5.32,-9.147c-0,-0 10.506,8.682 12.224,13.2c1.717,4.517 0.251,9.985 1.209,13.541c0.958,3.557 3.287,5.635 2.539,11.076c-0.748,5.441 -3.242,11.372 -6.934,13.905c-3.692,2.532 -10.26,3.585 -11.391,6.212c-1.13,2.628 0.032,4.306 -3.591,7.051c-3.623,2.744 -8.921,4.122 -11.209,5.28c-2.289,1.159 -10.909,5.575 -10.909,5.575c-0,0 -5.111,-13.751 -5.022,-18.888c0.088,-5.137 1.085,-11.67 2.307,-14.358c1.221,-2.689 1.458,-3.664 3.451,-6.082Z" />
  </g>
</svg>`,
  },
  {
    hole: 4,
    par: 4,
    inches: 505,
    tip: 'The devilish sand trap in on hole 4 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 100 275" class="scale-100 translate-y-2" version="1.1"
   style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
  <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
   <path class="fill-green-500" d="M52.066,259.474c0,0 -4.713,6.431 -8.187,6.262c-3.474,-0.169 -10.456,-0.658 -13.094,-4.245c-2.637,-3.586 -5.475,-13.561 -4.736,-21.347c0.739,-7.786 3.476,-38.532 4.21,-43.211c0.735,-4.678 -1.094,-34.29 -0.252,-40.672c0.841,-6.381 0.762,-24.852 -0.921,-29.38c-1.683,-4.528 -1.108,-9.294 -2.906,-10.347c-1.797,-1.053 -10.227,-6.573 -11.497,-16.496c-1.271,-9.923 -6.329,-43.828 -4.26,-52.282c2.069,-8.454 5.563,-3.938 5.119,-8.378c-0.445,-4.44 1.737,-19.4 5.602,-23.4c3.865,-3.999 16.875,-13.895 22.066,-15.037c5.191,-1.142 27.004,-1.835 31.227,1.52c4.222,3.354 10.215,2.847 12.281,10.989c2.066,8.143 1.305,20.256 -1.65,26.693c-2.955,6.437 -9.213,15.021 -9.856,18.543c-0.643,3.521 -5.953,6.08 -7.1,13.027c-1.147,6.946 -1.052,9.801 1.32,16.115c2.371,6.313 4.945,17.877 4.04,23.303c-0.906,5.427 -0.16,21.498 -0.523,31.56c-0.364,10.062 -1.854,12.7 -1.854,12.7c0,0 -13.291,17.54 -12.77,22.862c0.52,5.322 4.617,36.011 3.42,43.027c-1.196,7.015 -5.153,15.337 -6.039,21.233c-0.886,5.896 -3.64,16.961 -3.64,16.961Z"/>
    <path class="fill-orange-400" d="M22.727,114.385c0,-0 4.429,-3.996 2.465,-8.604c-1.963,-4.608 -2.603,-2.354 -2.212,-5.836c0.39,-3.481 1.799,-4.695 1.383,-7.133c-0.416,-2.439 -3.075,-8.923 -3.362,-12.237c-0.288,-3.315 1.303,-14.161 0.416,-19.786c-0.888,-5.625 -3.165,-14.779 -6.102,-17.478c-2.936,-2.699 -3.772,-2.588 -6.287,-0.754c-2.515,1.833 -6.478,6.601 -7.901,12.754c-1.422,6.153 -1.571,25.168 -0.03,30.202c1.542,5.035 5.399,12.985 9.047,18.614c3.648,5.629 6.497,7.101 7.954,7.989c1.456,0.889 4.629,2.269 4.629,2.269Z" />
    <path class="fill-orange-400" d="M64.28,125.909c0.635,-6.096 1.507,-23.826 3.791,-27.474c2.285,-3.648 3.543,-4.091 3.543,-4.091c-0,-0 2.368,10.967 5.42,15.279c3.052,4.313 7.696,12.878 8.349,15.207c0.653,2.329 2.216,2.691 1.751,4.811c-0.465,2.12 -15.467,22.075 -15.317,25.159c0.151,3.084 -9.059,-10.114 -7.395,-14.673c1.247,-3.418 -0.776,-8.123 -0.142,-14.218Z" />
  </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M52.066,259.474c0,0 -4.713,6.431 -8.187,6.262c-3.474,-0.169 -10.456,-0.658 -13.094,-4.245c-2.637,-3.586 -5.475,-13.561 -4.736,-21.347c0.739,-7.786 3.476,-38.532 4.21,-43.211c0.735,-4.678 -1.094,-34.29 -0.252,-40.672c0.841,-6.381 0.762,-24.852 -0.921,-29.38c-1.683,-4.528 -1.108,-9.294 -2.906,-10.347c-1.797,-1.053 -10.227,-6.573 -11.497,-16.496c-1.271,-9.923 -6.329,-43.828 -4.26,-52.282c2.069,-8.454 5.563,-3.938 5.119,-8.378c-0.445,-4.44 1.737,-19.4 5.602,-23.4c3.865,-3.999 16.875,-13.895 22.066,-15.037c5.191,-1.142 27.004,-1.835 31.227,1.52c4.222,3.354 10.215,2.847 12.281,10.989c2.066,8.143 1.305,20.256 -1.65,26.693c-2.955,6.437 -9.213,15.021 -9.856,18.543c-0.643,3.521 -5.953,6.08 -7.1,13.027c-1.147,6.946 -1.052,9.801 1.32,16.115c2.371,6.313 4.945,17.877 4.04,23.303c-0.906,5.427 -0.16,21.498 -0.523,31.56c-0.364,10.062 -1.854,12.7 -1.854,12.7c0,0 -13.291,17.54 -12.77,22.862c0.52,5.322 4.617,36.011 3.42,43.027c-1.196,7.015 -5.153,15.337 -6.039,21.233c-0.886,5.896 -3.64,16.961 -3.64,16.961Z"/>
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M33.335,256.479l1.171,-4.454c0.073,-0.279 0.36,-0.447 0.64,-0.373l10.115,2.66c0.28,0.074 0.447,0.36 0.374,0.64l-1.171,4.453c-0.074,0.28 -0.361,0.447 -0.64,0.373l-10.116,-2.66c-0.279,-0.073 -0.447,-0.36 -0.373,-0.639Z"/>
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M56.309,23.581c-0.357,-0.813 0.014,-1.762 0.827,-2.118c0.812,-0.356 1.761,0.014 2.118,0.827c0.356,0.812 -0.015,1.761 -0.827,2.118c-0.813,0.356 -1.762,-0.014 -2.118,-0.827Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M22.727,114.385c0,-0 4.429,-3.996 2.465,-8.604c-1.963,-4.608 -2.603,-2.354 -2.212,-5.836c0.39,-3.481 1.799,-4.695 1.383,-7.133c-0.416,-2.439 -3.075,-8.923 -3.362,-12.237c-0.288,-3.315 1.303,-14.161 0.416,-19.786c-0.888,-5.625 -3.165,-14.779 -6.102,-17.478c-2.936,-2.699 -3.772,-2.588 -6.287,-0.754c-2.515,1.833 -6.478,6.601 -7.901,12.754c-1.422,6.153 -1.571,25.168 -0.03,30.202c1.542,5.035 5.399,12.985 9.047,18.614c3.648,5.629 6.497,7.101 7.954,7.989c1.456,0.889 4.629,2.269 4.629,2.269Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M64.28,125.909c0.635,-6.096 1.507,-23.826 3.791,-27.474c2.285,-3.648 3.543,-4.091 3.543,-4.091c-0,-0 2.368,10.967 5.42,15.279c3.052,4.313 7.696,12.878 8.349,15.207c0.653,2.329 2.216,2.691 1.751,4.811c-0.465,2.12 -15.467,22.075 -15.317,25.159c0.151,3.084 -9.059,-10.114 -7.395,-14.673c1.247,-3.418 -0.776,-8.123 -0.142,-14.218Z" />
  </g>
</svg>`,
  },
  {
    hole: 5,
    par: 3,
    inches: 405,
    tip: 'The devilish sand trap in on hole 1 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 115 210" class="scale-125 rotate-[15deg] translate-y-6 translate-x-2" version="1.1"
   style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
     <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
      <path class="fill-green-500" d="M82.004,21.917c-0.022,-0.168 -0.107,-0.321 -0.238,-0.429c-0.965,-0.79 -5.088,-4.173 -6.44,-5.442c-1.598,-1.5 -4.742,-6.046 -6.557,-7.257c-8.633,-5.758 -7.979,-5.388 -15.517,-6.682c-7.537,-1.294 -19.025,-6.11 -27.945,5.375c-8.919,11.485 -17.209,22.015 -15.015,28.13c2.193,6.114 -2.148,17.169 -2.384,33.383c-0.236,16.213 13.377,28.762 16.887,35.942c1.751,3.581 13.036,11.091 15.597,22.117c4.022,17.314 7.348,10.554 9.485,13.276c4.818,6.139 7.446,8.167 10.784,15.057c3.337,6.89 4.352,23.368 16.768,34.986c3.785,3.541 17.226,9.438 23.828,2.475c17.268,-18.212 -6.324,-34.635 -11.989,-43.394c-5.666,-8.76 -13.116,-15.397 -13.189,-32.735c-0.074,-17.338 0.271,-23.509 1.955,-32.06c3.303,-16.766 -2.798,-16.289 -2.448,-27.668c0.351,-11.403 3.336,-12.588 4.335,-19.086c0.723,-4.707 2.591,-11.696 2.083,-15.988Z"/>
      <path class="fill-orange-400" d="M53.926,94.786c-5.906,0.345 -15.106,-4.894 -14.61,-8.655c0.851,-6.446 -4.349,-6.712 -4.207,-10.434c0.161,-4.235 0.695,-4.593 6.209,-8.819c4.758,-3.648 1.057,-1.504 7.465,-2.333c6.071,-0.785 5.765,-1.629 8.474,0.61c4.836,3.997 1.628,1.208 3.218,5.393c1.59,4.185 2.17,3.806 1.579,9.679c-0.591,5.872 4.018,7.966 1.749,11.343c-2.27,3.377 -3.97,2.871 -9.877,3.216Z" />
      <path class="fill-orange-400" d="M43.547,138.762c-0,-0 -3.573,3.323 -7.969,1.672c-4.397,-1.652 -6.948,-4.131 -11.773,-5.087c-4.825,-0.955 -12.999,-3.612 -15.949,-7.738c-2.951,-4.126 -8.354,-8.152 -7.819,-16.314c0.535,-8.162 -0.081,-12.576 1.477,-17.97c1.557,-5.394 -0.094,-8.889 2.749,-10.586c2.597,-1.549 1.996,-3.317 3.678,-8.297c0.091,-0.271 3.017,8.911 3.252,9.136c2.704,2.589 5.504,3.088 6.778,5.982c1.274,2.894 0.387,3.018 2.207,5.485c1.819,2.467 5.022,9.754 7.235,12.845c2.213,3.091 6.933,4.367 9.586,9.478c2.653,5.112 3.446,6.642 4.338,10.601c0.77,3.422 1.893,4.733 2.067,6.903c0.175,2.169 0.143,3.89 0.143,3.89Z" />
    </g>
    <g>
      <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M82.004,21.917c-0.022,-0.168 -0.107,-0.321 -0.238,-0.429c-0.965,-0.79 -5.088,-4.173 -6.44,-5.442c-1.598,-1.5 -4.742,-6.046 -6.557,-7.257c-8.633,-5.758 -7.979,-5.388 -15.517,-6.682c-7.537,-1.294 -19.025,-6.11 -27.945,5.375c-8.919,11.485 -17.209,22.015 -15.015,28.13c2.193,6.114 -2.148,17.169 -2.384,33.383c-0.236,16.213 13.377,28.762 16.887,35.942c1.751,3.581 13.036,11.091 15.597,22.117c4.022,17.314 7.348,10.554 9.485,13.276c4.818,6.139 7.446,8.167 10.784,15.057c3.337,6.89 4.352,23.368 16.768,34.986c3.785,3.541 17.226,9.438 23.828,2.475c17.268,-18.212 -6.324,-34.635 -11.989,-43.394c-5.666,-8.76 -13.116,-15.397 -13.189,-32.735c-0.074,-17.338 0.271,-23.509 1.955,-32.06c3.303,-16.766 -2.798,-16.289 -2.448,-27.668c0.351,-11.403 3.336,-12.588 4.335,-19.086c0.723,-4.707 2.591,-11.696 2.083,-15.988Z"/>
      <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M53.926,94.786c-5.906,0.345 -15.106,-4.894 -14.61,-8.655c0.851,-6.446 -4.349,-6.712 -4.207,-10.434c0.161,-4.235 0.695,-4.593 6.209,-8.819c4.758,-3.648 1.057,-1.504 7.465,-2.333c6.071,-0.785 5.765,-1.629 8.474,0.61c4.836,3.997 1.628,1.208 3.218,5.393c1.59,4.185 2.17,3.806 1.579,9.679c-0.591,5.872 4.018,7.966 1.749,11.343c-2.27,3.377 -3.97,2.871 -9.877,3.216Z" />
      <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M43.547,138.762c-0,-0 -3.573,3.323 -7.969,1.672c-4.397,-1.652 -6.948,-4.131 -11.773,-5.087c-4.825,-0.955 -12.999,-3.612 -15.949,-7.738c-2.951,-4.126 -8.354,-8.152 -7.819,-16.314c0.535,-8.162 -0.081,-12.576 1.477,-17.97c1.557,-5.394 -0.094,-8.889 2.749,-10.586c2.597,-1.549 1.996,-3.317 3.678,-8.297c0.091,-0.271 3.017,8.911 3.252,9.136c2.704,2.589 5.504,3.088 6.778,5.982c1.274,2.894 0.387,3.018 2.207,5.485c1.819,2.467 5.022,9.754 7.235,12.845c2.213,3.091 6.933,4.367 9.586,9.478c2.653,5.112 3.446,6.642 4.338,10.601c0.77,3.422 1.893,4.733 2.067,6.903c0.175,2.169 0.143,3.89 0.143,3.89Z" />
      <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M53.452,29.88c-0.887,0.006 -1.613,-0.709 -1.619,-1.596c-0.007,-0.887 0.708,-1.613 1.596,-1.619c0.887,-0.007 1.613,0.708 1.619,1.596c0.007,0.887 -0.708,1.612 -1.596,1.619Z" />
      <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M88.396,190.329l-2.089,-4.104c-0.131,-0.258 -0.028,-0.573 0.23,-0.704l9.321,-4.745c0.258,-0.131 0.573,-0.028 0.704,0.229l2.089,4.104c0.131,0.257 0.029,0.573 -0.229,0.704l-9.322,4.745c-0.257,0.131 -0.573,0.028 -0.704,-0.229Z"/>
    </g>
</svg>`,
  },
  {
    hole: 6,
    par: 3,
    inches: 335,
    tip: 'The devilish sand trap in on hole 2 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 80 205" version="1.1"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
       <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
      <path class="fill-green-500" d="M31.35,193.658c-2.742,0.109 -11.916,-3.371 -13.914,-7.381c-1.997,-4.01 -6.03,-10.123 -6.017,-13.486c0.012,-3.363 0.126,-15.3 2.901,-18.915c2.775,-3.616 3.602,-5.314 4.512,-8.566c0.911,-3.253 0.96,-3.402 0.356,-5.417c-0.603,-2.015 -4.374,-24.779 -3.918,-29.17c0.457,-4.392 -0.132,-23.484 -0.378,-27.642c-0.245,-4.158 -0.511,-7.985 0.912,-10.192c1.423,-2.207 3.008,-1.769 2.498,-4.135c-0.51,-2.367 -3.477,-7.365 -5.81,-12.71c-2.332,-5.346 -14.578,-30.487 -12.183,-34.821c2.394,-4.333 15.121,-18.942 21.455,-21.023c2.779,-0.914 23.466,1.421 26.25,4.713c3.611,4.271 7.044,15.185 8.552,21.2c1.509,6.016 5.278,32.071 3.407,36.061c-1.87,3.989 -5.199,13.434 -5.998,16.422c-0.799,2.987 -5.558,7.702 -3.687,10.636c1.87,2.934 4.099,3.11 5.115,5.742c1.016,2.631 0.072,7.91 0.165,10.885c0.093,2.975 0.513,7.512 1.339,9.969c0.826,2.458 4.512,14.188 2.544,16.032c-1.968,1.844 -11.042,7.428 -11.576,10.569c-0.533,3.14 0.22,22.877 0.362,26.922c0.142,4.045 -1.223,11.537 -1.982,13.23c-0.76,1.692 -6.812,7.302 -8.323,8.275c-1.512,0.973 -3.839,2.692 -6.582,2.802Z" />
    <path class="fill-orange-400" d="M66.292,95.366l-12.391,-1.507c-0,-0 -1.964,0.853 -2.183,4.165c-0.218,3.312 1.321,6.013 1.608,9.807c0.287,3.795 -2.451,5.215 -0.952,7.676c1.498,2.46 1.803,4.514 6.811,4.681c5.008,0.168 9.799,-5.502 10.048,-8.886c0.25,-3.384 0.821,-5.757 -0.137,-9.345c-0.957,-3.588 -2.804,-6.591 -2.804,-6.591Z" />
    <path class="fill-orange-400" d="M5.786,99.718c0,0 -0.628,7.128 -0.32,12.4c0.308,5.272 -0.067,9.342 1.298,11.909c1.365,2.567 4.091,10.073 6.99,11.933c2.899,1.86 6.032,4.713 6.032,4.713c-0,-0 3.154,-6.017 3.144,-7.804c-0.01,-1.787 0.801,-6.555 0.582,-9.44c-0.22,-2.885 -2.076,-4.282 -1.769,-7.721c0.308,-3.439 2.716,-6.957 2.515,-10.459c-0.201,-3.501 -2.1,-5.976 -1.967,-8.676c0.133,-2.699 3.182,-7.918 2.812,-10.024c-0.369,-2.105 -4.87,-10.966 -6.765,-12.03c-1.895,-1.063 -2.907,-1.463 -2.907,-1.463c0,-0 -7.158,9.851 -8.37,14.897c-1.212,5.046 -1.275,11.765 -1.275,11.765Z" />
    </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M31.35,193.658c-2.742,0.109 -11.916,-3.371 -13.914,-7.381c-1.997,-4.01 -6.03,-10.123 -6.017,-13.486c0.012,-3.363 0.126,-15.3 2.901,-18.915c2.775,-3.616 3.602,-5.314 4.512,-8.566c0.911,-3.253 0.96,-3.402 0.356,-5.417c-0.603,-2.015 -4.374,-24.779 -3.918,-29.17c0.457,-4.392 -0.132,-23.484 -0.378,-27.642c-0.245,-4.158 -0.511,-7.985 0.912,-10.192c1.423,-2.207 3.008,-1.769 2.498,-4.135c-0.51,-2.367 -3.477,-7.365 -5.81,-12.71c-2.332,-5.346 -14.578,-30.487 -12.183,-34.821c2.394,-4.333 15.121,-18.942 21.455,-21.023c2.779,-0.914 23.466,1.421 26.25,4.713c3.611,4.271 7.044,15.185 8.552,21.2c1.509,6.016 5.278,32.071 3.407,36.061c-1.87,3.989 -5.199,13.434 -5.998,16.422c-0.799,2.987 -5.558,7.702 -3.687,10.636c1.87,2.934 4.099,3.11 5.115,5.742c1.016,2.631 0.072,7.91 0.165,10.885c0.093,2.975 0.513,7.512 1.339,9.969c0.826,2.458 4.512,14.188 2.544,16.032c-1.968,1.844 -11.042,7.428 -11.576,10.569c-0.533,3.14 0.22,22.877 0.362,26.922c0.142,4.045 -1.223,11.537 -1.982,13.23c-0.76,1.692 -6.812,7.302 -8.323,8.275c-1.512,0.973 -3.839,2.692 -6.582,2.802Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M66.292,95.366l-12.391,-1.507c-0,-0 -1.964,0.853 -2.183,4.165c-0.218,3.312 1.321,6.013 1.608,9.807c0.287,3.795 -2.451,5.215 -0.952,7.676c1.498,2.46 1.803,4.514 6.811,4.681c5.008,0.168 9.799,-5.502 10.048,-8.886c0.25,-3.384 0.821,-5.757 -0.137,-9.345c-0.957,-3.588 -2.804,-6.591 -2.804,-6.591Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M5.786,99.718c0,0 -0.628,7.128 -0.32,12.4c0.308,5.272 -0.067,9.342 1.298,11.909c1.365,2.567 4.091,10.073 6.99,11.933c2.899,1.86 6.032,4.713 6.032,4.713c-0,-0 3.154,-6.017 3.144,-7.804c-0.01,-1.787 0.801,-6.555 0.582,-9.44c-0.22,-2.885 -2.076,-4.282 -1.769,-7.721c0.308,-3.439 2.716,-6.957 2.515,-10.459c-0.201,-3.501 -2.1,-5.976 -1.967,-8.676c0.133,-2.699 3.182,-7.918 2.812,-10.024c-0.369,-2.105 -4.87,-10.966 -6.765,-12.03c-1.895,-1.063 -2.907,-1.463 -2.907,-1.463c0,-0 -7.158,9.851 -8.37,14.897c-1.212,5.046 -1.275,11.765 -1.275,11.765Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M37.344,181.873l-0.509,4.577c-0.032,0.287 -0.291,0.495 -0.578,0.463l-10.396,-1.156c-0.287,-0.032 -0.495,-0.291 -0.463,-0.579l0.509,-4.576c0.032,-0.288 0.291,-0.495 0.578,-0.463l10.396,1.156c0.287,0.032 0.495,0.291 0.463,0.578Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M28.261,28.394c0.284,0.841 -0.169,1.754 -1.01,2.037c-0.841,0.284 -1.754,-0.169 -2.037,-1.01c-0.283,-0.841 0.169,-1.754 1.01,-2.037c0.841,-0.283 1.754,0.169 2.037,1.01Z" />
  </g>
</svg>`,
  },
  {
    hole: 7,
    par: 3,
    inches: 395,
    tip: 'The devilish sand trap in on hole 3 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 90 230" version="1.1"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
       <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
       <path class="fill-green-500" d="M19.917,1.074c-4.758,1.778 -11.167,5.819 -12.473,9.804c-1.307,3.984 -6.952,19.644 -5.599,23.988c1.353,4.344 3.839,11.536 3.332,16.456c-0.506,4.92 -1.416,7.013 -1.759,8.815c-0.342,1.801 1.174,19.446 5.088,25.141c3.915,5.695 16.267,25.027 18.161,29.799c1.893,4.771 2.746,3.196 2.505,6.077c-0.241,2.881 -3.827,37.278 -5.428,43.801c-1.601,6.522 -6.054,13.876 -7.172,18.896c-1.118,5.02 -3.01,26.087 -1.174,28.121c1.835,2.034 5.753,4.764 8.966,5.352c3.213,0.588 11.595,0.146 13.619,-2.335c2.023,-2.481 9.725,-10.313 11.333,-15.494c1.607,-5.18 10.415,-43.054 11.461,-48.13c1.047,-5.077 5.587,-22.889 4.468,-26.819c-1.12,-3.929 -6.348,-8.728 -6.496,-11.821c-0.147,-3.093 2.539,-5.799 2.479,-10.581c-0.06,-4.781 5.108,-41.201 5.108,-41.201l-1.719,-8.921c0,0 -2.289,-0.485 -3.026,-3.084c-0.737,-2.6 -10.446,-29.521 -12.902,-32.19c-2.456,-2.669 -8.828,-10.521 -12.127,-11.566c-3.299,-1.045 -8.164,-4.418 -10.532,-4.701c-2.369,-0.283 -1.355,-1.186 -6.113,0.593Z" />
    <path class="fill-orange-400" d="M74.321,55.502c-1.265,-1.484 -2.511,-2.537 -3.911,-3.198c-1.399,-0.66 -3.591,-3.269 -5.236,-1.184c-1.645,2.085 -1.799,3.62 -1.739,5.52c0.06,1.899 -1.503,2.645 -3.313,4.418c-1.81,1.773 -1.115,1.535 -1.433,3.412c-0.318,1.877 -0.931,4.326 -2.034,6.213c-1.103,1.887 0.615,2.269 -0.561,4.081c-1.177,1.811 -3.687,2.665 -3.356,5.648c0.331,2.984 1.984,4.341 1.834,6.788c-0.15,2.448 -2.005,4.716 -0.676,6.896c1.329,2.179 3.416,6.796 5.462,7.363c2.046,0.567 3.739,1.08 5.303,-0.881c1.564,-1.961 3.362,-4.349 5.334,-5.566c1.971,-1.217 3.201,-3.985 4.23,-5.883c1.029,-1.897 3.433,-4.902 4.321,-7.245c0.887,-2.344 -0.152,-9.632 0.67,-11.74c0.822,-2.108 1.937,-3.194 1.138,-5.078c-0.798,-1.884 -3.288,-2.587 -4.177,-4.213c-0.888,-1.626 -0.591,-3.868 -1.856,-5.351Z" />
    <path class="fill-orange-400" d="M5.737,92.584c3.618,5.986 11.941,16.383 13.311,18.086c1.37,1.702 3.303,5.778 4.954,2.348c1.651,-3.43 4.608,-4.937 3.445,-7.723c-1.163,-2.786 -3.149,-2.375 -3.44,-5.175c-0.29,-2.8 0.794,-5.367 -0.537,-7.822c-1.332,-2.455 -3.33,-3.47 -4.717,-5.657c-1.387,-2.187 -0.033,-3.599 -1.531,-6.282c-1.499,-2.683 -5.918,-6.52 -6.578,-8.472c-0.659,-1.953 0.387,-5.285 -0.836,-6.998c-1.224,-1.712 -5.41,-3.517 -6.916,-1.759c-1.506,1.758 -3.369,8.266 -2.781,12.271c0.588,4.005 2.009,11.197 5.626,17.183Z" />
    </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M19.917,1.074c-4.758,1.778 -11.167,5.819 -12.473,9.804c-1.307,3.984 -6.952,19.644 -5.599,23.988c1.353,4.344 3.839,11.536 3.332,16.456c-0.506,4.92 -1.416,7.013 -1.759,8.815c-0.342,1.801 1.174,19.446 5.088,25.141c3.915,5.695 16.267,25.027 18.161,29.799c1.893,4.771 2.746,3.196 2.505,6.077c-0.241,2.881 -3.827,37.278 -5.428,43.801c-1.601,6.522 -6.054,13.876 -7.172,18.896c-1.118,5.02 -3.01,26.087 -1.174,28.121c1.835,2.034 5.753,4.764 8.966,5.352c3.213,0.588 11.595,0.146 13.619,-2.335c2.023,-2.481 9.725,-10.313 11.333,-15.494c1.607,-5.18 10.415,-43.054 11.461,-48.13c1.047,-5.077 5.587,-22.889 4.468,-26.819c-1.12,-3.929 -6.348,-8.728 -6.496,-11.821c-0.147,-3.093 2.539,-5.799 2.479,-10.581c-0.06,-4.781 5.108,-41.201 5.108,-41.201l-1.719,-8.921c0,0 -2.289,-0.485 -3.026,-3.084c-0.737,-2.6 -10.446,-29.521 -12.902,-32.19c-2.456,-2.669 -8.828,-10.521 -12.127,-11.566c-3.299,-1.045 -8.164,-4.418 -10.532,-4.701c-2.369,-0.283 -1.355,-1.186 -6.113,0.593Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M74.321,55.502c-1.265,-1.484 -2.511,-2.537 -3.911,-3.198c-1.399,-0.66 -3.591,-3.269 -5.236,-1.184c-1.645,2.085 -1.799,3.62 -1.739,5.52c0.06,1.899 -1.503,2.645 -3.313,4.418c-1.81,1.773 -1.115,1.535 -1.433,3.412c-0.318,1.877 -0.931,4.326 -2.034,6.213c-1.103,1.887 0.615,2.269 -0.561,4.081c-1.177,1.811 -3.687,2.665 -3.356,5.648c0.331,2.984 1.984,4.341 1.834,6.788c-0.15,2.448 -2.005,4.716 -0.676,6.896c1.329,2.179 3.416,6.796 5.462,7.363c2.046,0.567 3.739,1.08 5.303,-0.881c1.564,-1.961 3.362,-4.349 5.334,-5.566c1.971,-1.217 3.201,-3.985 4.23,-5.883c1.029,-1.897 3.433,-4.902 4.321,-7.245c0.887,-2.344 -0.152,-9.632 0.67,-11.74c0.822,-2.108 1.937,-3.194 1.138,-5.078c-0.798,-1.884 -3.288,-2.587 -4.177,-4.213c-0.888,-1.626 -0.591,-3.868 -1.856,-5.351Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M5.737,92.584c3.618,5.986 11.941,16.383 13.311,18.086c1.37,1.702 3.303,5.778 4.954,2.348c1.651,-3.43 4.608,-4.937 3.445,-7.723c-1.163,-2.786 -3.149,-2.375 -3.44,-5.175c-0.29,-2.8 0.794,-5.367 -0.537,-7.822c-1.332,-2.455 -3.33,-3.47 -4.717,-5.657c-1.387,-2.187 -0.033,-3.599 -1.531,-6.282c-1.499,-2.683 -5.918,-6.52 -6.578,-8.472c-0.659,-1.953 0.387,-5.285 -0.836,-6.998c-1.224,-1.712 -5.41,-3.517 -6.916,-1.759c-1.506,1.758 -3.369,8.266 -2.781,12.271c0.588,4.005 2.009,11.197 5.626,17.183Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M33.966,204.617l-0.597,4.566c-0.037,0.286 -0.301,0.488 -0.587,0.451l-10.372,-1.357c-0.286,-0.037 -0.488,-0.3 -0.451,-0.587l0.598,-4.566c0.037,-0.286 0.3,-0.489 0.587,-0.451l10.371,1.357c0.287,0.037 0.489,0.3 0.451,0.587Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M25.966,23.871c0.267,0.846 -0.203,1.75 -1.049,2.017c-0.846,0.266 -1.75,-0.204 -2.017,-1.05c-0.267,-0.846 0.203,-1.75 1.049,-2.017c0.847,-0.267 1.75,0.203 2.017,1.05Z" />
  </g>
</svg>`,
  },
  {
    hole: 8,
    par: 3,
    inches: 300,
    tip: 'The devilish sand trap in on hole 4 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 85 180" version="1.1"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
       <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
      <path class="fill-green-500" d="M59.351,163.502c-1.403,2.015 -6.658,7.589 -9.434,7.079c-2.775,-0.51 -9.8,0.995 -10.419,-2.945c-0.618,-3.94 -2.094,-20.43 -3.019,-24.552c-0.924,-4.122 -1.985,-12.246 -3.467,-14.337c-1.483,-2.091 -7.179,-13.625 -8.45,-16.703c-1.271,-3.079 -4.803,-17.225 -7.025,-20.633c-2.221,-3.408 -5.532,-7.514 -5.532,-7.514l2.92,-56.477c0,-0 -0.172,-0.878 1.634,-3.417c1.806,-2.539 10.778,-12.743 14.571,-15.745c3.794,-3.001 7.325,-4.902 11.248,-6.633c3.924,-1.732 10.38,-2.149 12.887,-0.913c2.506,1.237 11.889,6.085 13.537,9.746c1.648,3.661 0.189,5.068 2.649,7.117c2.461,2.048 3.932,1.397 4.247,4.023c0.314,2.626 -1.393,20.018 -2.889,22.079c-1.497,2.061 -16.835,21.809 -17.622,25.871c-0.787,4.063 -1.984,16.985 -2.024,21.235c-0.04,4.251 1.793,6.697 1.594,11.119c-0.199,4.421 -2.181,10.753 -0.376,16.463c1.806,5.71 6.783,16.847 7.909,21.029c1.126,4.183 2.502,7.901 1.788,11.385c-0.715,3.485 -3.324,10.709 -4.727,12.723Z" />
    <path class="fill-orange-400" d="M0.018,65.14c-0.241,4.545 1.959,11.064 4.098,12.501c2.139,1.436 6.998,5.845 8.134,7.246c1.135,1.402 3.483,-1.881 3.92,-4.758c0.437,-2.877 -0.355,-6.237 -0.423,-8.842c-0.126,-4.793 3.031,-7.104 3.221,-12.528c0.241,-6.91 -2.818,-6.307 -2.051,-13.205c0.697,-6.264 5.44,-13.115 4.334,-16.178c-1.106,-3.062 -3.208,-7.175 -5.589,-5.443c-3.977,2.893 -4.976,9.196 -5.405,13.987c-0.428,4.791 -2.689,11.741 -5.834,15.982c-1.582,2.134 -4.164,6.692 -4.405,11.238Z" />
    </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M59.351,163.502c-1.403,2.015 -6.658,7.589 -9.434,7.079c-2.775,-0.51 -9.8,0.995 -10.419,-2.945c-0.618,-3.94 -2.094,-20.43 -3.019,-24.552c-0.924,-4.122 -1.985,-12.246 -3.467,-14.337c-1.483,-2.091 -7.179,-13.625 -8.45,-16.703c-1.271,-3.079 -4.803,-17.225 -7.025,-20.633c-2.221,-3.408 -5.532,-7.514 -5.532,-7.514l2.92,-56.477c0,-0 -0.172,-0.878 1.634,-3.417c1.806,-2.539 10.778,-12.743 14.571,-15.745c3.794,-3.001 7.325,-4.902 11.248,-6.633c3.924,-1.732 10.38,-2.149 12.887,-0.913c2.506,1.237 11.889,6.085 13.537,9.746c1.648,3.661 0.189,5.068 2.649,7.117c2.461,2.048 3.932,1.397 4.247,4.023c0.314,2.626 -1.393,20.018 -2.889,22.079c-1.497,2.061 -16.835,21.809 -17.622,25.871c-0.787,4.063 -1.984,16.985 -2.024,21.235c-0.04,4.251 1.793,6.697 1.594,11.119c-0.199,4.421 -2.181,10.753 -0.376,16.463c1.806,5.71 6.783,16.847 7.909,21.029c1.126,4.183 2.502,7.901 1.788,11.385c-0.715,3.485 -3.324,10.709 -4.727,12.723Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M0.018,65.14c-0.241,4.545 1.959,11.064 4.098,12.501c2.139,1.436 6.998,5.845 8.134,7.246c1.135,1.402 3.483,-1.881 3.92,-4.758c0.437,-2.877 -0.355,-6.237 -0.423,-8.842c-0.126,-4.793 3.031,-7.104 3.221,-12.528c0.241,-6.91 -2.818,-6.307 -2.051,-13.205c0.697,-6.264 5.44,-13.115 4.334,-16.178c-1.106,-3.062 -3.208,-7.175 -5.589,-5.443c-3.977,2.893 -4.976,9.196 -5.405,13.987c-0.428,4.791 -2.689,11.741 -5.834,15.982c-1.582,2.134 -4.164,6.692 -4.405,11.238Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M55.937,154.724l0.797,4.536c0.05,0.284 -0.14,0.556 -0.425,0.606l-10.302,1.811c-0.285,0.05 -0.556,-0.141 -0.606,-0.425l-0.797,-4.536c-0.051,-0.284 0.14,-0.556 0.425,-0.606l10.301,-1.811c0.285,-0.05 0.557,0.141 0.607,0.425Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M55.617,24.426c0.05,0.886 -0.629,1.646 -1.515,1.695c-0.886,0.05 -1.646,-0.629 -1.695,-1.515c-0.05,-0.886 0.629,-1.646 1.515,-1.695c0.886,-0.05 1.646,0.629 1.695,1.515Z" />
  </g>
</svg>`,
  },
  {
    hole: 9,
    par: 2,
    inches: 385,
    tip: 'The devilish sand trap in on hole 1 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 75 180" version="1.1"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
       <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
      <path class="fill-green-500" d="M46.458,166.952c-2.217,0.844 5.54,-2.264 7.196,-5.293c1.657,-3.03 4.405,-6.085 3.928,-7.98c-0.476,-1.895 -4.316,-6.909 -5.65,-10.196c-1.333,-3.287 -15.803,-42.173 -15.32,-46.548c0.483,-4.375 12.91,-27.427 14.73,-31.743c1.82,-4.315 13.172,-29.136 13.781,-33.998c0.61,-4.862 1.378,-10.201 -1.207,-13.345c-2.585,-3.145 -10.428,-7.357 -13.249,-9.23c-2.822,-1.874 -9.077,-5.142 -13.095,-6.359c-4.019,-1.216 -10.673,-2.048 -13.228,-2.237c-2.554,-0.189 -8.03,0.813 -9.417,1.852c-1.387,1.038 -10.836,9.125 -12.04,12.126c-1.204,3.001 -2.878,18.969 -2.886,22.516c-0.007,3.547 0.004,15.583 0.643,18.443c0.639,2.86 7.765,9.373 8.581,12.61c0.816,3.236 1.692,17.141 1.215,21.294c-0.476,4.152 4.563,18.156 5.927,21.813c1.364,3.658 5.199,15.398 4.007,18.302c-1.192,2.904 -0.138,14.26 2.229,18.949c2.367,4.689 7.448,13.716 10.52,15.886c3.072,2.17 4.136,3.009 6.703,3.358c2.568,0.35 8.848,-1.065 6.632,-0.22Z" />
   </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M46.458,166.952c-2.217,0.844 5.54,-2.264 7.196,-5.293c1.657,-3.03 4.405,-6.085 3.928,-7.98c-0.476,-1.895 -4.316,-6.909 -5.65,-10.196c-1.333,-3.287 -15.803,-42.173 -15.32,-46.548c0.483,-4.375 12.91,-27.427 14.73,-31.743c1.82,-4.315 13.172,-29.136 13.781,-33.998c0.61,-4.862 1.378,-10.201 -1.207,-13.345c-2.585,-3.145 -10.428,-7.357 -13.249,-9.23c-2.822,-1.874 -9.077,-5.142 -13.095,-6.359c-4.019,-1.216 -10.673,-2.048 -13.228,-2.237c-2.554,-0.189 -8.03,0.813 -9.417,1.852c-1.387,1.038 -10.836,9.125 -12.04,12.126c-1.204,3.001 -2.878,18.969 -2.886,22.516c-0.007,3.547 0.004,15.583 0.643,18.443c0.639,2.86 7.765,9.373 8.581,12.61c0.816,3.236 1.692,17.141 1.215,21.294c-0.476,4.152 4.563,18.156 5.927,21.813c1.364,3.658 5.199,15.398 4.007,18.302c-1.192,2.904 -0.138,14.26 2.229,18.949c2.367,4.689 7.448,13.716 10.52,15.886c3.072,2.17 4.136,3.009 6.703,3.358c2.568,0.35 8.848,-1.065 6.632,-0.22Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M43.67,147.354l1.459,4.367c0.091,0.274 -0.057,0.571 -0.331,0.663l-9.921,3.313c-0.275,0.091 -0.571,-0.057 -0.663,-0.331l-1.458,-4.368c-0.092,-0.274 0.056,-0.571 0.331,-0.663l9.921,-3.312c0.274,-0.092 0.571,0.056 0.662,0.331Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M33.338,35.976c0.18,0.869 -0.379,1.72 -1.248,1.9c-0.869,0.18 -1.72,-0.379 -1.9,-1.248c-0.18,-0.869 0.379,-1.721 1.248,-1.901c0.869,-0.179 1.721,0.38 1.9,1.249Z" />
  </g>
</svg>`,
  },
  {
    hole: 10,
    par: 3,
    inches: 375,
    tip: 'The devilish sand trap in on hole 2 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 90 235" version="1.1"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
       <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
      <path class="fill-green-500" d="M55.505,222.2c3.741,-0.037 5.209,-2.109 7.843,-5.625c2.634,-3.516 3.8,-5.155 4.558,-9.536c1.047,-6.049 1.996,-15.655 -1.219,-25.256c-2.102,-6.275 -3.364,-10.91 -3.364,-10.91c0,-0 -5.246,1.055 -5.668,-1.958c-0.422,-3.013 -9.778,-38.475 -8.622,-43.657c1.157,-5.182 3.37,-11.776 3.37,-11.776l8.417,-11.497l5.639,-21.721l3.207,-6.745c-0,-0 -4.425,-8.003 -2.432,-11.816c1.993,-3.812 8.285,-18.54 9.067,-23.594c0.782,-5.054 7.903,-28.189 -18.298,-36.577c-26.202,-8.389 -43.444,20.094 -44.561,26.99c-4.276,26.41 1.714,47.008 0.849,51.107c-0.864,4.098 -3.618,4.932 -3.618,4.932l-5.847,18.152l10.687,23.553c0,0 11.925,11.353 13.913,18.863c1.987,7.51 3.313,21.265 3.261,26.341c-0.052,5.075 4.004,19.11 3.557,24.801c-0.448,5.691 -0.085,8.278 -0.197,11.19c-0.113,2.913 1.89,7.138 5.373,9.418c3.483,2.28 10.344,5.358 14.085,5.321Z" />
    <path class="fill-orange-400" d="M0.002,103.994c0.146,-3.952 0.718,-7.584 2.526,-10.459c1.807,-2.874 1.969,-4.017 3.722,-5.346c1.753,-1.328 3.851,-2.529 3.851,-2.529c-0,0 0.213,6.721 1.137,13.725c1.091,8.264 4.423,11.778 4.751,15.539c0.327,3.761 -0.209,9.93 -0.209,9.93l-8.752,-6.989c0,0 -7.172,-9.919 -7.026,-13.871Z" />
    <path class="fill-orange-400" d="M52.827,95.015c5.941,-5.389 3.308,-9.534 5.479,-13.598c3.118,-5.837 10.364,-7.588 10.364,-7.588c0,0 2.011,6.809 0.284,15.454c-1.727,8.645 -4.541,14.231 -7.865,17.009c-3.324,2.778 -9.011,6.741 -9.011,6.741c0,-0 -8.062,-10.025 0.749,-18.018Z" />
    </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M55.505,222.2c3.741,-0.037 5.209,-2.109 7.843,-5.625c2.634,-3.516 3.8,-5.155 4.558,-9.536c1.047,-6.049 1.996,-15.655 -1.219,-25.256c-2.102,-6.275 -3.364,-10.91 -3.364,-10.91c0,-0 -5.246,1.055 -5.668,-1.958c-0.422,-3.013 -9.778,-38.475 -8.622,-43.657c1.157,-5.182 3.37,-11.776 3.37,-11.776l8.417,-11.497l5.639,-21.721l3.207,-6.745c-0,-0 -4.425,-8.003 -2.432,-11.816c1.993,-3.812 8.285,-18.54 9.067,-23.594c0.782,-5.054 7.903,-28.189 -18.298,-36.577c-26.202,-8.389 -43.444,20.094 -44.561,26.99c-4.276,26.41 1.714,47.008 0.849,51.107c-0.864,4.098 -3.618,4.932 -3.618,4.932l-5.847,18.152l10.687,23.553c0,0 11.925,11.353 13.913,18.863c1.987,7.51 3.313,21.265 3.261,26.341c-0.052,5.075 4.004,19.11 3.557,24.801c-0.448,5.691 -0.085,8.278 -0.197,11.19c-0.113,2.913 1.89,7.138 5.373,9.418c3.483,2.28 10.344,5.358 14.085,5.321Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M0.002,103.994c0.146,-3.952 0.718,-7.584 2.526,-10.459c1.807,-2.874 1.969,-4.017 3.722,-5.346c1.753,-1.328 3.851,-2.529 3.851,-2.529c-0,0 0.213,6.721 1.137,13.725c1.091,8.264 4.423,11.778 4.751,15.539c0.327,3.761 -0.209,9.93 -0.209,9.93l-8.752,-6.989c0,0 -7.172,-9.919 -7.026,-13.871Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M52.827,95.015c5.941,-5.389 3.308,-9.534 5.479,-13.598c3.118,-5.837 10.364,-7.588 10.364,-7.588c0,0 2.011,6.809 0.284,15.454c-1.727,8.645 -4.541,14.231 -7.865,17.009c-3.324,2.778 -9.011,6.741 -9.011,6.741c0,-0 -8.062,-10.025 0.749,-18.018Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M59.187,208.805l0.524,4.575c0.033,0.287 -0.173,0.547 -0.46,0.579l-10.392,1.19c-0.287,0.033 -0.547,-0.173 -0.58,-0.46l-0.524,-4.575c-0.033,-0.287 0.174,-0.547 0.461,-0.58l10.392,-1.19c0.287,-0.033 0.547,0.174 0.579,0.461Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M46.834,32.419c-0.004,0.887 -0.727,1.605 -1.615,1.601c-0.887,-0.004 -1.604,-0.727 -1.601,-1.614c0.004,-0.888 0.727,-1.605 1.615,-1.601c0.887,0.003 1.604,0.727 1.601,1.614Z" />
  </g>
</svg>`,
  },
  {
    hole: 11,
    par: 3,
    inches: 515,
    tip: 'The devilish sand trap in on hole 3 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg class="scale-90" width="100%" height="100%" viewBox="0 0 85 280" version="1.1"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
       <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
      <path class="fill-green-500" d="M23.034,264.521c-3.954,-0.354 -8.586,-5.407 -8.626,-10.312c-0.039,-4.904 5.979,-9.169 4.759,-13.043c-1.22,-3.874 0.143,-14.309 2.3,-20.155c2.157,-5.845 14.759,-43.326 15.695,-49.159c0.935,-5.833 3.803,-29.152 1.564,-34.936c-2.24,-5.783 -3.938,-13.393 -5.14,-18.399c-1.202,-5.006 -8.708,-40.948 -8.708,-40.948c0,0 -7.197,-21.686 -9.608,-25.812c-2.411,-4.127 -8.881,-15.297 -11.448,-19.193c-2.568,-3.897 -4.959,-11.397 -3.24,-16.412c1.718,-5.015 3.604,-12.28 12.716,-15.116c9.111,-2.836 16.16,0.68 23.984,4.188c7.824,3.508 17.59,13.813 20.634,21.41c3.044,7.596 7.692,26.892 8.29,35.725c0.597,8.833 4.71,29.159 5.821,39.754c1.11,10.595 1.03,14.464 -0.873,18.058c-1.903,3.595 -4.581,9.876 -4.505,14.798c0.075,4.921 -1.929,36.321 -3.66,41.686c-1.731,5.365 -9.702,24.428 -11.428,33.139c-1.725,8.712 -3.862,17.593 -7.473,26.039c-3.61,8.447 -3.588,17.61 -9.008,21.43c-5.421,3.82 -8.091,7.612 -12.046,7.258Z" />
    <path class="fill-orange-400" d="M41.666,109.307c-1.355,2.812 -6.065,4.708 -6.065,4.708c-0,0 -9.534,-1.44 -10.138,-6.43c-0.605,-4.991 -2.008,-16.297 -3.865,-21.993c-1.856,-5.697 -4.669,-12.902 -4.871,-16.926c-0.202,-4.024 -4.168,-10.553 -2.717,-12.921c1.451,-2.368 5.447,-6.083 8.292,-3.107c2.844,2.976 4.319,6.365 4.896,10.761c0.578,4.396 4.296,9.383 5.944,15.302c1.648,5.92 2.927,11.141 4.561,15.972c1.633,4.831 4.229,3.89 5.154,8.027c0.926,4.137 0.163,3.794 -1.191,6.607Z" />
    </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M23.034,264.521c-3.954,-0.354 -8.586,-5.407 -8.626,-10.312c-0.039,-4.904 5.979,-9.169 4.759,-13.043c-1.22,-3.874 0.143,-14.309 2.3,-20.155c2.157,-5.845 14.759,-43.326 15.695,-49.159c0.935,-5.833 3.803,-29.152 1.564,-34.936c-2.24,-5.783 -3.938,-13.393 -5.14,-18.399c-1.202,-5.006 -8.708,-40.948 -8.708,-40.948c0,0 -7.197,-21.686 -9.608,-25.812c-2.411,-4.127 -8.881,-15.297 -11.448,-19.193c-2.568,-3.897 -4.959,-11.397 -3.24,-16.412c1.718,-5.015 3.604,-12.28 12.716,-15.116c9.111,-2.836 16.16,0.68 23.984,4.188c7.824,3.508 17.59,13.813 20.634,21.41c3.044,7.596 7.692,26.892 8.29,35.725c0.597,8.833 4.71,29.159 5.821,39.754c1.11,10.595 1.03,14.464 -0.873,18.058c-1.903,3.595 -4.581,9.876 -4.505,14.798c0.075,4.921 -1.929,36.321 -3.66,41.686c-1.731,5.365 -9.702,24.428 -11.428,33.139c-1.725,8.712 -3.862,17.593 -7.473,26.039c-3.61,8.447 -3.588,17.61 -9.008,21.43c-5.421,3.82 -8.091,7.612 -12.046,7.258Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M41.666,109.307c-1.355,2.812 -6.065,4.708 -6.065,4.708c-0,0 -9.534,-1.44 -10.138,-6.43c-0.605,-4.991 -2.008,-16.297 -3.865,-21.993c-1.856,-5.697 -4.669,-12.902 -4.871,-16.926c-0.202,-4.024 -4.168,-10.553 -2.717,-12.921c1.451,-2.368 5.447,-6.083 8.292,-3.107c2.844,2.976 4.319,6.365 4.896,10.761c0.578,4.396 4.296,9.383 5.944,15.302c1.648,5.92 2.927,11.141 4.561,15.972c1.633,4.831 4.229,3.89 5.154,8.027c0.926,4.137 0.163,3.794 -1.191,6.607Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M32.117,253.637l-1.312,4.414c-0.082,0.277 -0.374,0.435 -0.651,0.352l-10.027,-2.978c-0.277,-0.083 -0.435,-0.374 -0.352,-0.652l1.311,-4.414c0.082,-0.277 0.374,-0.435 0.651,-0.352l10.027,2.978c0.277,0.083 0.435,0.374 0.353,0.652Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M28.309,26.117c0.826,0.324 1.233,1.258 0.909,2.084c-0.324,0.826 -1.258,1.233 -2.084,0.909c-0.826,-0.324 -1.233,-1.258 -0.909,-2.084c0.324,-0.826 1.258,-1.233 2.084,-0.909Z" />
  </g>
</svg>`,
  },
  {
    hole: 12,
    par: 3,
    inches: 385,
    tip: 'The devilish sand trap in on hole 4 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 100 270" version="1.1"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
       <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
      <path class="fill-green-500" d="M55.117,253.234c3.045,-1.22 8.262,-7.164 7.323,-15.84c-0.939,-8.675 -6.647,-30.558 -6.074,-38.184c0.573,-7.627 2.735,-11.073 1.391,-14.262c-1.344,-3.188 -2.981,-2.57 0.007,-8.735c2.988,-6.164 10.939,-17.403 13.107,-29.172c2.167,-11.77 4.401,-10.073 5.28,-19.969c0.878,-9.896 2.733,-26.466 4.18,-32.393c1.447,-5.928 6.21,-22.537 5.454,-27.321c-0.756,-4.784 -0.229,-9.13 -6.112,-12.453c-5.882,-3.324 -9.31,-3.518 -11.408,-7.086c-2.099,-3.568 0.781,-8.831 -2.474,-12.065c-3.254,-3.233 -22.964,-9.259 -22.964,-9.259l-10.229,3.742c0,0 -2.599,-1.427 -7.013,3.264c-4.413,4.692 -3.765,5.852 -7.405,8.713c-3.639,2.862 -13.056,7.864 -15.761,12.119c-2.705,4.254 -4.004,13.387 0.8,18.544c4.804,5.157 23.233,15.958 27.353,19.213c4.12,3.256 13.179,8.905 15.257,14.533c2.077,5.629 2.391,25.284 0.551,29.734c-1.841,4.45 -13.452,15.595 -16.341,21.375c-2.89,5.78 -4.21,17.585 -4.308,21.433c-0.098,3.849 0.905,22.851 3.297,29.044c2.392,6.194 7.23,20.507 8.974,25.63c1.743,5.122 4.18,12.786 7.84,15.262c3.66,2.476 6.231,5.352 9.275,4.133Z" />
    <path class="fill-orange-400" d="M26.273,31.396c3.87,-4.117 10.979,-11.306 11.866,-16.115c0.887,-4.809 0.876,-15.281 0.876,-15.281c0,-0 9.275,6.264 12.194,7.966c2.919,1.701 7.511,2.666 9.319,5.593c1.808,2.927 3.263,10.239 5.491,14.11c2.227,3.872 1.644,11.965 1.644,11.965c0,0 1.044,4.198 -3.912,2.264c-4.957,-1.934 -8.434,-5.392 -12.248,-5.675c-3.814,-0.283 -9.984,1.775 -14.112,-0.212c-4.129,-1.988 -14.988,-0.498 -11.118,-4.615Z" />
    </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M55.117,253.234c3.045,-1.22 8.262,-7.164 7.323,-15.84c-0.939,-8.675 -6.647,-30.558 -6.074,-38.184c0.573,-7.627 2.735,-11.073 1.391,-14.262c-1.344,-3.188 -2.981,-2.57 0.007,-8.735c2.988,-6.164 10.939,-17.403 13.107,-29.172c2.167,-11.77 4.401,-10.073 5.28,-19.969c0.878,-9.896 2.733,-26.466 4.18,-32.393c1.447,-5.928 6.21,-22.537 5.454,-27.321c-0.756,-4.784 -0.229,-9.13 -6.112,-12.453c-5.882,-3.324 -9.31,-3.518 -11.408,-7.086c-2.099,-3.568 0.781,-8.831 -2.474,-12.065c-3.254,-3.233 -22.964,-9.259 -22.964,-9.259l-10.229,3.742c0,0 -2.599,-1.427 -7.013,3.264c-4.413,4.692 -3.765,5.852 -7.405,8.713c-3.639,2.862 -13.056,7.864 -15.761,12.119c-2.705,4.254 -4.004,13.387 0.8,18.544c4.804,5.157 23.233,15.958 27.353,19.213c4.12,3.256 13.179,8.905 15.257,14.533c2.077,5.629 2.391,25.284 0.551,29.734c-1.841,4.45 -13.452,15.595 -16.341,21.375c-2.89,5.78 -4.21,17.585 -4.308,21.433c-0.098,3.849 0.905,22.851 3.297,29.044c2.392,6.194 7.23,20.507 8.974,25.63c1.743,5.122 4.18,12.786 7.84,15.262c3.66,2.476 6.231,5.352 9.275,4.133Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M26.273,31.396c3.87,-4.117 10.979,-11.306 11.866,-16.115c0.887,-4.809 0.876,-15.281 0.876,-15.281c0,-0 9.275,6.264 12.194,7.966c2.919,1.701 7.511,2.666 9.319,5.593c1.808,2.927 3.263,10.239 5.491,14.11c2.227,3.872 1.644,11.965 1.644,11.965c0,0 1.044,4.198 -3.912,2.264c-4.957,-1.934 -8.434,-5.392 -12.248,-5.675c-3.814,-0.283 -9.984,1.775 -14.112,-0.212c-4.129,-1.988 -14.988,-0.498 -11.118,-4.615Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M55.636,235.093l1.577,4.327c0.099,0.271 -0.041,0.572 -0.313,0.671l-9.827,3.583c-0.271,0.099 -0.572,-0.041 -0.671,-0.313l-1.577,-4.326c-0.099,-0.272 0.041,-0.572 0.312,-0.671l9.827,-3.583c0.272,-0.099 0.573,0.041 0.672,0.312Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M43.738,63.592c-0.7,-0.545 -0.826,-1.556 -0.281,-2.257c0.545,-0.7 1.556,-0.826 2.256,-0.281c0.7,0.545 0.826,1.556 0.281,2.256c-0.544,0.7 -1.555,0.827 -2.256,0.282Z" />
  </g>
</svg>`,
  },
  {
    hole: 13,
    par: 4,
    inches: 455,
    tip: 'The devilish sand trap in on hole 4 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 95 255" version="1.1"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
       <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
       <path class="fill-green-500" d="M74.03,238.654c-3.627,1.176 -10.95,5.263 -14.959,0.685c-4.009,-4.578 -9.721,-13.542 -15.273,-24.286c-5.551,-10.743 -14.008,-18.418 -17.523,-25.574c-3.514,-7.157 -3.895,-8.959 -9.115,-15.206c-5.219,-6.246 -6.804,-13.148 -7.381,-21.01c-0.576,-7.863 3.411,-15.736 1.117,-24.634c-2.294,-8.898 -2.687,-20.353 0.055,-33.456c2.743,-13.102 5.188,-13.655 4.361,-24.335c-0.828,-10.68 -10.399,-25.22 -4.128,-37.363c6.272,-12.144 10.969,-12.426 16.985,-18.614c6.017,-6.188 10.09,-17.994 19.975,-14.084c9.885,3.909 20.589,17.083 21.208,23.432c0.619,6.349 -3.245,28.764 -6.775,37.589c-3.53,8.826 -17.75,12.83 -19.959,21.968c-2.208,9.139 -8.637,38.8 -7.968,46.796c0.668,7.995 9.703,40.196 15.092,49.721c5.389,9.526 23.617,27.322 26.606,33.28c2.988,5.958 8.205,15.072 6.082,18.042c-2.122,2.97 -4.773,5.873 -8.4,7.049Z" />
    <path class="fill-orange-400" d="M8.571,135.401c2.605,2.356 2.808,6.606 5.066,8.544c2.258,1.938 3.026,1.671 3.146,4.455c0.119,2.784 -1.261,7.693 -0.238,10.447c1.023,2.753 2.224,6.711 0.448,9.816c-1.775,3.104 -2.792,3.562 -6.143,0.723c-3.352,-2.839 -7.028,0.186 -9.473,-6.523c-2.446,-6.709 -1.29,-15.295 0.8,-19.12c2.09,-3.824 3.79,-10.698 6.394,-8.342Z" />
    </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M74.03,238.654c-3.627,1.176 -10.95,5.263 -14.959,0.685c-4.009,-4.578 -9.721,-13.542 -15.273,-24.286c-5.551,-10.743 -14.008,-18.418 -17.523,-25.574c-3.514,-7.157 -3.895,-8.959 -9.115,-15.206c-5.219,-6.246 -6.804,-13.148 -7.381,-21.01c-0.576,-7.863 3.411,-15.736 1.117,-24.634c-2.294,-8.898 -2.687,-20.353 0.055,-33.456c2.743,-13.102 5.188,-13.655 4.361,-24.335c-0.828,-10.68 -10.399,-25.22 -4.128,-37.363c6.272,-12.144 10.969,-12.426 16.985,-18.614c6.017,-6.188 10.09,-17.994 19.975,-14.084c9.885,3.909 20.589,17.083 21.208,23.432c0.619,6.349 -3.245,28.764 -6.775,37.589c-3.53,8.826 -17.75,12.83 -19.959,21.968c-2.208,9.139 -8.637,38.8 -7.968,46.796c0.668,7.995 9.703,40.196 15.092,49.721c5.389,9.526 23.617,27.322 26.606,33.28c2.988,5.958 8.205,15.072 6.082,18.042c-2.122,2.97 -4.773,5.873 -8.4,7.049Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M8.571,135.401c2.605,2.356 2.808,6.606 5.066,8.544c2.258,1.938 3.026,1.671 3.146,4.455c0.119,2.784 -1.261,7.693 -0.238,10.447c1.023,2.753 2.224,6.711 0.448,9.816c-1.775,3.104 -2.792,3.562 -6.143,0.723c-3.352,-2.839 -7.028,0.186 -9.473,-6.523c-2.446,-6.709 -1.29,-15.295 0.8,-19.12c2.09,-3.824 3.79,-10.698 6.394,-8.342Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M72.524,225.007l2.567,3.823c0.161,0.24 0.097,0.566 -0.143,0.727l-8.684,5.83c-0.24,0.161 -0.566,0.097 -0.727,-0.143l-2.567,-3.823c-0.161,-0.24 -0.097,-0.566 0.143,-0.727l8.685,-5.83c0.24,-0.161 0.565,-0.097 0.726,0.143Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M40.904,33.358c-0.43,-0.777 -0.148,-1.756 0.628,-2.185c0.777,-0.43 1.756,-0.148 2.185,0.628c0.43,0.776 0.148,1.756 -0.628,2.185c-0.777,0.43 -1.756,0.148 -2.185,-0.628Z" />
  </g>
</svg>`,
  },
  {
    hole: 14,
    par: 3,
    inches: 390,
    tip: 'The devilish sand trap in on hole 4 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 80 225" version="1.1"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
       <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
      <path class="fill-green-500" d="M13.884,212.452c-3.416,-1.285 -10.874,-9.086 -12.026,-12.736c-1.152,-3.65 -3.129,-6.543 -0.709,-13.676c2.419,-7.132 8.438,-24.049 10.615,-31.267c2.177,-7.219 8.346,-23.794 8.187,-32.717c-0.159,-8.924 3.311,-35.271 2.512,-40.102c-0.799,-4.832 1.365,-14.096 0.188,-18.141c-1.176,-4.045 -11.679,-20.02 -10.585,-26.672c1.094,-6.653 2.11,-5.678 0.529,-9.851c-1.581,-4.173 0.762,-13.275 3.636,-17.439c2.873,-4.164 16.855,-11.248 21.432,-9.61c4.578,1.639 20.375,18.412 23.301,23.803c2.925,5.391 7.358,11.643 5.642,16.908c-1.716,5.264 -10.493,14.437 -12.396,20.154c-1.902,5.717 -4.269,11.917 -3.205,18.397c1.064,6.481 -1.786,8.051 0.495,11.979c2.281,3.927 6.543,4.189 6.689,10.496c0.147,6.307 -2.151,14.728 -0.458,22.211c1.692,7.483 2.3,19.376 2.3,19.376l-6.856,11.811c-0,-0 -4.874,-0.4 -7.863,1.754c-2.989,2.154 -6.545,8.588 -6.3,14.149c0.246,5.562 -4.514,23.191 -7.354,29.645c-2.839,6.454 -5.833,9.73 -10.121,10.795c-4.288,1.065 -4.236,2.019 -7.653,0.733Z" />
    <path class="fill-orange-400" d="M52.71,140.411c0.81,-3.58 -1.319,-9.465 1.271,-12.202c2.59,-2.738 3.65,-3.975 3.65,-3.975c-0,0 -0.41,3.965 4.118,8.57c4.529,4.605 8.215,4.335 6.463,7.55c-1.751,3.214 -0.555,8.355 -5.613,11.277c-5.057,2.922 -4.949,1.621 -8.225,3.696c-3.277,2.076 -6.751,2.939 -6.751,2.939c-0,0 -0.984,-5.748 1.008,-9.855c1.991,-4.107 3.269,-4.42 4.079,-8Z" />
    </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M13.884,212.452c-3.416,-1.285 -10.874,-9.086 -12.026,-12.736c-1.152,-3.65 -3.129,-6.543 -0.709,-13.676c2.419,-7.132 8.438,-24.049 10.615,-31.267c2.177,-7.219 8.346,-23.794 8.187,-32.717c-0.159,-8.924 3.311,-35.271 2.512,-40.102c-0.799,-4.832 1.365,-14.096 0.188,-18.141c-1.176,-4.045 -11.679,-20.02 -10.585,-26.672c1.094,-6.653 2.11,-5.678 0.529,-9.851c-1.581,-4.173 0.762,-13.275 3.636,-17.439c2.873,-4.164 16.855,-11.248 21.432,-9.61c4.578,1.639 20.375,18.412 23.301,23.803c2.925,5.391 7.358,11.643 5.642,16.908c-1.716,5.264 -10.493,14.437 -12.396,20.154c-1.902,5.717 -4.269,11.917 -3.205,18.397c1.064,6.481 -1.786,8.051 0.495,11.979c2.281,3.927 6.543,4.189 6.689,10.496c0.147,6.307 -2.151,14.728 -0.458,22.211c1.692,7.483 2.3,19.376 2.3,19.376l-6.856,11.811c-0,-0 -4.874,-0.4 -7.863,1.754c-2.989,2.154 -6.545,8.588 -6.3,14.149c0.246,5.562 -4.514,23.191 -7.354,29.645c-2.839,6.454 -5.833,9.73 -10.121,10.795c-4.288,1.065 -4.236,2.019 -7.653,0.733Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M52.71,140.411c0.81,-3.58 -1.319,-9.465 1.271,-12.202c2.59,-2.738 3.65,-3.975 3.65,-3.975c-0,0 -0.41,3.965 4.118,8.57c4.529,4.605 8.215,4.335 6.463,7.55c-1.751,3.214 -0.555,8.355 -5.613,11.277c-5.057,2.922 -4.949,1.621 -8.225,3.696c-3.277,2.076 -6.751,2.939 -6.751,2.939c-0,0 -0.984,-5.748 1.008,-9.855c1.991,-4.107 3.269,-4.42 4.079,-8Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M22.44,200.711l-1.696,4.281c-0.107,0.268 -0.411,0.4 -0.68,0.294l-9.725,-3.852c-0.268,-0.106 -0.4,-0.411 -0.294,-0.679l1.696,-4.282c0.106,-0.268 0.411,-0.4 0.68,-0.294l9.724,3.852c0.269,0.106 0.401,0.411 0.295,0.68Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M33.489,27.602c0.858,-0.226 1.738,0.288 1.964,1.146c0.226,0.858 -0.287,1.738 -1.145,1.964c-0.858,0.226 -1.738,-0.288 -1.964,-1.146c-0.226,-0.858 0.287,-1.738 1.145,-1.964Z" />
  </g>
</svg>`,
  },
  {
    hole: 15,
    par: 2,
    inches: 420,
    tip: 'The devilish sand trap in on hole 4 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg width="100%" height="100%" viewBox="0 0 85 245" version="1.1"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
       <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
     <path class="fill-green-500" d="M27.131,231.98c-2.788,-1.157 -9.926,-7.69 -9.259,-14.435c0.667,-6.746 1.969,-12.517 3.683,-19.443c1.715,-6.925 10.442,-13.295 8.894,-19.357c-1.548,-6.062 2.212,-17.261 1.097,-31.041c-1.116,-13.779 -4.006,-38.861 -6.974,-42.441c-2.967,-3.581 -16.996,-10.029 -17,-15.992c-0.004,-5.964 5.904,-15.006 4.722,-21.778c-1.182,-6.772 -2.025,-18.74 -5.651,-22.269c-3.626,-3.528 -8.511,-15.828 -5.911,-20.758c2.6,-4.93 12.712,-18.474 22.865,-20.649c10.153,-2.174 24.541,-6.061 27.951,-2.133c3.409,3.927 9.024,8.079 8.461,13.669c-0.564,5.59 -1.63,6.717 -0.02,10.769c1.61,4.052 4.079,15.628 0.123,21.283c-3.956,5.654 -4.073,9.856 -0.344,11.852c3.73,1.997 5.499,14.159 5.499,14.159l1.07,19.561c-0,-0 -8.961,11.641 -11.434,15.893c-2.474,4.251 -5.742,19.491 -3.359,26.316c2.382,6.824 3.189,16.329 2.166,25.222c-1.023,8.893 1.468,17.84 -1.173,24.615c-2.641,6.775 -3.105,13.101 -4.455,20.699c-1.35,7.598 -3.151,18.829 -8.06,21.425c-4.91,2.597 -10.103,5.989 -12.891,4.833Z" />
    <path class="fill-orange-400" d="M71.615,97.909c-0,0 2.048,-8.486 -0.309,-17.438c-2.358,-8.953 -0.338,-17.197 -4.541,-19.013c-4.203,-1.815 -7.467,-2.596 -7.467,-2.596l-2.89,-1.128c0,0 -0.566,7.22 -0.041,11.65c0.525,4.43 2.203,6.939 1.919,12.341c-0.284,5.403 -1.987,8.026 -1.413,12.535c0.574,4.51 0.075,12.794 4.823,12.954c4.747,0.16 9.919,-9.305 9.919,-9.305Z" />
    </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M27.131,231.98c-2.788,-1.157 -9.926,-7.69 -9.259,-14.435c0.667,-6.746 1.969,-12.517 3.683,-19.443c1.715,-6.925 10.442,-13.295 8.894,-19.357c-1.548,-6.062 2.212,-17.261 1.097,-31.041c-1.116,-13.779 -4.006,-38.861 -6.974,-42.441c-2.967,-3.581 -16.996,-10.029 -17,-15.992c-0.004,-5.964 5.904,-15.006 4.722,-21.778c-1.182,-6.772 -2.025,-18.74 -5.651,-22.269c-3.626,-3.528 -8.511,-15.828 -5.911,-20.758c2.6,-4.93 12.712,-18.474 22.865,-20.649c10.153,-2.174 24.541,-6.061 27.951,-2.133c3.409,3.927 9.024,8.079 8.461,13.669c-0.564,5.59 -1.63,6.717 -0.02,10.769c1.61,4.052 4.079,15.628 0.123,21.283c-3.956,5.654 -4.073,9.856 -0.344,11.852c3.73,1.997 5.499,14.159 5.499,14.159l1.07,19.561c-0,-0 -8.961,11.641 -11.434,15.893c-2.474,4.251 -5.742,19.491 -3.359,26.316c2.382,6.824 3.189,16.329 2.166,25.222c-1.023,8.893 1.468,17.84 -1.173,24.615c-2.641,6.775 -3.105,13.101 -4.455,20.699c-1.35,7.598 -3.151,18.829 -8.06,21.425c-4.91,2.597 -10.103,5.989 -12.891,4.833Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M71.615,97.909c-0,0 2.048,-8.486 -0.309,-17.438c-2.358,-8.953 -0.338,-17.197 -4.541,-19.013c-4.203,-1.815 -7.467,-2.596 -7.467,-2.596l-2.89,-1.128c0,0 -0.566,7.22 -0.041,11.65c0.525,4.43 2.203,6.939 1.919,12.341c-0.284,5.403 -1.987,8.026 -1.413,12.535c0.574,4.51 0.075,12.794 4.823,12.954c4.747,0.16 9.919,-9.305 9.919,-9.305Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M37.009,220.287l-1.34,4.406c-0.085,0.276 -0.377,0.432 -0.654,0.348l-10.007,-3.044c-0.276,-0.085 -0.432,-0.377 -0.348,-0.654l1.34,-4.405c0.084,-0.277 0.377,-0.433 0.654,-0.349l10.006,3.045c0.277,0.084 0.433,0.377 0.349,0.653Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M20.032,27.977c0.217,0.86 -0.305,1.735 -1.165,1.953c-0.86,0.217 -1.735,-0.304 -1.953,-1.165c-0.217,-0.86 0.305,-1.735 1.165,-1.952c0.86,-0.218 1.735,0.304 1.953,1.164Z" />
  </g>
</svg>`,
  },
  {
    hole: 16,
    par: 3,
    inches: 505,
    tip: 'The devilish sand trap in on hole 4 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg class="scale-75 -translate-y-12" width="100%" height="100%" viewBox="0 0 80 275" version="1.1"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
       <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
     <path class="fill-green-500" d="M25.179,265.694c4.657,-1.571 10.154,-3.921 12.999,-8.889c2.845,-4.967 5.776,-7.844 8.549,-15.402c2.773,-7.557 3.511,-9.928 2.89,-17.697c-0.62,-7.769 -2.572,-12.148 -1.851,-16.842c0.721,-4.693 -0.245,-10.478 3.193,-14.645c3.439,-4.166 8.57,-8.86 10.774,-13.848c2.205,-4.988 0.758,-25.901 0.231,-32.3c-0.527,-6.398 -6.073,-8.467 -8.071,-13.999c-1.999,-5.532 -2.895,-16.893 -4.786,-24.5c-1.891,-7.607 -5.399,-23.145 -6.834,-28.472c-1.434,-5.328 -0.542,-11.757 2.538,-17.272c3.08,-5.514 6.197,-8.388 10.776,-14.954c4.579,-6.567 6.51,-17.951 4.45,-26.196c-2.061,-8.245 -11.117,-18.455 -17.366,-19.847c-6.248,-1.393 -14.563,-0.909 -18.94,0.52c-4.377,1.429 -9.489,5.933 -12.007,9.387c-2.518,3.455 -6.477,1.735 -8.051,4.887c-1.574,3.152 -3.891,8.261 -3.656,16.555c0.234,8.294 1.614,17.48 3.824,22.777c2.21,5.297 13.921,17.843 14.141,23.824c0.22,5.981 3.112,24.6 2.453,30.987c-0.659,6.388 -1.669,18.466 -0.779,25.016c0.89,6.549 4.655,17.606 4.519,27.235c-0.136,9.628 -2.818,24.152 -4.979,35.251c-2.161,11.098 -1.625,26.813 -2.476,34.68c-0.851,7.867 -3.022,15.741 -1.8,20.155c1.222,4.413 5.602,15.161 10.259,13.589Z" />
    <path class="fill-orange-400" d="M61.193,138.76c1.924,2.419 2.921,5.463 5.752,6.728c2.83,1.264 6.069,14.077 4.362,22.622c-1.707,8.544 -2.571,13.37 -5.124,15.019c-2.553,1.65 -10.765,5.926 -10.765,5.926c0,0 0.923,-6.592 1.014,-11.017c0.091,-4.425 -2.139,-11.937 -0.549,-18.002c1.59,-6.066 5.143,-8.005 5.137,-12.159c-0.007,-4.153 -1.751,-11.535 0.173,-9.117Z" />
    </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M25.179,265.694c4.657,-1.571 10.154,-3.921 12.999,-8.889c2.845,-4.967 5.776,-7.844 8.549,-15.402c2.773,-7.557 3.511,-9.928 2.89,-17.697c-0.62,-7.769 -2.572,-12.148 -1.851,-16.842c0.721,-4.693 -0.245,-10.478 3.193,-14.645c3.439,-4.166 8.57,-8.86 10.774,-13.848c2.205,-4.988 0.758,-25.901 0.231,-32.3c-0.527,-6.398 -6.073,-8.467 -8.071,-13.999c-1.999,-5.532 -2.895,-16.893 -4.786,-24.5c-1.891,-7.607 -5.399,-23.145 -6.834,-28.472c-1.434,-5.328 -0.542,-11.757 2.538,-17.272c3.08,-5.514 6.197,-8.388 10.776,-14.954c4.579,-6.567 6.51,-17.951 4.45,-26.196c-2.061,-8.245 -11.117,-18.455 -17.366,-19.847c-6.248,-1.393 -14.563,-0.909 -18.94,0.52c-4.377,1.429 -9.489,5.933 -12.007,9.387c-2.518,3.455 -6.477,1.735 -8.051,4.887c-1.574,3.152 -3.891,8.261 -3.656,16.555c0.234,8.294 1.614,17.48 3.824,22.777c2.21,5.297 13.921,17.843 14.141,23.824c0.22,5.981 3.112,24.6 2.453,30.987c-0.659,6.388 -1.669,18.466 -0.779,25.016c0.89,6.549 4.655,17.606 4.519,27.235c-0.136,9.628 -2.818,24.152 -4.979,35.251c-2.161,11.098 -1.625,26.813 -2.476,34.68c-0.851,7.867 -3.022,15.741 -1.8,20.155c1.222,4.413 5.602,15.161 10.259,13.589Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M61.193,138.76c1.924,2.419 2.921,5.463 5.752,6.728c2.83,1.264 6.069,14.077 4.362,22.622c-1.707,8.544 -2.571,13.37 -5.124,15.019c-2.553,1.65 -10.765,5.926 -10.765,5.926c0,0 0.923,-6.592 1.014,-11.017c0.091,-4.425 -2.139,-11.937 -0.549,-18.002c1.59,-6.066 5.143,-8.005 5.137,-12.159c-0.007,-4.153 -1.751,-11.535 0.173,-9.117Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M32.714,252.202l-0.554,4.571c-0.035,0.287 -0.296,0.491 -0.583,0.457l-10.384,-1.259c-0.287,-0.035 -0.492,-0.296 -0.457,-0.583l0.554,-4.572c0.035,-0.286 0.296,-0.491 0.583,-0.456l10.384,1.259c0.287,0.034 0.492,0.296 0.457,0.583Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M34.794,24.861c-0.035,-0.887 0.656,-1.636 1.543,-1.671c0.886,-0.035 1.635,0.656 1.67,1.543c0.035,0.886 -0.656,1.635 -1.543,1.67c-0.886,0.035 -1.635,-0.656 -1.67,-1.542Z" />
  </g>
</svg>`,
  },
  {
    hole: 17,
    par: 3,
    inches: 550,
    tip: 'The devilish sand trap in on hole 4 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg class="scale-90 translate-y-6" width="100%" height="100%" viewBox="0 0 100 290" version="1.1"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
       <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
     <path class="fill-green-500" d="M28.225,275.404c0,0 -7.49,-13.026 -5.355,-18.785c2.136,-5.759 6.857,-16.487 8.469,-20.41c1.611,-3.923 10.339,-22.136 10.691,-26.223c0.352,-4.086 8.305,-12.914 6.624,-20.021c-1.682,-7.107 -8.189,-9.866 -9.293,-14.433c-1.103,-4.567 -14.402,-100.43 -14.402,-100.43c0,0 0.235,-8.678 -3.108,-12.161c-3.343,-3.483 -16.981,-12.482 -18.62,-16.115c-8.396,-18.617 1.722,-30.203 6.085,-34.095c4.364,-3.892 13.072,-13.328 19.493,-12.695c6.421,0.634 7.95,-1.52 13.291,2.381c5.34,3.9 11.981,7.093 13.183,13.701c1.202,6.608 3.969,27.313 5.376,32.187c1.407,4.874 7.804,0.387 10.659,7.323c2.855,6.936 13.619,38.557 11.17,46.72c-2.449,8.162 1.456,28.727 5.713,39.556c4.256,10.828 -4.963,43.957 -8.508,50.502c-3.546,6.546 -15.765,29.474 -17.488,38.851c-1.724,9.376 -7.896,20.838 -11.364,27.327c-3.468,6.49 -3.125,12.639 -9.315,14.347c-6.19,1.708 -3.942,3.22 -6.971,3.274c-3.028,0.054 -6.33,-0.801 -6.33,-0.801Z" />
    <path class="fill-orange-400" d="M88.108,70.964c-2.8,-5.579 0.675,14.782 -0.213,19.243c-0.888,4.46 0.414,5.388 0.414,5.388c-0,-0 -0.834,1.221 -3.752,4.642c-2.919,3.421 -6.776,-2.205 -7.638,-7.322c-0.861,-5.118 0.256,-7.016 -1.44,-11.602c-1.696,-4.587 -9.122,-10.636 -9.286,-15.691c-0.164,-5.055 -4.136,-12.248 -0.846,-15.262c3.289,-3.014 5.218,-6.787 9.65,-2.602c4.431,4.186 15.912,28.786 13.111,23.206Z" />
    <path class="fill-orange-400" d="M55.636,154.717c0.071,-5.036 -2.48,-14.961 -1.434,-22.744c1.045,-7.784 -1.622,-17.736 -4.701,-23.388c-3.08,-5.653 -12.636,-17.256 -13.883,-21.748c-1.247,-4.493 -7.215,-13.133 -10.092,-12.403c-2.877,0.731 -5.493,5.055 -2.355,12.98c3.137,7.926 2.761,15.261 3.578,25.072c0.818,9.811 4.905,12.479 5.173,19.73c0.268,7.25 -2.207,11.884 -0.756,17.873c1.451,5.989 3.027,17.296 5.554,21.077c2.528,3.781 3.439,4.933 3.439,4.933c0,0 6.991,-4.795 10.027,-9.816c3.037,-5.021 5.378,-6.531 5.45,-11.566Z" />
    </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M28.225,275.404c0,0 -7.49,-13.026 -5.355,-18.785c2.136,-5.759 6.857,-16.487 8.469,-20.41c1.611,-3.923 10.339,-22.136 10.691,-26.223c0.352,-4.086 8.305,-12.914 6.624,-20.021c-1.682,-7.107 -8.189,-9.866 -9.293,-14.433c-1.103,-4.567 -14.402,-100.43 -14.402,-100.43c0,0 0.235,-8.678 -3.108,-12.161c-3.343,-3.483 -16.981,-12.482 -18.62,-16.115c-8.396,-18.617 1.722,-30.203 6.085,-34.095c4.364,-3.892 13.072,-13.328 19.493,-12.695c6.421,0.634 7.95,-1.52 13.291,2.381c5.34,3.9 11.981,7.093 13.183,13.701c1.202,6.608 3.969,27.313 5.376,32.187c1.407,4.874 7.804,0.387 10.659,7.323c2.855,6.936 13.619,38.557 11.17,46.72c-2.449,8.162 1.456,28.727 5.713,39.556c4.256,10.828 -4.963,43.957 -8.508,50.502c-3.546,6.546 -15.765,29.474 -17.488,38.851c-1.724,9.376 -7.896,20.838 -11.364,27.327c-3.468,6.49 -3.125,12.639 -9.315,14.347c-6.19,1.708 -3.942,3.22 -6.971,3.274c-3.028,0.054 -6.33,-0.801 -6.33,-0.801Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M88.108,70.964c-2.8,-5.579 0.675,14.782 -0.213,19.243c-0.888,4.46 0.414,5.388 0.414,5.388c-0,-0 -0.834,1.221 -3.752,4.642c-2.919,3.421 -6.776,-2.205 -7.638,-7.322c-0.861,-5.118 0.256,-7.016 -1.44,-11.602c-1.696,-4.587 -9.122,-10.636 -9.286,-15.691c-0.164,-5.055 -4.136,-12.248 -0.846,-15.262c3.289,-3.014 5.218,-6.787 9.65,-2.602c4.431,4.186 15.912,28.786 13.111,23.206Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M55.636,154.717c0.071,-5.036 -2.48,-14.961 -1.434,-22.744c1.045,-7.784 -1.622,-17.736 -4.701,-23.388c-3.08,-5.653 -12.636,-17.256 -13.883,-21.748c-1.247,-4.493 -7.215,-13.133 -10.092,-12.403c-2.877,0.731 -5.493,5.055 -2.355,12.98c3.137,7.926 2.761,15.261 3.578,25.072c0.818,9.811 4.905,12.479 5.173,19.73c0.268,7.25 -2.207,11.884 -0.756,17.873c1.451,5.989 3.027,17.296 5.554,21.077c2.528,3.781 3.439,4.933 3.439,4.933c0,0 6.991,-4.795 10.027,-9.816c3.037,-5.021 5.378,-6.531 5.45,-11.566Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M38.842,268.421l-1.936,4.178c-0.121,0.263 -0.433,0.377 -0.695,0.255l-9.491,-4.397c-0.262,-0.122 -0.376,-0.433 -0.255,-0.696l1.936,-4.178c0.122,-0.262 0.433,-0.376 0.696,-0.255l9.49,4.398c0.262,0.121 0.377,0.433 0.255,0.695Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M27.097,23.925c-0.02,0.887 -0.756,1.592 -1.643,1.572c-0.887,-0.02 -1.592,-0.756 -1.572,-1.643c0.02,-0.887 0.756,-1.592 1.643,-1.572c0.887,0.02 1.591,0.756 1.572,1.643Z" />
  </g>
</svg>`,
  },
  {
    hole: 18,
    par: 3,
    inches: 365,
    tip: 'The devilish sand trap in on hole 4 can be bypassed with a skillful bankshot to the right or in the back left.',
    map: `<svg class="scale-110 translate-y-4" width="100%" height="100%" viewBox="0 0 90 215" version="1.1"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
       <g class="putt-putt-shadows" filter="url(#putt-putt-filter-one)">
     <path class="fill-green-500" d="M36.359,203.212c6.982,-0.117 10.667,2.316 14.18,-4.341c3.514,-6.658 2.333,-27.414 1.097,-36.146c-1.237,-8.731 0.609,-16.327 -0.819,-25.389c-1.429,-9.062 -2.023,-18.67 -2.081,-26.755c-0.057,-8.085 -2.764,-13.739 1.12,-19.042c3.883,-5.303 9.415,-11.51 10.058,-16.689c0.643,-5.18 8.267,-37.123 8.267,-37.123c0,-0 0.984,-7.633 -4.672,-16.557c-5.656,-8.924 -15.814,-19.474 -22.219,-20.735c-6.406,-1.26 -24.501,0.119 -30.215,5.435c-5.715,5.316 -11.257,13.73 -11.07,24.403c0.186,10.673 10.006,27.754 9.4,32.527c-0.605,4.773 -4.935,6.346 -4.208,13.712c0.728,7.365 8.378,25.007 11.912,32.452c3.533,7.446 3.611,3.17 6.323,11.043c2.712,7.874 5.013,25.683 3.461,35.058c-1.551,9.375 -4.721,20.906 -2.904,28.056c1.816,7.151 -1.079,13.357 3.406,16.525c4.485,3.167 1.982,3.683 8.964,3.566Z" />
    <path class="fill-orange-400" d="M54.306,57.65c2.034,-11.291 2.032,-9.561 5.107,-15.8c3.076,-6.239 2.482,-11.819 5.6,-12.029c3.119,-0.211 8.523,-0.268 11.277,3.518c2.753,3.786 4.231,11.274 2.337,17.845c-1.895,6.572 -4.887,13.277 -10.072,18.13c-5.185,4.854 -0.124,10.764 -3.76,11.607c-3.635,0.843 -4.814,-0.424 -8.074,2.756c-3.259,3.18 -4.587,1.305 -4.587,1.305c0,-0 1.419,-10.627 2.576,-16.825c1.157,-6.198 -2.438,0.784 -0.404,-10.507Z" />
    <path class="fill-orange-400" d="M21.017,116.261c-0,-0 0.278,-5.35 0.285,-8.878c0.007,-3.528 2.07,-2.409 0.879,-6.393c-1.191,-3.984 -0.184,-4.688 -4.269,-9.422c-4.086,-4.735 -3.803,-5.26 -4.404,-8.175c-0.602,-2.915 -1.742,-6.418 -5.472,-8.368c-3.73,-1.95 -3.389,-0.146 -3.389,-0.146c0,0 -6.264,7.776 -2.55,14.82c3.715,7.044 7.107,8.613 9.844,12.403c2.737,3.789 2.255,7.46 4.761,10.223c2.506,2.762 4.315,3.936 4.315,3.936Z" />
    </g>
  <g>
    <path class="putt-putt-green" fill="url(#grad-putt-putt-green)" d="M36.359,203.212c6.982,-0.117 10.667,2.316 14.18,-4.341c3.514,-6.658 2.333,-27.414 1.097,-36.146c-1.237,-8.731 0.609,-16.327 -0.819,-25.389c-1.429,-9.062 -2.023,-18.67 -2.081,-26.755c-0.057,-8.085 -2.764,-13.739 1.12,-19.042c3.883,-5.303 9.415,-11.51 10.058,-16.689c0.643,-5.18 8.267,-37.123 8.267,-37.123c0,-0 0.984,-7.633 -4.672,-16.557c-5.656,-8.924 -15.814,-19.474 -22.219,-20.735c-6.406,-1.26 -24.501,0.119 -30.215,5.435c-5.715,5.316 -11.257,13.73 -11.07,24.403c0.186,10.673 10.006,27.754 9.4,32.527c-0.605,4.773 -4.935,6.346 -4.208,13.712c0.728,7.365 8.378,25.007 11.912,32.452c3.533,7.446 3.611,3.17 6.323,11.043c2.712,7.874 5.013,25.683 3.461,35.058c-1.551,9.375 -4.721,20.906 -2.904,28.056c1.816,7.151 -1.079,13.357 3.406,16.525c4.485,3.167 1.982,3.683 8.964,3.566Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M54.306,57.65c2.034,-11.291 2.032,-9.561 5.107,-15.8c3.076,-6.239 2.482,-11.819 5.6,-12.029c3.119,-0.211 8.523,-0.268 11.277,3.518c2.753,3.786 4.231,11.274 2.337,17.845c-1.895,6.572 -4.887,13.277 -10.072,18.13c-5.185,4.854 -0.124,10.764 -3.76,11.607c-3.635,0.843 -4.814,-0.424 -8.074,2.756c-3.259,3.18 -4.587,1.305 -4.587,1.305c0,-0 1.419,-10.627 2.576,-16.825c1.157,-6.198 -2.438,0.784 -0.404,-10.507Z" />
    <path class="putt-putt-trap" fill="url(#grad-putt-putt-trap)" d="M21.017,116.261c-0,-0 0.278,-5.35 0.285,-8.878c0.007,-3.528 2.07,-2.409 0.879,-6.393c-1.191,-3.984 -0.184,-4.688 -4.269,-9.422c-4.086,-4.735 -3.803,-5.26 -4.404,-8.175c-0.602,-2.915 -1.742,-6.418 -5.472,-8.368c-3.73,-1.95 -3.389,-0.146 -3.389,-0.146c0,0 -6.264,7.776 -2.55,14.82c3.715,7.044 7.107,8.613 9.844,12.403c2.737,3.789 2.255,7.46 4.761,10.223c2.506,2.762 4.315,3.936 4.315,3.936Z" />
    <path class="putt-putt-tee" fill="url(#grad-putt-putt-tee)" d="M42.541,186.425l0.288,4.596c0.018,0.289 -0.202,0.538 -0.49,0.556l-10.439,0.654c-0.289,0.018 -0.538,-0.202 -0.556,-0.49l-0.288,-4.596c-0.018,-0.289 0.202,-0.537 0.49,-0.556l10.44,-0.653c0.288,-0.019 0.537,0.201 0.555,0.489Z" />
    <path class="putt-putt-hole" fill="url(#grad-putt-putt-hole)" d="M25.803,23.255c0.551,-0.696 1.563,-0.813 2.259,-0.262c0.695,0.551 0.812,1.564 0.261,2.259c-0.551,0.695 -1.563,0.812 -2.259,0.261c-0.695,-0.551 -0.812,-1.563 -0.261,-2.258Z" />
  </g>
</svg>`,
  },
]
let pollingGetUserData: any

// Computed
const resumeGame = computed(() => {
  return user.value.gameId !== -1
})
const gameIndex = computed(() => {
  // Useful for accessing the selected game data
  const gameId = user.value.games.findIndex(game => game.id === user.value.gameId)

  console.log('[debug] gameIndex: ', gameId)

  return gameId
})
const holeIndex = computed(() => {
  // Useful for retrieving active hole meta data
  return Number(page.value.split('-')[1]) - 1
})
const currentScoreToPar = computed(() => {
  const currentPlayer = user.value.games[gameIndex.value].players[group.value.playerIndex]
  const currentHoleIndex = currentPlayer.holes.length
  let pars = 0
  let strokes = 0

  for (let i = 0; i < currentHoleIndex; i++) {
    pars += holes[i].par
    strokes += currentPlayer.holes[i]
  }

  if (typeof (strokes - pars) !== 'number' || Number.isNaN(strokes - pars))
    return 0
  return Math.round(strokes - pars)
})
const currentScore = computed(() => {
  const currentPlayer = user.value.games[gameIndex.value].players[group.value.playerIndex]
  const currentHoleIndex = currentPlayer.holes.length
  let strokes = 0

  for (let i = 0; i < currentHoleIndex; i++) {
    strokes += currentPlayer.holes[i]
  }

  if (typeof (strokes) !== 'number' || Number.isNaN(strokes))
    return 0
  return Math.round(strokes)
})
const maxPlayers = computed(() => {
  return user.value.games[gameIndex.value].players.length === 5
})
const endGameReady = computed(() => {
  let ready = true

  for (const player of user.value.games[gameIndex.value].players) {
    if (player.holes.includes(null) || player.holes.includes(undefined)) {
      ready = false
      break
    }
    if (player.holes.length < 18) {
      ready = false
      break
    }
  }

  return ready
})
const leaveGameReady = computed(() => {
  return user.value.games[gameIndex.value].complete
})
const leaderboardTitle = computed(() => {
  if (leaderboardType.value === 'group_averages')
    return 'Group Averages'
  else if (leaderboardType.value === 'group_averages_to_par')
    return 'Group Averages To Par'
  else if (leaderboardType.value === 'group_totals')
    return 'Group Totals'
  else if (leaderboardType.value === 'group_totals_to_par')
    return 'Group Totals To Par'
  else if (leaderboardType.value === 'individual_scores')
    return 'Individual Scores'
  else
    return 'Individual Scores To Par'
})

// Methods
function setLeaderboardType({ prev, next }: { prev: boolean, next: boolean }) {
  if (prev) {
    const index = leaderboardTypes.indexOf(leaderboardType.value)

    if (index === undefined || index === 0)
      leaderboardType.value = leaderboardTypes[leaderboardTypes.length - 1]
    else
      leaderboardType.value = leaderboardTypes[index - 1]

    getLeaderboardData()
  }
  else if (next) {
    const index = leaderboardTypes.indexOf(leaderboardType.value)

    if (index === undefined || index === leaderboardTypes.length - 1)
      leaderboardType.value = leaderboardTypes[0]
    else
      leaderboardType.value = leaderboardTypes[index + 1]

    getLeaderboardData()
  }
}
const page = computed(() => {
  if (pageHistory.value.length === 0)
    return 'start'
  else
    return pageHistory.value[pageHistory.value.length - 1]
})
async function setPage({ value, prev, next }: { value: string, prev: boolean, next: boolean }) {
  console.log('setPage', value, prev, next)
  // let's  not push if we're already on the page
  if (page.value === value)
    return

  if (debug)
    console.log('[debug] setPage: ', { value, prev, next })

  if (prev) {
    if (page.value === 'start')
      pageHistory.value = ['start']

    pageHistory.value.pop()
  }
  else if (next) {
    const index = pages.value.indexOf(page.value)
    pageHistory.value.push(pages.value[index + 1])
  }
  else {
    // Get Leaderboard Data
    if (value === 'leaderboard')
      getLeaderboardData()

    // Resume Game
    if (page.value === 'start' && value === 'hole-1' && resumeGame.value) {
      // page.value = value
      pageHistory.value.push(value)
      return
    }

    // Set Group Play
    if (value === 'start-group' || value === 'join-group')
      setGroupPlay(true)
    else if (value === 'play-solo')
      setGroupPlay(false)

    // Validate Player
    if (
      // current page      &&     next page
      (page.value === 'start-group' && value === 'add-players')
      || (page.value === 'play-solo' && value === 'hole-1')
      || (page.value === 'join-group' && value === 'hole-1')
    ) {
      if (group.value.play && validatePlayer(true)) {
        const setGameSettingsRequest = await setGameSettings(true)
        if (setGameSettingsRequest?.status === 'Error')
          return
      }
      else if (!group.value.play && validatePlayer(false)) {
        const setGameSettingsRequest = await setGameSettings(false)
        if (setGameSettingsRequest?.status === 'Error')
          return
      }
      else {
        return
      }
    }

    // Set Page History
    if (value !== 'start')
      pageHistory.value.push(value)
    else if (value === 'start')
      pageHistory.value = ['start']
  }

  console.log('[debug] setPage end', page.value, pageHistory.value)
}
function onGameClick() {
  if (gameIndex.value > -1) {
    // we are in the middle of a game so let's load the last hole in the history
    for (let i = pageHistory.value.length - 1; i >= 0; i--) {
      if (pageHistory.value[i].includes('hole')) {
        break
      }
      else {
        pageHistory.value.pop()
      }
    }
  }
  else {
    setPage({ value: 'game', prev: false, next: false })
  }
}
function setPlayer({ prev, next }: { prev: boolean, next: boolean }) {
  if (next) {
    group.value.playerIndex = group.value.playerIndex + 1
    if (group.value.playerIndex === user.value.games[gameIndex.value].players.length)
      group.value.playerIndex = 0
  }
  else if (prev) {
    group.value.playerIndex = group.value.playerIndex - 1
    if (group.value.playerIndex < 0)
      group.value.playerIndex = user.value.games[gameIndex.value].players.length - 1
  }
}
function setGroupPlay(state: boolean) {
  group.value.name = ''
  group.value.code = ''
  group.value.play = state
}
async function setGameSettings(groupPlay: boolean) {
  if (groupPlay) {
    if (page.value === 'start-group' && group.value.play
      && group.value.name && !group.value.code) {
      return await createGroupGame()
    }
    else if (page.value === 'join-group' && group.value.play
      && !group.value.name && group.value.code) {
      return await joinGroupGame()
    }
  }
  else {
    return await createSoloGame()
  }
}
function validatePlayer(groupPlay: boolean) {
  const filter = new Filter()

  if (groupPlay && group.value.code.length < 4 && page.value === 'join-group') {
    voixToast.add('Please Enter Your Group Code', 'warning')
    return false
  }
  else if (groupPlay && group.value.name.trim() === '' && page.value === 'start-group') {
    voixToast.add('Please Enter Your Group Name', 'warning')
    return false
  }
  else if (user.value.name.trim() === '') {
    voixToast.add('Please Enter Your Name', 'warning')
    return false
  }
  else {
    if (filter.isProfane(user.value.name) || filter.isProfane(group.value.name)) {
      voixToast.add(`Let’s keep it friendly! Please remove any offensive language.`, 'warning')
      return false
    }

    return true
  }
}
function validateStrokes({ event, playerIndex, holeIndex }: { event: Event | null, playerIndex: number, holeIndex: number }) {
  const strokes = Number(event?.target ? (event.target as HTMLInputElement).value : 0)
  const minStrokes = 0
  const maxStrokes = 99

  if (typeof strokes === 'number' && Number.isInteger(strokes) && !Number.isNaN(strokes)) {
    if (strokes < minStrokes) {
      user.value.games[gameIndex.value].players[playerIndex].holes[holeIndex] = minStrokes
    }
    else if (strokes > maxStrokes) {
      user.value.games[gameIndex.value].players[playerIndex].holes[holeIndex] = maxStrokes
    }
    else {
      user.value.games[gameIndex.value].players[playerIndex].holes[holeIndex] = strokes
    }

    updateGame()
  }
  else {
    voixToast.add('Please Enter Valid Stroke Count', 'warning')
  }
}
async function addPlayer(isHostPlayer: boolean) {
  let playerName = group.value.playerInput.trim()
  let playerId = -1

  if (isHostPlayer) {
    playerName = user.value.name
    playerId = Number(user.value.id)
  }

  if (!isHostPlayer) {
    const createPlayerRequest: any = await createPlayer()
    if (createPlayerRequest.status === 'OK')
      playerId = createPlayerRequest.data.id
    else
      return
  }

  user.value.games[gameIndex.value].players.push({
    id: playerId,
    name: playerName,
    holes: [],
  })
  group.value.playerInput = ''
  updateGame()
}
function leaveGame(forceConfirm: boolean) {
  if (forceConfirm) {
    user.value.gameId = -1
    setPage({ value: 'start', prev: false, next: false })
    stopPollingGetUserData()
    window.location.reload()
    if (debug)
      console.log('[debug] leaveGame(user.value): ', JSON.parse(JSON.stringify(user.value)))
  }
  else if (confirm('Are you sure you want to leave the game?')) {
    user.value.gameId = -1
    setPage({ value: 'start', prev: false, next: false })
    stopPollingGetUserData()
    window.location.reload()
    if (debug)
      console.log('[debug] leaveGame(user.value): ', JSON.parse(JSON.stringify(user.value)))
  }
}
function endGame() {
  if (group.value.play) {
    if (confirm('Are you sure you want to submit final scores and end the game for all players?')) {
      completeGame()
    }
  }
  else {
    if (confirm('Are you sure you want to submit final scores and end the game?')) {
      completeGame()
    }
  }
}
function pollGetUserData() {
  pollingGetUserData = setInterval(() => {
    try {
      getUserData()
    }
    catch (error) {
      console.error('[debug] pollGetUserData(error)', error)
    }
  }, 2000)
}
function stopPollingGetUserData() {
  clearInterval(pollingGetUserData)
  if (debug)
    console.log('[debug] stopPollingGetUserData: ', pollingGetUserData)
}
function focusNextElement() {
  const focusedElement = document.activeElement
  const focusableElements = Array.from(document.querySelectorAll('input'))
  const currentIndex = focusedElement ? focusableElements.indexOf(focusedElement as HTMLInputElement) : -1
  const nextIndex = (currentIndex + 1) % focusableElements.length
  focusableElements[nextIndex].focus()
}
function focusPrevElement() {
  const focusedElement = document.activeElement
  const focusableElements = Array.from(document.querySelectorAll('input'))
  const currentIndex = focusedElement ? focusableElements.indexOf(focusedElement as HTMLInputElement) : -1
  const previousIndex = (currentIndex - 1 + focusableElements.length) % focusableElements.length
  focusableElements[previousIndex].focus()
}
function calcScore(playerIndex: number, toPar: boolean) {
  const currentPlayer = user.value.games[gameIndex.value].players[playerIndex]
  const currentHoleIndex = currentPlayer.holes.length
  let pars = 0
  let strokes = 0

  if (toPar) {
    for (let i = 0; i < currentHoleIndex; i++) {
      pars += holes[i].par
      strokes += currentPlayer.holes[i]
    }

    if (Number.isNaN(strokes - pars))
      return 0
    return strokes - pars
  }
  else {
    for (let i = 0; i < currentHoleIndex; i++) {
      strokes += currentPlayer.holes[i]
    }

    if (Number.isNaN(strokes))
      return 0
    return strokes
  }
}

// Actions
async function getLeaderboardData() {
  return $voixFetch(`/api/atlantis/golf/leaderboards?type=${leaderboardType.value}`)
    .then((response) => {
      leaderboard.value = response

      if (debug)
        console.log('[debug] getLeaderboardData(response): ', JSON.parse(JSON.stringify(response)))

      return { status: 'OK', data: response }
    })
    .catch((error) => {
      voixToast.add(`Oops! We’re having trouble connecting. Please check your network and try again.`, `error`)
      console.error('getLeaderboardData(error): ', error.message)

      return { status: 'Error', data: error }
    })
}
async function getUserData() {
  return $voixFetch(`/api/atlantis/golf/players/${user.value.id}`)
    .then((response) => {
      user.value.id = response.id
      user.value.name = response.name
      user.value.games = response.games

      if (debug)
        console.log('[debug] getUserData(user.value): ', JSON.parse(JSON.stringify(user.value)))

      return { status: 'OK', data: response }
    })
    .catch((error) => {
      voixToast.add(`Oops! We’re having trouble connecting. Please check your network and try again.`, `error`)
      console.error('getUserData(error): ', error.message)

      return { status: 'Error', data: error }
    })
}
async function createPlayer() {
  return $voixFetch(`/api/atlantis/golf/games`, {
    method: 'POST',
    body: {
      player_name: group.value.playerInput.trim(),
      group_name: group.value.name.trim(),
      code: group.value.code,
    },
  }).then((response) => {
    if (debug)
      console.log('[debug] createUser(response): ', JSON.parse(JSON.stringify(response)))

    return { status: 'OK', data: response }
  }).catch((error) => {
    voixToast.add(`Oops! We’re having trouble connecting. Please check your network and try again.`, `error`)
    console.error('createUser(error): ', error)

    return { status: 'Error', data: error }
  })
}
async function joinGroupGame() {
  return $voixFetch(`/api/atlantis/golf/games`, {
    method: 'POST',
    body: {
      player_name: user.value.name,
      code: group.value.code,
    },
  }).then((response) => {
    user.value = response
    pollGetUserData()

    if (debug)
      console.log('[debug] joinGroupGame(user.value): ', JSON.parse(JSON.stringify(user.value)))

    return { status: 'OK', data: response }
  }).catch((error) => {
    if (error.status === 404) {
      voixToast.add(`Sorry the code used is invalid. Please enter a different code.`, `error`)
      console.error('joinGroupGame(error): ', error.message)
    }
    else {
      voixToast.add(`Oops! We’re having trouble connecting. Please check your network and try again.`, `error`)
      console.error('joinGroupGame(error): ', error.message)
    }

    return { status: 'Error', data: error }
  })
}
async function createSoloGame() {
  return $voixFetch(`/api/atlantis/golf/games`, {
    method: 'POST',
    body: {
      player_id: user.value.id?.toString(),
      player_name: user.value.name,
    },
  }).then((response) => {
    user.value = response

    if (debug)
      console.log('[debug] createSoloGame(user.value): ', JSON.parse(JSON.stringify(user.value)))

    return { status: 'OK', data: response }
  }).catch((error) => {
    voixToast.add(`Oops! We’re having trouble connecting. Please check your network and try again.`, `error`)
    console.error('createSoloGame(error): ', error.message)

    return { status: 'Error', data: error }
  })
}
async function createGroupGame() {
  return $voixFetch(`/api/atlantis/golf/games`, {
    method: 'POST',
    body: {
      player_id: user.value.id?.toString(),
      player_name: user.value.name,
      group_name: group.value.name,
    },
  }).then((response) => {
    user.value = response
    const gameIndex = user.value.games.findIndex(game => game.id === user.value.gameId)
    group.value.code = user.value.games[gameIndex].code

    if (debug)
      console.log('[debug] createGroupGame(user.value): ', JSON.parse(JSON.stringify(user.value)))

    return { status: 'OK', data: response }
  }).catch((error) => {
    voixToast.add(`Oops! We’re having trouble connecting. Please check your network and try again.`, `error`)
    console.error('createGroupGame(error): ', error.message)

    return { status: 'Error', data: error }
  })
}
function startGroupGame() {
  pollGetUserData()
  setPage({ value: 'hole-1', prev: false, next: false })
}
async function updateGame() {
  return $voixFetch(`/api/atlantis/golf/games/${user.value.gameId}`, {
    method: 'PUT',
    body: {
      id: user.value.gameId,
      players: user.value.games[gameIndex.value].players,
    },
  }).then((response) => {
    editStroke.value = true

    if (debug)
      console.log('[debug] updateGame(response): ', JSON.parse(JSON.stringify(response)))
    if (debug)
      console.log('[debug] updateGame(user.value): ', JSON.parse(JSON.stringify(user.value)))

    return { status: 'OK', data: response }
  }).catch((error) => {
    voixToast.add(`Oops! We’re having trouble connecting. Please check your network and try again.`, `error`)
    console.error('updateGame(error): ', error.message)

    return { status: 'Error', data: error }
  })
}
async function completeGame() {
  return $voixFetch(`/api/atlantis/golf/games/${user.value.gameId}/complete`, {
    method: 'PUT',
  }).then((response) => {
    if (debug)
      console.log('[debug] completeGame(response): ', JSON.parse(JSON.stringify(response)))

    user.value.gameId = -1
    setPage({ value: 'start', prev: false, next: false })
    stopPollingGetUserData()
    window.location.reload()

    return { status: 'OK', data: response }
  }).catch((error) => {
    voixToast.add(`Oops! We’re having trouble connecting. Please check your network and try again.`, `error`)
    console.error('completeGame(error): ', error.message)

    return { status: 'Error', data: error }
  })
}

// Event Listeners
useEventListener('click', (event) => {
  if ((event.target as HTMLElement).id.includes('stroke'))
    editStroke.value = true
  else
    editStroke.value = false

  if (debug && group.value.play)
    console.log('[debug] editStroke.value:', editStroke.value)
})

// Watchers
watch(group.value.codeInput, () => {
  const oldPlayerGroupCode = group.value.code
  const newPlayerGroupCode = group.value.codeInput.join('')

  // Format Code Input
  for (let i = 0; i < group.value.codeInput.length; i++) {
    group.value.codeInput[i] = group.value.codeInput[i].toUpperCase()
  }

  // Set Player Group Code
  group.value.code = group.value.codeInput.join('')

  // Handle Focus Next Element
  if (newPlayerGroupCode.length === 1 && oldPlayerGroupCode.length === 0) {
    focusNextElement()
  }
  else if (newPlayerGroupCode.length === 2 && oldPlayerGroupCode.length === 1) {
    focusNextElement()
  }
  else if (newPlayerGroupCode.length === 3 && oldPlayerGroupCode.length === 2) {
    focusNextElement()
  }

  // Handle Focus Prev Element
  if (newPlayerGroupCode.length === 3 && oldPlayerGroupCode.length === 4) {
    focusPrevElement()
  }
  else if (newPlayerGroupCode.length === 2 && oldPlayerGroupCode.length === 3) {
    focusPrevElement()
  }
  else if (newPlayerGroupCode.length === 1 && oldPlayerGroupCode.length === 2) {
    focusPrevElement()
  }
})
watch(editStroke, () => {
  if (editStroke.value && group.value.play)
    stopPollingGetUserData()
  else if (!editStroke.value && group.value.play)
    pollGetUserData()
})
watch(page, () => {
  if (page.value === 'add-players')
    stopPollingGetUserData()
})

// Lifecycle
onMounted(async () => {
  // Get Player Data
  if (user.value.id)
    await getUserData()

  // Resume Game
  if (resumeGame.value)
    setPage({ value: 'hole-1', prev: false, next: false })

  // Set Group Play
  if (user.value.gameId !== -1 && user.value.games[gameIndex.value].players.length > 1) {
    group.value.play = true
    pollGetUserData()
  }
})

const hideScoreNotice = useCookie('golf-card-hide-score-notice')

function closeScoreNotice() {
  // set cookie
  hideScoreNotice.value = '1'
}

function scrollToTop() {
  try {
    window.scrollTo(0, 0)

    // hack in case the timing is off above
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
  }
  catch (error) {
    console.error('scrollToTop(error): ', error)
  }
}
</script>

<template>
  <div
    style="background-image:url(/imgs/golf/goodlies-plaid.gif)"
    class=" bg-repeat-x relative flex justify-center w-screen min-h-screen bg-[#f9f8f3] overflow-hidden select-none"
  >
    <!-- SVG definitions for map colors / styles -->
    <svg width="1" height="0" viewBox="0 0 232 544" fill="none" xmlns="http://www.w3.org/2000/svg">
      <filter
        id="putt-putt-filter-one" x="0" y="0.203552" width="246.873" height="542.835" filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.120505 0 0 0 0 0.33252 0 0 0 0 0.0222546 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_303" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10_303" result="shape" />
      </filter>
      <linearGradient
        id="grad-putt-putt-green" x1="4.00009" y1="-5.89407" x2="229.847" y2="492.172"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#669D5C" />
        <stop offset="1" stop-color="#6F9261" />
      </linearGradient>
      <radialGradient
        id="grad-putt-putt-tee" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.0561 8.5586) rotate(180) scale(18.0561 8.5586)"
      >
        <stop stop-color="#BBDCC8" />
        <stop offset="1" stop-color="#54D286" />
      </radialGradient>
      <radialGradient
        id="grad-putt-putt-hole" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
        gradientTransform="translate(105.439 234.954) rotate(90) scale(8.77245 8.52276)"
      >
        <stop stop-color="#0F4742" stop-opacity="0.55" />
        <stop offset="1" stop-color="#34E7F4" />
      </radialGradient>
      <radialGradient
        id="grad-putt-putt-trap" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
        gradientTransform="translate(106.445 321.537) rotate(90) scale(58.2634 23.2446)"
      >
        <stop stop-color="#EFEAD6" />
        <stop offset="1" stop-color="#D9CB91" />
      </radialGradient>
    </svg>

    <!-- Toasts -->
    <div class="fixed top-0 z-50">
      <VoixToasts />
    </div>

    <!-- Pages -->
    <div
      class="flex flex-col items-center relative w-[400px] h-full px-8 pb-36 overflow-x-hidden overflow-y-hidden"
      :class="{ 'pt-4': page.includes('hole'), 'pt-8': !page.includes('hole') }"
    >
      <!-- Navigation -->
      <div class="h-[110px]">
        <div class="fixed top-12 w-[400px] h-fit">
          <button
            v-if="page !== 'start' && page !== 'add-players' && !page.includes('hole')"
            class="flex items-center absolute top-1 left-4 adjust-left"
            @click="setPage({ value: '', prev: true, next: false })"
          >
            <Icon class="w-8 h-8 color-[#63502D]" name="ic:round-keyboard-arrow-left" />
            <span class="font-bold text-xs text-[#63502D]">
              BACK
            </span>
          </button>
        </div>

        <!-- Logo -->
        <img class="w-[109px]" src="/imgs/golf/logo.png">

        <!-- Leave Game -->
        <div v-if="page.includes('hole') && gameIndex > -1" class="absolute justify-end items-end top-12 right-0 h-fit">
          <button
            v-if="!endGameReady" class="flex items-center w-[104px] p-2"
            @click="leaveGame(false)"
          >
            <Icon class="w-4 h-4 mr-1 color-[#093b60]" name="mdi:exit-run" />
            <span class="font-bold text-xs text-[#093b60]">
              EXIT
            </span>
            <Icon class="w-8 h-8 color-[#63502D]" name="ic:round-keyboard-arrow-right" />
          </button>
        </div>
      </div>

      <!-- Start -->
      <section v-if="page === 'start'" class="flex flex-col justify-center items-center space-y-8 mt-14 ">
        <!-- Game Button -->
        <button
          class="relative w-[336px] h-[80px] bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-3xl"
          @click="setPage({ value: 'game', prev: false, next: false })"
        >
          <img class="w-24 h-24 absolute -top-2 -left-4" src="/imgs/golf/game.png">
          <span class="top-4 font-semibold text-xl text-white text-right tracking-wide">
            NEW GAME
          </span>
        </button>

        <!-- Leaderboard Button -->
        <button
          class="relative w-[336px] h-[80px] bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-3xl"
          @click="setPage({ value: 'leaderboard', prev: false, next: false })"
        >
          <img class="w-24 h-24 absolute -top-2 -left-2" src="/imgs/golf/leaderboard.png">
          <span class="top-4 font-semibold text-xl text-white tracking-wide">
            LEADERBOARD
          </span>
        </button>

        <!-- Map Button -->
        <button
          class="relative w-[336px] h-[80px] bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-3xl"
          @click="setPage({ value: 'map', prev: false, next: false })"
        >
          <img class="w-24 h-24 absolute -top-2 -left-2" src="/imgs/golf/map.png">
          <span class="top-4 font-semibold text-xl text-white tracking-wide">
            MAP
          </span>
        </button>
      </section>

      <!-- Game -->
      <section v-if="page === 'game'" class="flex flex-col justify-center items-center mt-8">
        <!-- Title -->
        <div class="font-extrabold text-2xl tracking-wide mb-3">
          NEW GAME
        </div>

        <!-- Play Solo -->
        <button
          class="flex flex-col justify-center items-center w-[305px] h-[80px] my-2 bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-2xl"
          @click="setPage({ value: 'play-solo', prev: false, next: false })"
        >
          <span class="text-xs text-[#f48273] leading-none tracking-wide">
            SINGLES
          </span>
          <span class="font-semibold text-white text-2xl tracking-wider">
            PLAY SOLO
          </span>
        </button>

        <!-- Divider -->
        <div class="w-[267px] h-[4px] bg-black my-6" />

        <!-- Start Group -->
        <button
          class="flex flex-col justify-center items-center w-[305px] h-[80px] my-2 bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-2xl"
          @click="setPage({ value: 'start-group', prev: false, next: false })"
        >
          <span class="text-xs text-[#f48273] leading-none tracking-wide">
            GROUP PLAY
          </span>
          <span class="font-semibold  text-white text-2xl tracking-wider">
            START GROUP
          </span>
        </button>

        <!-- Join Group -->
        <button
          class="flex flex-col justify-center items-center w-[305px] h-[80px] my-2 bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-2xl"
          @click="setPage({ value: 'join-group', prev: false, next: false })"
        >
          <span class="text-xs text-[#f48273] leading-none tracking-wide">
            GROUP PLAY
          </span>
          <span class="font-semibold text-white text-2xl tracking-wider">
            JOIN GROUP
          </span>
        </button>
      </section>

      <!-- Play Solo -->
      <section v-if="page === 'play-solo'" class="flex flex-col justify-center items-center mt-8">
        <!-- Title -->
        <div class="font-bold text-2xl tracking-wide mb-3">
          NEW GAME!
        </div>

        <!-- Content -->
        <div
          class="flex flex-col justify-center items-center w-[305px] h-fit bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-2xl my-2 pt-6 pb-8 px-6"
        >
          <span class="text-xs text-[#f48273] leading-none tracking-wide">
            SINGLES
          </span>
          <span class="font-semibold text-white text-2xl tracking-wider">
            PLAY SOLO
          </span>

          <span class="text-xs text-white leading-none tracking-wide mt-4">
            ENTER YOUR NAME
          </span>
          <input
            v-model="user.name"
            class="w-full h-[60px] bg-white text-[#63502D] text-2xl text-center tracking-wide rounded-2xl outline-none mt-2"
            type="text" maxlength="16"
            @keypress.enter="setPage({ value: 'hole-1', prev: false, next: false })"
          >
        </div>

        <!-- Start Button -->
        <button
          class="flex flex-col justify-center items-center w-[305px] h-[80px] my-2 bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-2xl"
          @click="setPage({ value: 'hole-1', prev: false, next: false })"
        >
          <span class="font-semibold text-white text-2xl tracking-wider">
            START GAME!
          </span>
        </button>
      </section>

      <!-- Start Group -->
      <section v-if="page === 'start-group'" class="flex flex-col justify-center items-center mt-8">
        <!-- Title -->
        <div class="font-bold text-2xl tracking-wide mb-3">
          NEW GAME!
        </div>

        <!-- Content -->
        <div
          class="flex flex-col justify-center items-center w-[305px] h-fit bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-2xl my-2 pt-6 pb-8 px-6"
        >
          <span class="text-xs text-[#f48273] leading-none tracking-wide">
            GROUP PLAY
          </span>
          <span class="font-semibold text-white text-2xl tracking-wider">
            START GROUP
          </span>

          <span class="text-xs text-white leading-none tracking-wide mt-4">
            ENTER YOUR NAME
          </span>
          <input
            v-model="user.name"
            class="w-full h-[60px] bg-white text-[#63502D] text-2xl text-center tracking-wide rounded-2xl outline-none mt-2"
            type="text" maxlength="16" placeholder="Player Name"
            @keypress.enter="setPage({ value: 'add-players', prev: false, next: false })"
          >

          <span class="text-xs text-white leading-none tracking-wide mt-4">
            ENTER GROUP NAME
          </span>
          <input
            v-model="group.name"
            class="w-full h-[60px] bg-white text-[#63502D] text-2xl text-center tracking-wide rounded-2xl outline-none mt-2"
            type="text" maxlength="16" placeholder="Group Name"
            @keypress.enter="setPage({ value: 'add-players', prev: false, next: false })"
          >
        </div>

        <!-- Start Button -->
        <button
          class="flex flex-col justify-center items-center w-[305px] h-[80px] my-2 bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-2xl"
          @click="setPage({ value: 'add-players', prev: false, next: false })"
        >
          <span class="font-semibold text-white text-2xl tracking-wider">
            START GAME!
          </span>
        </button>
      </section>

      <!-- Add Player -->
      <section v-if="page === 'add-players'" class="flex flex-col justify-center items-center mt-8">
        <!-- Player Count -->
        <div class="flex flex-row justify-center items-center space-x-2 w-[305px] mb-2">
          <Icon class="w-8 h-8 text-[#63502D]" name="mdi:users" />
          <span class="font-semibold text-[#63502D] text-2xl tracking-wider">
            {{ user.games[gameIndex].players.length }}/5 PLAYERS
          </span>
        </div>

        <!-- Inputs -->
        <div
          class="flex flex-col justify-center items-center w-[305px] h-fit bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-2xl my-2 p-6"
          :class="{
            'opacity-30': maxPlayers,
          }"
        >
          <span class="text-xs text-[#f48273] leading-none tracking-wide">
            GROUP PLAY
          </span>
          <span class="font-semibold text-white text-2xl tracking-wider">
            ADD PLAYERS
          </span>

          <span class="text-center text-xs text-white leading-none tracking-wide mt-4">
            ADD PLAYER (NO DEVICE)
          </span>
          <input
            v-model="group.playerInput"
            class="w-full h-[60px] bg-white text-[#63502D] text-2xl text-center tracking-wide rounded-2xl outline-none my-2"
            type="text" maxlength="16" placeholder="Player Name" :disabled="maxPlayers"
            @keypress.enter="addPlayer(false)"
          >

          <button
            v-if="!maxPlayers"
            class="px-3 flex flex-row justify-center items-center space-x-2 h-[50px] my-2 bg-[#f48273] rounded-2xl"
            @click="addPlayer(false)"
          >
            <Icon class="w-8 h-8 text-white" name="mdi:user-plus" />
            <span class="font-thin text-white text-base">
              ADD PLAYER
            </span>
          </button>
          <span class="mt-4 text-center text-xs text-[#f48273] leading-none tracking-wide">
            GROUP CODE
          </span>
          <span class="font-semibold text-white text-2xl tracking-wider">
            {{ group.code }}
          </span>
        </div>

        <!-- Done Button -->
        <button
          v-if="user.games[gameIndex].players.length > 0"
          class="flex flex-row justify-center items-center space-x-2 w-[305px] h-[80px] my-2 bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-2xl"
          @click="startGroupGame"
        >
          <span class="font-semibold text-white text-2xl tracking-wider">
            DONE
          </span>
        </button>
      </section>

      <!-- Join Group -->
      <section v-if="page === 'join-group'" class="flex flex-col justify-center items-center mt-8">
        <!-- Title -->
        <div class="font-bold text-2xl tracking-wide mb-3">
          NEW GAME!
        </div>

        <!-- Content -->
        <div
          class="flex flex-col justify-center items-center w-[305px] h-fit bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-2xl my-2 pt-6 pb-8 px-6"
        >
          <span class="text-xs text-[#f48273] leading-none tracking-wide">
            GROUP PLAY
          </span>
          <span class="font-semibold text-white text-2xl tracking-wider">
            JOIN GROUP
          </span>

          <span class="text-xs text-white leading-none tracking-wide mt-4">
            ENTER GROUP CODE
          </span>
          <div class="flex flex-row justify-between items-center w-full">
            <input
              v-model="group.codeInput[0]"
              class="w-[59px] h-[60px] bg-white text-[#63502D] text-2xl text-center tracking-wide rounded-2xl outline-none mt-2"
              type="text" maxlength="1" placeholder="0" @focus="$event.target.select()"
            >
            <input
              v-model="group.codeInput[1]"
              class="w-[59px] h-[60px] bg-white text-[#63502D] text-2xl text-center tracking-wide rounded-2xl outline-none mt-2"
              type="text" maxlength="1" placeholder="0" @focus="$event.target.select()"
            >
            <input
              v-model="group.codeInput[2]"
              class="w-[59px] h-[60px] bg-white text-[#63502D] text-2xl text-center tracking-wide rounded-2xl outline-none mt-2"
              type="text" maxlength="1" placeholder="0" @focus="$event.target.select()"
            >
            <input
              v-model="group.codeInput[3]"
              class="w-[59px] h-[60px] bg-white text-[#63502D] text-2xl text-center tracking-wide rounded-2xl outline-none mt-2"
              type="text" maxlength="1" placeholder="0"
              @keypress.enter="setPage({ value: 'hole-1', prev: false, next: false })" @focus="$event.target.select()"
            >
          </div>

          <span class="text-xs text-white leading-none tracking-wide mt-4">
            ENTER YOUR NAME
          </span>
          <input
            v-model="user.name"
            class="w-full h-[60px] bg-white text-[#63502D] text-2xl text-center tracking-wide rounded-2xl outline-none mt-2"
            type="text" maxlength="16" placeholder="Player Name"
            @keypress.enter="setPage({ value: 'hole-1', prev: false, next: false })"
          >
        </div>

        <!-- Start Button -->
        <button
          class="flex flex-col justify-center items-center w-[305px] h-[80px] my-2 bg-gradient-to-bl from-[#093b60] to-[#112a47] rounded-2xl"
          @click="setPage({ value: 'hole-1', prev: false, next: false })"
        >
          <span class="font-semibold text-white text-2xl tracking-wider">
            START GAME!
          </span>
        </button>
      </section>

      <!-- Leaderboard -->
      <section v-if="page === 'leaderboard'" class="flex flex-col justify-center items-center mt-8">
        <!-- Title -->
        <div class="font-thin text-lg tracking-wide mb-3">
          GOOD LIES LEADERBOARD
        </div>

        <!-- Navigation -->
        <div class="flex flex-row justify-between items-center w-full h-[33px] bg-white rounded-xl p-2">
          <button class="flex items-center" @click="setLeaderboardType({ prev: true, next: false })">
            <Icon class="w-6 h-6 color-[#63502D]" name="ic:round-keyboard-arrow-left" />
            <span class="text-xs text-[#63502D]">
              PREV
            </span>
          </button>

          <div class="w-[1px] h-full bg-[#63502D73]" />

          <span class="font-semibold text-xs text-[#63502D] tracking-wider">
            {{ leaderboardTitle }}
          </span>

          <div class="w-[1px] h-full bg-[#63502D73]" />

          <button class="flex items-center" @click="setLeaderboardType({ prev: false, next: true })">
            <span class="text-xs text-[#63502D]">
              NEXT
            </span>
            <Icon class="w-6 h-6 color-[#63502D]" name="ic:round-keyboard-arrow-right" />
          </button>
        </div>

        <!-- Content -->
        <div class="flex flex-col justify-center items-center w-[305px] h-fit rounded-2xl my-8">
          <!-- Scores -->
          <div v-if="leaderboard.length" class="w-full">
            <div v-for="(item, index) in leaderboard" v-show="item.name" :key="index" class="flex flex-col w-full h-fit">
              <div class="flex flex-row justify-between items-center w-full py-1">
                <span
                  class="flex justify-center items-center h-7 w-7 absolute left-12 rounded-full bg-[#e9bfc5] font-thin text-sm"
                >
                  {{ index + 1 }}
                </span>

                <span class="font-thin text-base text-[#63502D] leading-none tracking-wide uppercase ml-10">
                  {{ item.name }}
                </span>

                <span class="font-thin text-base text-[#63502D] leading-none tracking-wide">
                  {{ item.value }}
                </span>
              </div>

              <div class="w-full h-[1px] bg-[#63502D73] my-2" />
            </div>
          </div>
          <div v-else class="font-thin text-md tracking-wide mt-8">
            NO LEADERS YET TODAY
          </div>
        </div>
      </section>

      <!-- Holes -->
      <section v-if="page.includes('hole') && gameIndex > -1" class="max-h-[300px] flex flex-col items-center w-full">
        <!-- Title -->
        <div class="font-bold text-xl text-[#093b60] tracking-wide">
          HOLE {{ holes[holeIndex].hole }}
        </div>

        <!-- Content -->
        <div
          class="overflow-visible flex flex-row justify-start space-x-10 w-full h-full rounded-2xl pb-20 adjust-mt"
          :class="{ 'opacity-0': showFullScoreCard }"
        >
          <!-- Hole Map -->
          <div class="w-full h-full flex item-center">
            <Transition name="hole-fade-in" mode="out-in">
              <div :key="holeIndex" class="w-full h-full flex items-center scale-75" v-html="holes[holeIndex].map" />
            </Transition>
          </div>

          <!-- Hole Meta -->
          <div class="flex flex-col space-y-2 w-[104px] h-full">
            <!-- Par -->
            <div class="flex flex-col items-center w-[100px]">
              <span class="font-normal text-xs text-[#f48273] tracking-wide">
                PAR
              </span>
              <strong class="font-bold text-xl text-[#093b60] tracking-wide">
                {{ holes[holeIndex].par }}
              </strong>
            </div>

            <!-- Distance -->
            <div class="flex flex-col items-center w-[100px] pt-2">
              <span class="font-normal text-xs text-[#f48273] tracking-wide">
                DISTANCE
              </span>
              <strong class="font-bold text-xl text-[#093b60] tracking-wide">
                {{ holes[holeIndex].inches }}
              </strong>
            </div>

            <!-- Strokes -->
            <span class="font-normal text-xs text-center text-[#f48273] tracking-wide pt-2">
              STROKES
            </span>
            <div
              class="flex flex-col justify-center items-center relative w-[100px] h-[100px] bg-white rounded-2xl p-2"
              @click="closeScoreNotice"
            >
              <div class="flex justify-center items-center absolute -top-2 -right-3 w-8 h-8 bg-[#093b60] rounded-full">
                <Icon class="w-5 h-5 text-white" name="mdi:pencil-outline" />
              </div>

              <Icon class="w-7 h-7 text-[#093b60]" name="hugeicons:golf-bat" />
              <input
                id="edit-stroke" :value="user.games[gameIndex].players[group.playerIndex].holes[holeIndex]"
                placeholder="0" type="number"
                class="w-[54px] font-bold text-2xl text-[#093b60] tracking-wide text-center outline-none"
                :disabled="leaveGameReady" @keydown.enter="$event.target.blur()" @focus="$event.target.select()"
                @input="validateStrokes({ event: $event, playerIndex: group.playerIndex, holeIndex })"
                @blur="scrollToTop()"
              >
              <span class="text-center font-semibold text-[10px] tracking-wide">
                THIS HOLE
              </span>

              <div v-if="!hideScoreNotice" class="animate-bounce flex flex-row items-center absolute center-y -left-[130%] w-fit h-[33px] bg-white rounded-xl py-2 pl-3">
                <div class="pb-1">
                  Enter&nbsp;Score
                </div><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon w-8 h-8 color-[#63502D]" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M9.29 15.88L13.17 12L9.29 8.12a.996.996 0 1 1 1.41-1.41l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3a.996.996 0 0 1-1.41 0c-.38-.39-.39-1.03 0-1.42" /></svg>
              </div>
            </div>
          </div>
        </div>

        <!-- Confetti -->
        <div v-if="endGameReady" class="confetti pointer-events-none">
          <div
            v-for="(confetti, index) in Array.from({ length: 150 }, (_, i) => i + 1)" :key="index"
            :class="`confetti-${index}`"
          />
        </div>

        <!-- Notifications -->
        <div
          v-if="group.play || endGameReady" class="flex justify-end items-center fixed w-[336px] pointer-events-none"
          :class="{
            'bottom-44': !endGameReady,
            'h-[60px]': !endGameReady,
            'bottom-40': endGameReady,
            'h-[64px]': endGameReady,
          }"
        >
          <div
            v-if="!endGameReady"
            class="w-fit h-full bg-white rounded-t-2xl py-2 px-4 pointer-events-auto cursor-pointer"
          >
            <span class="font-semibold text-[11px] text-[#63502D] tracking-wider truncate">
              {{ `${user.games[gameIndex].name}` }}
            </span>

            <span class="font-semibold text-[11px] text-[#63502D] tracking-wider">
              {{ ` | ${user.games[gameIndex].code}` }}
            </span>
          </div>

          <div
            v-if="leaveGameReady"
            class="border border-gray-100 mb-32 w-full h-11 bg-white rounded-2xl p-2 pointer-events-auto cursor-pointer animate-bounce"
            @click="leaveGame(true)"
          >
            <span class="block w-full text-center font-semibold text-mx text-[#63502D] tracking-wider">
              🎉 Game Complete! Leave Game...
            </span>
          </div>

          <div
            v-else-if="endGameReady"
            class="border border-gray-100 mb-32 w-full h-11 bg-white rounded-2xl p-2 pointer-events-auto cursor-pointer animate-bounce"
            @click="endGame"
          >
            <span class="block w-full text-center font-semibold text-mx text-[#63502D] tracking-wider">
              🎉 Game Complete! Submit Scores!
            </span>
          </div>
        </div>

        <!-- Player Stats -->
        <div
          v-if="!showFullScoreCard"
          class="flex flex-row justify-between items-center fixed bottom-28 w-[336px] h-[70px] bg-[#093b60] rounded-3xl p-4 pb-6 z-10"
        >
          <!-- Player Name -->
          <div class="relative flex flex-col justify-between items-center w-[104px] h-fit font-bold text-lg text-white">
            {{ user.games[gameIndex].players[group.playerIndex].name }}

            <div class="flex justify-center items-center absolute -top-8 w-8 h-8 bg-[#093b60] rounded-full">
              <Icon v-if="!group.play" class="w-5 h-5 text-white" name="mdi:user" />

              <Icon v-if="group.play" class="w-5 h-5 text-white" name="mdi:users" />
            </div>
          </div>

          <!-- Current Score (To Par) -->
          <div class="flex flex-col justify-between items-center w-[104px] h-fit">
            <span class="text-bold text-lg text-white">
              {{ currentScoreToPar }}
            </span>
            <span class="font-thin text-[10px] text-white tracking-wide">
              TO PAR
            </span>
          </div>

          <!-- Current Score -->
          <div class="flex flex-col justify-between items-center w-[104px] h-fit">
            <span class="text-bold text-lg text-white">
              {{ currentScore }}
            </span>
            <span class="font-thin text-[10px] text-white tracking-wide">
              SCORE
            </span>
          </div>

          <!-- On Hole -->
          <div class="flex flex-col justify-between items-center w-[104px] h-fit">
            <span class="text-bold text-lg text-white">
              {{ holes[holeIndex].hole }}
            </span>
            <span class="font-thin text-[10px] text-white tracking-wide">
              HOLE
            </span>
          </div>

          <!-- Player Navigation -->
          <div
            v-if="group.play"
            class="flex flex-row items-center absolute left-5 -bottom-6 w-fit h-[33px] bg-white rounded-xl py-2"
          >
            <button class="flex items-center" @click="setPlayer({ prev: true, next: false })">
              <Icon class="w-8 h-8 color-[#63502D]" name="ic:round-keyboard-arrow-left" />
              <!-- <span class="text-[10px] text-[#63502D]">
                PREV
              </span> -->
            </button>

            <div class="w-[1px] h-full bg-[#63502D73] mx-1.5" />

            <button class="flex items-center" @click="setPlayer({ prev: false, next: true })">
              <!-- <span class="text-[10px] text-[#63502D]">
                NEXT
              </span> -->
              <Icon class="w-8 h-8 color-[#63502D]" name="ic:round-keyboard-arrow-right" />
            </button>
          </div>

          <!-- Holes Navigation -->
          <div
            class="flex flex-row items-center absolute right-5 -bottom-6 w-fit h-[33px] bg-white rounded-xl py-2"
          >
            <button
              class="flex items-center" :disabled="holeIndex === 0"
              @click="setPage({ value: '', prev: true, next: false })"
            >
              <Icon class="w-8 h-8 color-[#63502D]" name="ic:round-keyboard-arrow-left" />
              <!-- <span class="text-[10px] text-[#63502D]">
                PREV
              </span> -->
            </button>

            <div class="w-[1px] h-full bg-[#63502D73] mx-1.5" />

            <button
              class="flex items-center" :disabled="holeIndex === 17"
              @click="setPage({ value: '', prev: false, next: true })"
            >
              <!-- <span class="text-[10px] text-[#63502D]">
                NEXT
              </span> -->
              <Icon class="w-8 h-8 color-[#63502D]" name="ic:round-keyboard-arrow-right" />
            </button>
          </div>
        </div>

        <!-- Full Score Card -->
        <div
          v-if="showFullScoreCard"
          class="flex flex-row justify-start fixed bottom-28 w-[336px] h-[80%] bg-[#093b60] rounded-3xl pt-14 px-6 pb-8 adjust-h"
        >
          <div
            class="absolute top-4 left-6 rounded-full px-2 py-1 border-[1px] border-white text-white text-2xs uppercase"
            @click="showFullScoreCard = !showFullScoreCard"
          >
            X
          </div>
          <!-- Holes -->
          <div class="flex flex-col justify-between h-full w-[56px]">
            <span
              v-for="(_hole, _hole_index) in Array.from({ length: 18 }, (_, i) => i + 1)" :key="_hole_index"
              class="font-normal text-[10px] text-white tracking-wide"
            >
              HOLE {{ _hole }}
            </span>
            <!-- Invisible Devider -->
            <div class="w-full h-[1px] -my-2" />
            <span class="font-bold text-[11px] text-white tracking-wide">
              SCORES
            </span>
            <span class="font-normal text-[10px] text-white tracking-wide">
              TO PAR
            </span>
          </div>

          <!-- Divider -->
          <div class="w-[1px] h-full bg-white" />

          <!-- Players -->
          <div
            v-for="(_player, _player_index) in user.games[gameIndex].players" :key="_player_index"
            class="flex flex-row relative justify-start h-full w-fit"
          >
            <span class="absolute -top-7 rotate-45 font-bold text-xs text-white tracking-wide">
              {{ _player.name }}
            </span>

            <div class="flex flex-col justify-between px-3">
              <input
                v-for="(_hole, _hole_index) in 18" :id="`edit-stroke-${_hole_index}`" :key="_hole_index"
                :value="user.games[gameIndex].players[_player_index].holes[_hole_index]" placeholder="-"
                :tabindex="(_hole_index * (user.games[gameIndex].players.length)) + _player_index + 1" type="number"
                class="stroke w-4 bg-transparent font-normal text-xs text-white tracking-wide text-center outline-none"
                :disabled="leaveGameReady" @focus="$event.target.select()"
                @input="validateStrokes({ event: $event, playerIndex: _player_index, holeIndex: _hole_index })"
              >
              <div class="w-full h-[1px] bg-white opacity-50 -my-2" />

              <span class="font-normal text-xs text-white tracking-wide text-center">
                {{ calcScore(_player_index, false) }}
              </span>

              <span class="font-normal text-xs text-white tracking-wide text-center">
                {{ calcScore(_player_index, true) }}
              </span>
            </div>

            <div class="w-[1px] h-full bg-white" />
          </div>

          <!-- Add Player -->
          <div
            v-if="group.play && !maxPlayers"
            class="flex justify-center items-center absolute -top-2 -right-2 w-9 h-9 bg-white rounded-full cursor-pointer"
            @click="setPage({ value: 'add-players', prev: false, next: false })"
          >
            <Icon class="w-6 h-6 text-[#63502D]" name="mdi:user-plus" />
          </div>

          <!-- Navigation -->
          <div
            class="flex flex-row justify-center items-center absolute -bottom-7 right-[14px] w-[304px] h-[33px] bg-white rounded-xl p-2"
            @click="showFullScoreCard = !showFullScoreCard"
          >
            <span class="font-semibold text-[11px] text-[#63502D] tracking-wider cursor-pointer">
              HOLE GUIDES
            </span>
          </div>
        </div>

        <!-- Full Score Card Button -->
        <div
          v-if="!showFullScoreCard"
          class="flex justify-center items-center fixed bottom-[80px] w-12 h-12 bg-[#093b60] border-white border-[1px] rounded-full cursor-pointer z-20"
          @click="showFullScoreCard = !showFullScoreCard"
        >
          <Icon class="w-8 h-8 text-white" name="mdi:table" />
        </div>
      </section>

      <section v-if="page === 'map'" class="py-12">
        <NuxtImg src="/imgs/maps/putt-putt-map.png" alt="Putt Putt Map" />
      </section>
    </div>

    <!-- Navbar -->
    <div
      class="flex justify-center items-center space-x-14 w-full fixed bottom-0 bg-[#f9f8f3] px-2 pt-4 pb-2 bg-repeat-x"
      style="background-image:url(/imgs/golf/goodlies-plaid.gif)"
    >
      <!-- Games -->
      <button class="flex flex-col items-center space-y-2 text-base" tabindex="-1" @click="onGameClick">
        <Icon class="w-6 h-6 text-[#093b60]" name="hugeicons:golf-bat" />
        <span class="font-bold text-xs text-[#f48273]">
          GAMES
        </span>
      </button>

      <!-- Leaderboard -->
      <button
        class="flex flex-col items-center space-y-2 text-base" tabindex="-1"
        @click="setPage({ value: 'leaderboard', prev: false, next: false })"
      >
        <Icon class="w-6 h-6 text-[#093b60]" name="solar:cup-outline" />
        <span class="font-bold text-xs text-[#f48273]">
          LEADERBOARD
        </span>
      </button>

      <!-- Holes -->
      <button
        class="flex flex-col items-center space-y-2 text-base" tabindex="-1"
        @click="setPage({ value: 'map', prev: false, next: false })"
      >
        <Icon class="w-6 h-6 text-[#093b60]" name="ic:baseline-golf-course" />
        <span class="font-bold text-xs text-[#f48273]">
          HOLES
        </span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.adjust-mt {
  margin-top: 1rem;
}

@media (max-height: 845px) {
  .adjust-overflow {
    overflow-y: auto;
  }

  .adjust-left {
    left: 2rem;
  }

  .adjust-right {
    right: 2rem;
  }

  .adjust-mt {
    margin-top: 0rem;
  }
}

/* Confetti */
.confetti {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

[class|="confetti"] {
  position: absolute;
}

$colors: (
  #d13447,
  #ffbf00,
  #263672
);

@for $i from 0 through 150 {
  $w: random(10);
  $l: random(100);

  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w*0.4}px;
    background-color: nth($colors, random(3));
    top: -10%;
    left: unquote($l+"%");
    opacity: random() + 0.5;
    transform: rotate(#{random()*360}deg);
    animation: drop-#{$i} unquote(4+random()+"s") unquote(random()+"s") infinite;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l+random(15)+"%");
    }
  }

}
</style>

<style lang="scss">
/* Hole Fade In */

.hole-fade-in-leave-active {
  transition: all 0.0001s;

  .putt-putt-shadows {
    transition: all 0.5s;
  }

  .putt-putt-green,
  .putt-putt-tee,
  .putt-putt-hole,
  .putt-putt-trap {
    transition: all .25s ease-in;
  }

  .putt-putt-trap {
    transition-delay: 0.3s;
  }

  .putt-putt-green {
    transition-delay: 0.6s;
  }
}

.hole-fade-in-enter-active {
  transition: all 2s;

  .putt-putt-shadows {
    transition: all 1s;
    transition-delay: 1s
  }

  .putt-putt-green,
  .putt-putt-tee,
  .putt-putt-hole,
  .putt-putt-trap {
    transition: all .25s ease-out;
  }

  .putt-putt-green {
    transition-delay: .5s;
  }

  .putt-putt-trap {
    transition-delay: 0.3s;
  }

  .putt-putt-hole {
    transition-delay: 0s;
  }

  .putt-putt-tee {
    transition-delay: 0.1s;
  }
}

.hole-fade-in-enter-from,
.hole-fade-in-leave-to {
  transition-delay: .75s;

  .putt-putt-shadows {
    opacity: 0;
  }

  .putt-putt-green {
    opacity: 0;
    transform: scale(0.5);
    transform-origin: top left;
  }

  .putt-putt-tee {
    opacity: 0;
    transform: scale(0.75);
    transform-origin: top left;
  }

  .putt-putt-hole {
    opacity: 0;
    transform: scale(0.75);
    transform-origin: top left;
  }

  .putt-putt-trap {
    opacity: 0;
    transform: scale(0.75);
    transform-origin: top left;
  }

}
</style>
