<script>
export default {
  name: 'GenericButtons',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <a
    :href="devise.fields.link.value?.href"
    :target="devise.fields.link.value.target"
    :rel="devise.fields.link.value.rel"
    class="btn-ghost"
  >{{ devise.fields.link.value.text }}</a>
</template>
