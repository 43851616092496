<script>
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    devise: { type: Object },
    posts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      swiper: null,
    }
  },

  computed: {
    currentSlide() {
      if (this.swiper) {
        if (this.swiper.realIndex > this.posts.length)
          return 1

        if (this.swiper.realIndex < 1)
          return this.posts.length

        return this.swiper.realIndex
      }
      return null
    },

    wtf() {
      return this.swiper.realIndex
    },

    currentPost() {
      return this.posts[this.currentSlide - 1]
    },
  },

  mounted() {
    this.updateOnInit()
  },

  methods: {
    updateOnInit() {
      setTimeout(() => {
        if (this.$refs.mySwiper?.$el?.swiper) {
          this.$refs.mySwiper.$el.swiper.update()
          this.swiper = this.$refs.mySwiper.$el.swiper
        }
        else {
          this.updateOnInit()
        }
      }, 1000)
    },

    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    goToSlide(index) {
      this.swiper.slideTo(index)
    },
  },
}
</script>

<template>
  <div v-if="posts.length > 0" class="relative overflow-hidden">
    <Swiper
      ref="mySwiper"
      :slides-per-view="1"
      :centered-slides="true"
      :pagination="false"
      :navigation="false"
      :autoplay="{
        delay: 4000,
        disableOnInteraction: false,
      }"
      class="relative z-0"
    >
      <SwiperSlide v-if="posts[0]">
        <div class="h-[850px] max-h-[90vh] bg-glueblue-600 w-full">
          <img
            :src="posts[0].header_image"
            :alt="`Thumbnail for ${posts[0].title}`"
            class="w-full h-full object-cover"
          >
        </div>
      </SwiperSlide>
      <SwiperSlide v-if="posts[1]">
        <div class="h-[850px] max-h-[90vh] bg-red-600 w-full">
          <img
            :src="posts[1].header_image"
            :alt="`Thumbnail for ${posts[1].title}`"
            class="w-full h-full object-cover"
          >
        </div>
      </SwiperSlide>
      <SwiperSlide v-if="posts[2]">
        <div class="h-[850px] max-h-[90vh] bg-orange-600 w-full">
          <img
            :src="posts[2].header_image"
            :alt="`Thumbnail for ${posts[2].title}`"
            class="w-full h-full object-cover"
          >
        </div>
      </SwiperSlide>
      <SwiperSlide v-if="posts[3]">
        <div class="h-[850px] max-h-[90vh] bg-green-600 w-full">
          <img
            :src="posts[3].header_image"
            :alt="`Thumbnail for ${posts[3].title}`"
            class="w-full h-full object-cover"
          >
        </div>
      </SwiperSlide>
      <SwiperSlide v-if="posts[4]">
        <div class="h-[850px] max-h-[90vh] bg-yellow-600 w-full">
          <img
            :src="posts[4].header_image"
            :alt="`Thumbnail for ${posts[4].title}`"
            class="w-full h-full object-cover"
          >
        </div>
      </SwiperSlide>
    </Swiper>

    <div
      class="absolute z-30 bottom-0 mb-4 lg:inset-0 pointer-events-none flex lg:justify-between items-center"
    >
      <button
        class="pointer-events-auto flex lg:absolute lg:z-50 top-0 bottom-0 left-0 ml-10 -mt-8 justify-center items-center"
        @click="prev"
      >
        <div class="slider-button text-orange-900 hover:text-white bg-orange-50">
          <svg
            class="w-7 h-7"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </button>
      <button
        class="pointer-events-auto flex lg:absolute lg:z-50 top-0 bottom-0 right-0 mr-8 -mt-8 justify-center items-center"
        @click="next"
      >
        <div class="slider-button text-orange-900 hover:text-white bg-orange-50">
          <svg
            class="w-7 h-7 transform rotate-180"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </button>
    </div>

    <div
      class="pointer-events-none absolute z-30 inset-0 px-8 lg:px-20 py-32 lg:py-16 flex justify-center lg:justify-end item-start lg:items-end"
    >
      <GlueLogosIslandTime width="131" height="148" class="text-zinc-200" />
    </div>

    <div
      class="absolute z-20 bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-black/0 h-[50vh] pointer-events-none"
    >
      <div
        class="absolute z-30 bottom-0 text-white ml-8 lg:ml-20 mb-12 lg:mb-12"
        style="width: 300px"
      >
        <div
          class="text-3xl uppercase text-4xl font-sans font-light leading-snug antialiased tracking-wide"
        >
          {{ currentPost ? currentPost.title : "" }}
        </div>
        <a
          class="btn-ghost !text-white !border-white btn-sm my-8 pointer-events-auto"
          :href="currentPost ? currentPost.permalink : ''"
        >
          Learn More
        </a>
      </div>
    </div>
  </div>
</template>
