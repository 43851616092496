<script>
export default {
  name: 'IconDining',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 100"
  >
    <path
      transform="translate(-25,-25)"
      fill="currentColor"
      d="M108,50c-4.5-4.5-10.4-7-16.8-7c-6.3,0-12.3,2.5-16.8,7c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1-0.1-0.2-0.3-0.3-0.4
	c-4.5-4.5-10.4-7-16.8-7c-6.3,0-12.3,2.5-16.8,7c-4.5,4.5-6.9,10.5-6.9,16.9c0,6.4,2.5,12.4,6.9,16.9c0.2,0.2,0.5,0.5,0.7,0.7l0,0
	l15.6,15.7c0.3,0.3,0.5,0.6,0.8,0.9c4.5,4.5,10.4,7,16.8,7c0.4,0,0.8,0,1.2,0c5.9-0.3,11.4-2.7,15.6-7c0.3-0.3,0.6-0.6,0.8-0.9
	l15.6-15.7l0,0c0.2-0.2,0.5-0.4,0.7-0.7C117.3,74.4,117.3,59.3,108,50z M51.4,84.1c0-6,2.3-11.6,6.4-15.9l0.5-0.5
	c2.8-2.7,6.1-4.6,9.7-5.6c-0.3,1.6-0.5,3.2-0.5,4.8c0,5.9,2.1,11.4,5.9,15.8c-0.1,0.1-0.2,0.3-0.4,0.4c-4.3,4.3-10,6.7-16.1,6.7
	c-1.7,0-3.4-0.2-5-0.6C51.6,87.5,51.4,85.8,51.4,84.1z M68.5,66.8c0-1.7,0.2-3.4,0.6-5.1c1.6-0.4,3.3-0.6,5-0.6c1.7,0,3.4,0.2,5,0.6
	c1.5,6.9-0.1,14.4-5,20.1C70.5,77.7,68.5,72.4,68.5,66.8z M80.2,62.1c3.7,1,7.2,3,10,5.8c5.7,5.8,7.7,13.8,6.1,21.2
	c-1.6,0.4-3.3,0.6-5,0.6c-6.1,0-11.8-2.4-16.1-6.7c-0.1-0.1-0.3-0.3-0.4-0.4C79.9,76.8,81.7,69.2,80.2,62.1z M74.1,60.2
	C74.1,60.2,74.1,60.2,74.1,60.2C74.1,60.2,74.1,60.2,74.1,60.2c-1.6,0-3.2,0.2-4.8,0.5c0.9-3.2,2.5-6.3,4.7-8.8
	c2.3,2.6,3.9,5.7,4.7,8.8C77.3,60.4,75.8,60.2,74.1,60.2z M68.3,60.9c-1.8,0.5-3.6,1.2-5.3,2.1l7.4-7.4
	C69.4,57.3,68.7,59.1,68.3,60.9z M58.4,100.6l-0.7-0.7c-2.6-2.8-4.5-6.1-5.5-9.7c1.5,0.3,3.1,0.5,4.7,0.5c6.3,0,12.3-2.5,16.8-7
	c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.2,0.3,0.4,0.4c4.5,4.5,10.4,7,16.8,7c1.6,0,3.2-0.2,4.7-0.5c-1,3.7-2.9,7.1-5.8,10
	c-4.3,4.3-10,6.7-16.1,6.7C68.2,107,62.7,104.7,58.4,100.6z M80,60.9c-0.5-1.9-1.2-3.7-2.1-5.5l7.5,7.6C83.7,62.1,81.8,61.4,80,60.9
	z M34.3,66.8c0-6.1,2.4-11.9,6.7-16.2c4.3-4.3,10-6.7,16.1-6.7c6.1,0,11.8,2.4,16.1,6.7c0.1,0.1,0.2,0.3,0.4,0.4L58,66.5
	c-0.2,0.2-0.5,0.4-0.7,0.7c-0.2,0.2-0.4,0.5-0.6,0.7l-0.1,0.1l0,0c-4,4.4-6.2,10.1-6.2,16.1c0,1.6,0.2,3.2,0.5,4.8
	c-3.7-1-7.1-3-9.9-5.8C36.6,78.7,34.3,72.9,34.3,66.8z M45.8,87.9c1.7,0.9,3.4,1.6,5.3,2.1c0.5,1.9,1.1,3.6,2,5.3L45.8,87.9z
	M95.1,95.3c0.9-1.7,1.6-3.5,2.1-5.4c1.9-0.5,3.7-1.2,5.4-2.1L95.1,95.3z M97.4,88.9c0.3-1.6,0.5-3.2,0.5-4.8
	c0-6.4-2.5-12.4-6.9-16.9c-0.2-0.2-0.5-0.5-0.7-0.7l0,0L74.9,51c0.1-0.1,0.2-0.2,0.3-0.4c4.3-4.3,10-6.7,16.1-6.7
	c6.1,0,11.8,2.4,16.1,6.7c8.9,8.9,8.9,23.4,0,32.3C104.5,85.8,101.1,87.8,97.4,88.9z"
    />
  </svg>
</template>
