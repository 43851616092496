<script setup lang="ts">
import type { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  name: { label: 'Event Slider', group: 'Sliders', layouts: ['Npiwff'] },
  description: 'Event Slider Slice',
  tags: ['Slider', 'Event'],
  slots: [{ name: 'default' }],
  fields: {
    isRight: { label: 'Clips to the right', type: 'checkbox', default: true },
    title: { type: 'text', label: 'Title' },
    description: { type: 'textarea', label: 'Description' },
    wideTitle: { type: 'checkbox', label: 'Wide Title', default: true },
    autoPlay: { type: 'checkbox', label: 'Auto Play Slider?', default: false },
    linkPosition: { type: 'select', label: 'Link Position', options: { underneath: 'Underneath', beside: 'Beside' } },
    buttonLink: { type: 'link', label: 'Button Link', enabled: false },
    textLink: { type: 'link', label: 'Text Link', enabled: false },
  },
  templates: [{
    label: 'Event Slider',
    fields: {
      buttonLink: { value: { text: 'view all dining', target: '_self', relationship: null, href: '\/new-page' } },
      description: { value: 'With over 40 restaurants and 3 Michelin starred chefs, you can see why Atlantis is an epicurean destination.' },
      isRight: { value: true },
      linkPosition: { value: 'underneath' },
      title: { value: 'ATLANTIS BAHAMAS - THE CULINARY CAPITAL OF THE CARIBBEAN' },
    },
  }],
})

const swiper: Ref<SwiperType | null> = ref(null)
const windowWidth = ref(1200)

const containerWidth = computed(() => {
  let width = '1200px'
  if (windowWidth.value > 1680) {
    width = '1450px'
  }
  else if (windowWidth.value > 1120) {
    const threefourths = windowWidth.value * 0.75
    const bleedWidth = (windowWidth.value * 0.25) / 2
    width = `${bleedWidth + threefourths}px`
  }
  else {
    width = '100%'
  }

  return width
})
const activeIndex = computed(() => {
  if (swiper.value)
    return Number.parseInt(swiper.value.activeIndex) - 1

  return null
})
const autoplayOptions = computed(() => {
  if (props.fields.autoPlay.value) {
    return {
      delay: 5000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    }
  }

  return false
})

function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}
function next() {
  if (swiper.value)
    swiper.value.slideNext()
}
function prev() {
  if (swiper.value)
    swiper.value.slidePrev()
}
function goToSlide(index: number) {
  if (swiper.value)
    swiper.value.slideTo(index)
}

onMounted(() => {
  windowWidth.value = window.innerWidth
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth
  })
})
</script>

<template>
  <div :class="{ 'big-container-left': !fields.isRight.value, 'big-container-right': fields.isRight.value }">
    <div
      class="flex"
      :class="{ 'justify-end': fields.isRight.value, 'justify-start': !fields.isRight.value }"
    >
      <div :style="{ width: containerWidth }" class="pt-20">
        <!-- Slider Header -->
        <div
          class="px-8 md:px-0 flex flex-col"
          :class="{
            'items-start': fields.isRight.value,
            'items-end text-right': !fields.isRight.value,
            'lg:flex-row-reverse items-end':
              fields.linkPosition.value === 'beside'
              && !fields.isRight.value,
            'lg:flex-row  items-end':
              fields.linkPosition.value === 'beside'
              && fields.isRight.value,
            'md:pr-12': fields.isRight.value,
            'md:pl-12': !fields.isRight.value,
          }"
        >
          <div
            class="flex flex-col pb-8"
            :class="{
              'items-end': !fields.isRight.value,
              'lg:pl-48':
                fields.linkPosition.value === 'beside'
                && !fields.isRight.value,
              'lg:pr-48':
                fields.linkPosition.value === 'beside'
                && fields.isRight.value,
            }"
          >
            <!-- Title -->
            <div class="w-fit font-wff-headline text-wff-primary text-[38px] font-extrabold uppercase tracking-widest">
              {{ fields.title.value }}
            </div>

            <!-- Divider -->
            <div
              class="w-[332px] h-[1px] bg-wff-secondary mt-6 mb-8"
            />

            <!-- Description -->
            <VoixWysiwyg
              class="w-fit h-fit lg:max-w-[913px] text-wff-primary text-lg"
              :field="fields.description"
            />
          </div>

          <!-- Links -->
          <div
            class="flex items-center space-x-8 mb-8"
            :class="{
              'lg:ml-8':
                fields.linkPosition.value === 'beside'
                && !fields.isRight.value,
              'lg:mr-8':
                fields.linkPosition.value === 'beside'
                && fields.isRight.value,
            }"
          >
            <a
              v-if="fields.buttonLink.enabled"
              :href="fields.buttonLink.value?.href"
              :target="fields.buttonLink.value.target"
              :rel="fields.buttonLink.value.rel"
              class="inline-block text-center border-2 px-6 pt-2 pb-4 text-wff-primary border-wff-primary hover:bg-wff-primary hover:text-white duration-200 uppercase font-sans font-bold tracking-widest min-w-52"
            >
              {{ fields.buttonLink.value.text }}</a>
            <a
              v-if="fields.textLink.enabled"
              :href="fields.textLink.value?.href"
              :target="fields.textLink.value.target"
              :rel="fields.textLink.value.rel"
              class="special-learnmore uppercase text-xs font-sans text-wff-primary border-b border-wff-primary hover-draw-border"
            >
              {{ fields.textLink.value.text }}
            </a>
          </div>
        </div>

        <!-- Slider Container -->
        <div class="w-full relative md:ml-0 md:mr-0 overflow-hidden flex">
          <!-- Slider -->
          <Swiper
            v-if="slice.elements.length > 0"
            slides-per-view="auto"
            :dir="fields.isRight.value ? 'ltr' : 'rtl'"
            :space-between="10"
            :loop="slice.elements.length > 1"
            :autoplay="autoplayOptions"
            :centered-slides="false"
            @swiper="onSwiper"
          >
            <SwiperSlide
              v-for="(s, key) in slice.elements"
              :key="key"
              style="direction: ltr"
            >
              <div class="h-full flex">
                <VoixSlice
                  :slice="s"
                  :slice-index="key"
                  style="direction: ltr"
                />
              </div>
            </SwiperSlide>
          </Swiper>

          <div v-else class="aspect-video w-full h-full bg-orange-200 flex justify-center items-center">
            <Icon name="heroicons:photo-20-solid" class="w-12 h-12 text-orange-500" />
          </div>

          <!-- Slider Navigation -->
          <div
            v-if="slice.elements.length > 1"
            class="absolute z-50 inset-0 flex justify-between items-center px-8 pointer-events-none"
          >
            <button class="hover:bg-wff-primary pointer-events-auto" @click="prev">
              <svg
                class="w-7 h-7"
                fill="white"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>

            <button class="hover:bg-wff-primary pointer-events-auto" @click="next">
              <svg
                class="w-7 h-7 transform rotate-180"
                fill="white"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>

        <!-- Slider Pagination -->
        <div class="flex justify-center lg:justify-end px-4">
          <div class="flex space-x-3 mt-6">
            <button
              v-for="(slide, n) in slice.elements"
              :key="n"
              class="h-1.5 w-1.5 rounded-full transistion-colors duration-500"
              :class="{
                'bg-gray-500': n !== activeIndex % slice.elements.length,
                'bg-white': n === activeIndex % slice.elements.length,
              }"
              @click="goToSlide(n)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto;
  height: auto;
}
.swiper-wrapper {
  @apply items-stretch;
}
</style>
