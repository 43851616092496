<script setup lang="ts">
const props = defineProps({
  gallery: {
    type: Array<any>,
    required: true,
  },
  show: {
    type: Boolean,
    required: true,
  },
  initialImage: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['close'])
const modal: Ref<HTMLDialogElement | null> = ref(null)

const state = reactive({
  currentImage: 0,
  wtf: 0,
})

watch([props], () => {
  if (props.show) {
    modal.value?.showModal()
    state.currentImage = props.initialImage
  }
})

function close() {
  emit('close')
  modal.value?.close()
}

function backdropClickCheck(event: MouseEvent) {
  const target = event.target as HTMLElement
  const rect = target.getBoundingClientRect()

  if (rect.left > event.clientX
    || rect.right < event.clientX
    || rect.top > event.clientY
    || rect.bottom < event.clientY
  ) {
    close()
  }
}

function previous() {
  if (state.currentImage === 0)
    state.currentImage = props.gallery.length - 1

  else
    state.currentImage--
}

function next() {
  if (state.currentImage === props.gallery.length - 1)
    state.currentImage = 0

  else
    state.currentImage++
}
</script>

<template>
  <div>
    <Transition name="slide-fade">
      <dialog ref="modal" class="bg-transparent" @click="backdropClickCheck">
        <button class="pointer-event-auto absolute m-1 z-50 top-0 right-0 text-white" @click="close">
          <Icon name="heroicons:x-mark" class="w-8 h-8" />
        </button>
        <div class=" relative z-10 flex items-center justify-between" @click="close">
          <button class="pointer-events-auto bg-paranza-400 p-4" @click.stop.exact="previous">
            <Icon name="heroicons:chevron-left" class="w-8 h-8 text-white" />
          </button>

          <Transition name="slide-fade">
            <VoixMedia
              v-if="props.gallery[state.currentImage]?.url && props.gallery[state.currentImage]?.provider"
              :field="props.gallery[state.currentImage].url"
              :provider="props.gallery[state.currentImage].provider"
              class="select-none h-full max-w-[900px] max-h-[600px] duration-500 border-[20px] border-white"
              width="50%"
              @click.stop=""
            />
          </Transition>
          <button class="pointer-events-auto bg-paranza-400 p-4" @click.stop.exact="next">
            <Icon name="heroicons:chevron-right" class="w-8 h-8 text-white" />
          </button>
        </div>
        <div>
          <div class="relative z-10 pointer-events-none flex justify-center mt-4">
            <button
              v-for="(image, index) in props.gallery"
              :key="index"
              class="pointer-events-auto h-24 max-h-[20vh] focus:outline-none rounded p-0 duration-500"
              :class="{
                'border-2 border-paranza-400': state.currentImage === index,
                'scale-75': state.currentImage !== index,
              }"
              @click="state.currentImage = index"
            >
              <VoixMedia v-if="image" :field="image.url" :provider="image.provider" class="object-cover w-full h-full slide-fade select-none" />
            </button>
          </div>
        </div>
      </dialog>
    </Transition>
  </div>
</template>

<style scoped>
dialog::backdrop {
  @apply bg-black/75 cursor-pointer;
}

.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.9s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  @apply translate-y-4 scale-90;
  opacity: 0;
}
</style>
