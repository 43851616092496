<script>
export default {
  name: 'ClippedMedia',
  props: { devise: { type: Object, default: () => ({}) } },
  data() {
    return {
      windowWidth: 1200,
    }
  },
  computed: {
    videoStyles() {
      const styles = {
        height: '750px',
      }

      if (this.$voix.breakpoint.value === 'mobile' || this.$voix.breakpoint.value === 'tablet')
        styles.height = '200px'

      return styles
    },
    containerWidth() {
      let width = '1200px'
      if (this.windowWidth > 1680) {
        width = '1450px'
      }
      else if (this.windowWidth > 1120) {
        const threefourths = this.windowWidth * 0.75
        const bleedWidth = (this.windowWidth * 0.25) / 2
        width = `${bleedWidth + threefourths}px`
      }
      else {
        width = '100%'
      }

      return width
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
}
</script>

<template>
  <div>
    <div
      class="flex" :class="{
        'justify-center': devise.fields.neverClip.value || windowWidth > 1680,
        'justify-end': !devise.fields.neverClip.value && windowWidth <= 1680,
      }"
    >
      <div :style="{ width: containerWidth }" class="pb-20">
        <div
          class="px-8 md:px-0 md:pr-8 md:ml-8 text-center md:text-left" :class="{
            '2xl:text-center': windowWidth > 1680 || devise.fields.neverClip.value,
          }"
        >
          <div
            class="max-w-140 body-lg mb-6"
          >
            {{ devise.fields.copy.value }}
          </div>
          <a
            v-if="devise.fields.link.enabled"
            class="glue-btn btn-ghost border-zinc-600 text-zinc-700 text-xs mb-8"
            :href="devise.fields.link.value?.href"
          >
            {{ devise.fields.link.value.text }}
          </a>
        </div>

        <div :class="{ 'lg:ml-8 md:ml-0': !devise.fields.neverClip.value }">
          <div
            v-if="!devise.fields.youtubeId.value && !devise.fields.wistiaId.value"
            class="bg-glueblue-700"
          >
            <VoixMedia :field="devise.fields.image" class="w-full h-full" />
          </div>

          <div v-if="devise.fields.wistiaId.value">
            <GlueMediaWistia
              ref="vimeo"
              :video-id="devise.fields.wistiaId.value"
              class="w-full aspect-video"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
