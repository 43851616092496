<script lang="ts" setup>
import { getCurrentUser } from '@voix/composables/queries/useQueryCurrentUser'
import { useUserStore } from '@voix/store/userStore'

const userStore = useUserStore()
await getCurrentUser()
const currentUser = userStore.currentUser
const errorMessage = ref('')

const saveComplete = ref(false)
const userData = ref({
  name: currentUser?.name,
  email: currentUser?.email,
  password: null,
  password_confirmation: null,
  updatePassword: false,
})

async function attemptUpdate() {
  const { data, error } = await $voixFetch('/api/devise/reef-profiles', {
    method: 'PUT',
    body: userData.value,
  })

  if (error)
    errorMessage.value = error.data.message

  if (data)
    saveComplete.value = true
}

defineSlice({
  name: { label: 'Reef My Account', group: 'Account', layouts: ['Reef'] },
  preview: 'SlicesReefMyAccount.jpg',
  description: 'Profile information form',
  tags: ['Profile', 'Account', 'Form'],
  slots: [],
  fields: {},
  templates: [{
    label: 'Reef My Account',
    fields: {},
  }],
})
</script>

<template>
  <div class="container mt-24 flex flex-col justify-center items-center">
    <h1 class="mb-4 font-sans2 uppercase font-light">
      Your account settings for The Reef
    </h1>

    <div v-if="errorMessage" class="leading-loose">
      <div class="my-10 text-xl uppercase font-bold text-glueblue-600">
        {{ errorMessage }}
      </div>
    </div>

    <div v-if="saveComplete" class="leading-loose text-center mb-4">
      <div class="my-10 text-2xl uppercase font-bold text-glueblue-600">
        Success, your account has been updated.
      </div>
    </div>

    <form v-else class="w-full bg-white px-4 py-3 rounded-lg">
      <div class="w-full">
        <div class="py-4">
          <fieldset>
            <label class="text-left">Name</label>
            <input v-model="userData.name" name="last_name" type="text">
          </fieldset>
        </div>

        <div class="py-4 items-center">
          <fieldset>
            <label class="text-left">Email</label>
            <input v-model="userData.email" name="last_name" type="email">
          </fieldset>
        </div>

        <div class="py-4 items-center">
          <fieldset>
            <label class="text-left">
              Change Password
              <input
                v-model="userData.updatePassword"
                class="text-left"
                type="checkbox"
              >
            </label>
          </fieldset>
        </div>

        <div v-if="userData.updatePassword" class="py-4 items-center">
          <fieldset>
            <label class="text-left">New Password</label>
            <input v-model="userData.password" name="last_name" type="password">
          </fieldset>
        </div>

        <div v-if="userData.updatePassword" class="py-4 items-center">
          <fieldset>
            <label class="text-left">Confirm New Password</label>
            <input
              v-model="userData.password_confirmation"
              name="last_name"
              type="password"
            >
          </fieldset>
        </div>

        <div class="flex justify-center">
          <input
            class="btn btn-xs bg-glueblue-600 text-white mt-4"
            value="Save Changes"
            @click.prevent="attemptUpdate"
          >
        </div>
      </div>
    </form>
  </div>
</template>
