<script>
export default {
  name: 'GlueWaterParkIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 45 45"
  >
    <defs>
      <path id="prefix__a" d="M0 0.28L44.275 0.28 44.275 6.206 0 6.206z" />
      <path id="prefix__c" d="M0 0.266L44.275 0.266 44.275 6.192 0 6.192z" />
      <path id="prefix__e" d="M0 0.073L44.275 0.073 44.275 6 0 6z" />
      <path id="prefix__g" d="M0 42.638L44.275 42.638 44.275 0 0 0z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(0 28.638)">
        <mask id="prefix__b" fill="#fff">
          <use xlink:href="#prefix__a" />
        </mask>
        <path
          fill="currentcolor"
          stroke-width=".5"
          d="M26.686 2.197c2.235.029 3.846 1.026 5.406 1.991 1.645 1.02 3.166 1.963 5.285 2.017 1.012.017 1.998-.164 3.034-.565 1.1-.414 2.242-1.075 3.49-2.018a.942.942 0 00.181-1.321.946.946 0 00-1.322-.183c-1.091.831-2.08 1.41-3.02 1.772-.82.314-1.58.46-2.33.436-1.587-.039-2.895-.85-4.281-1.709C31.334 1.504 29.498.364 26.715.318c-2.753-.042-4.666 1.101-6.5 2.211-1.492.901-2.869 1.737-4.515 1.692-.693-.021-1.374-.177-2.086-.477-.828-.347-1.678-.872-2.598-1.605l-.05-.036C9.186.905 7.289.28 5.456.28 3.595.28 1.877.903.351 2.134a.914.914 0 00-.346.628.943.943 0 001.524.835l.002.001c1.175-.948 2.492-1.429 3.913-1.429 1.463 0 2.98.503 4.387 1.455 1.072.85 2.064 1.456 3.048 1.865.945.393 1.851.592 2.773.611 2.207.053 3.812-.922 5.516-1.958 1.578-.957 3.21-1.946 5.39-1.946l.127.001"
          mask="url(#prefix__b)"
        />
      </g>
      <g transform="translate(0 33.638)">
        <mask id="prefix__d" fill="#fff">
          <use xlink:href="#prefix__c" />
        </mask>
        <path
          fill="currentcolor"
          stroke-width=".5"
          d="M26.686 2.183c2.235.029 3.846 1.026 5.406 1.99 1.645 1.02 3.166 1.963 5.285 2.018 1.012.017 1.998-.164 3.034-.565 1.1-.415 2.242-1.075 3.49-2.018a.944.944 0 00.181-1.321.946.946 0 00-1.322-.183c-1.091.83-2.08 1.41-3.02 1.77-.82.316-1.58.46-2.33.438-1.587-.04-2.895-.851-4.281-1.71C31.334 1.49 29.498.35 26.715.305c-2.753-.042-4.666 1.1-6.5 2.21-1.492.9-2.869 1.737-4.515 1.693-.693-.021-1.374-.177-2.086-.477-.828-.348-1.678-.873-2.598-1.605l-.05-.037C9.186.89 7.289.266 5.456.266 3.595.266 1.877.889.351 2.12a.914.914 0 00-.346.628.94.94 0 001.525.835h0c1.177-.948 2.493-1.43 3.914-1.43 1.463 0 2.98.505 4.387 1.457 1.072.849 2.064 1.457 3.048 1.865.945.393 1.851.592 2.773.61 2.207.053 3.812-.922 5.516-1.957 1.578-.957 3.21-1.946 5.39-1.946h.127"
          mask="url(#prefix__d)"
        />
      </g>
      <g transform="translate(0 38.638)">
        <mask id="prefix__f" fill="#fff">
          <use xlink:href="#prefix__e" />
        </mask>
        <path
          fill="currentcolor"
          stroke-width=".5"
          d="M26.686 1.99c2.235.03 3.846 1.027 5.406 1.992 1.645 1.02 3.166 1.962 5.285 2.018 1.012.016 1.998-.165 3.034-.566 1.1-.415 2.242-1.075 3.49-2.018a.942.942 0 00.181-1.321.944.944 0 00-1.322-.183c-1.091.83-2.08 1.41-3.02 1.77-.82.316-1.58.462-2.33.438-1.587-.04-2.895-.85-4.281-1.71C31.334 1.297 29.498.159 26.715.111c-2.753-.041-4.666 1.101-6.5 2.211-1.492.9-2.869 1.737-4.515 1.692-.693-.02-1.374-.177-2.086-.477-.828-.346-1.678-.871-2.598-1.604l-.05-.038C9.186.698 7.289.073 5.456.073c-1.86 0-3.578.624-5.104 1.855a.916.916 0 00-.346.627.94.94 0 001.525.835v.002c1.177-.95 2.493-1.43 3.914-1.43 1.463 0 2.98.504 4.387 1.456 1.072.85 2.064 1.457 3.048 1.865.945.393 1.851.591 2.773.61 2.207.053 3.812-.921 5.516-1.957 1.578-.958 3.21-1.946 5.39-1.946h.127"
          mask="url(#prefix__f)"
        />
      </g>
      <mask id="prefix__h" fill="#fff">
        <use xlink:href="#prefix__g" />
      </mask>
      <path
        fill="currentcolor"
        stroke-width=".5"
        d="M17.441 20.548h9.983v-4.916h-9.983v4.916zm10.929 5.573a.945.945 0 00.944-.945V5.134a.996.996 0 00-.018-.183c-.068-1.3-.562-2.496-1.395-3.4-.917-1-2.146-1.55-3.464-1.55-1.299 0-2.529.55-3.464 1.552a5.347 5.347 0 00-1.402 3.638.945.945 0 001.88.132l-.011-.132c0-.903.319-1.743.897-2.364.562-.605 1.305-.938 2.091-.938.787 0 1.53.333 2.091.938.578.621.897 1.461.897 2.364l.008.077.001 8.475h-9.984V5.134c-.028-1.372-.525-2.63-1.403-3.583-.916-1-2.146-1.55-3.463-1.55-1.299 0-2.53.55-3.464 1.552a5.34 5.34 0 00-1.403 3.638.945.945 0 001.879.131l-.01-.13c0-.904.319-1.744.898-2.365.561-.605 1.304-.938 2.09-.938.787 0 1.53.333 2.091.938.576.62.895 1.456.896 2.357v19.992a.945.945 0 001.889 0v-2.739h9.984v2.74c0 .52.424.944.945.944h0z"
        mask="url(#prefix__h)"
      />
    </g>
  </svg>
</template>
