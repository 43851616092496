<script>
import dayjs from 'dayjs'
import { DatePicker } from 'v-calendar'

export default {

  components: {
    DatePicker,
  },

  props: {
    devise: { type: Object },
    origin: {
      type: String,
      default: 'http://AtlantisBahamas.com/',
    },
    prefillCheckIn: {
      type: String,
    },
    prefillCheckOut: {
      type: String,
    },
  },
  data() {
    return {
      errors: [],
      date: dayjs().add(1, 'day').toDate(),
      checkin: dayjs().add(1, 'day').toDate(),
      checkout: dayjs().add(1, 'day').toDate(),
      tracking: {
        nav: null,
        discount_code: null,
        discount_code_air: null,
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        specialid: null,
        special_id_air: null,
      },
    }
  },

  computed: {
    formattedCheckinDate() {
      if (this.devise.fields.mode.value === 'duo')
        return dayjs(this.checkin).format('MM/DD/YY')

      return dayjs(this.date).format('MM/DD/YY')
    },
    formattedCheckoutDate() {
      if (this.devise.fields.mode.value === 'duo')
        return dayjs(this.checkout).format('MM/DD/YY')

      return dayjs(this.date).format('MM/DD/YY')
    },
  },

  watch: {
    checkin(newValue) {
      this.checkout = newValue
    },
  },

  mounted() {
    this.retrieveTracking()
    this.prefillCheckInForm()
  },

  methods: {
    retrieveTracking() {
      $voixFetch(`/api/devise/pages/${this.devise.page.id}/booking-engine-parameters`)
        .then((response) => {
          this.tracking = Object.assign({}, response)

          if (this.devise.page.site_id === 1) {
            if (window.dataLayer)
              window.dataLayer.push({ CoBrand: this.tracking.nav })
          }
        })
    },
    prefillCheckInForm() {
      if (this.prefillCheckIn !== '')
        this.checkin = new Date(this.prefillCheckIn)

      if (this.prefillCheckOut !== '')
        this.checkout = new Date(this.prefillCheckOut)
    },

    submit() {
      const self = this
      this.validateForm()
        .then(() => {
          const from = useCookie('booking-submitted-from')
          from.value = window.location.href
          self.$refs.bookingform.submit()
        })
        .catch((errors) => {
          if (typeof errors === 'object')
            errors.map(error => this.showError(error))
        })
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        const errors = []
        const start = dayjs(this.checkin)
        const end = dayjs(this.checkout)

        if (this.tracking.book_limit_max_days === null) {
          if (end.diff(start, 'days') > 30)
            errors.push('Please call 1-800 Atlantis for bookings greater than 30 days.')
        }
        else if (end.diff(start, 'days') > this.tracking.book_limit_max_days) {
          errors.push(
            `Bookings over ${this.tracking.book_limit_max_days} nights not permitted. Please adjust your search to find results.`,
          )
        }

        if (errors.length > 0)
          reject(errors)
        else resolve()
      })
    },
    gaFormat(date) {
      const year = date.getFullYear()
      let month = (1 + date.getMonth()).toString()
      month = month.length > 1 ? month : `0${month}`
      let day = date.getDate().toString()
      day = day.length > 1 ? day : `0${day}`

      return `${month}/${day}/${year}`
    },
    showError(error) {
      this.errors.push(error)
    },
  },
}
</script>

<template>
  <div id="activities-search-activities" class="small-container text-align-center">
    <h1 class="uppercase mb-4" v-html="devise.fields.title.value" />
    <div class="relative grid grid-cols-1 lg:grid-cols-2 bg-orange-100">
      <div class="overflow-hidden">
        <img
          v-if="!devise.fields.image.enabled"
          class="w-full h-full object-cover"
          src="/imgs/heros/things-to-do.jpg"
          alt=""
        >
        <VoixMedia v-else :field="devise.fields.image" class="w-full h-full object-cover" />
      </div>
      <div class="flex justify-center items-center">
        <div
          class="flex flex-col items-center justify-between lg:p-8"
          :class="{ 'flex-col-reverse': devise.fields.mode.value === 'duo' }"
        >
          <div v-if="devise.fields.mode.value === 'inline'" class="pb-6 md:pb-0">
            <DatePicker
              v-model="date"
              :is-inline="true"
              class="calendar-picker"
            />
          </div>
          <div class="w-full">
            <div v-if="devise.fields.mode.value !== 'inline'" class="flex flex-col mb-6">
              <fieldset class="mb-8 w-full">
                <label>Check In</label>
                <DatePicker
                  v-model="checkin"
                  popover-direction="above"
                  class="calendar-picker"
                >
                  <template #default="{ inputValue }">
                    <div class="flex">
                      <input
                        :value="inputValue"
                        class="font-sans2 text-xs w-full"
                        readonly
                        type="text"
                      >
                    </div>
                  </template>
                </DatePicker>
              </fieldset>
              <fieldset class="w-full">
                <label>Check Out</label>
                <DatePicker
                  v-model="checkout"
                  popover-direction="above"
                  class="calendar-picker"
                >
                  <template #default="{ inputValue }">
                    <div class="flex">
                      <input
                        :value="inputValue"
                        class="font-sans2 text-xs"
                        readonly
                        type="text"
                      >
                    </div>
                  </template>
                </DatePicker>
              </fieldset>
            </div>
            <form
              ref="bookingform"
              action="https://secure.atlantisbahamas.com/travel/arc_waiting.cfm"
              method="GET"
              class="mb-6"
              target="_top"
            >
              <input type="hidden" name="nav" :value="tracking.nav">
              <input type="hidden" name="origin" :value="origin">
              <input type="hidden" name="activity1" value="true">
              <input type="hidden" name="area2" value="|airport%3ANAS">
              <input type="hidden" name="displayarea2" value="NAS">
              <input type="hidden" name="date1" :value="formattedCheckinDate">
              <input type="hidden" name="date2" :value="formattedCheckoutDate">

              <div
                :class="{
                  'flex flex-col items-center mt-4': devise.fields.mode.value === 'inline',
                }"
              >
                <ul v-if="errors.length > 0" class="font-sans mb-4">
                  <li
                    v-for="(error, key) in errors"
                    :key="key"
                    class="p-4 mb-2 bg-glueblue-700 text-white rounded"
                  >
                    {{ error }}
                  </li>
                </ul>

                <button
                  class="btn glue-btn btn-sm bg-glueblue-700 text-white"
                  :class="{}"
                  @click.prevent="submit"
                >
                  {{ devise.fields.buttonLabel.value }}
                </button>
              </div>
            </form>
            <VoixWysiwyg class="mb-6 leading-loose" :field="devise.fields.copy" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#activities-search-activities {
  .calendar-picker {
    @apply p-8;
  }

  .vc-day {
    @apply p-2
  }

  .vc-highlight {
    @apply rounded bg-glueblue-300 ;
  }

  .vc-highlight-content-solid {
    @apply text-glueblue-600;
  }
}
</style>
