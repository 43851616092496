<script>
export default {
  name: 'MarineIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 50 56"
  >
    <defs>
      <path
        id="marine-icon-prefix-a"
        d="M0 0.695L50.024 0.695 50.024 56 0 56z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(0 -1) translate(0 .305)">
        <mask id="marine-icon-prefix-b" fill="currentcolor">
          <use xlink:href="#marine-icon-prefix-a" />
        </mask>
        <path
          fill="currentcolor"
          d="M48.415 21.205c-2.753-.514-5.63-.347-8.56.497-2.603.747-5.256 2.026-7.885 3.8-3.482 2.35-5.955 4.9-6.957 6.007-1-1.107-3.473-3.655-6.957-6.007-2.629-1.774-5.282-3.053-7.884-3.8-1.824-.525-3.627-.788-5.392-.788-1.072 0-2.13.098-3.17.292C3.87 18 7.23 15.354 11.6 13.332c6.747-3.122 7.601-6.58 8.355-9.631.146-.59.283-1.146.461-1.704.046-.143.16-.165.226-.166.076 0 .177.028.221.159 1.175 3.416 2.571 5.148 4.15 5.148 1.578 0 2.973-1.732 4.148-5.148.05-.145.177-.16.221-.16.077 0 .18.02.227.167.176.552.313 1.107.461 1.704.754 3.05 1.61 6.509 8.357 9.63 4.368 2.022 7.727 4.67 9.988 7.874m1.493.19c-2.372-3.753-6.075-6.812-11.006-9.094-6.252-2.892-7.004-5.933-7.73-8.874-.145-.582-.294-1.184-.483-1.776-.183-.574-.69-.95-1.309-.956-.594 0-1.102.363-1.296.925C27.127 4.404 26.007 6 25.01 6c-.996 0-2.116-1.596-3.074-4.38-.193-.562-.7-.925-1.31-.925-.604.007-1.11.382-1.295.956-.187.589-.336 1.19-.48 1.777-.728 2.94-1.48 5.98-7.732 8.873-4.932 2.28-8.635 5.34-11.006 9.095-.16.259-.15.586.028.832.178.248.484.362.781.288 2.84-.701 5.842-.61 8.924.273 2.485.713 5.027 1.938 7.555 3.643 4.275 2.88 7.055 6.159 7.167 6.292.042.055 1.04 1.362 1.338 3.392.407 2.783-.665 5.488-3.188 8.04-2.324 2.352-3.446 4.723-3.337 7.049.137 2.9 2.135 4.592 2.22 4.662.102.087.23.133.363.133l.05-.003c.151-.012.29-.084.387-.201.2-.24.17-.598-.07-.8-.019-.015-1.703-1.456-1.814-3.845-.094-1.995.918-4.08 3.01-6.196 3.471-3.512 3.821-6.835 3.503-9.004-.245-1.67-.894-2.903-1.3-3.54.801-.893 3.333-3.577 6.874-5.967 2.531-1.71 5.075-2.937 7.562-3.652 3.085-.884 6.092-.978 8.936-.276.294.075.602-.04.781-.288.178-.247.188-.574.025-.833"
          mask="url(#marine-icon-prefix-b)"
        />
      </g>
      <path
        fill="currentcolor"
        d="M29.676 9.852c0 .135-.11.246-.246.246-.135 0-.245-.11-.245-.246 0-.135.11-.245.245-.245.136 0 .246.11.246.245M29.43 8.71c-.63 0-1.142.513-1.142 1.143s.513 1.143 1.142 1.143c.63 0 1.143-.513 1.143-1.143S30.06 8.71 29.43 8.71M20.461 9.852c0-.135.11-.245.246-.245.135 0 .245.11.245.245s-.11.246-.245.246c-.136 0-.246-.11-.246-.246m.246 1.143c.63 0 1.143-.513 1.143-1.143s-.513-1.143-1.143-1.143-1.143.513-1.143 1.143.512 1.143 1.143 1.143M14.276 21.097c-.224-.099-.495.007-.592.227-.05.11-.053.231-.01.344.043.111.127.2.236.248 5.387 2.414 8.7 5.93 8.838 6.077.082.09.193.14.312.145h.018c.113 0 .22-.042.303-.119.09-.082.14-.193.145-.313.004-.119-.038-.234-.119-.32-.035-.039-3.508-3.768-9.13-6.289M12.607 20.443c-.166-.065-1.026-.403-1.706-.607-.238-.072-.49.071-.56.301-.07.237.065.488.3.558.7.21 1.628.58 1.638.583.055.022.11.033.165.033.179 0 .348-.108.418-.283.09-.23-.021-.49-.255-.585"
        transform="translate(0 -1)"
      />
    </g>
  </svg>
</template>
