import anime from 'animejs/lib/anime.es.js'

export default {
  data() {
    return {
      slideUpAnimationState: 'idle',
      opacityAnimationState: 'idle',
    }
  },
  methods: {
    animationValidForMobileAndTablet() {
      return this.breakpoint !== 'mobile' && this.breakpoint !== 'tablet'
    },
    animateSlideUp(e) {
      if (
        e.type === 'enter'
        && e.percentTop > 0.5
        && this.slideUpAnimationState === 'idle'
      ) {
        this.slideUpAnimationState = 'enter'
        anime.set(e.target.element, { translateY: 50, opacity: 0 })

        anime({
          targets: e.target.element,
          opacity: 1,
          duration: 3000,
          translateY: 0,
          easing: 'easeOutExpo',
          complete: () => {
            this.slideUpAnimationState = 'idle'
          },
        })
      }
    },
    smallSpecialCards(e) {
      if (this.animationValidForMobileAndTablet()) {
        if (e.type === 'enter') {
          anime({
            targets: '.view-in',
            opacity: 1,
            duration: 1000,
            translateY: 0,
            easing: 'easeOutExpo',
            delay: anime.stagger(100),
          })
        }
      }
    },
    animateOpacity(e) {
      if (
        e.type === 'enter'
        && e.percentTop > 0.5
        && this.opacityAnimationState === 'idle'
      ) {
        this.opacityAnimationState = 'enter'
        anime.set(e.target.element, { opacity: 0 })

        anime({
          targets: e.target.element,
          opacity: 1,
          duration: 3000,
          easing: 'easeOutExpo',
          complete: () => {
            this.opacityAnimationState = 'idle'
          },
        })
      }
    },
    animateOpacityFast(e) {
      if (
        e.type === 'enter'
        && e.percentTop > 0.5
        && this.opacityAnimationState === 'idle'
      ) {
        this.opacityAnimationState = 'enter'
        anime.set(e.target.element, { opacity: 0 })

        anime({
          targets: e.target.element,
          opacity: 1,
          duration: 1000,
          easing: 'easeOutExpo',
          complete: () => {
            this.opacityAnimationState = 'idle'
          },
        })
      }
    },
    animateZoom(e) {
      if (this.animationValidForMobileAndTablet()) {
        if (e.type === 'mouseenter') {
          anime({
            targets: e.target,
            scale: 1.02,
            translateX: '-=15px',
            duration: 400,
            easing: 'easeOutExpo',
          })
        }
        else {
          anime({
            targets: e.target,
            scale: 1,
            translateX: '0px',
            translateY: '0px',
            duration: 500,
            easing: 'easeOutExpo',
          })
        }
      }
    },
    animateCenterZoom(e) {
      if (this.animationValidForMobileAndTablet()) {
        if (e.type === 'mouseenter') {
          anime({
            targets: e.target,
            scale: 1.03,
            duration: 400,
            easing: 'easeOutExpo',
          })
        }
        else {
          anime({
            targets: e.target,
            scale: 1,
            duration: 500,
            easing: 'easeOutExpo',
          })
        }
      }
    },
  },
}
