<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

export default {
  name: 'ThreeImageGridToMobileSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: { devise: { type: Object, default: () => ({}) } },

  mounted() {
    this.updateOnInit()
  },

  methods: {
    updateOnInit() {
      setTimeout(() => {
        if (this.$refs.mySwiper?.$el?.swiper) {
          this.$refs.mySwiper.$el.swiper.update()
          this.swiper = this.$refs.mySwiper.$el.swiper
        }
        else {
          this.updateOnInit()
        }
      }, 1000)
    },

    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    goToSlide(index) {
      this.swiper.slideTo(index)
    },

    goTo(link) {
      if (link.enabled && link.url) {
        window.location.href = link.url
      }
    },
  },
}
</script>

<template>
  <div>
    <div class="hidden lg:grid grid-cols-2 gap-2">
      <div
        class="relative h-[500px]"
        :class="{ 'cursor-pointer': devise.fields.link1.enabled }"
        @click="goTo(devise.fields.link1)"
      >
        <VoixMedia :field="devise.fields.image1" class="w-full h-full object-cover" />

        <div
          class="absolute inset-0 bg-gradient-to-t from-black/50 via-black/0 to-transparent"
        />
        <div
          class="absolute z-10 bottom-0 left-0 m-8 uppercase font-sans font-light leading-tight text-2xl tracking-widest text-gray-100"
        >
          {{ devise.fields.title1.value }}
        </div>
      </div>
      <div
        class="relative h-[500px]"
        :class="{ 'cursor-pointer': devise.fields.link2.enabled }"
        @click="goTo(devise.fields.link2)"
      >
        <VoixMedia :field="devise.fields.image2" class="w-full h-full object-cover" />

        <div
          class="absolute inset-0 bg-gradient-to-t from-black/50 via-black/0 to-transparent"
        />
        <div
          class="absolute z-10 bottom-0 left-0 m-8 uppercase font-sans font-light leading-tight text-2xl tracking-widest text-gray-100"
        >
          {{ devise.fields.title2.value }}
        </div>
      </div>
      <div
        class="relative h-[500px] col-span-2"
        :class="{ 'cursor-pointer': devise.fields.link3.enabled }"
        @click="goTo(devise.fields.link3)"
      >
        <VoixMedia :field="devise.fields.image3" class="w-full h-full object-cover" />

        <div
          class="absolute inset-0 bg-gradient-to-t from-black/50 via-black/0 to-transparent"
        />
        <div
          class="absolute z-10 bottom-0 left-0 m-8 uppercase font-sans font-light leading-tight text-2xl tracking-widest text-gray-100"
        >
          {{ devise.fields.title3.value }}
        </div>
      </div>
    </div>

    <div class="lg:hidden">
      <Swiper
        ref="mySwiper"
        slides-per-view="auto"
        :space-between="10"
        :loop="false"
        :centered-slides="true"
      >
        <SwiperSlide class="relative h-[500px] w-[300px]">
          <div class="relative h-[500px] w-[300px]">
            <VoixMedia :field="devise.fields.image1" class="w-full h-full object-cover" />

            <div
              class="absolute inset-0 bg-gradient-to-t from-black/50 via-black/0 to-transparent"
            />
            <div
              class="absolute z-10 bottom-0 left-0 m-8 uppercase font-sans font-light leading-tight text-2xl tracking-widest text-gray-100"
            >
              {{ devise.fields.title1.value }}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide class="relative h-[500px] w-[300px]">
          <div class="relative h-[500px] w-[300px]">
            <VoixMedia :field="devise.fields.image2" class="w-full h-full object-cover" />

            <div
              class="absolute inset-0 bg-gradient-to-t from-black/50 via-black/0 to-transparent"
            />
            <div
              class="absolute z-10 bottom-0 left-0 m-8 uppercase font-sans font-light leading-tight text-2xl tracking-widest text-gray-100"
            >
              {{ devise.fields.title2.value }}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide class="relative h-[500px] w-[300px]">
          <div class="relative h-[500px] w-[300px]">
            <VoixMedia :field="devise.fields.image3" class="w-full h-full object-cover" />

            <div
              class="absolute inset-0 bg-gradient-to-t from-black/50 via-black/0 to-transparent"
            />
            <div
              class="absolute z-10 bottom-0 left-0 m-8 uppercase font-sans font-light leading-tight text-2xl tracking-widest text-gray-100"
            >
              {{ devise.fields.title3.value }}
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto;
  height: auto;
  display: flex;
}
</style>

<style>
.swiper-wrapper {
  @apply items-stretch;
}
</style>
