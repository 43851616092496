<script>
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  components: {
    Swiper,
    SwiperSlide,

  },

  props: {
    devise: { type: Object },
    autoplay: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      swiper: null,
    }
  },

  computed: {
    currentSlide() {
      if (this.swiper)
        return this.swiper.realIndex

      return 0
    },
  },

  mounted() {
    this.updateOnInit()
  },

  methods: {
    updateOnInit() {
      setTimeout(() => {
        if (this.$refs.mySwiper?.$el?.swiper) {
          this.$refs.mySwiper.$el.swiper.update()
          this.swiper = this.$refs.mySwiper.$el.swiper
        }
        else {
          this.updateOnInit()
        }
      }, 1000)
    },

    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    goToSlide(index) {
      this.swiper.slideTo(index)
    },
  },
}
</script>

<template>
  <div class="relative lg:grid lg:grid-cols-12">
    <div
      class="row-start-1 col-span-6 flex flex-col justify-center"
      :class="{ 'col-start-7': devise.fields.mirror.value }"
    >
      <div
        class="flex flex-col px-8 lg:px-24"
        :class="{
          'items-end': !devise.fields.mirror.value,
          'items-start': devise.fields.mirror.value,
        }"
      >
        <div class="max-w-[400px]">
          <div class="w-full headline-2">
            {{ devise.fields.title.value }}
          </div>
          <div class="body-lg" v-html="devise.fields.description.value" />
          <a
            v-if="devise.fields.link.enabled"
            class="inline-block mt-8 text-center border border-zinc-500 text-zinc-700 font-bold px-8 py-5 leading-snug uppercase focus:outline-none font-sans text-2xs min-w-[180px]"
            :href="devise.fields.link.value?.href"
            :target="devise.fields.link.value.target"
          >{{ devise.fields.link.value.text }}</a>
        </div>
      </div>
    </div>
    <div
      class="row-start-1 relative col-span-12 md:col-span-6 mt-8 lg:mt-0"
      :class="{ 'col-start-1': devise.fields.mirror.value }"
    >
      <Swiper
        ref="mySwiper"
        :slides-per-view="1"
        :loop="devise.slice.elements.length > 1"
        :centered-slides="true"
        class="clipping-slider border-white"
      >
        <SwiperSlide v-for="(slice, key) in devise.slice.elements" :key="key">
          <VoixSlice :slice="slice" :slice-index="key" class="w-full" />
        </SwiperSlide>
      </Swiper>

      <div
        v-if="devise.slice.elements.length > 1"
        class="absolute z-50 inset-0 pointer-events-none flex justify-between items-center px-8"
      >
        <button class="slider-button border-gray-500" @click="prev">
          <svg
            class="w-7 h-7"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <button class="slider-button border-gray-500 next" @click="next">
          <svg
            class="w-7 h-7 transform rotate-180"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
