<script>
export default {
  name: 'AnniversaryIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 85" width="110" height="85">
    <path id="_x32_5_00000017515005437670449480000006631290529595604401_" style="fill: #ffffff;stroke: #ffffff" class="s0" d="m82.9 35.4c0 4.1-1.4 7.7-4.2 10.8-2.8 3.2-6.3 4.8-10.4 4.8-3.9 0-7.2-1.4-9.9-4.2q-1-0.9-1.8-2.1h-25.7v-2.6l0.4-0.2c0.8-0.7 2.6-1.9 5.4-3.7 2.7-1.8 5-3.5 6.9-4.9 1.8-1.4 3.9-3.1 6-5.1q3.3-2.9 5-6 1.6-2.9 1.6-5.8c0-3.6-1.1-6.5-3.5-9-2.4-2.4-5.2-3.6-8.8-3.6-2.6 0-5.1 0.9-7.3 2.7-2.2 1.8-3.3 3.8-3.3 6.3 0 1.6 0.4 3 1.2 4.2 0.8 1.2 1.9 2 3.1 2.4l0.9 0.3-1.1 3-0.8-0.3q-2.8-0.9-4.6-3.6-1.8-2.7-1.8-6c0-3.4 1.4-6.3 4.3-8.7 2.9-2.2 6.1-3.4 9.5-3.4 4.5 0 8.2 1.5 11 4.5 2.9 3 4.4 6.8 4.4 11.2q0 3-1.5 6-1.5 3-3.3 5.3c-1.3 1.4-3 3.1-5.2 5q-3.3 2.7-5.3 4.1-2.1 1.5-5.6 3.9l-1.3 0.9h17.6q-0.9-2.2-1.1-4.7l-0.1-0.9 3.2-0.3v0.9c0.3 3.1 1.6 5.7 3.8 8 2.1 2.2 4.7 3.3 7.7 3.3 3.3 0 5.9-1.3 8.1-3.8 2.2-2.5 3.3-5.4 3.3-8.7 0-3.4-1.1-6.3-3.3-8.8-2.2-2.5-4.8-3.8-8.1-3.8q-1.4 0-3.5 0.6c-1.5 0.4-2.1 0.9-2.3 1.2l-0.4 0.7-2.7-1.3 7.5-16.3h14.7v3.1h-12.7l-4.4 9.4q1.9-0.5 3.8-0.5c4.1 0 7.6 1.6 10.4 4.7 2.8 3.1 4.2 6.8 4.2 11z" />
    <g id="Layer">
      <path id="Layer" style="fill: #ffffff" class="s1" d="m72.3 66.4q-0.1 0-0.1 0h0.1c0.2 0 0.8-0.1 0.8-0.9h1.5v-0.4h0.6c0 0 0.3-0.6 1.4-0.6 1 0 1.4 0.6 1.4 0.6h0.6v0.4h1.5c0 0.8 0.6 0.9 0.8 0.9h0.1q-0.1 0-0.1 0h0.7v-1.8h5.7v5.8h16.6v-2.1h5.6v12.2 4h-2.5v-4-11.3h-0.6v11.3 4h-2.6v-4-8.8h-0.6v8.8 4h-2.5v-4-8.8h-0.7v8.8 4h-2.5v-4-8.8h-0.7v12.8h-2.5v-12.8h-0.7v12.8h-2.5v-12.8h-0.6v12.8h-2.6v-12.8h-0.6v12.8h-2.5v-18.7h-0.7v18.7h-2.5v-16.5h-0.6v16.5h-2.5v-16.5h-0.7v16.5h-2.5v-16.5h-0.7v16.5h-2.5v-16.5h-0.6v16.5h-2.5v-18.7h-0.7v18.7h-2.2v-11.9q0 0.1 0 0.1v-5.4-2.6h5.1v1.7z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m71.3 64.1h-5.3l-0.5-0.6 0.5-0.6h5.3l0.4 0.6z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m65.4 59.5c1.7 0 3.2-1.4 3.2-3.2 0 1.8 1.6 3.2 3.3 3.2-1.2 0-1.1 0.7-1.1 1.1 0 0.5 0 1.8 0 1.8h-0.4-2.8-1.2c0 0 0-1.3 0-1.8 0-0.4 0.1-1.1-1-1.1z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m81.8 62.9h5.3l0.5 0.6-0.5 0.6h-5.3l-0.5-0.6z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m103.4 63.2c1.7 0 3.3-1.4 3.3-3.2 0 1.8 1.5 3.2 3.2 3.2-1.1 0-1.1 0.7-1.1 1.1 0 0.4 0 1.8 0 1.8h-1.6-1.1-1.6c0 0 0-1.4 0-1.8 0-0.4 0.1-1.1-1.1-1.1z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m44.2 52.9c1.5 0 2.7-1.4 2.7-3.2 0 1.8 1.3 3.2 2.7 3.2-0.6 0-0.8 0.3-0.9 0.7v2.2h-2.8-0.8c0 0 0-1.4 0-1.8 0-0.4 0.1-1.1-0.9-1.1z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m104 66.6h5.3l0.5 0.6-0.5 0.6h-5.3l-0.5-0.6z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m81.2 59.5c1.7 0 3.3-1.4 3.3-3.2 0 1.8 1.5 3.2 3.2 3.2-1.1 0-1.1 0.7-1.1 1.1 0 0.5 0 1.8 0 1.8h-0.9-2.8-0.6c0 0 0-1.3 0-1.8 0-0.4 0-1.1-1.1-1.1z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m29.1 52.9c1.5 0 2.7-1.4 2.7-3.2 0 1.8 1.3 3.2 2.7 3.2-0.9 0-0.9 0.7-0.9 1.1 0 0.4 0 1.8 0 1.8h-0.4-2.8-0.4c0 0 0-1.4 0-1.8 0-0.4 0.1-1.1-0.9-1.1z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m11 60.6c1.7 0 3.2-1.4 3.2-3.2 0 1.8 1.4 3.2 3.1 3.2-1.1 0-1 0.7-1 1.1 0 0.4 0 1.8 0 1.8h-0.7-2.8-0.7c0 0 0-1.4 0-1.8 0-0.4 0-1.1-1.1-1.1z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m0.7 72.3l-0.5-0.6 0.5-0.7h5.1l0.4 0.7-0.4 0.6z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m11.6 65.2l-0.4-0.6 0.4-0.6h5.1l0.4 0.6-0.4 0.6z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m0.1 67.7c1.6 0 3.1-1.5 3.1-3.3 0 1.8 1.5 3.3 3.2 3.3-1.2 0-1.1 0.7-1.1 1.1 0 0.4 0 1.7 0 1.7h-0.8-0.7-1.1-1-0.6c0 0 0-1.3 0-1.7 0-0.4 0.1-1.2-1-1.1z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m34.2 57.5h-5.1v-1.2h5.1l0.5 0.6z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m44.3 57.5l-0.4-0.6 0.4-0.6h5.1l0.5 0.6-0.5 0.6z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m16.8 67.8h1.2c0.3 0 0.9-0.3 0.9-1.1h1.8v-0.5h0.6c0 0 0.5-0.8 1.7-0.8 1.2 0 1.6 0.8 1.6 0.8h0.7v0.5h1.7c0 0.8 0.6 1 0.9 1.1h1.3v-9.8h5v1.8h0.9q0 0 0 0c0.3 0 0.9-0.2 0.9-0.9h1.4v-0.5h0.6c0 0 0.3-0.5 1.3-0.5 1 0 1.3 0.5 1.3 0.5h0.6v0.5h1.4c0 0.7 0.6 0.9 0.9 0.9q0 0 0 0h0.6v-1.8h5.5v9.3 5.4q0 0 0-0.1v11.9h-2.4v-25.3h-0.6v25.3h-2.4v-23.1h-0.7v23.1h-2.4v-23.1h-0.6v23.1h-2.4v-23.1h-0.6v23.1h-2.5v-23.1h-0.6v23.1h-2.4v-25.3h-0.6v25.3h-2.3l0.1-15.4h-0.8v15.4h-2.3v-15.4h-0.6v15.4h-2.3v-15.4h-0.6v15.4h-2.3v-15.4h-0.6v15.4h-2.3v-15.4h-0.7v11.4 4h-2.2v-4-13.9h-0.7v13.9 4h-2.1v-4-4.5h-0.6v4.5 4h-2.2v-4-4.5h-0.6v4.5 4h-2.1v-4-4.5h-0.6v4.5 4h-2.2v-4-6.7h-0.6v6.7 4h-2.2v-4-7.6h5.1v2h5.7v-9.1h5.4z" />
      <path id="Layer" style="fill: #ffffff" class="s1" d="m49.6 72.6v-5.3h2 0.2c0.4-0.1 1.1-0.4 1.1-1.4h2.1v-0.6h0.8c0 0 0.5-0.9 2-0.9 1.5 0 2 0.9 2 0.9h0.8v0.6h2.1c0 1 0.7 1.3 1.1 1.4h0.2 2v5.3c-0.2-0.2-2.2-1.8-8.5-1.8-6.3 0-7.7 1.6-7.9 1.8z" />
    </g>
  </svg>
</template>
