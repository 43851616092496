<script setup lang="ts">
defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  name: {
    label: 'Filterable Wrapper',
    group: 'Activities',
    layouts: ['Atlantisbahamas', 'AtlantisMobileApp'],
  },
  tags: ['Activities', 'Index'],
  preview: 'SlicesGlueExperiencesFilterableWrapper.jpg',
  slots: [{ name: 'default' }],
  description: 'Used with Tabbed Filters in experiences.',
  fields: {
    type: { label: 'Type', type: 'select', options: {
      'tab-1': 'Tab 1',
      'tab-2': 'Tab 2',
      'tab-3': 'Tab 3',
    } },
  },
  templates: [{
    label: 'Filterable Wrapper',
    fields: {
      type: { value: 'tab-1' },
    },
  }],
})

const filterMode = inject('filterMode')
</script>

<template>
  <div v-if="filterMode() === fields.type.value">
    <slot />
  </div>
</template>
