<script setup>
import { useStickyScroll } from '@/composables/useStickyScroll'
import { onMounted, ref } from 'vue'

defineProps({
  eventDate: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['nextDay'])

const parent = ref(null)
const child = ref(null)
const { styles } = useStickyScroll(child, parent)
</script>

<template>
  <div ref="parent" class="bg-white">
    <div ref="child" class="flex justify-between items-center bg-white text-glueblue-700 text-xl font-bold border-b border-slate-200 p-8 lg:p-16" :style="styles">
      <date-formatter :date="eventDate" format="dddd, MMMM D, YYYY" />

      <button class="text-xs uppercase px-4 py-2 border bg-glueblue-600 text-white" @click="emit('nextDay', eventDate)">
        Next Day
      </button>
    </div>
    <slot />
  </div>
</template>
