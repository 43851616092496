<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'
import { inView } from 'motion'

defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

const showSubnav = ref(false)
const canSee = ref(true)
const menu: Ref<HTMLElement | null> = ref(null)

const { width: windowWidth } = useWindowSize()

onMounted(() => {
  if (menu.value) {
    inView(menu.value, () => {
      showSubnav.value = false
      canSee.value = true

      return (leaveInfo) => {
        canSee.value = false
        showSubnav.value = leaveInfo.boundingClientRect.top < 300
      }
    })
  }
})

defineSlice({
  name: { label: 'Navigation Gallery', group: 'Galleries', layouts: ['Atlantisbahamas', 'AtlantisMobileApp'] },
  slots: [{ name: 'default' }],
  description: 'Gallery with a navigation element',
  fields: {
    isRight: { label: 'Align right', type: 'checkbox' },
    subNavigation: { label: 'Enable Subnavigation', type: 'checkbox', default: true },
    subNavigationTitle: { label: 'Subnavigation Title', type: 'text', default: '"Go To"' },
    topLeft: { label: 'Top Left Image', type: 'media', breakpoints: { lg: { width: 665, height: 260 }, sm: { width: 200, height: 200 } } },
    topRight: { label: 'Top Right Text', type: 'wysiwyg' },
    middleLeft: { label: 'Middle Left Text', type: 'wysiwyg' },
    middleRight1: { label: 'Middle Right Image 1', type: 'media', breakpoints: { lg: { width: 300, height: 300 }, sm: { width: 200, height: 200 } } },
    middleRight2: { label: 'Middle Right Image 2', type: 'media', breakpoints: { lg: { width: 400, height: 300 }, sm: { width: 350, height: 200 } } },
    bottomLeft1: { label: 'Bottom Left Image 1', type: 'media', breakpoints: { lg: { width: 350, height: 250 }, sm: { width: 350, height: 200 } } },
    bottomLeft2: { label: 'Bottom Left Image 2', type: 'media', breakpoints: { lg: { width: 350, height: 250 }, sm: { width: 350, height: 200 } } },
    bottomRightText: { label: 'Bottom Right Text', type: 'wysiwyg' },
    bottomRightImage: { label: 'Bottom Right Image', type: 'media', breakpoints: { lg: { width: 350, height: 250 }, sm: { width: 350, height: 200 } } },
  },
  templates: [{
    label: 'Navigation Gallery',
    fields: {
      topLeft: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/photo-gallery\/pools-and-beaches\/atlantis-paradise-lagoon-beach-overhead-view.jpg' },
      topRight: { value: '<p><strong>5 miles<\/strong> of white sand beaches and turquoise waters<\/p>' },
      middleLeft: { value: '<p><strong>100<\/strong> adventure-filled tours \u2028&amp; excursions, <strong>20<\/strong> swimming areas, <strong>14<\/strong> different pools<\/p>' },
      middleRight1: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/experiences\/pool-holding-flower.jpg' },
      middleRight2: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/experiences\/atlantis-junkanoo-band-on-beach-color.jpeg' },
      bottomLeft1: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/experiences\/atlantis-baths-collonade-pool-couple-in-waterfall.jpeg' },
      bottomLeft2: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/experiences\/mom-child-kiss.jpg' },
      bottomRightText: { value: '<p><strong>50,000 animals<\/strong> &amp; <strong>200 species<\/strong><\/p>' },
      bottomRightImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/experiences\/dolphins.jpg' },
    },
  }],
})
</script>

<template>
  <div>
    <div :class="{ container: windowWidth > 1680 }">
      <div class="px-8 lg:px-0 grid grid-cols-12  ">
        <div
          class="py-16 col-span-12"
          :class="{
            'lg:col-start-2': fields.isRight.value,
          }"
        >
          <div ref="menu" class="grid lg:grid-cols-12 gap-4">
            <div
              class="grid grid-cols-2 lg:grid-cols-12 grid-rows-6 lg:grid-rows-12 gap-2 lg:gap-4 lg:h-[700px]"
              :class="{
                'lg:col-start-3': fields.isRight.value,
                'lg:col-span-12': !fields.subNavigation.value,
                'lg:col-span-9 xl:col-span-10': fields.subNavigation.value,
              }"
            >
              <div class="h-[155px] lg:h-auto lg:col-span-6 lg:col-start-1 lg:row-span-4 lg:row-start-1">
                <VoixMedia :field="fields.topLeft" class="object-cover w-full h-full transform duration-500" :class="{ 'translate-y-12 opacity-0': !canSee, 'translate-x-0 opacity-100': canSee }" />
              </div>
              <div
                class="col-span-2 lg:col-span-6 lg:row-span-3 row-start-2 lg:row-start-1 bg-glueblue-600 text-white flex justify-center items-center px-8 p-4 lg:p-8"
              >
                <div
                  class="font-sans2 tracking-wider uppercase text-lg xl:text-2xl font-light wysiwyg no-prose px-4 xl:px-8"
                  v-html="fields.topRight.value"
                />
              </div>
              <div
                class="col-span-2 lg:col-span-6 col-start-1 lg:row-span-3 row-start-4 lg:row-start-5 bg-red-200 text-white flex justify-center items-center px-8 p-4 lg:p-8"
              >
                <div
                  class="font-sans2 tracking-wider uppercase text-lg xl:text-2xl font-light wysiwyg no-prose px-4 xl:px-8"
                  v-html="fields.middleLeft.value"
                />
              </div>
              <div
                class="h-[155px] lg:h-auto  lg:col-span-2 col-start-1 lg:col-start-7 lg:row-span-4 row-start-1 lg:row-start-4"
              >
                <VoixMedia :field="fields.middleRight1" class="object-cover w-full h-full transform duration-500 delay-300" :class="{ 'translate-y-12 opacity-0': !canSee, 'translate-x-0 opacity-100': canSee }" />
              </div>
              <div
                class="h-[155px] lg:h-auto col-span-2 lg:col-span-4 lg:col-start-9 lg:row-span-4 row-start-3 lg:row-start-4 "
              >
                <VoixMedia :field="fields.middleRight2" class="object-cover w-full h-full transform duration-500 delay-700" :class="{ 'translate-y-12 opacity-0': !canSee, 'translate-x-0 opacity-100': canSee }" />
              </div>
              <div class="h-[155px] lg:h-auto col-span-2 lg:col-span-3 lg:row-span-3">
                <VoixMedia :field="fields.bottomLeft1" class="object-cover w-full h-full transform duration-500 delay-1000" :class="{ 'translate-y-12 opacity-0': !canSee, 'translate-x-0 opacity-100': canSee }" />
              </div>
              <div class="hidden lg:block lg:col-span-3 lg:row-span-3">
                <VoixMedia :field="fields.bottomLeft2" class="object-cover w-full h-full transform duration-500 delay-1000" :class="{ 'translate-y-12 opacity-0': !canSee, 'translate-x-0 opacity-100': canSee }" />
              </div>
              <div
                class="col-span-2 lg:col-span-3 lg:row-span-3 bg-glueblue-400 text-white flex justify-center items-center px-8 p-4 lg:p-6"
              >
                <div
                  class="font-sans2 tracking-wider uppercase text-lg xl:text-2xl font-light wysiwyg no-prose px-4 xl:px-8"
                  v-html="fields.bottomRightText.value"
                />
              </div>
              <div class="hidden lg:block lg:col-span-3 lg:row-span-3">
                <VoixMedia :field="fields.bottomRightImage" class="object-cover w-full h-full transform duration-500 delay-1000" :class="{ 'translate-y-12 opacity-0': !canSee, 'translate-x-0 opacity-100': canSee }" />
              </div>
            </div>
            <div
              v-if="fields.subNavigation.value"
              class="hidden lg:flex lg:col-span-3 xl:col-span-2 flex-col divide-y divide-orange-300"
              :class="{
                'lg:col-start-1 lg:row-start-1': fields.isRight.value,
              }"
            >
              <div class="headline-3 px-4 pb-2">
                {{ fields.subNavigationTitle.text }}
              </div>
              <VoixSlice
                v-for="(element, key) in slice.elements" :key="element.id" :slice-index="key" :slice="element"
                class="block py-3 px-4 text-xs transform duration-500 delay-1000" :class="{
                  '-translate-x-12 opacity-0': !canSee,
                  'translate-x-0 opacity-100': canSee,
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ClientOnly>
      <Teleport to="#below-navigation">
        <div
          v-if="fields.subNavigation.value"
          class="fixed top-[89px] left-0 right-0 z-10 -mt-0.5 bg-orange-300 font-sans2 uppercase text-2xs font-bold  transition-all duration-500"
          :class="{ '-translate-y-96': !showSubnav }"
        >
          <div class="container mx-auto flex">
            <VoixSlice
              v-for="(element, key) in slice.elements" :key="element.id" :slice-index="key" :slice="element"
              class="block pt-2 pb-1.5 px-4 text-2xs"
            />
          </div>
        </div>
      </Teleport>
    </ClientOnly>
  </div>
</template>
