<script setup lang="ts">
import type { ModelRef } from 'vue'

defineProps({
  devise: { type: Object },
  numberOfCurrentActivities: {
    type: Number,
    required: true,
  },
})

const modelValue: ModelRef<{ animalTypes: { [key: string]: boolean }, age: string, activityLevel: string } | undefined> = defineModel()

const ageOptions = ['3-6', '5 years +', '6 years +', '8 years +', '10 years +', '12 years +', '16 years +', 'All Ages']
const activityOptions = ['Very Low', 'Low', 'Medium', 'High']
const showMobileFilters = ref(false)
const animalsSelected = computed(() => {
  if (modelValue.value) {
    return Object.entries(modelValue.value.animalTypes)
      .filter(([, value]) => value)
      .map(([key]) => ({
        name: key,
      }))
  }
  else {
    return []
  }
})

function clearSelectedAnimals() {
  if (modelValue.value) {
    Object.keys(modelValue.value.animalTypes).forEach((key) => {
      if (modelValue.value)
        modelValue.value.animalTypes[key] = false
    })
  }
}
</script>

<template>
  <div v-if="modelValue" class="py-8 lg:bg-white w-full">
    <div
      class="px-12 flex flex-col xl:flex-row lg:justify-center space-y-8 md:space-y-4 lg:space-y-0 lg:space-x-12 lg:items-center"
    >
      <div class="headline-3 w-48 mb-4 xl:mb-0">
        {{ numberOfCurrentActivities }} Activities
      </div>

      <div class="relative grid grid-cols-12">
        <div
          class="col-span-2 text-zinc-700 flex items-center font-sans2 uppercase font-bold space-x-2"
          @click="showMobileFilters = !showMobileFilters"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 31" class="w-8 h-8">
            <rect width="39" height="2" transform="translate(0 5)" fill="currentColor" />
            <rect width="39" height="2" transform="translate(0 24)" fill="currentColor" />
            <rect width="39" height="2" transform="translate(0 15)" fill="currentColor" />
            <g
              transform="translate(23)"
              fill="#f5f2ea"
              stroke="currentColor"
              stroke-miterlimit="10"
              stroke-width="2"
            >
              <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
              <circle cx="5.5" cy="5.5" r="4.5" fill="none" />
            </g>
            <g
              transform="translate(5 10)"
              fill="#f5f2ea"
              stroke="currentColor"
              stroke-miterlimit="10"
              stroke-width="2"
            >
              <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
              <circle cx="5.5" cy="5.5" r="4.5" fill="none" />
            </g>
            <g
              transform="translate(18 20)"
              fill="#f5f2ea"
              stroke="currentColor"
              stroke-miterlimit="10"
              stroke-width="2"
            >
              <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
              <circle cx="5.5" cy="5.5" r="4.5" fill="none" />
            </g>
          </svg>

          <span>Filter By</span>
        </div>

        <!-- Desktop Filters -->
        <div class="col-span-3 hidden lg:flex flex-col -mt-3  ">
          <GlobalUICustomSelect
            class="flex p-0"
            dropdown-classes=" bg-white grid grid-cols-2 shadow-2xl"
            :match-width="false"
            label="Animal Type"
            :close-on-select="false"
          >
            <label
              v-for="(isOn, animal) in modelValue.animalTypes"
              :key="animal"
              class="block p-4 uppercase text-xs bg-white"
            >
              <div class="flex items-center space-x-2">
                <div class="flex items-center">
                  <button class="border border-zinc-400 px-0.5 rounded-sm" @click="modelValue.animalTypes[animal] = !modelValue.animalTypes[animal]">
                    <Icon
                      class="w-4 h-4" :class="{
                        'opacity-0': !modelValue.animalTypes[animal],
                      }"
                      name="heroicons:check-20-solid"
                    />
                  </button>
                </div>
                <span>{{ animal }}</span>
              </div>
            </label>
          </GlobalUICustomSelect>
          <div
            class="truncate font-sans2 text-center text-xs flex justify-center items-center font-light space-x-2 w-full"
          >
            <template v-if="animalsSelected.length">
              <span>{{
                animalsSelected.map((animal) => `${animal.name}`).join(", ")
              }}</span>
              <button @click="clearSelectedAnimals">
                <svg
                  class="w-3 h-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </template>
            <span v-else class="text-center w-full italic text-gray-500">
              Nothing Selected
            </span>
          </div>
        </div>

        <div class="col-span-3 hidden lg:block -mt-3">
          <GlobalUICustomSelect
            class="flex"
            dropdown-classes=" bg-white shadow-2xl"
            :match-width="false"
            label="Age"
            :close-on-select="true"
          >
            <label
              v-for="(age, key) in ageOptions"
              :key="key"
              class="block p-4 uppercase text-xs"
              @click="modelValue.age = age"
            >
              <div class="flex items-center space-x-2">
                <span>{{ `${age} ${age === '16' ? "+" : ""}` }}</span>
              </div>
            </label>
          </GlobalUICustomSelect>

          <div class="pl-4 truncate font-sans2 text-xs flex justify-center items-center font-light">
            <template v-if="modelValue.age">
              {{ modelValue.age }}

              <button class="ml-2" @click="modelValue.age = ''">
                <svg
                  class="w-3 h-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </template>
            <span v-else class="text-center w-full italic text-gray-500">
              Nothing Selected
            </span>
          </div>
        </div>

        <div class="col-span-3 hidden lg:block -mt-3">
          <GlobalUICustomSelect
            class="flex"
            dropdown-classes=" bg-white shadow-2xl"
            :match-width="false"
            label="Activity Level"
            :close-on-select="true"
          >
            <label
              v-for="(activityLevel, key) in activityOptions"
              :key="key"
              class="block p-4 uppercase text-xs"
              @click="modelValue.activityLevel = activityLevel"
            >
              <div class="flex items-center space-x-2">
                <span>{{ activityLevel }}</span>
              </div>
            </label>
          </GlobalUICustomSelect>

          <div
            class="pl-4 truncate font-sans2 text-xs flex justify-center items-center font-light"
          >
            <template v-if="modelValue.activityLevel">
              {{ modelValue.activityLevel }}

              <button class="ml-2" @click="modelValue.activityLevel = ''">
                <svg
                  class="w-3 h-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </template>
            <span v-else class="text-center w-full italic text-gray-500">
              Nothing Selected
            </span>
          </div>
        </div>

        <!-- Mobile Filters -->
        <div
          v-show="showMobileFilters"
          class="lg:hidden absolute z-30 top-0 left-0 right-0 mt-8 bg-white py-8 px-2 shadow-xl flex flex-col space-y-2"
        >
          <div class="-mt-3 grid grid-cols-2 font-sans2">
            <label
              v-for="(isOn, animal) in modelValue.animalTypes"
              :key="animal"
              class="block p-4 uppercase text-xs bg-white"
            >
              <div class="flex items-center space-x-2">
                <div class="flex items-center">
                  <input
                    v-model="modelValue.animalTypes[animal]"
                    type="checkbox"
                    class="focus:ring-glueblue-500 h-4 w-4 text-glueblue-600 border-gray-300 rounded"
                  >
                </div>
                <span>{{ animal }}</span>
              </div>
            </label>
          </div>

          <div class="-mt-3">
            <GlobalUICustomSelect
              class="flex"
              dropdown-classes=" bg-white shadow-2xl"
              :match-width="false"
              label="Age"
              :close-on-select="true"
            >
              <label
                v-for="(age, key) in ageOptions"
                :key="key"
                class="block p-4 uppercase text-xs"
                @click="modelValue.age = age"
              >
                <div class="flex items-center space-x-2">
                  <span>{{ age }}</span>
                </div>
              </label>
            </GlobalUICustomSelect>

            <div
              class="pl-4 truncate font-sans2 text-xs flex items-center font-light"
            >
              <template v-if="modelValue.age">
                {{ modelValue.age }}

                <button class="ml-2" @click="modelValue.age = ''">
                  <svg
                    class="w-3 h-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </template>
              <template v-else>
                Nothing Selected
              </template>
            </div>
          </div>

          <div class="-mt-3">
            <GlobalUICustomSelect
              class="flex"
              dropdown-classes=" bg-white shadow-2xl"
              :match-width="false"
              label="Activity Level"
              :close-on-select="true"
            >
              <label
                v-for="(activityLevel, key) in activityOptions"
                :key="key"
                class="block p-4 uppercase text-xs"
                @click="modelValue.activityLevel = activityLevel"
              >
                <div class="flex items-center space-x-2">
                  <span>{{ activityLevel }}</span>
                </div>
              </label>
            </GlobalUICustomSelect>

            <div
              class="pl-4 truncate font-sans2 text-xs flex items-center font-light"
            >
              <template v-if="modelValue.activityLevel">
                {{ modelValue.activityLevel }}

                <button class="ml-2" @click="modelValue.activityLevel = ''">
                  <svg
                    class="w-3 h-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </template>
              <template v-else>
                Nothing Selected
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
