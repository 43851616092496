<script setup lang="ts">
const props = defineProps({
  fields: {
    type: Object as PropType<Record<string, any>>,
    default: () => {},
  },
  slice: {
    type: Object as PropType<Record<string, any>>,
    default: () => {},
  },
})

defineSlice({
  label: 'Atlantean PDF Viewer',
  description: 'Atlantean PDF Viewer',
  fields: {
    pdf: { type: 'files', label: 'PDF', enabled: true },
    noFileMessage: { type: 'text', label: 'No PDF Message', default: 'No PDF available' },
    showTopBorder: { type: 'checkbox', label: 'Show Top Border', default: true },
    disableToolbar: { type: 'checkbox', label: 'Disable Toolbar', default: false },
  },
  name: { label: 'Pdf Viewer', group: 'Atlantean', layouts: ['Atlantisbahamas', 'AtlantisMobileApp', 'NPIWFF'] },
  tags: ['Atlantean', 'Pdf'],
  templates: [{ label: 'Pdf Viewer', fields: { pdfSelect: { value: '64' } } }],
})

const pdf = computed(() => {
  if (!props.fields.pdf.enabled || !props.fields.pdf.value || !props.fields.pdf.value[0])
    return null

  return props.fields.pdf.value[0]
})

const finalPdfUrl = computed(() => {
  if (!pdf.value?.url)
    return ''

  if (props.fields.disableToolbar.value)
    return `${pdf.value.url}#toolbar=0`

  return pdf.value.url
})
</script>

<template>
  <div
    v-if="pdf" class="border-glueblue-600 w-full h-[90vh]" :class="{
      'border-t-[100px] md:border-t-[140px]': fields.showTopBorder.value,
    }"
  >
    <embed :src="finalPdfUrl" height="100%" class="w-full min-h-[90vh]" download="false">
  </div>
  <div v-else class="text-center text-lg text-gray-500">
    {{ fields.noFileMessage?.value }}
  </div>
</template>
