<script>
export default {
  name: 'OfficeChairIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    viewBox="0 0 30 42"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="currentcolor" fill-rule="evenodd">
      <path
        id="Shape"
        d="M28.7,10.1 L25.5,10.1 L25.5,1.1 C25.5,0.7 25.2,0.4 24.8,0.4 L5.2,0.4 C4.8,0.4 4.5,0.7 4.5,1.1 L4.5,10.1 L1.3,10.1 C0.9,10.1 0.6,10.4 0.6,10.8 L0.6,22.2 L0.6,24.1 C0.6,24.5 0.9,24.8 1.3,24.8 L12.8,24.8 L12.8,28.3 C12.8,28.7 13.1,29 13.5,29 L14.4,29 L14.4,37.5 L6,37.5 C4.2,37.5 2.7,39 2.7,40.8 C2.7,41.2 3,41.5 3.4,41.5 C3.8,41.5 4.1,41.2 4.1,40.8 C4.1,39.8 4.9,38.9 6,38.9 L24.1,38.9 C25.1,38.9 26,39.7 26,40.8 C26,41.2 26.3,41.5 26.7,41.5 C27.1,41.5 27.4,41.2 27.4,40.8 C27.4,39 25.9,37.5 24.1,37.5 L15.8,37.5 L15.8,29 L16.7,29 C17.1,29 17.4,28.7 17.4,28.3 L17.4,24.8 L28.7,24.8 C29.1,24.8 29.4,24.5 29.4,24.1 L29.4,22.2 L29.4,10.8 C29.4,10.4 29.1,10.1 28.7,10.1 Z M28,11.5 L28,19.5 C27.4,19.1 26.7,18.8 25.9,18.8 L25.5,18.8 L25.5,11.5 L28,11.5 Z M5.9,1.9 L24.2,1.9 L24.2,18.8 L5.9,18.8 L5.9,1.9 Z M4.5,11.5 L4.5,18.8 L4,18.8 C3.2,18.8 2.5,19.1 1.9,19.5 L1.9,11.5 L4.5,11.5 Z M2,22.3 C2,21.2 2.9,20.2 4.1,20.2 L26,20.2 C27.1,20.2 28.1,21.1 28.1,22.3 L28.1,23.5 L16.7,23.5 L13.5,23.5 L2,23.5 L2,22.3 Z M16,27.7 L14.2,27.7 L14.2,24.9 L16,24.9 L16,27.7 Z"
      />
    </g>
  </svg>
</template>
