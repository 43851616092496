<script>
export default {
  name: 'FamilyIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 37">
    <path
      fill="currentcolor"
      fill-rule="evenodd"
      stroke="#currentColor"
      stroke-width=".3"
      d="M27.9 6.905c0-2.544 2.048-4.593 4.593-4.593 2.544 0 4.593 2.049 4.593 4.593s-2.049 4.593-4.593 4.593c-2.545 0-4.593-2.049-4.593-4.593h0zM21.34 22.65c-2.545 0-4.594-2.048-4.594-4.592 0-2.545 2.049-4.593 4.593-4.593s4.593 2.048 4.593 4.593c0 2.544-2.049 4.592-4.593 4.592h0zm3.28 1.969c2.556 0 4.592 2.036 4.592 4.592v5.849H13.466v-5.849c0-2.556 2.036-4.592 4.593-4.592h6.56zM12.154 35.06H2.312V18.06c0-2.557 2.037-4.593 4.593-4.593h6.56c1.188 0 2.264.444 3.077 1.168-.69.967-1.108 2.151-1.108 3.425 0 2.285 1.313 4.268 3.219 5.248h-.594c-3.26 0-5.905 2.645-5.905 5.905v5.849zM5.593 6.906c0-2.544 2.048-4.593 4.592-4.593 2.545 0 4.593 2.049 4.593 4.593s-2.048 4.593-4.593 4.593c-2.544 0-4.592-2.049-4.592-4.593h0zm36.085 24.22V18.058c0-3.26-2.645-5.905-5.905-5.905h-.594c1.906-.981 3.219-2.964 3.219-5.25C38.398 3.652 35.746 1 32.493 1c-3.254 0-5.905 2.651-5.905 5.905 0 2.285 1.313 4.268 3.219 5.249h-.595c-1.518 0-2.909.578-3.957 1.517-1.044-.931-2.411-1.517-3.916-1.517-1.506 0-2.871.583-3.916 1.517-1.048-.939-2.439-1.517-3.957-1.517h-.595c1.906-.981 3.22-2.964 3.22-5.25C16.09 3.652 13.438 1 10.184 1 6.932 1 4.281 3.651 4.281 6.905c0 2.285 1.312 4.268 3.218 5.249h-.594C3.645 12.154 1 14.798 1 18.059v18.314h40.678v-1.312H30.525v-5.849c0-3.26-2.645-5.905-5.905-5.905h-.595c1.906-.98 3.219-2.963 3.219-5.248 0-1.277-.414-2.457-1.107-3.425.812-.724 1.888-1.168 3.075-1.168h6.561c2.556 0 4.593 2.036 4.593 4.593v13.065h1.312z"
    />
  </svg>
</template>
