<script>
export default {
  name: 'PressReleasePosts',

  props: {
    devise: { type: Object },
    posts: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loaded: false,
      pattern: ['masonry-sm', 'masonry-sm', 'masonry-lg', 'masonry-sm', 'masonry-sm', 'masonry-lg', 'masonry-lg'],
    }
  },

  methods: {
    getSizeClass(index) {
      return this.pattern[index % this.pattern.length]
    },
  },
}
</script>

<template>
  <div
    class="w-full duration-200 ease-out"
  >
    <div class="container pb-12 mt-8">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        <div
          v-for="(post, postIndex) in posts" :key="post.id"
          class="masonry-grid-item p-1 cursor-pointer relative overflow-hidden group bg-white text-gray-600 flex flex-col"
          :class="getSizeClass(postIndex)"
        >
          <div v-if="post.thumbnail" class="relative z-0 group-hover:scale-110 duration-300 w-full h-full object-cover">
            <img :src="post.thumbnail" class="w-full h-full object-cover">
          </div>
          <div
            v-if="post.category === 'newsroom'"
            class="absolute inset-0 z-20 flex flex-col justify-end items-start duration-500 bg-gradient-to-t from-black/0 group-hover:from-black/75 to-black/0 "
          >
            <a :href="post.permalink" class="duration-300">
              <div
                class="absolute z-20 bottom-0 left-0 text-white p-4 transform translate-y-0 duration-500 opacity-0 group-hover:opacity-100 group-hover:-translate-y-12 ease-out"
              >
                <div class="text-base uppercase font-sans font-normal leading-snug antialiased tracking-wide">
                  {{ post.title }}
                </div>

                <div class="text-sm font-sans2 font-medium text-gray-300">{{ post.short_description }}</div>
              </div>
            </a>
          </div>

          <template v-else>
            <a
              :href="post.permalink"
              class="hidden lg:block absolute z-30 inset-0 justify-center items-center bg-black/75 text-center opacity-0 hover:opacity-100 duration-300"
            >
              <div class="text-white capitalize font-sans font-medium leading-snug antialiased tracking-wide">
                {{ post.title }}
              </div>
            </a>
            <a
              :href="post.permalink"
              class="lg:hidden absolute h-[200px] z-30 left-0 right-0 bottom-0 p-4 flex justify-center items-end text-center bg-gradient-to-t from-glueblue-900 to-glueblue-900/0"
            >
              <div class="text-white capitalize font-sans2 font-bold leading-snug antialiased tracking-wide">
                {{ post.title }}
              </div>
            </a>
          </template>
        </div>
        <div v-if="posts.length < 1" class="col-span-12 h-48">
          <div class="text-center">
            <h2 class="text-2xl font-sans2 font-light">
              No press releases found
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.masonry-item {
  @apply aspect-square;

  > div {
    @apply object-cover w-full h-full;
  }
}

.masonry-sm {

  @screen lg {
    @apply text-lg;

    a {
      @apply p-4;
    }
  }
}

.masonry-lg {
  @screen lg {
    @apply col-span-2 text-2xl row-span-2;

    a {
      @apply p-8;
    }
  }
}
</style>
