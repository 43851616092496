<script>
export default {
  components: {
    GlueDiningRestaurantHours: () => import('@/components/glue/dining/GlueDiningRestaurantHours.vue'),
  },

  props: {
    devise: { type: Object },
    restaurant: {
      type: Object,
      required: true,
    },

    showDiningTimes: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    pushToDataLayer() {
      if (window.dataLayer)
        window.dataLayer.push({ restaurantName: this.restaurant.name })
    },
  },
}
</script>

<template>
  <div
    v-if="restaurant.name"
    class="bg-white text-gray-700 flex flex-col"
    style="max-width: 80vw"
  >
    <div class="w-full aspect-video overflow-hidden">
      <img
        :src="restaurant.list_image"
        class="object-cover w-full h-full"
      >
    </div>
    <div class="px-8 py-6 flex flex-col justify-between flex-1">
      <div>
        <div
          class="uppercase font-sans font-light leading-tight text-gray-800 text-xl tracking-wider mb-4 h-14"
          v-html="restaurant.name"
        />
        <div
          class="font-sans2 text-sm font-light text-gray-500 line-clamp-4"
          v-html="restaurant.short_description"
        />
      </div>
      <div class="mt-8 flex-1 flex flex-row-reverse justify-between items-end">
        <GlueDiningRestaurantHours v-if="showDiningTimes" :restaurant="restaurant" />
        <div>
          <a
            v-if="restaurant.slug"
            class="btn-ghost btn-sm"
            :href="restaurant.slug"
            @click="pushToDataLayer"
          >Learn More</a>
        </div>
      </div>
    </div>
  </div>
</template>
