<script>
export default {
  name: 'PoolIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 41 41"
  >
    <defs>
      <path
        id="pool-icon-prefix-a"
        d="M0 0.255L40.339 0.255 40.339 5.654 0 5.654z"
      />
      <path
        id="pool-icon-prefix-c"
        d="M0 0.242L40.339 0.242 40.339 5.642 0 5.642z"
      />
      <path
        id="pool-icon-prefix-e"
        d="M0 0.067L40.339 0.067 40.339 5.467 0 5.467z"
      />
      <path id="pool-icon-prefix-g" d="M0 38.848L40.339 38.848 40.339 0 0 0z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(0 26.093)">
        <mask id="pool-icon-prefix-b" fill="currentcolor">
          <use xlink:href="#pool-icon-prefix-a" />
        </mask>
        <path
          fill="currentcolor"
          stroke="#currentColor"
          stroke-width=".5"
          d="M24.313 2.002c2.037.026 3.506.935 4.926 1.814 1.5.93 2.884 1.788 4.815 1.837.923.016 1.821-.149 2.765-.514 1.003-.378 2.042-.98 3.18-1.839.183-.14.3-.342.333-.568.03-.227-.029-.453-.168-.636-.288-.379-.83-.452-1.205-.166-.994.757-1.895 1.284-2.751 1.614-.748.286-1.44.42-2.123.397-1.447-.035-2.638-.774-3.901-1.557C28.548 1.37 26.875.332 24.34.29c-2.508-.039-4.25 1.003-5.922 2.014-1.36.821-2.613 1.583-4.114 1.542-.631-.02-1.252-.161-1.9-.435-.754-.316-1.529-.794-2.367-1.462l-.046-.033C8.37.825 6.64.256 4.97.256 3.276.255 1.71.822.32 1.943c-.18.142-.292.346-.316.572-.025.227.042.451.186.632.292.367.833.425 1.203.13h.001c1.072-.864 2.272-1.302 3.566-1.302 1.333 0 2.716.458 3.998 1.326.976.774 1.88 1.326 2.777 1.7.86.357 1.686.538 2.526.556 2.01.048 3.472-.84 5.026-1.784C20.726 2.902 22.212 2 24.198 2h.115"
          mask="url(#pool-icon-prefix-b)"
        />
      </g>
      <g transform="translate(0 30.648)">
        <mask id="pool-icon-prefix-d" fill="currentcolor">
          <use xlink:href="#pool-icon-prefix-c" />
        </mask>
        <path
          fill="currentcolor"
          stroke="#currentColor"
          stroke-width=".5"
          d="M24.313 1.989c2.037.026 3.506.935 4.926 1.814 1.5.93 2.884 1.787 4.815 1.838.923.015 1.821-.15 2.765-.515 1.003-.378 2.042-.98 3.18-1.839.183-.14.3-.342.333-.568.03-.227-.029-.453-.168-.635-.288-.38-.83-.453-1.205-.167-.994.756-1.895 1.284-2.751 1.613-.748.287-1.44.42-2.123.399-1.447-.036-2.638-.776-3.901-1.558C28.548 1.357 26.875.32 24.34.277 21.832.239 20.09 1.28 18.418 2.29c-1.36.82-2.613 1.582-4.114 1.542-.631-.02-1.252-.161-1.9-.435-.754-.317-1.529-.795-2.367-1.462l-.046-.034C8.37.812 6.64.242 4.97.242 3.276.242 1.71.81.32 1.932c-.18.142-.292.345-.316.572-.025.226.042.45.186.631.292.367.833.425 1.203.13h.001c1.072-.864 2.272-1.303 3.566-1.303 1.333 0 2.716.46 3.998 1.327.976.773 1.88 1.327 2.777 1.7.86.357 1.686.539 2.526.556 2.01.047 3.472-.841 5.026-1.784 1.438-.872 2.924-1.773 4.91-1.773h.115"
          mask="url(#pool-icon-prefix-d)"
        />
      </g>
      <g transform="translate(0 35.204)">
        <mask id="pool-icon-prefix-f" fill="currentcolor">
          <use xlink:href="#pool-icon-prefix-e" />
        </mask>
        <path
          fill="currentcolor"
          stroke="#currentColor"
          stroke-width=".5"
          d="M24.313 1.814c2.037.026 3.506.934 4.926 1.814 1.5.93 2.884 1.788 4.815 1.838.923.015 1.821-.15 2.765-.515 1.003-.379 2.042-.98 3.18-1.84.183-.139.3-.34.333-.568.03-.227-.029-.452-.168-.635-.288-.379-.83-.452-1.205-.165-.994.756-1.895 1.283-2.751 1.612-.748.287-1.44.42-2.123.4-1.447-.037-2.638-.776-3.901-1.559C28.548 1.182 26.875.144 24.34.102c-2.508-.039-4.25 1.003-5.922 2.014-1.36.82-2.613 1.583-4.114 1.542-.631-.02-1.252-.162-1.9-.435-.754-.316-1.529-.794-2.367-1.462l-.046-.034C8.37.637 6.64.067 4.97.067c-1.695 0-3.26.568-4.65 1.69-.18.142-.292.344-.316.571-.025.227.042.452.186.633.292.366.833.425 1.203.128l.001.001c1.072-.865 2.272-1.302 3.566-1.302 1.333 0 2.716.458 3.998 1.326.976.774 1.88 1.327 2.777 1.7.86.357 1.686.538 2.526.556 2.01.047 3.472-.84 5.026-1.784 1.438-.872 2.924-1.773 4.91-1.773h.115"
          mask="url(#pool-icon-prefix-f)"
        />
      </g>
      <mask id="pool-icon-prefix-h" fill="currentcolor">
        <use xlink:href="#pool-icon-prefix-g" />
      </mask>
      <path
        fill="currentcolor"
        stroke="#currentColor"
        stroke-width=".5"
        d="M15.89 18.722h9.096v-4.48h-9.095v4.48zm9.958 5.077c.475 0 .86-.386.86-.86V4.677c0-.057-.006-.113-.016-.167-.062-1.185-.512-2.274-1.271-3.098C24.585.501 23.466 0 22.265 0c-1.184 0-2.304.502-3.156 1.415-.824.894-1.278 2.072-1.278 3.315 0 .475.386.86.86.86.434 0 .795-.322.853-.74l-.01-.12c0-.823.29-1.588.817-2.154.513-.551 1.19-.855 1.906-.855.717 0 1.394.304 1.905.855.526.566.817 1.331.817 2.154l.007.07.001 7.722h-9.096V4.678c-.026-1.25-.479-2.396-1.279-3.265C13.778.501 12.657 0 11.457 0c-1.183 0-2.305.502-3.156 1.415-.824.893-1.278 2.071-1.278 3.315 0 .475.385.86.86.86.434 0 .794-.323.852-.74l-.01-.12c0-.823.291-1.588.819-2.154.511-.551 1.188-.855 1.904-.855.717 0 1.394.304 1.905.855.525.564.816 1.327.817 2.148v18.214c0 .475.386.861.86.861.475 0 .86-.386.86-.86v-2.496h9.097v2.495c0 .475.387.861.861.861h0z"
        mask="url(#pool-icon-prefix-h)"
      />
    </g>
  </svg>
</template>
