<script setup lang="ts">
const props = defineProps({
  fields: {
    type: Object as PropType<Record<string, any>>,
    default: () => {},
  },
  slice: {
    type: Object as PropType<Record<string, any>>,
    default: () => {},
  },
})

defineSlice({
  name: { label: 'Showclix Embed', group: 'Showclix', layouts: ['Atlantisbahamas', 'Summer2024', 'Barbie', 'NPIWFF'] },
  preview: 'SlicesClixEmbed.jpg',
  tags: ['Showclix', 'Vendor'],
  fields: {
    showclixId: {
      label: 'Showclix ID',
      type: 'text',
      default: 'f47c6735-d980-47f7-a682-a6fc5dd40e89',
      description: 'You will have to reload the page after changing this value based on how showclix works.',
    },
    advancedEventListener: {
      label: 'Advanced Event Listener',
      type: 'checkbox',
    },
  },
})

onMounted(() => {
  // place this in the onMounted function
  const script = document.createElement('script')
  script.src = `https://www.showclix.com/embed/${props.fields.showclixId.value}`
  script.async = true

  const widget = document.getElementById(`showclix-widget-${props.fields.showclixId.value}`)

  // Baseline Script
  if (widget)
    widget.appendChild(script)

  // Advanced Event Listener
  if (widget && props.fields.advancedEventListener.value) {
    const advancedEventListenerScript = document.createElement('script')
    advancedEventListenerScript.innerHTML = `
        var marketingPort = null;
        window.addEventListener("message", function(e) {
          console.log('showclix event', e)
          if (e.data == "embedPort" && e.ports) {
            marketingPort = e.ports[0];
            marketingPort.onmessage = function(embedMessage) {
              console.log("Handle Embed Marketing Data", embedMessage.data);
            }
          }
        });
      `
    widget.appendChild(advancedEventListenerScript)
  }
})
</script>

<template>
  <div id="showclix-slice">
    <!-- place this where you want the widget to appear on your site -->
    <div :id="`showclix-widget-${fields.showclixId.value}`" />
  </div>
</template>
