<script>
export default {
  name: 'BlogPosts',

  props: {
    devise: { type: Object },
    posts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      pattern: [
        'h-[350px] lg:col-span-6 lg:row-span-2 lg:h-[900px]',
        'h-[350px] lg:col-span-3 lg:h-[446px]',
        'h-[350px] lg:col-span-3 lg:h-[446px]',
        'h-[350px] lg:col-span-3 lg:h-[446px]',
        'h-[350px] lg:col-span-3 lg:h-[446px]',
        'h-[350px] lg:col-span-3 lg:h-[446px]',
        'h-[350px] lg:col-span-6 lg:row-span-2 lg:h-[900px]',
        'h-[350px] lg:col-span-3 lg:h-[446px]',
        'h-[350px] lg:col-span-3 lg:h-[446px]',
        'h-[350px] lg:col-span-3 lg:h-[446px]',
      ],
    }
  },

  methods: {
    generateClasses(key) {
      return this.pattern[key % this.pattern.length]
    },
    buildURL(post) {
      if (post.in_page_target)
        return `${post.permalink}${post.in_page_target}`

      return post.permalink
    },
  },
}
</script>

<template>
  <div class="grid lg:grid-cols-12 gap-2 px-4 lg:px-16">
    <div
      v-for="(post, key) in posts"
      :key="post.id"
      class="relative bg-glueblue-600 group overflow-hidden"
      :class="generateClasses(key)"
    >
      <img :src="post.thumbnail" class="w-full h-full object-cover">

      <div
        class="absolute z-10 inset-0 bg-gradient-to-t from-black/75 to-black/0"
      />

      <div
        class="absolute z-20 bottom-0 left-0 text-white p-12 transform translate-y-12 duration-500 group-hover:translate-y-0 ease-out"
      >
        <div
          class="text-2xl lg:text-4xl uppercase font-sans font-light leading-snug antialiased tracking-wide"
        >
          {{ post.title }}
        </div>
        <div
          class="max-h-[30px] opacity-0 group-hover:opacity-100 group-hover:max-h-[200px] duration-500 font-sans2 font-light"
        >
          {{ post.short_description }}
        </div>
      </div>
      <a :href="buildURL(post)" class="absolute z-30 inset-0" />
    </div>

    <div v-if="posts.length < 1" class="col-span-12 h-48">
      <div class="text-center">
        <h2 class="text-2xl font-sans2 font-light">
          No posts found
        </h2>
      </div>
    </div>
  </div>
</template>
