<script>
export default {
  name: 'Chat',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="currentcolor"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g
        stroke="none"
        stroke-width="1"
        fill="currentcolor"
        fill-rule="evenodd"
        transform="translate(2, 4)"
      >
        <path
          id="Path"
          d="M22.437,4.86 L5.4432,4.86 C5.1273,4.86 4.8762,5.1111 4.8762,5.427 C4.8762,5.7429 5.1273,5.994 5.4432,5.994 L22.437,5.994 C22.7529,5.994 23.004,5.7429 23.004,5.427 C23.004,5.1111 22.7448,4.86 22.437,4.86 Z"
        />
        <path
          id="Shape"
          d="M39.3498,7.938 C38.9367,7.5249 38.3859,7.29 37.7946,7.29 L27.8559,7.29 L27.8559,2.1708 C27.8559,0.972 26.8758,0 25.677,0 L2.2032,0 C0.9963,0 0.0162,0.972 0.0162,2.1708 L0.0162,18.5166 C0.0162,19.7154 0.9963,20.6874 2.1951,20.6874 L4.1715,20.6874 L4.8762,24.9642 C4.9086,25.1667 5.0463,25.3287 5.2407,25.4016 C5.3055,25.4259 5.3703,25.434 5.4351,25.434 C5.5647,25.434 5.6943,25.3854 5.7996,25.3044 L11.3886,20.6469 L11.8827,20.6469 L11.8827,25.6689 C11.8827,26.8839 12.8709,27.8721 14.0778,27.8721 L28.512,27.8721 L34.2063,32.5944 C34.3116,32.6835 34.4412,32.724 34.5708,32.724 C34.6356,32.724 34.7085,32.7159 34.7733,32.6916 C34.9677,32.6187 35.0973,32.4486 35.1297,32.2542 L35.8263,27.8802 L37.7946,27.8802 C39.0096,27.8802 39.9899028,26.892 39.9899028,25.677 L39.9899028,9.4932 C39.9978,8.9019 39.7629,8.3511 39.3498,7.938 Z M11.1861,19.5129 C11.0565,19.5129 10.9269,19.5615 10.8216,19.6425 L5.8401,23.7978 L5.2164,20.0232 C5.1678,19.7478 4.9329,19.5453 4.6575,19.5453 L2.2032,19.5453 C1.6281,19.5453 1.1583,19.0836 1.1583,18.5085 L1.1583,2.1708 C1.1583,1.6038 1.6281,1.134 2.2032,1.134 L25.677,1.134 C26.2521,1.134 26.7219,1.5957 26.7219,2.1708 L26.7219,7.857 L26.7219,7.857 L26.7219,18.4761 C26.7219,18.8325 26.5356,19.1484 26.2602,19.3347 C26.0901,19.4481 25.8957,19.5129 25.677,19.5129 L11.1861,19.5129 L11.1861,19.5129 Z M38.8638,25.6689 C38.8638,26.2602 38.3859,26.7381 37.8027,26.7381 L35.316,26.7381 C35.0163,26.7381 34.7733,26.9649 34.749,27.2646 L34.7247,27.621 L34.1739,31.0878 L29.079,26.8677 C28.9737,26.7867 28.8522,26.7381 28.7145,26.7381 L14.0778,26.7381 C13.4946,26.7381 13.0167,26.2602 13.0167,25.6689 L13.0167,20.6469 L25.677,20.6469 C26.1306,20.6469 26.5437,20.5092 26.892,20.2743 C27.4752,19.8855 27.8559,19.2213 27.8559,18.4761 L27.8559,8.424 L37.7946,8.424 C38.0781,8.424 38.3454,8.5374 38.5479,8.7399 C38.7504,8.9424 38.8638,9.2097 38.8638,9.4932 L38.8638,25.6689 L38.8638,25.6689 Z"
        />
        <path
          id="Path"
          d="M22.437,9.72 L5.4432,9.72 C5.1273,9.72 4.8762,9.9711 4.8762,10.287 C4.8762,10.6029 5.1273,10.854 5.4432,10.854 L22.437,10.854 C22.7529,10.854 23.004,10.6029 23.004,10.287 C23.004,9.9711 22.7448,9.72 22.437,9.72 Z"
        />
        <path
          id="Path"
          d="M22.437,14.58 L5.4432,14.58 C5.1273,14.58 4.8762,14.8311 4.8762,15.147 C4.8762,15.4629 5.1273,15.714 5.4432,15.714 L22.437,15.714 C22.7529,15.714 23.004,15.4629 23.004,15.147 C23.004,14.8311 22.7448,14.58 22.437,14.58 Z"
        />
      </g>
    </g>
  </svg>
</template>
