<script lang="ts" setup>
import type { VoixLinkFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    title: VoixWysiwygFieldInterface
    subtitle: VoixTextFieldInterface
    description: VoixWysiwygFieldInterface
    offerId: VoixTextFieldInterface
    flexPayLink: VoixLinkFieldInterface
    bonvoyLink: VoixLinkFieldInterface
    offerHighlightsTitle: VoixTextFieldInterface
    offerHighlight1: VoixWysiwygFieldInterface
    offerHighlight2: VoixWysiwygFieldInterface
    offerHighlight3: VoixWysiwygFieldInterface
    offerHighlight4: VoixWysiwygFieldInterface
    offerHighlight5: VoixWysiwygFieldInterface
    offerHighlight6: VoixWysiwygFieldInterface
    offerHighlight7: VoixWysiwygFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Special Offers Highlights', group: 'Specials', layouts: ['Atlantisbahamas'] },
  description: 'Special Offers Highlights Slice',
  tags: ['Special'],

  fields: {
    title: {
      type: 'wysiwyg',
      label: 'Title',
    },
    subtitle: {
      type: 'text',
      label: 'Subtitle',
    },
    description: {
      type: 'wysiwyg',
      label: 'Description',
    },
    offerId: {
      type: 'text',
      label: 'Offer ID',
    },
    flexPayLink: {
      type: 'link',
      label: 'FlexPay Link',
    },
    bonvoyLink: {
      type: 'link',
      label: 'Bonvoy Link',
      enabled: true,
    },
    offerHighlightsTitle: {
      type: 'text',
      label: 'Title',
      group: 'Highlights',
    },
    offerHighlight1: {
      type: 'wysiwyg',
      label: 'Highlight 1',
      group: 'Highlights',
    },
    offerHighlight2: {
      type: 'wysiwyg',
      label: 'Highlight 2',
      group: 'Highlights',
    },
    offerHighlight3: {
      type: 'wysiwyg',
      label: 'Highlight 3',
      group: 'Highlights',
    },
    offerHighlight4: {
      type: 'wysiwyg',
      label: 'Highlight 4',
      group: 'Highlights',
    },
    offerHighlight5: {
      type: 'wysiwyg',
      label: 'Highlight 5',
      group: 'Highlights',
    },
    offerHighlight6: {
      type: 'wysiwyg',
      label: 'Highlight 6',
      group: 'Highlights',
    },
    offerHighlight7: {
      type: 'wysiwyg',
      label: 'Highlight 7',
      group: 'Highlights',
    },
  },
  preview: 'SlicesSpecialOffersHighlights.jpg',
  templates: [{
    label: 'Special Offers Highlights',
    fields: {},
  }],
})

const viewMore = ref(false)
let pushToDataLayer = (title: string) => {}

onMounted(() => {
  pushToDataLayer = (title: string) => {
    if (window.dataLayer) {
      window.dataLayer.push({ offerName: title })
    }
  }
})
</script>

<template>
  <div class="py-24 px-16">
    <!-- Container -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-16 md:gap-32">
      <!-- Left Column -->
      <div class="relative w-full">
        <!-- Subtitle -->
        <div class="-mt-12 text-3xl font-serif tracking-wider italic text-red-500">
          {{ fields.subtitle.value }}
        </div>

        <!-- Title -->
        <VoixWysiwyg
          class="text-3xl font-light mb-4"
          :field="fields.title"
        />

        <!-- Description -->
        <VoixWysiwyg
          class="w-full pt-4 font-light"
          :field="fields.description"
        />

        <BookingBookNow
          class="w-full mt-8"
          :dropdown="false"
          :location="`Special - ${fields.title.value}`"
          :special="Number(fields.offerId.value)"
          @click="pushToDataLayer(`${fields.title.value}`)"
        >
          <template #default="slotProps">
            <button
              class="w-full py-3 px-6 mr-6 leading-snug uppercase focus:outline-none font-sans text-xs"
              :class="{
                'bg-glueblue-600 text-white': !slotProps.active,
                'btn-ghost btn-active': slotProps.active,
              }"
            >
              Book Now
            </button>
          </template>
        </BookingBookNow>

        <!-- FlexPay & Bonvoy -->
        <div class="flex flex-col xl:flex-row justify-between items-center mt-3 mb-4">
          <!-- FlexPay -->
          <div class="flex justify-between items-center space-x-2">
            <img src="/imgs/logos/flexpay.svg" alt="FlexPay" class="h-10">

            <span class="text-base font-normal">
              Available
            </span>

            <VoixLink
              class="text-xs sm:text-sm font-light underline"
              :field="fields.flexPayLink"
            >
              Learn More
            </VoixLink>
          </div>

          <!-- Bonvoy -->
          <div v-if="fields.bonvoyLink.enabled" class="flex justify-between items-center space-x-2">
            <img src="/imgs/logos/bonvoy.png" alt="Bonvoy" class="h-10">

            <span class="text-base font-normal">
              Available
            </span>

            <VoixLink
              class="text-xs sm:text-sm font-light underline"
              :field="fields.bonvoyLink"
            >
              Learn More
            </VoixLink>
          </div>
        </div>
      </div>

      <!-- Right Column -->
      <div class="flex flex-col">
        <!-- Highlights Title -->
        <p class="text-18 font-normal uppercase tracking-wider mb-1">
          <a href="#terms" class="text-sm font-light underline">
            Terms & Conditions Apply
          </a>
        </p>
      </div>
    </div>

    <!-- Seperator -->
    <div class="border-b-[1px] border-[#c8c6c0] md:mt-24" />
  </div>
</template>

<style scoped>
.interpolate-size {
  interpolate-size: allow-keywords;
}
</style>
