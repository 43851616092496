<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sortBy: null,
      sortDirection: null,
    }
  },
  computed: {
    label() {
      if (this.sortBy) {
        switch (this.sortBy) {
          case 'minPrice':
            return this.sortDirection === 'asc'
              ? 'Price: Low to High'
              : 'Price: High to Low'
          case 'maxAdults':
            return 'Capacity'
          case 'maxFt':
            return 'Size'
        }
      }

      return 'Sort By'
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Room Type List', group: 'Glue', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesGlueStayRoomTypeList.jpg',
      description: 'List of room types for a particular tower',
      tags: ['Room', 'Room Type', 'Hotel', 'Stay', 'Index'],

      slots: [{ name: 'default' }],
      fields: {
        title: { type: 'text', label: 'Title' },
      },
      templates: [{ label: 'Room Type List', fields: { title: { value: 'All Suites' } } }],
    })
  },
  methods: {
    sort(sortBy, dir) {
      this.sortBy = sortBy
      this.sortDirection = dir
    },

    sortRoomTypes(data) {
      return data.sort(this.sortFunction)
    },

    sortFunction(a, b) {
      if (a[this.sortBy] < b[this.sortBy])
        return this.sortDirection === 'asc' ? -1 : 1
      if (a[this.sortBy] > b[this.sortBy])
        return this.sortDirection === 'asc' ? 1 : -1
      return 0
    },
  },
}
</script>

<template>
  <div class="small-container">
    <div class="flex justify-between items-end mb-10 px-8 xl:px-0">
      <div class="headline-3">
        {{ fields.title.value || 'AllRooms' }}
      </div>
      <GlobalUICustomSelect
        class="flex"
        button-classes="px-8 uppercase font-bold text-sm tracking-wide"
        dropdown-classes="bg-white"
        :label="label"
        :close-on-select="true"
      >
        <div class="p-4" @click="sort('minPrice', 'asc')">
          Price: Low to High
        </div>
        <div class="p-4" @click="sort('minPrice', 'desc')">
          Price: High to Low
        </div>
        <div class="p-4" @click="sort('maxAdults', 'desc')">
          Capacity: High to Low
        </div>
        <div class="p-4" @click="sort('maxFt', 'desc')">
          Size: High to Low
        </div>
      </GlobalUICustomSelect>
    </div>
    <div
      v-if="$voix.breakpoint !== 'default' && $voix.breakpoint !== 'sm'"
      class="grid grid-cols-3 gap-y-6 gap-x-5"
    >
      <VoixSlice
        v-for="(element, key) in slice.elements[0].elements"
        :key="key"
        :slice="element"
      />
    </div>
    <div v-else>
      <Swiper>
        <SwiperSlide v-for="(element, key) in slice.elements" :key="key">
          <VoixSlice :slice="element" :slice-index="key" class="w-full" :slider="true" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>
