<script setup lang="ts">
import dayjs from 'dayjs'
import { animate, inView, stagger } from 'motion'

// Config
const props = defineProps<{
  fields: {
    mode: string
    learnMore: boolean
  }
}>()

defineSlice({
  name: { label: 'Events', group: 'Events', layouts: ['Npiwff'] },
  preview: 'SlicesWffEvents.jpg',
  description: '2022 refresh of the events system',
  tags: ['Events', 'Index'],

  fields: {
    mode: {
      type: 'select',
      label: 'Mode',
      options: { showEvents: 'Show Events', linkToEvents: 'Link to Events' },
    },
    learnMore: {
      type: 'checkbox',
      label: 'Show Learn More',
      default: true,
    },
  },
  templates: [{ label: 'Events', fields: { mode: { value: 'showEvents' } } }],
})

// State
const events = ref([])
const wffEvents = ref(null)
const filters = reactive({
  day: {
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  },
  category: {
    'Featured': false,
    'Dining Experiences': false,
    'Tasting Events': false,
    'Wine & More': false,
    'Around Atlantis': false,
  },
})

// Computed
const categoriesSelected = computed(() => {
  return Object.entries(filters.category)
    .filter(([, value]) => value)
    .map(([key]) => ({
      name: key,
    }))
})
const selectedEventsByDay = computed(() => {
  let filteredEvents = []
  if (categoriesSelected.value.length > 0) {
    filteredEvents = events.value.filter((event) => {
      return categoriesSelected.value.some((category) => {
        return event.categories.some((eventCategory) => {
          return eventCategory.name === `${category.name}`
        })
      })
    })
  }
  else {
    filteredEvents = events.value
  }
  // if any of the day filters are selected
  if (Object.values(filters.day).some(value => value)) {
    filteredEvents = events.value.filter((event) => {
      return Object.entries(filters.day).some(([day, value]) => {
        return value && getDow(event.start_date) === day
      })
    })
  }

  const eventsByDay = filteredEvents.reduce((acc, event) => {
    const date = new Date(event.start_date)
    const day = dayjs(date).format('D')
    const month = dayjs(date).format('MM')
    const year = dayjs(date).format('YYYY')

    const key = `${year}-${month}-${day}`

    if (!acc[key])
      acc[key] = []

    acc[key].push(event)

    return acc
  }, {})
  return eventsByDay
})
const currentTitle = computed(() => {
  // Are there any day filters enabled?
  const dayFilters = Object.values(filters.day).some(value => value)

  if (categoriesSelected.value.length > 0) {
    return categoriesSelected.value[0].name
  }
  else if (dayFilters) {
    const selectedDays = Object.entries(filters.day)
      .filter(([, value]) => value)
      .map(([day]) => day)

    return `${selectedDays.join(', ')} Events`
  }
  else {
    return 'All Events'
  }
})

// Methods
function animateIn() {
  animate('.days', { y: [100, 0], opacity: [0, 1] }, { duration: 1, delay: stagger(0.3) })
  animate('.categories', { scale: [0, 1], opacity: [0, 1] }, { duration: 1, delay: stagger(0.3) })
}
function animateOut() {
  animate('.days', { y: [0, 100], opacity: [1, 0] }, { duration: 1, delay: stagger(0.3) })
  animate('.categories', { scale: [1, 0], opacity: [1, 0] }, { duration: 1, delay: stagger(0.3) })
}
function setCategory(category) {
  if (props.fields.mode.value !== 'showEvents') {
    window.location.href = `/wine-and-food-festival/events?cat=${encodeURIComponent(category)}#npiwff-events`
  }
  else {
    unselectCategories()
    filters.category[category] = true
  }
}
function filterDay(day) {
  if (props.fields.mode.value !== 'showEvents')
    window.location.href = `/wine-and-food-festival/events?day=${day}`
  else
    filters.day[day] = !filters.day[day]
}
function unselectCategories() {
  if (props.fields.mode.value !== 'showEvents')
    window.location.href = '/wine-and-food-festival/events'

  filters.day = {
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  }

  Object.keys(filters.category).forEach((key) => {
    filters.category[key] = false
  })
}
function goToPackages() {
  window.location.href = `/wine-and-food-festival/packages`
}
function getDow(date) {
  const dow = dayjs(date).format('dddd')

  return `${dow}`
}
function getMonthDay(date) {
  const monthDay = dayjs(date).format('MMMM D')

  return `${monthDay}`
}

// SSR Actions
if (props.fields.mode.value === 'showEvents') {
  const { data } = await $voixNuxtApi(`/api/atlantis/events/NPIWFF/months/march?entire_month=true`)
  events.value = data.value.data
  console.log(events.value)
}

// Lifecycle Hooks
onMounted(() => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })

  const categoryValue = params.cat
  if (categoryValue)
    setCategory(categoryValue)

  const dayValue = params.day
  if (dayValue)
    filterDay(dayValue)

  inView(wffEvents.value, () => {
    animateIn()

    return animateOut
  })
})
</script>

<template>
  <div class="py-12 px-8 md:px-0">
    <div ref="wffEvents">
      <div class="relative -mx-8 font-sans2 text-center text-xs leading-snug uppercase">
        <WffSvgsWffWaves
          class="absolute z-0 inset-0 -translate-y-16 opacity-30 text-glueblue-300 pointer-events-none"
        />

        <div
          class="relative z-10 flex justify-center flex-wrap  text-glueblue-600 font-bold font-wff-headline text-sm"
        >
          <button class="days border border-glueblue-600 px-6 py-1 uppercase mx-2 my-2" @click="unselectCategories">
            All Events
          </button>
          <button
            class="days"
            :class="{ 'border border-glueblue-600 px-6 py-1 uppercase mx-2 my-2': !filters.day.Wednesday, 'mx-2 my-2 border border-transparent bg-glueblue-600 text-white px-6 py-1 uppercase': filters.day.Wednesday }"
            @click="filterDay('Wednesday')"
          >
            Wednesday
          </button>
          <button
            class="days"
            :class="{ 'border border-glueblue-600 px-6 py-1 uppercase mx-2 my-2': !filters.day.Thursday, 'mx-2 my-2 border border-transparent bg-glueblue-600 text-white px-6 py-1 uppercase': filters.day.Thursday }"
            @click="filterDay('Thursday')"
          >
            Thursday
          </button>
          <button
            class="days"
            :class="{ 'border border-glueblue-600 px-6 py-1 uppercase mx-2 my-2': !filters.day.Friday, 'mx-2 my-2 border border-transparent bg-glueblue-600 text-white px-6 py-1 uppercase': filters.day.Friday }"
            @click="filterDay('Friday')"
          >
            Friday
          </button>
          <button
            class="days"
            :class="{ 'border border-glueblue-600 px-6 py-1 uppercase mx-2 my-2': !filters.day.Saturday, 'mx-2 my-2 border border-transparent bg-glueblue-600 text-white px-6 py-1 uppercase': filters.day.Saturday }"
            @click="filterDay('Saturday')"
          >
            Saturday
          </button>
          <button
            class="days"
            :class="{ 'border border-glueblue-600 px-6 py-1 uppercase mx-2 my-2': !filters.day.Sunday, 'mx-2 my-2 border border-transparent bg-glueblue-600 text-white px-6 py-1 uppercase': filters.day.Sunday }"
            @click="filterDay('Sunday')"
          >
            Sunday
          </button>
          <button
            class="days"
            :class="{ 'border border-glueblue-600 px-6 py-1 uppercase mx-2 my-2': !filters.day.Sunday, 'mx-2 my-2 border border-transparent bg-glueblue-600 text-white px-6 py-1 uppercase': filters.day.Sunday }"
            @click="goToPackages"
          >
            Packages
          </button>
        </div>

        <div class="mt-6 flex justify-center flex-wrap ">
          <div class="cursor-pointer m-4" @click="unselectCategories">
            <div class="categories rounded-full w-24 h-24 lg:w-40 lg:h-40 relative overflow-hidden">
              <img src="/imgs/wff/all-events.jpg" alt="All Events" class="w-full h-full object-cover">
            </div>
            <div class="mt-3 text-glueblue-600 font-bold font-wff-headline">
              All<br>
              Events
            </div>
          </div>
          <div class="cursor-pointer m-4" @click="setCategory('Featured')">
            <div
              class="categories rounded-full w-24 h-24 lg:w-40 lg:h-40 relative overflow-hidden" :class="{
                'border-red-300 border-4': filters.category.Featured === true,
              }"
            >
              <img src="/imgs/wff/culinary-demos.jpg" alt="Featured Events" class="w-full h-full object-cover">
            </div>
            <div class="mt-3 text-glueblue-600 font-bold font-wff-headline">
              Featured<br>
              Events
            </div>
          </div>
          <div class="cursor-pointer m-4" @click="setCategory('Dining Experiences')">
            <div
              class="categories rounded-full w-24 h-24 lg:w-40 lg:h-40 relative overflow-hidden" :class="{
                'border-red-300 border-4': filters.category['Dining Experiences'] === true,
              }"
            >
              <img src="/imgs/wff/dining-experiences.jpg" alt="Dining Experiences" class="w-full h-full object-cover">
            </div>
            <div class="mt-3 text-glueblue-600 font-bold font-wff-headline">
              Dining<br>
              Experiences
            </div>
          </div>
          <div class="cursor-pointer m-4" @click="setCategory('Tasting Events')">
            <div
              class="categories rounded-full w-24 h-24 lg:w-40 lg:h-40 relative overflow-hidden" :class="{
                'border-red-300 border-4': filters.category['Tasting Events'] === true,
              }"
            >
              <img src="/imgs/wff/tasting-events.jpg" alt="Tasting Events" class="w-full h-full object-cover">
            </div>
            <div class="mt-3 text-glueblue-600 font-bold font-wff-headline">
              Tasting<br>
              Events
            </div>
          </div>
          <div class="cursor-pointer m-4" @click="setCategory('Wine & More')">
            <div
              class="categories rounded-full w-24 h-24 lg:w-40 lg:h-40 relative overflow-hidden" :class="{
                'border-red-300 border-4': filters.category['Wine & More'] === true,
              }"
            >
              <img src="/imgs/wff/wine-and-more.jpg" alt="Wine and More" class="w-full h-full object-cover">
            </div>
            <div class="mt-3 text-glueblue-600 font-bold font-wff-headline">
              Wine<br>
              &amp; More
            </div>
          </div>
          <div class="cursor-pointer m-4" @click="goToPackages">
            <div class="categories rounded-full w-24 h-24 lg:w-40 lg:h-40 relative overflow-hidden">
              <img src="/imgs/wff/packages.jpg" alt="All Events" class="w-full h-full object-cover">
            </div>
            <div class="mt-3 text-glueblue-600 font-bold font-wff-headline">
              Packages
            </div>
          </div>
          <!-- <div class="cursor-pointer m-4" @click="setCategory('Around Atlantis')">
            <div
              class="categories rounded-full w-24 h-24 lg:w-40 lg:h-40 relative overflow-hidden" :class="{
                'border-red-300 border-4': filters.category['Around Atlantis'] === true,
              }"
            >
              <img src="/imgs/wff/property-shot.jpg" alt="Wine and More" class="w-full h-full object-cover">
            </div>
            <div class="mt-3 text-glueblue-600 font-bold font-wff-headline">
              Around<br>
              Atlantis
            </div>
          </div> -->
        </div>
      </div>

      <div v-if="fields.mode.value === 'showEvents'">
        <div class="mt-12 flex justify-center">
          <div class="font-wff-headline text-glueblue-600 uppercase tracking-wider text-4xl font-bold container">
            {{ currentTitle }}
          </div>
        </div>

        <div v-for="(events, day) in selectedEventsByDay" :key="day" class="mt-8">
          <div class="text-red-300 border-t-2 border-red-300 flex justify-center pt-2">
            <div class="container font-wff-headline uppercase text-2xl flex space-x-2">
              <span class="font-bold">{{ getDow(day) }}</span>
              <span>{{ getMonthDay(day) }}</span>
            </div>
          </div>
          <div class="container">
            <div class="my-6 grid md:grid-cols-2 gap-x-4 gap-y-8">
              <WffWffEvent v-for="event in events" :key="event.id" :event="event" />
            </div>
          </div>
        </div>
      </div>

      <div v-show="selectedEventsByDay.length < 1" class="container mx-auto lg:-mt-12 text-center pb-12">
        <div class="p-8">
          <div class="headline-2">
            No events with these settings
          </div>
          <p class="mt-3 font-sans2">
            Come back for updates as new events are always added. Until then please adjust
            the filters above to find events in other time periods, categories, venues and
            age settings.
          </p>
        </div>
      </div>

      <div>&nbsp;</div>
    </div>
  </div>
</template>
