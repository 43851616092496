<script>
export default {
  name: 'BoatIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 42">
    <defs>
      <clipPath id="boat-icon-clip-path">
        <path d="M0,0H32V42H0Z" fill="none" />
      </clipPath>
    </defs>
    <g clip-path="url(#boat-icon-clip-path)">
      <path
        d="M23.97,42H5.413a1.044,1.044,0,0,1-.887-.5L.153,34.27a1.049,1.049,0,0,1-.019-1.058,1.033,1.033,0,0,1,.9-.535H9.845V1.048a1.039,1.039,0,1,1,2.078,0v.5h3.031a1.024,1.024,0,0,1,.608.2c6,2.551,10.734,8.133,13.7,16.142A47.931,47.931,0,0,1,32,29.467a1.054,1.054,0,0,1-.27.8,1.038,1.038,0,0,1-.764.339H11.923v2.074H28.874a1.036,1.036,0,0,1,.919.557,1.053,1.053,0,0,1-.062,1.081l-4.9,7.227A1.037,1.037,0,0,1,23.97,42ZM2.89,34.771h0L6,39.905H23.422L26.9,34.771H2.89ZM15.933,4.22V28.508H29.792c-.119-.857-.342-2.239-.733-3.947-.309-1.365-.682-2.728-1.106-4.051a36.346,36.346,0,0,0-4.233-9.054A21.017,21.017,0,0,0,15.933,4.22Zm-4.009-.58V28.508h1.931V3.641Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
