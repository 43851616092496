<script>
import WffBlueCorners from '@/components/wff/svgs/WffBlueCorners.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  name: 'Deals',
  components: {
    Swiper,
    SwiperSlide,
    WffBlueCorners,
  },

  props: {
    devise: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: 'glue',
    },
  },

  data() {
    return {
      swiper: null,
    }
  },

  computed: {
    slidesPerView() {
      if (this.$voix.breakpoint.isGreaterThan('md'))
        return 3

      return 1
    },
  },

  mounted() {
    this.updateOnInit()
  },

  methods: {
    updateOnInit() {
      setTimeout(() => {
        if (this.$refs.mySwiper?.$el?.swiper) {
          this.$refs.mySwiper.$el.swiper.update()
          this.swiper = this.$refs.mySwiper.$el.swiper
        }
        else {
          this.updateOnInit()
        }
      }, 1000)
    },

    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    goToSlide(index) {
      this.swiper.slideTo(index)
    },
  },
}
</script>

<template>
  <div class="relative py-20 bg-faw-light-blue">
    <div class="container px-8">
      <div class="font-wff-headline text-2xl font-bold uppercase text-glueblue-700">
        {{ devise.fields.title.value }}
      </div>

      <VoixWysiwyg class="mt-4 font-wff" :field="devise.fields.introduction" />
    </div>

    <div class="relative px-8">
      <template v-if="!devise.fields.disableSlider.value">
        <div class="mt-4 relative container clipping-slider overflow-hidden w-full">
          <Swiper
            ref="mySwiper"
            :slides-per-view="slidesPerView"
            :loop="devise.slice.elements.length > 1"
            :centered-slides="true"
          >
            <SwiperSlide v-for="(slice, key) in devise.slice.elements" :key="key" class="flex">
              <VoixSlice :slice="slice" :slice-index="key" />
            </SwiperSlide>
          </Swiper>
        </div>
        <div
          v-if="devise.slice.elements.length > 1"
          class="absolute z-20 inset-0 flex items-center justify-between px-8 lg:ml-0 pointer-events-none"
        >
          <button
            class="rounded-full bg-glueblue-700 text-white w-10 lg:w-20 h-10 lg:h-20 flex justify-center items-center pointer-events-auto"
            @click="prev"
          >
            <svg
              class="w-12 lg:w-24 h-12 lg:h-24"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <button
            class="rounded-full bg-glueblue-700 text-white w-10 lg:w-20 h-10 lg:h-20 flex justify-center items-center pointer-events-auto"
            @click="next"
          >
            <svg
              class="w-12 lg:w-24 h-12 lg:h-24 transform rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </template>

      <div v-else class="mt-4 relative container flex flex-col items-center lg:items-stretch lg:flex-row justify-between lg:space-x-4 space-y-4 lg:space-y-0">
        <div v-for="(slice, key) in devise.slice.elements" :key="key" class="flex">
          <VoixSlice :slice="slice" :slice-index="key" />
        </div>
      </div>
    </div>

    <WffBlueCorners class="absolute bottom-8 right-8 text-glueblue-600 w-20 h-20 -scale-x-100" />
  </div>
</template>

<style scoped>
.swiper-slide {
  @apply flex justify-center;
  width: auto;
  height: auto;
}

.swiper-wrapper {
  @apply items-stretch;
}
</style>
