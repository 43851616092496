<script>
export default {
  name: 'CityIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 34">
    <defs>
      <clipPath id="city-icon-clip-path">
        <path d="M0,0H56V34H0Z" fill="none" />
      </clipPath>
    </defs>
    <g id="Group_3" data-name="Group 3">
      <path d="M0,0H56V34H0Z" fill="none" />
      <g
        id="Group_3-2"
        data-name="Group 3"
        clip-path="url(#city-icon-clip-path)"
      >
        <path
          d="M55.384,34H.616a.612.612,0,1,1,0-1.225H5.152V31.686H2.386a.645.645,0,0,1-.493-.238.606.606,0,0,1-.112-.534L5.174,17.874a.612.612,0,0,1,1.188,0L9.755,30.915a.606.606,0,0,1-.112.534.645.645,0,0,1-.493.238H6.384v1.089h4.6V3.982a.615.615,0,0,1,.448-.589L23.307.024A.534.534,0,0,1,23.469,0a.649.649,0,0,1,.376.126.6.6,0,0,1,.247.488V32.786h3.292V30.643c-1.946-.39-2.2-2.672-2.2-4.616,0-3.364.966-6.77,2.812-6.77s2.812,3.406,2.812,6.77c0,1.944-.25,4.226-2.2,4.616v2.143h3.293V11.24a.615.615,0,0,1,.616-.613H44.409a.615.615,0,0,1,.617.613V32.786H50V30.507a2.433,2.433,0,0,1-.9.17c-.108,0-.208-.009-.313-.018l-.045,0a2.458,2.458,0,0,1-2.05-1.985,2.55,2.55,0,0,1,.056-1.122,2.449,2.449,0,0,1,1.065-4.525l.113,0,.119,0a1.117,1.117,0,0,1,.115.005,2.453,2.453,0,0,1,2.432-2.305q.061,0,.122,0a2.461,2.461,0,0,1,2.329,2.3,1.693,1.693,0,0,1,.174-.009,1.672,1.672,0,0,1,.173.009,2.448,2.448,0,0,1,1.065,4.524,2.549,2.549,0,0,1,.056,1.123,2.456,2.456,0,0,1-2.05,1.984,2.488,2.488,0,0,1-.327.022,2.608,2.608,0,0,1-.916-.168v2.267h4.155a.622.622,0,0,1,.626.614A.615.615,0,0,1,55.384,34ZM33.141,11.852V32.775H43.77V11.852ZM22.848,1.419,12.219,4.447V32.764H22.848ZM5.757,20.482,3.17,30.451H8.344ZM28,20.494c-.557,0-1.579,2.1-1.579,5.534S27.448,29.463,28,29.464s1.58,0,1.58-3.436S28.557,20.494,28,20.494Zm19.914,3.742a1.213,1.213,0,0,0-.2,2.381.623.623,0,0,1,.37.941,1.208,1.208,0,0,0,.828,1.859,1.249,1.249,0,0,0,1.2-.509.6.6,0,0,1,1.007,0,1.212,1.212,0,0,0,1,.522,1.251,1.251,0,0,0,.18-.013,1.208,1.208,0,0,0,.829-1.859.677.677,0,0,1-.056-.567.637.637,0,0,1,.426-.374,1.207,1.207,0,0,0,.9-1.3,1.23,1.23,0,0,0-1.1-1.077.87.87,0,0,0-.247,0,1.272,1.272,0,0,1-.132.007,1.08,1.08,0,0,1-.741-.279,1.063,1.063,0,0,1-.358-.806v-.046a1.213,1.213,0,0,0-1.154-1.179h-.045a1.213,1.213,0,0,0-1.221,1.214,1.059,1.059,0,0,1-.358.828,1.081,1.081,0,0,1-.734.279,1.2,1.2,0,0,1-.129-.007.717.717,0,0,0-.146-.011Z"
          fill="currentColor"
        />
      </g>
    </g>
    <path
      d="M.616,1.225H8.445A.616.616,0,0,0,9.061.613.616.616,0,0,0,8.445,0H.616A.616.616,0,0,0,0,.612a.616.616,0,0,0,.616.613"
      transform="translate(13.004 5.433)"
      fill="currentColor"
    />
    <path
      d="M8.445,0H.616A.616.616,0,0,0,0,.612a.616.616,0,0,0,.616.612H8.445A.616.616,0,0,0,9.061.612.616.616,0,0,0,8.445,0"
      transform="translate(13.004 9.357)"
      fill="currentColor"
    />
    <path
      d="M8.445,0H.616A.616.616,0,0,0,0,.612a.616.616,0,0,0,.616.612H8.445A.616.616,0,0,0,9.061.612.616.616,0,0,0,8.445,0"
      transform="translate(13.004 13.28)"
      fill="currentColor"
    />
    <path
      d="M8.445,0H.616A.616.616,0,0,0,0,.612a.616.616,0,0,0,.616.612H8.445A.616.616,0,0,0,9.061.612.623.623,0,0,0,8.445,0"
      transform="translate(13.004 17.205)"
      fill="currentColor"
    />
    <path
      d="M8.445,0H.616A.616.616,0,0,0,0,.612a.616.616,0,0,0,.616.612H8.445A.616.616,0,0,0,9.061.612.623.623,0,0,0,8.445,0"
      transform="translate(13.004 21.129)"
      fill="currentColor"
    />
    <path
      d="M8.445,0H.616A.616.616,0,0,0,0,.612a.616.616,0,0,0,.616.612H8.445A.616.616,0,0,0,9.061.612.616.616,0,0,0,8.445,0"
      transform="translate(13.004 25.063)"
      fill="currentColor"
    />
    <path
      d="M8.445,0H.616A.616.616,0,0,0,0,.612a.616.616,0,0,0,.616.612H8.445A.616.616,0,0,0,9.061.612.616.616,0,0,0,8.445,0"
      transform="translate(13.004 28.987)"
      fill="currentColor"
    />
    <path
      d="M.616,0A.616.616,0,0,0,0,.612V2.007a.616.616,0,0,0,1.232,0V.612A.6.6,0,0,0,.616,0"
      transform="translate(34.911 13.688)"
      fill="currentColor"
    />
    <path
      d="M.616,0A.616.616,0,0,0,0,.612V2.007a.616.616,0,0,0,1.232,0V.612A.616.616,0,0,0,.616,0"
      transform="translate(37.846 13.688)"
      fill="currentColor"
    />
    <path
      d="M.616,0A.616.616,0,0,0,0,.612V2.007a.616.616,0,0,0,1.232,0V.612A.616.616,0,0,0,.616,0"
      transform="translate(40.769 13.688)"
      fill="currentColor"
    />
    <path
      d="M.616,0A.616.616,0,0,0,0,.612V2.007a.616.616,0,0,0,1.232,0V.612A.609.609,0,0,0,.616,0"
      transform="translate(34.911 18.554)"
      fill="currentColor"
    />
    <path
      d="M.616,0A.616.616,0,0,0,0,.612V2.007a.616.616,0,0,0,1.232,0V.612A.623.623,0,0,0,.616,0"
      transform="translate(37.846 18.554)"
      fill="currentColor"
    />
    <path
      d="M.616,0A.616.616,0,0,0,0,.612V2.007a.616.616,0,0,0,1.232,0V.612A.623.623,0,0,0,.616,0"
      transform="translate(40.769 18.554)"
      fill="currentColor"
    />
    <path
      d="M.616,0A.616.616,0,0,0,0,.612V2.007a.616.616,0,0,0,1.232,0V.612A.6.6,0,0,0,.616,0"
      transform="translate(34.911 28.307)"
      fill="currentColor"
    />
    <path
      d="M.616,0A.616.616,0,0,0,0,.612V2.007a.616.616,0,0,0,1.232,0V.612A.616.616,0,0,0,.616,0"
      transform="translate(37.846 28.307)"
      fill="currentColor"
    />
    <path
      d="M.616,0A.616.616,0,0,0,0,.612V2.007a.616.616,0,0,0,1.232,0V.612A.616.616,0,0,0,.616,0"
      transform="translate(40.769 28.307)"
      fill="currentColor"
    />
    <path
      d="M.616,0A.616.616,0,0,0,0,.612V2.007a.616.616,0,0,0,1.232,0V.612A.609.609,0,0,0,.616,0"
      transform="translate(34.911 23.431)"
      fill="currentColor"
    />
    <path
      d="M.616,0A.616.616,0,0,0,0,.612V2.007a.616.616,0,0,0,1.232,0V.612A.623.623,0,0,0,.616,0"
      transform="translate(37.846 23.431)"
      fill="currentColor"
    />
    <path
      d="M.616,0A.616.616,0,0,0,0,.612V2.007a.616.616,0,0,0,1.232,0V.612A.623.623,0,0,0,.616,0"
      transform="translate(40.769 23.431)"
      fill="currentColor"
    />
  </svg>
</template>
