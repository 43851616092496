<script>
export default {
  name: 'Phone',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="currentcolor"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g
        stroke="none"
        stroke-width="1"
        fill="currentcolor"
        fill-rule="evenodd"
        transform="translate(0.000000, 6)"
      >
        <path
          id="Shape"
          d="M0.790697674,0 C0.354025493,0 0,0.355102344 0,0.793102765 L0,22.2068972 C0,22.6448977 0.354025493,23 0.790697674,23 L33.2093023,23 C33.6459745,23 34,22.6448977 34,22.2068972 L34,0.793102765 C34,0.355102344 33.6459745,0 33.2093023,0 L0.790697674,0 L0.790697674,0 Z M3.03924419,1.58620553 L30.9607558,1.58620553 L17,12.8631156 L3.03924419,1.58620553 Z M1.58139535,2.44124962 L16.505814,14.486478 C16.7946921,14.7184311 17.2053079,14.7184311 17.494186,14.486478 L32.4186047,2.44124962 L32.4186047,21.4137945 L1.58139535,21.4137945 L1.58139535,2.44124962 Z"
        />
      </g>
    </g>
  </svg>
</template>
