<script>
export default {
  name: 'GlueBedIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 27">
    <path
      fill="currentcolor"
      fill-rule="evenodd"
      stroke="#currentColor"
      stroke-width=".3"
      d="M4.394 1v14.687H1v5.678h3.085v4.505h1.852v-4.505h34.559v4.505h1.852v-4.505h3.085v-5.739h-3.394V1H4.394zm1.235 1.234h35.175v13.453h-1.666v-4.752h-3.825V5.751H25.191v5.184H21.18V5.751H11.121v5.184H7.295v4.752H5.629V2.234zm20.797 8.64h7.652v-3.95h-7.652v3.95zm-14.071-3.95h7.652v3.95h-7.652v-3.95zm-3.888 8.702h29.436v-3.518H8.467v3.518zm-6.233 4.443h41.964V16.86H2.234v3.209z"
    />
  </svg>
</template>
