<script setup lang="ts">
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  name: { label: 'Dining Restaurant Masthead', group: 'Glue', layouts: ['Atlantisbahamas', 'Empty'] },
  preview: 'SlicesGlueDiningDiningRestaurantMasthead.jpg',
  description: 'Empty container with child slices',
  tags: ['Dining'],
  fields: {
    logo: {
      type: 'media',
      label: 'Logo',
      breakpoints: { lg: { width: 144, height: 144 } },
    },
    restaurant: {
      type: 'select',
      label: 'Restaurant',
      options: {
        2: 'AURA NIGHTCLUB',
        3: 'Bahamian Club',
        13: 'Bar Sol',
        4: 'Beach Bites',
        1: 'Ben & Jerry\'s',
        15: 'Bimini Road',
        5: 'Breakwaters Grill',
        6: 'CAFÉ MARTINIQUE',
        7: 'CARMINE\'S',
        8: 'CASA D\'ANGELO',
        9: 'Cascades Grill',
        10: 'CAVE GRILL',
        11: 'CHOP STIX',
        12: 'DIVE IN',
        195: 'Fieldtrip',
        14: 'Fish by José Andrés',
        185: 'Frezca',
        196: 'Good Lies',
        190: 'KOSHER POP UP',
        17: 'LAGOON BAR & GRILL',
        18: 'MARINA PIZZERIA',
        19: 'Mayan Cart',
        20: 'McKenzie\'s Conch Shack',
        21: 'MOON BAR',
        23: 'MURRAY\'S DELI',
        182: 'Murray\'s Donut Cart',
        24: 'Nobu',
        140: 'Ocean Club Clubhouse',
        25: 'Olives',
        192: 'Paranza by Michael White',
        22: 'Perch',
        26: 'Pirate Republic',
        27: 'Pisces',
        145: 'Pita',
        28: 'PLATO\'S',
        29: 'Poseidon\'s Table',
        30: 'Riverbend Café',
        32: 'Seafire Steakhouse',
        31: 'SEAGLASS',
        194: 'Shake Shack',
        33: 'SHARK BITES',
        193: 'Silan by Alon Shaya',
        34: 'SIP SIP',
        188: 'STARBUCKS at Aquaventure',
        187: 'STARBUCKS at Marina Village',
        36: 'STARBUCKS at The Coral',
        186: 'STARBUCKS at The Reef',
        37: 'Sun & Ice',
        41: 'SUNSET POINT',
        38: 'The Beach Bar',
        39: 'The CORAL & The BEACH LOBBY LOUNGES',
        40: 'The Cove Pool',
        189: 'The Dilly Club',
        35: 'Triton\'s Snackbar',
        43: 'VILLAGE BURGER SHACK',
        44: 'VIRGIL\'S BBQ',
        45: 'Voyager\'s',
        191: 'Zero Entry',
      },
    },
    logoOnSide: { type: 'checkbox', label: 'Logo on Side?' },
    hours: { type: 'text', label: 'Hours', instructions: '"5:30pm - 10:30pm"' },
    booking: { type: 'text', label: 'Booking Suggestion', instructions: '"2 weeks ahead"' },
    notes: { type: 'textarea', label: 'Details (one line per note)', instructions: '"Please put each note on its own line"' },
    dresscode: { type: 'text', label: 'Dress Code', enabled: false },
    dresscodeDetails: { type: 'wysiwyg', label: 'Dress Code Details', enabled: false },
    reservation: { type: 'text', label: 'Open Table Restaurant ID (Enable only for now)', enabled: false },
    menu: { type: 'link', label: 'Link to menu \/ Back to restaurant', enabled: false },
    chefLink: { type: 'link', label: 'Link to Chef Microsite', enabled: false },
    extraButton: { type: 'link', label: 'Extra Button', enabled: false },
  },

  templates: [{
    label: 'Dining Restaurant Masthead',
    fields: {
      booking: { value: 'Reservations not needed' },
      hours: { value: '6:30am - 10:00pm' },
      logo: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/glue\/dining\/logos\/sun-ice-logo-black-white.jpg' },
      menu: { value: { text: 'View Menus', target: '_self', relationship: null, href: '\/new-page' } },
      notes: { value: 'Ice Cream Parlor\nFamily Friendly\nCasual' },
      reservation: { value: '1012876' },
    },
  }],
})

const restaurants = ref([])
const showReservations = ref(false)

const noteList = computed(() => {
  if (props.fields.notes && props.fields.notes.value)
    return props.fields.notes.value.split('\n')

  return null
})

const restaurant: ComputedRef<AtlantisRestaurant | null> = computed(() => {
  if (restaurants.value && restaurants.value.length)
    return restaurants.value.find((restaurant: AtlantisRestaurant) => restaurant.id === Number.parseInt(props.fields.restaurant.value)) || null

  return null
})

const voixAsyncDataResponse = await useVoixAsyncData('/api/devise/all-restaurants')
restaurants.value = voixAsyncDataResponse.data.value.data

const showDressCodeModal = ref(false)
</script>

<template>
  <div>
    <div class="px-16 small-container relative -mt-40 z-30">
      <div class="bg-white p-8 grid grid-cols-1 lg:grid-cols-12">
        <div
          class="
          col-span-1
          lg:col-span-3 lg:border-r
          border-gray-300
          lg:pr-8
          flex flex-col
          justify-center
        "
        >
          <div class="flex justify-center">
            <div
              :class="{
                'mb-6 lg:mb-0': fields.logoOnSide.checked,
                'mb-4': !fields.logoOnSide.checked,
              }"
            >
              <VoixMedia
                :field="fields.logo"
              />
            </div>
          </div>
        </div>
        <div
          class="
          col-span-1
          lg:col-span-6
          border-t border-b
          lg:border-t-0 lg:border-b-0
          border-gray-300
          py-8
          lg:py-0 lg:px-8
          grid
          text-xs
        "
          :class="{
            'lg:border-r': fields.reservation.enabled || fields.extraButton.enabled || fields.chefLink.enabled,
          }"
        >
          <div>
            <div v-if="restaurant" class="font-sans uppercase font-bold text-gray-600 text-lg" v-html="restaurant.name" />

            <div v-if="restaurant" class="mt-6">
              <div class="font-sans2 font-bold text-gray-600 text-sm">
                Availability:
              </div>
              <GlueDiningRestaurantHours :restaurant="restaurant" align="left" />
            </div>
            <div v-if="fields.booking.text" class="mt-6 space-x-2 flex font-sans2  text-sm">
              <div class="text-gray-600 font-bold">
                Book:
              </div>
              <div class="text-gray-500">
                {{ fields.booking.text }}
              </div>
            </div>

            <div v-if="fields.dresscode.enabled" class="mt-2 space-x-2 flex font-sans2  text-sm">
              <div class="text-gray-600 font-bold">
                Dress Code:
              </div>
              <div class="text-gray-500 flex space-x-1 items-center">
                <span>{{ fields.dresscode.text }}</span>
                <a
                  v-if="!fields.dresscodeDetails.enabled"
                  href="/faq#dress-code"
                  class="text-glueblue-500 underline hover:text-zinc-700 text-xs"
                >
                  Learn More
                </a>
                <button
                  v-else
                  class="text-glueblue-500 underline hover:text-zinc-700 text-xs"
                  @click="showDressCodeModal = true"
                >
                  Learn More
                </button>
              </div>
            </div>

            <div v-if="noteList" class="mt-2 flex space-x-2 font-sans2 text-sm">
              <div class="text-gray-600 font-bold">
                Details:
              </div>
              <div class="text-gray-500">
                <ul class="list-disc pl-6 font-sans2 text-gray-500">
                  <li v-for="(note, key) in noteList" :key="key">
                    {{ note }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-1 lg:col-span-3 py-8 lg:py-0 lg:pl-8 flex flex-col justify-center">
          <div>
            <VoixLink
              :field="fields.menu"
              class="

              btn-ghost
              w-full

            "
            />
          </div>

          <div v-if="fields.reservation.enabled" class="mt-2 relative">
            <a
              href="https://www.sevenrooms.com/landing/atlantisparadiseislandbahamas"
              target="_blank"
              class="
              glue-btn
              btn-ghost
              border-zinc-600
              text-zinc-700
              uppercase
              !text-xs
              body-11
              tracking-widest
              py-3
              px-6
              font-bold
              w-full

            "
            >
              Find a Table
            </a>
            <div v-show="showReservations && restaurant" class="absolute top-0 right-0 mt-8">
              <div
                class="fixed z-50 inset-0 bg-black opacity-50"
                @click="showReservations = false"
              />
              <div class="relative z-[60]">
                <BookingOpenTable :rid="fields.reservation.text" type="single" :small="true" />
              </div>
            </div>
          </div>

          <div class="mt-2">
            <VoixLink
              v-if="fields.chefLink.enabled"
              :field="fields.chefLink"
              class="
              glue-btn
              btn-ghost
              border-zinc-600
              text-zinc-700
              uppercase
              !text-xs
              body-11
              tracking-widest
              py-3
              px-6
              font-bold
              w-full

            "
            />
          </div>

          <div class="mt-2">
            <VoixLink
              :field="fields.extraButton"
              class="
              glue-btn
              btn-ghost
              border-zinc-600
              text-zinc-700
              uppercase
              !text-xs
              body-11
              tracking-widest
              py-3
              px-6
              font-bold
              w-full

            "
            />
          </div>
        </div>
      </div>
    </div>

    <Teleport to="#portal-modal">
      <div
        v-if="showDressCodeModal"
        class="fixed z-40 inset-0"
      >
        <div class="absolute z-30 inset-0 bg-black/75" @click="showDressCodeModal = false" />
        <div class="absolute z-40 inset-0 flex justify-center items-center pointer-events-none">
          <div class="relative bg-white w-full max-w-xl mx-8 text-center p-8 pointer-events-auto">
            <button class="absolute z-10 top-0 right-0 -translate-y-8 md:translate-x-8" @click="showDressCodeModal = false">
              <Icon class="w-8 h-8 text-white" name="heroicons:x-mark-20-solid" />
            </button>
            <div class="text-left space-y-2">
              <VoixMedia
                :field="fields.logo"
              />
              <div class="text-2xl font-sans2 font-light uppercase">
                Dress Code at {{ restaurant?.name }}
              </div>
              <VoixWysiwyg
                :field="fields.dresscodeDetails"
                class="mt-8 prose prose-sm"
              />
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>
