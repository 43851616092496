<script setup lang="ts">
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  name: { label: 'Dining Filter', group: 'Dining', layouts: ['AtlantisMobileApp', 'Atlantisbahamas'] },
  preview: 'SlicesGlueDiningDiningFilter.jpg',
  description: 'Dining Filters for use on dining index and dining results',
  fields: { showTopFilter: { type: 'checkbox', label: 'Show top filter on scroll?' } },
  templates: [{
    label: 'Dining Filter',
    fields: [],
  }],
})

const router = useRouter()
const filtersEl = ref(null)
const scrollTop = ref(0)
const initialOffset = ref(0)
const initialHeight = ref(0)
const nochrome = ref(false)
const scrollDirection = ref('down')
const displayNames = ref({
  'Specialty Dining': 'Signature Dining',
  'Casual Dining': 'Casual',
  'Quick Bites': 'Fast-Casual',
  'Bars, Lounges & Clubs': 'Bar & Lounge',
  'Contemporary Casual': 'Contemporary Casual',
  'Buffet': 'Buffet',
  'Celebrity Chef': 'Celebrity Chef',
  'Open Now': 'Open Now',
  'Open Today': 'Open Today',
  'Breakfast': 'Breakfast',
  'Lunch': 'Lunch',
  'Dinner': 'Dinner',
  'Late Night': 'Late Night',
  'Royal & Casino': 'The Royal',
  'Coral': 'The Coral',
  'Cove & Reef': 'The Cove',
  'The Reef': 'The Reef',
  'Marina Village': 'Marina Village',
  'Casino': 'Atlantis Casino',
  'Harborside': 'Harborside Resort',
  'Aquaventure': 'Aquaventure',
  'Golf Course': 'Ocean Club Golf Course',
  'Indoor Dining': 'Indoor Seating',
  'Patio Dining': 'Outdoor Seating',
  'Poolside Dining': 'Poolside Dining',
})

const currentFilterCategory = ref(null)

const localFilters = reactive({
  search: '',
  categories: [
    {
      name: 'Dining Type',
      // Note: Do not change the order due to automatic filters on specific dining category pages
      options: [
        {
          selected: false,
          name: 'Open Now',
        },
        {
          selected: false,
          name: 'Open Today',
        },
        {
          selected: false,
          name: 'Specialty Dining',
        },
        {
          selected: false,
          name: 'Casual Dining',
        },
        {
          selected: false,
          name: 'Quick Bites',
        },
        {
          selected: false,
          name: 'Bars, Lounges & Clubs',
        },
      ],
    },
    {
      name: 'Meal Period',
      options: [
        {
          selected: false,
          name: 'Open Now',
        },
        {
          selected: false,
          name: 'Open Today',
        },
        {
          selected: false,
          name: 'Breakfast',
        },
        {
          selected: false,
          name: 'Lunch',
        },
        {
          selected: false,
          name: 'Dinner',
        },
        {
          selected: false,
          name: 'Late Night',
        },
      ],
    },
    {
      name: 'Dining Location',
      options: [
        {
          selected: false,
          name: 'Royal & Casino',
        },
        {
          selected: false,
          name: 'Coral',
        },
        {
          selected: false,
          name: 'Cove & Reef',
        },
        {
          selected: false,
          name: 'The Reef',
        },
        {
          selected: false,
          name: 'Harborside',
        },
        {
          selected: false,
          name: 'Casino',
        },
        {
          selected: false,
          name: 'Marina Village',
        },
        {
          selected: false,
          name: 'Aquaventure',
        },
        {
          selected: false,
          name: 'Golf Course',
        },
        {
          selected: false,
          name: 'Indoor Dining',
        },
        {
          selected: false,
          name: 'Patio Dining',
        },
        {
          selected: false,
          name: 'Poolside Dining',
        },
      ],
    },
  ],
})

const route = useRoute()
nochrome.value = route.query.nochrome

const currentFilterCategoryOptions = computed(() => {
  // return all options if all is selected
  if (currentFilterCategory.value === 'all') {
    return {
      options: localFilters.categories
        .map(category => category.options)
        .flat(),
    }
  }
  if (!currentFilterCategory.value)
    return localFilters.categories[0]

  return localFilters.categories[currentFilterCategory.value]
})

const areAllSelected = computed(() => {
  if (localFilters.categories) {
    for (let i = 0; i < localFilters.categories.length; i++) {
      const category = localFilters.categories[i]
      for (let j = 0; j < category.options.length; j++) {
        if (!category.options[j].selected)
          return false
      }
    }
  }
  return true
})

const noneAreSelected = computed(() => {
  if (localFilters.categories) {
    for (let i = 0; i < localFilters.categories.length; i++) {
      const category = localFilters.categories[i]
      for (let j = 0; j < category.options.length; j++) {
        if (category.options[j].selected)
          return false
      }
    }
  }
  return true
})

const enabledCategories = computed(() => {
  const enabledCategories = {}

  if (localFilters && localFilters.categories) {
    localFilters.categories.forEach((category) => {
      category.options.forEach((option) => {
        if (option.selected) {
          if (!enabledCategories[category.name])
            enabledCategories[category.name] = []

          enabledCategories[category.name].push(option.name)
        }
      })
    })
  }

  return enabledCategories
})

watch(() => localFilters, () => {
  performSearchAddHistory()
}, { deep: true })

watch(scrollTop, (newValue, oldValue) => {
  if (oldValue < newValue)
    scrollDirection.value = 'down'
  else
    scrollDirection.value = 'up'
})

onMounted(() => {
  if (props.fields.showTopFilter.value) {
    scrollTop.value = document.documentElement.scrollTop
    window.addEventListener('scroll', () => {
      scrollTop.value = document.documentElement.scrollTop
    })
  }

  checkUrlForDefaultCategories()

  if (filtersEl.value) {
  // get the initial position
    initialOffset.value = filtersEl.value.offsetTop
    // get the height of the filter bar
    initialHeight.value = filtersEl.value.offsetHeight
  }

  if (route.query.filters) {
    syncLocalFiltersWithParamFilters()
  }
})

function syncLocalFiltersWithParamFilters() {
  const paramFilters = JSON.parse(route.query.filters)
  const pCategories = paramFilters.categories || []

  for (let i = 0; i < pCategories.length; i++) {
    const pOptions = pCategories[i].options
    const localFiltered = localFilters.categories.filter(category => category.name === pCategories[i].name)
    const localCategory = localFiltered.length ? localFiltered[0] : null
    if (localCategory) {
      const localOptions = localCategory.options
      for (let j = 0; j < pOptions.length; j++) {
        if (pOptions[j].selected) {
        // find index of option in local category
          const index = localOptions.findIndex(option => option.name === pOptions[j].name)
          if (index !== -1) {
            localOptions[index].selected = true
          }
        }
      }
    }
  }

  if (paramFilters?.search)
    localFilters.search = paramFilters.search
}

function performSearchAddHistory() {
  router.push({
    path: location.pathname,
    query: {
      filters: JSON.stringify(localFilters),
    },
  })
}

function toggleCategory(category) {
  if (areAllSelected.value) {
    localFilters.categories.map(
      category => (category.selected = false),
    )
  }

  category.selected = !category.selected

  if (noneAreSelected.value)
    selectAllCategories()
}

function selectAllCategories() {
  localFilters.categories.map(
    category => (category.selected = true),
  )
}

function checkUrlForDefaultCategories() {
  if (location.href.includes('specialty-dining')) {
    toggleCategory(localFilters.categories[0].options[3])
    performSearchAddHistory()
    return true
  }

  if (location.href.includes('casual-dining')) {
    toggleCategory(localFilters.categories[0].options[0])
    performSearchAddHistory()
    return true
  }

  if (location.href.includes('lounge')) {
    toggleCategory(localFilters.categories[0].options[1])
    performSearchAddHistory()
    return true
  }

  if (location.href.includes('quick-bites')) {
    toggleCategory(localFilters.categories[0].options[2])
    performSearchAddHistory()
    return true
  }

  return false
}

function goToDiningFilters() {
  document.querySelector('#dining-filters').scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })
}
</script>

<template>
  <div rel="filtersEl">
    <div class="md:mb-0 relative" />
    <div id="dining-filters" class="container mx-auto">
      <div class="flex justify-center mb-14 pt-16">
        <div class="w-2/3 flex items-center space-x-4">
          <svg
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round" stroke-linejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>

          <input
            v-model="localFilters.search" type="text" name="search" placeholder="Search by Name"
            class="bg-transparent border-b-2 border-zinc-300 focus:outline-none placeholder-zinc-500 font-sans uppercase font-light text-xl text-left w-full"
          >
        </div>
      </div>
      <div class="relative pb-12">
        <div class="grid lg:grid-cols-4 bg-white divide-y lg:divide-y-0 lg:divide-x divide-gray-200">
          <div
            class="flex justify-center items-center space-x-4 text-red-500 cursor-pointer"
            :class="{ 'bg-white': currentFilterCategory === 'all' }"
            @click="currentFilterCategory = currentFilterCategory === 'all' ? null : 'all'"
          >
            <div class="flex items-center font-sans lg:font-sans2 uppercase font-light py-4 space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 31" class="w-5 h-5 lg:w-8 lg:h-8">
                <rect width="39" height="2" transform="translate(0 5)" fill="currentColor" />
                <rect width="39" height="2" transform="translate(0 24)" fill="currentColor" />
                <rect width="39" height="2" transform="translate(0 15)" fill="currentColor" />
                <g
                  transform="translate(23)" fill="currentColor" stroke="currentColor" stroke-miterlimit="10"
                  stroke-width="2"
                >
                  <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
                  <circle cx="5.5" cy="5.5" r="4.5" fill="none" />
                </g>
                <g
                  transform="translate(5 10)" fill="currentColor" stroke="currentColor" stroke-miterlimit="10"
                  stroke-width="2"
                >
                  <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
                  <circle cx="5.5" cy="5.5" r="4.5" fill="none" />
                </g>
                <g
                  transform="translate(18 20)" fill="currentColor" stroke="currentColor" stroke-miterlimit="10"
                  stroke-width="2"
                >
                  <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
                  <circle cx="5.5" cy="5.5" r="4.5" fill="none" />
                </g>
              </svg>
              <span class="select-none">Filter By: All</span>

              <svg
                xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-red-500"
                :class="{ 'transform -rotate-90': currentFilterCategory !== 'all' }" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>
            </div>
          </div>

          <div
            v-for="(localCategory, localCategoryKey) in localFilters.categories" :key="localCategory.name"
            class="relative flex justify-center items-center cursor-pointer"
            :class="{ 'bg-white': currentFilterCategory === localCategoryKey }"
            @click="currentFilterCategory = currentFilterCategory === localCategoryKey ? null : localCategoryKey"
          >
            <div class=" font-sans2 text-sm uppercase py-2 select-none">
              {{ localCategory.name }}
              <div
                v-show="enabledCategories[localCategory.name] && enabledCategories[localCategory.name].length"
                class="absolute top-3 right-3 text-xs rounded-full w-6 h-6 bg-glueblue-600 text-white flex justify-center items-center"
              >
                {{ enabledCategories[localCategory.name] ? enabledCategories[localCategory.name].length : 0 }}
              </div>
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg" class="w-5 h-5"
              :class="{ 'transform -rotate-90': currentFilterCategory !== localCategoryKey }" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
        </div>

        <div
          class="bg-orange-50 font-sans2 overflow-hidden grid lg:grid-cols-4 gap-4 " :class="{
            'max-h-0': currentFilterCategory === null,
            'max-h-[2000px] p-4': currentFilterCategory !== null,
          }"
        >
          <label
            v-for="(categoryOption, key) in currentFilterCategoryOptions.options"
            :key="key" class="cursor-pointer inline-flex space-x-2 items-center p-3 uppercase text-xs border border-orange-300"
            :class="{ 'bg-glueblue-600': categoryOption.selected }"
          >
            <input v-model="categoryOption.selected" type="checkbox" class="hidden">
            <span
              class="text-xs"
              :class="{ 'text-white': categoryOption.selected, 'text-gray-800': !categoryOption.selected }"
            >
              {{
                displayNames[categoryOption.name]
              }}
            </span>
          </label>
        </div>
      </div>
    </div>

    <div class="fixed z-50 bottom-0 left-0 right-0 m-8 flex justify-center">
      <button
        class="flex items-center space-x-2 rounded-full bg-glueblue-600 text-white py-3 px-6 uppercase font-sans font-bold text-sm"
        @click="goToDiningFilters"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round" stroke-linejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>
        <span>Dining Filters</span>
      </button>
    </div>
  </div>
</template>
