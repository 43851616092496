<script>
export default {
  name: 'ButlerIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 38 44"
  >
    <defs>
      <path
        id="butler-icon-prefix-a"
        d="M0.001 0.22L37.505 0.22 37.505 44 0.001 44z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <mask id="butler-icon-prefix-b" fill="currentcolor">
        <use xlink:href="#butler-icon-prefix-a" />
      </mask>
      <path
        fill="currentcolor"
        stroke="#currentColor"
        stroke-width=".4"
        d="M36.115 42.587H30.56v-2.189c0-3.953 1.459-7.908 4.099-10.802.902 1.27 1.457 2.894 1.457 4.518v8.473zM1.39 34.114c0-1.624.555-3.248 1.457-4.518 2.64 2.965 4.098 6.778 4.098 10.802v2.26H1.39v-8.544zm5.07-7.343l3.68-1.342 8.613 15.04 8.61-15.04 3.683 1.342c1.041.352 1.944.988 2.708 1.695-2.916 3.177-4.584 7.484-4.584 11.862v2.19H8.334v-2.26c0-4.449-1.597-8.615-4.584-11.863.765-.636 1.667-1.272 2.71-1.624h0zm9.375-4.872c.904.564 1.876.917 2.918.917 1.041 0 2.014-.353 2.916-.917.972 2.33 4.375 3.036 4.375 3.036l-7.291 12.71-7.293-12.71c2.5-.706 3.82-1.765 4.375-3.036h0zm-4.723-12.5c0-.775.14-1.552.348-2.33 5.834.918 9.167-1.694 9.167-1.694 1.042 1.413 3.057 2.543 5.487 2.048.14.635.279 1.27.279 1.977 0 4.52-3.543 12.004-7.64 12.004-4.098 0-7.64-7.484-7.64-12.004h0zm7.64-7.767c3.056 0 5.695 1.836 6.945 4.45-3.333.493-4.583-2.685-4.583-2.685s-3.403 3.107-9.03 2.26c1.182-2.33 3.751-4.025 6.669-4.025h0zm12.78 23.797l-6.181-2.26c-2.5-.918-2.431-2.26-2.431-2.26 2.986-2.824 4.86-8.19 4.86-11.51 0-5.083-4.027-9.18-9.027-9.18-5.002 0-9.03 4.097-9.03 9.18 0 3.32 1.875 8.686 4.862 11.51 0 0 .139 1.342-2.43 2.26l-6.182 2.26C2.431 26.77.001 30.23.001 34.114V44h37.504v-9.886c0-3.883-2.431-7.343-5.973-8.685h0z"
        mask="url(#butler-icon-prefix-b)"
      />
      <path
        fill="currentcolor"
        stroke="#currentColor"
        stroke-width=".4"
        d="M16.22 27.266v-1.17l2.71.585-2.71.585zm5.56-1.17v1.17l-2.71-.585 2.71-.585zm1.39 2.925v-4.68l-4.17.878-4.17-.879v4.681l4.17-.877 4.17.877z"
      />
    </g>
  </svg>
</template>
