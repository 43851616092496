<script lang="ts" setup>
import type { VoixLinkField, VoixLinkFieldInterface, VoixMediaFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    title: VoixTextFieldInterface
    description: VoixWysiwygFieldInterface
    // Card 1
    cardImage1: VoixMediaFieldInterface
    cardTitle1: VoixTextFieldInterface
    cardDescription1: VoixWysiwygFieldInterface
    cardLink1: VoixLinkFieldInterface
    // Card 2
    cardImage2: VoixMediaFieldInterface
    cardTitle2: VoixTextFieldInterface
    cardDescription2: VoixWysiwygFieldInterface
    cardLink2: VoixLinkFieldInterface
    // Card 3
    cardImage3: VoixMediaFieldInterface
    cardTitle3: VoixTextFieldInterface
    cardDescription3: VoixWysiwygFieldInterface
    cardLink3: VoixLinkFieldInterface
    // Card 4
    cardImage4: VoixMediaFieldInterface
    cardTitle4: VoixTextFieldInterface
    cardDescription4: VoixWysiwygFieldInterface
    cardLink4: VoixLinkFieldInterface
    // Card 5
    cardImage5: VoixMediaFieldInterface
    cardTitle5: VoixTextFieldInterface
    cardDescription5: VoixWysiwygFieldInterface
    cardLink5: VoixLinkFieldInterface
  }
}>()

defineSlice({
  name: {
    label: 'Special Offers Discover',
    group: 'Specials',
    layouts: ['Atlantisbahamas'],
  },
  description: 'Special Offers Discover Slice',
  tags: ['Special'],
  fields: {
    title: {
      type: 'text',
      label: 'Title',
    },
    description: {
      type: 'wysiwyg',
      label: 'Description',
    },
    // Card 1
    cardImage1: {
      type: 'media',
      label: 'Card Image 1',
      group: 'Card 1',
      breakpoints: {
        default: { width: 1600 },
      },
    },
    cardTitle1: {
      type: 'text',
      label: 'Card Title 1',
      group: 'Card 1',
    },
    cardDescription1: {
      type: 'wysiwyg',
      label: 'Card Description 1',
      group: 'Card 1',
    },
    cardLink1: {
      type: 'link',
      label: 'Card Link 1',
      group: 'Card 1',
    },
    // Card 2
    cardImage2: {
      type: 'media',
      label: 'Card Image 2',
      group: 'Card 2',
      breakpoints: {
        default: { width: 1600 },
      },
    },
    cardTitle2: {
      type: 'text',
      label: 'Card Title 2',
      group: 'Card 2',
    },
    cardDescription2: {
      type: 'wysiwyg',
      label: 'Card Description 2',
      group: 'Card 2',
    },
    cardLink2: {
      type: 'link',
      label: 'Card Link 2',
      group: 'Card 2',
    },
    // Card 3
    cardImage3: {
      type: 'media',
      label: 'Card Image 3',
      group: 'Card 3',
      breakpoints: {
        default: { width: 1600 },
      },
    },
    cardTitle3: {
      type: 'text',
      label: 'Card Title 3',
      group: 'Card 3',
    },
    cardDescription3: {
      type: 'wysiwyg',
      label: 'Card Description 3',
      group: 'Card 3',
    },
    cardLink3: {
      type: 'link',
      label: 'Card Link 3',
      group: 'Card 3',
    },
    // Card 4
    cardImage4: {
      type: 'media',
      label: 'Card Image 4',
      group: 'Card 4',
      breakpoints: {
        default: { width: 1600 },
      },
    },
    cardTitle4: {
      type: 'text',
      label: 'Card Title 4',
      group: 'Card 4',
    },
    cardDescription4: {
      type: 'wysiwyg',
      label: 'Card Description 4',
      group: 'Card 4',
    },
    cardLink4: {
      type: 'link',
      label: 'Card Link 4',
      group: 'Card 4',
    },
    // Card 5
    cardImage5: {
      type: 'media',
      label: 'Card Image 5',
      group: 'Card 5',
      breakpoints: {
        default: { width: 1600 },
      },
    },
    cardTitle5: {
      type: 'text',
      label: 'Card Title 5',
      group: 'Card 5',
    },
    cardDescription5: {
      type: 'wysiwyg',
      label: 'Card Description 5',
      group: 'Card 5',
    },
    cardLink5: {
      type: 'link',
      label: 'Card Link 5',
      group: 'Card 5',
    },
  },
  preview: 'SlicesSpecialOffersDiscover.jpg',
  templates: [{
    label: 'Special Offers ',
    fields: {},
  }],
})

// Show Card Descriptions
const showCardDescription1 = ref(false)
const showCardDescription2 = ref(false)
const showCardDescription3 = ref(false)
const showCardDescription4 = ref(false)
const showCardDescription5 = ref(false)
</script>

<template>
  <div class="flex flex-col justify-center items-center w-full h-full py-40 px-0 lg:px-16">
    <!-- Title -->
    <div class="text-3xl sm:text-4xl text-center font-light uppercase tracking-wider px-4">
      {{ fields.title.value }}
    </div>

    <!-- Description -->
    <VoixWysiwyg
      class="w-full font-light mt-5 px-8"
      :field="fields.description"
    />

    <!-- Discover -->
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-2 mt-24">
      <!-- Left Column -->
      <div class="flex flex-col space-y-2 h-fit">
        <!-- Card 1 -->
        <div
          class="relative w-full lg:max-w-[512px] min-h-[320px] cursor-pointer"
          @touchstart="showCardDescription1 = true"
          @touchend="showCardDescription1 = false"
          @mouseenter="showCardDescription1 = true"
          @mouseleave="showCardDescription1 = false"
        >
          <!-- Background Image -->
          <VoixMedia
            class="absolute top-0 left-0 w-full h-full object-cover object-center z-10"
            :field="fields.cardImage1"
          />

          <!-- Content -->
          <div
            class="absolute bottom-0 w-full from-black to-transparent py-4 px-4 duration-700 ease-out overflow-hidden z-20"
            :class="{
              'h-[120px] bg-gradient-to-t': !showCardDescription1,
              'h-[200px] bg-glueblue-700': showCardDescription1,
            }"
          >
            <p
              class="text-xl text-white font-light uppercase tracking-wider pb-1.5"
              :class="{
                '-translate-y-2': showCardDescription1,
              }"
            >
              {{ fields.cardTitle1.value }}
            </p>

            <div
              class="duration-500 delay-200"
              :class="{
                '-translate-y-2': showCardDescription1,
              }"
            >
              <VoixWysiwyg
                class="font-light text-xs text-white pb-6 duration-700 overflow-hidden"
                :class="{
                  'h-0 opacity-0': !showCardDescription1,
                  'h-[500px] opacity-100': showCardDescription1,
                }"
                :field="fields.cardDescription1"
              />
            </div>

            <VoixLink
              class="absolute bottom-4 left-4 btn-primary hover:bg-glueblue-600 border-white border-[1px] hover:border-transparent text-white text-xs font-normal uppercase tracking-wide leading-none py-3.5 px-6 inline-block duration-1000"
              :class="{
                'xl:-translate-y-2': showCardDescription1,
              }"
              :field="fields.cardLink1"
            >
              Learn More
            </VoixLink>
          </div>
        </div>

        <!-- Card 2 -->
        <div
          class="relative w-full lg:max-w-[512px] min-h-[320px] cursor-pointer"
          @touchstart="showCardDescription2 = true"
          @touchend="showCardDescription2 = false"
          @mouseenter="showCardDescription2 = true"
          @mouseleave="showCardDescription2 = false"
        >
          <!-- Background Image -->
          <VoixMedia
            class="absolute top-0 left-0 w-full h-full object-cover object-center z-10"
            :field="fields.cardImage2"
          />

          <!-- Content -->
          <div
            class="absolute bottom-0 w-full from-black to-transparent py-4 px-4 duration-700 ease-out overflow-hidden z-20"
            :class="{
              'h-[120px] bg-gradient-to-t': !showCardDescription2,
              'h-[200px] bg-glueblue-700': showCardDescription2,
            }"
          >
            <p
              class="text-xl text-white font-light uppercase tracking-wider pb-1.5"
              :class="{
                '-translate-y-2': showCardDescription2,
              }"
            >
              {{ fields.cardTitle2.value }}
            </p>

            <div
              class="duration-500 delay-200"
              :class="{
                '-translate-y-2': showCardDescription2,
              }"
            >
              <VoixWysiwyg
                class="font-light text-xs text-white pb-6 duration-700 overflow-hidden"
                :class="{
                  'h-0 opacity-0': !showCardDescription2,
                  'h-[500px] opacity-100': showCardDescription2,
                }"
                :field="fields.cardDescription2"
              />
            </div>

            <VoixLink
              class="absolute bottom-4 left-4 btn-primary hover:bg-glueblue-600 border-white border-[1px] hover:border-transparent text-white text-xs font-normal uppercase tracking-wide leading-none py-3.5 px-6 inline-block duration-1000"
              :class="{
                'xl:-translate-y-2': showCardDescription2,
              }"
              :field="fields.cardLink2"
            >
              Learn More
            </VoixLink>
          </div>
        </div>
      </div>

      <!-- Middle Column -->
      <div
        class="relative max-w-full w-[815px] lg:w-[512px] min-h-[320px] 2xl:h-full cursor-pointer"
        @touchstart="showCardDescription3 = true"
        @touchend="showCardDescription3 = false"
        @mouseenter="showCardDescription3 = true"
        @mouseleave="showCardDescription3 = false"
      >
        <!-- Background Image -->
        <VoixMedia
          class="absolute top-0 left-0 w-full h-full object-cover object-center z-10"
          :field="fields.cardImage3"
        />

        <!-- Content -->
        <div
          class="absolute bottom-0 w-full from-black to-transparent py-4 px-4 duration-700 ease-out overflow-hidden z-20"
          :class="{
            'h-[120px] bg-gradient-to-t': !showCardDescription3,
            'h-[200px] bg-glueblue-700': showCardDescription3,
          }"
        >
          <p
            class="text-xl text-white font-light uppercase tracking-wider pb-1.5"
            :class="{
              '-translate-y-2': showCardDescription3,
            }"
          >
            {{ fields.cardTitle3.value }}
          </p>

          <div
            class="duration-500 delay-200"
            :class="{
              '-translate-y-2': showCardDescription3,
            }"
          >
            <VoixWysiwyg
              class="font-light text-xs text-white pb-6 duration-700 overflow-hidden"
              :class="{
                'h-0 opacity-0': !showCardDescription3,
                'h-[500px] opacity-100': showCardDescription3,
              }"
              :field="fields.cardDescription3"
            />
          </div>

          <VoixLink
            class="absolute bottom-4 left-4 btn-primary hover:bg-glueblue-600 border-white border-[1px] hover:border-transparent text-white text-xs font-normal uppercase tracking-wide leading-none py-3.5 px-6 inline-block duration-1000"
            :class="{
              'xl:-translate-y-2': showCardDescription3,
            }"
            :field="fields.cardLink3"
          >
            Learn More
          </VoixLink>
        </div>
      </div>

      <!-- Right Column -->
      <div class="flex flex-col space-y-2 h-fit">
        <!-- Card 4 -->
        <div
          class="relative w-full lg:max-w-[512px] min-h-[320px] cursor-pointer"
          @touchstart="showCardDescription4 = true"
          @touchend="showCardDescription4 = false"
          @mouseenter="showCardDescription4 = true"
          @mouseleave="showCardDescription4 = false"
        >
          <!-- Background Image -->
          <VoixMedia
            class="absolute top-0 left-0 w-full h-full object-cover object-center z-10"
            :field="fields.cardImage4"
          />

          <!-- Content -->
          <div
            class="absolute bottom-0 w-full from-black to-transparent py-4 px-4 duration-700 ease-out overflow-hidden z-20"
            :class="{
              'h-[120px] bg-gradient-to-t': !showCardDescription4,
              'h-[200px] bg-glueblue-700': showCardDescription4,
            }"
          >
            <p
              class="text-xl text-white font-light uppercase tracking-wider pb-1.5"
              :class="{
                '-translate-y-2': showCardDescription4,
              }"
            >
              {{ fields.cardTitle4.value }}
            </p>

            <div
              class="duration-500 delay-200"
              :class="{
                '-translate-y-2': showCardDescription4,
              }"
            >
              <VoixWysiwyg
                class="font-light text-xs text-white pb-6 duration-700 overflow-hidden"
                :class="{
                  'h-0 opacity-0': !showCardDescription4,
                  'h-[500px] opacity-100': showCardDescription4,
                }"
                :field="fields.cardDescription4"
              />
            </div>

            <VoixLink
              class="absolute bottom-4 left-4 btn-primary hover:bg-glueblue-600 border-white border-[1px] hover:border-transparent text-white text-xs font-normal uppercase tracking-wide leading-none py-3.5 px-6 inline-block duration-1000"
              :class="{
                'xl:-translate-y-2': showCardDescription4,
              }"
              :field="fields.cardLink4"
            >
              Learn More
            </VoixLink>
          </div>
        </div>

        <!-- Card 5 -->
        <div
          class="relative w-full lg:max-w-[512px] min-h-[320px] cursor-pointer"
          @touchstart="showCardDescription5 = true"
          @touchend="showCardDescription5 = false"
          @mouseenter="showCardDescription5 = true"
          @mouseleave="showCardDescription5 = false"
        >
          <!-- Background Image -->
          <VoixMedia
            class="absolute top-0 left-0 w-full h-full object-cover object-center z-10"
            :field="fields.cardImage5"
          />

          <!-- Content -->
          <div
            class="absolute bottom-0 w-full from-black to-transparent py-4 px-4 duration-700 ease-out overflow-hidden z-20"
            :class="{
              'h-[120px] bg-gradient-to-t': !showCardDescription5,
              'h-[200px] bg-glueblue-700': showCardDescription5,
            }"
          >
            <p
              class="text-xl text-white font-light uppercase tracking-wider pb-1.5"
              :class="{
                '-translate-y-2': showCardDescription5,
              }"
            >
              {{ fields.cardTitle5.value }}
            </p>

            <div
              class="duration-500 delay-200"
              :class="{
                '-translate-y-2': showCardDescription5,
              }"
            >
              <VoixWysiwyg
                class="font-light text-xs text-white pb-6 duration-700 overflow-hidden"
                :class="{
                  'h-0 opacity-0': !showCardDescription5,
                  'h-[500px] opacity-100': showCardDescription5,
                }"
                :field="fields.cardDescription5"
              />
            </div>

            <VoixLink
              class="absolute bottom-4 left-4 btn-primary hover:bg-glueblue-600 border-white border-[1px] hover:border-transparent text-white text-xs font-normal uppercase tracking-wide leading-none py-3.5 px-6 inline-block duration-1000"
              :class="{
                'xl:-translate-y-2': showCardDescription5,
              }"
              :field="fields.cardLink5"
            >
              Learn More
            </VoixLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
